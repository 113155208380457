import conn from "@/flit2go-web-core/services/connection";

const url =
  window.location !== window.parent.location
    ? document.location.ancestorOrigins
      ? document.location.ancestorOrigins[0]
      : document.referrer
    : document.location.origin;
export default {
  getConfigurationToken() {
    return {
      Authorization: localStorage.getItem(`apiToken-${url}`),
      "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
      "Accept-language": localStorage.getItem(`user-language`),
    };
  },
  customerStripe(data) {
    return conn.$axios({
      url: `secured/v1/stripe/customer/`,
      method: "PUT",
      params: {
        ownerId: data.ownerId,
      },
      headers: {
        Authorization: "Bearer " + data.access_token,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
    });
  },
  getApiKeyStripe(params) {
    return conn.$axios({
      url: `secured/v1/stripe/apiKey`,
      method: "GET",
      params: params.ownerId,
      headers: {
        Authorization: "Bearer " + params.loginParameters.access_token,
        "x-requested-with": localStorage.getItem("windowUrl"),
      },
    });
  },
  getIntentStripe(data) {
    return conn.$axios({
      url: `secured/v1/stripe/intent/`,
      method: "GET",
      params: {
        ownerId: data.ownerId,
      },
      headers: {
        Authorization: "Bearer " + data.access_token,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
    });
  },
  newPayment(data) {
    return conn.$axios({
      url: `secured/v1.1/user/${data.userId}/payment/new`,
      method: "POST",
      headers: {
        Authorization: "Bearer " + data.access_token,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
      params: data.data,
    });
  },
  getPrice(data) {
    return conn.$axios({
      url: `v1/frameweb/booking/category/${data.categoryId}/price`,
      method: "POST",
      data: data.data,
      headers: this.getConfigurationToken(),
    });
  },
  createBooking(data) {
    return conn.$axios({
      url: `secured/v1/frameweb/booking/category/${data.categoryId}/user/${data.userId}`,
      method: "POST",
      data: data.data,
      params: {
        ApiKey: localStorage.getItem(`apiToken-${url}`),
      },
      headers: {
        Authorization: "Bearer " + data.loginParameters.access_token,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
    });
  },

  createReserve(data) {
    return conn.$axios({
      url: `/secured/v1/frameweb/booking/stripe/pay/reserve/${data.reserveId}`,
      method: "POST",
      params: data.params,
      headers: {
        Authorization: "Bearer " + data.loginParameters.access_token,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
    });
  },
  getConfiguration(params) {
    return conn.$axios({
      url: `v1/frameweb/configuration`,
      method: "GET",
      params: params,
      headers: this.getConfigurationToken(),
    });
  },

  getPrivacy(data) {
    return conn.$axios({
      url: `/users/v1/frameweb/privacy`,
      method: "GET",
      params: {
        apiKey: localStorage.getItem(`apiToken-${url}`),
      },
      headers: {
        Authorization: "Bearer " + data.access_token,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
    });
  },

  getTerms(data) {
    return conn.$axios({
      url: `/users/v1/frameweb/terms`,
      method: "GET",
      params: {
        idCategory: data.idCategory,
        apiKey: localStorage.getItem(`apiToken-${url}`),
      },
      headers: {
        Authorization: "Bearer " + data.access_token,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
    });
  },
  sendHubspotEndpoint(data) {
    return conn.$axios({
      url: `/secured/v1/frameweb/stage/${data.idStage}`,
      method: "POST",
      data: data.data,
      params: data.params,
      headers: {
        Authorization: "Bearer " + data.access_token,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
    });
  },
};
