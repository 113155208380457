import moment from "moment";

function formatThousands(number) {
  let newNumber = number;
  let decimals = 0;
  if (number.includes(",")) {
    newNumber = number.split(",")[0];
    decimals = number.split(",")[1];
  }
  let formatNumber = "";
  let reverseNumber = newNumber.split("").reverse().join("");
  let reverseNumberLength = reverseNumber.length;
  for (let i = 0; i <= reverseNumberLength; i += 3) {
    formatNumber += reverseNumber.substring(i, i + 3);
    if (reverseNumber.substr(i).length > 3) {
      formatNumber += ".";
    }
  }
  formatNumber = formatNumber.split("").reverse().join("");

  let total = decimals ? formatNumber + "," + decimals : formatNumber;
  return total;
}

export default {
  // Format a decimal or non decimal number with '.' for the thousands and ',' for the decimal part.
  formatNumber(x) {
    if (null != x && "" !== x) {
      let negative = false;
      if (x.toString().includes("_")) {
        x = x.toString().split("_")[1];
        negative = true;
      }
      let parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      if (parts.length > 1 && parts[1].length > 2) {
        parts[1] = parts[1].substr(0, 2);
      }
      parts = parts.join(",");
      if (negative) parts = `-${parts}`;
      return parts;
    } else {
      return "";
    }
  },
  formatBillingValue(value, ownerSymbol = "€", withSymbol = true) {
    let valueString = value ? value.toString() : "0";
    let formatValue = "";
    let negative = false;
    if (valueString.startsWith("_")) {
      valueString = valueString.split("_")[1];
      negative = true;
    }
    if (valueString.includes(".")) {
      let valueSplit = valueString.split(".");
      formatValue = formatThousands(valueSplit[0]);
      if (formatValue.startsWith("-.")) {
        formatValue.toString();
        formatValue = formatValue.replace("-.", "-");
      }
      if (valueSplit[1].length > 2) {
        formatValue += "," + valueSplit[1].substring(0, 2);
      } else {
        formatValue += "," + valueSplit[1];
      }
    } else {
      if (
        valueString.includes("-")
          ? valueString.length > 4
          : valueString.length > 3
      ) {
        formatValue = formatThousands(valueString);
        if (formatValue.startsWith("-.")) {
          formatValue.toString();
          formatValue = formatValue.replace("-.", "-");
        }
        if (formatValue.includes(".,")) {
          formatValue = formatValue.replace(".,", ",");
        }
      } else {
        formatValue = valueString;
      }
    }

    if (formatValue.includes(",00")) {
      formatValue = formatValue.replace(",00", "");
    } else if (/,\d$/.test(formatValue)) {
      formatValue += "0";
    }

    if (withSymbol) {
      if (ownerSymbol === "$") {
        formatValue = formatValue
          .replace(/\./g, "#")
          .replace(/,/g, ".")
          .replace(/#/g, ",");
        return negative
          ? `${ownerSymbol}-${formatValue}`
          : `${ownerSymbol}${formatValue}`;
      } else {
        return negative
          ? `-${formatValue} ${ownerSymbol}`
          : `${formatValue} ${ownerSymbol}`;
      }
    } else {
      return negative ? "-" + formatValue : formatValue;
    }
  },
  loadYears() {
    let year = new Date().getFullYear();
    let years = [];
    for (let i = 2019; i <= year; i++) {
      years.push(i);
    }
    return years;
  },
  checkMonth(month) {
    switch (Number(month)) {
      case 1:
        return "january";
      case 2:
        return "february";
      case 3:
        return "march";
      case 4:
        return "april";
      case 5:
        return "may";
      case 6:
        return "june";
      case 7:
        return "july";
      case 8:
        return "august";
      case 9:
        return "september";
      case 10:
        return "october";
      case 11:
        return "november";
      case 12:
        return "december";
      default:
        break;
    }
  },
  formatTodayMonth() {
    let today = new Date();
    let month = today.getMonth() + 1;
    let date = today.getFullYear() + "-";
    if (month < 10) {
      date += "0" + month;
    } else {
      date += month;
    }
    return date;
  },
  formatTodayDay() {
    let today = new Date();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    let date = today.getFullYear() + "-";
    if (month < 10) {
      date += "0" + month + "-";
    } else {
      date += month + "-";
    }
    if (day < 10) {
      date += "0" + day;
    } else {
      date += day;
    }
    return date;
  },
  formatDate(travelDate, condition, travelDateStore) {
    let date = new Date(travelDate);
    let formatDate = "";
    if (condition) {
      let sameDay = date.getDate() == travelDateStore.split("/")[1];
      let sameMonth =
        date.getDate() == travelDateStore.split("/")[1] &&
        Number(date.getMonth()) + 1 == travelDateStore.split("/")[0];

      if (!sameDay || !sameMonth) {
        formatDate = date.getFullYear() + "-";
        if (date.getMonth() < 9) {
          formatDate += "0" + (date.getMonth() + 1) + "-";
        } else {
          formatDate += date.getMonth() + 1 + "-";
        }
        if (date.getDate() < 10) {
          formatDate += "0" + date.getDate() + " ";
        } else {
          formatDate += date.getDate() + " ";
        }
      }
    }
    if (date.getHours() < 10) {
      formatDate += "0" + date.getHours() + ":";
    } else {
      formatDate += date.getHours() + ":";
    }
    if (date.getMinutes() < 10) {
      formatDate += "0" + date.getMinutes() + ":";
    } else {
      formatDate += date.getMinutes() + ":";
    }
    if (date.getSeconds() < 10) {
      formatDate += "0" + date.getSeconds();
    } else {
      formatDate += date.getSeconds();
    }
    return formatDate;
  },
  returnPeriodInternationalized(period) {
    let name = "";
    switch (period) {
      case "YEAR":
        name = "annual";
        break;
      case "MONTH":
        name = "monthly";
        break;
      case "DAY":
        name = "daily";
        break;
      default:
        break;
    }
    return name;
  },
  formatPaymentDate(timestamp, timeZone = "Europe/Madrid") {
    let date = moment(timestamp).tz(timeZone).format("YYYY/MM/DD").split("/");
    return `${date[0]}/${this.checkMonth(date[1])}/${date[2]}`;
  },
  calculatePriceWithTax(price, tax) {
    if (price && tax) {
      return price + price * (tax / 100);
    } else {
      return "-";
    }
  },
  decimalAdjust(type, value, exp, withSymbol = true, currency = "€") {
    // Si el exp no está definido o es cero...
    if (typeof exp === "undefined" || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // Si el valor no es un número o el exp no es un entero...
    if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
      return NaN;
    }
    // Shift
    value = value.toString().split("e");
    value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
    // Shift back
    value = value.toString().split("e");
    return this.formatBillingValue(
      +(value[0] + "e" + (value[1] ? +value[1] + exp : exp)),
      currency,
      withSymbol
    );
  },
  roundNumber(value, currency = "€") {
    return this.decimalAdjust("round", value, -2, true, currency);
  },

  roundNumberWithoutCurrency(value) {
    return this.decimalAdjust("round", value, -2, false);
  },

  trunc(x, posiciones = 0) {
    let s = x.toString();
    let l = s.length;
    let decimalLength = s.indexOf(".") + 1;

    if (l - decimalLength <= posiciones) {
      return x;
    }
    // Decimal part of number
    let isNegative = x < 0;
    let decimal = x % 1;
    let entire = isNegative ? Math.ceil(x) : Math.floor(x);
    // Parte decimal como número entero
    // Ejemplo: parte decimal = 0.77
    // decimalFormated = 0.77 * (10^posiciones)
    // si posiciones es 2 ==> 0.77 * 100
    // si posiciones es 3 ==> 0.77 * 1000
    let decimalFormated = Math.floor(
      Math.abs(decimal) * Math.pow(10, posiciones)
    );
    // Sustraemos del número original la parte decimal
    // y le sumamos la parte decimal que hemos formateado
    let finalNum =
      entire +
      (decimalFormated / Math.pow(10, posiciones)) * (isNegative ? -1 : 1);

    return finalNum;
  },
};
