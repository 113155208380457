var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-aspect-ratio",on:{"mouseenter":function($event){return _vm.$emit('mouseenter')},"mouseleave":function($event){return _vm.$emit('mouseleave')}}},[_c('v-responsive',{staticClass:"flex-row-center",class:{
      border: _vm.border,
      border25: _vm.border25,
      'border-line': _vm.borderLine,
      circle: _vm.circle,
      'mx-auto': _vm.marginAuto,
      borderRadiusLeftTopBottom: _vm.borderRadiusLeftTopBottom,
    },style:(`min-height: ${_vm.minHeight};min-width: ${_vm.minWidth};max-height: ${_vm.maxHeight};max-width: ${_vm.maxWidth}`),attrs:{"aspect-ratio":_vm.aspectRatio}},[(_vm.img)?_c('v-img',{staticClass:"image",style:({ transform: ` rotate(${_vm.rotateRate}deg)` }),attrs:{"src":_vm.image,"contain":_vm.contain},on:{"click":function($event){return _vm.$emit('click')}}}):_c('v-img',{staticClass:"image",attrs:{"src":require('@/flit2go-web-core/assets/no-information/placeholder.png')},on:{"click":function($event){return _vm.$emit('click')}}}),_vm._t("image-content")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }