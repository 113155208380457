const PRICING_TIME_TYPE = Object.freeze({
  MONTH: "MON",
  HOURS: "HOU",
  MINUTES: "MIN",
  WEEK: "WEK",
  DAYS: "DAY",
});

const PRICING_RESERVE_TYPE = Object.freeze({
  WITHOUT_RESERVE: "FRE",
  FLEET_CONTROL: "FLC",
  RESERVE: "USC",
  SUBSCRIPTION: "SUB",
  OPERATION: "OPE",
});

export default {
  TIME_TYPE: PRICING_TIME_TYPE,
  RESERVE_TYPE: PRICING_RESERVE_TYPE,
};
