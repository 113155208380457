const STATUS = Object.freeze({
  PENDING_ACCEPTATION: "PEA",
  PENDING_PICK_UP: "COP",
  IN_SUBSCRIPTION: "SUS",
  PENDING_RETURN: "PER",
  FINISHED: "SUT",
  CANCELLED: "SUC",
});

export default {
  STATUS: STATUS,
};
