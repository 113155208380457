import i18n from "@/flit2go-web-core/lang/";
import service from "@/flit2go-web-core/services/";

export default {
  namespaced: true,
  state: {
    loadedLanguages: ["en-EN"],
    languages: [],
    status: "",
    numberResultsSelected: "",
    showDateTooltip: false,
    settings: null,
    bearer: null,
    urlNewTab: null,
  },
  getters: {
    getShowDateTooltips: (state) => state.showDateTooltip,
    getBearer: (state) => state.bearer,
    languages: (state) =>
      state.languages.map((language) => {
        return language.code;
      }),
    languagesObject: (state) =>
      state.languages.map((language) => {
        if (language.code !== "EN") {
          return { code: language.code };
        } else {
          return { code: language.code, disabled: true };
        }
      }),
    getUrlNewTab: (state) => state.urlNewTab,
  },
  mutations: {
    ["STATUS_SUCCESS"]: (state) => {
      state.status = "success";
    },
    ["STATUS_LOADING"]: (state) => {
      state.status = "loading";
    },
    ["STATUS_ERROR"]: (state) => {
      state.status = "error";
    },
    ["ADD_LOCALE"]: (state, lang) => {
      state.loadedLanguages.push(lang);
    },
    ["SET_LANGUAGES"]: (state, languages) => {
      state.languages = languages;
    },
    ["SET_SHOW_TOOLTIP"]: (state, showDateTooltip) => {
      state.showDateTooltip = showDateTooltip;
    },
    ["SET_SETTINGS"]: (state, settings) => {
      state.settings = settings;
    },
    ["SET_SETTINGS_FIELD"]: (state, settings) => {
      state.settings[settings.name] = settings.value;
    },
    ["SET_BEARER"]: (state, bearer) => {
      state.bearer = bearer;
    },
    ["SET_URL_NEW_TAB"]: (state, url) => {
      state.urlNewTab = url;
    },
  },
  actions: {
    // METHOD TO VERIFY THE LANGUAGE FOR THE COUNTRY
    ["CHANGE_LANG"]: ({ commit, state }, person) => {
      if (person && Object.prototype.hasOwnProperty.call(person, "id")) {
        service.settings.updateLanguage(person);
      }

      let language = person.language.split("-")[0];
      let country = "";

      if (!language || language === "en") {
        country = "GB";
      } else if (language === "es" || language === "eu") {
        country = "ES";
      } else if (
        language === "it" ||
        language === "fr" ||
        language === "pt" ||
        language === "de"
      ) {
        country = language.toUpperCase();
      }

      if (!language.includes("_") && country.length > 0) {
        language = `${language.toLowerCase()}_${country}`;
      }
      localStorage.setItem("user-language", person.language);
      if (state.loadedLanguages.includes(language)) {
        // lazy load language
        i18n.locale = language;
        document.querySelector("html").setAttribute("lang", person.language);
      } else {
        return import(`@/flit2go-web-core/lang/lang/${language}`).then(
          (msgs) => {
            i18n.setLocaleMessage(language, msgs.default);
            commit("ADD_LOCALE", language);
            i18n.locale = language;
            document
              .querySelector("html")
              .setAttribute("lang", person.language);
          }
        );
      }
    },
    getLanguages({ commit }) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service.settings
          .getLanguages()
          .then((resp) => {
            commit("SET_LANGUAGES", resp.status === 200 ? resp.data : []);
            commit("STATUS_SUCCESS");
            resolve(resp.data);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getUserSettings({ commit }) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service.settings
          .getUserSettings()
          .then((resp) => {
            commit("SET_SETTINGS", resp.data);
            commit("STATUS_SUCCESS");
            resolve(resp.data);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getShowDateTooltips({ commit }) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service.settings
          .getShowDateTooltip()
          .then((resp) => {
            commit(
              "SET_SHOW_TOOLTIP",
              resp.status === 200 ? resp.data.showTooltipDate : false
            );
            commit("STATUS_SUCCESS");
            resolve(resp.data);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    updateNumberResults({ commit }, numberResults) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service.settings
          .updateNumberResults(numberResults)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp.data);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    updateShowDateTooltips({ commit }, showDateTooltip) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service.settings
          .updateShowDateTooltips(showDateTooltip)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp.data);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    updateSettings({ commit }, settings) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service.settings
          .updateSettings(settings)
          .then((resp) => {
            commit("SET_SETTINGS_FIELD", settings);
            commit("STATUS_SUCCESS");
            resolve(resp.data);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
  },
};
