export default {
  prefixes() {
    return [
      {
        prefix: "+93",
        size: [9],
        code: "AF",
        name_EN: "Afghanistan",
        name_own: "افغانستان",
        localPrefixes: [
          7, 20, 52, 41, 58, 50, 23, 21, 26, 43, 57, 52, 42, 31, 34, 40, 60, 54,
          30, 22, 27, 62, 51, 63, 34, 29, 50, 44, 61, 32, 65, 64, 28, 58, 55,
          56, 54, 53, 24, 33,
        ],
        default: true,
      },
      {
        prefix: "+355",
        size: [8, 9],
        code: "AL",
        name_EN: "Albania",
        name_own: "Shqipëri",
        localPrefixes: [
          6, 4, 32, 52, 54, 34, 84, 82, 511, 24, 215, 218, 83, 22, 33,
        ],
        default: true,
      },
      {
        prefix: "+213",
        size: [8, 9],
        code: "DZ",
        name_EN: "Algeria",
        name_own: "الجزائر",
        localPrefixes: [
          55, 66, 69, 77, 79, 21, 38, 33, 49, 25, 35, 34, 27, 31, 38, 25, 41,
          29, 36, 48, 38, 37, 46, 49, 24, 43,
        ],
        default: true,
      },
      {
        prefix: "+1",
        size: [10],
        code: "AS",
        name_EN: "American Samoa",
        localPrefixes: [684],
      },
      {
        prefix: "+376",
        size: [6],
        code: "AD",
        name_EN: "Andorra",
        localPrefixes: [3, 4, 6, 8],
        default: true,
      },
      {
        prefix: "+244",
        size: [9],
        code: "AO",
        name_EN: "Angola",
        localPrefixes: [2, 9],
        default: true,
      },
      {
        prefix: "+1",
        size: [10],
        code: "AI",
        name_EN: "Anguilla",
        localPrefixes: [264],
      },
      {
        prefix: "+1",
        size: [10],
        code: "AG",
        name_EN: "Antigua and Barbuda",
        localPrefixes: [268],
      },
      {
        prefix: "+54",
        size: [10],
        code: "AR",
        name_EN: "Argentina",
        localPrefixes: [
          9, 249, 221, 2901, 2920, 353, 11, 291, 383, 297, 345, 379, 370, 3525,
          221, 380, 223, 263, 261, 2324, 220, 299, 343, 376, 11, 3482, 362, 358,
          2966, 341, 387, 294, 2252, 264, 266, 263, 381, 336, 260, 388, 342,
          2954, 385, 3756,
        ],
        default: true,
      },
      {
        prefix: "+374",
        size: [8],
        code: "AM",
        name_EN: "Armenia",
        name_own: "Հայաստան",
        localPrefixes: [
          5, 6, 7, 9, 10, 222, 237, 235, 232, 268, 231, 256, 264, 284, 312, 223,
          263, 285, 261, 255, 47, 322, 281,
        ],
        default: true,
      },
      {
        prefix: "+297",
        size: [7],
        code: "AW",
        name_EN: "Aruba",
        localPrefixes: [2, 5, 9],
        default: true,
      },
      {
        prefix: "+61",
        size: [7, 9, 10],
        code: "AU",
        name_EN: "Australia",
        localPrefixes: [4, 2, 8, 3, 7, 8, 13, 18],
        default: true,
      },
      {
        prefix: "+43",
        size: [4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
        code: "AT",
        name_EN: "Austria",
        name_own: "Österreich",
        localPrefixes: [
          6, 650, 651, 652, 653, 655, 657, 659, 660, 661, 663, 664, 665, 666,
          667, 668, 669, 67, 68, 69, 1, 7472, 2252, 5574, 5572, 2685, 5522,
          7612, 316, 512, 3862, 463, 2243, 3512, 2732, 3842, 732, 662, 2742,
          7252, 2630, 7229, 4242, 7672, 7542, 2622, 4352,
        ],
        default: true,
      },
      {
        prefix: "+994",
        size: [9],
        code: "AZ",
        name_EN: "Azerbaijan",
        name_own: "Azərbaycan",
        localPrefixes: [
          347, 40, 44, 50, 51, 55, 60, 70, 77, 12, 26, 20, 22, 25, 24, 36, 21,
          18, 23,
        ],
        default: true,
      },
      {
        prefix: "+1",
        size: [10],
        code: "BS",
        name_EN: "Bahamas",
        localPrefixes: [242],
      },
      {
        prefix: "+973",
        size: [8],
        code: "BH",
        name_EN: "Bahrain",
        name_own: "البحرين",
        localPrefixes: [3, 6],
        default: true,
      },
      {
        prefix: "+880",
        size: [4, 5, 6, 7, 8, 9, 10, 11],
        code: "BD",
        name_EN: "Bangladesh",
        name_own: "বাংলাদেশ",
        localPrefixes: [
          1, 2, 431, 51, 851, 31, 81, 341, 531, 681, 421, 41, 488, 691, 91, 2,
          628, 6225, 321, 731, 721, 521, 552, 821, 921,
        ],
        default: true,
      },
      {
        prefix: "+1",
        size: [10],
        code: "BB",
        name_EN: "Barbados",
        localPrefixes: [246],
      },
      {
        prefix: "+375",
        size: [7, 8, 9, 10, 11],
        code: "BY",
        name_EN: "Belarus",
        name_own: "Беларусь",
        localPrefixes: [
          25, 29, 33, 44, 17, 225, 163, 177, 16, 23, 15, 236, 22, 176, 216, 165,
          214, 174, 21,
        ],
        default: true,
      },
      {
        prefix: "+32",
        size: [7, 8, 9],
        code: "BE",
        name_EN: "Belgium",
        name_own: "België",
        localPrefixes: [4, 2, 53, 3, 50, 71, 9, 11, 56, 64, 16, 15, 65, 81],
        default: true,
      },
      {
        prefix: "+501",
        size: [7],
        code: "BZ",
        name_EN: "Belize",
        localPrefixes: [6, 8, 2, 4, 5, 3, 7],
        default: true,
      },
      {
        prefix: "+229",
        size: [8],
        code: "BJ",
        name_EN: "Benin",
        name_own: "Bénin",
        localPrefixes: [9, 21, 22, 23],
        default: true,
      },
      {
        prefix: "+1",
        size: [10],
        code: "BM",
        name_EN: "Bermuda",
        localPrefixes: [441],
      },
      {
        prefix: "+975",
        size: [7, 8],
        code: "BT",
        name_EN: "Bhutan",
        name_own: "Druk Yul",
        localPrefixes: [17, 2, 8, 5, 7],
        default: true,
      },
      {
        prefix: "+591",
        size: [8],
        code: "BO",
        name_EN: "Bolivia",
        localPrefixes: [7, 4, 3, 2],
        default: true,
      },
      {
        prefix: "+387",
        size: [8],
        code: "BA",
        name_EN: "Bosnia And Herzegovina",
        name_own: "Bosna i Hercegovina",
        localPrefixes: [6, 33, 51, 37, 55, 49, 53, 36, 52, 35, 32],
        default: true,
      },
      {
        prefix: "+267",
        size: [7, 8],
        code: "BW",
        name_EN: "Botswana",
        localPrefixes: [7, 3, 2, 54, 47, 68, 59, 26, 46],
        default: true,
      },
      {
        prefix: "+55",
        size: [8, 10, 11],
        code: "BR",
        name_EN: "Brazil",
        name_own: "Brasil",
        localPrefixes: [
          436, 437, 438, 439, 966, 967, 968, 969, 826, 827, 828, 829, 926, 927,
          928, 929, 616, 617, 618, 619, 916, 917, 918, 919, 796, 797, 798, 799,
          146, 147, 148, 149, 316, 317, 318, 319, 956, 957, 958, 959, 676, 677,
          678, 679, 516, 517, 518, 519, 116, 117, 118, 119, 276, 277, 278, 279,
          546, 547, 548, 549, 656, 657, 658, 659, 416, 417, 418, 419, 216, 217,
          218, 219, 976, 977, 978, 979, 756, 757, 758, 759, 486, 487, 488, 489,
          856, 857, 858, 859, 166, 167, 168, 169, 626, 627, 628, 629, 836, 837,
          838, 839, 476, 477, 478, 479, 326, 327, 328, 329, 446, 447, 448, 449,
          386, 387, 388, 389, 846, 847, 848, 849, 816, 817, 818, 819, 636, 637,
          638, 639, 246, 247, 248, 249, 196, 197, 198, 199, 696, 697, 698, 699,
          186, 187, 188, 189, 516, 517, 518, 519, 686, 687, 688, 689, 716, 717,
          718, 719, 986, 987, 988, 989, 136, 137, 138, 139, 176, 177, 178, 179,
          126, 127, 128, 129, 156, 157, 158, 159, 866, 867, 868, 869, 346, 347,
          348, 349,
        ],
        default: true,
      },
      {
        prefix: "+1",
        size: [10],
        code: "VG",
        name_EN: "British Virgin Islands",
        localPrefixes: [284],
      },
      {
        prefix: "+673",
        size: [7],
        code: "BN",
        name_EN: "Brunei",
        localPrefixes: [8, 2, 3, 5, 4],
        default: true,
      },
      {
        prefix: "+359",
        size: [7, 8, 9],
        code: "BG",
        name_EN: "Bulgaria",
        name_own: "България",
        localPrefixes: [
          87, 2, 56, 73, 58, 66, 751, 38, 361, 431, 78, 68, 96, 34, 76, 64, 32,
          84, 82, 54, 86, 44, 301, 42, 601, 52, 62, 94, 92, 46,
        ],
        default: true,
      },
      {
        prefix: "+226",
        size: [8],
        code: "BF",
        name_EN: "Burkina Faso",
        localPrefixes: [7, 6, 50, 20, 40],
        default: true,
      },
      {
        prefix: "+257",
        size: [8],
        code: "BI",
        name_EN: "Burundi",
        localPrefixes: [22, 7],
        default: true,
      },
      {
        prefix: "+855",
        size: [8, 9],
        code: "KH",
        name_EN: "Cambodia",
        localPrefixes: [
          1, 23, 53, 42, 25, 33, 26, 62, 55, 54, 43, 52, 75, 63, 34, 54, 23,
        ],
        default: true,
      },
      {
        prefix: "+237",
        size: [9],
        code: "CM",
        name_EN: "Cameroon",
        name_own: "Cameroun",
        localPrefixes: [6, 222, 333],
        default: true,
      },
      {
        prefix: "+1",
        size: [10],
        code: "CA",
        name_EN: "Canada",
        localPrefixes: [
          613, 343, 403, 587, 825, 902, 418, 581, 780, 506, 819, 873, 905, 289,
          365, 519, 226, 548, 450, 579, 514, 438, 306, 639, 709, 639, 807, 416,
          647, 437, 604, 778, 236, 250, 867, 204, 431,
        ],
      },
      {
        prefix: "+238",
        size: [7],
        code: "CV",
        name_EN: "Cape Verde",
        name_own: "Cabo Verde",
        localPrefixes: [2, 9],
        default: true,
      },
      {
        prefix: "+1",
        size: [10],
        code: "KY",
        name_EN: "Cayman Islands",
        localPrefixes: [345],
      },
      {
        prefix: "+236",
        size: [8],
        code: "CF",
        name_EN: "Central African Republic",
        name_own: "République centrafricaine",
        localPrefixes: [7, 21, 22],
        default: true,
      },
      {
        prefix: "+235",
        size: [8],
        code: "TD",
        name_EN: "Chad",
        name_own: "Tchad",
        localPrefixes: [22, 6],
        default: true,
      },
      {
        prefix: "+56",
        size: [8, 9],
        code: "CL",
        name_EN: "Chile",
        localPrefixes: [
          9, 2, 57, 55, 58, 41, 42, 52, 51, 67, 75, 32, 73, 34, 43, 64, 53, 65,
          61, 33, 32, 72, 35, 71, 45, 63,
        ],
        default: true,
      },
      {
        prefix: "+86",
        size: [8, 9, 10, 11],
        code: "CN",
        name_EN: "China",
        name_own: "中國",
        localPrefixes: [
          13, 10, 431, 731, 28, 23, 411, 769, 757, 591, 20, 851, 571, 451, 551,
          752, 871, 931, 578, 379, 791, 25, 574, 532, 595, 21, 754, 24, 755,
          311, 557, 512, 351, 315, 22, 991, 577, 27, 510, 29, 516, 535, 371,
          533,
        ],
        default: true,
      },
      {
        prefix: "+57",
        size: [8, 10],
        code: "CO ",
        name_EN: "Colombia",
        localPrefixes: [3, 1, 4, 7, 6, 5, 2, 8],
        default: true,
      },
      {
        prefix: "+269",
        size: [7],
        code: "KM",
        name_EN: "Comoros",
        name_own: "جزر القمر",
        localPrefixes: [3],
        default: true,
      },
      {
        prefix: "+682",
        size: [5],
        code: "CK",
        name_EN: "Cook Islands",
        localPrefixes: [5, 2, 31, 33, 34, 43, 35, 36, 45, 37, 42, 41, 44],
        default: true,
      },
      {
        prefix: "+506",
        size: [8],
        code: "CR",
        name_EN: "Costa Rica",
        localPrefixes: [8, 5, 6, 7],
        default: true,
      },
      {
        prefix: "+385",
        size: [8, 9],
        code: "HR",
        name_EN: "Croatia",
        name_own: "Hrvatska",
        localPrefixes: [
          9, 1, 43, 40, 20, 53, 47, 48, 49, 31, 52, 34, 51, 22, 44, 35, 21, 42,
          32, 33, 23,
        ],
        default: true,
      },
      {
        prefix: "+53",
        size: [6, 7, 8],
        code: "CU",
        name_EN: "Cuba",
        localPrefixes: [
          5, 7, 47, 99, 23, 32, 45, 33, 43, 22, 21, 24, 31, 46, 48, 41, 42,
        ],
        default: true,
      },
      {
        prefix: "+599",
        size: [7, 8],
        code: "CW",
        name_EN: "Curacao",
        localPrefixes: [],
        default: true,
      },
      {
        prefix: "+357",
        size: [8],
        code: "CY",
        name_EN: "Cyprus",
        name_own: "Κύπρος",
        localPrefixes: [99, 22, 23, 24, 25, 26],
        default: true,
      },
      {
        prefix: "+420",
        size: [8, 9, 10],
        code: "CZ",
        name_EN: "Czech Republic",
        name_own: "Česká republika",
        localPrefixes: [
          6, 7, 2, 51, 53, 54, 38, 39, 49, 56, 35, 48, 58, 55, 59, 46, 37, 31,
          32, 41, 47, 57,
        ],
        default: true,
      },
      {
        prefix: "+243",
        size: [7, 9],
        code: "CD",
        name_EN: "Democratic Republic of the Congo",
        name_own: "République Démocratique du Congo",
        localPrefixes: [8, 9, 1, 3, 6, 5, 4, 2],
        default: true,
      },
      {
        prefix: "+45",
        size: [8],
        code: "DK",
        name_EN: "Denmark",
        name_own: "Danmark",
        localPrefixes: [
          40, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 40, 41, 42, 50,
          51, 52, 53, 60, 61, 71, 81, 91, 92, 93,
        ],
        default: true,
      },
      {
        prefix: "+253",
        size: [8],
        code: "DJ",
        name_EN: "Djibouti",
        localPrefixes: [21, 77],
        default: true,
      },
      {
        prefix: "+1",
        size: [10],
        code: "DM",
        name_EN: "Dominica",
        localPrefixes: [767],
      },
      {
        prefix: "+1",
        size: [7, 10],
        code: "DO",
        name_EN: "Dominican Republic",
        name_own: "República Dominicana",
        localPrefixes: [809, 829, 849],
      },
      {
        prefix: "+670",
        size: [7, 8],
        code: "TL",
        name_EN: "East Timor",
        name_own: "Timor Leste",
        localPrefixes: [3, 7],
        default: true,
      },
      {
        prefix: "+593",
        size: [8, 9],
        code: "EC",
        name_EN: "Ecuador",
        localPrefixes: [9, 2, 3, 7, 5, 4, 6],
        default: true,
      },
      {
        prefix: "+20",
        size: [8, 9, 10],
        code: "EG",
        name_EN: "Egypt",
        name_own: "مصر",
        localPrefixes: [
          1, 2, 3, 88, 97, 13, 82, 45, 57, 68, 92, 50, 69, 84, 65, 64, 47, 95,
          46, 86, 66, 96, 48, 62, 93, 40, 55,
        ],
        default: true,
      },
      {
        prefix: "+503",
        size: [8],
        code: "SV",
        name_EN: "El Salvador",
        localPrefixes: [2, 7],
        default: true,
      },
      {
        prefix: "+240",
        size: [9],
        code: "GQ",
        name_EN: "Equatorial Guinea",
        name_own: "Guinea Ecuatorial",
        localPrefixes: [3, 222, 551],
        default: true,
      },
      {
        prefix: "+291",
        size: [7],
        code: "ER",
        name_EN: "Eritrea",
        localPrefixes: [1, 7, 17],
        default: true,
      },
      {
        prefix: "+372",
        size: [7, 8],
        code: "EE",
        name_EN: "Estonia",
        name_own: "Eesti",
        localPrefixes: [5, 8],
        default: true,
      },
      {
        prefix: "+251",
        size: [9],
        code: "ET",
        name_EN: "Ethiopia",
        name_own: "ኢትዮጵያ",
        localPrefixes: [91, 11, 22, 57, 46, 58, 33, 25, 47, 34],
        default: true,
      },
      {
        prefix: "+500",
        size: [5],
        code: "FK",
        name_EN: "Falkland Islands",
        localPrefixes: [5, 6],
        default: true,
      },
      {
        prefix: "+298",
        size: [6],
        code: "FO",
        name_EN: "Faroe Islands",
        name_own: "Færøerne",
        localPrefixes: [],
        default: true,
      },
      {
        prefix: "+679",
        size: [7, 9],
        code: "FJ",
        name_EN: "Fiji",
        localPrefixes: [9, 7, 32, 60, 67, 34],
        default: true,
      },
      {
        prefix: "+358",
        size: [8, 9],
        code: "FI",
        name_EN: "Finland",
        name_own: "Suomi",
        localPrefixes: [4, 50, 9, 3, 14, 17, 5, 16, 18, 15, 8, 13, 2, 19, 6],
        default: true,
      },
      {
        prefix: "+33",
        size: [9],
        code: "FR",
        name_EN: "France",
        localPrefixes: [6, 7, 1, 4, 3, 2, 5, 495],
        default: true,
      },
      {
        prefix: "+689",
        size: [6],
        code: "PF",
        name_EN: "French Polynesia",
        name_own: "Polynésie française",
        localPrefixes: [2, 3, 4, 7],
        default: true,
      },
      {
        prefix: "+241",
        size: [7],
        code: "GA",
        name_EN: "Gabon",
        localPrefixes: [1, 2, 3, 4, 5, 6, 7],
        default: true,
      },
      {
        prefix: "+220",
        size: [7],
        code: "GM",
        name_EN: "Gambia",
        localPrefixes: [2, 3, 6, 7, 9, 42, 448, 43],
        default: true,
      },
      {
        prefix: "+995",
        size: [8, 9, 10],
        code: "GE",
        name_EN: "Georgia",
        name_own: "საქართველო",
        localPrefixes: [
          5, 7, 32, 422, 370, 331, 357, 393, 341, 311, 413, 122, 315,
        ],
        default: true,
      },
      {
        prefix: "+49",
        size: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
        code: "DE",
        name_EN: "Germany",
        name_own: "Deutschland",
        localPrefixes: [
          1, 30, 241, 821, 521, 234, 228, 3381, 421, 531, 371, 221, 231, 351,
          203, 211, 361, 201, 69, 761, 209, 345, 40, 511, 721, 431, 2151, 341,
          214, 451, 391, 6131, 621, 2161, 89, 251, 911, 208, 331, 381, 681, 385,
          711, 611, 202,
        ],
        default: true,
      },
      {
        prefix: "+233",
        size: [9],
        code: "GH",
        name_EN: "Ghana",
        localPrefixes: [2, 5, 30, 33, 36, 34, 32, 31, 37, 35, 39],
        default: true,
      },
      {
        prefix: "+350",
        size: [8],
        code: "GI",
        name_EN: "Gibraltar",
        localPrefixes: [200, 5],
        default: true,
      },
      {
        prefix: "+30",
        size: [8, 9, 10, 11, 12],
        code: "GR",
        name_EN: "Greece",
        name_own: "Ελλάδα",
        localPrefixes: [
          6, 21, 2641, 2223, 2821, 2661, 2741, 281, 2381, 2691, 2281, 281, 2651,
          2721, 2377, 2531, 2461, 2231, 241, 2632, 2251, 261, 2621, 2831, 2241,
          2841, 2321, 231, 271, 2421,
        ],
        default: true,
      },
      {
        prefix: "+299",
        size: [6],
        code: "GL",
        name_EN: "Greenland",
        name_own: "Grænland",
        localPrefixes: [2, 4, 5, 31, 32, 33, 34, 35, 36, 94, 64, 85, 86],
        default: true,
      },
      {
        prefix: "+1",
        size: [10],
        code: "GD",
        name_EN: "Grenada",
        localPrefixes: [473],
      },
      {
        prefix: "+1",
        size: [10],
        code: "GU",
        name_EN: "Guam",
        localPrefixes: [671],
      },
      {
        prefix: "+502",
        size: [8],
        code: "GT",
        name_EN: "Guatemala",
        localPrefixes: [4, 5, 2, 6, 7],
        default: true,
      },
      {
        prefix: "+224",
        size: [8],
        code: "GN",
        name_EN: "Guinea",
        name_own: "Guinée",
        localPrefixes: [30, 6],
        default: true,
      },
      {
        prefix: "+245",
        size: [7],
        code: "GW",
        name_EN: "Guinea-Bissau",
        name_own: "Guiné-Bissau",
        localPrefixes: [3, 5, 6, 7],
        default: true,
      },
      {
        prefix: "+592",
        size: [7],
        code: "GY",
        name_EN: "Guyana",
        localPrefixes: [
          6, 223, 225, 226, 227, 231, 218, 219, 263, 771, 455, 339, 444, 228,
          456, 333, 334, 260, 262, 330, 264,
        ],
        default: true,
      },
      {
        prefix: "+509",
        size: [8],
        code: "HT",
        name_EN: "Haiti",
        name_own: "Ayiti",
        localPrefixes: [2, 3, 4],
        default: true,
      },
      {
        prefix: "+504",
        size: [8],
        code: "HN",
        name_EN: "Honduras",
        localPrefixes: [3, 7, 8, 9, 22, 28, 26, 27, 24, 25],
        default: true,
      },
      {
        prefix: "+852",
        size: [8],
        code: "HK",
        name_EN: "Hong Kong",
        name_own: "香港",
        localPrefixes: [5, 6, 9],
        default: true,
      },
      {
        prefix: "+36",
        size: [7, 8, 9],
        code: "HU",
        name_EN: "Hungary",
        name_own: "Magyarország",
        localPrefixes: [20, 30, 70, 1, 52, 25, 96, 76, 46, 42, 72, 62, 22, 34],
        default: true,
      },
      {
        prefix: "+354",
        size: [7],
        code: "IS",
        name_EN: "Iceland",
        name_own: "Ísland",
        localPrefixes: [3, 6, 7, 8, 5, 4],
        default: true,
      },
      {
        prefix: "+91",
        size: [8, 9, 10, 11, 12],
        code: "IN",
        name_EN: "India",
        name_own: "भारत",
        localPrefixes: [
          9, 11, 381, 562, 79, 389, 571, 532, 183, 341, 240, 80, 2522, 757, 674,
          22, 33, 172, 44, 422, 671, 135, 3862, 129, 79, 3592, 120, 361, 751,
          33, 836, 40, 3852, 731, 761, 141, 181, 657, 291, 512, 522, 161, 121,
          821, 712, 253, 832, 612, 20, 3192, 771, 281, 651, 427, 177, 217, 194,
          261, 471, 431, 265, 542, 866, 891, 7152,
        ],
        default: true,
      },
      {
        prefix: "+62",
        size: [7, 8, 9, 10, 11],
        code: "ID",
        name_EN: "Indonesia",
        localPrefixes: [
          8, 21, 911, 542, 651, 721, 22, 511, 251, 282, 231, 361, 262, 435, 741,
          967, 401, 380, 411, 341, 431, 962, 370, 61, 751, 536, 711, 451, 761,
          31, 24, 541, 561, 271, 771, 274,
        ],
        default: true,
      },
      {
        prefix: "+98",
        size: [9, 10, 11],
        code: "IR",
        name_EN: "Iran",
        name_own: "ایران",
        localPrefixes: [
          9, 21, 221, 631, 632, 611, 612, 121, 122, 861, 862, 451, 452, 111,
          112, 761, 763, 561, 562, 584, 585, 662, 665, 771, 772, 773, 641, 642,
          228, 229, 21, 172, 174, 171, 173, 811, 812, 841, 842, 311, 312, 661,
          663, 261, 262, 361, 362, 341, 342, 831, 832, 461, 462, 442, 444, 851,
          852, 421, 422, 728, 729, 511, 512, 331, 332, 551, 552, 292, 281, 282,
          251, 252, 391, 392, 131, 132, 571, 572, 871, 872, 874, 875, 151, 152,
          255, 256, 231, 232, 381, 382, 273, 274, 711, 712, 345, 347, 411, 412,
          441, 443, 291, 292, 741, 743, 351, 352, 353, 542, 544, 541, 543, 241,
          242,
        ],
        default: true,
      },
      {
        prefix: "+964",
        size: [7, 8, 9, 10],
        code: "IQ",
        name_EN: "Iraq",
        localPrefixes: [
          7, 1, 43, 30, 37, 25, 40, 36, 62, 66, 32, 50, 23, 60, 42, 33, 24, 53,
          21,
        ],
        default: true,
      },
      {
        prefix: "+353",
        size: [8, 9, 10],
        code: "IE",
        name_EN: "Ireland",
        name_own: "Éireann",
        localPrefixes: [
          8, 1, 402, 90, 54, 62, 59, 21, 66, 74, 41, 42, 65, 91, 45, 56, 64, 61,
          45, 46, 45, 71, 91, 66, 51, 53,
        ],
        default: true,
      },
      {
        prefix: "+972",
        size: [8, 9],
        code: "IL",
        name_EN: "Israel",
        name_own: "ישראל",
        localPrefixes: [5, 2, 8, 4, 9, 3],
        default: true,
      },
      {
        prefix: "+39",
        size: [8, 9, 10, 11, 12],
        code: "IT",
        name_EN: "Italy",
        name_own: "Italia",
        localPrefixes: [
          "3",
          "06",
          "071",
          "080",
          "035",
          "051",
          "030",
          "0831",
          "070",
          "081",
          "0823",
          "095",
          "031",
          "0984",
          "0532",
          "055",
          "0881",
          "0543",
          "010",
          "0773",
          "0832",
          "0586",
          "0465",
          "0884",
          "090",
          "02",
          "39",
          "081",
          "0321",
          "049",
          "091",
          "0521",
          "0382",
          "075",
          "085",
          "050",
          "0574",
          "0544",
          "0522",
          "0965",
          "0541",
          "089",
          "079",
          "019",
          "0931",
          "099",
          "0744",
          "011",
          "0422",
          "040",
          "0432",
          "0332",
          "041",
          "045",
          "0444",
        ],
        default: true,
      },
      {
        prefix: "+225",
        size: [8],
        code: "CI",
        name_EN: "Ivory Coast",
        name_own: "Côte d'Ivoire",
        localPrefixes: [0, 30, 21, 24, 31, 32, 36, 20, 34],
        default: true,
      },
      {
        prefix: "+1",
        size: [10],
        code: "JM",
        name_EN: "Jamaica",
        localPrefixes: [876],
      },
      {
        prefix: "+81",
        size: [5, 6, 7, 8, 9, 10, 11, 12, 13],
        code: "JP",
        name_EN: "Japan",
        name_own: "日本",
        localPrefixes: [
          70, 80, 90, 3, 18, 6, 17, 166, 43, 466, 92, 84, 47, 58, 42, 53, 6, 79,
          72, 82, 586, 246, 99, 76, 4, 49, 48, 44, 75, 93, 78, 88, 24, 48, 96,
          86, 942, 42, 27, 89, 985, 19, 26, 95, 52, 98, 742, 25, 798, 97, 86,
          564, 77, 42, 11, 22, 54, 87, 532, 565, 59, 28, 73, 45, 46,
        ],
        default: true,
      },
      {
        prefix: "+962",
        size: [8, 9],
        code: "JO",
        name_EN: "Jordan",
        name_own: "الأردن",
        localPrefixes: [7, 6, 2, 3, 5],
        default: true,
      },
      {
        prefix: "+7",
        size: [10],
        code: "KZ",
        name_EN: "Kazakhstan",
        name_own: "Қазақстан",
        localPrefixes: [
          70, 76, 77, 78, 717, 721, 729, 713, 727, 712, 718, 721, 716, 714, 724,
          711, 723, 718, 715, 722, 725, 728, 726, 721, 710,
        ],
      },
      {
        prefix: "+254",
        size: [7, 8, 9],
        code: "KE",
        name_EN: "Kenya",
        localPrefixes: [
          7, 20, 53, 46, 56, 52, 58, 57, 54, 44, 42, 64, 41, 51, 61, 67, 46,
        ],
        default: true,
      },
      {
        prefix: "+686",
        size: [5],
        code: "KI",
        name_EN: "Kiribati",
        localPrefixes: [
          6, 9, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 81, 82, 34,
          47, 45,
        ],
        default: true,
      },
      {
        prefix: "+383",
        size: [8],
        code: "XK",
        name_EN: "Kosovo",
        name_own: "Kosovë",
        localPrefixes: [],
        default: true,
      },
      {
        prefix: "+965",
        size: [8],
        code: "KW",
        name_EN: "Kuwait",
        name_own: "الكويت",
        localPrefixes: [2, 6, 9, 5],
        default: true,
      },
      {
        prefix: "+996",
        size: [8, 9, 10],
        code: "KG",
        name_EN: "Kyrgyzstan",
        name_own: "Кыргызстан",
        localPrefixes: [
          5, 7, 312, 315, 3622, 3660, 3736, 3762, 3722, 3760, 3132, 3162, 3922,
          3960, 3139, 3166, 3522, 3560, 3655, 3662, 3222, 3260, 3134, 3164,
          3748, 3775, 3422, 3460, 3233, 3266,
        ],
        default: true,
      },
      {
        prefix: "+856",
        size: [8, 10],
        code: "LA",
        name_EN: "Laos",
        name_own: "ກຸຍກີສະຕາລ້າ",
        localPrefixes: [20, 21, 71, 31, 54, 34, 41, 51, 64],
        default: true,
      },
      {
        prefix: "+371",
        size: [7, 8, 9],
        code: "LV",
        name_EN: "Latvia",
        name_own: "Latvija",
        localPrefixes: [2, 66, 67, 654, 630, 67, 634, 650, 646, 642, 635],
        default: true,
      },
      {
        prefix: "+961",
        size: [7, 8],
        code: "LB",
        name_EN: "Lebanon",
        name_own: "لبنان",
        localPrefixes: [3, 7, 1, 5, 4, 8, 9, 6],
        default: true,
      },
      {
        prefix: "+266",
        size: [8],
        code: "LS",
        name_EN: "Lesotho",
        localPrefixes: [22, 5, 6],
        default: true,
      },
      {
        prefix: "+231",
        size: [7, 8],
        code: "LR",
        name_EN: "Liberia",
        localPrefixes: [2, 4, 5, 6, 7],
        default: true,
      },
      {
        prefix: "+218",
        size: [7, 8, 9, 10],
        code: "LY",
        name_EN: "Libya",
        name_own: "ليبيا",
        localPrefixes: [9, 21, 84, 652, 61, 41, 57, 51, 71, 87, 727],
        default: true,
      },
      {
        prefix: "+423",
        size: [7],
        code: "LI",
        name_EN: "Liechtenstein",
        localPrefixes: [7],
        default: true,
      },
      {
        prefix: "+370",
        size: [8],
        code: "LT",
        name_EN: "Lituania",
        localPrefixes: [
          6, 52, 315, 381, 450, 313, 528, 46, 386, 349, 426, 447, 346, 37, 427,
          464, 445, 343, 443, 421, 454, 455, 451, 448, 319, 422, 428, 458, 345,
          380, 41, 449441, 387, 446, 444, 528, 340, 389, 310, 342, 386,
        ],
        default: true,
      },
      {
        prefix: "+352",
        size: [6, 7, 8, 9, 10, 11, 12],
        code: "LU",
        name_EN: "Luxembourg",
        name_own: "Lëtzebuerg",
        localPrefixes: [
          6, 2422, 22, 2428, 2622, 2627, 2628, 2722, 2728, 28, 4, 80, 2480,
          2680, 2780, 58, 2458, 2658, 2758, 51, 2451, 2452, 52, 2467, 2651,
          2652, 2667, 2751, 2752, 2767, 53, 54, 55, 57, 2453, 2454, 2455, 2457,
          2653, 2654, 2655, 265, 32, 2432, 2632, 2732, 50, 2650, 2365, 2665,
          2776,
        ],
        default: true,
      },
      {
        prefix: "+853",
        size: [8],
        code: "MO",
        name_EN: "Macau",
        name_own: "澳門",
        localPrefixes: [6],
        default: true,
      },
      {
        prefix: "+389",
        size: [7, 8, 9],
        code: "MK",
        name_EN: "Macedonia",
        name_own: "Македонија",
        localPrefixes: [7, 2, 47, 42, 43, 33, 31, 46, 48, 32, 34, 44, 43],
        default: true,
      },
      {
        prefix: "+261",
        size: [7, 9],
        code: "MG",
        name_EN: "Madagascar",
        name_own: "Madagasikara",
        localPrefixes: [
          3, 202, 2054, 204, 2044, 2082, 2073, 2075, 2062, 2072, 2053, 2092,
          2094,
        ],
        default: true,
      },
      {
        prefix: "+265",
        size: [7, 8, 9],
        code: "MW",
        name_EN: "Malawi",
        localPrefixes: [1, 77],
        default: true,
      },
      {
        prefix: "+60",
        size: [7, 8, 9, 10],
        code: "MY",
        name_EN: "Malaysia",
        localPrefixes: [1, 3, 5, 7, 9, 88, 82, 89, 6, 89],
        default: true,
      },
      {
        prefix: "+960",
        size: [7],
        code: "MV",
        name_EN: "Maldives",
        name_own: "মালদ্বীপ",
        localPrefixes: [7, 9, 3, 6],
        default: true,
      },
      {
        prefix: "+223",
        size: [8],
        code: "ML",
        name_EN: "Mali",
        localPrefixes: [6, 7, 20, 44, 218, 212, 215, 214, 213, 216, 219],
        default: true,
      },
      {
        prefix: "+356",
        size: [8],
        code: "MT",
        name_EN: "Malta",
        localPrefixes: [79, 99],
        default: true,
      },
      {
        prefix: "+692",
        size: [7],
        code: "MH",
        name_EN: "Marshall Islands",
        localPrefixes: [],
        default: true,
      },
      {
        prefix: "+222",
        size: [8],
        code: "MR",
        name_EN: "Mauritania",
        name_own: "موريتانيا",
        localPrefixes: [],
        default: true,
      },
      {
        prefix: "+230",
        size: [7, 8],
        code: "MU",
        name_EN: "Mauritius",
        localPrefixes: [],
        default: true,
      },
      {
        prefix: "+262",
        size: [9],
        code: "YT",
        name_EN: "Mayotte",
        localPrefixes: [269, 639],
      },
      {
        prefix: "+52",
        size: [9, 10, 11],
        code: "MX",
        name_EN: "Mexico",
        localPrefixes: [
          1, 55, 744, 449, 833, 81, 624, 981, 998, 461, 983, 614, 747, 877, 656,
          871, 644, 834, 312, 963, 271, 777, 667, 646, 33, 473, 662, 715, 612,
          477, 314, 868, 999, 686, 81, 443, 867, 951, 771, 984, 222, 322, 899,
          464, 844, 967, 444, 615, 442, 665, 311, 664, 246, 722, 961, 452, 985,
          457, 229, 618, 993, 228, 492, 351,
        ],
        default: true,
      },
      {
        prefix: "+691",
        size: [7],
        code: "FM",
        name_EN: "Micronesia",
        localPrefixes: [9, 32, 92, 33, 93, 94, 35, 95, 96],
        default: true,
      },
      {
        prefix: "+373",
        size: [7, 8, 9],
        code: "MD",
        name_EN: "Moldova",
        localPrefixes: [
          6, 7, 22, 231, 552, 299, 291, 298, 215, 246, 259, 235, 555, 230, 237,
          294, 533, 236,
        ],
        default: true,
      },
      {
        prefix: "+377",
        size: [8, 9],
        code: "MC",
        name_EN: "Monaco",
        localPrefixes: [9, 4, 6],
        default: true,
      },
      {
        prefix: "+976",
        size: [5, 7, 8, 9, 10],
        code: "MN",
        name_EN: "Mongolia",
        name_own: "Монгол",
        localPrefixes: [5, 8, 9, 1, 48, 58, 37, 35, 43, 38],
        default: true,
      },
      {
        prefix: "+382",
        size: [8],
        code: "ME",
        name_EN: "Montenegro",
        name_own: "Монтенегро",
        localPrefixes: [6, 20, 30, 51, 50, 33, 41, 32, 31, 40, 52, 32],
        default: true,
      },
      {
        prefix: "+1",
        size: [10],
        code: "MS",
        name_EN: "Montserrat",
        localPrefixes: [664],
      },
      {
        prefix: "+212",
        size: [9],
        code: "MA",
        name_EN: "Morocco",
        name_own: "المغرب",
        localPrefixes: [6, 53, 52],
        default: true,
      },
      {
        prefix: "+258",
        size: [8, 9],
        code: "MZ",
        name_EN: "Mozambique",
        name_own: "Moçambique",
        localPrefixes: [8, 21, 23, 281, 293, 271, 251, 26, 272, 24, 252, 282],
        default: true,
      },
      {
        prefix: "+95",
        size: [6, 7, 8, 9, 10],
        code: "MM",
        name_EN: "Myanmar",
        name_own: "မြန်မာ",
        localPrefixes: [9, 67, 52, 59, 2, 57, 64, 59, 71, 42, 53, 43, 1],
        default: true,
      },
      {
        prefix: "+264",
        size: [6, 7, 8, 9, 10],
        code: "NA",
        name_EN: "Namibia",
        localPrefixes: [8, 61, 67, 66, 63, 62, 65, 64],
        default: true,
      },
      {
        prefix: "+674",
        size: [7],
        code: "NR",
        name_EN: "Nauru",
        localPrefixes: [444, 55],
        default: true,
      },
      {
        prefix: "+977",
        size: [7, 8, 9, 10],
        code: "NP",
        name_EN: "Nepal",
        name_own: "नेपाल",
        localPrefixes: [98, 1, 56, 21, 51, 71, 91, 25, 41, 61],
        default: true,
      },
      {
        prefix: "+31",
        size: [9],
        code: "NL",
        name_EN: "Netherlands",
        name_own: "Nederland",
        localPrefixes: [
          6, 20, 72, 36, 172, 33, 55, 26, 73, 76, 15, 570, 78, 318, 40, 591, 53,
          50, 23, 45, 492, 35, 58, 71, 43, 174, 24, 10, 46, 70, 13, 30, 77, 75,
          79, 38,
        ],
        default: true,
      },
      {
        prefix: "+599",
        size: [7, 8],
        code: "AN",
        name_EN: "Netherlands Antilles",
        name_own: "Nederlandse Antillen",
        localPrefixes: [9, 7, 4, 3],
      },
      {
        prefix: "+687",
        size: [6],
        code: "NC",
        name_EN: "New Caledonia",
        name_own: "Nouvelle Calédonie",
        localPrefixes: [7, 8, 9],
        default: true,
      },
      {
        prefix: "+64",
        size: [8, 10],
        code: "NZ",
        name_EN: "New Zealand",
        name_own: "I Niu Tireni",
        localPrefixes: [2, 4, 9, 3, 7, 6, 2409],
        default: true,
      },
      {
        prefix: "+505",
        size: [8],
        code: "NI",
        name_EN: "Nicaragua",
        localPrefixes: [8, 22, 24, 25, 23, 27],
        default: true,
      },
      {
        prefix: "+227",
        size: [8],
        code: "NE",
        name_EN: "Niger",
        localPrefixes: [20, 9],
        default: true,
      },
      {
        prefix: "+234",
        size: [6, 7, 8, 9, 10],
        code: "NG",
        name_EN: "Nigeria",
        localPrefixes: [
          70, 80, 81, 9, 43, 30, 34, 56, 48, 77, 52, 68, 87, 74, 64, 42, 72, 63,
          2, 1, 31, 79, 73, 62, 64, 65, 47, 58, 76, 44, 66, 35, 83, 84, 60, 88,
          85, 89, 75, 69,
        ],
        default: true,
      },
      {
        prefix: "+683",
        size: [4],
        code: "NU",
        name_EN: "Niue",
        localPrefixes: [3, 4, 1, 2, 5, 6],
        default: true,
      },
      {
        prefix: "+850",
        size: [8, 9, 10],
        code: "KP",
        name_EN: "North Korea",
        name_own: "북한",
        localPrefixes: [191, 2, 73, 53, 49, 39, 41, 61, 57],
        default: true,
      },
      {
        prefix: "+1",
        size: [10],
        code: "MP",
        name_EN: "Northern Mariana Islands",
        localPrefixes: [670],
      },
      {
        prefix: "+47",
        size: [7, 8, 9],
        code: "NO",
        name_EN: "Norway",
        name_own: "Norge",
        localPrefixes: [4, 9, 2, 7, 37, 5, 3, 35, 69, 62, 32, 38, 33, 79, 77],
        default: true,
      },
      {
        prefix: "+968",
        size: [6, 8],
        code: "OM",
        name_EN: "Oman",
        name_own: "سلطنة عمان",
        localPrefixes: [9, 24, 25, 23, 26],
        default: true,
      },
      {
        prefix: "+92",
        size: [8, 9, 10],
        code: "PK",
        name_EN: "Pakistan",
        name_own: "پاکستان",
        localPrefixes: [
          3, 51, 62, 47, 64, 41, 55, 53, 22, 544, 21, 49, 42, 74, 937, 946, 233,
          61, 244, 44, 91, 81, 68, 51, 40, 48, 56, 52, 71, 67,
        ],
        default: true,
      },
      {
        prefix: "+680",
        size: [7],
        code: "PW",
        name_EN: "Palau",
        localPrefixes: [77, 62, 63, 64, 66, 68, 69, 654, 488],
        default: true,
      },
      {
        prefix: "+970",
        size: [8, 9],
        code: "PS",
        name_EN: "Palestine",
        name_own: "فلسطين",
        localPrefixes: [5, 8, 2, 4, 9],
        default: true,
      },
      {
        prefix: "+507",
        size: [7, 8],
        code: "PA",
        name_EN: "Panama",
        localPrefixes: [6, 2, 3, 5, 7, 9, 4],
        default: true,
      },
      {
        prefix: "+675",
        size: [7, 8],
        code: "PG",
        name_EN: "Papua New Guinea",
        localPrefixes: [7, 3, 97, 53, 98, 47, 85, 54, 629],
        default: true,
      },
      {
        prefix: "+595",
        size: [8, 9, 10],
        code: "PY",
        name_EN: "Paraguay",
        localPrefixes: [
          9, 21, 291, 72, 511, 522, 542, 228, 61, 331, 521, 71, 631, 21, 491,
          318, 497, 224, 294, 531, 336, 786, 46, 677, 343, 782, 81, 342, 226,
          541, 530,
        ],
        default: true,
      },
      {
        prefix: "+51",
        size: [7, 8, 9],
        code: "PE",
        name_EN: "Peru",
        localPrefixes: [
          9, 1, 83, 54, 66, 76, 14, 63, 41, 74, 43, 56, 84, 67, 64, 62, 65, 53,
          42, 73, 61, 82, 73, 52, 44, 72,
        ],
        default: true,
      },
      {
        prefix: "+63",
        size: [8, 9, 10],
        code: "PH",
        name_EN: "Philippines",
        localPrefixes: [
          9, 2, 45, 34, 46, 74, 44, 43, 49, 85, 88, 55, 32, 64, 75, 82, 35, 83,
          33, 52, 43, 42, 54, 47, 48, 36, 93, 53, 84, 62,
        ],
        default: true,
      },
      {
        prefix: "+48",
        size: [9],
        code: "PL",
        name_EN: "Poland",
        name_own: "Polska",
        localPrefixes: [
          50, 51, 53, 60, 66, 69, 72, 22, 85, 71, 52, 12, 34, 58, 32, 41, 42,
          81, 61, 17, 91, 56,
        ],
        default: true,
      },
      {
        prefix: "+351",
        size: [8, 9, 10],
        code: "PT",
        name_EN: "Portugal",
        localPrefixes: [
          8, 9, 21, 241, 295, 235, 234, 284, 253, 273, 262, 272, 286, 276, 239,
          275, 268, 266, 289, 233, 291, 271, 292, 277, 282, 244, 22, 231, 278,
          285, 283, 255, 254, 236, 296, 242, 245, 282, 291, 274, 243, 269, 256,
          238, 265, 219, 281, 279, 249, 261, 251, 258, 263, 252, 22, 259, 232,
        ],
        default: true,
      },
      {
        prefix: "+1",
        size: [7, 10],
        code: "PR",
        name_EN: "Puerto Rico",
        localPrefixes: [787, 939],
      },
      {
        prefix: "+974",
        size: [8],
        code: "QA",
        name_EN: "Qatar",
        name_own: "دولة قطر",
        localPrefixes: [33, 44, 55, 66, 77],
        default: true,
      },
      {
        prefix: "+242",
        size: [7, 9],
        code: "CG",
        name_EN: "Republic of the Congo",
        name_own: "République du Congo",
        localPrefixes: [8, 9, 1, 3, 6, 5, 4, 2],
        default: true,
      },
      {
        prefix: "+262",
        size: [9],
        code: "RE",
        name_EN: "Reunion",
        name_own: "Réunion",
        localPrefixes: [692, 693],
        default: true,
      },
      {
        prefix: "+40",
        size: [8, 9, 10],
        code: "RO",
        name_EN: "Romania",
        name_own: "România",
        localPrefixes: [
          7, 21, 258, 241, 269, 259, 247, 243, 257, 244, 240, 250, 255, 249,
          263, 233, 246, 234, 256, 254, 260, 264, 248, 267, 251, 231, 268, 230,
          239, 245, 237, 236, 266, 238, 235, 232, 265, 262, 221, 261, 253, 242,
          252, 2179,
        ],
        default: true,
      },
      {
        prefix: "+7",
        size: [8, 9, 10, 11, 12],
        code: "RU",
        name_EN: "Russia",
        name_own: "Россия",
        localPrefixes: [
          9, 495, 499, 8182, 8512, 3852, 4722, 4832, 8352, 3512, 8202, 3022,
          343, 3952, 4932, 3412, 4212, 4012, 4842, 843, 3842, 83361, 861, 3912,
          3522, 47122, 4742, 3519, 8722, 8152, 8552, 8312, 3435, 3843, 383,
          3812, 3532, 4862, 8412, 342, 4912, 48536, 863, 846, 812, 8452, 4812,
          8622, 8652, 3462, 3452, 8482, 3822, 4872, 4822, 3472, 3012, 8422,
          8672, 4922, 4232, 8442, 8443, 4732, 4112, 4852,
        ],
        default: true,
      },
      {
        prefix: "+250",
        size: [9],
        code: "RW",
        name_EN: "Rwanda",
        localPrefixes: [25, 7],
        default: true,
      },
      {
        prefix: "+590",
        size: [9],
        code: "BL",
        name_EN: "Saint Barthelemy",
        localPrefixes: [690],
      },
      {
        prefix: "+290",
        size: [5],
        code: "SH",
        name_EN: "Saint Helena",
        localPrefixes: [5, 6, 2, 8],
        default: true,
      },
      {
        prefix: "+1",
        size: [10],
        code: "KN",
        name_EN: "Saint Kitts and Nevis",
        localPrefixes: [869],
      },
      {
        prefix: "+1",
        size: [10],
        code: "LC",
        name_EN: "Saint Lucia",
        localPrefixes: [758],
      },
      {
        prefix: "+590",
        size: [9],
        code: "MF",
        name_EN: "Saint Martin",
        localPrefixes: [690],
        default: true,
      },
      {
        prefix: "+508",
        size: [6],
        code: "PM",
        name_EN: "Saint Pierre and Miquelon",
        name_own: "Saint-Pierre-et-Miquelon",
        localPrefixes: [41, 55],
        default: true,
      },
      {
        prefix: "+1",
        size: [10],
        code: "VC",
        name_EN: "Saint Vincent and the Grenadines",
        localPrefixes: [784],
      },
      {
        prefix: "+685",
        size: [5, 7],
        code: "WS",
        name_EN: "Samoa",
        localPrefixes: [7, 2, 3, 6, 5, 4],
        default: true,
      },
      {
        prefix: "+378",
        size: [10],
        code: "SM",
        name_EN: "San Marino",
        localPrefixes: ["3", "6", "0549"],
        default: true,
      },
      {
        prefix: "+239",
        size: [7],
        code: "ST",
        name_EN: "Sao Tome and Principe",
        name_own: "São Tomé e Príncipe",
        localPrefixes: [22, 98, 99],
        default: true,
      },
      {
        prefix: "+966",
        size: [9],
        code: "SA",
        name_EN: "Saudí Arabia",
        name_own: "المملكة العربية السعودية",
        localPrefixes: [5, 11, 17, 14, 13, 16, 12],
        default: true,
      },
      {
        prefix: "+221",
        size: [9],
        code: "SN",
        name_EN: "Senegal",
        name_own: "Sénégal",
        localPrefixes: [33, 7],
        default: true,
      },
      {
        prefix: "+381",
        size: [7, 8, 9],
        code: "RS",
        name_EN: "Serbia",
        name_own: "Србија",
        localPrefixes: [
          6, 11, 32, 390, 35, 34, 36, 37, 16, 18, 20, 21, 13, 39, 38, 29, 51,
          15, 26, 25, 24, 290, 31, 36, 17, 23,
        ],
        default: true,
      },
      {
        prefix: "+248",
        size: [7],
        code: "SC",
        name_EN: "Seychelles",
        name_own: "les Seychelles",
        localPrefixes: [2, 4],
        default: true,
      },
      {
        prefix: "+232",
        size: [8],
        code: "SL",
        name_EN: "Sierra Leone",
        localPrefixes: [
          76, 77, 78, 79, 30, 33, 34, 88, 40, 44, 21, 25, 50, 55, 22, 32, 52,
        ],
        default: true,
      },
      {
        prefix: "+65",
        size: [8],
        code: "SG",
        name_EN: "Singapore",
        name_own: "Singapura",
        localPrefixes: [3, 6, 8, 9],
        default: true,
      },
      {
        prefix: "+1",
        size: [10],
        code: "SX",
        name_EN: "Sint Maarten",
        name_own: "Sint-Maarten",
        localPrefixes: [721],
      },
      {
        prefix: "+421",
        size: [8, 9, 10],
        code: "SK",
        name_EN: "Slovakia",
        name_own: "Slovensko",
        localPrefixes: [
          9, 2, 48, 54, 31, 57, 55, 36, 44, 47, 43, 56, 37, 35, 52, 42, 51, 46,
          58, 2, 34, 53, 38, 32, 33, 41, 45,
        ],
        default: true,
      },
      {
        prefix: "+386",
        size: [8],
        code: "SI",
        name_EN: "Slovenia",
        name_own: "Slovenija",
        localPrefixes: [
          30, 31, 40, 41, 51, 64, 65, 66, 67, 68, 69, 70, 71, 1, 3, 5, 4, 7, 2,
        ],
        default: true,
      },
      {
        prefix: "+677",
        size: [5, 7],
        code: "SB",
        name_EN: "Solomon Islands",
        localPrefixes: [7, 8, 2, 1, 40, 35, 60, 50, 53, 63, 32],
        default: true,
      },
      {
        prefix: "+252",
        size: [7, 8],
        code: "SO",
        name_EN: "Somalia",
        name_own: "Soomaaliya",
        localPrefixes: [],
        default: true,
      },
      {
        prefix: "+27",
        size: [9],
        code: "ZA",
        name_EN: "South Africa",
        name_own: "Suid-Afrika",
        localPrefixes: [
          7, 8, 51, 21, 12, 11, 18, 31, 44, 53, 43, 13, 34, 33, 15, 41, 14, 16,
          57,
        ],
        default: true,
      },
      {
        prefix: "+82",
        size: [7, 8, 9, 10],
        code: "KR",
        name_EN: "South Korea",
        name_own: "대한민국",
        localPrefixes: [
          1, 2, 31, 32, 51, 41, 55, 43, 33, 42, 62, 54, 64, 63, 61, 53, 52, 82,
        ],
        default: true,
      },
      {
        prefix: "+211",
        size: [9],
        code: "SS",
        name_EN: "South Sudan",
        localPrefixes: [1, 9],
        default: true,
      },
      {
        prefix: "+34",
        size: [9],
        code: "ES",
        name_EN: "Spain",
        name_own: "España",
        localPrefixes: [
          6, 7, 8, 91, 967, 867, 96, 865, 950, 850, 920, 924, 824, 93, 94, 900,
          901, 902, 947, 927, 827, 956, 856, 964, 864, 956, 926, 826, 957, 857,
          969, 972, 872, 98, 958, 858, 949, 959, 859, 974, 874, 971, 871, 953,
          853, 981, 881, 928, 828, 987, 887, 973, 873, 941, 982, 95, 851, 924,
          968, 868, 988, 98, 979, 971, 871, 948, 848, 986, 886, 923, 943, 843,
          942, 842, 921, 95, 854, 975, 875, 922, 822, 977, 877, 978, 925, 825,
          96, 983, 883, 986, 945, 980, 976, 876,
        ],
        default: true,
      },
      {
        prefix: "+94",
        size: [9],
        code: "LK",
        name_EN: "Sri Lanka",
        name_own: "ශ්රී ලංකාව",
        localPrefixes: [
          7, 11, 25, 55, 65, 66, 91, 21, 67, 81, 24, 37, 31, 45, 26,
        ],
        default: true,
      },
      {
        prefix: "+249",
        size: [9],
        code: "SD",
        name_EN: "Sudan",
        name_own: "السودان",
        localPrefixes: [1, 9],
        default: true,
      },
      {
        prefix: "+597",
        size: [6, 7],
        code: "SR",
        name_EN: "Suriname",
        localPrefixes: [6, 7, 8, 4, 5, 3, 2],
        default: true,
      },
      {
        prefix: "+268",
        size: [8],
        code: "SZ",
        name_EN: "Swaziland",
        localPrefixes: [7, 24, 25, 22, 23],
        default: true,
      },
      {
        prefix: "+46",
        size: [6, 7, 8, 9, 10, 11],
        code: "SE",
        name_EN: "Sweden",
        name_own: "Sverige",
        localPrefixes: [
          7, 8, 33, 16, 26, 31, 42, 36, 54, 13, 40, 11, 19, 121, 504, 90, 18,
          21,
        ],
        default: true,
      },
      {
        prefix: "+41",
        size: [9],
        code: "CH",
        name_EN: "Switzerland",
        name_own: "Schweiz",
        localPrefixes: [
          7, 31, 24, 56, 61, 91, 32, 34, 81, 26, 22, 43, 44, 34, 21, 62, 55, 71,
          27, 33, 52, 24,
        ],
        default: true,
      },
      {
        prefix: "+963",
        size: [8, 9],
        code: "SY",
        name_EN: "Syria",
        name_own: "سوريا",
        localPrefixes: [
          9, 11, 52, 12, 21, 16, 15, 51, 33, 31, 23, 41, 25, 34, 53, 14, 22, 43,
          13,
        ],
        default: true,
      },
      {
        prefix: "+886",
        size: [8, 9, 10],
        code: "TW",
        name_EN: "Taiwan",
        name_own: "台灣",
        localPrefixes: [9, 2, 3, 5, 4, 7, 82, 836, 37, 49, 6, 8, 89, 826],
        default: true,
      },
      {
        prefix: "+992",
        size: [8, 9, 10],
        code: "TJ",
        name_EN: "Tajikistan",
        name_own: "Точикистон",
        localPrefixes: [9, 372, 3454, 3422, 3522, 3467, 3322, 3222, 3130, 3136],
        default: true,
      },
      {
        prefix: "+255",
        size: [9],
        code: "TZ",
        name_EN: "Tanzania",
        localPrefixes: [7, 26, 27, 22, 28, 23, 25, 24],
        default: true,
      },
      {
        prefix: "+66",
        size: [8, 9, 10],
        code: "TH",
        name_EN: "Thailand",
        name_own: "ประเทศไทย",
        localPrefixes: [
          8, 2, 53, 38, 74, 43, 54, 34, 44, 75, 38, 55, 76, 77, 45, 42,
        ],
        default: true,
      },
      {
        prefix: "+228",
        size: [8],
        code: "TG",
        name_EN: "Togo",
        name_own: "Aller",
        localPrefixes: [9, 22, 24, 25, 26, 23, 27],
        default: true,
      },
      {
        prefix: "+690",
        size: [4],
        code: "TK",
        name_EN: "Tokelau",
        localPrefixes: [2, 3, 4, 5],
        default: true,
      },
      {
        prefix: "+676",
        size: [5],
        code: "TO",
        name_EN: "Tonga",
        localPrefixes: [
          15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 33, 34, 46,
          49, 53, 54, 55, 56, 57, 58, 59, 63, 64, 65, 66, 67, 68, 69, 70, 71,
          74, 75, 76, 77, 78, 79,
        ],
        default: true,
      },
      {
        prefix: "+1",
        size: [10],
        code: "TT",
        name_EN: "Trinidad and Tobago",
        localPrefixes: [868],
      },
      {
        prefix: "+216",
        size: [8],
        code: "TN",
        name_EN: "Tunisia",
        name_own: "تونس",
        localPrefixes: [2, 4, 9, 80, 82, 88, 71, 72, 75, 76, 77, 73, 74],
        default: true,
      },
      {
        prefix: "+90",
        size: [10],
        code: "TR",
        name_EN: "Turkey",
        name_own: "Türkiye",
        localPrefixes: [
          5, 312, 322, 264, 416, 272, 472, 382, 358, 326, 242, 478, 256, 266,
          378, 488, 458, 228, 426, 434, 374, 248, 224, 286, 376, 364, 258, 412,
          380, 284, 424, 446, 442, 222, 232, 212, 216, 342, 454, 406, 438, 476,
          246, 262, 344, 370, 338, 474, 366, 352, 348, 312, 288, 386, 332, 274,
          422, 236, 482, 324, 252, 436, 384, 388, 452, 328, 464, 362, 414, 484,
          368, 486, 346, 282, 356, 462, 428, 276, 432, 226, 354, 372,
        ],
        default: true,
      },
      {
        prefix: "+993",
        size: [7, 8, 9],
        code: "TM",
        name_EN: "Turkmenistan",
        name_own: "Türkmenistan",
        localPrefixes: [],
        default: true,
      },
      {
        prefix: "+1",
        size: [10],
        code: "TC",
        name_EN: "Turks and Caicos Islands",
        localPrefixes: [649],
      },
      {
        prefix: "+688",
        size: [5, 6],
        code: "TV",
        name_EN: "Tuvalu",
        localPrefixes: [20, 21, 90],
        default: true,
      },
      {
        prefix: "+1",
        size: [10],
        code: "VI",
        name_EN: "U.S. Virgin Islands",
        localPrefixes: [340],
      },
      {
        prefix: "+256",
        size: [8, 9, 10],
        code: "UG",
        name_EN: "Uganda",
        localPrefixes: [
          7, 41, 476, 41, 483, 471, 43, 486, 473, 481, 465, 45, 485, 46, 3, 464,
          2,
        ],
        default: true,
      },
      {
        prefix: "+380",
        size: [9],
        code: "UA",
        name_EN: "Ukraine",
        name_own: "Україна",
        localPrefixes: [
          39, 50, 63, 66, 67, 68, 9, 44, 6442, 6153, 4563, 47, 46, 37, 56, 569,
          62, 624, 34, 55, 38, 6561, 57, 52, 5447, 626, 536, 56, 32, 64, 33,
          6451, 623, 619, 629, 51, 566, 48, 53, 45, 36, 69, 6452, 65, 626, 54,
          35, 31, 43, 61, 41,
        ],
        default: true,
      },
      {
        prefix: "+971",
        size: [8, 9],
        code: "AE",
        name_EN: "United Arab Emirates",
        name_own: "الإمارات العربية المتحدة",
        localPrefixes: [5, 2, 6, 3, 4, 9, 6, 7],
        default: true,
      },
      {
        prefix: "+44",
        size: [6, 7, 8, 9, 10, 11, 12, 13],
        code: "GB",
        name_EN: "United Kingdom",
        localPrefixes: [
          7, 20, 1225, 121, 1202, 1274, 1273, 117, 1223, 1227, 1228, 1244, 1243,
          24, 1332, 1384, 1385, 1353, 1392, 1452, 1432, 1482, 1524, 113, 116,
          1543, 1522, 151, 1582, 161, 191, 1604, 1603, 1602, 1865, 1752, 23,
          1772, 118, 1765, 1722, 114, 23, 1727, 1782, 191, 1793, 1872, 1924,
          1922, 20, 1749, 1962, 1902, 1905, 1904,
        ],
        default: true,
      },
      {
        prefix: "+1",
        size: [10],
        code: "US",
        name_EN: "United States",
        name_own: "United States",
        localPrefixes: [
          202, 262, 325, 571, 703, 734, 512, 410, 443, 409, 425, 276, 802, 843,
          304, 704, 980, 434, 423, 307, 312, 773, 872, 931, 979, 803, 614, 936,
          361, 214, 469, 972, 940, 313, 915, 215, 267, 810, 817, 682, 616, 864,
          808, 713, 281, 832, 317, 731, 904, 254, 865, 956, 702, 323, 213, 806,
          901, 305, 432, 615, 830, 757, 212, 405, 602, 480, 623, 401, 804, 540,
          385, 801, 210, 619, 858, 415, 408, 206, 605, 509, 435, 253, 430, 903,
          360, 757, 586, 385, 801,
        ],
        default: true,
      },
      {
        prefix: "+598",
        size: [8],
        code: "UY",
        name_EN: "Uruguay",
        localPrefixes: [
          9, 2, 4, 2, 477, 433, 452, 42, 436, 435, 456, 433, 464, 453, 444, 472,
          462, 447, 473, 434, 42, 463, 445, 436,
        ],
        default: true,
      },
      {
        prefix: "+998",
        size: [9],
        code: "UZ",
        name_EN: "Uzbekistan",
        name_own: "O'zbekiston",
        localPrefixes: [9, 71, 74, 65, 62, 72, 73, 61, 75, 69, 79, 66, 67, 76],
        default: true,
      },
      {
        prefix: "+678",
        size: [5, 7],
        code: "VU",
        name_EN: "Vanuatu",
        localPrefixes: [5, 7, 2, 37, 36],
        default: true,
      },
      {
        prefix: "+379",
        size: [7, 8, 9, 10, 11, 12],
        code: "VA",
        name_EN: "Vatican",
        name_own: "Vaticano",
        localPrefixes: [],
        default: true,
      },
      {
        prefix: "+58",
        size: [10],
        code: "VE",
        name_EN: "Venezuela",
        localPrefixes: [
          4, 212, 255, 282, 281, 274, 251, 279, 244, 246, 294, 285, 239, 286,
          268, 293, 286, 283, 275, 237, 245, 272, 236, 295, 232, 261, 243, 291,
          274, 248, 281, 259, 269, 258, 254, 276, 247, 273, 246, 287, 241,
        ],
        default: true,
      },
      {
        prefix: "+84",
        size: [8, 9, 10, 11],
        code: "VN",
        name_EN: "Vietnam",
        name_own: "Việt Nam",
        localPrefixes: [
          9, 1, 4, 781, 61, 500, 780, 710, 63, 511, 33, 320, 31, 8, 54, 76, 73,
          350, 58, 62, 56, 77, 280, 37, 38, 64,
        ],
        default: true,
      },
      {
        prefix: "+681",
        size: [6],
        code: "WF",
        name_EN: "Wallis and Futuna",
        name_own: "Wallis et Futuna",
        localPrefixes: [],
        default: true,
      },
      {
        prefix: "+212",
        size: [9],
        code: "EH",
        name_EN: "Western Sahara",
        name_own: "الصحراء الغربية",
        localPrefixes: [],
      },
      {
        prefix: "+967",
        size: [7, 8, 9],
        code: "YE",
        name_EN: "Yemen",
        name_own: "اليمن",
        localPrefixes: [7, 1, 2, 6, 3, 7, 5, 4],
        default: true,
      },
      {
        prefix: "+260",
        size: [9],
        code: "ZM",
        name_EN: "Zambia",
        localPrefixes: [21, 9, 211, 212, 216, 215, 214, 213],
        default: true,
      },
      {
        prefix: "+263",
        size: [4, 5, 6, 7, 8, 9, 10, 11, 12],
        code: "ZW",
        name_EN: "Zimbabwe",
        name_own: "",
        localPrefixes: [86, 71, 73, 77, 78, 9, 270, 4, 54, 20, 667],
        default: true,
      },
    ];
  },
};
