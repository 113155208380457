var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"phone-number",class:{ 'w-full': _vm.outlined }},[_c('v-layout',{attrs:{"align-center":""}},[_c('v-flex',{staticClass:"prefixPosition",class:{ 'mr-2': _vm.outlined },attrs:{"xs4":""}},[_c('v-autocomplete',{ref:"selectCountry",class:_vm.frameColours ? 'custom-placeholer-color' : '',style:(_vm.colors
            ? `--custom_color-text:${_vm.colors.colorOnSurface}; --custom_color-label:${_vm.colors.colorOnSurfaceMedium}`
            : ''),attrs:{"disabled":_vm.disabled,"menu-props":{
          auto: false,
          maxWidth: 600,
          closeOnContentClick: true,
        },"item-text":"prefix","item-value":"code","color":_vm.colors ? _vm.colors.colorPrimary : '',"append-icon":_vm.$vuetify.breakpoint.name === 'xs' ? '' : '$dropdown',"hide-selected":"","items":_vm.countries,"name":"country","outlined":_vm.outlined,"filter":_vm.findCountries,"backgroundColor":_vm.backgroundColor,"dark":_vm.dark,"label":_vm.$t('common.countryCode'),"return-object":""},on:{"change":() => {
            _vm.$v.phoneNumber.$touch();
            _vm.formatPhoneNumber();
            _vm.focusTexfield();
          }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-layout',{staticStyle:{"width":"200px","min-height":"80px"}},[_c('v-layout',[_c('svg',{staticStyle:{"width":"20px","height":"20px","margin-right":"5px"}},[_c('use',_vm._b({staticClass:"onion-icon"},'use',{ 'xlink:href': _vm.spriteUrl + '#' + item.code },false))])]),_c('v-layout',{staticStyle:{"width":"190px","min-height":"20px","margin-right":"5px mt-1"}},[_vm._v(" "+_vm._s(item.name_EN)+" "+_vm._s(item.name_own ? `(${item.name_own})` : "")+" "+_vm._s(item.prefix)+" ")])],1)]}}]),model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}},[_c('template',{slot:"prepend-inner"},[_c('div',{staticClass:"flag"},[_c('v-layout',[(_vm.country)?_c('svg',{staticStyle:{"width":"20px","height":"20px"}},[_c('use',_vm._b({staticClass:"onion-icon"},'use',{
                    'xlink:href': _vm.spriteUrl + '#' + _vm.country.code,
                  },false))]):_vm._e()])],1)])],2)],1),_c('v-flex',{attrs:{"xs9":""}},[_c('v-text-field',{class:_vm.frameColours ? 'custom-placeholer-color' : '',style:(_vm.colors
            ? `--custom_color-text:${_vm.colors.colorOnSurface}; --custom_color-label:${_vm.colors.colorOnSurfaceMedium}`
            : ''),attrs:{"id":"textFieldPhone","disabled":_vm.disabled,"label":_vm.labelText,"outlined":_vm.outlined,"color":_vm.colors ? _vm.colors.colorPrimary : '',"error-messages":_vm.phoneNumberErrors,"backgroundColor":_vm.backgroundColor,"dark":_vm.dark},on:{"input":() => {
            _vm.$v.phoneNumber.$touch();
            _vm.formatPhoneNumber();
          },"blur":function($event){_vm.validateBlur ? _vm.$v.phoneNumber.$touch() : ''},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('keyupEnter')}},scopedSlots:_vm._u([(_vm.showAsterisk)?{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("forms.phoneNumber"))),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" * ")])])]},proxy:true}:null],null,true),model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }