import conn from "@/flit2go-web-core/services/connection";

const url =
  window.location !== window.parent.location
    ? document.location.ancestorOrigins
      ? document.location.ancestorOrigins[0]
      : document.referrer
    : document.location.origin;
export default {
  getConfigurationToken() {
    return {
      Authorization: localStorage.getItem(`apiToken-${url}`),
      "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
      "Accept-language": localStorage.getItem(`user-language`),
    };
  },
  createUser(user) {
    return conn.$axios({
      url: `v1/frameweb/user`,
      data: user,
      method: "POST",
      headers: this.getConfigurationToken(),
    });
  },
  updateUser(data) {
    return conn.$axios({
      url: `secured/v1/frame/user/update_information`,
      data: data.user,
      method: "POST",
      headers: {
        Authorization: "Bearer " + data.token,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
    });
  },
  createInfoNewUser(data) {
    return conn.$axios({
      url: `secured/v1/user/register/documentation`,
      data: data.userInfo,
      params: data.params,
      method: "PUT",
      headers: {
        Authorization: "Bearer " + data.token,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
    });
  },
  updateInfoUser(data) {
    return conn.$axios({
      url: `secured/v1/user/update/documentation`,
      data: data.userInfo,
      params: data.params,
      method: "PUT",
      headers: {
        Authorization: "Bearer " + data.token,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
    });
  },
  confirmUpdateUploadImages(data) {
    return conn.$axios({
      url: `secured/v1/user/confirm/documentation`,
      data: data.userId,
      method: "PUT",
      params: {
        isFrameweb: true,
      },
      headers: {
        Authorization: "Bearer " + data.token,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
    });
  },
  getDrivingLicensesOrigins() {
    return conn.$axios({
      url: `user/v1/licenceType/web`,
      method: "GET",
    });
  },
  getNationalities() {
    return conn.$axios({
      url: `/v1/frameweb/master/country/user/search`,
      method: "GET",
      headers: this.getConfigurationToken(),
      params: {
        page: 0,
        size: 239,
      },
    });
  },
  getClientId() {
    return conn.$axios({
      url: `v1/frameweb/clientId`,
      headers: this.getConfigurationToken(),
      method: "GET",
    });
  },
  validateToken(data) {
    return conn.$axios({
      url: `user/v1/login/token/validate`,
      headers: this.getConfigurationToken(),
      method: "POST",
      data: data,
    });
  },
  changeLostPassword(data) {
    return conn.$axios({
      url: `user/v1/login/token/pass/user`,
      headers: this.getConfigurationToken(),
      method: "POST",
      data: data,
    });
  },
  changePassword(data) {
    return conn.$axios({
      url: `secured/v1/user/password`,
      headers: {
        Authorization: "Bearer " + data.token,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
      method: "POST",
      data: data.data,
    });
  },
  getOwner() {
    return conn.$axios({
      url: `v1/frameweb/owner`,
      headers: this.getConfigurationToken(),
      method: "GET",
    });
  },
  getImageValidate(data) {
    return conn.$axios({
      url: `secured/v1/img/validate?name=${data.name}`,
      headers: {
        Authorization: "Bearer " + data.token,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
      method: "GET",
    });
  },
  login(params) {
    params.access.append("grant_type", "password");
    params.access.append("client_id", params.loginParams.clientId);
    return conn.$axios({
      url: "oauth/token",
      method: "POST",
      data: params.access,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: params.loginParams.basicId,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
    });
  },

  getUserData(params) {
    return conn.$axios({
      url: `secured/v1/frameweb/user`,
      method: "GET",
      params: {
        ApiKey: this.getConfigurationToken().Authorization,
      },
      headers: {
        Authorization: "Bearer " + params.loginParameters.access_token,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
    });
  },

  getPayment(params) {
    return conn.$axios({
      url: `secured/v1/user/${params.id}/payment/all`,
      method: "GET",
      params: {
        ownerId: params.ownerId,
        type: "USC",
        frame: true,
      },
      headers: {
        Authorization: "Bearer " + params.loginParameters.access_token,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
    });
  },
  getPromotionList(params) {
    return conn.$axios({
      url: `secured/v1/promotion/user/list`,
      method: "GET",
      params: {
        tokenOwner: localStorage.getItem(`apiToken-${url}`),
        categoryId: params.categoryId,
      },
      headers: {
        Authorization: "Bearer " + params.loginParameters.access_token,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
    });
  },
  sendPromotionalCode(params) {
    return conn.$axios({
      url: `secured/v1/promotion/user/search/`,
      method: "GET",
      params: {
        name: params.name,
        tokenOwner: localStorage.getItem(`apiToken-${url}`),
      },
      headers: {
        Authorization: "Bearer " + params.loginParameters.access_token,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
    });
  },
  sendUserFields(data) {
    return conn.$axios({
      url: `secured/v1/frame/user`,
      method: "PUT",
      data: data.user,
      headers: {
        Authorization: "Bearer " + data.loginParameters.access_token,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
    });
  },

  changeDefaultPayment(params) {
    return conn.$axios({
      url: `secured/v1.1/user/${params.id}/payment/default`,
      method: "PUT",
      headers: {
        Authorization: "Bearer " + params.loginParameters.access_token,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
      params: params.data,
    });
  },

  deletePayment(data) {
    return conn.$axios({
      url: `secured/v1/user/${data.id}/payment`,
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + data.loginParameters.access_token,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
      data: data.cardStripeId,
    });
  },

  personIdByUsername(params) {
    return conn.$axios({
      url: `user/v1/login`,
      method: "POST",
      params: params,
      headers: {
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
    });
  },
  getUserByUsername(data) {
    return conn.$axios({
      url: `secured/v1/person`,
      method: "GET",
      params: { username: data.username },
      headers: {
        Authorization: "Bearer " + data.token,
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
    });
  },
  sendEmailToUser(id) {
    return conn.$axios({
      url: `user/v1/login/${id}/token?option=PADM`,
      method: "POST",
      headers: {
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
    });
  },
  sendEmailToUserApp(id) {
    return conn.$axios({
      url: `user/v1/login/${id}/token?option=PASS`,
      method: "POST",
      headers: {
        "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
        "Accept-language": localStorage.getItem(`user-language`),
      },
    });
  },
  getAgencyByCodeFrame(params) {
    return conn.$axios({
      url: `/v1/agency/code`,
      method: "GET",
      params: params,
      headers: this.getConfigurationToken(),
    });
  },
  checkToken(data) {
    return conn.$axios({
      url: `oauth/check_token?token=${data.token}`,
      method: "POST",
      headers: data.headers,
    });
  },
};
