import conn from "./connection";
import auth from "@/base/config-auth";

let config = {
  "Content-Type": "application/x-www-form-urlencoded",
  Authorization: "Basic " + btoa(auth.client_id + ":" + auth.secret_id),
};

export default {
  login(params) {
    params.append("grant_type", "password");
    params.append("client_id", auth.client_id);
    return conn.$axios({
      url: "oauth/token",
      method: "POST",
      data: params,
      headers: config,
    });
  },
  check_token(token) {
    return conn.$axios({
      url: `oauth/check_token?token=${token}`,
      method: "POST",
      headers: {
        Authorization: "Basic " + btoa(auth.client_id + ":" + auth.secret_id),
      },
    });
  },
  personByUsername(username) {
    return conn.$axios({
      url: `secured/v1/person?username=${username}`,
      method: "GET",
      headers: {
        "Accept-language": "",
      },
    });
  },
  personIdByUsername(params) {
    return conn.$axios({
      url: `user/v1/login`,
      method: "POST",
      params: params,
    });
  },
  sendEmailToUser(id) {
    return conn.$axios({
      url: `user/v1/login/${id}/token?option=PADM`,
      method: "POST",
    });
  },
  resetUserPassword(newPassword) {
    return conn.$axios({
      url: `user/v1/login/token/pass`,
      method: "POST",
      data: newPassword,
    });
  },
  tokenForFirstLogin(id) {
    return conn.$axios({
      url: `secured/v1/person/${id}/web/token/resetPassword`,
      method: "GET",
    });
  },
  validatePhoneToken(payload) {
    return conn.$axios({
      url: `secured/v1/person/token/mobile`,
      method: "PUT",
      data: payload,
    });
  },
  enableMFA() {
    return conn.$axios({
      url: `secured/v1/person/enabled/mfa`,
      method: "PUT",
    });
  },
  validateMFATokenEnabled(payload) {
    return conn.$axios({
      url: `secured/v1/person/token/mfa/enabled`,
      method: "PUT",
      data: payload,
    });
  },
  disableMFA() {
    return conn.$axios({
      url: `secured/v1/person/disabled/mfa`,
      method: "PUT",
    });
  },
  validateMFATokenDisabled(payload) {
    return conn.$axios({
      url: `secured/v1/person/token/mfa/disabled`,
      method: "PUT",
      data: payload,
    });
  },
  getMfaLogin(payload) {
    return conn.$axios({
      url: `oauth/mfa`,
      method: "POST",
      params: {
        client_ID: auth.client_id,
      },
      data: payload,
    });
  },
  getRoleSchema() {
    return conn.$axios({
      url: `secured/v1/permissions`,
      method: "GET",
    });
  },
  getMultiOwner() {
    return conn.$axios({
      url: `secured/v1/owner_employe/owner/list`,
      method: "GET",
    });
  },
  changeMultiOwner(idOwner) {
    return conn.$axios({
      url: `secured/v1/owner_employe/owner/${idOwner}`,
      method: "PUT",
    });
  },
};
