/* eslint-disable no-async-promise-executor */
import service from "@/flit2go-web-core/services/";

export default {
  namespaced: true,
  state: {
    status: "",
    baseInfo: {},
    widgetsDashboard: [],
    // Legacy
    /*typeWidgets: [],
        dataTypeWidgets: [],
        periodicity: [],
        colours: [],
        coloursSwatches: [],
        labels: []*/
  },
  mutations: {
    // REQUEST STATE
    ["STATUS_SUCCESS"]: (state) => {
      state.status = "success";
    },
    ["STATUS_LOADING"]: (state) => {
      state.status = "loading";
    },
    ["STATUS_ERROR"]: (state, msg) => {
      state.status = "error";
      state.errorMsg = msg || "";
    },
    ["SET_BASE_INFO"]: (state, baseInfo) => {
      state.baseInfo = baseInfo;
    },
    ["SET_WIDGETS_DASHBOARD"]: (state, widgets) => {
      state.widgetsDashboard = widgets;
    },
    // Legacy
  },
  actions: {
    /**
     * Obtains the generic information required for the dashboard
     * TypeWidgets, DataTypeWidgets, Periodicity, Colours, Labels ...
     */
    getDashboardBaseInfo({ commit }) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service.dashboard
          .getDashboardBaseInfo()
          .then((resp) => {
            commit("SET_BASE_INFO", resp.data);
            commit("STATUS_SUCCESS");
            resolve(resp.data);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    /**
     * Obtains the distribution of the layout
     */
    getUserDashboard({ commit }, dashboardType) {
      return new Promise(async (resolve, reject) => {
        commit("STATUS_LOADING");
        service.dashboard
          .getUserDashboard(dashboardType)
          .then((resp) => {
            commit("SET_WIDGETS_DASHBOARD", resp.data);
            commit("STATUS_SUCCESS");
            resolve(resp.data);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getDashboardData({ commit }, widgetItems) {
      return new Promise(async (resolve) => {
        commit("STATUS_LOADING");
        const promises = widgetItems.map((widget) => {
          switch (widget.widget.code) {
            case "1":
              return new Promise((resolve, reject) => {
                let paramsAux = {
                  code: widget.widget.type,
                  date: widget.widget.date,
                };
                service.dashboard
                  .getDataIndicator(paramsAux)
                  .then((resp) => {
                    let value =
                      (Math.abs(resp.data.diference) * 100) /
                      resp.data.totalElements;
                    var footer = `${value}% de incremento`;
                    var icon = "arrow_upward";
                    if (resp.data.diference <= 0) {
                      footer = `${value}% de decremento`;
                      icon = "arrow_downward";
                    }
                    widget.widget.value = `${resp.data.totalElements}`;
                    widget.widget.footer = footer;
                    widget.widget.iconFooter = icon;
                    commit("STATUS_SUCCESS");
                    resolve();
                  })
                  .catch((err) => {
                    commit("STATUS_ERROR", err);
                    reject();
                  });
              });
            case "2":
              // Nothing
              break;
            default:
              // Nothing
              break;
          }
        });
        await Promise.all(promises);
        resolve(widgetItems);
      });
    },
    draftWidget({ commit }, type) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service.dashboard
          .draftWidget(type)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp.data);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    // Legacy
    updateDashboard({ commit }, dashboard) {
      return new Promise(async (resolve, reject) => {
        commit("STATUS_LOADING");
        service.dashboard
          .updateDashboard(dashboard)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp.data);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    deleteWidgets({ commit }, widgets) {
      return new Promise(async (resolve, reject) => {
        commit("STATUS_LOADING");
        service.dashboard
          .deleteWidgets(widgets)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp.data);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
  },
};
