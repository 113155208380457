<template>
  <div class="hour-picker">
    <div v-if="menu" class="speech-bubble"></div>
    <v-menu
      :style="getStyle"
      content-class="mt-4"
      :close-on-content-click="false"
      v-model="menu"
      transition="scale-transition"
      offset-y
      :nudge-right="0"
      :nudge-top="18"
      max-width="290px"
      min-width="290px"
      :disabled="disabled"
    >
      <template v-slot:activator="{ on }">
        <span v-on="on">
          <v-text-field
            ref="hourTextField"
            id="hourTextField"
            slot="activator"
            v-model="hour"
            :backgroundColor="backgroundColor"
            :filled="filled"
            :dense="dense"
            :hide-details="hideDetails"
            :single-line="singleLine"
            :class="[customClass]"
            :disabled="disabled"
            :readonly="readonly"
            :error-messages="errors"
            v-mask="'##:##'"
            placeholder="HH:mm"
            :clearable="clearable"
            @keydown.enter="selectHour(hourSelected)"
            @input="emitInput"
            @click:clear="selectHour('')"
          >
            <template v-if="prependIcon && showIcon" v-slot:prepend>
              <F2gIcon
                class="cursor-pointer"
                value="clock"
                :strokeWidth="strokeWidth"
                :stroke="customColor"
              />
            </template>
            <template v-else-if="showIcon" v-slot:prepend-inner>
              <F2gIcon
                :class="`cursor-pointer ${iconPaddingRight}`"
                value="clock"
                :strokeWidth="strokeWidth"
                :stroke="customColor"
              />
            </template>
          </v-text-field>
        </span>
      </template>
      <div :style="getStyle">
        <F2gCard class="pa-2 rounded" :style="getStyle">
          <v-layout wrap>
            <v-flex
              xs3
              v-for="(item, index) in hoursToShow"
              :key="index"
              class="pa-1 cursor-pointer rounded text-center hour-item flex justify-center"
              :class="{ 'hour-item--marked': item === hourSelected }"
              @click="selectHour(item)"
              >{{ item }}</v-flex
            >
          </v-layout>
        </F2gCard>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "HourPicker",
  props: {
    label: String,
    errors: { required: false, type: Array, default: null },
    value: { required: false, type: String, default: "" },
    minHour: { required: false, type: String, default: null },
    maxHour: { required: false, type: String, default: null },
    disabled: { type: Boolean, required: false, default: false },
    readonly: { type: Boolean, required: false, default: false },
    clearable: { type: Boolean, default: true, required: false },
    rounded: { type: Boolean, default: false, required: false },
    filled: { type: Boolean, default: false, required: false },
    dense: { type: Boolean, default: false, required: false },
    singleLine: { type: Boolean, default: false, required: false },
    hideDetails: { type: Boolean, default: false, required: false },
    customClass: { type: String, default: "", required: false },
    customColor: { type: String, default: "#202020", required: false },
    backgroundColor: { type: String, default: "", required: false },
    inputColor: { type: String, required: false },
    prependIcon: { required: false, type: Boolean, default: false },
    showIcon: { required: false, type: Boolean, default: true },
    availableHours: { required: false, type: Array, default: null },
    clear: { required: false, type: Boolean, default: false },
    strokeWidth: { required: false, type: Number, default: 3 },
    iconPaddingRight: { required: false, type: String, default: "pr-3" },
    zIndex: { Type: Number, required: false },
  },
  data() {
    return {
      hour: "",
      hourSelected: "",
      hoursToShow: [],
      hours: [
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30",
        "00:00",
        "00:30",
        "01:00",
        "01:30",
        "02:00",
        "02:30",
        "03:00",
        "03:30",
        "04:00",
        "04:30",
        "05:00",
        "05:30",
        "06:00",
        "06:30",
        "07:00",
        "07:30",
      ],
      menu: null,
    };
  },
  watch: {
    availableHours: {
      deep: true,
      immediate: true,
      handler() {
        this.filterHours();
      },
    },
    clear() {
      if (this.clear) {
        this.hour = "";
        this.hourSelected = "";
      }
    },
    value() {
      this.hour = this.value;
    },
    menu() {
      if (this.menu) {
        this.$emit("closeDialogs");
      }
    },
  },
  computed: {
    getStyle() {
      let style = "";
      if (this.zIndex) {
        style = `z-index: ${this.zIndex}`;
      }
      return style;
    },
  },
  mounted() {
    this.filterHours();
    if (this.value) {
      this.hour = this.value;
    }
    this.$emit("mounted");
  },
  methods: {
    getHours() {
      let hoursToReturn = [];
      if (this.availableHours && this.availableHours.length) {
        this.hours.forEach((hour) => {
          const index = this.availableHours.findIndex(
            (availableHour) => availableHour === hour
          );
          if (index > -1) {
            hoursToReturn.push(hour);
          }
        });
      } else {
        hoursToReturn = [...this.hours];
      }
      return hoursToReturn;
    },
    filterHours() {
      this.hoursToShow = this.getHours();
      if (this.getHours.length > 0) {
        if (this.minHour) {
          let min = this.getHours.findIndex((hour) => hour === this.minHour);
          if (min === -1) {
            min = 0;
          }
          this.hoursToShow = this.getHours.slice(min, this.getHours.length);
        }
        if (this.maxHour) {
          const max = this.getHours.findIndex((hour) => hour === this.maxHour);
          this.hoursToShow = this.getHours.slice(0, max);
        }
      }
    },
    emitInput() {
      if (
        this.hourSelected &&
        this.hourSelected.length === 5 &&
        this.hour &&
        this.hour.length === 5
      ) {
        this.hour = this.hourSelected;
        this.menu = false;
      } else if (
        this.hour &&
        (this.hour.length === 2 || this.hour.includes(":"))
      ) {
        const hourFinded = this.hours.find((item) =>
          item.startsWith(this.hour)
        );
        if (hourFinded) {
          this.hourSelected = this.hours.find((item) =>
            item.startsWith(this.hour)
          );
        }
        this.menu = true;
      }
      if ((this.hour && this.hour.length === 5) || !this.hour) {
        this.$emit("input", this.hour);
      }
    },
    selectHour(hour) {
      this.hourSelected = hour;
      this.hour = hour;
      this.emitInput();
    },
    setInputColor() {
      const textField = this.$refs.hourTextField;
      if (textField) {
        const input = textField.$refs["input"];
        if (input) {
          input.style.color = this.inputColor;
        }
      }
    },
    open() {
      this.$refs.hourTextField.$refs["input"].click();
    },
  },
};
</script>
<style lang="scss" scoped>
.hour-item--marked {
  background: $gray-2-f2g;
}
.hour-item:hover {
  background: $gray-2-f2g;
}
.speech-bubble {
  position: relative;
  background: #ffffff;
  border-radius: 0.4em;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  top: 28px;
  left: 40%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-bottom-color: #ffffff;
  border-top: 0;
  margin-left: -20px;
  margin-top: 0px;
  z-index: 10;
}
</style>
