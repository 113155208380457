var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.userInfo)?_c('div',{staticClass:"driver-selected-data"},[_c('div',{staticClass:"flex flex-col flex-wrap mb-4"},[_c('div',{staticClass:"relative w-max"},[_c('h2',{staticClass:"relative leading-4 font-medium text-lg",style:({ color: _vm.colors.colorOnBackground })},[_vm._v(" "+_vm._s(_vm.$t("common.dataPrincipalDriver"))+" ")]),_c('div',{staticClass:"absolute -top-2 -right-10 flex flex-row align-end p-2 rounded-full",style:({ backgroundColor: _vm.colors.colorPrimary }),on:{"click":() => {
            _vm.userInfo.imageLinks = _vm.imageLinks;
            _vm.showEdit = true;
          }}},[_c('F2gIcon',{staticClass:"cursor-pointer",attrs:{"height":14,"width":14,"stroke":_vm.colors.colorOnPrimary,"file":"icons_solid.svg","value":'edit',"strokeWidth":4}})],1)])]),(!_vm.showEdit)?_c('div',[_c('div',{staticClass:"flex sm:flex-row flex-col w-full"},[(_vm.userInfo.name)?_c('span',{staticClass:"sm:w-1/3 w-full mr-2 p-1 pl-2 rounded-lg border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
        })},[_vm._v(_vm._s(_vm.userInfo.name + " " + _vm.userInfo.surnames))]):_vm._e(),(_vm.userInfo.username)?_c('span',{staticClass:"sm:w-1/3 w-full mr-2 p-1 pl-2 rounded-lg border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
        })},[_vm._v(_vm._s(_vm.userInfo.username))]):_vm._e(),(_vm.userInfo.phoneNumber)?_c('span',{staticClass:"sm:w-1/3 w-full mr-2 p-1 pl-2 rounded-lg border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
        })},[_vm._v(_vm._s(_vm.userInfo.phoneNumber))]):_vm._e()]),_c('div',{staticClass:"flex sm:flex-row flex-col w-full mt-2"},[(_vm.userInfo.bornDate)?_c('span',{staticClass:"sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
        })},[_vm._v(_vm._s(_vm.userInfo.bornDate))]):_vm._e(),(_vm.userInfo.country)?_c('span',{staticClass:"sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
        })},[_vm._v(_vm._s(_vm.$PageUtils.getObjectNameLower(_vm.userInfo.country)))]):_vm._e()]),(_vm.userInfo.address && _vm.userInfo.address.direction)?_c('div',{staticClass:"flex flex-row w-full sm:mt-2"},[_c('p',{staticClass:"leading-4 font-medium text-lg mb-3",style:({ color: _vm.colors.colorOnBackground, paddingTop: '5px' })},[_vm._v(" "+_vm._s(_vm.$t("forms.address.residenceAddress"))+" ")])]):_vm._e(),_c('div',{staticClass:"flex sm:flex-row flex-col w-full"},[(_vm.userInfo.address && _vm.userInfo.address.direction)?_c('span',{staticClass:"sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
        })},[_vm._v(_vm._s(_vm.userInfo.address.direction))]):_vm._e(),(_vm.userInfo.address && _vm.userInfo.address.directionAdditionalInfo)?_c('span',{staticClass:"sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
        })},[_vm._v(_vm._s(_vm.userInfo.address.directionAdditionalInfo))]):_vm._e()]),_c('div',{staticClass:"flex sm:flex-row flex-col w-full mt-2"},[(_vm.userInfo.address && _vm.userInfo.address.country)?_c('span',{staticClass:"sm:w-1/4 w-full mr-2 p-1 pl-2 rounded-lg border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
        })},[_vm._v(_vm._s(_vm.$PageUtils.getObjectNameLower(_vm.userInfo.address.country)))]):_vm._e(),(_vm.userInfo.address && _vm.userInfo.address.city)?_c('span',{staticClass:"sm:w-1/4 w-full mr-2 p-1 pl-2 rounded-lg border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
        })},[_vm._v(_vm._s(_vm.userInfo.address.city))]):_vm._e(),(_vm.userInfo.address && _vm.userInfo.address.locality)?_c('span',{staticClass:"sm:w-1/4 w-full mr-2 p-1 pl-2 rounded-lg border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
        })},[_vm._v(_vm._s(_vm.userInfo.address.locality))]):_vm._e(),(_vm.userInfo.address && _vm.userInfo.address.postalCode)?_c('span',{staticClass:"sm:w-1/4 w-full mr-2 p-1 pl-2 rounded-lg border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
        })},[_vm._v(_vm._s(_vm.userInfo.address.postalCode))]):_vm._e()]),(_vm.userInfo.nif)?_c('div',{staticClass:"flex flex-row w-full sm:mt-2"},[_c('p',{staticClass:"leading-4 font-medium text-lg mb-3",style:({ color: _vm.colors.colorOnBackground, paddingTop: '5px' })},[_vm._v(" "+_vm._s(_vm.$t("forms.nif"))+" ")])]):_vm._e(),_c('div',{staticClass:"flex sm:flex-row flex-col w-full"},[(_vm.userInfo.nif)?_c('span',{staticClass:"sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
        })},[_vm._v(_vm._s(_vm.userInfo.nif))]):_vm._e(),(_vm.userInfo.nifValidityDate)?_c('span',{staticClass:"sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
        })},[_vm._v(_vm._s(_vm.DateUtil.beautifullFormatedDate(new Date(_vm.userInfo.nifValidityDate))))]):_vm._e()]),_c('div',{staticClass:"flex sm:flex-row flex-col w-full mt-2 ml-2"},[_c('div',{staticClass:"flex flex-col"},[(_vm.imageLinks['imgDniFront'])?_c('v-img',{staticClass:"image rounded-lg mr-2",attrs:{"src":_vm.imageLinks['imgDniFront'],"aspect-ratio":16 / 9,"max-width":"300px"}}):_vm._e(),_c('span',{staticClass:"mr-4"},[(_vm.userInfo.nifImgFrontName)?_c('v-icon',{staticClass:"mb-1",attrs:{"left":"","small":"","color":_vm.colors.colorPrimary}},[_vm._v(" mdi-camera-plus ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.userInfo.nifImgFrontName))],1)],1),_c('div',{staticClass:"flex flex-col"},[(_vm.imageLinks['imgDniReverse'])?_c('v-img',{staticClass:"image rounded-lg mr-2",attrs:{"src":_vm.imageLinks['imgDniReverse'],"aspect-ratio":16 / 9,"max-width":"300px"}}):_vm._e(),_c('span',[(_vm.userInfo.nifImgReverseName)?_c('v-icon',{staticClass:"mb-1",attrs:{"left":"","small":"","color":_vm.colors.colorPrimary}},[_vm._v(" mdi-camera-plus ")]):_vm._e(),_vm._v(_vm._s(_vm.userInfo.nifImgReverseName))],1)],1)]),(_vm.userInfo.typeLicenseDriving)?_c('div',{staticClass:"flex flex-row w-full sm:mt-2"},[_c('p',{staticClass:"leading-4 font-medium text-lg mb-3",style:({ color: _vm.colors.colorOnBackground, paddingTop: '5px' })},[_vm._v(" "+_vm._s(_vm.$t("mobile.register.licenseDriving"))+" ")])]):_vm._e(),_c('div',{staticClass:"flex sm:flex-row flex-col w-full"},[(_vm.userInfo.typeLicenseDriving)?_c('span',{staticClass:"sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
        })},[_vm._v(_vm._s(_vm.userInfo.typeLicenseDriving.code === _vm.$CONSTANTS.DRIVING_LICENSE.TYPE.OBTAINED_IN_COUNTRY ? _vm.$t("forms.drivingLicenseCountry") + ": " + _vm.$PageUtils.getObjectNameLower(_vm.userInfo.countryLicenseDriving) : _vm.$t("common.drivingLicenseTypeB")))]):_vm._e(),(_vm.userInfo.drivingPermissionValidityDate)?_c('span',{staticClass:"sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
        })},[_vm._v(_vm._s(_vm.DateUtil.beautifullFormatedDate( new Date(_vm.userInfo.drivingPermissionValidityDate) )))]):_vm._e()]),_c('div',{staticClass:"flex sm:flex-row flex-col w-full mt-2 ml-2"},[_c('div',{staticClass:"flex flex-col"},[(_vm.imageLinks['imgDrivingLicenseFront'])?_c('v-img',{staticClass:"image rounded-lg mr-2",attrs:{"src":_vm.imageLinks['imgDrivingLicenseFront'],"aspect-ratio":16 / 9,"max-width":"300px"}}):_vm._e(),_c('span',{staticClass:"mr-4"},[(_vm.userInfo.drivingLicenseImgFrontName)?_c('v-icon',{staticClass:"mb-1",attrs:{"left":"","small":"","color":_vm.colors.colorPrimary}},[_vm._v(" mdi-camera-plus ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.userInfo.drivingLicenseImgFrontName))],1)],1),_c('div',{staticClass:"flex flex-col"},[(_vm.imageLinks['imgDrivingLicenseReverse'])?_c('v-img',{staticClass:"image rounded-lg mr-2",attrs:{"src":_vm.imageLinks['imgDrivingLicenseReverse'],"aspect-ratio":16 / 9,"max-width":"300px"}}):_vm._e(),_c('span',[(_vm.userInfo.drivingLicenseImgReverseName)?_c('v-icon',{staticClass:"mb-1",attrs:{"left":"","small":"","color":_vm.colors.colorPrimary}},[_vm._v(" mdi-camera-plus ")]):_vm._e(),_vm._v(_vm._s(_vm.userInfo.drivingLicenseImgReverseName))],1)],1)]),(_vm.userInfo.expeditionLicenseB)?_c('div',{staticClass:"flex flex-row w-full sm:mt-2"},[_c('p',{staticClass:"leading-4 font-medium text-lg mb-3",style:({ color: _vm.colors.colorOnBackground, paddingTop: '5px' })},[_vm._v(" "+_vm._s(_vm.$t("mobile.register.licenseTypeB"))+" ")])]):_vm._e(),_c('div',{staticClass:"flex sm:flex-row flex-col w-full"},[(_vm.userInfo.expeditionLicenseB)?_c('span',{staticClass:"w-full mr-2 p-1 pl-2 rounded-lg border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
        })},[_vm._v(_vm._s(_vm.DateUtil.beautifullFormatedDate( new Date(_vm.userInfo.expeditionLicenseB) )))]):_vm._e()]),(_vm.userInfo.expeditionLicenseA)?_c('div',{staticClass:"flex flex-row w-full sm:mt-2"},[_c('p',{staticClass:"leading-4 font-medium text-lg mb-3",style:({ color: _vm.colors.colorOnBackground, paddingTop: '5px' })},[_vm._v(" "+_vm._s(_vm.$t("mobile.register.licenseTypeA"))+" ")])]):_vm._e(),_c('div',{staticClass:"flex sm:flex-row flex-col w-full"},[(_vm.userInfo.expeditionLicenseA)?_c('span',{staticClass:"sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
        })},[_vm._v(_vm._s(_vm.DateUtil.beautifullFormatedDate( new Date(_vm.userInfo.expeditionLicenseA) )))]):_vm._e(),(_vm.userInfo.licenseTypeA)?_c('span',{staticClass:"sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
        })},[_vm._v(_vm._s(_vm.$t("common.type") + " " + _vm.userInfo.licenseTypeA))]):_vm._e()])]):_vm._e(),_c('span',{attrs:{"id":"top-part-register"}}),(_vm.showEdit)?_c('register-data',{ref:"registerData",attrs:{"ownerName":_vm.ownerName,"ownerIsPremium":_vm.ownerIsPremium,"validate":_vm.validate,"nationalities":_vm.user.nationalities,"userInfo":_vm.userInfo,"openExtraData":_vm.openExtraData},on:{"personalData":_vm.setPersonalData,"directionData":_vm.setDirectionData,"nifDrivingLicenseData":_vm.setNifDrivingLicenseData,"validationDirection":_vm.validateDirection,"validationNifDrivingLicenseData":_vm.validateNifDrivingLicenseData,"validation":_vm.validateData}}):_vm._e(),(_vm.showEdit)?_c('div',{staticClass:"flex sm:flex-row flex-col w-full mt-2"},[_c('span',{staticClass:"sm:w-48 sm:mr-2 w-full"},[_c('v-btn',{staticClass:"w-full lg:w-48 mb-4 mx-auto border-2 rounded-config30",attrs:{"color":_vm.colors.colorPrimary},on:{"click":_vm.validateFields}},[_c('span',{style:({ color: _vm.colors.colorOnPrimary })},[_vm._v(_vm._s(_vm.$t("confirmData")))])])],1),_c('span',{staticClass:"sm:w-48 w-full"},[_c('v-btn',{staticClass:"w-full lg:w-48 mb-4 mx-auto border-2 rounded-config30",attrs:{"outlined":"","color":_vm.colors.colorPrimary},on:{"click":() => {
            _vm.showEdit = false;
          }}},[_vm._v(_vm._s(_vm.$t("common.cancel"))+" ")])],1)]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }