<template>
  <div class="main-filter">
    <main-filter-component :type="`${$route.params.type}`" />
  </div>
</template>

<script>
import MainFilterComponent from "@/modules/v1/Components/main-filter-component";

export default {
  name: "MainFilter",
  components: {
    MainFilterComponent,
  },
};
</script>