const REPORT_TYPE = Object.freeze({
  VEHICLES: "VEH",
  SALES: "SAL",
  RENTALS: "REN",
});

const REPORT_URL_TYPE = Object.freeze({
  VEHICLES: "vehicles",
  SALES: "sales",
  RENTALS: "rentals",
});

const REPORT_SALE_TYPE = Object.freeze({
  GROUPED: "SGRO",
  BY_VEHICLE: "SVEH",
  BY_USER: "SUSE",
  BY_COMPANY: "SCOM",
  BY_PRICING: "SPRI",
  BY_LOCATION: "SLOC",
});

const REPORT_VEHICLE_TYPE = Object.freeze({
  MILEAGE: "VMIL",
  KMS_TRAVELED: "VKMS",
});

const REPORT_RENTAL_TYPE = Object.freeze({
  GROUPED: "RGRO",
  BY_VEHICLE: "RVEH",
  BY_USER: "VUSE",
  BY_COMPANY: "RCOM",
  BY_PRICING: "VPRI",
  BY_LOCATION: "VLON",
  BY_GROUP_SEATS: "RGRS",
});

const REPORT_PERIOD = Object.freeze({
  DAY: "DAY",
  HOUR: "HOUR",
  WEEK: "WEEK",
  MONTH: "MONTH",
});

const REPORT_RENTAL_TIME_TYPE = Object.freeze({
  CREATED: "CRE",
  STARTED: "STA",
  FINISHED: "FIN",
});

const REPORT_SALES_STATUS = Object.freeze({
  PAID: "PAI",
  PENDING: "PEN",
  NOT_INVOICED: "NIN",
  INVOICED: "INV",
});

const REPORT_SALES_DATES = Object.freeze({
  CREATION: "CRE",
  PAID: "PAI",
  INVOICE: "INV",
});

const REPORT_SALES_SERVICE_TYPE = Object.freeze({
  NO_SERVICE: "NSE",
  WITHOUT_RESERVE: "WRE",
  RESERVATION: "RES",
  SUBSCRIPTION: "SUB",
});

const REPORT_RENTALS_SERVICE_TYPE = Object.freeze({
  WITHOUT_RESERVE: "WRE",
  RESERVATION: "RES",
  SUBSCRIPTION: "SUB",
});

const REPORT_VEHICLE_CONNECTIVITY_TYPE = Object.freeze({
  CONNECTED: "VCO",
  NOT_CONNECTED: "VNC",
});

export default {
  TYPE: REPORT_TYPE,
  URL_TYPE: REPORT_URL_TYPE,
  SALE_TYPE: REPORT_SALE_TYPE,
  VEHICLE_TYPE: REPORT_VEHICLE_TYPE,
  RENTAL_TYPE: REPORT_RENTAL_TYPE,
  PERIOD: REPORT_PERIOD,
  RENTAL_TIME_TYPE: REPORT_RENTAL_TIME_TYPE,
  SALES_STATUS: REPORT_SALES_STATUS,
  SALES_DATES: REPORT_SALES_DATES,
  SALES_SERVICE_TYPE: REPORT_SALES_SERVICE_TYPE,
  RENTALS_SERVICE_TYPE: REPORT_RENTALS_SERVICE_TYPE,
  VEHICLE_CONNECTIVITY_TYPE: REPORT_VEHICLE_CONNECTIVITY_TYPE,
};
