<template>
  <div id="lmap">
    <slot v-if="ready" />
  </div>
</template>

<script src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"></script>

<script>
import L from "leaflet";

export default {
  name: "lmap",
  props: {
    centerLocation: {
      type: Array,
      required: false,
    },
    isFrameweb: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      defaultCoordinates: [],
      ready: false,
      lmap: null,
      zoom: 13,
    };
  },
  watch: {
    centerLocation: {
      immediate: true,
      deep: true,
      handler() {
        if (
          this.defaultCoordinates.length === 0 &&
          this.centerLocation &&
          this.centerLocation.length > 0
        ) {
          this.defaultCoordinates = [...this.centerLocation];
          this.init();
        }
      },
    },
  },
  mounted() {
    if (!this.isFrameweb) {
      navigator.permissions.query({ name: "geolocation" }).then((res) => {
        if (res.state === "granted") {
          navigator.geolocation.getCurrentPosition((position) => {
            this.defaultCoordinates = [
              position.coords.latitude,
              position.coords.longitude,
            ];
            this.init();
          });
        } else {
          this.$showMessage.error(
            this.$t(this.$CONSTANTS.ERROR.UBICATON_NOT_ACTIVATED)
          );
        }
      });
    } else {
      this.defaultCoordinates = [39.5484975, -0.4651093];
      this.init();
    }
  },
  methods: {
    clearMap() {
      for (let i in this.lmap._layers) {
        if (
          this.lmap._layers[i]._path != undefined &&
          // For watch lines between points in polygons when is drawing
          this.lmap._layers[i]._parts.length > 0
        ) {
          try {
            this.lmap.removeLayer(this.lmap._layers[i]);
          } catch (e) {
            this.$showMessage.error("problem with " + e + this.lmap._layers[i]);
          }
        }
      }
    },
    centerMap(coords) {
      if (coords.length > 0 && this.ready) {
        this.lmap.setView(coords);
      }
    },
    /**
     * @param figure = Polygon, circle, marker ...
     */
    zoomFigure(figure) {
      this.lmap.fitBounds(figure.getBounds());
    },
    resize() {
      this.lmap.invalidateSize(true);
    },
    init() {
      if (this.defaultCoordinates.length > 0 && !this.ready) {
        const container = document.getElementById("lmap");
        if (this.lmap) {
          this.lmap.remove();
        }
        if (container !== null) {
          // Map instance
          this.lmap = L.map("lmap", {
            zoomControl: false,
            minZoom: 3,
            zoom: this.zoom,
          }).setView(this.defaultCoordinates);
          // Show zoom control buttons
          // L.control.zoom({ position: "topright" }).addTo(this.lmap);
          //https://leafletjs.com/examples/map-panes/ (Enable layers)
          this.lmap.createPane("labels");
          this.lmap.getPane("labels").style.zIndex = 650;
          this.lmap.getPane("labels").style.pointerEvents = "none";

          if ("cartocdn" == process.env.VUE_APP_LEAFLET_MAP) {
            // Show terrain background image
            L.tileLayer(
              "http://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png"
            ).addTo(this.lmap);
            // Show city and streets names
            L.tileLayer(
              "http://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png",
              {
                pane: "labels",
              }
            ).addTo(this.lmap);
          }
          // Map for deployment
          /*classic styles, dont work
      https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}
      Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>
      mapbox.streets*/
          if ("mapbox" == process.env.VUE_APP_LEAFLET_MAP) {
            L.tileLayer(
              "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
              {
                attribution:
                  '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
                tileSize: 512,
                zoomOffset: -1,
                id: "mapbox/streets-v11",
                accessToken: process.env.VUE_APP_LEAFLET_MAPBOX_TOKEN,
              }
            ).addTo(this.lmap);
          }
          // Activate flag to show slot
          this.ready = true;
        }
      }
    },
  },
};
</script>

<style scoped>
@import "https://unpkg.com/leaflet@1.4.0/dist/leaflet.css";

#lmap {
  height: 100%;
  width: 100%;
  z-index: 0;
}
#map .v-card {
  z-index: 1;
}
</style>
