<template>
  <div class="extras-listV2 flex flex-col mx-2">
    <!-- Characteristics-->
    <h2
      class="leading-4 font-medium text-lg mt-6 lg:mt-10"
      :style="{ color: colors.colorOnBackground }"
    >
      {{ $t("common.vehicleCharacteristics") }}
    </h2>
    <div class="text-sm">
      <div class="w-full flex flex-row align-center flex-wrap">
        <div
          class="inline-block mr-2 mt-4"
          v-for="(attribute, index) in categorySelected.attributeList"
          :key="index"
        >
          <div
            class="text-sm align-middle mr-2 inline-block rounded-md flex-none"
            :style="{
              backgroundColor: colors.colorSurfaceMedium,
              color: colors.colorPrimary,
            }"
          >
            <div class="pa-1 m-1 inline-block align-middle">
              <svg
                style="height: 24px; width: 24px"
                :fill="colors.colorPrimary"
              >
                <path v-if="attribute.icon.path" :d="attribute.icon.path" />
              </svg>
            </div>
            <div
              class="align-center inline-block align-middle mr-3"
              :style="{ color: colors.colorOnSurfaceMedium }"
            >
              {{
                attribute.supportedLangs[$i18n.locale.split("_")[0]]
                  ? attribute.supportedLangs[$i18n.locale.split("_")[0]]
                  : attribute.supportedLangs.en
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Kms and petrol -->
    <div class="mt-6">
      <div class="align-middle">
        <h2
          class="leading-4 font-medium text-lg inline-block align-middle"
          :style="{ color: colors.colorOnBackground }"
        >
          {{ $t("common.kilometersAndPetrol") }}
        </h2>
      </div>
    </div>
    <div class="w-full flex flex-row align-center flex-wrap">
      <div class="inline-block mr-2">
        <div
          class="text-sm align-middle mr-2 inline-block rounded-md flex-none mt-4 border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
            color: colors.colorOnSurface,
          }"
        >
          <div class="pa-1 m-1 inline-block align-middle">
            <F2gIcon
              class="mx-1"
              :value="'counter'"
              :stroke="colors.colorPrimary"
              :height="16"
              :width="25"
              :strokeWidth="4"
            />
          </div>
          <div
            v-if="actualPrice"
            class="align-center inline-block align-middle mr-3 py-2"
            :style="{ color: colors.colorOnSurface }"
          >
            {{
              actualPrice.kmIncluded === -1
                ? $t("unlimitedKm")
                : actualPrice.kmIncluded === 0
                ? $t("mileageNotIncluded")
                : `${actualPrice.kmIncluded} ${$t("common.includedKilometers")}`
            }}

            <div
              v-if="
                categorySelected.kmPriceExtra && actualPrice.kmIncluded !== -1
              "
              class="text-xs align-center"
              :style="{ color: colors.colorOnSurface }"
            >
              *{{ $t("common.extraKmCost") }}:
              {{
                BillingUtil.formatBillingValue(
                  categorySelected.kmPriceExtra,
                  categorySelected.symbol
                )
              }}/km
            </div>
          </div>
        </div>
        <div
          v-if="
            categorySelected.vehicleType &&
              categorySelected.vehicleType.code &&
              categorySelected.vehicleType.code !==
                $CONSTANTS.VEHICLE.TYPE.SCOOTER
          "
          class="text-sm align-middle mr-2 inline-block rounded-md flex-none mt-4 border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
            color: colors.colorOnSurface,
          }"
        >
          <div class="pa-1 m-1 inline-block align-middle">
            <F2gIcon
              class="mx-1"
              :value="'gas-station'"
              :stroke="colors.colorPrimary"
              :height="16"
              :width="25"
              :strokeWidth="4"
            />
          </div>
          <div
            class="align-center inline-block align-middle mr-3 py-2"
            :style="{ color: colors.colorOnSurface }"
          >
            {{ $t("common.fullFull") }}
            <div
              v-if="categorySelected.consumptionPenalization"
              class="-left-10 text-xs align-center"
              :style="{ color: colors.colorOnSurface }"
            >
              *{{ $t("common.extraFuelDifference") }}:
              {{
                BillingUtil.formatBillingValue(
                  categorySelected.consumptionPenalization,
                  categorySelected.symbol
                )
              }}/l
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Franchises -->
    <h2
      class="leading-4 font-medium text-lg pt-1 mt-6"
      :style="{ color: colors.colorOnBackground }"
      v-if="franchises"
    >
      {{ $t("common.franchise") }}
    </h2>
    <div
      class="flex flex-col md:flex-row mt-2 rounded md:gap-20 gap-4"
      v-if="franchises"
    >
      <div>
        <v-radio-group
          v-model="radioGroupFranchise"
          v-for="(franchise, index) in franchises"
          :key="index"
          :dark="colors.colorBackground === '#000000' ? true : false"
          hide-details
          class="flex flex-row"
          @change="setFranchise()"
        >
          <div class="flex flex-row">
            <v-radio
              :key="franchise.type"
              :value="franchise"
              :color="colors.colorPrimary"
            >
              <template v-slot:label>
                <div class="flex flex-col">
                  <div
                    class="font-weight-medium"
                    :style="{ color: colors.colorOnBackground }"
                  >
                    {{ franchise.message }}
                  </div>
                  <div
                    class="font-weight-regular caption"
                    :style="{ color: colors.colorOnBackground }"
                  >
                    {{ franchise.dayPrice }}
                  </div>
                </div>
              </template>
            </v-radio>
            <F2gIcon
              class="svg ml-2 cursor-pointer"
              :value="'info'"
              :fill="colors.colorOnSurfaceMedium"
              file="icons_solid.svg"
              solid
              :height="20"
              :width="20"
              :strokeWidth="4"
              @click="
                () => {
                  showInfoMessage(index);
                }
              "
            />
          </div>
        </v-radio-group>
        <!-- <div
          v-if="radioGroupFranchiseErrors.length"
          class="text-red-700 mt-4 ml-3"
        >
          {{ radioGroupFranchiseErrors[0] }}
        </div> -->
      </div>

      <div
        class="w-full max-w-104"
        v-if="franchiseInfoMessage && showFranchiseInfoMessage"
      >
        <div
          class="p-2 shadow rounded-t-lg flex justify-between"
          :style="{ backgroundColor: colors.colorSurfaceMedium }"
        >
          <div class="flex flex-row align-center px-1">
            <F2gIcon
              class="svg mr-2"
              :value="'info'"
              :fill="colors.colorOnSurfaceMedium"
              file="icons_solid.svg"
              solid
              :height="20"
              :width="20"
              :strokeWidth="4"
            />
            <h3
              class="title font-normal roun"
              :style="{ color: colors.colorOnSurfaceMedium }"
            >
              {{ franchiseInfoMessage.message }}
            </h3>
            <div class="flex justify-end">
              <F2gIcon
                class="svg mr-2 cursor-pointer"
                value="close"
                :fill="colors.colorOnSurfaceMedium"
                :height="26"
                :width="26"
                :strokeWidth="6"
                @click="showFranchiseInfoMessage = false"
              />
            </div>
          </div>
        </div>
        <div
          class="flex-col p-2 px-3 rounded-b-lg shadow-md"
          :style="{
            backgroundColor: colors.colorSurface,
            color: colors.colorOnSurface,
          }"
        >
          <span>{{ selectLabelFranchiseAlert(franchiseInfoMessage) }}</span>
          <p class="text-gray-500 text-xs pt-2">
            {{ franchiseInfoMessage.dayPrice }}
          </p>
        </div>
        <div
          class="flex flex-col--without-important md:flex-row justify-between"
        >
          <div class="justify-start ml-2"></div>
        </div>
      </div>
    </div>

    <!-- Extras List -->
    <v-expansion-panels
      v-model="showExtrasPanel"
      class="mt-8 z-0"
      id="extras-panel"
      v-if="
        (categorySelected.extraList && categorySelected.extraList.length) ||
          (categorySelected.additionalServicesList &&
            categorySelected.additionalServicesList.length)
      "
    >
      <v-expansion-panel
        class="rounded-lg"
        ref="extrasPanel"
        :style="{
          backgroundColor: colors.colorSurface,
          color: colors.colorOnSurface,
        }"
      >
        <v-expansion-panel-header>
          <h2
            class="font-medium text-lg py-1 flex flex-none"
            :style="{ color: colors.colorOnSurface }"
          >
            {{ $t("extrasAndOptionalServices") }}
          </h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>
            <div
              class="flex flex-col flex-wrap"
              v-if="
                categorySelected.additionalServicesList &&
                  categorySelected.additionalServicesList.length
              "
            >
              <div class="w-full">
                <category-additionals-extras
                  :itemsList="categorySelected.additionalServicesList"
                  :type="'additional'"
                  @addedExtra="extraAdded"
                  @servicesValidation="
                    (validation) => $emit('servicesValidation', validation)
                  "
                />
              </div>
            </div>
            <div
              class="flex flex-col flex-wrap mt-4"
              v-if="
                categorySelected.extraList && categorySelected.extraList.length
              "
            >
              <category-additionals-extras
                :itemsList="categorySelected.extraList"
                :type="'extras'"
                @addedExtra="extraAdded"
              />
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CategoryAdditionalsExtras from "@/components/category-additionals-extras.vue";
import { EventBus } from "@/flit2go-web-core/util/event-bus.js";
// import { requiredIf } from "vuelidate/lib/validators";
import BillingUtil from "@/flit2go-web-core/util/billingUtil.js";

export default {
  name: "ExtrasListV2",
  props: {
    showExtras: { required: false, type: Boolean, default: false },
  },
  components: {
    CategoryAdditionalsExtras,
  },
  data() {
    return {
      BillingUtil,
      radioGroupFranchise: null,
      franchises: null,
      showExtrasPanel: null,
      showFranchiseInfoMessage: false,
      franchiseInfoMessage: null,
    };
  },
  validations: {
    // radioGroupFranchise: {
    //   required: requiredIf(function() {
    //     return this.franchises;
    //   }),
    // },
  },
  watch: {
    showExtras() {
      this.showExtrasPanel = this.showExtras ? 0 : undefined;
    },
    showExtrasPanel() {
      EventBus.$emit("hideDialogExtras");
    },
  },
  computed: {
    ...mapState(["category", "configuration"]),
    ...mapGetters({
      colors: "configuration/getColors",
      categorySelected: "category/categorySelected",
      bookingFilters: "configuration/getBookingFilters",
      userLanguage: "configuration/getUserLanguage",
      actualPrice: "configuration/getActualPrice",
      packSelected: "category/packSelected",
    }),
    borderColor() {
      return `border-color: ${this.colors.colorPrimary}`;
    },

    // radioGroupFranchiseErrors() {
    //   const errors = [];
    //   if (this.$v.radioGroupFranchise.$dirty) {
    //     !this.$v.radioGroupFranchise.required &&
    //       errors.push(this.$t("errors.required"));
    //   }
    //   return errors;
    // },
    hasAdditionalsRequired() {
      let hasAdditionalsRequiredToCheck = false;
      if (
        this.categorySelected.additionalServicesList &&
        this.categorySelected.additionalServicesList.length
      ) {
        this.categorySelected.additionalServicesList.forEach((additional) => {
          if (additional.mandatory) {
            hasAdditionalsRequiredToCheck = true;
            this.$emit("servicesValidation", false);
          }
        });
      }
      return hasAdditionalsRequiredToCheck;
    },
  },
  mounted() {
    this.getFranchises();
    let userUbication = window.localStorage.getItem("user-ubication");
    this.showExtrasPanel =
      this.hasAdditionalsRequired || userUbication ? 0 : undefined;
  },
  methods: {
    getFranchises() {
      let params = {};
      params.categoryId = this.categorySelected.id;
      params.params = {
        earlyPay: this.categorySelected.earlyPay,
        lang: this.userLanguage,
        packId: this.packSelected?.id ? this.packSelected.id : null,
      };
      this.$store
        .dispatch("category/getFranchisesByCategoryId", params)
        .then((resp) => {
          this.franchises = resp.data;
          this.radioGroupFranchise = resp.data[resp.data.length - 1];
          this.$store.commit(
            "category/SET_FRANCHISE_SELECTED",
            this.radioGroupFranchise
          );
          this.validate();
        })
        .catch(() => {
          this.franchises = null;
        });
    },
    selectLabelFranchiseAlert(franchise) {
      if (franchise.franchiseType == 2) {
        return this.$t("common.damageFranchiseAlert", {
          number: BillingUtil.formatBillingValue(
            franchise.value,
            franchise.price &&
              franchise.price.currency &&
              franchise.franchiseType != 0
              ? franchise.price.currency
              : ""
          ),
        });
      }
      if (franchise.franchiseType == 1) {
        return this.$t("common.damageFranchiseAlert", {
          number: BillingUtil.formatBillingValue(
            franchise.value,
            franchise.price &&
              franchise.price.currency &&
              franchise.franchiseType != 0
              ? franchise.price.currency
              : ""
          ),
        });
      }
      if (franchise.franchiseType == 0) {
        return this.$t("common.damageWithoutFranchiseAlert");
      }
    },
    extraAdded(arraySelecteds) {
      if (arraySelecteds.type == "additional") {
        arraySelecteds.extras.forEach((extraOfList, index) => {
          if (
            extraOfList.type &&
            extraOfList.type.code ===
              this.$CONSTANTS.CATEGORY.SERVICE.ADDITIONAL_DRIVER &&
            !extraOfList.name
          ) {
            arraySelecteds.extras.splice(index, 1);
          }
        });
        this.$store.commit(
          "configuration/SET_ADDITIONALS_SELECTED",
          arraySelecteds.extras
        );
      } else {
        this.$store.commit(
          "configuration/SET_EXTRAS_SELECTED",
          arraySelecteds.extras
        );
      }
      EventBus.$emit("getPrice");
    },
    setFranchise() {
      this.$store.commit(
        "category/SET_FRANCHISE_SELECTED",
        this.radioGroupFranchise
      );
      EventBus.$emit("getPrice");
      this.validate();
    },
    validate() {
      this.$v.$touch();
      this.$emit("validation", {
        valid: !this.$v.$invalid,
      });
    },
    showInfoMessage(index) {
      this.showFranchiseInfoMessage = true;
      this.franchiseInfoMessage = this.franchises[index];
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-container {
  height: 35px;
  width: 35px;
  border-radius: 100%;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 250ms ease-in;
}
</style>
