const TRAVEL_STATUS = Object.freeze({
  CLEANLINESS_PROCESS: "CLP",
  PENDING_TRAVEL: "PTR",
  PRE_TRAVELING: "PTA",
  PENDING_CLOSE_PROCESS: "PCL",
  PENDING_FUEL: "PEF",
  TRAVELLING: "TRA",
  NO_PHOTOS: "NPH",
  EXTERIOR_PHOTO_PROCESS: "EPP",
  FINALIZED: "FIN",
});

export default {
  STATUS: TRAVEL_STATUS,
};
