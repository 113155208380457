<template>
  <div class="branch-card-detail-horizontal">
    <div class="h-80 flex flex-row w-full max-h-full">
      <div
        class="min-h-64 max-h-full flex flex-col overflow-y-scroll branch-card-detail-horizontal__branches"
        :style="`--primary_color:${colors.colorPrimary}`"
      >
        <div class="w-full max-h-full py-2">
          <div
            v-for="(branch, index) in branches"
            :key="index"
            class="cursor-pointer rounded flex flex-col"
          >
            <div
              class="flex flex-row hoverList p-2"
              :class="
                branchToShow && branchToShow.id === branch.id
                  ? 'backgroundSelected'
                  : ''
              "
              :style="`--custom_color:${colors.colorSurfaceMedium}`"
            >
              <F2gIcon
                :style="`cursor: pointer, color: ${colors.colorPrimary}`"
                :fill="$CONSTANTS.COLOUR.BLUE_1_F2G"
                :height="22"
                :width="20"
                class="cursor-pointer"
                value="info"
                solid
                file="icons_solid.svg"
                :strokeWidth="2"
                @click="getBranchInfo(branch)"
              />
              <div class="ml-2 w-full" @click="sendBranch(branch)">
                {{ branch.name }}
              </div>
            </div>
            <div
              class="flex flex-col my-1 relative"
              v-if="branchInfo && branchInfo.id === branch.id"
            >
              <div class="absolute top-0 right-0 z-20 cursor-pointer mr-1">
                <F2gIcon
                  value="close"
                  :height="26"
                  :width="26"
                  :stroke="colors.colorOnSurfaceMedium"
                  :strokeWidth="6"
                  @click="branchInfo = null"
                />
              </div>
              <div class="mr-4 overflow-y-scroll w-full">
                <div
                  v-for="(day, index) in getScheduleList"
                  :key="index"
                  class="ml-2"
                >
                  <div class="w-full grid grid-cols-2 text-sm">
                    <div class="w-full">
                      {{ $PageUtils.getObjectNameLower(day.day) }}
                    </div>

                    <div
                      :class="day.closed ? 'text-red-700' : ''"
                      class="flex flex-col"
                    >
                      <span>{{ getOpenTimeFormatted(day) }}</span>

                      <span>{{ getOpenTimeAdditionalFormatted(day) }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <v-skeleton-loader
                v-show="loading"
                height="250px"
                width="250px"
                type="image"
                class="mr-4"
              ></v-skeleton-loader>

              <div v-if="size === 'full'" class="m-2">
                <div
                  class="relative w-full h-full shadow min-h-10 rounded z-5 p-2"
                >
                  <LMap
                    v-show="branchInfo"
                    ref="map"
                    class="relative rounded"
                    :centerLocation="centerLocation"
                    :isFrameweb="true"
                  >
                    <LMarker
                      ref="marker"
                      :lat="branchInfo.latitude"
                      :lng="branchInfo.longitude"
                      :zoom="false"
                    />
                  </LMap>
                  <v-btn
                    v-if="branchInfo && branchInfo.latitude"
                    class="absolute bottom-2 right-2 border"
                    :style="{
                      backgroundColor: colors.colorPrimary,
                      'border-color': colors.colorOnPrimary,
                    }"
                    width="40px"
                    height="40px"
                    outlined
                    fab
                    bottom
                    right
                    @click="centerMap"
                  >
                    <F2gIcon
                      value="send"
                      :stroke="colors.colorOnPrimary"
                      :strokeWidth="3"
                    />
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LMap from "@/flit2go-web-core/components/ui/lmap";
import LMarker from "@/flit2go-web-core/components/ui/lmarker";
import NoDataEnum from "@/flit2go-web-core/interfaces/no-data-enum";

export default {
  name: "BranchCardDetailHorizontal",
  components: {
    LMap,
    LMarker,
  },
  props: {
    branches: { required: true, type: Array },
    type: { required: true, type: String, default: "" },
    size: { required: true, type: String, default: "" },
    branchToShow: { required: false, type: Object, default: () => {} },
  },
  data() {
    return {
      NoDataEnum,
      branchInfo: null,
      centerLocation: null,
      loading: false,
    };
  },
  watch: {},
  mounted() {},

  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
    }),
    getScheduleList() {
      if (this.type === "pickUp") {
        return this.branchInfo.scheduleBranchOffice;
      } else {
        if (this.branchInfo.scheduleBranchOfficeBack.length) {
          return this.branchInfo.scheduleBranchOfficeBack;
        } else {
          return this.branchInfo.scheduleBranchOffice;
        }
      }
    },
  },
  methods: {
    getBranchInfo(branch) {
      this.loading = true;
      this.branchInfo = null;
      this.$store
        .dispatch("branchVehicle/getBranchById", branch.id)
        .then((resp) => {
          this.loading = false;
          this.branchInfo = resp;
          this.centerLocation = [
            this.branchInfo.latitude,
            this.branchInfo.longitude,
          ];
          setTimeout(() => this.centerMap(), 500);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    sendBranch(branch) {
      this.$emit("branchSelected", branch);
    },
    centerMap() {
      if (this.branchInfo && this.$refs.map) {
        this.$refs.map.centerMap([
          this.branchInfo.latitude,
          this.branchInfo.longitude,
        ]);
      }
    },
    getHourOpen(day, additional = false) {
      let label = "";
      if (!additional) {
        if (day.openTime) {
          label = day.openTime.split(":")[0] + ":" + day.openTime.split(":")[1];
        } else {
          if (day.open24h) {
            label = "00:00";
          }
        }
      } else {
        const openTimeAdditionalSplitted = day.openTimeAdditional.split(":");
        label =
          label +
          openTimeAdditionalSplitted[0] +
          ":" +
          openTimeAdditionalSplitted[1];
      }
      return label;
    },
    getHourClose(day, additional = false) {
      let label = "";
      if (!additional) {
        if (day.closeTime) {
          label =
            day.closeTime.split(":")[0] + ":" + day.closeTime.split(":")[1];
        } else {
          if (day.open24h) {
            label = "24:00";
          }
        }
      } else {
        const closeTimeAdditionalSplitted = day.closeTimeAdditional.split(":");
        label =
          label +
          closeTimeAdditionalSplitted[0] +
          ":" +
          closeTimeAdditionalSplitted[1];
      }
      return label;
    },
    getOpenTimeFormatted(day) {
      const openTime = this.getHourOpen(day);
      const closeTime = this.getHourClose(day);
      let timeFormatted = "";
      if (openTime === "00:00" && closeTime === "24:00") {
        timeFormatted = "24h";
      } else {
        timeFormatted = `${openTime}-${closeTime}`;
      }
      if (day.closed) {
        timeFormatted = this.$t("common.closed");
      }
      return timeFormatted;
    },
    getOpenTimeAdditionalFormatted(day) {
      if (day.openTimeAdditional) {
        return `${this.getHourOpen(day, true)}-${this.getHourClose(day, true)}`;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.hoverList {
  &:hover {
    background: var(--custom_color);
  }
}
.backgroundSelected {
  background: var(--custom_color);
}
.branch-card-detail-horizontal {
  &__branches {
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--primary_color);
      border-radius: 30px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--primary_color);
    }
  }
}
</style>
