<template>
  <div class="vehicle-selected-card">
    <div class="flex flex-col flex-wrap md:flex-row md:my-4 w-full">
      <!-- Vehicle -->
      <div class="p-2 flex flex-col md:flex-row items-center">
        <!-- CategoryImage -->
        <div class="flex flex-col md:min-w-320 md:max-w-320 min-w-18 max-w-1/3">
          <v-flex class="inline-block">
            <v-img
              :src="categorySelected.img"
              :aspect-ratio="16 / 9"
              class="image rounded-lg"
            />
          </v-flex>
        </div>

        <!-- CategoryData -->
        <div class="mt-3 ml-4 w-1/2">
          <!-- CategoryName -->
          <h2
            class="text-3xl font-bold"
            :style="{ color: colors.colorPrimary }"
          >
            {{ categorySelected.name }}
          </h2>
          <div
            v-if="packSelected"
            class="flex flex-row align-center"
            :style="{ color: colors.colorPrimary }"
          >
            <div class="text-2xl font-bold">
              {{
                `${$t("pack")} ${packSelected.name} (${BillingUtil.formatNumber(
                  getPricePerDay()
                )}${categorySelected.symbol} / ${$t(
                  packSelected.timeType === "day" ? "common.day" : "common.hour"
                )})`
              }}
            </div>
          </div>
          <div
            v-else
            class="flex flex-row align-center"
            :style="{ color: colors.colorPrimary }"
          >
            <div class="text-2xl font-bold">
              {{
                BillingUtil.formatBillingValue(
                  getPricePerDay(),
                  categorySelected.symbol
                )
              }}
            </div>
            <div class="text-lg ml-2">
              {{
                categorySelected.timeType == "day"
                  ? $t("common.perDay")
                  : $t("common.perHour")
              }}
            </div>
          </div>
        </div>
      </div>
      <!-- CategoryDesciption -->
      <div
        v-if="categorySelected.description"
        :style="{ color: colors.colorOnSurfaceMedium }"
        class="my-6 mx-2"
        v-html="categorySelected.description[this.$i18n.locale.split('_')[0]]"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BillingUtil from "@/flit2go-web-core/util/billingUtil.js";

export default {
  name: "VehicleSelectedCard",
  components: {},
  data() {
    return {
      BillingUtil,
      loading: true,
      categoryList: [],
    };
  },
  computed: {
    ...mapGetters({
      categorySelected: "category/categorySelected",
      colors: "configuration/getColors",
      packSelected: "category/packSelected",
    }),
    borderColor() {
      return `border-color: ${this.colors.colorPrimary}`;
    },
  },
  methods: {
    getPricePerDay() {
      let price;
      if (this.packSelected) {
        if (
          this.packSelected.deferredPayment &&
          this.packSelected.pricePerTimeDeferred &&
          this.packSelected.deferredDiscount
        ) {
          price = this.packSelected.pricePerTimeDeferred;
        } else if (this.packSelected.pricePerTime) {
          price = this.packSelected.pricePerTime;
        } else {
          price = 0;
        }
      } else if (this.categorySelected.earlyPay) {
        price = this.categorySelected.pricePerTimeDeferred;
      } else {
        price = this.categorySelected.pricePerTime
          ? this.categorySelected.pricePerTime.toFixed(2)
          : 0;
      }
      return price;
    },
    returnPrice() {
      if (this.categorySelected.deferredPayment) {
        return this.categorySelected.totalPriceDeferredDiscount;
      } else {
        return this.categorySelected.totalPrice
          ? this.categorySelected.totalPrice
          : "0";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
