const PAY_ORDER_STATUS = Object.freeze({
  CONFIRMED: "CNF",
  CREATED: "CRE",
  PENDING_CONFIRM_PROCESS: "PCF",
  PENDING_CONFIRM_OWNER: "PCO",
  PENDING_CONFIRM_RECEIPT: "PCR",
  PENDING_BILLING: "POI",
  PENDING_PAYMENT_OWNER: "POO",
  PENDING_PAYMENT_FLIT2GO: "PPF",
  PENDING_INVOICE_NUMBERING: "PIN",
  WITHOUT_BILLING: "WBI",
  PENDING_CONFIRMATION: "PCA",
});

const PAY_ORDER_TYPE = Object.freeze({
  COLLECTION_ORDER: "COL",
  PAYMENT_ORDER: "PAY",
});

const PAY_ORDER_PERIODICITY = Object.freeze({
  BIWEEKLY: "BIW",
  MONTHLY: "MON",
});

export default Object.freeze({
  STATUS: PAY_ORDER_STATUS,
  TYPE: PAY_ORDER_TYPE,
  PERIODICITY: PAY_ORDER_PERIODICITY,
});
