<template>
  <div class="resume-dataV2">
    <div class="flex flex-col p-2" id="top-part">
      <div
        class="p-2 rounded-lg shadow-md"
        :style="{ backgroundColor: colors.colorSurface }"
      >
        <h2 class="font-bold ml-3" :style="{ color: colors.colorOnSurface }">
          {{ $t("delivery").toUpperCase() }}
        </h2>
        <div class="flex flex-row">
          <div class="w-full pt-1 flex flex-row relative ml-3">
            <div
              class="flex flex-col mb-3"
              :style="{ color: colors.colorOnSurface }"
            >
              <div class="font-bold text-xs">
                {{ bookingFilters.pickUpNameBranch }}
              </div>
              <div class="text-xs mb-4" v-if="filters.showBranchAddress">
                {{ bookingFilters.pickUpDirectionBranch }}
              </div>
            </div>
            <div
              class="absolute -bottom-2 -right-2 flex flex-row align-end p-2 rounded-br-lg rounded-tl-lg shadow-md"
              :style="{ backgroundColor: colors.colorSurfaceMedium }"
            >
              <span
                class="text-xs mx-2 font-bold"
                :style="{ color: colors.colorOnSurfaceMedium }"
              >
                {{
                  DateUtil.beautifullDateHour(Number(bookingFilters.pickUpDate))
                }}</span
              >
            </div>
            <div
              v-if="currentStep === 1"
              class="absolute -top-6 right-0 flex flex-row align-end p-2 rounded-full"
              :style="{ backgroundColor: colors.colorPrimary }"
              @click="editButton"
            >
              <F2gIcon
                :height="14"
                :width="14"
                :stroke="colors.colorOnPrimary"
                file="icons_solid.svg"
                class="cursor-pointer"
                :value="'edit'"
                :strokeWidth="4"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="p-2 rounded-lg mt-3 shadow-md"
        :style="{ backgroundColor: colors.colorSurface }"
      >
        <h2 class="font-bold ml-3" :style="{ color: colors.colorOnSurface }">
          {{ $t("common.returnBack").toUpperCase() }}
        </h2>
        <div class="flex flex-row">
          <!-- <div class="h-10 min-w-min max-w-xs mx-2 my-2">
            <div class="bg-gray-200 w-10 max-h-full rounded">
              `https://maps.googleapis.com/maps/api/streetview?size=100x100&location=${bookingFilters.returnBackLatitude},${bookingFilters.returnBackLongitude}&fov=80&heading=70&pitch=0&key=${apiGoogleMaps}`
              <image-aspect-ratio
                class="flex-col-center"
                :border="true"
                :img="bookingFilters.returnLogo"
                minHeight="2.5rem"
                maxHeight="2.5rem"
                minWidth="2.5rem"
                maxWidth="2.5rem"
              />
            </div>
          </div> -->
          <div class="w-full pt-1 flex flex-row relative ml-3">
            <span
              class="flex flex-col mb-3"
              :style="{ color: colors.colorOnSurface }"
            >
              <span class="font-bold text-xs">
                {{ bookingFilters.returnBackNameBranch }}</span
              >
              <span class="text-xs mb-4" v-if="filters.showBranchAddress">
                {{ bookingFilters.returnBackDirectionBranch }}</span
              >
            </span>
            <div
              class="absolute -bottom-2 -right-2 flex flex-row align-end p-2 rounded-br-lg rounded-tl-lg shadow-md"
              :style="{ backgroundColor: colors.colorSurfaceMedium }"
            >
              <span
                class="text-xs mx-2 font-bold"
                :style="{ color: colors.colorOnSurfaceMedium }"
              >
                {{
                  DateUtil.beautifullDateHour(
                    Number(bookingFilters.returnBackDate)
                  )
                }}</span
              >
            </div>
            <div
              v-if="currentStep === 1"
              class="absolute -top-6 right-0 flex flex-row align-end p-2 rounded-full"
              :style="{ backgroundColor: colors.colorPrimary }"
              @click="editButton"
            >
              <F2gIcon
                :height="14"
                :width="14"
                :stroke="colors.colorOnPrimary"
                file="icons_solid.svg"
                class="cursor-pointer"
                :value="'edit'"
                :strokeWidth="4"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="categorySelected && categorySelected.id"
        class="p-4 rounded-lg mt-3 shadow-md"
        :style="{ backgroundColor: colors.colorSurfaceMedium }"
      >
        <div
          class="flex flex-row"
          v-if="categorySelected && categorySelected.id"
        >
          <div class="h-16 mr-2">
            <div class="bg-gray-100 w-24 h-16 rounded-sm">
              <image-aspect-ratio
                class="flex-col-center mr-2"
                :border="true"
                :border25="false"
                :img="categorySelected.img"
                minHeight="4rem"
                maxHeight="4rem"
                min-width="6rem"
                maxWidth="6rem"
              />
            </div>
          </div>
          <div class="flex-col justify-center">
            <h1
              class="text-base text--bold"
              :style="{ color: colors.colorOnSurfaceMedium }"
            >
              {{ categorySelected.name }}
            </h1>
            <div v-if="packSelected" class="text-base font-bold">
              {{
                `${$t("pack")} ${packSelected.name} (${BillingUtil.formatNumber(
                  getPricePerDay()
                )}${categorySelected.symbol} / ${$t(
                  packSelected.timeType === "day" ? "common.day" : "common.hour"
                )})`
              }}
            </div>
            <div
              v-else
              class="text-base font-bold"
              :style="{ color: colors.colorOnSurfaceMedium }"
            >
              {{ BillingUtil.formatNumber(getPricePerDay())
              }}{{ categorySelected.symbol }}
              <span class="text-xs">
                {{
                  categorySelected.timeType == "day"
                    ? $t("common.perDay")
                    : $t("common.perHour")
                }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="bookingInfo && categorySelected && categorySelected.id"
        class="max-h-full p-4 rounded-lg mt-6 shadow-md"
        :style="{ backgroundColor: colors.colorSurface }"
      >
        <h2 class="font-bold" :style="{ color: colors.colorOnSurface }">
          {{ $t("common.price").toUpperCase() }}
        </h2>
        <div class="max-h-full">
          <div class="flex flex-row justify-between">
            <span
              v-if="
                !categorySelected.earlyPay && categorySelected.deferredPayment
              "
              class="mt-2 text-sm font-bold"
              :style="{ color: colors.colorOnSurface }"
              >{{ bookingLabelDiscount() }}</span
            >
          </div>
          <div class="flex flex-row justify-between">
            <span
              class="mt-2 text-sm"
              :style="{ color: colors.colorOnSurface }"
              >{{ bookingLabel() }}</span
            >
            <span
              class="mt-2 text-sm"
              :style="{ color: colors.colorOnSurface }"
              >{{
                BillingUtil.formatBillingValue(
                  bookingInfo.priceBase.value,
                  bookingInfo.priceBase.currency
                )
              }}</span
            >
          </div>
          <div
            class="flex flex-row justify-between"
            v-if="
              franchiseSelected &&
              franchiseSelected.hasOwnProperty('franchiseType')
            "
          >
            <div
              class="mt-2 text-sm font-bold"
              :style="{ color: colors.colorPrimary }"
            >
              {{ $t("common.franchise") }}
            </div>
          </div>
          <div
            class="flex flex-row justify-between"
            v-if="
              franchiseSelected &&
              franchiseSelected.hasOwnProperty('franchiseType')
            "
          >
            <span class="mt-2 text-sm" :style="{ color: colors.colorOnSurface }"
              >{{ franchiseSelected.message }}
            </span>
            <span class="mt-2 text-sm" :style="{ color: colors.colorOnSurface }"
              >{{
                BillingUtil.formatBillingValue(
                  bookingInfo.priceFranchise.value,
                  bookingInfo.priceFranchise.currency
                )
              }}
            </span>
          </div>
          <div
            class="flex flex-row justify-between"
            v-if="bookingInfo.priceExtras.value"
          >
            <span
              class="mt-2 text-sm font-bold"
              :style="{ color: colors.colorPrimary }"
              >{{ $t("common.extraEquipment") }}</span
            >
          </div>
          <span
            v-for="(extra, index) in extrasSelected"
            :key="'extra' + index"
            :style="{ color: colors.colorOnSurface }"
          >
            <div
              class="flex flex-row justify-between"
              v-if="bookingInfo.priceExtras.value"
            >
              <span class="mt-2 text-sm">{{ extra.name }}</span>
              <span class="mt-2 text-sm">{{
                BillingUtil.formatBillingValue(
                  getExtraPrice(extra),
                  bookingInfo.priceExtras.currency
                )
              }}</span>
            </div>
          </span>
          <div
            class="flex flex-row justify-between"
            v-if="bookingInfo.priceServices.value"
          >
            <span
              class="mt-2 text-sm font-bold"
              :style="{ color: colors.colorPrimary }"
              >{{ $t("common.additionalServices") }}</span
            >
          </div>
          <span
            v-for="(additional, index) in additionalsSelected"
            :key="'add' + index"
            :style="{ color: colors.colorOnSurface }"
          >
            <div
              class="flex flex-row justify-between"
              v-if="bookingInfo.priceServices.value"
            >
              <span class="mt-2 text-sm">{{
                returnAdditionalName(additional)
              }}</span>
              <span class="mt-2 text-sm">
                {{
                  BillingUtil.formatBillingValue(
                    additional.numberPackages
                      ? additional.numberPackages *
                          getExtraPriceNumber(additional)
                      : getExtraPrice(additional),
                    bookingInfo.priceServices.currency
                  )
                }}</span
              >
            </div>
          </span>
          <div
            class="flex flex-row justify-between mt-10"
            :style="{ color: colors.colorOnSurface }"
          >
            <span class="mt-2 text-sm">{{ $t("common.discount") }}</span>
            <span class="mt-2 text-sm"
              >{{ "-"
              }}{{
                bookingInfo.discount.value
                  ? BillingUtil.formatBillingValue(
                      bookingInfo.discount.value.toFixed(2),
                      bookingInfo.discount.currency
                    )
                  : ""
              }}</span
            >
          </div>
          <div
            v-if="agencyCode && bookingInfo.agencyDiscount"
            class="flex flex-row justify-between"
            :style="{ color: colors.colorOnSurface }"
          >
            <span class="mt-2 text-sm">{{ $t("agencyDiscount") }}</span>
            <span class="mt-2 text-sm"
              >{{ "-"
              }}{{
                bookingInfo.agencyDiscount.value
                  ? BillingUtil.formatBillingValue(
                      bookingInfo.agencyDiscount.value,
                      bookingInfo.agencyDiscount.currency
                    )
                  : ""
              }}{{
                bookingInfo.agencyDiscount.value
                  ? bookingInfo.agencyDiscount.currency
                  : ""
              }}</span
            >
          </div>
          <div
            class="flex flex-row justify-between"
            :style="{ color: colors.colorOnSurface }"
            v-if="bookingInfo.priceTotal.value"
          >
            <span class="mt-2 text-sm"
              >{{ $t("common.iva") }} ({{ bookingInfo.priceTotal.tax }}%)</span
            >
            <span class="mt-2 text-sm">{{
              BillingUtil.formatBillingValue(
                (
                  (bookingInfo.priceTotal.value * bookingInfo.priceTotal.tax) /
                  100
                ).toFixed(2),
                bookingInfo.priceTotal.currency
              )
            }}</span>
          </div>
          <div class="flex flex-col mt-3">
            <div
              class="flex flex-row"
              :class="{
                'justify-between align-center': currentStep !== 5,
                'justify-end': currentStep === 5,
              }"
            >
              <div
                class="text-lg font-bold"
                :style="{ color: colors.colorOnSurface }"
              >
                {{ $t("common.total") }}
              </div>
              <div
                class="text-lg font-bold"
                :style="{ color: colors.colorOnSurface }"
              >
                {{
                  BillingUtil.formatBillingValue(
                    priceTotal ? priceTotal : 0,
                    bookingInfo.priceTotal.currency
                  )
                }}
              </div>
            </div>
            <div
              v-if="priceTotalDeferred !== null && currentStep !== 1"
              class="mt-2 flex justify-end text-normal font-bold"
              :style="{ color: colors.colorOnSurface }"
            >
              {{
                $t("mobile.payments.payPercentageNow", {
                  0: BillingUtil.formatBillingValue(
                    priceTotalDeferred,
                    bookingInfo.priceTotal.currency
                  ),
                })
              }}
            </div>
          </div>
        </div>
      </div>
      <v-btn
        v-if="showNextStepButton"
        class="border-2 rounded-config30 mt-3"
        :loading="loading"
        :disabled="!servicesValidation"
        :style="{
          backgroundColor: colors.colorPrimary,
          color: colors.colorOnPrimary,
        }"
        @click="nextStep"
      >
        {{ returnLabelButton() }}
      </v-btn>
      <v-btn
        v-if="
          !showNextStepButton &&
          ((this.currentStep === 2 && !isCatalogue) ||
            (isCatalogue && this.currentStep === 1))
        "
        class="border-2 rounded-config30 mt-3"
        :loading="loading"
        :style="{
          backgroundColor: colors.colorPrimary,
          color: colors.colorOnPrimary,
        }"
        @click="goToLogin"
      >
        {{ $t("bookNow") }}
      </v-btn>
    </div>
    <!--<TailDialog
      :backgroundColor="colors.colorBackground"
      :closeButtonBackgroundColor="colors.colorPrimary"
      :closeButtonLogoColor="colors.colorOnPrimary"
      colorCloseBorder="gray"
      :show="showModalEdit"
      @close="showModalEdit = false"
    >-->
    <LateralPanel v-if="!isCatalogue" :open="showModalEdit" ref="lateralPanel">
      <template slot="content">
        <MainFilterComponentNew
          style="min-height: 600px; display: flex"
          :style="`min-width: ${
            this.$vuetify.breakpoint.name === 'sm' ||
            this.$vuetify.breakpoint.name === 'xs'
              ? ''
              : '600px'
          }`"
          :type="'vertical'"
          :edit="true"
          showCancelButton
          :errorSend="error"
          :bookingFiltersOld="bookingFilters"
          @close="mainFilterClose"
        />
      </template>
    </LateralPanel>

    <v-dialog
      :class="'rounded-t-xl'"
      transition="dialog-bottom-transition"
      :value="showModalExtras"
      :maxWidth="800"
      :style="`backgroundColor:${colors.colorSurface} !important`"
    >
      <template>
        <v-card
          class="max-h-full bg-white backdrop-blur-xl overflow-y-auto rounded-lg"
          :style="`backgroundColor:${colors.colorSurface} !important`"
        >
          <v-card-title
            class="text-h5 mb-2"
            id="dialog-extras"
            :style="{
              color: colors.colorOnSurface,
            }"
          >
            <F2gIcon
              class="mr-2"
              :value="'warning'"
              :height="30"
              :width="30"
              :stroke="colors.colorOnSurface"
              :strokeWidth="3"
            />
            {{ $t("reviewExtras") }}
          </v-card-title>
          <v-card-text>
            <div
              class="absolute flex flex-row justify-end align-end mt-2 mr-2 top-0 right-0 z-50"
            >
              <v-btn icon @click="showModalExtras = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div
              class="flex flex-row align-center"
              :style="{
                color: colors.colorOnSurface,
              }"
            >
              <v-layout column class="mb-4">
                {{ $t("reviewExtrasQuestion") }}
              </v-layout>
            </div>

            <div
              class="flex align-end justify-end"
              :class="{
                'flex-col': $vuetify.breakpoint.name === 'xs',
              }"
            >
              <v-btn
                outlined
                class="rounded"
                :class="{
                  'mb-2': $vuetify.breakpoint.name === 'xs',
                }"
                :color="colors.colorPrimary"
                @click="nextStep"
                >{{ $t("dontNeedAnything") }}</v-btn
              >
              <v-btn
                :color="colors.colorPrimary"
                class="rounded"
                :class="{
                  'ml-2': $vuetify.breakpoint.name !== 'xs',
                }"
                :style="'color: ' + colors.colorOnPrimary"
                @click="
                  showModalExtras = false;
                  $emit('showExtras');
                "
                >{{ $t("showExtras") }}</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import ImageAspectRatio from "@/flit2go-web-core/components/ui/image-aspect-ratio";
import { mapGetters } from "vuex";
import DateUtil from "@/flit2go-web-core/util/dateUtil";
import { EventBus } from "@/flit2go-web-core/util/event-bus.js";
import MainFilterComponentNew from "@/modules/v2/Components/main-filter-component-new.vue";
import BillingUtil from "@/flit2go-web-core/util/billingUtil.js";
import LateralPanel from "@/flit2go-web-core/components/frameweb/lateral-panel";

export default {
  name: "ResumeDataV2",
  components: { ImageAspectRatio, LateralPanel, MainFilterComponentNew },
  props: {
    currentStep: { required: false, type: Number, default: 1 },
    showNextStep: { required: false, type: Boolean, default: false },
    loading: { required: false, type: Boolean, default: false },
    servicesValidation: { required: false, type: Boolean, default: true },
    extrasValidation: { required: false, type: Boolean, default: true },
    isCatalogue: { required: false, type: Boolean, default: false },
  },
  data() {
    return {
      DateUtil,
      BillingUtil,
      bookingInfo: null,
      hours: null,
      showModalEdit: false,
      showModalExtras: false,
      hideDialogExtras: false,
      priceTotal: 0,
      priceTotalDeferred: null,
      apiGoogleMaps: process.env.VUE_APP_GOOGLE_MAPS_KEY,
      agencyCode: null,
      error: null,
    };
  },
  mounted() {
    if (
      this.franchiseSelected &&
      this.categorySelected &&
      this.bookingFilters
    ) {
      this.getPrice();
    }
    EventBus.$on("getPrice", () => {
      this.getPrice();
    });
    EventBus.$on("hideDialogExtras", () => {
      this.hideDialogExtras = true;
    });
    EventBus.$on("agencyCode", (agencyCode) => {
      this.agencyCode = agencyCode;
      this.getPrice();
    });
  },
  watch: {
    franchiseSelected() {
      if (!this.franchiseSelected) {
        this.bookingInfo = null;
        this.priceTotal = 0;
        this.priceTotalDeferred = null;
      } else if (
        this.categorySelected &&
        this.bookingFilters &&
        !this.bookingInfo
      ) {
        this.getPrice();
      }
    },
    currentStep() {
      if (this.currentStep === 1) {
        this.priceTotalDeferred = null;
        this.priceTotal = 0;
      }
    },
    showModalEdit() {
      this.goToTop();
    },
    showModalExtras() {
      this.goToTop();
    },
  },
  computed: {
    ...mapGetters({
      bookingFilters: "configuration/getBookingFilters",
      additionalsSelected: "configuration/getAdditionalsSelected",
      extrasSelected: "configuration/getExtrasSelected",
      franchiseSelected: "category/franchiseSelected",
      categorySelected: "category/categorySelected",
      colors: "configuration/getColors",
      logo: "configuration/getLogo",
      language: "configuration/getUserLanguage",
      promotions: "user/userPromotion",
      user: "user/userLoged",
      filters: "configuration/getFilters",
      packSelected: "category/packSelected",
    }),
    showButton() {
      return (this.currentStep !== 2 && !this.isCatalogue) || this.showNextStep;
    },
    showNextStepButton() {
      if (this.isCatalogue) {
        return (
          this.currentStep != 3 && this.showButton && this.extrasValidation
        );
      } else {
        return (
          this.currentStep != 4 &&
          this.currentStep != 1 &&
          this.showButton &&
          this.extrasValidation
        );
      }
    },
  },
  methods: {
    getPrice() {
      if (this.categorySelected && this.bookingFilters) {
        let data = {};
        data.categoryId = this.categorySelected.id;
        data.data = {
          pickUpDate: this.bookingFilters.pickUpDate,
          returnBackDate: this.bookingFilters.returnBackDate,
          pickUpBranch: this.bookingFilters.pickUpBranch,
          returnBackBranch: this.bookingFilters.returnBackBranch,
          franchise: this.franchiseSelected
            ? this.franchiseSelected.franchiseType
            : -1,
          extra: [],
          additionServices: [],
          earlyPay: this.packSelected?.id
            ? true
            : this.categorySelected.earlyPay,
          promotion:
            this.promotions && this.promotions.length > 0 ? true : false,
          userId: this.user && this.user.id ? this.user.id : null,
          agencyCode: this.agencyCode,
          packId: this.packSelected?.id ? this.packSelected.id : null,
        };
        if (this.extrasSelected[0]) {
          Object.keys(this.extrasSelected).forEach((ext) => {
            data.data.extra.push(this.extrasSelected[ext].id);
          });
        }

        if (this.additionalsSelected[0]) {
          Object.entries(this.additionalsSelected).forEach(([key, value]) => {
            data.data.additionServices.push(value);
          });
        }

        if (data.categoryId) {
          this.$store
            .dispatch("configuration/getPrice", data)
            .then((resp) => {
              this.error = null;
              this.bookingInfo = resp.data;
              let totalPrice = 0;
              if (this.bookingInfo.priceTotal) {
                totalPrice =
                  this.bookingInfo.priceTotal.value +
                  Number(
                    (
                      (this.bookingInfo.priceTotal.value *
                        this.bookingInfo.priceTotal.tax) /
                      100
                    ).toFixed(2)
                  );
              }
              if (
                this.bookingInfo.priceDeferred &&
                !this.categorySelected.earlyPay &&
                this.categorySelected.deferredPayment
              ) {
                let priceTotalDeferred = 0;
                priceTotalDeferred =
                  this.bookingInfo.priceDeferred.value +
                  Number(
                    (
                      (this.bookingInfo.priceDeferred.value *
                        this.bookingInfo.priceDeferred.tax) /
                      100
                    ).toFixed(2)
                  );
                this.priceTotalDeferred = priceTotalDeferred;
              }
              this.priceTotal = totalPrice;
            })
            .catch((err) => {
              this.error =
                err.response && err.response.data
                  ? err.response.data
                  : this.$t(this.$CONSTANTS.ERROR.GENERIC);
              EventBus.$emit("goStep1");
              this.goToTop();
              this.showModalEdit = true;
            });
        }
      } else {
        this.bookingInfo = null;
      }
    },
    goToTop() {
      window.parent.postMessage("goToTop", "*");
    },
    goToLogin() {
      EventBus.$emit("goToLogin");
    },
    getPricePerDay() {
      let price;
      if (this.packSelected) {
        if (
          this.packSelected.deferredPayment &&
          this.packSelected.pricePerTimeDeferred &&
          this.packSelected.deferredDiscount
        ) {
          price = this.packSelected.pricePerTimeDeferred;
        } else if (this.packSelected.pricePerTime) {
          price = this.packSelected.pricePerTime;
        } else {
          price = 0;
        }
      } else if (this.categorySelected.earlyPay) {
        price = this.categorySelected.pricePerTimeDeferred;
      } else {
        price = this.categorySelected.pricePerTime
          ? this.categorySelected.pricePerTime.toFixed(2)
          : 0;
      }
      return price;
    },
    bookingLabel() {
      return (
        this.$t("common.booking") +
        " (" +
        this.bookingDays() +
        " " +
        (this.bookingDays() > 1
          ? this.$t("common.days")
          : this.$t("common.day")) +
        ")"
      );
    },
    bookingLabelDiscount() {
      return this.$t("common.payNowPercentage", {
        percentage: this.categorySelected.deferredPaymentPercentageFirst,
      });
    },
    returnAdditionalName(additional) {
      if (additional.type.code === this.$CONSTANTS.CATEGORY.SERVICE.LUGGAGE) {
        return (
          this.$PageUtils.getObjectNameLower(additional.type) +
          (" (x" + additional.numberPackages + ")")
        );
      }
      return this.$PageUtils.getObjectNameLower(additional.type);
    },
    bookingDays() {
      let timeHours =
        (this.bookingFilters.returnBackDate - this.bookingFilters.pickUpDate) /
        3600000;
      if (timeHours < 8) {
        this.hours = timeHours;
      }
      let timeDays = parseInt(timeHours / 24);
      let timeHoursRemainder = timeHours % 24;
      if (timeHoursRemainder > 2) {
        timeDays = timeDays + 1;
      }
      return timeDays;
    },
    getExtraPrice(extra) {
      let price = extra.priceNoTax;
      if (extra.priceModality.code == "PRD") {
        let days = this.bookingDays();
        if (days < 1) {
          days = 1;
        }
        return BillingUtil.formatNumber(price * days);
      } else if (extra.priceModality.code == "PRH") {
        if (this.hours) {
          return BillingUtil.formatNumber(price * this.hours);
        } else {
          let days = this.bookingDays();
          if (days < 1) {
            days = 1;
          }
          return BillingUtil.formatNumber(price * 24 * days);
        }
      } else {
        return BillingUtil.formatNumber(price);
      }
    },
    getExtraPriceNumber(extra) {
      if (extra.priceModality.code == "PRD") {
        let days = this.bookingDays();
        if (days < 1) {
          days = 1;
        }
        return extra.priceNoTax * days;
      } else if (extra.priceModality.code == "PRH") {
        if (this.hours) {
          return extra.priceNoTax * this.hours;
        } else {
          let days = this.bookingDays();
          if (days < 1) {
            days = 1;
          }
          return extra.priceNoTax * 24 * days;
        }
      } else {
        return extra.priceNoTax;
      }
    },
    nextStep() {
      if (
        !this.hideDialogExtras &&
        (this.categorySelected.additionalServicesList.length ||
          this.categorySelected.extraList.length) &&
        !(
          this.$vuetify.breakpoint.name === "sm" ||
          this.$vuetify.breakpoint.name === "xs"
        ) &&
        ((this.currentStep === 1 && this.isCatalogue) ||
          (this.currentStep === 2 && !this.isCatalogue))
      ) {
        if (
          (this.categorySelected.additionalServicesList.length ||
            this.categorySelected.extraList.length) &&
          !Object.entries(this.extrasSelected).length &&
          !Object.entries(this.additionalsSelected).length &&
          !this.showModalExtras
        ) {
          this.showModalExtras = true;
          this.hideDialogExtras = true;
        }
      } else if (this.categorySelected.id) {
        this.$emit("currentStep", this.currentStep + 1);
        this.showModalExtras = false;
      }
    },

    returnLabelButton() {
      if (!this.isCatalogue) {
        switch (this.currentStep) {
          case 1: {
            return this.$t("button.goToExtras");
          }
          case 2: {
            return this.$t("common.continue");
          }
          case 3: {
            if (
              this.categorySelected.deferredPayment &&
              this.categorySelected.deferredStartRental &&
              !this.categorySelected.earlyPay &&
              Number(this.categorySelected.deferredPaymentPercentageFirst) === 0
            ) {
              return this.$t("common.continue");
            } else {
              return this.$t("button.goToPay");
            }
          }
          case 4: {
            if (
              this.categorySelected.deferredPayment &&
              this.categorySelected.deferredStartRental &&
              !this.categorySelected.earlyPay &&
              Number(this.categorySelected.deferredPaymentPercentageFirst) === 0
            ) {
              return this.$t("common.continue");
            } else {
              return this.$t("button.goToPay");
            }
          }
          default: {
            return "";
          }
        }
      } else {
        switch (this.currentStep) {
          case 1: {
            return this.$t("common.continue");
          }
          case 2: {
            if (
              this.categorySelected.deferredPayment &&
              this.categorySelected.deferredStartRental &&
              !this.categorySelected.earlyPay &&
              Number(this.categorySelected.deferredPaymentPercentageFirst) === 0
            ) {
              return this.$t("common.continue");
            } else {
              return this.$t("button.goToPay");
            }
          }
          case 3: {
            if (
              this.categorySelected.deferredPayment &&
              this.categorySelected.deferredStartRental &&
              !this.categorySelected.earlyPay &&
              Number(this.categorySelected.deferredPaymentPercentageFirst) === 0
            ) {
              return this.$t("common.continue");
            } else {
              return this.$t("button.goToPay");
            }
          }
          default: {
            return "";
          }
        }
      }
    },
    editButton() {
      if (!this.isCatalogue) {
        this.showModalEdit = true;
      } else {
        this.$store.commit(
          "category/SET_CATEGORY_ID_URL",
          this.categorySelected.id
        );
        this.$router.push({
          name: "Catalogue",
          query: this.$route.query,
          params: { categoryId: this.categorySelected.id },
        });
        window.parent.postMessage("manualResize", "*");
      }
    },
    mainFilterClose() {
      this.showModalEdit = false;
      //EventBus.$emit("tailClose");
      ///setTimeout(() => window.parent.postMessage("manualResize", "*"), 300);
    },
  },
};
</script>
