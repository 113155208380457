var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"required-fields-end-booking",attrs:{"id":"top-part"}},[_c('F2gDialog',{attrs:{"showClose":"","show":_vm.showDialog,"title":_vm.$t('checkRequiredFields'),"width":_vm.$vuetify.breakpoint.name === 'xs' ? '90%' : 500},on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.filters.dniRequired && !_vm.user.userLoged.nif)?_c('v-layout',{attrs:{"wrap":""}},[_c('p',{staticClass:"leading-4 font-medium text-base mb-2 w-full",style:({ paddingTop: '5px' })},[_vm._v(" "+_vm._s(_vm.$t("forms.nif"))+" ")]),_c('v-text-field',{staticClass:"lg:w-1/2 w-full lg:mr-2",attrs:{"outlined":true,"error-messages":_vm.nifErrors},model:{value:(_vm.fields.nif),callback:function ($$v) {_vm.$set(_vm.fields, "nif", $$v)},expression:"fields.nif"}})],1):_vm._e(),(
          _vm.filters.billingAddressRequired &&
          !(_vm.user.userLoged.billAddress && _vm.user.userLoged.billAddress.country)
        )?_c('v-layout',{attrs:{"wrap":""}},[_c('p',{staticClass:"leading-4 font-medium text-base mb-0 w-full",style:({ paddingTop: '5px' })},[_vm._v(" "+_vm._s(_vm.$t("forms.address.billingAddress"))+" ")]),(_vm.user.userLoged.address && _vm.user.userLoged.address.country)?_c('div',{staticClass:"flex flex-row justify-start items-center"},[_c('v-checkbox',{attrs:{"color":"primary"},model:{value:(_vm.checkBilling),callback:function ($$v) {_vm.checkBilling=$$v},expression:"checkBilling"}}),_c('span',{staticClass:"ml-2",style:({ color: _vm.colors.colorOnBackground })},[_vm._v(_vm._s(_vm.$t("forms.address.useResidenceAddress")))])],1):_vm._e(),(
            !_vm.checkBilling ||
            !(_vm.user.userLoged.address && _vm.user.userLoged.address.country)
          )?_c('direction-billing-data',{attrs:{"validate":_vm.validate,"nationalities":_vm.user.nationalities},on:{"billingAddress":_vm.setBillingAddress,"validation":_vm.setValidationData}}):_vm._e()],1):_vm._e()]},proxy:true},{key:"actions",fn:function(){return [_c('div',{staticClass:"mt-3"},[_c('F2gButton',{staticClass:"mr-2",attrs:{"customClass":"secondary-dark","outlined":"","text":_vm.$t('common.cancel')},on:{"click":function($event){return _vm.$emit('close')}}}),_c('F2gButton',{attrs:{"customClass":"primary","text":_vm.$t('common.save')},on:{"click":_vm.sendData}})],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }