var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-card-catalogue m-4"},[_c('div',{staticClass:"w-80 max-h-full flex flex-1 md:align-left relative rounded-3xl border-black shadow hover:shadow-xl showBorder",class:{
      'cursor-pointer':
        !_vm.categoryRow.notAvailable && !_vm.categoryRow.requestVehicle,
    },style:({
      outlineColor: _vm.colors.colorPrimary + '!important',
    }),on:{"click":function($event){_vm.categoryRow.notAvailable && !_vm.categoryRow.requestVehicle
        ? ''
        : _vm.$emit('categorySelected', _vm.categoryRow)}}},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"w-full h-12 rounded-t-3xl p-1 flex align-center",style:({ backgroundColor: _vm.colors.colorSurfaceMedium })},[_c('div',{staticClass:"w-full max-h-full flex align-center"},[_c('h1',{staticClass:"w-4/6 text-sm md:text-base font-bold ml-2 mt-1",style:({
              color: _vm.colors.colorOnSurface,
              lineHeight: '1.25 !important',
            })},[_vm._v(" "+_vm._s(_vm.categoryRow.name)+" ")])]),_c('div',{staticClass:"absolute right-0 top-0 h-16 z-5 rounded-tr-3xl rounded-bl-3xl py-3 px-3 flex justify-center align-center",style:({
            backgroundColor: _vm.colors.colorPrimary,
            color: _vm.colors.colorOnPrimary,
          })},[_c('div',{staticClass:"flex flex-col align-end"},[_c('div',{staticClass:"text-lg"},[_vm._v(" "+_vm._s(_vm.BillingUtil.formatBillingValue( _vm.returnPrice(), _vm.categoryRow.symbol ))+" ")]),_c('div',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.$t("approx") + "*")+" / "+_vm._s(_vm.$t("common.day"))+" ")])])])]),_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"flex flex-col"},[_c('v-flex',{staticClass:"inline-block"},[(_vm.categoryRow.requestVehicle)?_c('v-chip',{staticClass:"ma-2 absolute z-5 text-xs",attrs:{"color":"warning"}},[_vm._v(" "+_vm._s(_vm.$t("requestTheVehicle"))+" ")]):(_vm.categoryRow.notAvailable)?_c('v-chip',{staticClass:"ma-2 absolute z-5 text-xs",attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.$t("temporarilyNoStock"))+" ")]):_vm._e(),(_vm.categoryRow.img)?_c('v-img',{staticClass:"image",class:{
                'h-52': _vm.categoryRow.attributeList.length,
                'h-64': !_vm.categoryRow.attributeList.length,
              },style:([
                !_vm.categoryRow.attributeList.length
                  ? {
                      'border-bottom-left-radius': '30px',
                      'border-bottom-right-radius': '30px',
                    }
                  : '',
              ]),attrs:{"src":_vm.categoryRow.img,"aspect-ratio":16 / 9}}):_vm._e()],1),_c('div',{staticClass:"w-full rounded-b-xl"},[_c('div',{staticClass:"w-full pl-2 flex flex-row align-center overflow-x-hidden category_services rounded-b-xl",style:({
                backgroundColor: _vm.colors.colorSurfaceMedium,
              })},_vm._l((_vm.categoryRow.attributeList),function(attribute,index){return _c('div',{key:index,staticClass:"max-w-1/3"},[(index < 3)?_c('div',{staticClass:"inline-block my-1 text-sm align-middle mr-2 flex flex-row"},[_c('div',{staticClass:"text-sm align-middle mr-2 flex flex-row truncate"},[_c('div',{staticClass:"pa-1 m-1 inline-block align-middle"},[_c('svg',{staticStyle:{"height":"24px","width":"24px"},attrs:{"fill":_vm.colors.colorPrimary}},[(attribute.icon.path)?_c('path',{attrs:{"d":attribute.icon.path}}):_vm._e()])]),_c('div',{staticClass:"flex align-center inline-block align-middle",style:({ color: _vm.colors.colorOnSurface })},[_vm._v(" "+_vm._s(attribute.supportedLangs[ _vm.$PageUtils.getLanguageSplittedMinus(_vm.$i18n.locale) ] ? attribute.supportedLangs[ _vm.$PageUtils.getLanguageSplittedMinus( _vm.$i18n.locale ) ] : attribute.supportedLangs.en)+" ")])])]):_vm._e()])}),0)])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }