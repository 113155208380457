<template>
  <div
    class="
      fixed
      top-1/2
      left-1/2
      m-auto
      -ml-12
      -mt-12
      p-5
      bg-white bg-opacity-80
      h-24
      w-24
      rounded-lg
      shadow-xl
    "
    style="z-index: 320"
  >
    <v-progress-circular class="mt-3 ml-3" :color="color" indeterminate>
    </v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "TailLoader",
  props: {
    color: { default: "green", required: false, type: String },
  },
};
</script>
