<template>
  <div
    class="image-aspect-ratio"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <v-responsive
      class=" flex-row-center"
      :style="
        `min-height: ${minHeight};min-width: ${minWidth};max-height: ${maxHeight};max-width: ${maxWidth}`
      "
      :class="{
        border: border,
        border25: border25,
        'border-line': borderLine,
        circle: circle,
        'mx-auto': marginAuto,
        borderRadiusLeftTopBottom: borderRadiusLeftTopBottom,
      }"
      :aspect-ratio="aspectRatio"
    >
      <v-img
        v-if="img"
        class="image"
        :src="image"
        :style="{ transform: ` rotate(${rotateRate}deg)` }"
        :contain="contain"
        @click="$emit('click')"
      />
      <v-img
        v-else
        class="image"
        :src="
          require('@/flit2go-web-core/assets/no-information/placeholder.png')
        "
        @click="$emit('click')"
      />
      <slot name="image-content"></slot>
    </v-responsive>
  </div>
</template>

<script>
export default {
  name: "ImageAspectRatio",
  props: {
    minHeight: { type: String, default: "40px", required: false },
    minWidth: { type: String, default: "40px", required: false },
    maxHeight: { type: String, default: "200px", required: false },
    maxWidth: { type: String, default: "200px", required: false },
    base64: { type: Boolean, default: false, required: false },
    aspectRatio: { type: Number, default: 16 / 9, required: false },
    img: { type: String, required: false },
    border: { type: Boolean, default: true, required: false },
    border25: { type: Boolean, default: true, required: false },
    borderLine: { type: Boolean, default: false, required: false },
    borderRadiusLeftTopBottom: {
      type: Boolean,
      default: false,
      required: false,
    },
    circle: { type: Boolean, default: false, required: false },
    marginAuto: { type: Boolean, default: true, required: false },
    rotateRate: { type: Number, default: 0, required: false },
    contain: { type: Boolean, default: false, required: false },
  },
  computed: {
    image() {
      return this.base64 ? "data:image/png;base64, " + this.img : this.img;
    },
  },
};
</script>

<style lang="scss" scoped>
.image-aspect-ratio {
  .image {
    height: 100%;
    width: 100%;
  }

  .border {
    border-radius: 5px;
  }

  .border25 {
    border-radius: 25px;
  }

  .borderRadiusLeftTopBottom {
    border-radius: 10px 0px 0px 10px;
  }

  .border-line {
    border: 1px solid grey;
  }

  .circle {
    border-radius: 90%;
  }
}
</style>
