<template>
  <v-app
    :style="{
      fontFamily: fontName ? fontName + ' !important' : 'Poppins',
    }"
  >
    <f2gsnackbar ref="f2gsnackbar" />
    <v-main
      v-if="colors"
      :style="{
        backgroundColor: getBackgroundColor,
      }"
    >
      <TailLoader v-if="showLoader" :color="colors.colorPrimary" />
      <div class="flex flex-col justify-center max-h-full">
        <router-view />
        <br class="clear" />
      </div>
    </v-main>
    <LateralPanel ref="lateralPanel">
      <template slot="content">
        <div
          class="text-xl font-bold mb-4"
          :style="`color: ${colors.colorOnSurface}`"
        >
          {{ $t("logoutQuestion") }}
        </div>
        <div class="mb-8" :style="`color: ${colors.colorOnSurface}`">
          {{
            $t("currentUser", {
              user: `${userLoged.name} ${userLoged.surnames}`,
            })
          }}
        </div>
        <div class="flex">
          <div class="w-full px-1 md:w-1/2 mx-auto">
            <v-btn
              class="w-full border-2 rounded-config30 my-1"
              color="warning"
              outlined
              @click="closeLogout"
              >{{ $t("common.cancel") }}
            </v-btn>
          </div>
          <div class="w-full px-1 md:w-1/2 mx-auto">
            <v-btn
              class="w-full border-2 rounded-config30 my-1"
              color="primary"
              @click="doLogout"
              >{{ $t("common.close") }}
            </v-btn>
          </div>
        </div>
      </template>
    </LateralPanel>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import branchStore from "@/stores/branchStore";
import categoryStore from "@/stores/categoryStore";
import configurationStore from "@/stores/configurationStore";
import userStore from "@/stores/userStore";
import TailLoader from "@/components/tail-loader";
import Vue from "vue";
import f2gsnackbar from "@/flit2go-web-core/components/ui/f2g-snackbar";
import { EventBus } from "@/flit2go-web-core/util/event-bus.js";
import LateralPanel from "@/flit2go-web-core/components/frameweb/lateral-panel";
import VueGtag from "vue-gtag";

export default {
  name: "App",
  components: {
    TailLoader,
    f2gsnackbar,
    LateralPanel,
  },
  data() {
    return {
      fontName: null,
    };
  },
  watch: {
    "$route.params.type"() {
      if (this.$route.params && this.$route.params.type) {
        this.$router.go(0);
      }
    },
    "$route.hash"() {
      if (this.$route.hash && this.$route.hash.startsWith("#?lang=")) {
        this.$router.go(0);
      }
    },
    "configuration.trackNumberHubSpot"() {
      if (this.configuration.trackNumberHubSpot) {
        let hubspotScript = document.createElement("script");
        hubspotScript.setAttribute(
          "src",
          "//js.hs-scripts.com/" + this.configuration.trackNumberHubSpot + ".js"
        );
        hubspotScript.setAttribute("id", "hs-script-loader");
        hubspotScript.setAttribute("type", "text/javascript");
        hubspotScript.setAttribute("defer", "defer");
        hubspotScript.setAttribute("async", "async");
        document.head.appendChild(hubspotScript);
      }
    },
    "$route.name"() {
      if (
        this.$route.name !== "ReservationV2" &&
        this.$route.name !== "ReservationCatalogue"
      ) {
        this.$refs.lateralPanel.doClose();
      }
    },
  },
  computed: {
    ...mapState(["configuration", "category", "user"]),
    ...mapGetters({
      colors: "configuration/getColors",
      userLoged: "user/userLoged",
    }),
    showLoader() {
      if (this.$route.params.type) {
        return (
          this.configuration.status === "loading" ||
          this.category.status === "loading" ||
          this.user.status === "loading"
        );
      } else {
        return this.user.status === "loading";
      }
    },
    getBackgroundColor() {
      return (this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm") &&
        !this.$route.path.includes("catalogue") &&
        !this.category.categoriesList
        ? this.colors.colorHeader
        : this.colors.colorBackground;
    },
  },
  created() {
    this.$store.registerModule("user", userStore);
    this.$store.registerModule("category", categoryStore);
    this.$store.registerModule("configuration", configurationStore);
    this.$store.registerModule("branchVehicle", branchStore);
    this.initSetup();
    this.getConfiguration();
    EventBus.$on("openLogout", () => this.$refs.lateralPanel.doOpen());
  },
  mounted() {
    this.$root.f2gsnackbar = this.$refs.f2gsnackbar;
    this.initShowMessages();
  },
  methods: {
    doLogout() {
      this.$store.commit("user/EMPTY_USER_LOGED");
      EventBus.$emit("doLogout");
      window.localStorage.removeItem("frame-user-token");
      this.closeLogout();
    },
    closeLogout() {
      this.$refs.lateralPanel.doClose();
    },
    initSetup() {
      const url =
        window.location !== window.parent.location
          ? document.location.ancestorOrigins
            ? document.location.ancestorOrigins[0]
            : document.referrer
          : document.location.origin;

      localStorage.removeItem("colors");
      localStorage.removeItem("labels");
      localStorage.removeItem(`apiToken-${url}`);
      this.$store.commit("category/SET_CATEGORY_ID_URL", null);
      let URLactual = window.location;
      localStorage.setItem(
        `apiToken-${url}`,
        URLactual.href
          .split("apiKey=")
          .pop()
          .split("#")[0]
      );
      if (
        !this.$route.query.hasOwnProperty("pickUpBranch") &&
        !this.$route.query.hasOwnProperty("pickUpDate") &&
        !this.$route.query.hasOwnProperty("returnBackBranch") &&
        !this.$route.query.hasOwnProperty("returnBackDate")
      ) {
        // Set language to user language or browser language
        let person = URLactual.href
          .split("?lang=")
          .pop()
          .split("#")[0];
        let language = "en";
        if (
          person === "es" ||
          person === "de" ||
          person === "pt" ||
          person === "it" ||
          person === "fr" ||
          person === "ca"
        ) {
          language = person;
        }

        if (language.includes("_")) language = language.split("_")[0];
        localStorage.setItem("user-language", language);
        this.$store.dispatch("settings/CHANGE_LANG", { language: language });
      }
      localStorage.setItem(`windowUrl-${url}`, url);

      if (URLactual.href.includes("?categoryId=")) {
        let categoryId = URLactual.href
          .split("?categoryId=")
          .pop()
          .split("#")[0];
        this.$store.commit("category/SET_CATEGORY_ID_URL", categoryId);
      }
      if (URLactual.href.includes("?url=")) {
        let urlNewTab = URLactual.href
          .split("?url=")
          .pop()
          .split("#")[0];
        this.$store.commit("settings/SET_URL_NEW_TAB", urlNewTab);
      }

      this.initFont();
      this.getAttributes();
    },
    initFont() {
      let URLactual = window.location;
      if (URLactual.href.includes("?fontName=")) {
        this.fontName = URLactual.href
          .split("?fontName=")
          .pop()
          .split("#")[0];
        this.fontName = this.fontName.replaceAll("+", " ");
      }
      if (URLactual.href.includes("?fontUrl=")) {
        let fontUrl = URLactual.href
          .split("?fontUrl=")
          .pop()
          .split("#")[0];

        const head = document.getElementsByTagName("head")[0];
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.type = "text/css";
        link.href = fontUrl ? fontUrl : "";
        link.media = "all";
        head.appendChild(link);
      }
    },
    getAttributes() {
      let URLactual = window.location;
      if (URLactual.href.includes("?filter=")) {
        let filterUrl = decodeURIComponent(
          URLactual.href
            .split("?filter=")
            .pop()
            .split("#")[0]
        );
        let filter = filterUrl.split(",");
        this.$store.commit("category/SET_ATTRIBUTES_URL", filter);
      }
    },
    getConfiguration() {
      let language = localStorage.getItem("user-language");
      let params = {
        type: this.$CONSTANTS.INTEGRATION.FRAME_WEB,
      };
      this.$store.commit("configuration/SET_LANGUAGE", language);
      this.$store
        .dispatch("configuration/getConfiguration", params)
        .then((resp) => {
          this.$vuetify.theme.themes.light.primary = resp.data.colorPrimary;

          if (resp.data.gtagCode?.length > 0) {
            // const scriptAnalytics = document.createElement("script");
            // scriptAnalytics.type = "text/javascript";
            // scriptAnalytics.async = true;
            // scriptAnalytics.src = `https://www.googletagmanager.com/gtag/js?id=${resp.data.gtagCode}`;
            // document.head.appendChild(scriptAnalytics);

            // const scriptAnalytics2 = document.createElement("script");
            // scriptAnalytics2.type = "text/javascript";
            // const scriptAnalytics2Body = document.createTextNode(
            //   `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', '${new Date()}'); gtag('config', '${
            //     resp.data.gtagCode
            //   }');`
            // );
            // scriptAnalytics2.appendChild(scriptAnalytics2Body);
            // document.head.appendChild(scriptAnalytics2);
            Vue.use(VueGtag, {
              config: { id: this.configuration.gtagCode },
            });
          }
        });
    },
    initShowMessages() {
      Vue.prototype.$showMessage = {
        success: (message) =>
          this.$root.f2gsnackbar.show({
            text: message,
            code: "success",
            show: true,
          }),
        info: (message) =>
          this.$root.f2gsnackbar.show({
            text: message,
            code: "info",
            show: true,
          }),
        error: (message) =>
          this.$root.f2gsnackbar.show({
            text: message,
            code: "error",
            show: true,
          }),
        warning: (message) =>
          this.$root.f2gsnackbar.show({
            text: message,
            code: "warning",
            show: true,
          }),
        errorGeneric: () =>
          this.$root.f2gsnackbar.show({
            text: this.$t(this.$CONSTANTS.ERROR.GENERIC),
            code: "error",
            show: true,
          }),
        showError: (message) => {
          const error =
            message.response && message.response.data
              ? message.response.data
              : this.$t(this.$CONSTANTS.ERROR.GENERIC);
          this.$root.f2gsnackbar.show({
            text: error,
            code: "error",
            show: true,
          });
        },
      };
    },
  },
};
</script>

<style lang="scss">
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
*::-webkit-scrollbar {
  width: 0px; /* Hide scrollbar for Chrome, Safari and Opera */
}

.clear {
  clear: both;
}
.v-dialog {
  position: absolute;
  top: 0;
}
</style>
