<template>
  <div class="category-card-calendar">
    <div class="speech-bubble" v-if="!fullscreen"></div>
    <!-- <div class="flex flex-row w-full justify-between align-center">
      <div class="font-bold">{{ $t("selectDates").toUpperCase() }}</div>
    </div> -->
    <!-- Calendar -->
    <v-skeleton-loader
      height="500px"
      type="image"
      v-show="!showCalendar"
    ></v-skeleton-loader>
    <div class="rounded-lg" :style="{ backgroundColor: colors.colorSurface }">
      <div class="flex justify-end mb-2" v-if="fullscreen">
        <F2gIcon
          value="close"
          :class="'cursor-pointer mr-2 mt-1'"
          :height="26"
          :width="26"
          :stroke="colors.colorOnHeader"
          :strokeWidth="6"
          @click="$emit('close')"
        />
      </div>
      <vc-date-picker
        v-if="showCalendar"
        mode="date"
        v-model="range"
        :drag-attribute="selectAttribute"
        :select-attribute="selectAttribute"
        :value="range"
        is-range
        :style="{
          backgroundColor: colors.colorSurface,
          color: colors.colorOnSurface,
          border: 0,
        }"
        is-expanded
        color="green"
        type="range"
        :rows="2"
        :minDate="isReturnBackMode ? range.start : startDate"
        :attributes="attrs"
        :locale="language"
        :firstDayOfWeek="language === 'en' ? 1 : 2"
        @input="changeDates"
        @update:from-page="changeMonth"
        @dayclick="dateClicked"
      />
      <div class="pl-4 pr-4 pb-2 flex flex-col">
        <div class="flex flex-row mb-2">
          <div class="circulo mr-2"></div>
          {{ $t("error.branchOffice.close") }}
        </div>
        <div class="flex flex-row">
          <div class="esfera mr-2"></div>
          {{ $t("reserve.user.delayOtherUser.title") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DateUtil from "@/flit2go-web-core/util/dateUtil";

export default {
  name: "CategoryCardCalendar",
  components: {},
  props: {
    categoryRow: { required: true, type: Object, default: () => [] },
    reserveFilter: { required: true, type: Object, default: () => [] },
    mode: { required: false, type: String, default: "pickUp" },
    fullscreen: { required: false, type: Boolean, default: false },
  },
  data() {
    return {
      DateUtil,
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      range: {
        start: DateUtil.formatDateNoTimeZone(
          this.reserveFilter.pickDate,
          this.ownerTimezone
        ),
        end: DateUtil.formatDateNoTimeZone(
          this.reserveFilter.returnDate,
          this.ownerTimezone
        ),
      },
      attrs: [],
      disabledDays: [],
      showCalendar: true,
      monthSelected: new Date().getMonth(),
    };
  },
  mounted() {
    this.range.start = DateUtil.formatDateNoTimeZone(
      this.reserveFilter.pickDate,
      this.ownerTimezone
    );
    this.range.end = DateUtil.formatDateNoTimeZone(
      this.reserveFilter.returnDate,
      this.ownerTimezone
    );
    this.getAvailabilityByCategoryId();
    this.attrs = [];
    this.disabledDays = [];
    if (
      this.mode === "returnBack" &&
      this.reserveFilter.returnLocation &&
      this.reserveFilter.returnLocation.id
    ) {
      this.getDaysClosedBranch("return");
    } else if (
      this.reserveFilter.pickLocation &&
      this.reserveFilter.pickLocation.id
    ) {
      this.getDaysClosedBranch("pick");
    }
  },
  watch: {
    reserveFilter: {
      deep: true,
      handler() {
        this.showCalendar = false;
        this.range.start = DateUtil.formatDateNoTimeZone(
          this.reserveFilter.pickDate,
          this.ownerTimezone
        );
        this.range.end = DateUtil.formatDateNoTimeZone(
          this.reserveFilter.returnDate,
          this.ownerTimezone
        );
        this.attrs = [];
        this.disabledDays = [];
        if (
          this.reserveFilter.pickLocation &&
          this.reserveFilter.pickLocation.id
        ) {
          this.getDaysClosedBranch("pick");
        }
        if (
          this.reserveFilter.returnLocation &&
          this.reserveFilter.returnLocation.id
        ) {
          this.getDaysClosedBranch("return");
        }
        this.getAvailabilityByCategoryId();
        setTimeout(() => {
          this.showCalendar = true;
        }, 50);
      },
    },
  },
  computed: {
    ...mapState(["branchVehicle, category"]),
    ...mapGetters({
      colors: "configuration/getColors",
      language: "configuration/getUserLanguage",
      ownerTimezone: "configuration/getOwnerTimezone",
    }),
    isReturnBackMode() {
      return this.mode === "returnBack";
    },
    getMinDate() {
      return this.mode === "pickUp"
        ? new Date(this.startDate)
        : new Date(this.range.start);
    },
    selectAttribute() {
      return {
        highlight: {
          start: {
            fillMode: "solid",
            style: {
              // Circle styles
              background: this.colors.colorPrimary,
            },
            contentStyle: {
              // Text styles
              color: "white",
            },
          },
          base: {
            fillMode: "light",
            style: {
              // Circle styles
              background: this.colors.colorPrimary + "55",
            },
            contentStyle: {
              // Text styles
              color: "black",
            },
          },
          end: {
            fillMode: "solid",
            style: {
              // Circle styles
              background: this.colors.colorPrimary,
            },
            contentStyle: {
              // Text styles
              color: "white",
            },
          },
        },
      };
    },
  },
  methods: {
    changeDates() {
      this.$emit("changeDates", this.range);
    },
    getDaysClosedBranch(type) {
      this.$store
        .dispatch(
          "branchVehicle/getBranchById",
          type === "pick"
            ? this.reserveFilter.pickLocation.id
            : this.reserveFilter.returnLocation.id
        )
        .then((resp) => {
          let daysClosedBranch = [];
          const schedule =
            type === "return" && resp.scheduleBranchOfficeBack?.length > 0
              ? "scheduleBranchOfficeBack"
              : "scheduleBranchOffice";
          resp[schedule].map((day) => {
            if (day.closed) {
              daysClosedBranch.push(day.day.numberDayOfWeek + 1);
            }
          });
          if (daysClosedBranch.length) {
            this.attrs.push({
              key: "daysClosedBranch",
              highlight: {
                color: "red",
                fillMode: "outline",
                contentStyle: {
                  pointerEvents: "none",
                },
              },
              dates: { weekdays: daysClosedBranch },
              popover: {
                label: this.$t("branchNameClosed", {
                  name:
                    type === "pick"
                      ? this.reserveFilter.pickLocation.name
                      : this.reserveFilter.returnLocation.name,
                }),
              },
            });
            this.disabledDays.push({ weekdays: daysClosedBranch });
          }
          this.getSpecialDaysBranch(type);
        });
    },
    getSpecialDaysBranch(type) {
      this.$store
        .dispatch(
          "branchVehicle/getBranchSpecialDays",
          type === "pick"
            ? this.reserveFilter.pickLocation.id
            : this.reserveFilter.returnLocation.id
        )
        .then((resp) => {
          let specialDaysClosedBranch = [];
          if (resp) {
            resp.map((day) => {
              if (day.closed) {
                specialDaysClosedBranch.push(new Date(day.date));
                this.disabledDays.push(new Date(day.date));
              }
            });
            if (specialDaysClosedBranch.length) {
              this.attrs.push({
                key: "specialDaysClosedBranch",
                highlight: {
                  color: "red",
                  fillMode: "outline",
                  contentStyle: {
                    pointerEvents: "none",
                  },
                },
                dates: specialDaysClosedBranch,
                popover: {
                  label: this.$t("branchNameClosed", {
                    name:
                      type === "pick"
                        ? this.reserveFilter.pickLocation.name
                        : this.reserveFilter.returnLocation.name,
                  }),
                },
              });
            }
          }
        });
    },
    getAvailabilityByCategoryId() {
      let year = new Date().getFullYear();
      let params = {
        categoryId: this.categoryRow.id,
        params: {
          startDate: new Date(
            Date.UTC(year, this.monthSelected, 1, 0, 0, 0)
          ).getTime(),
        },
      };
      this.$store
        .dispatch("category/getAvailabilityByCategoryId", params)
        .then((resp) => {
          if (resp && resp.data) {
            this.attrs.forEach((attr, index) => {
              if (attr.key == "availability") {
                this.attrs.splice(index, 1);
              }
            });
            let categoryNotAvailable = [];
            resp.data.map((day) => {
              if (!day.availability) {
                let closedDay = this.DateUtil.formatDateNoTimeZone(
                  day.dayDate,
                  this.ownerTimezone
                );
                let startDate = this.DateUtil.formatDateNoTimeZone(
                  this.range.start,
                  this.ownerTimezone
                );
                if (startDate === closedDay) {
                  this.showCalendar = false;

                  this.range.start = this.DateUtil.addDate(
                    new Date(this.range.start),
                    1,
                    "days",
                    "YYYY-MM-DD HH:mm:ss"
                  );
                  this.range.end = this.DateUtil.addDate(
                    new Date(this.range.end),
                    1,
                    "days",
                    "YYYY-MM-DD HH:mm:ss"
                  );
                  setTimeout(() => {
                    this.showCalendar = true;
                  }, 50);
                }
                categoryNotAvailable.push(
                  this.DateUtil.formatDateNoTimeZone(
                    day.dayDate,
                    this.ownerTimezone
                  )
                );
                this.disabledDays.push(
                  this.DateUtil.formatDateNoTimeZone(
                    day.dayDate,
                    this.ownerTimezone
                  )
                );
              }
            });
            if (categoryNotAvailable.length) {
              this.attrs.push({
                key: "availability",
                highlight: {
                  color: "red",
                  fillMode: "light",
                  contentStyle: {
                    pointerEvents: "none",
                  },
                },
                dates: categoryNotAvailable,
                popover: {
                  label: this.$t("reserve.user.delayOtherUser.title"),
                },
              });
            }
          }
        });
    },
    dateClicked(dateSelected) {
      if (this.getMinDate <= dateSelected.date) {
        if (this.mode === "pickUp") {
          this.range.start = DateUtil.formatDateNoTimeZone(
            dateSelected.date,
            this.ownerTimezone
          );
          if (this.range.start > this.range.end) {
            this.range.end = DateUtil.formatDateNoTimeZone(
              dateSelected.date,
              this.ownerTimezone
            );
          }
        } else {
          this.range.end = DateUtil.formatDateNoTimeZone(
            dateSelected.date,
            this.ownerTimezone
          );
        }
        this.range.mode = this.mode;
        this.$emit("changeDates", this.range);
        this.$emit("close");
      }
    },
    changeMonth(event) {
      this.monthSelected = event.month - 1;
      this.getAvailabilityByCategoryId();
    },
  },
};
</script>

<style lang="scss" scoped>
.category-card-calendar {
  ::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
    .v-skeleton-loader__image {
      height: 500px;
    }
  }
}
.speech-bubble {
  position: relative;
  background: #ffffff;
  border-radius: 0.4em;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  top: -22px;
  left: 40%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-bottom-color: #ffffff;
  border-top: 0;
  margin-left: -20px;
  margin-top: 0px;
}
// ::v-deep .vc-highlight {
//   background-color: red !important;
// }
// ::v-deep .vc-highlight.vc-highlight-base-middle {
//   background-color: blue !important;
// }
.esfera {
  width: 23px;
  height: 23px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #feb2b2;
}
.circulo {
  border: 2px solid #c53030;
  height: 23px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 23px;
}
</style>
