<template>
  <div class="reserve-filter-long">
    <div class="flex flex-row flex-wrap">
      <v-flex class="w-full md:w-1/2 px-2 md:px-4">
        <v-autocomplete
          type="text"
          :label="$t('placeOfDelivery')"
          v-model="filter.pickLocation"
          outlined
          :prepend-inner-icon="'room'"
          :items="branches"
          :item-text="`name`"
          return-object
          @change="sendFilters"
          :error-messages="pickLocationErrors"
        />
      </v-flex>
      <v-flex class="w-full md:w-1/2 px-2 md:px-4">
        <v-autocomplete
          type="text"
          :label="$t('common.returnLocation')"
          v-model="filter.returnLocation"
          outlined
          :prepend-inner-icon="'room'"
          :items="branches"
          :item-text="`name`"
          return-object
          @change="sendFilters"
          :error-messages="returnLocationErrors"
        />
      </v-flex>
      <v-flex class="w-full md:w-1/4 px-2 md:px-4">
        <DatePickerFW
          :label="$t('common.pickUpDate')"
          @form="setPickUpDate"
          :outlined="true"
          :errors="pickUpDateErrors"
          :minDate="pickUpMinDate"
        />
      </v-flex>
      <v-flex class="w-full md:w-1/4 px-2 md:px-4">
        <TimePickerFW
          :allowedMinutes="(m) => m % 30 === 0"
          :disabledInput="true"
          :label="$t('common.pickUpTime')"
          :showIcon="true"
          @input="setPickUpTime"
          :outlined="true"
          :errors="pickUpTimeErrors"
          :minDate="pickUpMinTime"
        />
      </v-flex>
      <v-flex class="w-full md:w-1/4 px-2 md:px-4">
        <DatePickerFW
          :label="$t('common.returnBackDate')"
          @form="setReturnBackDate"
          :outlined="true"
          :errors="returnBackDateErrors"
          :minDate="returnBackMinDate"
        />
      </v-flex>
      <v-flex class="w-full md:w-1/4 px-2 md:px-4">
        <TimePickerFW
          :allowedMinutes="(m) => m % 30 === 0"
          :disabledInput="true"
          :label="$t('common.returnBackTime')"
          :showIcon="true"
          @input="setReturnHour"
          :outlined="true"
          :errors="returnBackTimeErrors"
          :minDate="returnBackMinTime"
        />
      </v-flex>
    </div>
    <span class="flex justify-end">
      <v-btn
        class="w-full md:w-1/2 focus:outline-none"
        :style="{
          backgroundColor: colors.colorPrimary,
          color: colors.colorOnPrimary,
        }"
        :loading="loading"
        @click="$emit('checkAvailability')"
      >
        {{ $t("mobile.booking.checkAvailability") }}
      </v-btn>
    </span>
    <div class="w-full mt-2 flex justify-end text-red-700" v-if="error">
      {{
        error == "branch"
          ? $t("errors.getBranches")
          : $t("common.noVehiclesFoundDate")
      }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import TimePickerFW from "@/modules/v1/Components/web-core/time-pickerFW.vue";
import DatePickerFW from "@/modules/v1/Components/web-core/date-pickerFW.vue";
import DateUtil from "@/flit2go-web-core/util/dateUtil";

export default {
  name: "ReserveFilterLong",
  components: {
    DatePickerFW,
    TimePickerFW,
  },
  props: {
    loading: { required: true, type: Boolean, default: false },
    error: { required: false, type: Boolean, default: false },
    branches: { required: true, type: Array },
  },
  data: () => ({
    DateUtil,
    showDialogBranch: false,
    filter: {
      pickDate: null,
      returnDate: null,
      pickHour: null,
      returnHour: null,
      pickLocation: null,
      returnLocation: null,
    },
    branchType: "",
    returnBackMinDate: DateUtil.currentDateBasicFormat(),
    returnBackMinTime: "",
    pickUpMinDate: DateUtil.currentDateBasicFormat(),
    pickUpMinTime: "",
  }),
  validations: {
    filter: {
      pickDate: { required },
      returnDate: { required },
      pickHour: { required },
      returnHour: { required },
      pickLocation: { required },
      returnLocation: { required },
    },
  },
  watch: {
    "filter.pickDate"() {
      if (this.filter.pickDate === DateUtil.currentDateBasicFormat()) {
        this.pickUpMinTime = DateUtil.currentTimeHour();
      } else {
        this.pickUpMinTime = "";
      }
    },
    "filter.returnDate"() {
      if (this.filter.returnDate === DateUtil.currentDateBasicFormat()) {
        this.returnBackMinTime = DateUtil.currentTimeHour();
      } else {
        this.returnBackMinTime = "";
      }
    },
  },
  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
    }),

    buttonStyle() {
      return this.styles.primary + ";" + this.styles.onPrimary;
    },
    pickLocationErrors() {
      const errors = [];
      if (!this.$v.filter.pickLocation.$dirty) return errors;
      !this.$v.filter.pickLocation.required &&
        errors.push(this.$t("errors.required"));
      return errors;
    },
    pickUpDateErrors() {
      const errors = [];
      if (!this.$v.filter.pickDate.$dirty) return errors;
      !this.$v.filter.pickDate.required &&
        errors.push(this.$t("errors.required"));
      if (this.filter.pickDate && this.filter.returnDate) {
        let pickDateTimestamp = new Date(this.filter.pickDate);
        let returnDateTimestamp = new Date(this.filter.returnDate);
        if (pickDateTimestamp > returnDateTimestamp)
          errors.push(this.$t("errors.dateLess"));
      }
      return errors;
    },
    pickUpTimeErrors() {
      const errors = [];
      if (!this.$v.filter.pickHour.$dirty) return errors;
      !this.$v.filter.pickHour.required &&
        errors.push(this.$t("errors.required"));
      if (
        this.filter.pickDate &&
        this.filter.returnDate &&
        this.filter.pickHour &&
        this.filter.returnHour
      ) {
        let pickDateTimeTimestamp = new Date(
          this.filter.pickDate + " " + this.filter.pickHour
        );
        if (this.filter.pickDate === this.filter.returnDate) {
          let returnDateTimestamp = new Date(
            this.filter.returnDate + " " + this.filter.returnHour
          );
          if (
            pickDateTimeTimestamp.getTime() >= returnDateTimestamp.getTime()
          ) {
            errors.push(this.$t("errors.hourLess"));
          }
        }
        if (pickDateTimeTimestamp < Date.now() - 3600000) {
          errors.push(this.$t("errors.hourMoreActual"));
        }
      }
      return errors;
    },
    returnLocationErrors() {
      const errors = [];
      if (!this.$v.filter.returnLocation.$dirty) return errors;
      !this.$v.filter.returnLocation.required &&
        errors.push(this.$t("errors.required"));
      return errors;
    },
    returnBackDateErrors() {
      const errors = [];
      if (!this.$v.filter.returnDate.$dirty) return errors;
      !this.$v.filter.returnDate.required &&
        errors.push(this.$t("errors.required"));
      if (this.filter.pickDate && this.filter.returnDate) {
        let pickDateTimestamp = new Date(this.filter.pickDate);
        let returnDateTimestamp = new Date(this.filter.returnDate);
        if (pickDateTimestamp > returnDateTimestamp)
          errors.push(this.$t("errors.dateMore"));
      }

      return errors;
    },
    returnBackTimeErrors() {
      const errors = [];
      if (!this.$v.filter.returnHour.$dirty) return errors;
      !this.$v.filter.returnHour.required &&
        errors.push(this.$t("errors.required"));
      if (
        this.filter.pickDate &&
        this.filter.returnDate &&
        this.filter.pickHour &&
        this.filter.returnHour
      ) {
        if (this.filter.pickDate === this.filter.returnDate) {
          let pickDateTimeTimestamp = new Date(
            this.filter.pickDate + " " + this.filter.pickHour
          );
          let returnDateTimestamp = new Date(
            this.filter.returnDate + " " + this.filter.returnHour
          );
          if (
            pickDateTimeTimestamp.getTime() >= returnDateTimestamp.getTime()
          ) {
            errors.push(this.$t("errors.hourMore"));
          }
        }
      }
      return errors;
    },
  },
  methods: {
    sendFilters() {
      this.$emit("filters", this.filter);
    },
    validate() {
      this.$v.$touch();
      this.$emit("validation", {
        valid: !this.$v.$invalid,
        filter: this.filter,
      });
    },
    setPickUpDate(date) {
      this.filter.pickDate = date;
    },
    setPickUpTime(time) {
      this.filter.pickHour = time;
    },
    setReturnBackDate(time) {
      this.filter.returnDate = time;
    },
    setReturnHour(time) {
      this.filter.returnHour = time;
    },
    setBranch(branch) {
      switch (this.branchType) {
        case "pick":
          this.filter.pickLocation = branch;
          break;
        case "return":
          this.filter.returnLocation = branch;
          break;
      }
    },
  },
};
</script>

<style></style>
