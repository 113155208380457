<template>
  <div
    class="mainFilterComponentNewV2"
    :class="{ 'max-h-full': isVertical }"
    :style="{ 'min-height: 640px': isExtraSmall }"
  >
    <div
      class="home overflow-y-scroll"
      :class="{
        'max-w-128 max-h-full': isVertical && !edit,
        'w-80 max-h-full': isVertical && edit && isExtraSmall,
        'pb-10': !isVertical,
        'mx-auto': !edit,
        'w-full max-h-full':
          edit &&
          (!showCancelButton ||
            showCalendarSelector ||
            showLocationCard ||
            branchShowDetail),
        'max-w-full max-h-full':
          edit &&
          showCancelButton &&
          !showCalendarSelector &&
          !showLocationCard &&
          !branchShowDetail,
      }"
    >
      <div
        v-if="isVertical && !showLoader"
        padding
        class="py-8 flex-wrap"
        :class="{
          'max-h-full': isSmall || isExtraSmall,
          'mx-12': !isSmall && !isExtraSmall && !edit,
          'w-full max-h-full flex justify-center': edit && !showCancelButton,
          'max-w-full max-h-full flex justify-center':
            edit &&
            showCancelButton &&
            !showCalendarSelector &&
            !showLocationCard &&
            !branchShowDetail,
          'w-3/4 max-h-full flex justify-center':
            edit &&
            showCancelButton &&
            (showCalendarSelector || showLocationCard || branchShowDetail) &&
            $vuetify.breakpoint.name !== 'xs' &&
            $vuetify.breakpoint.name !== 'sm',
          'w-full max-h-full flex justify-center':
            edit &&
            showCancelButton &&
            (showCalendarSelector || showLocationCard || branchShowDetail) &&
            $vuetify.breakpoint.name === 'xs' &&
            $vuetify.breakpoint.name === 'sm',
          'px-6 shadow-xl': !showCancelButton,
        }"
        :style="{
          backgroundColor: showCancelButton
            ? colors.colorSurface
            : colors.colorHeader,
          minHeight: '600px',
        }"
      >
        <div
          v-if="!showLocationCard && !branchShowDetail && !showCalendarSelector"
        >
          <div class="flex flex-row align-center mb-4" id="top-part-main">
            <div
              class="rounded-full w-24 h-24 mr-2"
              v-if="filters.showLogo && !showCancelButton"
            >
              <v-layout justify-center>
                <v-img
                  aspect-ratio="1"
                  :src="logo"
                  alt="Logo"
                  class="rounded"
                  contain
                />
              </v-layout>
            </div>
            <div
              class="flex flex-col"
              v-if="filters.showTitle && !showCancelButton"
            >
              <h1
                class="text-2xl text-bold"
                :style="{
                  color: showCancelButton
                    ? colors.colorOnSurface
                    : colors.colorOnHeader,
                }"
              >
                {{
                  labels.textTitle[language]
                    ? labels.textTitle[language]
                    : labels.textTitle.en
                }}
              </h1>
              <span
                :style="{
                  color: showCancelButton
                    ? colors.colorOnSurface
                    : colors.colorOnHeader,
                }"
                >{{
                  labels.textSubtitle[language]
                    ? labels.textSubtitle[language]
                    : labels.textSubtitle.en
                }}</span
              >
            </div>
            <h1
              v-if="showCancelButton"
              class="text-xl text-bold"
              :style="{
                color: showCancelButton
                  ? colors.colorOnSurface
                  : colors.colorOnHeader,
              }"
            >
              {{ $t("modifyDates") }}
            </h1>
          </div>
          <reserve-filter-vertical-new
            v-if="canShowFilters"
            id="reserveFilter"
            ref="reserveFilter"
            :branches="branches"
            :loading="loadingData"
            :loadingBranches="loadingBranches"
            :type="type"
            :errorSend="errorSend"
            :bookingFilters="bookingFilters ? bookingFilters : null"
            :isSuperowner="isSuperowner"
            :size="size"
            @validation="eventReserveValidation"
            @filters="setBookingFilters"
            @changeSelected="changeSelected"
            @send="eventCheckAvailability"
            @vehiclesSelected="eventVehiclesSelected"
            @openLocationCard="openLocationCard"
            @openDateCard="openDateCard"
            @changeDates="changeDatesRange"
          />
          <age-filter
            v-if="filters.showAgeFilter && canShowFilters"
            class="my-2"
            ref="ageFilter"
            :minimumAge="bookingFilters ? bookingFilters.minimumAge : null"
            :type="type"
            @ageSelected="eventAgeSelected"
          />
          <!-- <vehicle-filter
            v-if="filters.showVehicleFilter && canShowFilters"
            :type="type"
            :vehiclesFilter="vehicles"
            :vehicleTypesSelected="
              bookingFilters && bookingFilters.vehicleTypes
                ? bookingFilters.vehicleTypes.toString()
                : null
            "
            @vehiclesSelected="eventVehiclesSelected"
          /> -->
          <v-layout wrap v-if="showCancelButton">
            <v-flex xs12 lg6 class="px-1">
              <v-btn
                class="focus:outline-none mt-4 max-w-lg rounded-config30 w-full"
                :style="{
                  backgroundColor: colors.colorOnPrimary,
                  color: colors.colorPrimary,
                }"
                :loading="loadingData"
                @click="closeLateralPanel"
              >
                {{ $t("common.cancel") }}
              </v-btn>
            </v-flex>
            <v-flex xs12 lg6 class="px-1">
              <v-btn
                class="focus:outline-none mt-4 max-w-lg rounded-config30 w-full"
                :style="{
                  backgroundColor: colors.colorPrimary,
                  color: colors.colorOnPrimary,
                }"
                :loading="loadingData"
                @click="eventCheckAvailability"
              >
                {{ $t("common.search") }}
              </v-btn>
            </v-flex>
          </v-layout>
          <v-btn
            v-else
            class="focus:outline-none mt-4 max-w-lg rounded-config30 w-full"
            :style="{
              backgroundColor: colors.colorPrimary,
              color: colors.colorOnPrimary,
            }"
            :loading="loadingData"
            @click="eventCheckAvailability"
          >
            {{ $t("common.search") }}
          </v-btn>
          <div
            class="w-full mt-2 text-red-700 max-w-lg"
            v-if="error && type != 'horizontal'"
          >
            {{
              error == "branch"
                ? $t("errors.getBranches")
                : error == "category"
                ? errorCategory
                : error
            }}
          </div>
        </div>
        <location-card-selector
          v-show="showLocationCard"
          class="max-h-full w-full"
          :branches="branches"
          :branchToShow="
            this.locationTypeSelected === 'pick'
              ? this.bookingFilters.pickUpBranch
              : this.bookingFilters.returnBackBranch
          "
          @close="closeLocationCard"
          @branchSelected="changeBranchSelected"
          @goToinfoBranch="goToinfoBranch"
        />
        <branch-card-detail
          v-show="branchShowDetail"
          class="max-h-full w-full"
          :branch="branchSelectedDetail"
          :type="locationTypeSelected"
          @close="closeLocationCard"
          @returnBack="branchDetailReturnBack"
        />
        <calendar-date-selector
          v-show="showCalendarSelector"
          class="max-h-full w-full"
          :mode="showCalendarSelectorType"
          :pickUpDate="bookingFilters.pickDate"
          :returnBackDate="bookingFilters.returnDate"
          @close="closeDateCard"
          @changeDates="changeDatesRange"
        />
      </div>
      <div
        v-if="!isVertical && !showLoader"
        padding
        class="py-8 px-6 shadow-xl"
        :class="{
          'rounded-lg': type === 'horizontal',
          'h-max': isSmall || isExtraSmall,
          'mx-12': !isSmall && !isExtraSmall && !this.edit,
        }"
        :style="{ backgroundColor: colors.colorHeader }"
      >
        <v-flex xs12 class="mb-11">
          <v-layout justify-center align-center>
            <div class="rounded-full w-28 h-28 mr-2" v-if="filters.showLogo">
              <v-layout>
                <v-img
                  aspect-ratio="1"
                  :src="logo"
                  alt="Logo"
                  class="rounded"
                />
              </v-layout>
            </div>
            <div
              :style="{ color: colors.colorOnHeader }"
              v-if="filters.showTitle"
            >
              <h1 class="text-3xl font-bold">
                {{
                  labels.textTitle[language]
                    ? labels.textTitle[language]
                    : labels.textTitle.en
                }}
              </h1>
              <span class="text-xl">{{
                labels.textSubtitle[language]
                  ? labels.textSubtitle[language]
                  : labels.textSubtitle.en
              }}</span>
            </div></v-layout
          >
        </v-flex>
        <reserve-filter-horizontal
          v-if="canShowFilters"
          id="reserveFilter"
          ref="reserveFilter"
          :branches="branches"
          :loading="loadingData"
          :loadingBranches="loadingBranches"
          :type="type"
          :errorSend="error"
          :bookingFilters="bookingFilters ? bookingFilters : null"
          :isSuperowner="isSuperowner"
          :size="size"
          @validation="eventReserveValidation"
          @filters="setBookingFilters"
          @changeSelected="changeSelected"
          @send="eventCheckAvailability"
          @ageSelected="eventAgeSelected"
          @vehiclesSelected="eventVehiclesSelected"
          @openDateCard="openDateCard"
          @changeDates="changeDatesRange"
        />
      </div>
      <div v-if="showLoader" class="flex-row flex w-full pb-3 justify-center">
        <div class="w-full flex flex-col justify-center text-center">
          <div class="w-full mb-4">
            <v-progress-linear
              :color="colors.colorPrimary"
              indeterminate
              rounded
              height="8"
            ></v-progress-linear>
          </div>
          <div class="text-2xl font-bold">
            {{ $t("lookingForAvailableVehicles") }}
          </div>
          <div class="font-semibold">
            {{
              this.bookingFiltersNewTab.pickUpNameBranch +
              " - " +
              this.bookingFiltersNewTab.returnBackNameBranch
            }}
          </div>
          <div class="">
            {{ getFormatedDate(this.bookingFiltersNewTab.pickUpDate) }}
            {{ " - " }}
            {{ getFormatedDate(this.bookingFiltersNewTab.returnBackDate) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ReserveFilterVerticalNew from "@/modules/v2/Components/reserve-filter-vertical-new";
// import ReserveFilterVertical from "@/modules/v2/Components/reserve-filter-vertical";
import ReserveFilterHorizontal from "@/modules/v2/Components/reserve-filter-horizontal";
// import VehicleFilter from "@/modules/v2/Components/vehicle-filter";
import AgeFilter from "@/modules/v2/Components/age-filter";
import LocationCardSelector from "@/modules/v2/Components/location-card-selector";
import BranchCardDetail from "@/modules/v2/Components/branch-card-detail";
import CalendarDateSelector from "@/modules/v2/Components/calendar-date-selector";
import DateUtil from "@/flit2go-web-core/util/dateUtil";

export default {
  name: "main-filter-component-new-v2",
  components: {
    ReserveFilterVerticalNew,
    // ReserveFilterVertical,
    ReserveFilterHorizontal,
    // VehicleFilter,
    AgeFilter,
    LocationCardSelector,
    BranchCardDetail,
    CalendarDateSelector,
  },
  props: {
    type: { required: false, type: String, default: "vertical" },
    size: { required: false, type: String, default: "full" },
    errorSend: { required: false, type: String, default: "" },
    edit: { required: false, type: Boolean, default: false },
    currentCategory: { required: false, type: Object, default: null },
    bookingFiltersOld: { required: false, type: Object, default: null },
    showCancelButton: { required: false, type: Boolean, default: false },
  },
  data: () => ({
    DateUtil,
    bookingFilters: {},
    mainFilter: {
      pickUpDate: null,
      returnBackDate: null,
      pickUpBranch: null,
      returnBackBranch: null,
      pickUpNameBranch: null,
      returnBackNameBranch: null,
      pickUpDirectionBranch: null,
      returnBackDirectionBranch: null,
      pickUpLatitude: null,
      pickUpLongitude: null,
      returnBackLatitude: null,
      returnBackLongitude: null,
      vehicleTypes: [],
      minimumAge: null,
      pickUpLogo: null,
      returnLogo: null,
      otherBranch: true,
    },
    branches: [],
    loadingData: false,
    error: null,
    showStepper: false,
    showMap: false,
    mapType: null,
    showLocationCard: false,
    locationTypeSelected: "",
    branchShowDetail: false,
    showCalendarSelector: false,
    showCalendarSelectorType: null,
    branchSelectedDetail: {},
    canShowFilters: false,
    showLoader: false,
  }),
  watch: {
    edit() {
      if (this.edit) {
        this.goToTop();
      }
    },
  },
  beforeCreate() {
    if (
      this.$route.query.hasOwnProperty("pickUpBranch") &&
      this.$route.query.hasOwnProperty("pickUpDate") &&
      this.$route.query.hasOwnProperty("returnBackBranch") &&
      this.$route.query.hasOwnProperty("returnBackDate")
    ) {
      this.showLoader = true;
      const url =
        window.location !== window.parent.location
          ? document.location.ancestorOrigins
            ? document.location.ancestorOrigins[0]
            : document.referrer
          : document.location.origin;

      var newParametersUrl = {};

      newParametersUrl.pickUpBranch = parseInt(this.$route.query.pickUpBranch);
      newParametersUrl.pickUpDate = parseInt(this.$route.query.pickUpDate);
      newParametersUrl.returnBackBranch = parseInt(
        this.$route.query.returnBackBranch
      );
      newParametersUrl.returnBackDate = parseInt(
        this.$route.query.returnBackDate
      );
      newParametersUrl.pickUpNameBranch = decodeURI(
        this.$route.query.pickUpNameBranch
      );
      newParametersUrl.returnBackNameBranch = decodeURI(
        this.$route.query.returnBackNameBranch
      );
      newParametersUrl.pickUpDirectionBranch = decodeURI(
        this.$route.query.pickUpDirectionBranch
      );
      newParametersUrl.returnBackDirectionBranch = decodeURI(
        this.$route.query.returnBackDirectionBranch
      );
      newParametersUrl.pickUpLatitude = decodeURI(
        this.$route.query.pickUpLatitude
      );
      newParametersUrl.pickUpLongitude = decodeURI(
        this.$route.query.pickUpLongitude
      );
      newParametersUrl.returnBackLatitude = decodeURI(
        this.$route.query.returnBackLatitude
      );
      newParametersUrl.returnBackLongitude = decodeURI(
        this.$route.query.returnBackLongitude
      );
      if (
        this.$route.query.vehicleTypes &&
        this.$route.query.vehicleTypes.length
      ) {
        newParametersUrl.vehicleTypes = decodeURI(
          this.$route.query.vehicleTypes.toString()
        );
      } else {
        newParametersUrl.vehicleTypes = [];
      }
      this.categorySelected = null;
      this.showCategorySelected = false;

      // Set language to user language or browser language
      let URLactual = window.location;
      let language;
      if (URLactual.href.includes("lang")) {
        let person = URLactual.href.split("?lang=").pop().split("#")[0];
        language = "en";
        if (
          person === "es" ||
          person === "de" ||
          person === "pt" ||
          person === "it" ||
          person === "fr" ||
          person === "ca"
        ) {
          language = person;
        }
      } else {
        language = localStorage.getItem("user-language");
      }
      if (language.includes("_")) language = language.split("_")[0];
      localStorage.setItem("user-language", language);
      this.$store.dispatch("settings/CHANGE_LANG", { language: language });

      this.$store.commit("configuration/SET_BOOKING_FILTERS", newParametersUrl);
      localStorage.setItem(`apiToken-${url}`, this.$route.query.apiKey);
    }
  },
  computed: {
    ...mapState(["category, branchVehicle, configuration"]),
    ...mapGetters({
      logo: "configuration/getLogo",
      contact: "configuration/getContact",
      vehicles: "configuration/getVehiclesFilter",
      colors: "configuration/getColors",
      labels: "configuration/getLabels",
      language: "configuration/getUserLanguage",
      isSuperowner: "configuration/getSuperowner",
      filters: "configuration/getFilters",
      urlNewTab: "settings/getUrlNewTab",
      bookingFiltersNewTab: "configuration/getBookingFilters",
      ownerTimezone: "configuration/getOwnerTimezone",
      orderedHours: "configuration/getOrderedHours",
      gtagCode: "configuration/getGtagCode",
    }),
    isVertical() {
      return this.type === "vertical";
    },
    isSmall() {
      return this.$vuetify.breakpoint.name === "sm";
    },
    isExtraSmall() {
      return this.$vuetify.breakpoint.name === "xs";
    },
    errorCategory() {
      let text;
      if (this.contact.phoneNumber && this.contact.email) {
        text =
          this.$t("common.noVehiclesFoundDate") +
          ", " +
          this.$t("mobile.booking.nveText").toLowerCase() +
          this.contact.phoneNumber +
          "/" +
          this.contact.email;
      } else if (this.contact.phoneNumber) {
        text =
          this.$t("common.noVehiclesFoundDate") +
          ", " +
          this.$t("mobile.booking.nveText").toLowerCase() +
          this.contact.phoneNumber;
      } else if (this.contact.email) {
        text =
          this.$t("common.noVehiclesFoundDate") +
          ", " +
          this.$t("mobile.booking.nveText").toLowerCase() +
          this.contact.email;
      } else {
        text = this.$t("noVehiclesFoundDateTryOtherBranch");
      }
      return text;
    },
  },
  mounted() {
    if (this.edit) {
      this.goToTop();
    }
    let params = {
      categoryId: this.currentCategory ? this.currentCategory.id : null,
    };
    this.searchBranches(params);
    if (
      this.bookingFiltersNewTab.pickUpBranch &&
      this.bookingFiltersNewTab.returnBackBranch &&
      !this.edit
    ) {
      this.showLoader = true;
      // this.loadingData = true;
      setTimeout(() => {
        this.mainFilter = this.bookingFiltersNewTab;
        this.goToReservation();
      }, 500);
    }
  },
  methods: {
    closeLateralPanel() {
      this.$emit("close");
    },
    goToTop() {
      window.parent.postMessage("goToTop", "*");
    },
    getFormatedDate(dateToFormat) {
      let date = DateUtil.formatCustomFrameDate(dateToFormat, this.language);
      date = date.charAt(0).toUpperCase() + date.slice(1);
      date = date.replace(".", "");
      return date;
    },
    setDefaultDates() {
      const time = DateUtil.currentTime().toString().split(":");
      let hours = Number(time[0]);
      let minutes = Number(time[1]);
      let addDay = false;
      if (minutes < 30) {
        minutes = "30";
      } else {
        if (hours === 23) {
          addDay = true;
          hours = "00";
        } else {
          hours = hours + 1;
        }
        minutes = "00";
      }
      if (hours.toString().length === 1) {
        hours = `0${hours}`;
      }

      let formattedTime = `${hours}:${minutes}`;

      if (
        this.filters.hours &&
        !this.filters.hours.find((hour) => hour === formattedTime)
      ) {
        let nextHour = this.orderedHours.find(
          (orderedHour) =>
            (Number(orderedHour.split(":")[0]) === Number(hours) &&
              Number(orderedHour.split(":")[1]) >= Number(minutes)) ||
            Number(orderedHour.split(":")[0]) > Number(hours)
        );

        if (!nextHour) {
          nextHour = this.orderedHours[0];
          addDay = true;
        }
        formattedTime = nextHour;
      }

      const todayDate = DateUtil.currentDateBasicFormat();
      this.bookingFilters.pickDate = todayDate;
      this.bookingFilters.returnDate = this.$PageUtils.getTomorrowDate();
      this.bookingFilters.pickHour = formattedTime;
      this.bookingFilters.returnHour = formattedTime;

      if (addDay) {
        this.bookingFilters.pickDate = DateUtil.addDate(
          this.bookingFilters.pickDate,
          1,
          "days"
        ).split(" ")[0];
        this.bookingFilters.returnDate = DateUtil.addDate(
          this.bookingFilters.returnDate,
          1,
          "days"
        ).split(" ")[0];
      }

      this.canShowFilters = true;
    },
    setBookingFilters(filters) {
      this.bookingFilters.pickDate = filters.pickDate;
      this.bookingFilters.returnDate = filters.returnDate;
      this.bookingFilters.pickHour = filters.pickHour;
      this.bookingFilters.returnHour = filters.returnHour;
    },
    eventCheckAvailability() {
      this.error = null;
      this.loadingData = true;
      setTimeout(() => this.validateData(), 1000);
    },
    searchBranches(query) {
      this.loadingBranches = true;
      query.type = this.$CONSTANTS.INTEGRATION.FRAME_WEB;
      this.$store
        .dispatch("branchVehicle/getAvailableBranches", query)
        .then((resp) => {
          if (resp.status !== 200) {
            this.branches = [];
            this.error = "branch";
          } else {
            this.error = null;
            this.branches = resp.data;
            this.startFilters();
            this.loadingBranches = false;
          }
        });
    },
    startFilters() {
      if (this.edit && this.bookingFiltersOld) {
        this.branches.forEach((branch) => {
          if (branch.id === this.bookingFiltersOld.pickUpBranch) {
            this.bookingFilters.pickUpBranch = branch;
          }
          if (branch.id === this.bookingFiltersOld.returnBackBranch) {
            this.bookingFilters.returnBackBranch = branch;
          }
        });
        this.bookingFilters.pickDate = this.$PageUtils.dateFormatNotTimeInt(
          Number(this.bookingFiltersOld.pickUpDate)
        );
        this.bookingFilters.returnDate = this.$PageUtils.dateFormatNotTimeInt(
          Number(this.bookingFiltersOld.returnBackDate)
        );
        this.bookingFilters.pickHour = DateUtil.formatBasicHour(
          Number(this.bookingFiltersOld.pickUpDate)
        );
        this.bookingFilters.returnHour = DateUtil.formatBasicHour(
          Number(this.bookingFiltersOld.returnBackDate)
        );
        this.bookingFilters.minimumAge = this.bookingFiltersOld.minimumAge;
        this.bookingFilters.vehicleTypes = this.bookingFiltersOld.vehicleTypes;
        this.canShowFilters = true;
      } else {
        this.setDefaultDates();
      }
    },

    openLocationCard(type) {
      this.showLocationCard = true;
      this.locationTypeSelected = type;
    },
    openDateCard(type) {
      this.showCalendarSelectorType = type;
      this.showCalendarSelector = true;
    },
    closeDateCard() {
      this.showCalendarSelector = false;
    },
    closeLocationCard() {
      this.showLocationCard = false;
      this.branchShowDetail = false;
      this.locationTypeSelected = "";
    },
    branchDetailReturnBack() {
      this.showLocationCard = true;
      this.branchShowDetail = false;
      this.branchSelectedDetail = {};
    },
    changeBranchSelected(branch) {
      this.showLocationCard = false;
      if (this.locationTypeSelected === "pick") {
        this.bookingFilters.pickUpBranch = branch;
        if (
          !this.mainFilter.otherBranch ||
          !this.filters.allowReturnInAnotherBranch
        ) {
          this.bookingFilters.returnBackBranch = branch;
        }
      }
      if (this.locationTypeSelected === "return") {
        this.bookingFilters.returnBackBranch = branch;
      }
      this.locationTypeSelected = "";
    },
    goToinfoBranch(branch) {
      this.showLocationCard = false;
      this.branchShowDetail = true;
      this.branchSelectedDetail = branch;
    },
    validateData() {
      this.$refs.reserveFilter.validate();
    },

    eventVehiclesSelected(vehiclesSelected) {
      this.bookingFilters.vehicleTypes = vehiclesSelected
        .toString()
        .toUpperCase();
    },
    eventAgeSelected(ageSelected) {
      this.bookingFilters.minimumAge = ageSelected;
    },

    eventReserveValidation(event) {
      if (event.valid) {
        this.mainFilter.otherBranch = event.filter.otherBranch;
        this.mainFilter.pickUpBranch = event.filter.pickLocation.id;
        this.mainFilter.returnBackBranch = event.filter.otherBranch
          ? event.filter.returnLocation.id
          : event.filter.pickLocation.id;
        this.mainFilter.pickUpDate =
          this.$PageUtils.formatDateChangeTimeZoneTimestamp(
            `${event.filter.pickDate} ${event.filter.pickHour}`,
            this.ownerTimezone
          );
        this.mainFilter.returnBackDate =
          this.$PageUtils.formatDateChangeTimeZoneTimestamp(
            `${event.filter.returnDate} ${event.filter.returnHour}`,
            this.ownerTimezone
          );

        this.mainFilter.pickUpNameBranch = event.filter.pickLocation.name;
        this.mainFilter.returnBackNameBranch = event.filter.returnLocation.name;
        this.mainFilter.pickUpDirectionBranch =
          event.filter.pickLocation.positionStr;
        this.mainFilter.returnBackDirectionBranch =
          event.filter.returnLocation.positionStr;
        this.mainFilter.pickUpLatitude = event.filter.pickLocation.latitude;
        this.mainFilter.pickUpLongitude = event.filter.pickLocation.longitude;
        this.mainFilter.returnBackLatitude =
          event.filter.returnLocation.latitude;
        this.mainFilter.returnBackLongitude =
          event.filter.returnLocation.longitude;
        this.mainFilter.pickUpLogo = event.filter.pickLocation.owner.logo;
        this.mainFilter.returnLogo = event.filter.returnLocation.owner.logo;
        this.mainFilter.minimumAge = event.filter.minimumAge
          ? event.filter.minimumAge
          : this.bookingFilters.minimumAge;
        this.mainFilter.vehicleTypes =
          this.bookingFilters.vehicleTypes &&
          this.bookingFilters.vehicleTypes.length
            ? this.bookingFilters.vehicleTypes
            : this.vehicles;
        this.$store.commit(
          "configuration/SET_BOOKING_FILTERS",
          this.mainFilter
        );
        this.goToReservation();
        if (this.gtagCode) {
          this.$gtag.event("category_list", {
            event_name: "category_list",
          });
        }
      } else {
        this.loadingData = false;
      }
    },
    changeSelected(branch, type = "pickUp") {
      if (type === "pickUp") {
        this.bookingFilters.pickUpBranch = branch;
      } else {
        this.bookingFilters.returnBackBranch = branch;
      }
    },

    goToReservation() {
      this.$store
        .dispatch("category/getAvailableCategories", this.mainFilter)
        .then((resp) => {
          this.loadingData = false;
          if (resp.status !== 200) {
            this.error = "category";
          } else {
            this.$emit("close", true);
            const bookingInfo = JSON.stringify(this.mainFilter);
            if (this.urlNewTab) {
              var urlToOpenNewWindow =
                this.urlNewTab +
                "?pickUpDate=" +
                encodeURI(this.mainFilter.pickUpDate) +
                "&returnBackDate=" +
                encodeURI(this.mainFilter.returnBackDate) +
                "&pickUpBranch=" +
                encodeURI(this.mainFilter.pickUpBranch) +
                "&returnBackBranch=" +
                encodeURI(this.mainFilter.returnBackBranch) +
                "&pickUpNameBranch=" +
                encodeURI(this.mainFilter.pickUpNameBranch) +
                "&returnBackNameBranch=" +
                encodeURI(this.mainFilter.returnBackNameBranch) +
                "&pickUpDirectionBranch=" +
                encodeURI(this.mainFilter.pickUpDirectionBranch) +
                "&returnBackDirectionBranch=" +
                encodeURI(this.mainFilter.returnBackDirectionBranch) +
                "&pickUpLatitude=" +
                encodeURI(this.mainFilter.pickUpLatitude) +
                "&pickUpLongitude=" +
                encodeURI(this.mainFilter.pickUpLongitude) +
                "&returnBackLatitude=" +
                encodeURI(this.mainFilter.returnBackLatitude) +
                "&returnBackLongitude=" +
                encodeURI(this.mainFilter.returnBackLongitude) +
                "&vehicleTypes=" +
                encodeURI(this.mainFilter.vehicleTypes) +
                "&apiKey=" +
                encodeURI(this.$route.query.apiKey) +
                "#?lang=" +
                encodeURI(this.language);
              window.open(urlToOpenNewWindow, "_top");
            } else {
              switch (this.$route.params.mode) {
                case "current":
                  this.$router.push({
                    name: "ReservationV2",
                    query: this.$route.query,
                  });
                  break;
                case "dialog":
                  this.showStepper = true;
                  break;
                case "new":
                  window.parent.postMessage(bookingInfo, "*"); // Emit booking data for other frames
                  break;
                default:
                  break;
              }
            }
          }
        })
        .catch((err) => {
          this.loadingData = false;
          this.error = err.response.data.error
            ? err.response.data.error
            : err.response.data;
        });
    },
    changeDatesRange(dates) {
      this.bookingFilters.pickDate = this.DateUtil.formatBasicDateNoTimeZone(
        dates.start,
        this.ownerTimezone
      );
      this.bookingFilters.returnDate = this.DateUtil.formatBasicDateNoTimeZone(
        dates.end,
        this.ownerTimezone
      );
    },
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
