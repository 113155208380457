var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-details-catalogue overflow-y-scroll",class:{ flex: !_vm.fullscreen }},[(_vm.fullscreen)?_c('div',{staticClass:"m-4"},[_c('div',{staticClass:"px-4 pt-6 pb-1 rounded-t-xl shadow",style:({ backgroundColor: _vm.colors.colorSurface })},[_c('h1',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("vehicleDetails"))+" ")])])]):_vm._e(),(_vm.fullscreen)?_c('div',{staticClass:"m-4"},[_c('ReserveFilterCatalogue',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showCalendarSelector),expression:"!showCalendarSelector"}],ref:"reserveFilterCatalogue",attrs:{"calendarDates":_vm.calendarDates,"currentCategory":_vm.categoryRow},on:{"filter":_vm.changeCalendar,"changeCategory":(value) => {
          _vm.$emit('changeCategory', value);
        },"openDateCard":_vm.openDateCard}}),(_vm.showCalendarSelector)?_c('CategoryCardCalendar',{staticClass:"mb-12",attrs:{"categoryRow":_vm.categoryRow,"mode":_vm.mode,"fullscreen":"","reserveFilter":_vm.reserveFilter},on:{"changeDates":_vm.changeReserveFilter,"close":_vm.closeDateCard}}):_vm._e()],1):_vm._e(),(_vm.fullscreen)?_c('div',{staticClass:"mx-4 flex flex-row align-center"},[_c('div',{staticClass:"p-1 rounded-full shadow mr-2 cursor-pointer",style:({ backgroundColor: _vm.colors.colorSurface })},[_c('F2gIcon',{staticClass:"inline-block align-middle",attrs:{"stroke":_vm.colors.colorPrimary,"value":'arrow-left',"height":28,"width":28,"strokeWidth":6},on:{"click":function($event){return _vm.$emit('close')}}})],1),_c('div',{staticClass:"text-sm cursor-pointer",style:({ color: _vm.colors.colorPrimary }),on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t("returnToVehicles"))+" ")])]):_vm._e(),_c('div',{staticClass:"lg:w-9/12 mx-4 max-h-full backdrop-blur-xl shadow rounded-lg",class:_vm.fullscreen ? 'my-2' : 'my-12',style:({ backgroundColor: _vm.colors.colorSurface })},[_c('div',{staticClass:"flex align-center justify-center w-full flex-col",style:([
        _vm.fullscreen ? { backgroundColor: _vm.colors.colorSurfaceMedium } : '',
      ])},[(!_vm.fullscreen)?_c('div',{staticClass:"absolute left-10 mt-8 flex flex-row align-center"},[_c('div',{staticClass:"p-1 rounded-full shadow mr-2 cursor-pointer",style:({ backgroundColor: _vm.colors.colorSurface })},[_c('F2gIcon',{staticClass:"inline-block align-middle",attrs:{"stroke":_vm.colors.colorPrimary,"value":'arrow-left',"height":28,"width":28,"strokeWidth":4},on:{"click":function($event){return _vm.$emit('close')}}})],1),_c('div',{staticClass:"text-sm cursor-pointer",style:({ color: _vm.colors.colorPrimary }),on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t("returnToVehicles"))+" ")])]):_vm._e(),_c('div',{staticClass:"flex flex-row items-center align-center justify-center mx-2 w-4/6",class:{ 'mt-8 relative': !_vm.fullscreen, 'h-12 my-2': _vm.fullscreen }},[_c('h1',{staticClass:"text-lg md:text-2xl text-xl font-bold ml-2 inline-block items-center align-center",style:({ color: _vm.colors.colorOnSurfaceMedium })},[_vm._v(" "+_vm._s(_vm.categoryRow.name)+" ")])])]),_c('div',{staticClass:"flex align-center justify-center w-full flex-col",class:{ 'px-4': !_vm.fullscreen }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCarousel),expression:"showCarousel"}],staticClass:"bg-gray-200 max-h-full relative mx-2 lg:w-4/6 w-full",class:{
          'rounded-lg mt-4': !_vm.fullscreen,
        }},[_c('Carousel',{attrs:{"borderRadius":_vm.fullscreen ? '0px' : '8px',"images":_vm.images,"showPagination":false,"showFooter":false},on:{"mounted":_vm.mountedCarousel}})],1),(!_vm.showCarousel)?_c('div',{staticClass:"bg-gray-200 max-h-full relative mt-4 rounded-xl mx-2 w-4/6"},[_c('v-img',{staticClass:"image rounded-lg",attrs:{"src":_vm.categoryRow.img,"aspect-ratio":16 / 9}})],1):_vm._e(),(_vm.categoryRow.attributeList.length && _vm.fullscreen)?_c('div',{staticClass:"h-16 w-full rounded-b-xl relative bottom-0"},[_c('div',{staticClass:"max-h-full w-full px-2 flex flex-row align-center overflow-x-hidden category_services",style:({ backgroundColor: _vm.colors.colorSurfaceMedium })},_vm._l((_vm.categoryRow.attributeList),function(attribute,index){return _c('div',{key:index},[(index < 3)?_c('div',{staticClass:"inline-block my-2 text-sm align-middle mr-2 flex flex-row"},[_c('div',{staticClass:"pa-1 m-1 flex flex-none align-center"},[_c('svg',{staticStyle:{"height":"24px","width":"24px"},attrs:{"fill":_vm.colors.colorPrimary}},[(attribute.icon.path)?_c('path',{attrs:{"d":attribute.icon.path}}):_vm._e()])]),_c('div',{staticClass:"flex align-center inline-block align-middle text-xs mt-1",style:({ color: _vm.colors.colorOnSurface })},[_vm._v(" "+_vm._s(attribute.supportedLangs[ _vm.$PageUtils.getLanguageSplittedMinus(_vm.$i18n.locale) ] ? attribute.supportedLangs[ _vm.$PageUtils.getLanguageSplittedMinus(_vm.$i18n.locale) ] : attribute.supportedLangs.en)+" ")])]):_vm._e()])}),0)]):_vm._e(),(_vm.fullscreen)?_c('PriceResumeData',{attrs:{"currentCategory":_vm.categoryRow,"detailCatalogue":true,"loading":_vm.loading},on:{"selected":(value, pack) => {
            _vm.priceSelected(value, pack);
          }}}):_vm._e(),(!_vm.fullscreen)?_c('div',{staticClass:"w-full flex flex-row align-center flex-wrap"},_vm._l((_vm.categoryRow.attributeList),function(attribute,index){return _c('div',{key:index,staticClass:"inline-block mr-2 mt-4"},[_c('div',{staticClass:"text-sm align-middle mr-2 inline-block rounded-md flex-none",style:({
              backgroundColor: _vm.colors.colorSurfaceMedium,
              color: _vm.colors.colorPrimary,
            })},[_c('div',{staticClass:"pa-1 m-1 inline-block align-middle"},[_c('svg',{staticStyle:{"height":"24px","width":"24px"},attrs:{"fill":_vm.colors.colorPrimary}},[(attribute.icon.path)?_c('path',{attrs:{"d":attribute.icon.path}}):_vm._e()])]),_c('div',{staticClass:"align-center inline-block align-middle mr-3",style:({ color: _vm.colors.colorOnSurfaceMedium })},[_vm._v(" "+_vm._s(attribute.supportedLangs[_vm.$i18n.locale.split("_")[0]] ? attribute.supportedLangs[_vm.$i18n.locale.split("_")[0]] : attribute.supportedLangs.en)+" ")])])])}),0):_vm._e()],1),_c('div',{staticClass:"pt-4 px-4"},[_c('div',{staticClass:"rounded-2xl mb-4",style:({
          backgroundColor: _vm.colors.colorSurface,
          color: _vm.colors.colorOnSurface,
        })},[(_vm.categoryRow.description)?_c('div',{staticClass:"text-sm overflow-clip overflow-hidden",style:({
            color: _vm.colors.colorOnSurface,
          }),domProps:{"innerHTML":_vm._s(_vm.categoryRow.description[_vm.$i18n.locale.split('_')[0]])}}):_vm._e()])])]),(!_vm.fullscreen)?_c('div',{staticClass:"relative top-6 w-3/12 px-0 max-h-full min-h-96"},[_c('div',{staticClass:"m-4 rounded-lg"},[_c('ReserveFilterCatalogue',{ref:"reserveFilterCatalogue",attrs:{"calendarDates":_vm.calendarDates,"currentCategory":_vm.categoryRow,"reserveFilter":_vm.reserveFilter},on:{"filter":_vm.changeCalendar,"changeCategory":(value) => {
            _vm.$emit('changeCategory', value);
          },"changeDates":_vm.changeReserveFilter}}),_c('PriceResumeData',{attrs:{"currentCategory":_vm.categoryRow,"detailCatalogue":false,"loading":_vm.loading},on:{"selected":(value, pack) => {
            _vm.priceSelected(value, pack);
          }}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }