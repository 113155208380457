const COUNTRY_CODE = Object.freeze({
  SPANISH: "ES",
});

const COUNTRY_NAME = Object.freeze({
  ES: "Spain",
  AU: "Australia",
});

export default Object.freeze({
  CODE: COUNTRY_CODE,
  NAME: COUNTRY_NAME,
});
