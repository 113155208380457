import service from "@/services/categoryService";

export default {
  namespaced: true,
  state: {
    status: "",
    categoriesList: null,
    franchiseList: null,
    franchiseSelected: null,
    categoryIdUrl: null,
    attributesUrl: null,
    category: {
      attributeList: null,
      date: null,
      description: null,
      extraList: null,
      id: null,
      img: null,
      name: null,
      pricePerTime: null,
      timeType: null,
      personUpdateCreate: null,
      pricingAcronym: null,
      pricingName: null,
      totalPrice: null,
      totalVehicle: null,
      vehicleType: null,
      webDescriptionString: null,
      earlyPay: null,
    },
    pack: null,
  },
  getters: {
    status: (state) => {
      return state.status;
    },
    categoriesList: (state) => {
      return state.categoriesList;
    },
    franchiseList: (state) => {
      return state.franchiseList;
    },
    categorySelected: (state) => {
      return state.category;
    },
    franchiseSelected: (state) => {
      return state.franchiseSelected;
    },
    getCategoryIdUrl: (state) => {
      return state.categoryIdUrl;
    },
    getAttributesUrl: (state) => {
      return state.attributesUrl;
    },
    packSelected: (state) => {
      return state.pack;
    },
  },

  mutations: {
    // Status
    STATUS_LOADING: (state) => {
      state.status = "loading";
    },
    STATUS_SUCCESS: (state) => {
      state.status = "success";
    },
    STATUS_ERROR: (state) => {
      state.status = "error";
    },
    ["SET_CATEGORIES_LIST"]: (state, list) => {
      state.categoriesList = list;
    },
    ["SET_FRANCHISE_LIST"]: (state, list) => {
      state.franchiseList = list;
    },
    ["SET_CATEGORY"]: (state, category) => {
      state.category = Object.assign({}, category);
    },
    ["SET_FRANCHISE_SELECTED"]: (state, franchise) => {
      if (franchise) {
        state.franchiseSelected = Object.assign({}, franchise);
      } else {
        state.franchiseSelected = null;
      }
    },
    ["SET_CATEGORY_ID_URL"]: (state, categoryId) => {
      state.categoryIdUrl = categoryId;
    },
    ["SET_ATTRIBUTES_URL"]: (state, attributes) => {
      state.attributesUrl = attributes;
    },
    ["SET_PACK_RATE"]: (state, pack) => {
      state.pack = pack;
    },
  },

  actions: {
    getAvailableCategories({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getAvailableCategories(params)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resp.status === 200
              ? commit("SET_CATEGORIES_LIST", resp.data)
              : commit("SET_CATEGORIES_LIST", []);
            commit("SET_CATEGORY", null);
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getAvailableCategoriesCatalogue({ commit }, headers) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getAvailableCategoriesCatalogue(headers)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resp.status === 200
              ? commit("SET_CATEGORIES_LIST", resp.data)
              : commit("SET_CATEGORIES_LIST", []);
            commit("SET_CATEGORY", null);
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getFranchisesByCategoryId({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getFranchisesByCategoryId(params)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            commit("SET_FRANCHISE_LIST", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getAvailabilityByCategoryId({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getAvailabilityByCategoryId(params)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            // commit("SET_CATEGORY", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    canShowPriceDeferredCategory({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .canShowPriceDeferredCategory(data)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getCategoryPackRatePrice({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getCategoryPackRatePrice(data)
          .then((resp) => {
            commit("STATUS_SUCCESS");

            resolve(resp);
          })
          .catch((err) => reject(err));
      });
    },
  },
};
