var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-component"},[_c('v-skeleton-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading || !_vm.mounted),expression:"loading || !mounted"}],staticClass:"mx-auto",attrs:{"height":"500px","type":"image"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.mounted && !_vm.loading),expression:"mounted && !loading"}],staticClass:"carousel",style:(`width:${_vm.width} !important; border-radius: ${_vm.borderRadius};`),attrs:{"id":"carousel"}},[_vm._l((_vm.images),function(image,index){return _c('div',{key:index,staticClass:"carousel__item",attrs:{"id":`block${index + 1}`}},[_c('img',{style:(`width:${_vm.width} !important;object-fit: cover; !important;aspect-ratio:${_vm.aspectRatio} !important; border-radius: ${_vm.borderRadius}`),attrs:{"src":image,"alt":image}})])}),(_vm.images.length > 0)?_c('div',{staticClass:"carousel__buttons px-4",style:(`width:${_vm.width} !important`)},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"carousel__buttons__item",on:{"click":function($event){return _vm.previousPositionEvent(null)}}},[_c('F2gIcon',{attrs:{"value":"arrow-left","strokeWidth":6,"height":40,"width":40,"stroke":"white"}})],1),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"carousel__buttons__item",on:{"click":function($event){return _vm.nextPositionEvent(null)}}},[_c('F2gIcon',{attrs:{"value":"arrow-right","strokeWidth":6,"height":40,"width":40,"stroke":"white"}})],1)]):_vm._e(),(_vm.showFooter)?_c('div',{staticClass:"carousel__footer",class:{
        'carousel__footer--big': !_vm.showPagination,
        'carousel__footer--small': _vm.showPagination,
      },style:(`border-bottom-left-radius: ${_vm.borderRadius};border-bottom-right-radius: ${_vm.borderRadius}`)},[(_vm.showPagination)?_c('div',{staticClass:"carousel__footer__position"},_vm._l((_vm.images.length),function(index){return _c('div',{key:index,staticClass:"carousel__footer__position__container mx-2",class:{
            'carousel__footer__position__container--selected':
              _vm.position === index,
            'carousel__footer__position__container--not-selected':
              _vm.position !== index,
          }},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"carousel__footer__position__container__item",class:{
              'carousel__footer__position__container__item--selected':
                _vm.position === index,
            },on:{"click":function($event){return _vm.goTo(index)}}})])}),0):_vm._t("footer")],2):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }