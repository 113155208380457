<template>
  <div class="driver-selected-data" v-if="userInfo">
    <div class="flex flex-col flex-wrap mb-4">
      <div class="relative w-max">
        <h2
          class="relative leading-4 font-medium text-lg"
          :style="{ color: colors.colorOnBackground }"
        >
          {{ $t("common.dataPrincipalDriver") }}
        </h2>
        <div
          class="absolute -top-2 -right-10 flex flex-row align-end p-2 rounded-full"
          :style="{ backgroundColor: colors.colorPrimary }"
          @click="
            () => {
              userInfo.imageLinks = imageLinks;
              showEdit = true;
            }
          "
        >
          <F2gIcon
            :height="14"
            :width="14"
            :stroke="colors.colorOnPrimary"
            file="icons_solid.svg"
            class="cursor-pointer"
            :value="'edit'"
            :strokeWidth="4"
          />
        </div>
      </div>
    </div>
    <div v-if="!showEdit">
      <div class="flex sm:flex-row flex-col w-full">
        <span
          class="sm:w-1/3 w-full mr-2 p-1 pl-2 rounded-lg border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
          }"
          v-if="userInfo.name"
          >{{ userInfo.name + " " + userInfo.surnames }}</span
        >
        <span
          class="sm:w-1/3 w-full mr-2 p-1 pl-2 rounded-lg border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
          }"
          v-if="userInfo.username"
          >{{ userInfo.username }}</span
        >
        <span
          class="sm:w-1/3 w-full mr-2 p-1 pl-2 rounded-lg border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
          }"
          v-if="userInfo.phoneNumber"
          >{{ userInfo.phoneNumber }}</span
        >
      </div>
      <div class="flex sm:flex-row flex-col w-full mt-2">
        <span
          class="sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
          }"
          v-if="userInfo.bornDate"
          >{{ userInfo.bornDate }}</span
        >
        <span
          class="sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
          }"
          v-if="userInfo.country"
          >{{ $PageUtils.getObjectNameLower(userInfo.country) }}</span
        >
      </div>
      <div
        class="flex flex-row w-full sm:mt-2"
        v-if="userInfo.address && userInfo.address.direction"
      >
        <p
          class="leading-4 font-medium text-lg mb-3"
          :style="{ color: colors.colorOnBackground, paddingTop: '5px' }"
        >
          {{ $t("forms.address.residenceAddress") }}
        </p>
      </div>
      <div class="flex sm:flex-row flex-col w-full">
        <span
          v-if="userInfo.address && userInfo.address.direction"
          class="sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
          }"
          >{{ userInfo.address.direction }}</span
        >
        <span
          v-if="userInfo.address && userInfo.address.directionAdditionalInfo"
          class="sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
          }"
          >{{ userInfo.address.directionAdditionalInfo }}</span
        >
      </div>
      <div class="flex sm:flex-row flex-col w-full mt-2">
        <span
          v-if="userInfo.address && userInfo.address.country"
          class="sm:w-1/4 w-full mr-2 p-1 pl-2 rounded-lg border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
          }"
          >{{ $PageUtils.getObjectNameLower(userInfo.address.country) }}</span
        >
        <span
          v-if="userInfo.address && userInfo.address.city"
          class="sm:w-1/4 w-full mr-2 p-1 pl-2 rounded-lg border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
          }"
          >{{ userInfo.address.city }}</span
        >
        <span
          v-if="userInfo.address && userInfo.address.locality"
          class="sm:w-1/4 w-full mr-2 p-1 pl-2 rounded-lg border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
          }"
          >{{ userInfo.address.locality }}</span
        >
        <span
          v-if="userInfo.address && userInfo.address.postalCode"
          class="sm:w-1/4 w-full mr-2 p-1 pl-2 rounded-lg border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
          }"
          >{{ userInfo.address.postalCode }}</span
        >
      </div>
      <div class="flex flex-row w-full sm:mt-2" v-if="userInfo.nif">
        <p
          class="leading-4 font-medium text-lg mb-3"
          :style="{ color: colors.colorOnBackground, paddingTop: '5px' }"
        >
          {{ $t("forms.nif") }}
        </p>
      </div>
      <div class="flex sm:flex-row flex-col w-full">
        <span
          class="sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
          }"
          v-if="userInfo.nif"
          >{{ userInfo.nif }}</span
        >
        <span
          class="sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
          }"
          v-if="userInfo.nifValidityDate"
          >{{
            DateUtil.beautifullFormatedDate(new Date(userInfo.nifValidityDate))
          }}</span
        >
      </div>
      <div class="flex sm:flex-row flex-col w-full mt-2 ml-2">
        <div class="flex flex-col">
          <v-img
            v-if="imageLinks['imgDniFront']"
            :src="imageLinks['imgDniFront']"
            :aspect-ratio="16 / 9"
            max-width="300px"
            class="image rounded-lg mr-2"
          />
          <span class="mr-4"
            ><v-icon
              v-if="userInfo.nifImgFrontName"
              left
              small
              class="mb-1"
              :color="colors.colorPrimary"
            >
              mdi-camera-plus
            </v-icon>
            {{ userInfo.nifImgFrontName }}</span
          >
        </div>
        <div class="flex flex-col">
          <v-img
            v-if="imageLinks['imgDniReverse']"
            :src="imageLinks['imgDniReverse']"
            :aspect-ratio="16 / 9"
            max-width="300px"
            class="image rounded-lg mr-2"
          />
          <span
            ><v-icon
              v-if="userInfo.nifImgReverseName"
              left
              small
              class="mb-1"
              :color="colors.colorPrimary"
            >
              mdi-camera-plus </v-icon
            >{{ userInfo.nifImgReverseName }}</span
          >
        </div>
      </div>
      <div
        class="flex flex-row w-full sm:mt-2"
        v-if="userInfo.typeLicenseDriving"
      >
        <p
          class="leading-4 font-medium text-lg mb-3"
          :style="{ color: colors.colorOnBackground, paddingTop: '5px' }"
        >
          {{ $t("mobile.register.licenseDriving") }}
        </p>
      </div>
      <div class="flex sm:flex-row flex-col w-full">
        <span
          class="sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
          }"
          v-if="userInfo.typeLicenseDriving"
          >{{
            userInfo.typeLicenseDriving.code ===
            $CONSTANTS.DRIVING_LICENSE.TYPE.OBTAINED_IN_COUNTRY
              ? $t("forms.drivingLicenseCountry") +
                ": " +
                $PageUtils.getObjectNameLower(userInfo.countryLicenseDriving)
              : $t("common.drivingLicenseTypeB")
          }}</span
        >
        <span
          class="sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
          }"
          v-if="userInfo.drivingPermissionValidityDate"
          >{{
            DateUtil.beautifullFormatedDate(
              new Date(userInfo.drivingPermissionValidityDate)
            )
          }}</span
        >
      </div>
      <div class="flex sm:flex-row flex-col w-full mt-2 ml-2">
        <div class="flex flex-col">
          <v-img
            v-if="imageLinks['imgDrivingLicenseFront']"
            :src="imageLinks['imgDrivingLicenseFront']"
            :aspect-ratio="16 / 9"
            max-width="300px"
            class="image rounded-lg mr-2"
          />
          <span class="mr-4"
            ><v-icon
              v-if="userInfo.drivingLicenseImgFrontName"
              left
              small
              class="mb-1"
              :color="colors.colorPrimary"
            >
              mdi-camera-plus
            </v-icon>
            {{ userInfo.drivingLicenseImgFrontName }}</span
          >
        </div>
        <div class="flex flex-col">
          <v-img
            v-if="imageLinks['imgDrivingLicenseReverse']"
            :src="imageLinks['imgDrivingLicenseReverse']"
            :aspect-ratio="16 / 9"
            max-width="300px"
            class="image rounded-lg mr-2"
          />
          <span
            ><v-icon
              v-if="userInfo.drivingLicenseImgReverseName"
              left
              small
              class="mb-1"
              :color="colors.colorPrimary"
            >
              mdi-camera-plus </v-icon
            >{{ userInfo.drivingLicenseImgReverseName }}</span
          >
        </div>
      </div>
      <div
        class="flex flex-row w-full sm:mt-2"
        v-if="userInfo.expeditionLicenseB"
      >
        <p
          class="leading-4 font-medium text-lg mb-3"
          :style="{ color: colors.colorOnBackground, paddingTop: '5px' }"
        >
          {{ $t("mobile.register.licenseTypeB") }}
        </p>
      </div>
      <div class="flex sm:flex-row flex-col w-full">
        <span
          class="w-full mr-2 p-1 pl-2 rounded-lg border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
          }"
          v-if="userInfo.expeditionLicenseB"
          >{{
            DateUtil.beautifullFormatedDate(
              new Date(userInfo.expeditionLicenseB)
            )
          }}</span
        >
      </div>
      <div
        class="flex flex-row w-full sm:mt-2"
        v-if="userInfo.expeditionLicenseA"
      >
        <p
          class="leading-4 font-medium text-lg mb-3"
          :style="{ color: colors.colorOnBackground, paddingTop: '5px' }"
        >
          {{ $t("mobile.register.licenseTypeA") }}
        </p>
      </div>
      <div class="flex sm:flex-row flex-col w-full">
        <span
          class="sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
          }"
          v-if="userInfo.expeditionLicenseA"
          >{{
            DateUtil.beautifullFormatedDate(
              new Date(userInfo.expeditionLicenseA)
            )
          }}</span
        >
        <span
          class="sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
          }"
          v-if="userInfo.licenseTypeA"
          >{{ $t("common.type") + " " + userInfo.licenseTypeA }}</span
        >
      </div>
    </div>
    <span id="top-part-register"></span>
    <register-data
      v-if="showEdit"
      ref="registerData"
      :ownerName="ownerName"
      :ownerIsPremium="ownerIsPremium"
      :validate="validate"
      :nationalities="user.nationalities"
      :userInfo="userInfo"
      :openExtraData="openExtraData"
      @personalData="setPersonalData"
      @directionData="setDirectionData"
      @nifDrivingLicenseData="setNifDrivingLicenseData"
      @validationDirection="validateDirection"
      @validationNifDrivingLicenseData="validateNifDrivingLicenseData"
      @validation="validateData"
    />
    <div class="flex sm:flex-row flex-col w-full mt-2" v-if="showEdit">
      <span class="sm:w-48 sm:mr-2 w-full">
        <v-btn
          class="w-full lg:w-48 mb-4 mx-auto border-2 rounded-config30"
          :color="colors.colorPrimary"
          @click="validateFields"
        >
          <span class="" :style="{ color: colors.colorOnPrimary }">{{
            $t("confirmData")
          }}</span>
        </v-btn>
      </span>
      <span class="sm:w-48 w-full">
        <v-btn
          class="w-full lg:w-48 mb-4 mx-auto border-2 rounded-config30"
          outlined
          :color="colors.colorPrimary"
          @click="
            () => {
              showEdit = false;
            }
          "
          >{{ $t("common.cancel") }}
        </v-btn>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DateUtil from "@/flit2go-web-core/util/dateUtil";
import RegisterData from "@/components/register-data.vue";
import { sameAs } from "vuelidate/lib/validators";
import uploadImgS3 from "@/flit2go-web-core/util/uploadImgS3";
import { EventBus } from "@/flit2go-web-core/util/event-bus.js";

export default {
  name: "DriverSelectedData",
  components: {
    RegisterData,
  },
  data() {
    return {
      DateUtil,
      userInfo: null,
      validate: false,
      ownerName: "",
      ownerIsPremium: false,
      showEdit: false,
      userToCreate: {
        id: null,
        address: null,
        billAddress: null,
        name: "",
        surnames: "",
        country: null,
        phoneNumber: "",
        bornDate: "",
        language: "",
        username: "",
      },
      usertoCreateExtraInfo: {
        drivingPermissionValidityDate: null,
        licenseTypeA: false,
        expeditionLicenseA: null,
        expeditionLicenseB: null,
        typeLicenseDriving: null,
        drivingLicenseCar: false,
        nif: null,
        typeLicense: null,
        countryLicense: null,
        newDlImgFront: null,
        newDlImgReverse: null,
        newNifImgFront: null,
        newNifImgReverse: null,
        nifValidityDate: null,
      },
      canSendDocumentation: false,
      imagesToUpload: {
        nifImgFront: null,
        nifImgReverse: null,
        drivingLicenseImgFront: null,
        drivingLicenseImgReverse: null,
      },
      openExtraData: false,
      validatedRegister: false,
      validatedDirection: false,
      validatedNifDrivingLicenseData: false,
      userLogged: null,
      imageLinks: [],
    };
  },
  validations() {
    return {
      validatedDirection: {
        sameAs: sameAs(() => {
          return true;
        }),
      },
      validatedNifDrivingLicenseData: {
        sameAs: sameAs(() => {
          return true;
        }),
      },
      validatedRegister: {
        sameAs: sameAs(() => {
          return true;
        }),
      },
    };
  },
  created() {
    if (this.loginParameters) {
      this.getUserData(this.loginParameters);
      this.getOwner();
    }
  },
  watch: {
    validatedRegister() {
      if (this.validatedRegister) {
        this.updateUser();
      }
    },
    validatedDirection() {
      if (this.validatedDirection) {
        this.updateUser();
      }
    },
    validatedNifDrivingLicenseData() {
      if (this.validatedNifDrivingLicenseData) {
        this.updateUser();
      }
    },
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      colors: "configuration/getColors",
      loginParameters: "user/loginParameters",
    }),
    borderColor() {
      return `border-color: ${this.colors.primary}`;
    },
  },
  methods: {
    getUserData(loginParameters) {
      let params = {
        loginParameters: loginParameters,
      };
      this.$store
        .dispatch("user/getUserData", params)
        .then((resp) => {
          this.userInfo = Object.assign({}, resp.data);
          if (resp.data.address && resp.data.address.country) {
            const addressCountry = this.user.nationalities.filter(
              (country) =>
                this.$PageUtils.getObjectNameLower(country) ===
                resp.data.address.country
            )[0];
            this.userInfo.address.country = addressCountry;
          }
          this.userInfo.nifImgFrontName = this.getNameImage("imgDniFront");
          this.userInfo.nifImgReverseName = this.getNameImage("imgDniReverse");
          this.userInfo.drivingLicenseImgFrontName = this.getNameImage(
            "imgDrivingLicenseFront"
          );
          this.userInfo.drivingLicenseImgReverseName = this.getNameImage(
            "imgDrivingLicenseReverse"
          );
          this.validateDataCreated();
        })
        .catch(() => {
          this.$showMessage.error(this.$t("errors.generic"));
        });
    },
    getOwner() {
      this.$store
        .dispatch("user/getOwner")
        .then((resp) => {
          this.ownerName = resp.data.ownerName;
          this.ownerIsPremium = resp.data.isPremium;
        })
        .catch(() => {
          this.$showMessage.error(this.$t("errors.generic"));
        });
    },
    getNameImage(image) {
      let imageName = null;
      let arr = this.userInfo.validateImg[image]
        ? this.userInfo.validateImg[image].split("/")
        : null;
      imageName = arr ? arr[arr.length - 1] : null;
      if (imageName !== null) {
        this.getLinkImage(this.userInfo.validateImg[image], image);
        return imageName;
      } else {
        imageName = this.userInfo.preValidateImg[image]
          ? this.userInfo.preValidateImg[image]
          : null;
        return imageName;
      }
    },
    getLinkImage(imageName, name) {
      const data = {
        name: imageName,
        token: this.loginParameters.access_token,
      };
      this.$store.dispatch("user/getImageValidate", data).then((resp) => {
        this.imageLinks[name] = resp.data;
      });
    },
    validateFields() {
      this.validate = true;
      setTimeout(() => {
        this.validate = false;
      }, 200);
      this.canSendDocumentation = true;
      this.updateUser();
    },
    validateData(event) {
      this.validatedRegister = event.valid;

      if (!event.valid) {
        this.openExtraData = true;
        var register = document.getElementById("top-part-register");
        register.scrollIntoView({ behavior: "smooth" }, true);
      }
    },
    validateDirection(event) {
      this.validatedDirection = event.valid;
      if (!event.valid) {
        this.openExtraData = true;
        var register = document.getElementById("top-part-register");
        register.scrollIntoView({ behavior: "smooth" }, true);
      }
    },
    validateNifDrivingLicenseData(event) {
      this.validatedNifDrivingLicenseData = event.valid;
      if (!event.valid) {
        this.openExtraData = true;
        var register = document.getElementById("top-part-register");
        register.scrollIntoView({ behavior: "smooth" }, true);
      }
    },
    setPersonalData(data) {
      this.userToCreate.name = data.name;
      this.userToCreate.surnames = data.surnames;
      this.userToCreate.country = Object.assign({}, data.nationality);
      this.userToCreate.phoneNumber = data.phoneNumber;
      this.userToCreate.bornDate = data.bornDate;
      this.userToCreate.username = data.username;
      this.userToCreate.language = this.$PageUtils.getLanguageSplittedMinus();
    },
    setDirectionData(data) {
      this.userToCreate.address = {};
      this.userToCreate.address.direction = data.address;
      this.userToCreate.address.directionAdditionalInfo =
        data.additionalAddressInfo;
      this.userToCreate.address.postalCode = data.postalCode;
      this.userToCreate.address.city = data.province;
      this.userToCreate.address.locality = data.locality;
      this.userToCreate.address.country =
        data.country && data.country.name
          ? this.$PageUtils.getObjectNameLower(data.country)
          : null;
    },
    setNifDrivingLicenseData(data) {
      this.usertoCreateExtraInfo.country = data.drivingLicenseCountry
        ? data.drivingLicenseCountry
        : this.userToCreate.country
        ? this.userToCreate.country
        : null;
      this.usertoCreateExtraInfo.typeLicense = data.drivingLicense;
      this.usertoCreateExtraInfo.drivingLicenseCar = data.drivingLicenseCar;
      this.usertoCreateExtraInfo.expeditionLicenseB = this.getDate(
        data.drivingLicenseCarIssueDate
      );
      this.usertoCreateExtraInfo.countryLicense = data.drivingLicenseCountry;
      this.usertoCreateExtraInfo.newDlImgFront = data.drivingLicenseImgFront
        ? true
        : false;
      this.usertoCreateExtraInfo.newDlImgReverse = data.drivingLicenseImgReverse
        ? true
        : false;
      this.usertoCreateExtraInfo.licenseTypeA = data.licenseHighestRange;
      this.usertoCreateExtraInfo.expeditionLicenseA = this.getDate(
        data.drivingLicenseMotorcycleIssueDate
      );
      this.usertoCreateExtraInfo.drivingPermissionValidityDate = this.getDate(
        data.drivingLicenseValidityDate
      );
      this.usertoCreateExtraInfo.typeLicenseDriving =
        data.drivingLicense && data.drivingLicense.code !== "NO"
          ? data.drivingLicense.code
          : null;
      this.usertoCreateExtraInfo.nif = data.nif;
      this.usertoCreateExtraInfo.newNifImgFront = data.nifImgFront
        ? true
        : false;
      this.usertoCreateExtraInfo.newNifImgReverse = data.nifImgReverse
        ? true
        : false;
      this.usertoCreateExtraInfo.nifValidityDate = this.getDate(
        data.nifValidityDate
      );
      this.imagesToUpload.nifImgFront = data.nifImgFront;
      this.imagesToUpload.nifImgReverse = data.nifImgReverse;
      this.imagesToUpload.drivingLicenseImgFront = data.drivingLicenseImgFront;
      this.imagesToUpload.drivingLicenseImgReverse =
        data.drivingLicenseImgReverse;
    },
    updateUser() {
      this.errorShow = null;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.validatedRegister && this.validatedDirection) {
          if (
            this.userToCreate.country &&
            typeof this.userToCreate.country === "object" &&
            Object.keys(this.userToCreate.country).length === 0
          ) {
            this.userToCreate.country = null;
          }
          this.userToCreate.id = this.userInfo.id;
          if (
            this.userInfo.address &&
            this.userInfo.address.direction &&
            !this.userToCreate.address.direction
          ) {
            this.userToCreate.address = Object.assign(
              {},
              this.userInfo.address
            );
          }
          if (
            this.userToCreate.address &&
            typeof this.userToCreate.address.country === "object"
          ) {
            this.userToCreate.address.country = this.$PageUtils.getObjectNameLower(
              this.userToCreate.address.country
            );
          }
          let data = {
            user: this.userToCreate,
            token: this.loginParameters.access_token,
          };
          this.$store
            .dispatch("user/updateUser", data)
            .then((resp) => {
              this.userLogged = resp;
              if (this.canSendDocumentation) {
                this.updateNifInfo();
              } else {
                this.$emit("validation", {
                  valid: !this.$v.$invalid,
                });
                this.getUserData(this.loginParameters);
                this.showEdit = false;
              }
            })
            .catch((err) => {
              this.$showMessage.showError(err);
              this.getUserData(this.loginParameters);
              this.showEdit = false;
            });
        }
      }
    },
    updateNifInfo() {
      let data = {
        userInfo: this.usertoCreateExtraInfo,
        token: this.loginParameters.access_token,
        params: {
          isFrameweb: true,
        },
      };
      this.canSendDocumentation = false;
      this.$store
        .dispatch("user/updateInfoUser", data)
        .then((resp) => {
          if (
            this.imagesToUpload.nifImgFront ||
            this.imagesToUpload.nifImgReverse ||
            this.imagesToUpload.drivingLicenseImgFront ||
            this.imagesToUpload.drivingLicenseImgReverse
          ) {
            this.uploadImages(resp);
          } else {
            this.getUserData(this.loginParameters);
            this.showEdit = false;
          }
          this.$emit("validation", {
            valid: !this.$v.$invalid,
          });
        })
        .catch((resp) => {
          this.$showMessage.showError(resp);
          this.getUserData(this.loginParameters);
          this.showEdit = false;
        });
    },
    uploadImages(data) {
      const promises = [];
      if (data.imgDniFront) {
        promises.push(
          uploadImgS3(data.imgDniFront, this.imagesToUpload.nifImgFront)
        );
      }
      if (data.imgDniReverse) {
        promises.push(
          uploadImgS3(data.imgDniReverse, this.imagesToUpload.nifImgReverse)
        );
      }
      if (data.imgDrivingLicense) {
        promises.push(
          uploadImgS3(
            data.imgDrivingLicense,
            this.imagesToUpload.drivingLicenseImgFront
          )
        );
      }
      if (data.imgDrivingLicenseReverse) {
        promises.push(
          uploadImgS3(
            data.imgDrivingLicenseReverse,
            this.imagesToUpload.drivingLicenseImgReverse
          )
        );
      }
      Promise.allSettled(promises)
        .catch(this.$showMessage.showError)
        .finally(this.confirmUpdateUploadImages);
    },
    confirmUpdateUploadImages() {
      this.$store
        .dispatch("user/confirmUpdateUploadImages", {
          userId: this.userLogged.id,
          token: this.loginParameters.access_token,
        })
        .then(() => {
          this.getUserData(this.loginParameters);
          this.showEdit = false;
        })
        .catch((err) => {
          this.$showMessage.showError(err);
          this.getUserData(this.loginParameters);
          this.showEdit = false;
        });
    },
    getDate(element) {
      return element ? new Date(element).getTime() : null;
    },
    validateDataCreated() {
      this.validate = true;
      setTimeout(() => {
        this.validate = false;
      }, 100);
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showEdit = true;
      }
      this.$emit("validation", {
        valid: !this.$v.$invalid,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
