const DNR_TYPE = Object.freeze({
    "EMAIL": "EMA",
    "EXISTENT_USER": "EXU",
    "ID_NUMBER": "IDN",
    "TELEPHONE": "TEL"
  });
  
  export default Object.freeze({
      TYPE: DNR_TYPE
  });
  