<template>
  <v-stepper
    v-model="currentStep"
    class="rounded-none lg:rounded-md"
    :color="colors.colorPrimary"
    :class="{ 'my-2 mx-4': margin }"
    :style="{ backgroundColor: colors.colorBackground }"
    non-linear
  >
    <!-- Header -->
    <v-stepper-header
      class="rounded-t"
      :style="{ backgroundColor: colors.colorHeader }"
    >
      <template v-for="(step, index) in stepList">
        <v-stepper-step
          v-if="isLarge || index + 1 === currentStep"
          class="cursor-pointer"
          :class="{ 'flex justify-center': !isLarge }"
          :color="colors.colorOnHeader"
          :key="index"
          :step="index + 1"
          :complete="step.valid"
          :rules="[() => !step.visited || (step.visited && step.valid)]"
          error-icon="$vuetify.icons.cancel"
          @click="eventClickStep(step, index)"
        >
          <span
            v-if="isLarge || index + 1 === currentStep"
            :style="{ color: colors.onHeader }"
            >{{ step.label }}</span
          >
          <small v-if="step.optionalLabel">{{ $t(step.optionalLabel) }}</small>
          <span
            class="absolute left-4 top-6"
            v-if="!isLarge && currentStep != 1"
          >
            <F2gIcon
              :value="'arrow-left'"
              :height="22"
              :width="30"
              :strokeWidth="8"
              @click="eventBackStep()"
          /></span>
        </v-stepper-step>

        <v-divider
          :key="`divider${index}`"
          v-if="isLarge && index < stepList.length - 1"
        />
      </template>
    </v-stepper-header>

    <template v-for="(step, indexStep) in stepList">
      <v-stepper-content
        :key="indexStep"
        :step="indexStep + 1"
        class="min-h-90"
        color="#97d3c4"
      >
        <component
          v-if="step.component"
          :is="step.component"
          :data="step.data"
        />

        <slot v-else :name="`content${indexStep + 1}`">
          Slot with name: {{ `content${indexStep + 1}` }}
        </slot>
      </v-stepper-content>
    </template>
  </v-stepper>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "F2gFWStepper",
  props: {
    stepList: { required: true, type: Array, default: new Array() },
    margin: { required: false, type: Boolean, default: true },
    currentStep: { required: false, type: Number, default: 1 },
  },
  data: () => ({
    isLarge: window.innerWidth >= 1024 ? true : false,
  }),
  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
      categorySelected: "category/categorySelected",
    }),
  },
  watch: {},
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    myEventHandler(e) {
      if (window.innerWidth >= 1024) {
        this.isLarge = true;
      } else {
        this.isLarge = false;
      }
    },
    eventClickStep(step, index) {
      if (step.visited || this.currentStep > index) {
        this.$emit("currentStep", index + 1);
      }
    },
    eventBackStep() {
      this.$emit("currentStep", this.currentStep - 1);
    },
  },
};
</script>

<style lang="scss">
@media only screen and (max-width: 959px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: -webkit-box !important; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box !important; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox !important; /* TWEENER - IE 10 */
    display: -webkit-flex !important; /* NEW - Chrome */
    display: flex !important; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  }
  @media only screen and (max-width: 959px) {
    .v-stepper:not(.v-stepper--vertical) .v-stepper__step__step {
      margin-right: 12px !important;
    }
  }
}
.v-application .white {
  background-color: #ffffff !important;
  border-color: rgba(245, 146, 23, 0.5) !important;
  border-width: 2px;
}
.theme--light.v-stepper
  .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error)
  .v-stepper__step__step {
  background: rgba(255, 255, 255, 0.8) !important;
}
.theme--light.v-stepper .v-stepper__step__step {
  color: black !important;
}

.v-stepper__step__step {
  border-radius: 14px !important;
  width: 30px !important;
  height: 30px !important;
}
</style>
