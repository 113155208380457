<template>
  <div class="mainFilterCatalogue" id="top-part">
    <div class="lg:m-12 m-4" v-if="!showCategorySelected">
      <div class="flex flex-row justify-center align-center mb-4">
        <div
          class="rounded-full w-24 h-24 mr-2"
          v-if="configurationFilters.showLogo"
        >
          <v-layout justify-center>
            <v-img aspect-ratio="1" :src="logo" alt="Logo" class="rounded" />
          </v-layout>
        </div>
        <div class="" v-if="configurationFilters.showTitle">
          <h1
            class="text-2xl text-bold flex-wrap"
            :style="{ color: colors.colorOnHeader }"
          >
            {{
              labels.textTitle[language]
                ? labels.textTitle[language]
                : labels.textTitle.en
            }}
          </h1>
          <div :style="{ color: colors.colorOnHeader }">
            {{
              labels.textSubtitle[language]
                ? labels.textSubtitle[language]
                : labels.textSubtitle.en
            }}
          </div>
        </div>
      </div>
      <div class="lg:max-max-h-full md:mt-12">
        <div class="flex md:flex-row flex-col mt-4">
          <div class="flex flex-row w-full">
            <div
              class="w-2/5 md:w-1/3 md:mb-6 mb-2 mr-2"
              v-if="
                branchesFilter.length > 0 &&
                configurationFilters.showLocationFilter
              "
            >
              <v-autocomplete
                class="rounded-md"
                type="text"
                :label="$t('common.ubication')"
                v-model="branchesFilterSelecteds"
                :background-color="colors.colorSurfaceMedium"
                dense
                filled
                single-line
                hide-details
                :items="branchesFilter"
                item-text="name"
                item-value="id"
                multiple
              >
              </v-autocomplete>
            </div>
            <div
              class="w-2/5 md:w-1/3 md:mb-6 mb-2 mr-2"
              v-if="
                vehiclesForFilter.length > 0 &&
                configurationFilters.showVehicleFilter
              "
            >
              <v-autocomplete
                class="rounded-md"
                type="text"
                :label="$t('common.vehicleType')"
                v-model="filter.vehicleTypes"
                :background-color="colors.colorSurfaceMedium"
                dense
                filled
                single-line
                hide-details
                :items="vehiclesForFilter"
                item-text="name"
                item-value="code"
                multiple
              >
              </v-autocomplete>
            </div>
            <!--   <div
              class="w-2/5 md:w-1/5 mr-2"
              v-if="vehiclesForFilter.length > 0 && filters.showPriceFilter"
            >
              <v-autocomplete
                type="text"
                class="rounded-md"
                :label="$t('common.price')"
                v-model="filter.price"
                :background-color="colors.colorSurfaceMedium"
                filled
                single-line
                dense
                hide-details
                :items="prices"
                item-text="name"
                item-value="value"
              >
              </v-autocomplete>
            </div>-->
            <div
              class="w-2/5 md:w-1/5 mr-2"
              v-if="
                vehiclesForFilter.length > 0 &&
                configurationFilters.showAgeFilter
              "
            >
              <v-autocomplete
                type="text"
                class="rounded-md"
                :label="$t('common.minimumAge')"
                v-model="filter.minimumAge"
                :background-color="colors.colorSurfaceMedium"
                filled
                single-line
                dense
                hide-details
                :items="ages"
                item-text="name"
                item-value="value"
              >
              </v-autocomplete>
            </div>
            <div
              class="w-2/5 md:w-1/5 mr-2"
              v-if="configurationFilters.showCategoryFilter"
            >
              <v-text-field
                single-line
                filled
                :dense="true"
                autocomplete="off"
                hide-no-data
                flat
                :label="$t('searchCategory')"
                hide-details
                class="rounded input-size"
                type="text"
                v-model="categoryNameFilter"
                :color="colors.colorOnSurfaceMedium"
                :background-color="colors.colorSurfaceMedium"
              />
            </div>
          </div>
          <div
            v-if="filters.showOrdination"
            class="flex rounded-md flex align-center justify-end cursor-pointer w-full h-10 md:w-44"
            :style="{
              backgroundColor: colors.colorSurface,
            }"
          >
            <div class="flex flex-row w-full align-center">
              <v-autocomplete
                type="text"
                class="inline-block mb-1"
                :label="$t('common.orderBy') + ':'"
                v-model="filter.order"
                dense
                single-line
                reverse
                hide-details
                :items="orderBy"
                item-text="name"
                item-value="value"
                append-icon=""
              >
              </v-autocomplete>
              <F2gIcon
                stroke="black"
                class="cursor-pointer ml-2"
                :value="arrowValue"
                :strokeWidth="3"
                @click="filter.order ? (arrow = !arrow) : ''"
              />
            </div>
          </div>
        </div>
        <div v-if="categoriesFiltered.length">
          <div class="flex flex-row align-center">
            <div
              class="text-sm mt-2 mr-4"
              :style="{ color: colors.colorOnBackground }"
            >
              {{ categoriesFiltered.length }}
              {{ $t("categoriesFound") }}...
            </div>
            <div
              class="text-sm flex flex-row mt-2"
              :style="{ color: colors.colorOnBackground }"
            >
              <F2gIcon
                stroke="black"
                class="cursor-pointer ml-2 mr-2"
                :value="'info'"
                :width="20"
                :height="20"
                :strokeWidth="3"
              />
              {{ $t("approxPriceShowed") }}
            </div>
          </div>
          <div class="flex md:flex-row flex-col md:flex-wrap">
            <category-card-catalogue
              v-for="(category, index) in categoriesFiltered"
              :key="index"
              :categoryRow="category"
              @categorySelected="changeCategorySelected"
            />
          </div>
        </div>
        <div class="flex flex-wrap" v-if="showSkeleton">
          <div v-for="i in 12" :key="i" class="min-w-22">
            <v-skeleton-loader class="m-3" type="image"></v-skeleton-loader>
          </div>
        </div>
        <noData
          v-if="!categoriesFiltered.length && showNoData"
          :title="error ? error : $t('common.noData')"
        />
      </div>
    </div>
    <div v-if="showCategorySelected">
      <category-details-catalogue
        :categoryRow="categorySelected"
        :fullscreen="
          $vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'
        "
        :loading="loading"
        @close="
          () => {
            categorySelected = null;
            showCategorySelected = false;
          }
        "
        @selected="selectCategory"
        @changeCategory="changeCategorySelected"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import noData from "@/flit2go-web-core/components/ui/no-data.vue";
import CategoryCardCatalogue from "@/modules/catalogue/Components/category-card-catalogue";
import CategoryDetailsCatalogue from "@/modules/catalogue/Components/category-details-catalogue";
import { EventBus } from "@/flit2go-web-core/util/event-bus.js";
import BillingUtil from "@/flit2go-web-core/util/billingUtil.js";

export default {
  name: "main-filter-catalogue",
  components: {
    noData,
    CategoryCardCatalogue,
    CategoryDetailsCatalogue,
  },
  props: {},
  data() {
    return {
      filter: {
        minimumAge: null,
        vehicleTypes: [],
        price: null,
        order: null,
      },
      vehicles: [],
      prices: [],
      ages: [],
      orderBy: [],
      arrow: 1,
      categories: [],
      error: null,
      errorToShow: null,
      categorySelected: null,
      showCategorySelected: false,
      loading: false,
      reserveFilter: null,
      showNoData: false,
      showSkeleton: false,
      categoryNameFilter: "",
      branchesFilter: [],
      branchesFilterSelecteds: [],
    };
  },
  watch: {
    showCategorySelected() {
      window.parent.postMessage("manualResize", "*");
    },
    "configurationFilters.showLocationFilter"() {
      if (this.configurationFilters.showLocationFilter) {
        this.$store
          .dispatch("branchVehicle/getAvailableBranches", {
            type: this.$CONSTANTS.INTEGRATION.FRAME_WEB,
          })
          .then(
            (resp) =>
              (this.branchesFilter = resp.status === 200 ? resp.data : [])
          );
      }
    },
  },
  computed: {
    ...mapState(["category, branchVehicle, configuration"]),
    ...mapGetters({
      logo: "configuration/getLogo",
      colors: "configuration/getColors",
      labels: "configuration/getLabels",
      language: "configuration/getUserLanguage",
      vehiclesFilters: "configuration/getVehiclesFilter",
      configurationFilters: "configuration/getFilters",
      categoryIdUrl: "category/getCategoryIdUrl",
      attributesUrl: "category/getAttributesUrl",
      filters: "configuration/getFilters",
      ownerTimezone: "configuration/getOwnerTimezone",
      gtagCode: "configuration/getGtagCode",
    }),

    categoriesFiltered() {
      let categories = this.categories ? this.categories : [];
      if (this.filter.order && this.filter.order === "price") {
        categories = this.orderByPrice;
      }
      if (this.filter.order && this.filter.order === "name") {
        categories = this.orderByName;
      }
      /* if (this.filter.price) {
        categories = categories.filter(
          (category) =>
            category.totalPrice <= this.filter.price.max &&
            category.totalPrice >= this.filter.price.min
        );
      }*/
      if (this.filter.vehicleTypes.length) {
        let categoriesvehicles = [];
        this.filter.vehicleTypes.forEach((vehicle) => {
          categories.forEach((category) => {
            if (category.vehicleType.code === vehicle) {
              categoriesvehicles.push(category);
            }
          });
        });
        categories = categoriesvehicles;
      }
      if (this.filter.minimumAge) {
        categories = categories.filter(
          (category) => category.minimumAge >= this.filter.minimumAge
        );
      }
      if (
        this.configurationFilters.showCategoryFilter &&
        this.categoryNameFilter?.length > 0
      ) {
        categories = categories.filter(
          (category) =>
            category.name
              .toLowerCase()
              .indexOf(this.categoryNameFilter.toLowerCase()) > -1
        );
      }
      if (
        this.configurationFilters.showLocationFilter &&
        this.branchesFilterSelecteds.length > 0
      ) {
        categories = categories.filter(
          (category) =>
            category.branch?.length > 0 &&
            category.branch.some((branchId) =>
              this.branchesFilterSelecteds.includes(branchId)
            )
        );
      }

      return categories;
    },

    orderByPrice() {
      return this.categories
        .filter((category) => category.totalPrice >= 0)
        .sort((a, b) =>
          this.arrow ? a.totalPrice - b.totalPrice : b.totalPrice - a.totalPrice
        );
    },
    orderByName() {
      let categoriesSorted = this.categories
        .filter((category) => category.name != null)
        .sort((a, b) => {
          const name1 = a.name.toUpperCase();
          const name2 = b.name.toUpperCase();
          if (name1 > name2) {
            return 1;
          } else if (name1 < name2) {
            return -1;
          }
        });
      if (!this.arrow) {
        categoriesSorted.reverse();
      }
      return categoriesSorted;
    },
    vehiclesForFilter() {
      const vehiclesList = [];
      this.vehicles.forEach((vehicle) => {
        if (
          this.vehiclesFilters &&
          this.vehiclesFilters.includes(vehicle.code)
        ) {
          vehiclesList.push(vehicle);
        }
      });

      return vehiclesList;
    },
    arrowValue() {
      if (this.arrow) {
        return "arrow-up";
      } else {
        return "arrow-down";
      }
    },
  },
  created() {
    setTimeout(() => {
      this.fillFilters();
    }, 1000);
    this.searchCategories();
  },
  methods: {
    changeCategorySelected(category) {
      if (
        (!this.categorySelected || this.categorySelected.id !== category.id) &&
        this.gtagCode
      ) {
        this.$gtag.event("category_detail", {
          event_name: "category_detail",
        });
      }
      this.categorySelected = category;
      this.showCategorySelected = true;
      window.parent.postMessage("goToTop", "*");
    },
    selectCategory(reserveFilter) {
      this.reserveFilter = Object.assign({}, reserveFilter);
      this.loading = true;
      this.errorToShow = this.pickUpErrors();
      this.errorToShow = this.returnBackErrors();
      if (!this.errorToShow) {
        let mainFilter = {};
        mainFilter.pickUpBranch = reserveFilter.pickLocation.id;
        mainFilter.returnBackBranch = reserveFilter.otherBranch
          ? reserveFilter.returnLocation.id
          : reserveFilter.pickLocation.id;
        mainFilter.pickUpDate =
          this.$PageUtils.formatDateChangeTimeZoneTimestamp(
            `${reserveFilter.pickDate} ${reserveFilter.pickHour}`,
            this.ownerTimezone
          );
        mainFilter.returnBackDate =
          this.$PageUtils.formatDateChangeTimeZoneTimestamp(
            `${reserveFilter.returnDate} ${reserveFilter.returnHour}`,
            this.ownerTimezone
          );

        mainFilter.pickUpNameBranch = reserveFilter.pickLocation.name;
        mainFilter.returnBackNameBranch = reserveFilter.returnLocation.name;
        mainFilter.pickUpDirectionBranch =
          reserveFilter.pickLocation.positionStr;
        mainFilter.returnBackDirectionBranch =
          reserveFilter.returnLocation.positionStr;
        mainFilter.pickUpLatitude = reserveFilter.pickLocation.latitude;
        mainFilter.pickUpLongitude = reserveFilter.pickLocation.longitude;
        mainFilter.returnBackLatitude = reserveFilter.returnLocation.latitude;
        mainFilter.returnBackLongitude = reserveFilter.returnLocation.longitude;
        mainFilter.pickUpLogo = reserveFilter.pickLocation.owner
          ? reserveFilter.pickLocation.owner.logo
          : null;
        mainFilter.returnLogo = reserveFilter.pickLocation.owner
          ? reserveFilter.returnLocation?.owner?.logo
          : null;
        mainFilter.vehicleTypes = this.vehiclesForFilter;
        this.$store.commit("configuration/SET_BOOKING_FILTERS", mainFilter);
        this.getPriceCategorySelected(mainFilter);
      } else {
        this.loading = false;
        EventBus.$emit("errorFilters", this.errorToShow);
      }
    },

    getPriceCategorySelected(mainFilter) {
      let data = {};
      data.categoryId = this.categorySelected.id;
      data.data = {
        pickUpDate: mainFilter.pickUpDate,
        returnBackDate: mainFilter.returnBackDate,
        pickUpBranch: mainFilter.pickUpBranch,
        returnBackBranch: mainFilter.returnBackBranch,
        franchise: -1,
        extra: [],
        additionServices: [],
        earlyPay: this.categorySelected.earlyPay,
        promotion: false,
        userId: null,
      };

      if (data.categoryId) {
        this.$store
          .dispatch("configuration/getPrice", data)
          .then(() => {
            this.goToReservation();
          })
          .catch((err) => {
            EventBus.$emit("goStep1");
            this.loading = false;
            this.$showMessage.showError(err);
          });
      }
    },

    goToReservation() {
      EventBus.$emit("getPrice");
      this.$router.push({
        name: "ReservationCatalogue",
        query: this.$route.query,
      });
    },

    pickUpErrors() {
      let errorFilter = false;
      if (
        !this.reserveFilter.pickDate ||
        !this.reserveFilter.pickHour ||
        !this.reserveFilter.pickLocation
      ) {
        errorFilter = this.$t("errors.completeAllFields");
      }
      if (this.reserveFilter.pickDate && this.reserveFilter.returnDate) {
        let pickDateTimestamp = new Date(this.reserveFilter.pickDate);
        let returnDateTimestamp = new Date(this.reserveFilter.returnDate);
        if (pickDateTimestamp > returnDateTimestamp)
          errorFilter = this.$t("errors.dateLess");
      }
      if (
        this.reserveFilter.pickDate &&
        this.reserveFilter.returnDate &&
        this.reserveFilter.pickHour &&
        this.reserveFilter.returnHour
      ) {
        let pickDateTimeTimestamp = new Date(
          this.reserveFilter.pickDate + "T" + this.reserveFilter.pickHour
        );
        if (this.reserveFilter.pickDate === this.reserveFilter.returnDate) {
          let returnDateTimestamp = new Date(
            this.reserveFilter.returnDate + "T" + this.reserveFilter.returnHour
          );
          if (
            pickDateTimeTimestamp.getTime() >= returnDateTimestamp.getTime()
          ) {
            errorFilter = this.$t("errors.hourLess");
          }
        }
        if (pickDateTimeTimestamp < Date.now() - 3600000) {
          errorFilter = this.$t("errors.hourMoreActual");
        }
      }
      return errorFilter;
    },

    returnBackErrors() {
      let errorFilter = false;
      if (
        !this.reserveFilter.returnDate ||
        !this.reserveFilter.returnHour ||
        !this.reserveFilter.returnLocation
      ) {
        errorFilter = this.$t("errors.completeAllFields");
      }
      if (this.reserveFilter.pickDate && this.reserveFilter.returnDate) {
        let pickDateTimestamp = new Date(this.reserveFilter.pickDate);
        let returnDateTimestamp = new Date(this.reserveFilter.returnDate);
        if (pickDateTimestamp > returnDateTimestamp)
          errorFilter = this.$t("errors.dateMore");
      }
      if (
        this.reserveFilter.pickDate &&
        this.reserveFilter.returnDate &&
        this.reserveFilter.pickHour &&
        this.reserveFilter.returnHour
      ) {
        if (this.reserveFilter.pickDate === this.reserveFilter.returnDate) {
          let pickDateTimeTimestamp = new Date(
            this.reserveFilter.pickDate + " " + this.reserveFilter.pickHour
          );
          let returnDateTimestamp = new Date(
            this.reserveFilter.returnDate + " " + this.reserveFilter.returnHour
          );
          if (
            pickDateTimeTimestamp.getTime() >= returnDateTimestamp.getTime()
          ) {
            errorFilter = this.$t("errors.hourMore");
          }
        }
      }
      return errorFilter;
    },

    searchCategories() {
      this.showNoData = false;
      this.showSkeleton = true;
      this.mainFilter = {};
      this.mainFilter.vehicleTypes = this.filter.vehicleTypes;
      // this.mainFilter.price = this.filter.price;
      let updatedAttributesArray = [];

      if (this.attributesUrl) {
        const attributesArray = Object.values(this.attributesUrl);
        updatedAttributesArray = attributesArray.map((attribute) => {
          return attribute.replace(/-/g, " ");
        });
      }

      const headers = {
        nameAttributes: updatedAttributesArray.join(", "),
      };

      this.$store
        .dispatch("category/getAvailableCategoriesCatalogue", headers)
        .then((resp) => {
          this.showNoData = true;
          this.showSkeleton = false;
          this.categories = resp.data;
          if (this.categoryIdUrl) {
            this.categories.forEach((category) => {
              if (category.id == this.categoryIdUrl) {
                if (!category.notAvailable) {
                  this.changeCategorySelected(category);
                }
              }
            });
          }
        })
        .catch((err) => {
          this.showNoData = true;
          this.showSkeleton = false;
          this.error = err.response.data.error
            ? err.response.data.error
            : err.response.data;
        });
    },
    fillFilters() {
      this.vehicles = [
        { code: "CAR", name: this.$t("vehicleTypes.CAR") },
        { code: "BIC", name: this.$t("vehicleTypes.BIC") },
        { code: "MOT", name: this.$t("vehicleTypes.MOT") },
        { code: "MOP", name: this.$t("vehicleTypes.MOP") },
        { code: "VAN", name: this.$t("vehicleTypes.VAN") },
        { code: "ES", name: this.$t("vehicleTypes.ES") },
        { code: "BOA", name: this.$t("vehicleTypes.BOA") },
      ];
      this.ages = [
        { name: "18", color: "#F9A8D4" },
        { name: "20", color: "#C4B5FD" },
        { name: "21", color: "#A5B4FC" },
        { name: "23", color: "#93C5FD" },
        { name: "25", color: "#6EE7B7" },
      ];
      this.prices = [
        { name: this.$t("common.allPrices"), value: { min: 0, max: 10000000 } },
        {
          name:
            BillingUtil.formatBillingValue(
              0,
              this.configurationFilters.currency
            ) +
            BillingUtil.formatBillingValue(
              -25,
              this.configurationFilters.currency
            ),
          value: { min: 0, max: 25 },
        },
        {
          name:
            BillingUtil.formatBillingValue(
              25,
              this.configurationFilters.currency
            ) +
            BillingUtil.formatBillingValue(
              -50,
              this.configurationFilters.currency
            ),
          value: { min: 25, max: 50 },
        },
        {
          name:
            BillingUtil.formatBillingValue(
              50,
              this.configurationFilters.currency
            ) +
            BillingUtil.formatBillingValue(
              -100,
              this.configurationFilters.currency
            ),
          value: { min: 50, max: 100 },
        },
        {
          name:
            BillingUtil.formatBillingValue(
              100,
              this.configurationFilters.currency
            ) +
            BillingUtil.formatBillingValue(
              -250,
              this.configurationFilters.currency
            ),
          value: { min: 100, max: 250 },
        },
        {
          name:
            BillingUtil.formatBillingValue(
              250,
              this.configurationFilters.currency
            ) +
            BillingUtil.formatBillingValue(
              -500,
              this.configurationFilters.currency
            ),
          value: { min: 250, max: 500 },
        },
        {
          name:
            "+" +
            BillingUtil.formatBillingValue(
              500,
              this.configurationFilters.currency
            ),
          value: { min: 500, max: 10000000 },
        },
      ];
      this.orderBy = [
        { name: this.$t("common.price"), value: "price" },
        { name: this.$t("common.name"), value: "name" },
      ];
    },
  },
};
</script>
