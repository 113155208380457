import conn from "./connection";
import dashboardModule from "./dashboardService";
import authModule from "./authService";
import settingsModule from "./settingsService";
import profileModule from "./profileService";
import notificationsModule from "./notificationService";
import promotionModule from "./promotionService";
import paymentModule from "./paymentService";

export default {
  dashboard: dashboardModule,
  auth: authModule,
  notification: notificationsModule,
  settings: settingsModule,
  setToken: conn.setToken,
  profile: profileModule,
  promotion: promotionModule,
  payment: paymentModule,
};
