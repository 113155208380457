const PROMOTION_STATUS = Object.freeze({
  ACTIVE: "ACT",
  INACTIVE: "ICT",
  EXPIRED: "EXP",
});

const PROMOTION_PERIOD = Object.freeze({
  WEEK: "WEEK",
  MONTH: "MONTH",
  YEAR: "YEAR",
});

const PROMOTION_TYPE = Object.freeze({
  MINUTES: "MIN",
  HOURS: "HOU",
  AMOUNT: "AMO",
  DISCOUNT: "DIS",
  MONTH: "MON",
});

export default Object.freeze({
  STATUS: PROMOTION_STATUS,
  PERIOD: PROMOTION_PERIOD,
  TYPE: PROMOTION_TYPE,
});
