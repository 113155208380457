<template>
  <div class="category-card-catalogue m-4">
    <div
      class="w-80 max-h-full flex flex-1 md:align-left relative rounded-3xl border-black shadow hover:shadow-xl showBorder"
      :class="{
        'cursor-pointer':
          !categoryRow.notAvailable && !categoryRow.requestVehicle,
      }"
      :style="{
        outlineColor: colors.colorPrimary + '!important',
      }"
      @click="
        categoryRow.notAvailable && !categoryRow.requestVehicle
          ? ''
          : $emit('categorySelected', categoryRow)
      "
    >
      <div class="flex flex-col">
        <div
          class="w-full h-12 rounded-t-3xl p-1 flex align-center"
          :style="{ backgroundColor: colors.colorSurfaceMedium }"
        >
          <div class="w-full max-h-full flex align-center">
            <h1
              class="w-4/6 text-sm md:text-base font-bold ml-2 mt-1"
              :style="{
                color: colors.colorOnSurface,
                lineHeight: '1.25 !important',
              }"
            >
              {{ categoryRow.name }}
            </h1>
          </div>
          <div
            class="absolute right-0 top-0 h-16 z-5 rounded-tr-3xl rounded-bl-3xl py-3 px-3 flex justify-center align-center"
            :style="{
              backgroundColor: colors.colorPrimary,
              color: colors.colorOnPrimary,
            }"
          >
            <div class="flex flex-col align-end">
              <div class="text-lg">
                {{
                  BillingUtil.formatBillingValue(
                    returnPrice(),
                    categoryRow.symbol
                  )
                }}
              </div>
              <div class="text-xs">
                {{ $t("approx") + "*" }} / {{ $t("common.day") }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="flex flex-col">
            <v-flex class="inline-block">
              <v-chip
                v-if="categoryRow.requestVehicle"
                class="ma-2 absolute z-5 text-xs"
                color="warning"
              >
                {{ $t("requestTheVehicle") }}
              </v-chip>
              <v-chip
                v-else-if="categoryRow.notAvailable"
                class="ma-2 absolute z-5 text-xs"
                color="error"
              >
                {{ $t("temporarilyNoStock") }}
              </v-chip>
              <v-img
                v-if="categoryRow.img"
                :src="categoryRow.img"
                :aspect-ratio="16 / 9"
                class="image"
                :style="[
                  !categoryRow.attributeList.length
                    ? {
                        'border-bottom-left-radius': '30px',
                        'border-bottom-right-radius': '30px',
                      }
                    : '',
                ]"
                :class="{
                  'h-52': categoryRow.attributeList.length,
                  'h-64': !categoryRow.attributeList.length,
                }"
              />
            </v-flex>
            <div class="w-full rounded-b-xl">
              <div
                class="w-full pl-2 flex flex-row align-center overflow-x-hidden category_services rounded-b-xl"
                :style="{
                  backgroundColor: colors.colorSurfaceMedium,
                }"
              >
                <div
                  v-for="(attribute, index) in categoryRow.attributeList"
                  :key="index"
                  class="max-w-1/3"
                >
                  <div
                    v-if="index < 3"
                    class="inline-block my-1 text-sm align-middle mr-2 flex flex-row"
                  >
                    <div
                      class="text-sm align-middle mr-2 flex flex-row truncate"
                    >
                      <div class="pa-1 m-1 inline-block align-middle">
                        <svg
                          style="height: 24px; width: 24px"
                          :fill="colors.colorPrimary"
                        >
                          <path
                            v-if="attribute.icon.path"
                            :d="attribute.icon.path"
                          />
                        </svg>
                      </div>
                      <div
                        class="flex align-center inline-block align-middle"
                        :style="{ color: colors.colorOnSurface }"
                      >
                        {{
                          attribute.supportedLangs[
                            $PageUtils.getLanguageSplittedMinus($i18n.locale)
                          ]
                            ? attribute.supportedLangs[
                                $PageUtils.getLanguageSplittedMinus(
                                  $i18n.locale
                                )
                              ]
                            : attribute.supportedLangs.en
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BillingUtil from "@/flit2go-web-core/util/billingUtil.js";

export default {
  name: "CategoryCardCatalogue",
  components: {},
  props: {
    categoryRow: { required: true, type: Object, default: () => [] },
  },
  data() {
    return {
      BillingUtil,
      loading: true,
    };
  },
  computed: {
    ...mapState(["category", "configuration"]),
    ...mapGetters({
      colors: "configuration/getColors",
      bookingFilters: "configuration/getBookingFilters",
    }),
    images() {
      return [this.categoryRow.img].concat(this.categoryRow.image);
    },
  },
  methods: {
    getPricePerDay() {
      if (this.categoryRow.deferredPayment) {
        return this.categoryRow.pricePerTimeDeferred;
      } else {
        return this.categoryRow.pricePerTime
          ? this.categoryRow.pricePerTime.toFixed(2)
          : 0;
      }
    },
    returnPrice() {
      if (
        this.categoryRow.deferredPayment &&
        this.categoryRow.deferredDiscount
      ) {
        return this.categoryRow.totalPriceDeferredDiscount;
      } else {
        return this.categoryRow.totalPrice ? this.categoryRow.totalPrice : "0";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.showBorder:hover {
  outline: 8px solid;
  // border-radius: 2rem;
  //   border-width: 6px;
  border-radius: 32px !important;
}
</style>
