var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reservation flex flex-row lg:px-4 lg:pt-4",class:_vm.showLogoAndVersion ? 'relative' : 'fixed w-full max-h-full',attrs:{"id":"top-part"}},[_c('div',{staticClass:"w-full lg:w-9/12 mb-16"},[_c('F2gFWCustomStepper',{ref:"stepper",attrs:{"stepList":_vm.stepList,"currentStep":_vm.currentStep,"loading":_vm.loading,"servicesValidation":_vm.servicesValidation,"showNextStep":_vm.showNextStep,"selectedcurrentStep":"eventCurrentStep"},on:{"currentStep":_vm.eventCurrentStep,"showExtras":function($event){_vm.showExtras = true},"search":_vm.setQuery},scopedSlots:_vm._u([{key:"content1",fn:function(){return [(!_vm.showCategorySelected)?_c('div',{staticClass:"lg:max-max-h-full px-4"},[_c('div',{staticClass:"flex md:flex-row flex-col mt-4"},[_c('div',{staticClass:"flex flex-row w-full"},[(
                  _vm.vehiclesForFilter.length > 0 && _vm.filters.showVehicleFilter
                )?_c('div',{staticClass:"w-3/5 md:w-1/3 md:mb-6 mb-2 mr-2"},[_c('v-autocomplete',{staticClass:"rounded-md",attrs:{"type":"text","background-color":_vm.colors.colorSurfaceMedium,"dense":"","filled":"","single-line":"","hide-details":"","items":_vm.vehiclesForFilter,"item-text":"name","item-value":"code","multiple":""},on:{"change":_vm.searchCategories},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_c('span',{style:(`color:${_vm.colors.colorOnSurfaceMedium} !important`)},[_vm._v(_vm._s(item.name)+" ")])]}},{key:"label",fn:function(){return [_c('span',{style:(`color:${_vm.colors.colorOnSurfaceMedium} !important`)},[_vm._v(_vm._s(_vm.$t("common.vehicleType")))])]},proxy:true}],null,false,2353753141),model:{value:(_vm.filter.vehicleTypes),callback:function ($$v) {_vm.$set(_vm.filter, "vehicleTypes", $$v)},expression:"filter.vehicleTypes"}})],1):_vm._e()]),(_vm.filters.showOrdination)?_c('div',{staticClass:"flex rounded-md flex align-center justify-end cursor-pointer w-full h-10 md:w-44",style:({
                backgroundColor: _vm.colors.colorSurface,
              })},[_c('div',{staticClass:"flex flex-row w-full align-center"},[_c('v-autocomplete',{staticClass:"inline-block mb-1",attrs:{"type":"text","dense":"","single-line":"","reverse":"","hide-details":"","items":_vm.orderBy,"item-text":"name","item-value":"value","append-icon":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{style:(`color:${_vm.colors.colorOnSurfaceMedium} !important`)},[_vm._v(_vm._s(`${_vm.$t("common.orderBy")} :`))])]},proxy:true}],null,false,2604158434),model:{value:(_vm.filter.order),callback:function ($$v) {_vm.$set(_vm.filter, "order", $$v)},expression:"filter.order"}}),_c('F2gIcon',{staticClass:"cursor-pointer ml-2",attrs:{"stroke":_vm.colors.colorOnSurfaceMedium,"value":_vm.arrowValue,"strokeWidth":3},on:{"click":function($event){_vm.filter.order ? (_vm.arrow = !_vm.arrow) : ''}}})],1)]):_vm._e()]),(_vm.categoriesFiltered.length)?_c('div',[_c('div',{staticClass:"text-sm mt-4",style:({ color: _vm.colors.colorOnBackground })},[_vm._v(" "+_vm._s(_vm.categoriesFiltered.length)+" "+_vm._s(_vm.$t("categoriesFound"))+"... ")]),_vm._l((_vm.categoriesFiltered),function(category,index){return _c('category-cardV2',{key:index,attrs:{"isDetail":false,"categoryRow":category},on:{"categorySelected":_vm.changeCategorySelected,"selected":_vm.selectCategory}})})],2):_c('noData',{attrs:{"title":_vm.$t('common.noData')}})],1):_vm._e(),(_vm.showCategorySelected)?_c('div',{staticClass:"lg:max-max-h-full px-4 w-full rounded-lg mt-2 mb-1",style:({
            backgroundColor: _vm.colors.colorSurface,
            color: _vm.colors.colorOnSurface,
          })},[(
              _vm.$vuetify.breakpoint.name !== 'xs' &&
                _vm.$vuetify.breakpoint.name !== 'sm'
            )?_c('category-details',{attrs:{"categoryRow":_vm.categorySelected,"fullscreen":_vm.$vuetify.breakpoint.name === 'xs' ||
                _vm.$vuetify.breakpoint.name === 'sm'},on:{"close":() => {
                _vm.categorySelected = null;
                _vm.showCategorySelected = false;
              },"selected":_vm.selectCategory}}):_c('category-cardV2',{attrs:{"isDetail":true,"categoryRow":_vm.categorySelected},on:{"categorySelected":_vm.changeCategorySelected,"selected":_vm.selectCategory,"close":() => {
                _vm.categorySelected = null;
                _vm.$store.commit('category/SET_PACK', null);
                _vm.showCategorySelected = false;
              }}})],1):_vm._e()]},proxy:true},{key:"content2",fn:function(){return [_c('div',{staticClass:"lg:max-max-h-full px-4"},[_c('extras-list',{ref:"extrasList",attrs:{"showExtras":_vm.showExtras},on:{"validation":_vm.validateExtras,"servicesValidation":(validation) => (_vm.servicesValidation = validation)}}),_c('div',{staticClass:"mt-12 mx-2"},[_c('data-card',{ref:"dataCard",on:{"type":_vm.setTypeUser,"validation":_vm.loginValidation,"nextStep":function($event){return _vm.eventCurrentStep(3)},"showButtonNextStep":_vm.showUserNextStep,"hideButtonNextStep":() => {
                  _vm.showNextStep = false;
                }}})],1),(_vm.showNextStep)?_c('div',{staticClass:"w-full flex px-2 justify-center mt-2"},[_c('v-btn',{staticClass:"my-2 w-full border-2 rounded-config30",style:({
                backgroundColor: _vm.colors.colorPrimary,
                color: _vm.colors.colorOnPrimary,
              }),attrs:{"disabled":!_vm.servicesValidation},on:{"click":function($event){return _vm.eventCurrentStep(3)}}},[_vm._v(" "+_vm._s(_vm.returnLabelButton())+" ")])],1):_vm._e()],1)]},proxy:true},{key:"content3",fn:function(){return [_c('div',{staticClass:"lg:max-max-h-full min-h-2/3 px-4"},[_c('confirm-data',{ref:"confirmData",on:{"validateConfirm":_vm.validateConfirm}}),_c('div',{staticClass:"w-full flex px-2 justify-center"},[_c('v-btn',{staticClass:"my-2 w-full border-2 rounded-config30",style:({
                backgroundColor: _vm.colors.colorPrimary,
                color: _vm.colors.colorOnPrimary,
              }),attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.eventCurrentStep(4)}}},[_vm._v(" "+_vm._s(_vm.returnLabelButton())+" ")])],1)],1)]},proxy:true},{key:"content4",fn:function(){return [_c('div',{staticClass:"lg:max-h-full min-h-3/4 px-4"},[_c('div',{staticClass:"min-h-2/3"},[_c('pay-card')],1)])]},proxy:true}])})],1),(
      !(
        _vm.$vuetify.breakpoint.name === 'xs' || _vm.$vuetify.breakpoint.name === 'sm'
      )
    )?_c('div',{staticClass:"top-6 w-3/12 px-0 right-2 max-h-full"},[_c('Resume',{attrs:{"currentStep":_vm.currentStep,"loading":_vm.loading,"categoryDetail":_vm.showCategorySelected,"currentCategory":_vm.categorySelected,"servicesValidation":_vm.servicesValidation,"extrasValidation":_vm.extrasValidation,"showNextStep":_vm.showNextStep},on:{"showExtras":function($event){_vm.showExtras = true},"currentStep":(value) => {
          _vm.hideDialogExtras = true;
          _vm.eventCurrentStep(value);
        },"selected":_vm.selectCategory}})],1):_vm._e(),(_vm.showLogoAndVersion)?_c('div',{staticClass:"flex bottom-0 align-end absolute",class:_vm.$vuetify.breakpoint.name === 'xs' || _vm.$vuetify.breakpoint.name === 'sm'
        ? 'w-full'
        : 'width73',style:({ backgroundColor: _vm.colors.colorBackground })},[_c('div',{staticClass:"version mt-4 ml-6"},[_vm._v("v."+_vm._s(_vm.version))]),_c('div',{staticClass:"version mt-4 ml-1"},[_vm._v(" developed by "),_c('a',{style:({ color: _vm.colors.colorPrimary }),attrs:{"href":"https://flit2go.com/","target":"_blank"}},[_vm._v("Flit2go technology")])])]):_vm._e(),_c('v-dialog',{class:'rounded-t-lg',style:(`backgroundColor:${_vm.colors.colorSurface} !important`),attrs:{"transition":"dialog-bottom-transition","value":_vm.showModalExtras,"maxWidth":800}},[[_c('v-card',{staticClass:"max-h-full bg-white backdrop-blur-xl overflow-y-auto rounded-lg",style:(`backgroundColor:${_vm.colors.colorSurface} !important`)},[_c('v-card-title',{staticClass:"text-h5 mb-2",style:({
            color: _vm.colors.colorOnSurface,
          }),attrs:{"id":"dialog-extras"}},[_c('F2gIcon',{staticClass:"mr-2",attrs:{"value":'warning',"height":30,"width":30,"stroke":_vm.colors.colorOnSurface,"strokeWidth":3}}),_vm._v(" "+_vm._s(_vm.$t("reviewExtras"))+" ")],1),_c('v-card-text',[_c('div',{staticClass:"absolute flex flex-row justify-end align-end mt-2 mr-2 top-0 right-0 z-50"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showModalExtras = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"flex flex-row align-center",style:({
              color: _vm.colors.colorOnSurface,
            })},[_c('v-layout',{staticClass:"mb-4",attrs:{"column":""}},[_vm._v(" "+_vm._s(_vm.$t("reviewExtrasQuestion"))+" ")])],1),_c('div',{staticClass:"flex align-end justify-end",class:{
              'flex-col': _vm.$vuetify.breakpoint.name === 'xs',
            }},[_c('v-btn',{staticClass:"rounded",class:{
                'mb-2': _vm.$vuetify.breakpoint.name === 'xs',
              },attrs:{"outlined":"","color":_vm.colors.colorPrimary},on:{"click":function($event){_vm.hideDialogExtras = true;
                _vm.eventCurrentStep(3);}}},[_vm._v(_vm._s(_vm.$t("dontNeedAnything")))]),_c('v-btn',{staticClass:"rounded",class:{
                'ml-2': _vm.$vuetify.breakpoint.name !== 'xs',
              },style:('color: ' + _vm.colors.colorOnPrimary),attrs:{"color":_vm.colors.colorPrimary},on:{"click":function($event){_vm.showModalExtras = false;
                _vm.showExtras = true;}}},[_vm._v(_vm._s(_vm.$t("showExtras")))])],1)])],1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }