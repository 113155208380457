import conn from "./connection"

export default {
    changePassword(data) {
        return conn.$axios({
            url: `secured/v1/person/password`,
            method: 'POST',
            data: data
        })
    }
}