const VEHICLE_TYPE = Object.freeze({
  CAR: "CAR",
  VAN: "VAN",
  SCOOTER: "E-S",
  BICYCLE: "BIC",
  MOPED: "MOP",
  MOTORCYCLE: "MOT",
  BOAT: "BOA",
});

const VEHICLE_STATUS = Object.freeze({
  DISABLED: "DIS",
  ENABLED: "ENA",
  PENDING_SYNC: "PSY",
  PENDING_PRICING: "PRI",
  PENDING_ZONE: "PZO",
  PENDING_CATEGORY: "PEC",
  RESERVED: "RES",
  USING: "USO",
  WORKSHOP: "WSH",
  UNSUBSCRIBED: "UNS",
});

const VEHICLES_WITH_DISPLACEMENT = [
  Object.freeze({ code: VEHICLE_TYPE.CAR }),
  Object.freeze({ code: VEHICLE_TYPE.VAN }),
  Object.freeze({ code: VEHICLE_TYPE.MOTORCYCLE }),
];

const VEHICLE_RESERVE_TYPE = Object.freeze({
  FLEET_CONTROL: "FLC",
  WITHOUT_RESERVATION: "FRE",
  SCHEDULED_BOOKING: "USC",
  SUBSCRIPTION: "SUB",
  OPERATION: "OPE",
});

const VEHICLE_CLEANING = Object.freeze({
  DIRTY: "DIR",
  NORMAL: "NOR",
  CLEANING: "CLE",
});

const VEHICLE_PETROL = Object.freeze({
  COMPRESSED_NATURAL_GAS: "CNG",
  DIESEL: "DIE",
  ELECTRIC: "ELE",
  "95_GASOLINE": "GAS",
  HYBRID: "HYB",
  LIQUID_PETROLEUM_GAS: "LPG",
  WITHOUT_PETROL: "WPT",
});

const VEHICLE_TRANSMISSION = Object.freeze({
  AUTOMATIC: "AUTO",
  CONTINUOSLY_VARIABLE_TRANSMISSION_: "CVT",
  DUAL_CLUTCH: "DCT",
  MANUAL: "MANU",
  TRIPTONIC: "TRIP",
});

const PRICING_TYPES = Object.freeze({
  HOUR: "HOU",
  MINUTE: "MIN",
  MONTH: "MON",
});

export default Object.freeze({
  TYPE: VEHICLE_TYPE,
  STATUS: VEHICLE_STATUS,
  VEHICLES_WITH_DISPLACEMENT: VEHICLES_WITH_DISPLACEMENT,
  RESERVE_TYPE: VEHICLE_RESERVE_TYPE,
  CLEANING_STATUS: VEHICLE_CLEANING,
  PETROL: VEHICLE_PETROL,
  TRANSMISSION: VEHICLE_TRANSMISSION,
  PRICING_TYPE: PRICING_TYPES,
});
