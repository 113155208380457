<template>
  <div class="extras-selected-list w-full">
    <div class="flex flex-col flex-wrap my-4">
      <h2
        class="leading-4 font-medium text-lg mb-2"
        :style="{ color: colors.colorOnBackground }"
      >
        {{ $t("common.vehicleCharacteristics") }}
      </h2>
      <!-- CategoryAttributes -->

      <div class="text-sm">
        <div class="w-full flex flex-row align-center flex-wrap">
          <div
            class="inline-block mr-2 mt-4"
            v-for="(attribute, index) in categorySelected.attributeList"
            :key="index"
          >
            <div
              class="text-sm align-middle mr-2 inline-block rounded-md flex-none"
              :style="{
                backgroundColor: colors.colorSurfaceMedium,
                color: colors.colorPrimary,
              }"
            >
              <div class="pa-1 m-1 inline-block align-middle">
                <svg
                  style="height: 24px; width: 24px"
                  :fill="colors.colorPrimary"
                >
                  <path v-if="attribute.icon.path" :d="attribute.icon.path" />
                </svg>
              </div>
              <div
                class="align-center inline-block align-middle mr-3"
                :style="{ color: colors.colorOnSurfaceMedium }"
              >
                {{
                  attribute.supportedLangs[
                    $PageUtils.getLanguageSplittedMinus($i18n.locale)
                  ]
                    ? attribute.supportedLangs[
                        $PageUtils.getLanguageSplittedMinus($i18n.locale)
                      ]
                    : attribute.supportedLangs.en
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Kms and petrol -->
    <div class="mt-6">
      <div class="align-middle">
        <h2
          class="leading-4 font-medium text-lg inline-block align-middle"
          :style="{ color: colors.colorOnBackground }"
        >
          {{ $t("common.kilometersAndPetrol") }}
        </h2>
      </div>
    </div>
    <div class="w-full flex flex-row align-center flex-wrap">
      <div class="inline-block mr-2">
        <div
          class="text-sm align-middle mr-2 inline-block rounded-md flex-none mt-4 border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
            color: colors.colorOnSurface,
          }"
        >
          <div class="pa-1 m-1 inline-block align-middle">
            <F2gIcon
              class="mx-1"
              :value="'counter'"
              :stroke="colors.colorPrimary"
              :height="16"
              :width="25"
              :strokeWidth="4"
            />
          </div>
          <div
            class="align-center inline-block align-middle mr-3 py-2"
            :style="{ color: colors.colorOnSurface }"
          >
            {{
              categorySelected.kmBase === -1
                ? $t("unlimitedKm")
                : categorySelected.kmBase === 0
                ? $t("mileageNotIncluded")
                : `${categorySelected.kmBase} ${$t(
                    "common.includedKilometers"
                  )}`
            }}

            <div
              v-if="categorySelected.kmPriceExtra"
              class="text-xs align-center"
              :style="{ color: colors.colorOnSurface }"
            >
              *{{ $t("common.extraKmCost") }}:
              {{
                BillingUtil.formatBillingValue(
                  categorySelected.kmPriceExtra,
                  categorySelected.symbol
                )
              }}/km
            </div>
          </div>
        </div>
        <div
          v-if="
            categorySelected.vehicleType.code !==
              $CONSTANTS.VEHICLE.TYPE.SCOOTER
          "
          class="text-sm align-middle mr-2 inline-block rounded-md flex-none mt-4 border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
            color: colors.colorOnSurface,
          }"
        >
          <div class="pa-1 m-1 inline-block align-middle">
            <F2gIcon
              class="mx-1"
              :value="'gas-station'"
              :stroke="colors.colorPrimary"
              :height="16"
              :width="25"
              :strokeWidth="4"
            />
          </div>
          <div
            class="align-center inline-block align-middle mr-3 py-2"
            :style="{ color: colors.colorOnSurface }"
          >
            {{ $t("common.fullFull") }}
            <div
              v-if="categorySelected.consumptionPenalization"
              class="-left-10 text-xs align-center"
              :style="{ color: colors.colorOnSurface }"
            >
              *{{ $t("common.extraFuelDifference") }}:
              {{
                BillingUtil.formatBillingValue(
                  categorySelected.consumptionPenalization,
                  categorySelected.symbol
                )
              }}/l
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex flex-col flex-wrap mb-2 mt-6"
      v-if="
        franchiseSelected &&
          (franchiseSelected.franchiseType ||
            franchiseSelected.franchiseType === 0)
      "
    >
      <h2
        class="leading-4 font-medium text-lg pt-1 mb-4"
        :style="{ color: colors.colorOnBackground }"
      >
        {{ $t("common.franchise") }}
      </h2>
      <div class="w-full flex flex-row align-center flex-wrap">
        <div
          class="text-sm align-middle mr-2 rounded-md border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
            color: colors.colorOnSurface,
          }"
        >
          <div class="pa-1 m-1 inline-block align-middle">
            <F2gIcon
              class="inline-block align-middle"
              :value="'check'"
              :stroke="colors.colorPrimary"
              :height="16"
              :width="25"
              :strokeWidth="4"
            />
          </div>
          <div
            class="align-center inline-block align-middle mr-3 py-2"
            :style="{ color: colors.colorOnSurface }"
          >
            {{ franchiseSelected.message }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex flex-col flex-wrap my-4"
      v-if="
        Object.keys(extrasSelected).length != 0 ||
          Object.keys(additionalsSelected).length != 0
      "
    >
      <p
        class="leading-4 font-medium text-lg pt-1 mb-0"
        :style="{ color: colors.colorOnSurface }"
      >
        {{ $t("extraEquipmentAndOptionalServices") }}
      </p>
      <div class="mt-4">
        <category-additionals-extras
          v-if="Object.keys(extrasSelected).length != 0"
          :itemsList="Object.values(extrasSelected)"
          :selected="true"
        />
        <category-additionals-extras
          v-if="Object.keys(additionalsSelected).length != 0"
          :itemsList="Object.values(additionalsSelected)"
          :selected="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CategoryAdditionalsExtras from "@/components/category-additionals-extras.vue";
import BillingUtil from "@/flit2go-web-core/util/billingUtil.js";

export default {
  name: "ExtrasSelectedList",
  components: {
    CategoryAdditionalsExtras,
  },
  data() {
    return {
      BillingUtil,
    };
  },
  computed: {
    ...mapGetters({
      categorySelected: "category/categorySelected",
      extrasSelected: "configuration/getExtrasSelected",
      additionalsSelected: "configuration/getAdditionalsSelected",
      franchiseSelected: "category/franchiseSelected",
      colors: "configuration/getColors",
    }),
    borderColor() {
      return `border-color: ${this.colors.primary}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-container {
  height: 35px;
  width: 35px;
  border-radius: 100%;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 250ms ease-in;
}
.icon-container--selected {
  background-color: $success-f2g;
}

.icon-container--not-selected {
  background-color: $f2g-light-gray-2;
}
</style>
