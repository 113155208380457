import service from "@/flit2go-web-core/services/imagesService";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    status: "",
    ownerImages: {},
  },
  mutations: {
    ["STATUS_SUCCESS"]: (state) => {
      state.status = "success";
    },
    ["STATUS_LOADING"]: (state) => {
      state.status = "loading";
    },
    ["STATUS_ERROR"]: (state) => {
      state.status = "error";
    },
    ["ADD_OWNER_IMAGES"]: (state, images) => {
      Object.entries(images).forEach(([key, value]) => {
        //make changes reactive
        Vue.set(state.ownerImages, key, value);
      });
    },
    ["DELETE_OWNER_IMAGE"]: (state, ownerId) => {
      if (
        state.ownerImages &&
        Object.prototype.hasOwnProperty.call(state.ownerImages, ownerId)
      ) {
        delete state.ownerImages[ownerId];
      }
    },
  },
  actions: {
    getOwnerImages({ commit }, ownerIds) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        let ownerImages = {};
        let promises = [];
        ownerIds.forEach((id) => {
          ownerImages[id] = "";
          promises.push(
            service.getOwnerImage(id).then((resp) => {
              ownerImages[id] = resp.data;
            })
          );
          Promise.all(promises)
            .then(() => {
              commit("ADD_OWNER_IMAGES", ownerImages);
              commit("STATUS_SUCCESS");
              resolve();
            })
            .catch((err) => {
              commit("STATUS_ERROR");
              reject(err);
            });
        });
      });
    },

    // getImageByUrl({commit}, imgUrl){
    //     return new Promise((resolve, reject) => {
    //         service.getImageByUrl(imgUrl)
    //             .then(resp => {
    //                 resolve(resp)
    //             })
    //             .catch((err) =>
    //                 reject(err))
    //     })
    // }
  },
};
