import moment from "moment-timezone";
import authStore from "@/flit2go-web-core/store/authModule";
import i18n from "@/flit2go-web-core/lang/";
import BillingUtil from "@/flit2go-web-core/util/billingUtil.js";

function i18nLower() {
  return i18n.locale.split("_")[0];
}

function i18nUpper() {
  return i18n.locale.split("_")[1];
}

function getVuetifyColors() {
  return [
    "amber",
    "red",
    "light-blue",
    "purple",
    "green",
    "teal",
    "brown",
    "lime",
    "deep-orange",
    "blue-grey",
    "indigo",
    "pink",
    "amber darken-1",
    "red darken-1",
    "light-blue darken-1",
    "purple darken-1",
    "green darken-1",
    "teal darken-1",
    "brown darken-1",
    "lime darken-1",
    "deep-orange darken-1",
    "blue-grey darken-1",
    "indigo darken-1",
    "pink darken-1",
    "amber lighten-1",
    "red lighten-1",
    "light-blue lighten-1",
    "purple lighten-1",
    "green lighten-1",
    "teal lighten-1",
    "brown lighten-1",
    "lime lighten-1",
    "deep-orange lighten-1",
    "blue-grey lighten-1",
    "indigo lighten-1",
    "pink lighten-1",
    "amber darken-2",
    "red darken-2",
    "light-blue darken-2",
    "purple darken-2",
    "green darken-2",
    "teal darken-2",
    "brown darken-2",
    "lime darken-2",
    "deep-orange darken-2",
    "blue-grey darken-2",
    "indigo darken-2",
    "pink darken-2",
    "amber lighten-2",
    "red lighten-2",
    "light-blue lighten-2",
    "purple lighten-2",
    "green lighten-2",
    "teal lighten-2",
    "brown lighten-2",
    "lime lighten-2",
    "deep-orange lighten-2",
    "blue-grey lighten-2",
    "indigo lighten-2",
    "pink lighten-2",
    "amber darken-3",
    "red darken-3",
    "light-blue darken-3",
    "purple darken-3",
    "green darken-3",
    "teal darken-3",
    "brown darken-3",
    "lime darken-3",
    "deep-orange darken-3",
    "blue-grey darken-3",
    "indigo darken-3",
    "pink darken-3",
    "amber lighten-3",
    "red lighten-3",
    "light-blue lighten-3",
    "purple lighten-3",
    "green lighten-3",
    "teal lighten-3",
    "brown lighten-3",
    "lime lighten-3",
    "deep-orange lighten-3",
    "blue-grey lighten-3",
    "indigo lighten-3",
    "pink lighten-3",
    "amber darken-4",
    "red darken-4",
    "light-blue darken-4",
    "purple darken-4",
    "green darken-4",
    "teal darken-4",
    "brown darken-4",
    "lime darken-4",
    "deep-orange darken-4",
    "blue-grey darken-4",
    "indigo darken-4",
    "pink darken-4",
    "amber lighten-4",
    "red lighten-4",
    "light-blue lighten-4",
    "purple lighten-4",
    "green lighten-4",
    "teal lighten-4",
    "brown lighten-4",
    "lime lighten-4",
    "deep-orange lighten-4",
    "blue-grey lighten-4",
    "indigo lighten-4",
    "pink lighten-4",
    "amber darken-5",
    "red darken-5",
    "light-blue darken-5",
    "purple darken-5",
    "green darken-5",
    "teal darken-5",
    "brown darken-5",
    "lime darken-5",
    "deep-orange darken-5",
    "blue-grey darken-5",
    "indigo darken-5",
    "pink darken-5",
    "amber lighten-5",
    "red lighten-5",
    "light-blue lighten-5",
    "purple lighten-5",
    "green lighten-5",
    "teal lighten-5",
    "brown lighten-5",
    "lime lighten-5",
    "deep-orange lighten-5",
    "blue-grey lighten-5",
    "indigo lighten-5",
    "pink lighten-5",
  ];
}

function getHtmlColors() {
  return [
    "#BA55D3",
    "#CD5C5C",
    "#B8860B",
    "#FFA07A",
    "#F08080",
    "#FF6347",
    "#9400D3",
    "#B0C4DE",
    "#7CFC00",
    "#FA8072",
    "#800080",
    "#E9967A",
    "#ADD8E6",
    "#9932CC",
    "#2F4F4F",
    "#FFA07A",
    "#DA70D6",
    "#4682B4",
    "#DC143C",
    "#FF69B4",
    "#FF7F50",
    "#FF0000",
    "#DAA520",
    "#DDA0DD",
    "#483D8B",
    "#F4A460",
    "#B22222",
    "#EEE8AA",
    "#B0E0E6",
    "#8B0000",
    "#FFC0CB",
    "#7B68EE",
    "#FFFFE0",
    "#FF1493",
    "#FF4500",
    "#C71585",
    "#FFD700",
    "#FFE4B5",
    "#FFDAB9",
    "#8A2BE2",
    "#7FFF00",
    "#ADFF2F",
    "#3CB371",
    "#FF00FF",
    "#00FF00",
    "#D8BFD8",
    "#BDB76B",
    "#FF8C00",
    "#FFA500",
    "#FFFF00",
    "#F0E68C",
    "#E6E6FA",
    "#EE82EE",
    "#FF00FF",
    "#9370DB",
    "#8B008B",
    "#4B0082",
    "#6A5ACD",
    "#98FB98",
    "#2E8B57",
    "#008000",
    "#FFFAFA",
    "#32CD32",
    "#87CEFA",
    "#D3D3D3",
    "#A52A2A",
    "#48D1CC",
    "#228B22",
    "#663399",
    "#BC8F8F",
    "#808000",
    "#FAFAD2",
    "#DB7093",
    "#00FA9A",
    "#D2691E",
    "#FFDEAD",
    "#778899",
    "#AFEEEE",
    "#8B4513",
    "#66CDAA",
    "#7FFFD4",
    "#40E0D0",
    "#00FF7F",
    "#00FFFF",
    "#CD853F",
    "#006400",
    "#6B8E23",
    "#556B2F",
    "#F0FFFF",
    "#8FBC8B",
    "#6495ED",
    "#000080",
    "#A0522D",
    "#90EE90",
    "#FFB6C1",
    "#87CEEB",
    "#F8F8FF",
    "#0000CD",
    "#00BFFF",
    "#F5F5F5",
    "#808080",
    "#F5DEB3",
    "#FFF5EE",
    "#800000",
    "#F5F5DC",
    "#4169E1",
    "#9ACD32",
    "#5F9EA0",
    "#696969",
    "#DEB887",
    "#FFF8DC",
    "#FDF5E6",
    "#C0C0C0",
    "#FFE4C4",
    "#7B68EE",
    "#FFFAF0",
    "#20B2AA",
    "#0000FF",
    "#191970",
    "#E0FFFF",
    "#FFFFF0",
    "#000000",
    "#FAEBD7",
    "#00CED1",
    "#00FFFF",
    "#DCDCDC",
    "#D2B48C",
    "#FAF0E6",
    "#008080",
    "#A9A9A9",
    "#00008B",
    "#FFF0F5",
    "#FFEFD5",
    "#1E90FF",
    "#708090",
    "#FFE4E1",
    "#008B8B",
  ];
}

function compareStrings(a, b) {
  if (a.name[i18nLower()] < b.name[i18nLower()]) {
    return -1;
  } else if (a.name[i18nLower()] > b.name[i18nLower()]) {
    return 1;
  } else {
    return 0;
  }
}

export default {
  /**
   * Evalúa una cláusula RSQL y la añade a la query.
   *
   * @param {*} query La query actual
   * @param {*} param El nombre del parámetro en la entity java
   * @param {*} value El valor por el que se quiere filtrar.
   * @param {*} clause La cláusula RSQL (==, gt, ge, lt, le, etc.)
   * @param {*} isLikeable Si la cláusula admite el parámetro LIKE
   */
  evaluateRSQLclause(query, param, value, clause, isLikeable) {
    let like = "";
    let spaceTxt = "";
    let qStr = "";

    if (isLikeable) {
      like = "*";
      spaceTxt = "'";
    }
    if (null != value && "" != value) {
      if ("" != query) {
        qStr += ";";
      }
      if (Array.isArray(value)) {
        value.forEach((item) => {
          qStr += `${param}${clause}${spaceTxt}${like}${item}${like}${spaceTxt}`;
          if (item != value[value.length - 1]) {
            qStr += ",";
          }
        });
        if (value.length > 1) {
          if (qStr.includes(";")) {
            qStr = ";(" + qStr.substr(1, qStr.length) + ")";
          } else {
            qStr = "(" + qStr + ")";
          }
        }
      } else {
        if (typeof value === "string" && !isLikeable) {
          value = `'${value}'`;
        }
        qStr += `${param}${clause}${spaceTxt}${like}${value}${like}${spaceTxt}`;
      }
    }

    return qStr;
  },

  /**
   * Evalúa una cláusula RSQL y la añade a la query.
   *
   * @param {*} query La query actual
   * @param {*} param El nombre del parámetro en la entity java
   * @param {*} value El valor por el que se quiere filtrar.
   * @param {*} clause La cláusula RSQL (:, >:, >:, <, <:, ~)
   * @param {*} isLikeable Si la cláusula admite el parámetro LIKE
   */
  evaluateNewRSQLclause(query, param, value, clause, isLikeable) {
    let like = "";
    let spaceTxt = "'";
    let qStr = "";

    if (isLikeable) {
      like = "%";
      spaceTxt = "'";
    }
    if (
      null !== value &&
      "" !== value &&
      (value.length > 0 || typeof value === "number")
    ) {
      if (Array.isArray(param)) {
        param.forEach((item) => {
          qStr += `${item}${clause}${spaceTxt}${like}${value}${like}${spaceTxt}`;
          if (item !== param[param.length - 1]) {
            qStr += " or ";
          }
        });
        if ("" !== query) {
          qStr = " and (" + qStr + ")";
        } else {
          qStr = "(" + qStr + ")";
        }
      } else if (Array.isArray(value)) {
        value.forEach((item) => {
          qStr += `${param}${clause}${spaceTxt}${like}${item}${like}${spaceTxt}`;
          if (item !== value[value.length - 1]) {
            qStr += " or ";
          }
        });
        if ("" !== query) {
          qStr = " and (" + qStr + ")";
        } else {
          qStr = "(" + qStr + ")";
        }
      } else {
        if ("" != query) {
          qStr += " and ";
        }
        qStr += `${param}${clause}${spaceTxt}${like}${value}${like}${spaceTxt}`;
      }
    }
    return qStr;
  },

  // Muestra el número de elementos mostrados en la página del total.
  elementsShown(pagination, label) {
    let elEnd = pagination.rowsPerPage * pagination.page;
    let elStart = elEnd - pagination.rowsPerPage + 1;
    let totalItems = 0;

    if (elEnd >= pagination.totalItems) {
      elEnd = pagination.totalItems;
    }

    if (pagination.totalItems !== undefined) {
      totalItems = pagination.totalItems;
    }

    if (0 == totalItems) {
      elEnd = 0;
      elStart = 0;
    }

    return `${elStart}-${elEnd} ${label} ${totalItems}`;
  },

  // Time elapsed between two dates
  timeElapsed(start, end) {
    let str = "";

    if (start != null && end != null) {
      // get total seconds between the times
      let delta = Math.abs(end - start) / 1000;
      // calculate (and subtract) whole days
      let days = Math.floor(delta / 86400);
      delta -= days * 86400;
      // calculate (and subtract) whole hours
      let hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;
      // calculate (and subtract) whole minutes
      let minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;
      // what's left is seconds
      let seconds = delta % 60;

      str = days > 0 ? days + "d " : "";
      str += hours > 0 ? hours + "h " : "";
      str += minutes > 0 ? minutes + "m " : "";
      str += seconds > 0 ? seconds + "s " : "";
    }

    return str;
  },
  dateFormat(
    value,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid"
  ) {
    let dateString = "-";
    if (null != value && "" != value && "0" != value) {
      dateString = moment(value).tz(timeZone).format("DD/MM/YYYY HH:mm:ss");
    }
    return dateString;
  },

  dateFormatWithoutSeconds(
    value,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid"
  ) {
    let dateString = "-";
    if (null != value && "" != value && "0" != value) {
      dateString = moment(value).tz(timeZone).format("DD/MM/YYYY HH:mm");
    }
    return dateString;
  },

  dateFormatWithoutTimezone(value, format = "DD/MM/YYYY HH:mm:ss") {
    let dateString = "-";
    if (null != value && "" != value && "0" != value) {
      dateString = moment(value).utc().format(format);
    }
    return dateString;
  },

  getTodayDate() {
    return moment(new Date()).format("YYYY-MM-DD HH:mm");
  },

  todayDateForMaxDate() {
    return moment(new Date()).format("YYYY-MM-DD");
  },

  getYesterdayDate() {
    return moment(new Date()).subtract(1, "days").format("YYYY-MM-DD");
  },
  getTomorrowDate() {
    return moment(new Date()).add(1, "days").format("YYYY-MM-DD");
  },
  getTime() {
    return moment(new Date()).format("HH:mm");
  },

  dateFormatNotTime(value) {
    let dateString = "-";
    if (null != value && "" != value && "0" != value) {
      dateString = moment(value).format("DD/MM/YYYY");
    }
    return dateString;
  },

  dateFormatNotTimeDash(value) {
    let dateString = "-";
    if (null != value && "" != value && "0" != value) {
      dateString = moment(value).format("DD-MM-YYYY");
    }
    return dateString;
  },

  dateFormatInt(
    value,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid"
  ) {
    let dateString = "-";
    if (null != value && "" != value && "0" != value) {
      dateString = moment(value).tz(timeZone).format("YYYY/MM/DD HH:mm:ss");
    }
    return dateString;
  },

  dateFormatIntHourMinuts(
    value,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid"
  ) {
    let dateString = "-";
    if (null != value && "" != value && "0" != value) {
      dateString = moment(value).tz(timeZone).format("DD/MM/YYYY HH:mm");
    }
    return dateString;
  },

  dateFormatNotTimeInt(
    value,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid"
  ) {
    let dateString = "-";
    if (null != value && "" != value && "0" != value) {
      dateString = moment(value).tz(timeZone).format("YYYY/MM/DD");
    }
    return dateString;
  },

  dateFormatForForms(
    value,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid"
  ) {
    let dateString = "-";
    if (null != value && "" != value && "0" != value) {
      dateString = moment(value).tz(timeZone).format("YYYY-MM-DD");
    }
    return dateString;
  },

  formatDateToTimestamp(
    value,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid"
  ) {
    let dateString = "-";
    if (null != value && "" != value && "0" != value) {
      dateString = moment(value).tz(timeZone).format("x");
    }
    return dateString;
  },
  formatDateToTimestampUTC(
    value,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid"
  ) {
    let dateString = "-";
    if (null != value && "" != value && "0" != value) {
      dateString = moment(value).utc(true).tz(timeZone).format("x");
    }
    return dateString;
  },
  formatDateChangeTimeZoneTimestamp(
    value,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid"
  ) {
    let dateString = "-";
    if (null != value && "" != value && "0" != value) {
      dateString = moment(value).tz(timeZone, true).format("x");
    }
    return dateString;
  },

  formatLocale(date) {
    return moment(date).locale(i18n.locale.substring(0, 2));
  },

  getMonthName(startDate, month) {
    return moment(startDate)
      .locale(i18n.locale.substring(0, 2))
      .add(month - 1, "months")
      .format("MMMM")
      .toUpperCase();
  },
  getDayName(startDate) {
    return moment(startDate, "YYYY-MM-DD HH:mm:ss").format("dddd");
  },
  getDayNameEnglish(startDate) {
    return moment(startDate, "YYYY-MM-DD HH:mm:ss").locale("en").format("dddd");
  },
  calcStartOfMonth(startDate, month) {
    return moment(startDate)
      .add(month - 1, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
  },

  calcEndOfMonth(startDate, month) {
    return moment(startDate)
      .add(month - 1, "months")
      .endOf("month")
      .format("YYYY-MM-DD");
  },

  // Formatea en la lista el valor de la dirección
  // addressFormat(value) {
  //   let addStr = "";
  //   if (null != value && value.length != 0) {
  //     addStr = `${value.street}, ${value.number}, ${value.postalCode} ${value.locality}, ${value.city}, ${value.country}`;
  //   }
  //   return addStr;
  // },
  addressFormat(value) {
    let addStr = "";
    if (null != value && value.length != 0) {
      addStr = `${value.direction}${value.direction ? "," : ""} ${
        value.directionAdditionalInfo ? value.directionAdditionalInfo : ""
      }${value.directionAdditionalInfo ? "," : ""} ${value.postalCode} ${
        value.locality
      }, ${value.city}, ${value.country}`;
    }
    return addStr;
  },

  getStartDateOfMonth() {
    // timeZone = authStore.state.userProfile.ownerPerson
    //   ? authStore.state.userProfile.ownerPerson.timeZone
    //   : "Europe/Madrid"
    let timeZone = "Europe/Madrid";
    return moment().startOf("month").tz(timeZone).format("YYYY-MM-DD HH:mm:ss");
  },

  getFinishDateOfMonth(date) {
    let timeZone = "Europe/Madrid";
    return moment(date)
      .endOf("month")
      .tz(timeZone)
      .format("YYYY-MM-DD HH:mm:ss");
  },

  getStartDateOfDay(
    date,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid",
    typeFormat = "-"
  ) {
    return moment(date)
      .startOf("day")
      .tz(timeZone)
      .format(`YYYY${typeFormat}MM${typeFormat}DD`);
  },

  getFinishDate(
    startDate,
    months,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid"
  ) {
    return moment(startDate)
      .add(months - 1, "months")
      .endOf("month")
      .tz(timeZone)
      .format("YYYY-MM-DD HH:mm:ss");
  },

  getStartDateToLoad(startDate, timeZone) {
    return moment(startDate)
      .subtract(2, "months")
      .startOf("day")
      .tz(timeZone)
      .format("YYYY-MM-DD HH:mm:ss");
  },

  getFinishDateToLoad(
    startDateToLoad,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid"
  ) {
    return moment(startDateToLoad)
      .add(2, "months")
      .endOf("month")
      .tz(timeZone)
      .format("YYYY-MM-DD HH:mm:ss");
  },

  getYearFromDate(date) {
    return moment(date).format("YYYY");
  },

  // Formatea en la lista el valor de la dirección
  googleAddressFormat(value) {
    let addStr = "";
    if (null != value && value.length != 0) {
      let obj = JSON.parse(value);
      if (obj.road) {
        addStr = `${obj.road}, `;
      }

      addStr += `${obj.postcode}, `;

      if (obj.village) {
        addStr += `${obj.village}, `;
      } else if (obj.town) {
        addStr += `${obj.town}, `;
      }

      if (obj.city) {
        addStr += `${obj.city}, `;
      }

      addStr += `${obj.country}`;
    }

    return addStr;
  },
  personFormat(value) {
    let str = "";
    if (null != value && value.length != 0) {
      str = `${value.name} ${value.surnames}`;
    }

    return str;
  },
  /**
   * Obtiene un color distinto según los valores de un array. Ejemplo de uso: Primera inicial de los modelos en la lista de devices.
   * Si el array de entrada contiene el valor introducido, devuelve el mismo color de ese elemento en el momento en el que se pintó,
   * si no, devuelve un nuevo color e introduce el valor en el array para una próxima validación.
   * @param {*} arr Array con los valores ya evaluados.
   * @param {*} val Valor a evaluar.
   * @param {*} vuetify Si utiliza los colores de vuetify.
   */
  getCircleColour(arr, val, vuetify = true) {
    const colourArray = vuetify ? getVuetifyColors() : getHtmlColors();
    const size = arr.length;
    let pos = 0;

    if (arr.includes(val)) {
      pos = arr.indexOf(val) + 1;
    } else {
      pos = size;
      arr.push(val);
    }

    let pos1 =
      pos - colourArray.length * Math.trunc(pos / colourArray.length) - 1;

    if (pos1 <= 0) {
      let value;
      if (val && val.length > 1) {
        value = val.charCodeAt(0) * val.charCodeAt(1);
      } else {
        value = val;
      }
      pos1 = Math.trunc(value / colourArray.length);
    }

    return colourArray[pos1];
  },

  getCircleColourWithWords(arr, firstWord, secondWord) {
    const val = `${firstWord.charCodeAt(0)}${secondWord.charCodeAt(0)}`;
    this.getCircleColour(arr, val);
  },

  getAcronym(name) {
    let aux = name.split(" ");
    if (aux.length > 1) {
      return (
        aux[0].substring(0, 1).toUpperCase() +
        aux[1].substring(0, 1).toUpperCase()
      );
    } else {
      return aux[0].substring(0, 2).toUpperCase();
    }
  },

  formatIBAN(strValue) {
    let objRegExp =
      /^([A-Z]{2}[-]?[0-9]{2})(?=(?:[-]?[A-Z0-9]){9,30}$)((?:[-]?[A-Z0-9]{3,5}){2,7})([-]?[A-Z0-9]{1,3})?$/;

    strValue = strValue.replace(objRegExp, "\\$&");

    return strValue;
  },

  formatUserCreatedUpdated(person) {
    let nameFormatted = null;
    if (
      undefined !== person &&
      null !== person &&
      person.hasOwnProperty("id") &&
      null !== person.id &&
      person.id.toString().length > 0
    ) {
      if (!authStore.state.userProfile.flit && person.flit) {
        nameFormatted = "Flit2go";
      } else {
        nameFormatted = `${person.name} ${person.surnames}`;
      }
    }
    return nameFormatted;
  },

  getLanguageSplitted(locale) {
    if (!locale.includes("_")) {
      locale = `${locale}_${locale.toUpperCase()}`;
    }
    return locale.split("_");
  },

  /**
   * Recibe una búsqueda, unos campos y unos parámetros
   * y construye la rsql para filtrar
   *
   * @param {*} query El inicio de la query preparada desde el componente
   * @param {*} headers Los headers de la tabla por las que se esté filtrando
   * @param {*} queryData Los datos adicionales que la query deba llevar, como si poner paréntesis o es dealer o manager premium
   * @param {*} newQuery Si la query es la version 1.1 o la 1.2
   */

  formatRsqlQueryFilter(
    query,
    headers,
    searchInput,
    queryData,
    newQuery = false
  ) {
    //refactorizar
    let counter = 0;
    let fieldNumber = headers.filter((item) => item.field).length;
    let queryEquivalenceSymbol = null;
    let queryContainSymbol = null;
    let orSymbol = null;

    if (newQuery) {
      queryEquivalenceSymbol = ":";
      queryContainSymbol = "~";
      orSymbol = " OR";
    } else {
      queryEquivalenceSymbol = "==";
      queryContainSymbol = "==";
      orSymbol = ",";
    }
    if (
      queryData &&
      (queryData.noParenthesis == false || queryData.noParenthesis == null)
    ) {
      query += "(";
    }

    headers.forEach((element) => {
      if (element.additionalFields) {
        fieldNumber += element.additionalFields.length;
        element.additionalFields.forEach((item) => {
          if (newQuery) {
            query += `${item}${queryContainSymbol}'%${searchInput}%'`;
            counter++;
          } else {
            query += `${item}${queryContainSymbol}'*${searchInput}*'`;
            counter++;
          }
          if (counter < fieldNumber) {
            query += `${orSymbol} `;
          }
        });
      }

      if (element.field) {
        let addOrSymbol = true;
        if (element.isInteger) {
          counter++;
          if (Number(searchInput)) {
            query += `${element.field}${queryEquivalenceSymbol}'${searchInput}'`;
          } else {
            addOrSymbol = false;
            if (counter >= fieldNumber && query && query.length > 2) {
              if (query.charAt(query.length - 2) === ",") {
                query = query.slice(0, query.length - 2);
              } else if (
                query.charAt(query.length - 3) === "O" &&
                query.charAt(query.length - 2) === "R"
              ) {
                query = query.slice(0, query.length - 3);
              }
            }
          }
        } else if (newQuery) {
          if (element.exists) {
            query += "exists(";
          }
          query += `${element.field}${queryContainSymbol}'%${searchInput}%'`;
          if (element.exists) {
            query += ")";
          }
          counter++;
        } else {
          query += `${element.field}${queryContainSymbol}'*${searchInput}*'`;
          counter++;
        }

        if (addOrSymbol && counter < fieldNumber) {
          query += `${orSymbol} `;
        }
      }
    });
    if (queryData) {
      if (queryData.noParenthesis == false || queryData.noParenthesis == null) {
        query += ")";
      }
      if (newQuery) {
        if (queryData.isDealer && queryData.isPremium) {
          query += "AND dealer:true" + orSymbol + "premium:true";
        } else if (queryData.isDealer) {
          query += "AND dealer:true";
        } else if (queryData.isPremium) {
          query += "AND premium:true";
        }
      } else {
        if (queryData.isDealer && queryData.isPremium) {
          query += ";dealer==true" + orSymbol + "premium==true";
        } else if (queryData.isDealer) {
          query += ";dealer==true";
        } else if (queryData.isPremium) {
          query += ";premium==true";
        }
      }
    }
    return query;
  },

  subtract(val1, val2) {
    return BillingUtil.formatNumber(val1 - val2);
  },

  getLanguageSplittedMinus() {
    return i18nLower();
  },

  getLanguageSplittedUpper() {
    return i18nUpper();
  },

  getNameLower() {
    return `name.${i18nLower()}`;
  },

  getNameUpper() {
    return `name.${i18nUpper()}`;
  },

  getDescriptionLower() {
    return `description.${i18nLower()}`;
  },

  getDescriptionUpper() {
    return `description.${i18nUpper()}`;
  },

  getObjectNameLower(item) {
    let name = "";
    if (item && item.name) {
      name = item["name"][i18nLower()];
    }
    return name;
  },

  getObjectLower(item) {
    let name = "";
    if (item) {
      name = item[i18nLower()];
    }
    return name;
  },

  getObjectNameUpper(item) {
    let name = "";
    if (item && item.name) {
      name = item["name"][i18nUpper()];
    }
    return name;
  },

  getObjectDescriptionLower(item) {
    let description = "";
    if (item !== null) {
      description = item["description"][i18nLower()];
    }
    return description;
  },

  getObjectDescriptionUpper(item) {
    let description = "";
    if (item !== null) {
      description = item["description"][i18nUpper()];
    }
    return description;
  },

  getHtmlColors() {
    return getHtmlColors();
  },

  isObject(item) {
    return typeof item === "object" && !Array.isArray(item);
  },

  canShowHelpTexts(editable = false) {
    return (
      (authStore.state.userProfile?.settings?.showFormHelpMessages &&
        authStore.state.userProfile?.settings?.showDetailHelpMessages) ||
      (authStore.state.userProfile?.settings?.showFormHelpMessages && editable)
    );
  },
  dateFormatForLists() {
    const lang = this.getLanguageSplittedMinus();
    return lang === "es" || lang === "ca"
      ? "DD/MM/YYYY HH:mm:ss"
      : "YYYY/MM/DD HH:mm:ss";
  },
  removeAccents(value) {
    return value
      .replace(/[áàãâä]/gi, "a")
      .replace(/[éè¨ê]/gi, "e")
      .replace(/[íìïî]/gi, "i")
      .replace(/[óòöôõ]/gi, "o")
      .replace(/[úùüû]/gi, "u");
  },

  orderArray(array) {
    return array.slice().sort(compareStrings);
  },
};
