<template>
  <div class="extra-driver-validate">
    <div
      class="divContainer mx-3 button-rounded flex-wrap rounded"
      :style="{
        backgroundColor: colors.colorSurfaceMedium,
        color: colors.colorOnSurfaceMedium,
      }"
    >
      <div class="flex flex-row justify-between">
        <div class="flex flex-row justify-start m-2">
          <F2gIcon
            class="mr-2"
            :value="'check'"
            :stroke="colors.colorPrimary"
            :height="22"
            :width="32"
            :strokeWidth="7"
          />
          <div class="flex-col-center">
            {{ $t("common.additionalDriver") }}
          </div>
          <div class="flex-col-center font-weight-bold ml-2">
            {{ "#" + (id + 1) }}
          </div>
          <div class="flex-col-center ml-2">
            {{
              additionalDriver.name +
              " " +
              additionalDriver.surnames +
              " - " +
              additionalDriver.nif +
              " - " +
              DateUtil.formatDate(additionalDriver.nifValidDate)
            }}
          </div>
          <F2gIcon
            class="ml-auto cursor-pointer"
            :value="'delete'"
            :stroke="'#D25050'"
            :height="22"
            :width="32"
            :strokeWidth="7"
            @click="deleteUser"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DateUtil from "@/flit2go-web-core/util/dateUtil";

export default {
  name: "extraDriverValidate",
  components: {},
  props: {
    additionalDriver: { required: false, type: Object },
    id: { required: false, type: Number, default: 0 },
  },
  data: () => ({
    DateUtil,
  }),
  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
    }),
  },
  methods: {
    deleteUser() {
      this.$emit("deleteUser", this.additionalDriver);
    },
  },
};
</script>
