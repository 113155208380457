const USER_STATUS = Object.freeze({
  VALIDATED: "VAL",
  DOCUMENTATION_UPDATED: "IMG",
  VALIDATED_BY_OWNER: "VAO",
  VALIDATED_TOKEN: "TOK",
  EXPIRED_DOCUMENTATION: "EXD",
});

const USER_MANDATORY = Object.freeze({
  OPTIONAL: "OPT",
  MANDATORY: "MAN",
  NOT_APPEAR: "NAP",
});

export default Object.freeze({
  STATUS: USER_STATUS,
  MANDATORY: USER_MANDATORY,
});
