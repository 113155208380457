const HELP_MESSAGES = Object.freeze({
  ALWAYS_SHOW: "ALL",
  ONLY_FORMS: "OLF",
  DO_NOT_SHOW: "DNS",
});

const STATUS_FRAME = Object.freeze({
  FRAME_WEB_LOGIN: "FRLOG",
  FRAME_WEB_PENDING_PAYMENT: "FRPEP",
  FRAME_WEB_CONFIRMATION: "FRCON",
});

export default { HELP: HELP_MESSAGES, STATUS: STATUS_FRAME };
