<template>
  <div class="create-credit-card-modal-redsys">
    <v-dialog
      :class="'rounded-t-xl'"
      transition="dialog-bottom-transition"
      :value="show"
      :maxWidth="800"
      width="100%"
      :height="700"
      :style="`backgroundColor:${colors.colorSurface} !important`"
      @click:outside="closeDialog"
    >
      <template>
        <v-card class="border-0">
          <v-toolbar :color="colors.colorPrimary">
            <v-btn icon @click="closeDialog">
              <v-icon :color="colors.colorOnPrimary">close</v-icon>
            </v-btn>
            <v-toolbar-title :style="`color: ${colors.colorOnPrimary}`">{{
              $t("addCreditCard")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text :style="'padding: 0 !important'">
            <TailLoader :color="'green'" class="z-0" />
            <iframe
              :src="
                payments_url +
                  `/#/mainView?token=redsysCreateCard&bearer=${this.loginParameters.access_token}&userId=${this.userLoged.id}&ownerId=${this.owner}`
              "
              title="Redsys"
              width="100%"
              :height="700"
              class="overflow-y-hidden z-10 relative"
            >
            </iframe>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import TailLoader from "@/components/tail-loader";

export default {
  name: "CreateCreditCardModalRedsys",
  props: {
    show: { required: true, type: Boolean, default: true },
  },
  components: { TailLoader },
  data() {
    return { payments_url: "" };
  },
  mounted() {
    this.payments_url = process.env.VUE_APP_PAYMENTS_URL;
  },
  computed: {
    ...mapState(["configuration"]),
    ...mapGetters({
      colors: "configuration/getColors",
      loginParameters: "user/loginParameters",
      userLoged: "user/userLoged",
      owner: "configuration/getOwner",
    }),
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.v-dialog--active {
  border-radius: 24px !important;
}
.v-dialog--fullscreen {
  height: 90% !important;
  bottom: 0 !important;
  top: auto !important;
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
</style>
