<template>
  <div
    class="lateral-panel my-0 flex"
    :class="{
      'lateral-panel--shown': show,
      'lateral-panel--hidded': !show,
      'rounded-l-lg':
        $vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm',
    }"
    :style="`background-color: ${colors.colorSurface}`"
  >
    <div
      class="lateral-panel__content mx-auto md:p-8"
      :class="{
        'lateral-panel__content--shown': showContent,
        'lateral-panel__content--hidded': !showContent,
      }"
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LateralPanel",
  props: { open: { type: Boolean, default: false, required: false } },
  data() {
    return {
      show: false,
      showContent: false,
    };
  },
  watch: {
    open() {
      if (this.open) {
        this.doOpen();
      } else {
        this.doClose();
      }
    },
  },
  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
    }),
  },
  methods: {
    doClose() {
      this.showContent = false;
      setTimeout(() => (this.show = false), 400);
    },
    doOpen() {
      this.show = true;
      setTimeout(() => (this.showContent = true), 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.lateral-panel {
  height: 100vh;
  position: fixed;
  background-color: white;
  top: 0;
  right: 0;
  z-index: 500;
  overflow: auto;
  box-shadow: -6px 0px 20px -3px rgba(15, 15, 15, 0.53);
  transition: width 0.8s ease-in;

  &--shown {
    width: 550px;
  }

  &--hidded {
    width: 0px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    transition: opacity 0.4s ease-in;

    &--shown {
      opacity: 1;
    }

    &--hidded {
      opacity: 0;
    }
  }
}

@media (max-width: 690px) {
  .lateral-panel {
    &--shown {
      width: 100vw;
    }

    &--hidded {
      width: 0px;
    }
  }
}
</style>
