import PayOrderConstants from "@/flit2go-web-core/interfaces/payOrderConstants.js";
import IssueConstants from "@/flit2go-web-core/interfaces/issueConstants.js";
import SettingsConstants from "@/flit2go-web-core/interfaces/settingsConstants.js";
import VehicleConstants from "@/flit2go-web-core/interfaces/vehicleConstants.js";
import RentalConstants from "@/flit2go-web-core/interfaces/rentalConstants.js";
import ColourConstants from "@/flit2go-web-core/interfaces/colourConstants.js";
import RoleConstants from "@/flit2go-web-core/interfaces/roleConstants.js";
import OwnerConstants from "@/flit2go-web-core/interfaces/ownerConstants.js";
import PricingConstants from "@/flit2go-web-core/interfaces/pricingConstants.js";
import DNRConstants from "@/flit2go-web-core/interfaces/dnrConstants.js";
import DeviceConstants from "@/flit2go-web-core/interfaces/deviceConstants.js";
import PromotionConstants from "@/flit2go-web-core/interfaces/promotionConstants.js";
import VehicleDealerConstants from "@/flit2go-web-core/interfaces/vehicleDealerConstants.js";
import CustomerConstants from "@/flit2go-web-core/interfaces/customerConstants.js";
import CustomerDealerConstants from "@/flit2go-web-core/interfaces/customerDealerConstants.js";
import ModuleConstants from "@/flit2go-web-core/interfaces/moduleConstants.js";
import WidgetConstants from "@/flit2go-web-core/interfaces/widgetConstants.js";
import DrivingLicenseConstants from "@/flit2go-web-core/interfaces/drivingLicenseConstants.js";
import CountryConstants from "@/flit2go-web-core/interfaces/countryConstants.js";
import SymbolConstants from "@/flit2go-web-core/interfaces/symbolConstants.js";
import ControlConstants from "@/flit2go-web-core/interfaces/controlConstants.js";
import ErrorConstants from "@/flit2go-web-core/interfaces/errorConstants.js";
import TimeCodeConstants from "@/flit2go-web-core/interfaces/timeCodeConstants.js";
import IndemnizationConstants from "@/flit2go-web-core/interfaces/indemnizationConstants.js";
import DamageConstants from "@/flit2go-web-core/interfaces/damageConstants.js";
import TimeConstants from "@/flit2go-web-core/interfaces/timeConstants.js";
import ReserveLogConstants from "@/flit2go-web-core/interfaces/reserveLogConstants.js";
import travelConstants from "@/flit2go-web-core/interfaces/travelConstants.js";
import CategoryConstants from "@/flit2go-web-core/interfaces/categoryConstants.js";
import NotificationTypeConstants from "@/flit2go-web-core/interfaces/notificationTypeConstants.js";
import ChatPositionConstants from "@/flit2go-web-core/interfaces/chatPositionConstants.js";
import PaymentsConstants from "@/flit2go-web-core/interfaces/paymentsConstants.js";
import PaymentPlatformConstants from "@/flit2go-web-core/interfaces/paymentPlatformsConstants.js";
import ReportConstants from "@/flit2go-web-core/interfaces/reportConstants.js";
import IntegrationConstants from "@/flit2go-web-core/interfaces/integrationConstants.js";
import TagNotesTypeConstants from "@/flit2go-web-core/interfaces/tagNotesTypesConstants.js";
import TaskConstants from "@/flit2go-web-core/interfaces/taskConstants.js";
import UserConstants from "@/flit2go-web-core/interfaces/userConstants.js";
import SubscriptionConstants from "@/flit2go-web-core/interfaces/subscriptionConstants.js";
import BonusConstants from "@/flit2go-web-core/interfaces/bonusConstants.js";
import BranchConstants from "@/flit2go-web-core/interfaces/branchConstants.js";

export default {
  ISSUE: IssueConstants,
  VEHICLE: VehicleConstants,
  RENTAL: RentalConstants,
  COLOUR: ColourConstants,
  PAY_ORDER: PayOrderConstants,
  ROLE: RoleConstants,
  OWNER: OwnerConstants,
  PRICING: PricingConstants,
  DNR: DNRConstants,
  PROMOTION: PromotionConstants,
  VEHICLE_DEALER: VehicleDealerConstants,
  CUSTOMER: CustomerConstants,
  CUSTOMER_DEALER: CustomerDealerConstants,
  MODULE: ModuleConstants,
  WIDGET: WidgetConstants,
  DRIVING_LICENSE: DrivingLicenseConstants,
  COUNTRY: CountryConstants,
  SYMBOL: SymbolConstants,
  ERROR: ErrorConstants,
  TIME_CODE: TimeCodeConstants,
  DEVICES: DeviceConstants,
  CONTROL: ControlConstants,
  INDEMNIZATION: IndemnizationConstants,
  DAMAGE: DamageConstants,
  TIME: TimeConstants,
  RESERVE_LOG: ReserveLogConstants,
  TRAVEL: travelConstants,
  NOTIFICATION_TYPE: NotificationTypeConstants,
  CHAT_POSITION: ChatPositionConstants,
  REPORT: ReportConstants,
  INTEGRATION: IntegrationConstants,
  CATEGORY: CategoryConstants,
  PAYMENT: PaymentsConstants,
  PAYMENT_PLATFORM: PaymentPlatformConstants,
  TAG_NOTES_TYPE: TagNotesTypeConstants,
  USER: UserConstants,
  TASK: TaskConstants,
  SUBSCRIPTION: SubscriptionConstants,
  SETTINGS: SettingsConstants,
  BONUS: BonusConstants,
  BRANCH: BranchConstants,
};
