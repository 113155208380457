<template>
  <div class="calendar-date-selector">
    <div class="flex justify-end mb-2" v-if="!isHorizontal">
      <F2gIcon
        value="close"
        :class="'cursor-pointer'"
        :height="26"
        :width="26"
        :stroke="colors.colorOnHeader"
        :strokeWidth="6"
        @click="$emit('close')"
      />
    </div>
    <!-- Calendar -->
    <v-skeleton-loader
      height="500px"
      type="image"
      v-show="!showCalendar"
    ></v-skeleton-loader>
    <div class="mt-4">
      <vc-date-picker
        ref="calendar"
        v-if="showCalendar"
        mode="date"
        v-model="range"
        :drag-attribute="selectAttribute"
        :select-attribute="selectAttribute"
        :value="range"
        is-range
        :style="{
          border: 0,
        }"
        is-expanded
        color="green"
        type="range"
        :rows="isHorizontal ? 1 : 2"
        :columns="isHorizontal ? 2 : 1"
        :minDate="isReturnBackMode ? range.start : startDate"
        :attributes="attrs"
        :locale="language"
        :firstDayOfWeek="language === 'en' ? 1 : 2"
        @input="changeDates"
        @update:from-page="changeMonth"
        @dayclick="dateClicked"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DateUtil from "@/flit2go-web-core/util/dateUtil";

export default {
  name: "CalendarDateSelector",
  components: {},
  props: {
    pickUpDate: { required: false, type: String, default: "" },
    returnBackDate: { required: false, type: String, default: "" },
    type: { required: false, type: String, default: "vertical" },
    mode: { required: false, type: String, default: "pickUp" },
  },
  data() {
    return {
      showCalendar: true,
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      range: {
        start: DateUtil.formatDateNoTimeZone(
          this.pickUpDate,
          this.ownerTimezone
        ),
        end: DateUtil.formatDateNoTimeZone(
          this.returnBackDate,
          this.ownerTimezone
        ),
      },
      attrs: [],
      monthSelected: new Date().getMonth(),
    };
  },
  watch: {
    pickUpDate() {
      this.showCalendar = false;
      this.range.start = DateUtil.formatDateNoTimeZone(
        this.pickUpDate,
        this.ownerTimezone
      );
      this.range.end = DateUtil.formatDateNoTimeZone(
        this.returnBackDate,
        this.ownerTimezone
      );
      setTimeout(() => {
        this.showCalendar = true;
      }, 50);
    },
    returnBackDate() {
      this.showCalendar = false;
      this.range.start = DateUtil.formatDateNoTimeZone(
        this.pickUpDate,
        this.ownerTimezone
      );
      this.range.end = DateUtil.formatDateNoTimeZone(
        this.returnBackDate,
        this.ownerTimezone
      );
      setTimeout(() => {
        this.showCalendar = true;
      }, 50);
    },
  },

  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
      language: "configuration/getUserLanguage",
      ownerTimezone: "configuration/getOwnerTimezone",
    }),
    isHorizontal() {
      return this.type === "horizontal";
    },
    isReturnBackMode() {
      return this.mode === "returnBack";
    },
    getMinDate() {
      return this.mode === "pickUp"
        ? new Date(this.startDate)
        : new Date(this.range.start);
    },
    selectAttribute() {
      return {
        highlight: {
          start: {
            fillMode: "solid",
            style: {
              // Circle styles
              background: this.colors.colorPrimary,
            },
            contentStyle: {
              // Text styles
              color: "white",
            },
          },
          base: {
            fillMode: "light",
            style: {
              // Circle styles
              background: this.colors.colorPrimary + "55",
            },
            contentStyle: {
              // Text styles
              color: "black",
            },
          },
          end: {
            fillMode: "solid",
            style: {
              // Circle styles
              background: this.colors.colorPrimary,
            },
            contentStyle: {
              // Text styles
              color: "white",
            },
          },
        },
      };
    },
  },
  methods: {
    changeDates() {
      this.range.start = DateUtil.formatDateNoTimeZone(
        this.range.start,
        this.ownerTimezone
      );
      this.range.end = DateUtil.formatDateNoTimeZone(
        this.range.end,
        this.ownerTimezone
      );
      let rangeToSend = this.range;
      rangeToSend.mode = this.mode;
      this.$emit("changeDates", this.rangeToSend);
      this.$emit("close");
    },
    changeMonth(event) {
      this.monthSelected = event.month - 1;
    },
    dateClicked(dateSelected) {
      if (this.getMinDate <= dateSelected.date) {
        if (this.mode === "pickUp") {
          this.range.start = DateUtil.formatDateNoTimeZone(
            dateSelected.date,
            this.ownerTimezone
          );
          if (this.range.start > this.range.end) {
            this.range.end = DateUtil.formatDateNoTimeZone(
              dateSelected.date,
              this.ownerTimezone
            );
          }
        } else {
          this.range.end = DateUtil.formatDateNoTimeZone(
            dateSelected.date,
            this.ownerTimezone
          );
        }
        let rangeToSend = this.range;
        rangeToSend.mode = this.mode;
        this.$emit("changeDates", this.range);
        this.$emit("close");
      }
    },
  },
};
</script>
<style lang="scss">
.vc-svg-icon {
  color: var(--gray-800);
}
</style>
