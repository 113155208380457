<template>
  <div class="direction-billing-data">
    <div v-if="!showEdit" class="mb-3 mt-6">
      <div class="relative w-max">
        <p
          class="relative leading-4 font-medium text-lg"
          :style="{ color: colors.colorOnBackground }"
        >
          {{ $t("forms.address.billingAddress") }}
        </p>
        <div
          class="absolute -top-2 -right-10 flex flex-row align-end p-2 rounded-full"
          :style="{ backgroundColor: colors.colorPrimary }"
          @click="showEdit = true"
        >
          <F2gIcon
            :height="14"
            :width="14"
            :stroke="colors.colorOnPrimary"
            file="icons_solid.svg"
            class="cursor-pointer"
            :value="'edit'"
            :strokeWidth="4"
          />
        </div>
      </div>
      <div v-if="userLoged.billAddress">
        <div class="flex sm:flex-row flex-col w-full">
          <div
            v-if="userLoged.billAddress.documentIdentity"
            class="w-full mr-2 p-1 pl-2 rounded-lg border"
            :class="
              userLoged.billAddress.businessSurnames ? 'sm:w-1/3' : 'sm:w-1/2'
            "
            :style="{
              backgroundColor: colors.colorSurface,
              borderColor: colors.colorSurfaceMedium,
            }"
          >
            <div class="" :style="{ color: colors.colorOnSurface }">
              {{ userLoged.billAddress.documentIdentity }}
            </div>
          </div>
          <div
            v-if="userLoged.billAddress.businessName"
            class="w-full mr-2 p-1 pl-2 rounded-lg border"
            :class="
              userLoged.billAddress.businessSurnames ? 'sm:w-1/3' : 'sm:w-1/2'
            "
            :style="{
              backgroundColor: colors.colorSurface,
              borderColor: colors.colorSurfaceMedium,
            }"
          >
            <div class="" :style="{ color: colors.colorOnSurface }">
              {{ userLoged.billAddress.businessName }}
            </div>
          </div>
          <div
            v-if="userLoged.billAddress.businessSurnames"
            class="sm:w-1/3 w-full mr-2 p-1 pl-2 rounded-lg border"
            :style="{
              backgroundColor: colors.colorSurface,
              borderColor: colors.colorSurfaceMedium,
            }"
          >
            <div class="" :style="{ color: colors.colorOnSurface }">
              {{ userLoged.billAddress.businessSurnames }}
            </div>
          </div>
        </div>
        <div class="flex sm:flex-row flex-col w-full mt-2">
          <div
            v-if="userLoged.billAddress.direction"
            class="sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border"
            :style="{
              backgroundColor: colors.colorSurface,
              borderColor: colors.colorSurfaceMedium,
            }"
          >
            <div class="" :style="{ color: colors.colorOnSurface }">
              {{ userLoged.billAddress.direction }}
            </div>
          </div>
          <div
            v-if="userLoged.billAddress.directionAdditionalInfo"
            class="sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border"
            :style="{
              backgroundColor: colors.colorSurface,
              borderColor: colors.colorSurfaceMedium,
            }"
          >
            <div class="" :style="{ color: colors.colorOnSurface }">
              {{ userLoged.billAddress.directionAdditionalInfo }}
            </div>
          </div>
        </div>
        <div class="flex sm:flex-row flex-col w-full mt-2">
          <div
            v-if="userLoged.billAddress.country"
            class="sm:w-1/4 w-full mr-2 p-1 pl-2 rounded-lg border"
            :style="{
              backgroundColor: colors.colorSurface,
              borderColor: colors.colorSurfaceMedium,
            }"
          >
            <div class="" :style="{ color: colors.colorOnSurface }">
              {{ userLoged.billAddress.country }}
            </div>
          </div>
          <div
            v-if="userLoged.billAddress.city"
            class="sm:w-1/4 w-full mr-2 p-1 pl-2 rounded-lg border"
            :style="{
              backgroundColor: colors.colorSurface,
              borderColor: colors.colorSurfaceMedium,
            }"
          >
            <div class="" :style="{ color: colors.colorOnSurface }">
              {{ userLoged.billAddress.city }}
            </div>
          </div>
          <div
            v-if="userLoged.billAddress.locality"
            class="sm:w-1/4 w-full mr-2 p-1 pl-2 rounded-lg border"
            :style="{
              backgroundColor: colors.colorSurface,
              borderColor: colors.colorSurfaceMedium,
            }"
          >
            <div class="" :style="{ color: colors.colorOnSurface }">
              {{ userLoged.billAddress.locality }}
            </div>
          </div>
          <div
            v-if="userLoged.billAddress.postalCode"
            class="sm:w-1/4 w-full mr-2 p-1 pl-2 rounded-lg border"
            :style="{
              backgroundColor: colors.colorSurface,
              borderColor: colors.colorSurfaceMedium,
            }"
          >
            <div class="" :style="{ color: colors.colorOnSurface }">
              {{ userLoged.billAddress.postalCode }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex flex-row w-full sm:mt-2"
        v-if="direction.flightNumber || direction.destinationAddress"
      >
        <p
          class="leading-4 font-medium text-lg"
          :style="{ color: colors.colorOnBackground, paddingTop: '5px' }"
        >
          {{ $t("destinationAddress") }}
        </p>
      </div>
      <div
        v-if="direction.flightNumber || direction.destinationAddress"
        class="flex sm:flex-row flex-col w-full"
      >
        <div
          v-if="direction.flightNumber"
          class="sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
          }"
        >
          <div class="" :style="{ color: colors.colorOnSurface }">
            {{ direction.flightNumber }}
          </div>
        </div>
        <div
          v-if="direction.destinationAddress"
          class="sm:w-1/2 w-full mr-2 p-1 pl-2 rounded-lg border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
          }"
        >
          <div class="" :style="{ color: colors.colorOnSurface }">
            {{ direction.destinationAddress }}
          </div>
        </div>
      </div>
      <error-message
        v-if="$v.$invalid"
        :text="$t('errors.invalidData')"
        :color="$CONSTANTS.COLOUR.RED_ERROR"
      />
    </div>
    <div v-if="showEdit">
      <p
        class="leading-4 font-medium text-lg"
        :style="{ color: colors.colorOnBackground, paddingTop: '5px' }"
      >
        {{ $t("forms.address.billingAddress") }}
      </p>
      <div
        class="flex flex-row justify-start items-center mb-2"
        v-if="userLoged.address.direction"
      >
        <v-checkbox
          class="mt-0 pt-0"
          v-model="checkBilling"
          :dark="colors.colorBackground === '#000000' ? true : false"
          :color="colors.colorPrimary"
          hide-details
          @change="changeCheckBilling"
        />
        <span class="" :style="{ color: colors.colorOnBackground }">{{
          $t("forms.address.useResidenceAddress")
        }}</span>
      </div>
      <div
        v-if="!checkBilling"
        class="flex-wrap flex-col"
        :class="{
          'direction-billing-data__content--shown': showContent,
          'direction-billing-data__content--hidded': !showContent,
        }"
      >
        <span class="flex flex-row w-full">
          <v-radio-group
            row
            v-model="direction.company"
            :error-messages="companyErrors"
          >
            <v-radio
              :label="$t('naturalPerson')"
              :value="false"
              :color="colors.colorPrimary"
            ></v-radio>
            <v-radio
              :label="$t('mobile.common.business')"
              :value="true"
              :color="colors.colorPrimary"
            ></v-radio>
          </v-radio-group>
        </span>
        <div v-if="direction.company == true || direction.company == false">
          <span class="flex flex-col lg:flex-row">
            <v-text-field
              class="lg:w-1/3 w-full lg:mr-2 custom-placeholer-color"
              v-model="direction.documentIdentity"
              :outlined="true"
              :error-messages="documentIdentityErrors"
              :style="`--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`"
              :color="colors.colorPrimary"
              :backgroundColor="colors.colorSurface"
              :dark="colors.colorBackground === '#000000' ? true : false"
            >
              <template #label>
                {{ direction.company ? $t("forms.cif") : $t("forms.nif")
                }}<RedAsterisk v-if="filters.billingAddressRequired" />
              </template>
            </v-text-field>
            <v-text-field
              class="lg:w-1/3 w-full lg:ml-2 custom-placeholer-color"
              v-model="direction.businessName"
              :outlined="true"
              :style="`--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`"
              :color="colors.colorPrimary"
              :backgroundColor="colors.colorSurface"
              :dark="colors.colorBackground === '#000000' ? true : false"
              :error-messages="nameErrors"
            >
              <template #label>
                {{
                  direction.company
                    ? $t("common.businessName")
                    : $t("forms.name")
                }}<RedAsterisk v-if="filters.billingAddressRequired" />
              </template>
            </v-text-field>
            <v-text-field
              v-if="!direction.company"
              class="lg:w-1/3 w-full lg:ml-2 custom-placeholer-color"
              v-model="direction.businessSurnames"
              :outlined="true"
              :error-messages="businessSurnamesErrors"
              :style="`--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`"
              :color="colors.colorPrimary"
              :backgroundColor="colors.colorSurface"
              :dark="colors.colorBackground === '#000000' ? true : false"
            >
              <template #label>
                {{ $t("forms.surnames")
                }}<RedAsterisk v-if="filters.billingAddressRequired" />
              </template>
            </v-text-field>
          </span>
          <span class="flex flex-col lg:flex-row">
            <v-text-field
              class="lg:w-1/2 w-full lg:mr-2 custom-placeholer-color"
              v-model="direction.direction"
              :outlined="true"
              :style="`--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`"
              :color="colors.colorPrimary"
              :backgroundColor="colors.colorSurface"
              :dark="colors.colorBackground === '#000000' ? true : false"
              :error-messages="addressErrors"
            >
              <template #label>
                {{ $t("forms.address.address")
                }}<RedAsterisk v-if="filters.billingAddressRequired" />
              </template>
            </v-text-field>
            <v-text-field
              class="lg:w-1/2 w-full lg:ml-2 custom-placeholer-color"
              v-model="direction.directionAdditionalInfo"
              :outlined="true"
              :style="`--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`"
              :color="colors.colorPrimary"
              :backgroundColor="colors.colorSurface"
              :dark="colors.colorBackground === '#000000' ? true : false"
            >
              <template #label>
                {{ $t("forms.address.additionalAddressInfo") }}
              </template>
            </v-text-field>
          </span>
          <span class="flex flex-col lg:flex-row">
            <v-autocomplete
              class="lg:w-1/4 w-full lg:mr-2 custom-placeholer-color"
              v-model="direction.country"
              :items="$PageUtils.orderArray(user.nationalities)"
              :item-text="$PageUtils.getNameLower()"
              return-object
              :outlined="true"
              :style="`--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`"
              :color="colors.colorPrimary"
              :backgroundColor="colors.colorSurface"
              :dark="colors.colorBackground === '#000000' ? true : false"
              :error-messages="countryErrors"
              :filter="customFilterCountries"
            >
              <template #label>
                {{ $t("forms.address.country")
                }}<RedAsterisk v-if="filters.billingAddressRequired" />
              </template>
            </v-autocomplete>
            <v-text-field
              class="lg:w-1/4 w-full lg:mr-2 custom-placeholer-color"
              v-model="direction.city"
              :outlined="true"
              :style="`--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`"
              :color="colors.colorPrimary"
              :backgroundColor="colors.colorSurface"
              :dark="colors.colorBackground === '#000000' ? true : false"
              :error-messages="provinceErrors"
            >
              <template #label>
                {{ $t("forms.address.province")
                }}<RedAsterisk v-if="filters.billingAddressRequired" />
              </template>
            </v-text-field>
            <v-text-field
              class="lg:w-1/4 w-full lg:mr-2 custom-placeholer-color"
              v-model="direction.locality"
              :outlined="true"
              :style="`--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`"
              :color="colors.colorPrimary"
              :backgroundColor="colors.colorSurface"
              :dark="colors.colorBackground === '#000000' ? true : false"
              :error-messages="localityErrors"
            >
              <template #label>
                {{ $t("forms.address.locality")
                }}<RedAsterisk v-if="filters.billingAddressRequired" />
              </template>
            </v-text-field>
            <v-text-field
              class="lg:w-1/4 w-full custom-placeholer-color"
              v-model="direction.postalCode"
              :outlined="true"
              :style="`--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`"
              :color="colors.colorPrimary"
              :backgroundColor="colors.colorSurface"
              :dark="colors.colorBackground === '#000000' ? true : false"
              :error-messages="postalCodeErrors"
            >
              <template #label>
                {{ $t("forms.address.postalCode")
                }}<RedAsterisk v-if="filters.billingAddressRequired" />
              </template>
            </v-text-field>
          </span>
        </div>
      </div>
      <p
        v-if="
          canShowData('flightMandatoryType') ||
          canShowData('destinationMandatoryType')
        "
        class="leading-4 font-medium text-lg"
        :style="{ color: colors.colorOnBackground, paddingTop: '5px' }"
      >
        {{ $t("destinationAddress") }}
      </p>
      <span
        v-if="
          canShowData('flightMandatoryType') ||
          canShowData('destinationMandatoryType')
        "
        class="flex flex-col lg:flex-row"
      >
        <v-text-field
          v-if="canShowData('flightMandatoryType')"
          class="lg:w-1/2 w-full lg:mr-2 custom-placeholer-color"
          v-model="direction.flightNumber"
          :outlined="true"
          :color="colors.colorPrimary"
          :style="`--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`"
          :backgroundColor="colors.colorSurface"
          :dark="colors.colorBackground === '#000000' ? true : false"
          :error-messages="flightNumberErrors"
        >
          <template #label>
            {{ $t("flyNumber")
            }}<RedAsterisk v-if="isRequired('flightMandatoryType')" />
          </template>
        </v-text-field>
        <v-text-field
          v-if="canShowData('destinationMandatoryType')"
          class="lg:w-1/2 w-full custom-placeholer-color"
          v-model="direction.destinationAddress"
          :outlined="true"
          :style="`--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`"
          :color="colors.colorPrimary"
          :backgroundColor="colors.colorSurface"
          :dark="colors.colorBackground === '#000000' ? true : false"
          :error-messages="destinationAddressErrors"
        >
          <template #label>
            {{ $t("hotelAddress")
            }}<RedAsterisk v-if="isRequired('destinationMandatoryType')" />
          </template>
        </v-text-field>
      </span>
      <span class="sm:w-48 sm:mr-2 w-full" v-if="showButtons">
        <v-btn
          class="w-full lg:w-48 mb-4 mx-auto border-2 rounded-config30"
          :color="colors.colorPrimary"
          @click="saveData"
        >
          <span class="" :style="{ color: colors.colorOnPrimary }">{{
            $t("confirmData")
          }}</span>
        </v-btn>
      </span>
      <span class="sm:w-48 w-full" v-if="showButtons">
        <v-btn
          class="w-full lg:w-48 mb-4 mx-auto border-2 rounded-config30"
          outlined
          :color="colors.colorPrimary"
          @click="showEdit = false"
          >{{ $t("common.cancel") }}
        </v-btn>
      </span>
    </div>
  </div>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";
import { mapGetters, mapState } from "vuex";
export default {
  name: "DirectionBillingData",
  components: {
    RedAsterisk: () =>
      import("@/flit2go-web-core/components/fields/f2g-red-asterisk"),
    ErrorMessage: () =>
      import("@/flit2go-web-core/components/ui/error-message"),
  },
  props: {
    validate: {
      type: Boolean,
      default: false,
    },
    showContent: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      checkBilling: false,
      showEdit: false,
      showButtons: true,
      direction: {
        documentIdentity: null,
        businessName: null,
        businessSurnames: null,
        direction: null,
        directionAdditionalInfo: null,
        country: null,
        city: null,
        locality: null,
        postalCode: null,
        company: null,
        flightNumber: null,
        destinationAddress: null,
      },
      userToCreate: {
        id: null,
        address: null,
        billAddress: null,
        name: "",
        surnames: "",
        country: null,
        phoneNumber: "",
        bornDate: "",
        language: "",
        username: "",
      },
    };
  },
  validations() {
    return {
      direction: {
        company: {
          required: requiredIf(() => {
            return !this.checkBilling && this.filters.billingAddressRequired;
          }),
        },
        documentIdentity: {
          required: requiredIf(() => {
            return !this.checkBilling && this.filters.billingAddressRequired;
          }),
        },
        businessName: {
          required: requiredIf(() => {
            return !this.checkBilling && this.filters.billingAddressRequired;
          }),
        },
        businessSurnames: {
          required: requiredIf(() => {
            return (
              !this.checkBilling &&
              this.filters.billingAddressRequired &&
              !this.direction.company
            );
          }),
        },
        direction: {
          required: requiredIf(() => {
            return !this.checkBilling && this.filters.billingAddressRequired;
          }),
        },
        country: {
          required: requiredIf(() => {
            return !this.checkBilling && this.filters.billingAddressRequired;
          }),
        },
        city: {
          required: requiredIf(() => {
            return !this.checkBilling && this.filters.billingAddressRequired;
          }),
        },
        locality: {
          required: requiredIf(() => {
            return !this.checkBilling && this.filters.billingAddressRequired;
          }),
        },
        postalCode: {
          required: requiredIf(() => {
            return !this.checkBilling && this.filters.billingAddressRequired;
          }),
        },
        flightNumber: {
          required: requiredIf(() => {
            return this.isRequired("flightMandatoryType");
          }),
        },
        destinationAddress: {
          required: requiredIf(() => {
            return this.isRequired("destinationMandatoryType");
          }),
        },
      },
    };
  },
  watch: {},
  mounted() {
    if (!this.userLoged.billAddress) {
      this.showEdit = true;
      if (this.userLoged.address.direction) {
        this.showButtons = false;
        this.checkBilling = true;
        this.changeCheckBilling();
        setTimeout(() => {
          this.saveData();
        }, 300);
      }
    } else {
      this.initData();
    }
    if (!this.filters.billingAddressRequired) {
      this.$emit("validation", {
        valid: true,
      });
    }
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      colors: "configuration/getColors",
      language: "configuration/getUserLanguage",
      filterInformationUsers: "configuration/getFilterInformationUsers",
      userLoged: "user/userLoged",
      filters: "configuration/getFilters",
      loginParameters: "user/loginParameters",
    }),
    companyErrors() {
      const errors = [];
      if (this.$v.direction.company.$dirty) {
        !this.$v.direction.company.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    documentIdentityErrors() {
      const errors = [];
      if (this.$v.direction.documentIdentity.$dirty) {
        !this.$v.direction.documentIdentity.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (this.$v.direction.businessName.$dirty) {
        !this.$v.direction.businessName.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    businessSurnamesErrors() {
      const errors = [];
      if (this.$v.direction.businessSurnames.$dirty) {
        !this.$v.direction.businessSurnames.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (this.$v.direction.direction.$dirty) {
        !this.$v.direction.direction.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    countryErrors() {
      const errors = [];
      if (this.$v.direction.country.$dirty) {
        !this.$v.direction.country.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    provinceErrors() {
      const errors = [];
      if (this.$v.direction.city.$dirty) {
        !this.$v.direction.city.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    localityErrors() {
      const errors = [];
      if (this.$v.direction.locality.$dirty) {
        !this.$v.direction.locality.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    postalCodeErrors() {
      const errors = [];
      if (this.$v.direction.postalCode.$dirty) {
        !this.$v.direction.postalCode.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    flightNumberErrors() {
      const errors = [];
      if (this.$v.direction.flightNumber.$dirty) {
        !this.$v.direction.flightNumber.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    destinationAddressErrors() {
      const errors = [];
      if (this.$v.direction.destinationAddress.$dirty) {
        !this.$v.direction.destinationAddress.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
  },
  methods: {
    saveData() {
      this.$v.$touch();
      this.$emit("validation", {
        valid: !this.$v.$invalid,
      });
      this.$emit("setTravelFields", this.direction);
      if (!this.$v.$invalid) {
        this.userToCreate = Object.assign({}, this.userLoged);
        if (
          this.userToCreate.country &&
          typeof this.userToCreate.country === "object" &&
          Object.keys(this.userToCreate.country).length === 0
        ) {
          this.userToCreate.country = null;
        }

        this.userToCreate.billAddress = Object.assign({}, this.direction);
        if (
          this.userToCreate.billAddress &&
          typeof this.userToCreate.billAddress.country === "object"
        ) {
          this.userToCreate.billAddress.country =
            this.$PageUtils.getObjectNameLower(
              this.userToCreate.billAddress.country
            );
        }
        if (
          this.userToCreate.address &&
          this.userToCreate.address.country &&
          typeof this.userToCreate.address.country === "object"
        ) {
          this.userToCreate.address.country =
            this.$PageUtils.getObjectNameLower(
              this.userToCreate.address.country
            );
        }
        let data = {
          user: this.userToCreate,
          token: this.loginParameters.access_token,
        };
        data.user["checkBilling"] = this.checkBilling;
        this.$store
          .dispatch("user/updateUser", data)
          .then(() => {
            this.getUserData(this.loginParameters);
            this.showEdit = false;
          })
          .catch((err) => {
            this.$showMessage.showError(err);
            this.showEdit = false;
          });
      }
    },
    getUserData(loginParameters) {
      let params = {
        loginParameters: loginParameters,
      };
      this.$store.dispatch("user/getUserData", params).then(() => {
        this.initData();
      });
    },
    initData() {
      if (this.userLoged.billAddress) {
        this.direction.documentIdentity =
          this.userLoged.billAddress.documentIdentity;
        this.direction.businessName = this.userLoged.billAddress.businessName;
        this.direction.businessSurnames =
          this.userLoged.billAddress.businessSurnames;
        this.direction.direction = this.userLoged.billAddress.direction;
        this.direction.directionAdditionalInfo =
          this.userLoged.billAddress.directionAdditionalInfo;
        this.direction.country = this.userLoged.billAddress.country;
        this.direction.city = this.userLoged.billAddress.city;
        this.direction.locality = this.userLoged.billAddress.locality;
        this.direction.postalCode = this.userLoged.billAddress.postalCode;
        this.direction.company = this.userLoged.billAddress.company;
      }
      if (
        this.filters.billingAddressRequired &&
        (!this.direction.documentIdentity ||
          !this.direction.businessName ||
          !this.direction.direction ||
          !this.direction.country ||
          !this.direction.locality ||
          !this.direction.postalCode)
      ) {
        this.showEdit = true;
      }
      if (
        (this.isRequired("flightMandatoryType") &&
          !this.direction.flightNumber) ||
        (this.isRequired("destinationMandatoryType") &&
          !this.direction.destinationAddress)
      ) {
        this.showEdit = true;
      }
      if (!this.showEdit) {
        this.$v.$touch();
        this.$emit("validation", {
          valid: !this.$v.$invalid,
        });
      }
    },
    changeCheckBilling() {
      if (this.checkBilling) {
        this.direction.documentIdentity = this.userLoged.nif;
        this.direction.businessName = this.userLoged.name;
        this.direction.businessSurnames = this.userLoged.surnames;
        if (this.userLoged.address) {
          this.direction.direction = this.userLoged.address.direction;
          this.direction.directionAdditionalInfo =
            this.userLoged.address.directionAdditionalInfo;
          this.direction.country = this.userLoged.address.country;
          this.direction.city = this.userLoged.address.city;
          this.direction.locality = this.userLoged.address.locality;
          this.direction.postalCode = this.userLoged.address.postalCode;
          this.direction.company = this.userLoged.address.company;
        }
      } else {
        this.direction.documentIdentity = null;
        this.direction.businessName = null;
        this.direction.businessSurnames = null;
        this.direction.direction = null;
        this.direction.directionAdditionalInfo = null;
        this.direction.country = null;
        this.direction.city = null;
        this.direction.locality = null;
        this.direction.postalCode = null;
        this.direction.company = null;
      }
    },
    isRequired(element) {
      return (
        this.filterInformationUsers[element] ===
        this.$CONSTANTS.USER.MANDATORY.MANDATORY
      );
    },
    isOptional(element) {
      return (
        this.filterInformationUsers[element] ===
        this.$CONSTANTS.USER.MANDATORY.OPTIONAL
      );
    },
    canShowData(element) {
      return (
        this.filterInformationUsers[element] !==
        this.$CONSTANTS.USER.MANDATORY.NOT_APPEAR
      );
    },
    customFilterCountries(item, queryText) {
      return (
        this.$PageUtils
          .removeAccents(item.name[this.$PageUtils.getLanguageSplittedMinus()])
          ?.toLowerCase()
          .indexOf(this.$PageUtils.removeAccents(queryText).toLowerCase()) > -1
      );
    },
  },
};
</script>
<style>
.v-input--radio-group--row {
  margin-top: 0px;
}
.custom-placeholer-color input {
  color: var(--custom_color-text) !important;
}
.custom-placeholer-color .v-label {
  color: var(--custom_color-label) !important;
  opacity: 1;
}
</style>
