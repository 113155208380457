var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"age-filter flex",class:_vm.type === 'horizontal' ? 'flex-row flex-grow-0 align-center' : 'flex-col'},[_c('div',{class:{
      'max-h-full flex-grow-0 pr-2 ': _vm.type === 'horizontal',
    }},[_c('h1',{style:({ color: _vm.colors.colorOnHeader })},[_vm._v(" "+_vm._s(_vm.$t("driversAge"))+" ")])]),_c('div',{staticClass:"flex-grow-0 flex flex-row",class:{
      'align-center': _vm.type === 'horizontal',
      'w-64':
        this.$vuetify.breakpoint.name === 'sm' ||
        this.$vuetify.breakpoint.name === 'xs' ||
        this.$vuetify.breakpoint.name === 'md',
      'w-80':
        this.$vuetify.breakpoint.name === 'lg' ||
        this.$vuetify.breakpoint.name === 'xl',
    }},[_c('div',{staticClass:"w-12 h-8 m-2 shadow-md rounded-md flex justify-center align-center transform hover:-translate-y-1 hover:scale-105 cursor-pointer transition-all duration-500",style:(`background-color: ${
        _vm.ageSelected === '20' ? _vm.colors.colorPrimary : _vm.colors.colorSurfaceMedium
      }; color: ${
        _vm.ageSelected === '20'
          ? _vm.colors.colorOnPrimary
          : _vm.colors.colorOnSurfaceMedium
      };`),on:{"click":function($event){_vm.ageSelected === '20' ? (_vm.ageSelected = null) : (_vm.ageSelected = '20')}}},[_vm._v(" 19-20 ")]),_c('div',{staticClass:"w-12 h-8 rounded-md flex justify-center m-2 shadow-md align-center transform hover:-translate-y-1 hover:scale-105 cursor-pointer transition-all duration-500",style:(`background-color: ${
        _vm.ageSelected === '24' ? _vm.colors.colorPrimary : _vm.colors.colorSurfaceMedium
      }; color: ${
        _vm.ageSelected === '24'
          ? _vm.colors.colorOnPrimary
          : _vm.colors.colorOnSurfaceMedium
      };`),on:{"click":function($event){_vm.ageSelected === '24' ? (_vm.ageSelected = null) : (_vm.ageSelected = '24')}}},[_vm._v(" 21-24 ")]),_c('div',{staticClass:"w-12 h-8 rounded-md flex justify-center m-2 shadow-md align-center transform cursor-pointer transition-all duration-500 hover:-translate-y-1 hover:scale-105",style:(`background-color: ${
        _vm.ageSelected == '100' ? _vm.colors.colorPrimary : _vm.colors.colorSurfaceMedium
      }; color: ${
        _vm.ageSelected === '100'
          ? _vm.colors.colorOnPrimary
          : _vm.colors.colorOnSurfaceMedium
      };`),on:{"click":function($event){_vm.ageSelected === '100' ? (_vm.ageSelected = null) : (_vm.ageSelected = '100')}}},[_vm._v(" +25 ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }