<template>
  <div
    class="ReservationCatalogue flex flex-row lg:px-4 lg:pt-4"
    :class="showLogoAndVersion ? 'relative' : 'fixed'"
    id="top-part"
  >
    <div class="w-full lg:w-9/12 mb-16">
      <F2gFWCustomStepper
        ref="stepper"
        :stepList="stepList"
        :currentStep="currentStep"
        :loading="loading"
        :servicesValidation="servicesValidation"
        :showNextStep="showNextStep"
        :isCatalogue="true"
        @currentStep="eventCurrentStep"
        @showExtras="showExtras = true"
        @search="setQuery"
      >
        <template v-slot:content1>
          <div class="lg:max-max-h-full px-4">
            <extras-list
              ref="extrasList"
              :showExtras="showExtras"
              @validation="validateExtras"
              @servicesValidation="
                (validation) => (servicesValidation = validation)
              "
            />
            <div class="mt-12 mx-2">
              <data-card
                ref="dataCard"
                @type="setTypeUser"
                @validation="loginValidation"
                @showConfirmation="(show) => (showConfirmation = show)"
                @nextStep="eventCurrentStep(2)"
                @showButtonNextStep="showNextStep = true"
                @hideButtonNextStep="showNextStep = false"
              />
            </div>
            <div v-if="showNextStep" class="w-full flex px-2 justify-center">
              <v-btn
                class="my-2 w-full border-2 rounded-config30"
                :style="{
                  backgroundColor: colors.colorPrimary,
                  color: colors.colorOnPrimary,
                }"
                :disabled="!servicesValidation"
                @click="eventCurrentStep(2)"
              >
                {{ returnLabelButton() }}
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:content2>
          <div class="lg:max-max-h-full min-h-2/3 px-4">
            <confirm-data
              ref="confirmData"
              @validateConfirm="validateConfirm"
            />
            <div class="w-full flex px-2 justify-center">
              <v-btn
                class="my-2 w-full border-2 rounded-config30"
                :style="{
                  backgroundColor: colors.colorPrimary,
                  color: colors.colorOnPrimary,
                }"
                :loading="loading"
                @click="eventCurrentStep(3)"
              >
                {{ returnLabelButton() }}
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:content3>
          <div class="lg:max-max-h-full min-h-3/4 px-4">
            <div class="min-h-2/3"><pay-card /></div>
          </div>
        </template>
      </F2gFWCustomStepper>
    </div>
    <div
      class="fixed top-6 w-3/12 px-0 right-2 max-h-full"
      v-if="
        !(
          $vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'
        )
      "
    >
      <Resume
        :currentStep="currentStep"
        :loading="loading"
        :categoryDetail="showCategorySelected"
        :currentCategory="categorySelected"
        :servicesValidation="servicesValidation"
        :extrasValidation="extrasValidation"
        :showNextStep="showNextStep"
        :isCatalogue="true"
        @showExtras="showExtras = true"
        @currentStep="
          (value) => {
            hideDialogExtras = true;
            eventCurrentStep(value);
          }
        "
        @selected="selectCategory"
      />
    </div>
    <div
      v-if="showLogoAndVersion"
      :style="{ backgroundColor: colors.colorBackground }"
      class="flex bottom-0 align-end absolute"
      :class="
        $vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'
          ? 'w-full'
          : 'width73'
      "
    >
      <div class="version mt-4 ml-6">v.{{ version }}</div>
      <div class="version mt-4 ml-1">
        developed by
        <a
          href="https://flit2go.com/"
          target="_blank"
          :style="{ color: colors.colorPrimary }"
          >Flit2go technology</a
        >
      </div>
      <!-- <img
        class="flit2go-logo z-5 mr-8"
        width="100"
        height="40"
        src="@/flit2go-web-core/assets/powered-new-logo.svg"
        alt="f2gLogo"
      /> -->
    </div>

    <v-dialog
      :class="'rounded-t-xl'"
      transition="dialog-bottom-transition"
      :value="showModalExtras"
      :maxWidth="800"
      :style="`backgroundColor:${colors.colorSurface} !important`"
    >
      <template>
        <v-card
          class="max-h-full bg-white backdrop-blur-xl overflow-y-auto rounded-lg"
          :style="`backgroundColor:${colors.colorSurface} !important`"
        >
          <v-card-title
            class="text-h5 mb-2"
            id="dialog-extras"
            :style="{
              color: colors.colorOnSurface,
            }"
          >
            <F2gIcon
              class="mr-2"
              :value="'warning'"
              :height="30"
              :width="30"
              :stroke="colors.colorOnSurface"
              :strokeWidth="3"
            />
            {{ $t("reviewExtras") }}
          </v-card-title>
          <v-card-text>
            <div
              class="absolute flex flex-row justify-end align-end mt-2 mr-2 top-0 right-0 z-50"
            >
              <v-btn icon @click="showModalExtras = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div
              class="flex flex-row align-center"
              :style="{
                color: colors.colorOnSurface,
              }"
            >
              <v-layout column class="mb-4">
                {{ $t("reviewExtrasQuestion") }}
              </v-layout>
            </div>

            <div
              class="flex align-end justify-end"
              :class="{
                'flex-col': $vuetify.breakpoint.name === 'xs',
              }"
            >
              <v-btn
                outlined
                class="rounded"
                :class="{
                  'mb-2': $vuetify.breakpoint.name === 'xs',
                }"
                :color="colors.colorPrimary"
                @click="
                  hideDialogExtras = true;
                  eventCurrentStep(2);
                "
                >{{ $t("dontNeedAnything") }}</v-btn
              >
              <v-btn
                :color="colors.colorPrimary"
                class="rounded"
                :class="{
                  'ml-2': $vuetify.breakpoint.name !== 'xs',
                }"
                :style="'color: ' + colors.colorOnPrimary"
                @click="
                  showModalExtras = false;
                  showExtras = true;
                "
                >{{ $t("showExtras") }}</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import ExtrasList from "@/modules/v2/Components/extras-list";
import DataCard from "@/components/data-card";
import PayCard from "@/components/pay-card";
import ConfirmData from "@/components/confirm-data";
import Resume from "@/modules/v2/Components/resume";
import F2gFWCustomStepper from "@/modules/v2/Components/f2gFW-custom-stepper";
import { mapGetters, mapState } from "vuex";
import { EventBus } from "@/flit2go-web-core/util/event-bus.js";
import DateUtil from "@/flit2go-web-core/util/dateUtil";

export default {
  name: "ReservationCatalogue",
  components: {
    Resume,
    F2gFWCustomStepper,
    ExtrasList,
    DataCard,
    PayCard,
    ConfirmData,
  },
  data() {
    return {
      currentStep: 1,
      version: process.env.PACKAGE_VERSION || "0",
      loading: false,
      search: "",
      showExtras: false,
      stepList: [
        {
          id: 1,
          label: this.$t("common.extras"),
          ref: "extras",
          valid: false,
          visited: false,
        },
        {
          id: 2,
          label: this.$t("issueActions.RES"),
          ref: "confirm",
          valid: false,
          visited: false,
        },
        {
          id: 3,
          label: this.$t("common.payment"),
          ref: "payment",
          valid: false,
          visited: false,
        },
      ],
      typeUser: null,
      showConfirmation: false,
      showCategorySelected: false,
      categorySelected: null,
      servicesValidation: true,
      showNextStep: false,
      extrasValidation: false,
      hideDialogExtras: false,
      showModalExtras: false,
      showLogoAndVersion: true,
      validateConfirmData: false,
    };
  },
  watch: {
    showModalExtras() {
      this.goToTop();
    },
    currentStep() {
      window.parent.postMessage("manualResize", "*");
    },
  },
  computed: {
    ...mapState(["configuration"]),
    ...mapGetters({
      categoriesList: "category/categoriesList",
      bookingFilters: "configuration/getBookingFilters",
      colors: "configuration/getColors",
      vehiclesFilters: "configuration/getVehiclesFilter",
      getcategorySelected: "category/categorySelected",
      filters: "configuration/getFilters",
      loginParameters: "user/loginParameters",
      gtagCode: "configuration/getGtagCode",
    }),
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);

    EventBus.$on("changeDates", () => {
      this.showCategorySelected = false;
    });
    EventBus.$on("hideDialogExtras", () => {
      this.hideDialogExtras = true;
    });
    EventBus.$on("tailOpen", () => {
      this.showLogoAndVersion = false;
    });
    EventBus.$on("tailClose", () => {
      this.showLogoAndVersion = true;
    });
    EventBus.$on("goToEndBooking", () => {
      if (this.gtagCode) {
        this.$gtag.event("confirmation", {
          event_name: "confirmation",
        });
      }
      this.sendHubspotEndpoint(
        this.$CONSTANTS.SETTINGS.STATUS.FRAME_WEB_CONFIRMATION
      );
    });
    EventBus.$on("doLogout", () => {
      if (this.$refs.dataCard) {
        this.$refs.dataCard.clear();
      }
      this.eventCurrentStep(1);
      this.showNextStep = false;
    });
  },
  mounted() {
    EventBus.$on("goStep1", () => {
      this.showCategorySelected = false;
      this.eventCurrentStep(1);
    });
    window.parent.postMessage("manualResize", "*");
    this.categorySelected = this.getcategorySelected;
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    sendHubspotEndpoint(status) {
      if (
        this.configuration.trackNumberHubSpot ||
        this.configuration.clientifyActived
      ) {
        let params = {
          idStage: status,
          data: {
            category: this.categorySelected.name,
            initialDate: DateUtil.formatCustomDate(
              this.bookingFilters.pickUpDate
            ),
            finishDate: DateUtil.formatCustomDate(
              this.bookingFilters.returnBackDate
            ),
            initial:
              this.bookingFilters.pickUpNameBranch +
              " " +
              DateUtil.formatCustomDate(this.bookingFilters.pickUpDate),
            finish:
              this.bookingFilters.returnBackNameBranch +
              " " +
              DateUtil.formatCustomDate(this.bookingFilters.returnBackDate),
            franchise: this.franchiseSelected
              ? this.franchiseSelected.message
              : null,
            extras: this.extrasSelected ? this.getExtras() : null,
            servicesAdditional: this.additionalsSelected
              ? this.getAdditionalServices()
              : null,
            amount: this.actualPrice ? this.getActualPrice() : null,
            username: this.userLoged ? this.userLoged.username : null,
            currency: this.filters.currencyCode
              ? this.filters.currencyCode
              : null,
          },
          params: {
            ApiKey: this.$route.query.apiKey,
          },
          access_token: this.loginParameters.access_token,
        };
        this.$store.dispatch("configuration/sendHubspotEndpoint", params);
      }
    },
    setQuery(query) {
      this.search = query.toLowerCase();
    },
    returnLabelButton() {
      switch (this.currentStep) {
        case 1: {
          return this.$t("common.continue");
        }
        case 2: {
          if (
            this.categorySelected.deferredPayment &&
            this.categorySelected.deferredStartRental &&
            !this.categorySelected.earlyPay &&
            Number(this.categorySelected.deferredPaymentPercentageFirst) === 0
          ) {
            return this.$t("common.continue");
          } else {
            return this.$t("button.goToPay");
          }
        }
        default: {
          return "";
        }
      }
    },
    eventCurrentStep(currentStep) {
      if (currentStep == 1) {
        this.currentStep = currentStep;
      }
      if (currentStep == 2) {
        if (this.$refs && this.$refs.extrasList) {
          this.$refs.extrasList.validate();
        }
        if (this.servicesValidation) {
          if (
            this.hideDialogExtras ||
            (!this.categorySelected.additionalServicesList.length &&
              !this.categorySelected.extraList.length)
          ) {
            this.goToTop();
            this.showModalExtras = false;
            this.currentStep = currentStep;
            this.loading = false;
            if (this.gtagCode) {
              this.$gtag.event("summary", {
                event_name: "summary",
              });
            }
            this.sendHubspotEndpoint(
              this.$CONSTANTS.SETTINGS.STATUS.FRAME_WEB_LOGIN
            );
          } else {
            this.showModalExtras = true;
          }
        }
      }
      if (currentStep == 3) {
        if (this.$refs && this.$refs.confirmData) {
          this.$refs.confirmData.validateData();
        }
        if (this.validateConfirmData) {
          this.goToTop();
          this.currentStep = currentStep;
          if (this.gtagCode) {
            this.$gtag.event("payment_process", {
              event_name: "payment_process",
            });
          }
          this.sendHubspotEndpoint(
            this.$CONSTANTS.SETTINGS.STATUS.FRAME_WEB_PENDING_PAYMENT
          );
        }
      }
      if (this.currentStep > currentStep) {
        this.currentStep = currentStep;
      }
    },
    setTypeUser(userType) {
      this.typeUser = userType;
    },
    validateExtras(extras) {
      this.extrasValidation = extras.valid;
      if (extras.valid) {
        if (this.$refs && this.$refs.dataCard) {
          this.$refs.dataCard.validateTextField();
        }
      }
    },
    loginValidation(login) {
      this.loading = false;
      this.showNextStep = false;
      if (login.valid) {
        this.showNextStep = true;
      }
    },
    selectCategory(value) {
      this.categorySelected.earlyPay = value;
      this.$store.commit("category/SET_CATEGORY", this.categorySelected);
      this.$store.commit("configuration/SET_EXTRAS_SELECTED", null);
      EventBus.$emit("getPrice");
      this.eventCurrentStep(2);
    },
    goToTop() {
      window.parent.postMessage("goToTop", "*");
    },
    validateConfirm(value) {
      this.validateConfirmData = value.valid;
    },
  },
};
</script>
<style scoped lang="scss">
.fade-enter-active {
  transition: opacity 1.2s;
}
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 1.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(1300px);
  opacity: 0;
}
.version {
  color: $gray-1-f2g;
}
.width73 {
  width: 73%;
  padding-right: 5px;
}
</style>
