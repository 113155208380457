export default Object.freeze({
  GREEN_1_F2G: "#1c8523",
  GREEN_2_F2G: "#42a549",
  GREEN_3_F2G: "#3bcd92",
  GREEN_4_F2G: "#1c852380",
  GREEN_5_F2G: "#334532",
  GREEN_6_F2G: "#14892c",
  BLACK_1_F2G: "#202020",
  BLACK_2_F2G: "#8e0000",
  BLACK_3_F2G: "#000000",
  BLUE_1_F2G: "#1976d2",
  BLUE_2_F2G: "#2196f3",
  BLUE_3_F2G: "#3bcdf2",
  BLUE_4_F2G: "#d4fbff",
  BLUE_5_F2G: "#2196f3cc",
  BLUE_6_F2G: "#12cad2",
  DARK_BLUE: "#3a2c60",
  YELLOW_1_F2G: "#f5b540",
  YELLOW_2_F2G: "#ffd359",
  RED_1_F2G: "#d32f2f",
  RED_2_F2G: "#f44336",
  RED_3_F2G: "#d04437",
  RED_4_F2G: "#ba000d",
  RED_5_F2G: "#ef5350",
  LIGHT_RED: "#fbe7e8",
  RED_ERROR: "#b71c1c",
  RED_ERROR_VUETIFY: "#ff5252",
  DARK_GRAY_1_F2G: "#808080",
  GRAY_1_F2G: "#777",
  GRAY_2_F2G: "#464343",
  GREY_3_F2G: "#9e9e9e",
  GREY_4_F2G: "#0000008a",
  GREY_5_F2G: "#c2c2c2",
  GREY_6_F2G: "#707b7c",
  GREY_7_F2G: "#777777",
  GREY_8_F2G: "#d3d3d3",
  GREY_DISABLED: "#00000060",
  LIGHT_GRAY: "#b0bec5",
  LIGHT_GRAY_2: "#eeeeee",
  LIGHT_GRAY_3: "#ccc",
  LIGHT_GRAY_4: "#ddd",
  RED_DARK: "#8e0000",
  ORANGE_1_F2G: "#F59217",
  PURPLE: "#3b088f",
  WARNING: "#f592171A",
  ISSUE_LIGHT_ORANGE: "#F1892D14",
  ISSUE_DARK_ORANGE: "#F1892D",
  ISSUE_LIGHT_BLUE: "#1976D214",
  ISSUE_DARK_BLUE: "#1976D2",
  ISSUE_LIGHT_GREEN: "#1C85231A",
  ISSUE_DARK_GREEN: "#1C8523",
  LIGHTEN_GRAY: "#f5f5f5",
});
