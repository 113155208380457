<template>
  <div class="category-details">
    <div
      class="max-h-full backdrop-blur-xl overflow-y-auto"
      :class="fullscreen ? 'rounded-t-xl' : 'rounded-lg'"
    >
      <div
        class="flex flex-row mt-8 items-center align-center justify-center w-full"
      >
        <div class="absolute left-0 flex flex-row align-center ml-8">
          <div
            class="p-1 rounded-full shadow mr-2 cursor-pointer"
            :style="{ backgroundColor: colors.colorSurface }"
          >
            <F2gIcon
              class="inline-block align-middle"
              :stroke="colors.colorPrimary"
              :value="'arrow-left'"
              :height="28"
              :width="28"
              :strokeWidth="4"
              @click="$emit('close')"
            />
          </div>
          <div
            class="text-sm"
            v-if="!fullscreen"
            :style="{ color: colors.colorPrimary }"
            @click="$emit('close')"
          >
            {{ $t("returnToVehicles") }}
          </div>
        </div>
        <h1
          class="text-lg md:text-2xl font-bold ml-2 inline-block items-center align-center cursor-pointer"
          :style="{ color: colors.colorOnSurfaceMedium }"
          @click="$emit('close')"
        >
          {{ categoryRow.name }}
        </h1>
      </div>
      <div class="flex align-center justify-center w-full flex-col">
        <div
          class="bg-gray-200 max-h-full relative mt-4 rounded-xl mx-2 w-4/6"
          v-show="showCarousel"
        >
          <Carousel
            :images="images"
            :showPagination="false"
            :showFooter="false"
            @mounted="mountedCarousel"
          >
          </Carousel>
        </div>
        <div
          v-if="!showCarousel"
          class="bg-gray-200 max-h-full relative mt-4 rounded-xl mx-2 w-4/6"
        >
          <v-img
            :src="categoryRow.img"
            :aspect-ratio="16 / 9"
            class="image rounded-xl"
          />
        </div>
        <div class="w-full pl-2 flex flex-row align-center flex-wrap ml-4">
          <div
            class="inline-block mr-2 mt-4"
            v-for="(attribute, index) in categoryRow.attributeList"
            :key="index"
          >
            <div
              class="text-sm align-middle mr-2 inline-block rounded-md flex-none"
              :style="{
                backgroundColor: colors.colorSurfaceMedium,
                color: colors.colorPrimary,
              }"
            >
              <div class="pa-1 m-1 inline-block align-middle">
                <svg
                  style="height: 24px; width: 24px"
                  :fill="colors.colorPrimary"
                >
                  <path v-if="attribute.icon.path" :d="attribute.icon.path" />
                </svg>
              </div>
              <div
                class="align-center inline-block align-middle mr-3"
                :style="{ color: colors.colorOnSurfaceMedium }"
              >
                {{
                  attribute.supportedLangs[$i18n.locale.split("_")[0]]
                    ? attribute.supportedLangs[$i18n.locale.split("_")[0]]
                    : attribute.supportedLangs.en
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="m-2 pt-4">
        <div
          class="rounded-2xl p-2 mb-4"
          :style="{
            backgroundColor: colors.colorSurface,
            color: colors.colorOnSurface,
          }"
        >
          <span class="font-bold text-lg">{{ $t("common.description") }}</span>
          <div
            v-if="categoryRow.description"
            class="mt-4 text-sm overflow-clip overflow-hidden"
            :style="{
              color: colors.colorOnSurface,
            }"
            v-html="categoryRow.description[$i18n.locale.split('_')[0]]"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import Carousel from "@/flit2go-web-core/components/base/carousel";

export default {
  name: "CategoryDetails",
  components: { Carousel },
  props: {
    categoryRow: { required: true, type: Object, default: () => [] },
    fullscreen: { required: false, type: Boolean, default: true },
  },
  data() {
    return {
      loading: true,
      showDetails: false,
      carouselPosition: 0,
      showModalEdit: false,
      showCarousel: false,
    };
  },
  computed: {
    ...mapState(["category", "configuration"]),
    ...mapGetters({
      colors: "configuration/getColors",
    }),
    images() {
      return [this.categoryRow.img].concat(this.categoryRow.image);
    },
  },
  methods: {
    mountedCarousel() {
      this.showCarousel = true;
    },
  },
};
</script>

<style lang="scss">
.v-dialog--active {
  border-radius: 24px !important;
}
.v-dialog--fullscreen {
  height: 90% !important;
  bottom: 0 !important;
  top: auto !important;
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.errorText {
  color: #b71c1c !important;
  font-size: 1.25rem;
}
</style>
