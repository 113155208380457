import service from "@/services/branchService";

export default {
  namespaced: true,
  state: {
    status: "",
    branchesList: null,
  },

  getters: {
    status: (state) => {
      return state.status;
    },
    branchesList: (state) => {
      return state.branchesList;
    },
  },

  mutations: {
    // Status
    STATUS_LOADING: (state) => {
      state.status = "loading";
    },
    STATUS_SUCCESS: (state) => {
      state.status = "success";
    },
    STATUS_ERROR: (state) => {
      state.status = "error";
    },
    ["SET_BRANCHES_LIST"]: (state, list) => {
      state.branchesList = list;
    },
  },

  actions: {
    getAvailableBranches({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getAvailableBranches(params)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            commit("SET_BRANCHES_LIST", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getBranchById({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getBranchById(id)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp.data);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },

    async getBranchSpecialDays({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getBranchSpecialDays(id)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp.data);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
  },
};
