const INDEMNIZATION_PAYMENT_TYPE = Object.freeze({
    "APP": "APP",
    "CASH": "MON"
});

const INDEMNIZATION_STATUS = Object.freeze({
    "DISMISSED": "DIS",
    "OUTSTANDING": "OUT",
    "PAID" :"PAY",
    "LOCAL_OUTSTANDING": "PPL",
    "RESORTED": "RES"
});

const INDEMNIZATION_TYPE = Object.freeze({
    "DAMAGE": "DAM",
    "DIRT": "DIR",
    "OTHERS" :"OTH",
    "SAN": "SANCTION",
    "RESORTED": "RES"
});

export default { 
    PAYMENT_TYPE: INDEMNIZATION_PAYMENT_TYPE,
    STATUS: INDEMNIZATION_STATUS,
    TYPE: INDEMNIZATION_TYPE
}