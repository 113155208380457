// UploadImgS3 file

import axios from "axios";

export default function (url, img, image = true) {
  return new Promise((resolve, reject) => {
    const axiosInstance = axios.create({ baseURL: url });
    const headers = {
      "Content-Type": image ? "image/png" : "application/pdf",
    };

    if (
      process.env.VUE_APP_MODE &&
      ("pre-production" === process.env.VUE_APP_MODE ||
        "production" === process.env.VUE_APP_MODE)
    ) {
      headers["x-amz-acl"] = "bucket-owner-full-control";
    }
    axiosInstance({
      method: "PUT",
      headers: headers,
      data: img,
    })
      .then((resp) => {
        resolve(resp);
      })
      .catch(() => {
        reject();
      });
  });
}
