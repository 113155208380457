const OWNER_TYPE = Object.freeze({
  "PREMIUM": "PRE",
  "PLATFORM": "PLA",
  "PLATFORM_PREMIUM": "PMP"
});

const OWNER_BILLING_TYPE = Object.freeze({
  "INTERNAL": "INT",
  "EXTERNAL": "EXT"
});

const OWNER_BILLING_PERIOD = Object.freeze({
  "ONE_MONTH": "MO1"
});

const VALIDATION_TYPE = Object.freeze({
  "VALIDATION_F2G": "VFG",
  "WITHOUT_VALIDATION": "WTV",
});

const PRIVACY = Object.freeze({
  "VISIBLE": "VIS",
  "PRIVATE": "PRI",
});

export default Object.freeze({
    TYPE: OWNER_TYPE,
    BILLING_TYPE: OWNER_BILLING_TYPE,
    BILLING_PERIOD: OWNER_BILLING_PERIOD,
    VALIDATION_TYPE: VALIDATION_TYPE,
    PRIVACY: PRIVACY,
});
