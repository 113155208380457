<template>
  <div
    class="overflow-y-scroll fixed w-screen h-screen p-5 bg-opacity-30 top-0 left-0 transform transition-all"
    :class="{
      'z-10 bg-gray-800 scale-100': show,
      '-z-10 bg-gray-100 scale-0': !show,
    }"
  >
    <div class="flex max-h-full">
      <div
        class="mx-auto mb-auto flex top-30 bg-gray-100 rounded shadow-xl transform transition-all duration-500"
        :class="{ 'scale-100': show, 'scale-0': !show }"
        :style="{ maxWidth: width + 'px' }"
      >
        <div
          class="border cursor-pointer absolute -top-3 -right-3 rounded-full transition-all"
          :style="{ backgroundColor: closeButtonBackgroundColor }"
          @click="closeTail"
        >
          <F2gIcon
            value="close"
            :height="26"
            :width="26"
            :stroke="closeButtonLogoColor"
            :strokeWidth="4"
          />
        </div>
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/flit2go-web-core/util/event-bus.js";
export default {
  name: "TailLoader",
  props: {
    backgroundColor: { default: "white", required: false, type: String },
    closeButtonBackgroundColor: {
      default: "white",
      required: false,
      type: String,
    },
    closeButtonLogoColor: { default: "black", required: false, type: String },
    show: { default: false, required: false, type: Boolean },
    width: { default: 600, required: false, type: Number },
  },
  watch: {
    show() {
      if (this.show) {
        EventBus.$emit("tailOpen");
      }
    },
  },
  methods: {
    closeTail() {
      EventBus.$emit("tailClose");
      this.$emit("close");
    },
  },
};
</script>
