/* eslint-disable no-async-promise-executor */
import service from "@/flit2go-web-core/services/";
import * as Sentry from "@sentry/browser";
import encryptStorage from "@/flit2go-web-core/util/encryptUtil";

export default {
  namespaced: true,
  state: {
    userProfile: JSON.parse(localStorage.getItem("user-profile")) || {},
    userRole: encryptStorage.getItem("user-role") || [],
    globalOwner: JSON.parse(localStorage.getItem("global-owner")) || {},
    ownerColors: {},
    ownerToolbarColors: {},
    status: "",
    lastRouteVisited: "",
    multiOwner: null,
  },
  getters: {
    isAuthenticated: (state) => Object.entries(state.userProfile).length !== 0,
    user: (state) => state.userProfile.ownerPerson,
    getUserAuthority: (state) => {
      if (
        state.userProfile &&
        Object.prototype.hasOwnProperty.call(state.userProfile, "authorities")
      ) {
        return state.userProfile.authorities[0];
      } else {
        return "";
      }
    },
    getUserProfile: (state) => state.userProfile,
    getUserRole: (state) => state.userRole,
    getGlobalOwner: (state) => state.globalOwner,
    getOwnerColors: (state) => state.ownerColors,
    getOwnerToolbarColors: (state) => state.ownerToolbarColors,
    getTimeZone: (state) =>
      state.userProfile.ownerPerson
        ? state.userProfile.ownerPerson.timeZone
        : "Europe/Madrid",
    getLanguage: (state) => state.userProfile.language,
    isSuperOwner: (state) =>
      Object.keys(state.userProfile).length === 0
        ? false
        : state.userProfile.ownerPerson
        ? state.userProfile.ownerPerson.superOwner
        : false,
    isAggregator: (state) =>
      Object.keys(state.userProfile).length === 0
        ? false
        : state.userProfile.ownerPerson
        ? state.userProfile.ownerPerson.aggregator
        : false,
    isWithoutApp: (state) =>
      Object.keys(state.userProfile).length === 0
        ? false
        : state.userProfile.ownerPerson
        ? state.userProfile.ownerPerson.withoutApp
        : false,
    isOwnerPremium: (state) =>
      Object.keys(state.userProfile).length === 0
        ? false
        : state.userProfile.ownerPerson
        ? state.userProfile.ownerPerson.premium
        : false,
    isOwnerMarketplace: (state) =>
      Object.keys(state.userProfile).length === 0
        ? false
        : state.userProfile.ownerPerson
        ? state.userProfile.ownerPerson.marketplace
        : false,
    isOwnerDealer: (state) =>
      Object.keys(state.userProfile).length === 0
        ? false
        : state.userProfile.ownerPerson
        ? state.userProfile.ownerPerson.dealer
        : false,
    isOwnerFleet: (state) =>
      Object.keys(state.userProfile).length === 0
        ? false
        : state.userProfile.ownerPerson
        ? state.userProfile.ownerPerson.control
        : false,
    isOwnerSubscription: (state) =>
      Object.keys(state.userProfile).length === 0
        ? false
        : state.userProfile.ownerPerson
        ? state.userProfile.ownerPerson.subscription
        : false,
    isOwnerOnlyDealer: (state) =>
      Object.keys(state.userProfile).length === 0
        ? false
        : state.userProfile.ownerPerson
        ? state.userProfile.ownerPerson.dealer &&
          !state.userProfile.ownerPerson.premium &&
          !state.userProfile.ownerPerson.marketplace
        : false,
    isOwnerOnlyMarkeplace: (state) =>
      Object.keys(state.userProfile).length === 0
        ? false
        : state.userProfile.ownerPerson
        ? !state.userProfile.ownerPerson.dealer &&
          !state.userProfile.ownerPerson.premium &&
          state.userProfile.ownerPerson.marketplace
        : false,
    isOwnerOnlyPremium: (state) =>
      Object.keys(state.userProfile).length === 0
        ? false
        : state.userProfile.ownerPerson
        ? !state.userProfile.ownerPerson.dealer &&
          !state.userProfile.ownerPerson.marketplace &&
          state.userProfile.ownerPerson.premium
        : false,
    getRowsPerPage: (state) =>
      state.userProfile.numberResults === null ||
      state.userProfile.numberResults === 0
        ? 10
        : state.userProfile.numberResults,
    getMultiOwner: (state) => state.multiOwner,
  },
  mutations: {
    ["AUTH_LOADING"]: (state) => {
      state.status = "loading";
    },
    ["AUTH_DELETE_GLOBAL_OWNER"]: (state) => {
      state.globalOwner = {};
    },
    ["AUTH_SET_OWNER_COLORS"]: (state, ownerColors) => {
      state.ownerColors = ownerColors;
    },
    ["AUTH_SET_OWNER_TOOLBAR_COLORS"]: (state, ownerToolbarColors) => {
      state.ownerToolbarColors = ownerToolbarColors;
    },
    ["AUTH_ERROR"]: (state) => {
      state.status = "error";
    },
    ["AUTH_SUCCESS"]: (state) => {
      state.status = "success";
    },
    ["AUTH_SET_GLOBAL_OWNER"]: (state, globalOwner) => {
      state.globalOwner = globalOwner;
    },
    ["AUTH_SET_PROFILE"]: (state, userProfile) => {
      state.userProfile = userProfile;
    },
    ["AUTH_SET_PROFILE_PHONE"]: (state, phoneNumber) => {
      state.userProfile.phoneNumber = phoneNumber;
    },
    ["AUTH_SET_PROFILE_MFA"]: (state, mfaStatus) => {
      state.userProfile.mfa = mfaStatus;
    },
    ["SET_NUMBER_RESULTS"]: (state, number) => {
      state.userProfile.numberResults = number;
    },
    ["SET_LANGUAGE"]: (state, language) => {
      state.userProfile.language = language;
    },
    ["SET_SETTING_ATTRIBUTE"]: (state, data) => {
      state.userProfile.settings[data.attr] = data.value;
    },
    ["SET_LAST_ROUTE_VISITED"]: (state, route) => {
      state.lastRouteVisited = route;
    },
    ["SET_OWNER_LOGO"]: (state, logo) => {
      state.userProfile.ownerPerson.logo = logo;
    },
    ["SET_ROLE"]: (state, role) => {
      state.userRole = role;
    },
    ["AUTH_SET_MULTIOWNER"]: (state, multiOwner) => {
      state.multiOwner = multiOwner;
    },
  },
  actions: {
    ["AUTH_LOGIN"]: ({ commit, dispatch }, userCredentials) => {
      return new Promise((resolve, reject) => {
        commit("AUTH_LOADING");
        // Prepare userCredentials params
        let params = new URLSearchParams();
        params.append("username", userCredentials.email);
        params.append("password", userCredentials.password);
        params.append("MFA", userCredentials.mfaToken);
        // Call to backend
        service.auth
          .login(params)
          .then((response) => {
            service.setToken(response.data.access_token);
            dispatch("AUTH_CHECK_TOKEN", response.data.access_token)
              .then(() => {
                localStorage.setItem("user-token", response.data.access_token);
                commit("AUTH_SUCCESS");
                resolve(response);
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            commit("AUTH_ERROR");
            reject(err);
          });
      });
    },
    ["AUTH_CHECK_TOKEN"]: ({ commit, dispatch }, token) => {
      return new Promise(async (resolve, reject) => {
        commit("AUTH_LOADING");
        try {
          let response = await service.auth.check_token(token);
          service.setToken(token);
          let user = await service.auth.personByUsername(
            response.data.user_name
          );

          // Sending Sentry user's username to identify errors
          if (
            process.env.NODE_ENV === "desa" ||
            process.env.NODE_ENV === "production"
          ) {
            Sentry.setUser({ email: user.data.username });
            //  this.$gtag.set({
            //  'userId':  user.data.id
            //})
            //this.$gtag.config({ user_id: user.data.id.toString() })
          }
          // Revoke access to intranet zone
          if (
            process.env.VUE_APP_TITLE == "Intranet" &&
            response.data.authorities[0] != "SUPER_ADMIN" &&
            response.data.authorities[0] != "EMPLOYEE"
          ) {
            throw "Access denied for this role";
          }
          if (
            process.env.VUE_APP_TITLE == "Manager" &&
            (response.data.authorities[0] == "SUPER_ADMIN" ||
              response.data.authorities[0] == "EMPLOYEE")
          ) {
            throw "Access denied for this role";
          }
          user.data.authorities = response.data.authorities;

          let ownerColors;
          let ownerToolbarColors;
          if (process.env.VUE_APP_TITLE == "Intranet") {
            let settings = await service.settings.getUserSettings();
            user.data.settings = settings.data;
            ownerColors = {
              ownerPrimaryColor: "#1c8523",
              ownerOnPrimaryColor: "#ffffff",
              ownerHeaderColor: "#334532",
              ownerOnHeaderColor: "#eeeeee",
              ownerSurfaceColor: "#495848",
              ownerOnSurfaceColor: "#eeeeee",
              ownerSecondary: "#eeeeee",
              ownerOnSecondary: "#495848",
              ownerNotificationDotColor: "#3bcdf2",
            };
            ownerToolbarColors = {
              ownerPrimaryColor: "#1c8523",
              ownerOnPrimaryColor: "#ffffff",
              ownerHeaderColor: "#334532",
              ownerOnHeaderColor: "#eeeeee",
              ownerSurfaceColor: "#495848",
              ownerOnSurfaceColor: "#eeeeee",
              ownerSecondary: "#eeeeee",
              ownerOnSecondary: "#495848",
              ownerNotificationDotColor: "#3bcdf2",
              ownerAppIcon: "@/flit2go-web-core/assets/logo_simple_b_n.svg",
              ownerLogo: "@/flit2go-web-core/assets/flit2goLogo.svg",
            };
            commit("AUTH_SET_OWNER_TOOLBAR_COLORS", ownerToolbarColors);
            commit("AUTH_SET_OWNER_COLORS", ownerColors);
          } else if (process.env.VUE_APP_TITLE == "Manager") {
            let settings = await service.settings.getUserSettings();
            user.data.settings = settings.data;
            ownerToolbarColors = {
              ownerPrimaryColor: user.data.ownerPerson.colorPrimary,
              ownerOnPrimaryColor: user.data.ownerPerson.colorOnPrimary,
              ownerSecondaryColor: user.data.ownerPerson.colorSecondary,
              ownerOnSecondaryColor: user.data.ownerPerson.colorOnSecondary,
              ownerHeaderColor: user.data.ownerPerson.colorHeader,
              ownerOnHeaderColor: user.data.ownerPerson.colorOnHeader,
              ownerSurfaceColor: user.data.ownerPerson.colorSurface,
              ownerOnSurfaceColor: user.data.ownerPerson.colorOnSurface,
              ownerNotificationDotColor: user.data.ownerPerson.colorPrimary,
              ownerAppIcon: user.data.ownerPerson.appIcon,
              ownerLogo: user.data.ownerPerson.logo,
              personalizationContent:
                user.data.settings.showColorPersonalizationContent,
              personalizationToolbar: user.data.settings.colorPersonalization,
            };
            if (
              !user.data.settings.showColorPersonalizationContent &&
              !user.data.settings.colorPersonalization
            ) {
              ownerColors = {
                ownerPrimaryColor: "#1c8523",
                ownerOnPrimaryColor: "#ffffff",
                ownerHeaderColor: "#334532",
                ownerOnHeaderColor: "#eeeeee",
                ownerSurfaceColor: "#495848",
                ownerOnSurfaceColor: "#eeeeee",
                ownerSecondary: "#eeeeee",
                ownerOnSecondary: "#495848",
                ownerNotificationDotColor: "#3bcdf2",
                ownerAppIcon: "@/flit2go-web-core/assets/logo_simple_b_n.svg",
                ownerLogo: "@/flit2go-web-core/assets/flit2goLogo.svg",
                personalizationContent:
                  user.data.settings.showColorPersonalizationContent,
                personalizationToolbar: user.data.settings.colorPersonalization,
              };
            } else if (
              (user.data.settings.showColorPersonalizationContent &&
                !user.data.settings.colorPersonalization) ||
              (user.data.settings.showColorPersonalizationContent &&
                user.data.settings.colorPersonalization)
            ) {
              ownerColors = {
                ownerPrimaryColor: user.data.ownerPerson.colorPrimary,
                ownerOnPrimaryColor: user.data.ownerPerson.colorOnPrimary,
                ownerSecondaryColor: user.data.ownerPerson.colorSecondary,
                ownerOnSecondaryColor: user.data.ownerPerson.colorOnSecondary,
                ownerHeaderColor: user.data.ownerPerson.colorHeader,
                ownerOnHeaderColor: user.data.ownerPerson.colorOnHeader,
                ownerSurfaceColor: user.data.ownerPerson.colorSurface,
                ownerOnSurfaceColor: user.data.ownerPerson.colorOnSurface,
                ownerNotificationDotColor: user.data.ownerPerson.colorPrimary,
                personalizationContent:
                  user.data.settings.showColorPersonalizationContent,
                personalizationToolbar: user.data.settings.colorPersonalization,
              };
            } else if (
              !user.data.settings.showColorPersonalizationContent &&
              user.data.settings.colorPersonalization
            ) {
              ownerColors = {
                ownerPrimaryColor: "#1c8523",
                ownerOnPrimaryColor: "#ffffff",
                ownerHeaderColor: "#334532",
                ownerOnHeaderColor: "#eeeeee",
                ownerSurfaceColor: "#495848",
                ownerOnSurfaceColor: "#eeeeee",
                ownerSecondary: "#eeeeee",
                ownerOnSecondary: "#495848",
                ownerNotificationDotColor: "#3bcdf2",
                ownerAppIcon: require("@/flit2go-web-core/assets/logo_simple_b_n.svg"),
                ownerLogo: require("@/flit2go-web-core/assets/flit2goLogo.svg"),
                personalizationContent:
                  user.data.settings.showColorPersonalizationContent,
                personalizationToolbar: user.data.settings.colorPersonalization,
              };
            }
            commit("AUTH_SET_OWNER_COLORS", ownerColors);
            commit("AUTH_SET_OWNER_TOOLBAR_COLORS", ownerToolbarColors);
          }
          commit("AUTH_SET_PROFILE", user.data);
          localStorage.setItem("user-profile", JSON.stringify(user.data));
          localStorage.setItem("user-language", user.data.language);
          commit("AUTH_SUCCESS");
          resolve(response);
        } catch (err) {
          commit("AUTH_ERROR");
          dispatch("AUTH_LOGOUT");
          reject(err);
        }
      });
    },
    ["AUTH_LOGOUT"]: ({ commit }) => {
      return new Promise((resolve) => {
        localStorage.removeItem("user-token");
        localStorage.removeItem("user-role");
        localStorage.removeItem("user-profile");
        localStorage.removeItem("update");
        commit("AUTH_SET_PROFILE", {});
        // Remove servie workers
        if ("serviceWorker" in navigator) {
          navigator.serviceWorker
            .getRegistrations()
            .then(function(registrations) {
              for (let registration of registrations) {
                registration.unregister();
              }
            })
            .catch(function(err) {
              // eslint-disable-next-line
              console.log("Service Worker registration failed: ", err);
            });
        }

        resolve();
      });
    },
    ["CHANGE_GLOBAL_OWNER"]: ({ commit }, globalOwner) => {
      return new Promise((resolve) => {
        commit("AUTH_SET_GLOBAL_OWNER", globalOwner);
        localStorage.setItem("global-owner", JSON.stringify(globalOwner));
        resolve();
      });
    },
    ["RESET_GLOBAL_OWNER"]: ({ commit }) => {
      return new Promise((resolve) => {
        commit("AUTH_DELETE_GLOBAL_OWNER");
        localStorage.removeItem("global-owner");
        resolve();
      });
    },
    ["SET_OWNER_COLORS"]: ({ commit }, ownerColors) => {
      return new Promise((resolve) => {
        commit("AUTH_SET_OWNER_COLORS", ownerColors);
        resolve();
      });
    },

    getMultiOwner({ commit }) {
      return new Promise((resolve, reject) => {
        commit("AUTH_LOADING");
        service.auth
          .getMultiOwner()
          .then((resp) => {
            commit("AUTH_SUCCESS");
            commit("AUTH_SET_MULTIOWNER", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            commit("AUTH_ERROR");
            reject(err);
          });
      });
    },

    changeMultiOwner({ commit }, idOwner) {
      return new Promise((resolve, reject) => {
        commit("AUTH_LOADING");
        service.auth
          .changeMultiOwner(idOwner)
          .then((resp) => {
            commit("AUTH_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("AUTH_ERROR");
            reject(err);
          });
      });
    },

    getPersonIdByUsername({ commit }, username) {
      return new Promise((resolve, reject) => {
        commit("AUTH_LOADING");
        service.auth
          .personIdByUsername(username)
          .then((resp) => {
            commit("AUTH_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("AUTH_ERROR");
            reject(err);
          });
      });
    },

    sendEmailToUser({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("AUTH_LOADING");
        service.auth
          .sendEmailToUser(id)
          .then((resp) => {
            commit("AUTH_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("AUTH_ERROR");
            reject(err);
          });
      });
    },
    resetUserPassword({ commit }, newPassword) {
      return new Promise((resolve, reject) => {
        commit("AUTH_LOADING");
        service.auth
          .resetUserPassword(newPassword)
          .then((resp) => {
            commit("AUTH_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("AUTH_ERROR");
            reject(err);
          });
      });
    },
    tokenForFirstLogin({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("AUTH_LOADING");
        service.auth
          .tokenForFirstLogin(id)
          .then((resp) => {
            commit("AUTH_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("AUTH_ERROR");
            reject(err);
          });
      });
    },
    validatePhoneToken({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("AUTH_LOADING");
        service.auth
          .validatePhoneToken(payload)
          .then((resp) => {
            commit("AUTH_SUCCESS");
            commit("AUTH_SET_PROFILE_PHONE", payload.phoneNumber);
            resolve(resp);
          })
          .catch((err) => {
            commit("AUTH_ERROR");
            reject(err);
          });
      });
    },
    enableMFA({ commit }) {
      return new Promise((resolve, reject) => {
        commit("AUTH_LOADING");
        service.auth
          .enableMFA()
          .then((resp) => {
            commit("AUTH_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("AUTH_ERROR");
            reject(err);
          });
      });
    },
    disableMFA({ commit }) {
      return new Promise((resolve, reject) => {
        commit("AUTH_LOADING");
        service.auth
          .disableMFA()
          .then((resp) => {
            commit("AUTH_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("AUTH_ERROR");
            reject(err);
          });
      });
    },
    validateMFATokenEnabled({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("AUTH_LOADING");
        service.auth
          .validateMFATokenEnabled(payload)
          .then((resp) => {
            commit("AUTH_SUCCESS");
            commit("AUTH_SET_PROFILE_MFA", true);
            resolve(resp);
          })
          .catch((err) => {
            commit("AUTH_ERROR");
            reject(err);
          });
      });
    },
    validateMFATokenDisabled({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("AUTH_LOADING");
        service.auth
          .validateMFATokenDisabled(payload)
          .then((resp) => {
            commit("AUTH_SUCCESS");
            commit("AUTH_SET_PROFILE_MFA", false);
            resolve(resp);
          })
          .catch((err) => {
            commit("AUTH_ERROR");
            reject(err);
          });
      });
    },
    getMfaLogin({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("AUTH_LOADING");

        service.auth
          .getMfaLogin(payload)
          .then((resp) => {
            commit("AUTH_SUCCESS");
            let response = {};
            response.hasMfa = resp.data;
            response.data = resp.data;
            resolve(response);
          })
          .catch((err) => {
            commit("AUTH_ERROR");
            let response = {};
            if (err.response && err.response.status === 400) {
              if (err.response.data == "") {
                response.bannedUser = false;
              } else {
                response.bannedUser = true;
                response.bannedText = err.response.data;
              }
            }
            reject(response);
          });
      });
    },
    getSetRoleSchema({ commit }) {
      return new Promise((resolve, reject) => {
        commit("AUTH_LOADING");
        service.auth
          .getRoleSchema()
          .then((resp) => {
            commit("AUTH_SUCCESS");
            localStorage.setItem("update", resp.data[0].update);
            encryptStorage.setItem("user-role", JSON.stringify(resp.data));
            commit("SET_ROLE", encryptStorage.getItem("user-role"));
            resolve();
          })
          .catch(() => {
            commit("AUTH_ERROR");
            reject();
          });
      });
    },
    // End actions
  },
};
