<template>
  <div class="required-fields-end-booking" id="top-part">
    <F2gDialog
      showClose
      :show="showDialog"
      :title="$t('checkRequiredFields')"
      :width="$vuetify.breakpoint.name === 'xs' ? '90%' : 500"
      @close="$emit('close')"
    >
      <template v-slot:content>
        <v-layout wrap v-if="filters.dniRequired && !user.userLoged.nif">
          <p
            class="leading-4 font-medium text-base mb-2 w-full"
            :style="{ paddingTop: '5px' }"
          >
            {{ $t("forms.nif") }}
          </p>
          <v-text-field
            class="lg:w-1/2 w-full lg:mr-2"
            v-model="fields.nif"
            :outlined="true"
            :error-messages="nifErrors"
          >
          </v-text-field>
        </v-layout>
        <v-layout
          wrap
          v-if="
            filters.billingAddressRequired &&
            !(user.userLoged.billAddress && user.userLoged.billAddress.country)
          "
        >
          <p
            class="leading-4 font-medium text-base mb-0 w-full"
            :style="{ paddingTop: '5px' }"
          >
            {{ $t("forms.address.billingAddress") }}
          </p>
          <div
            class="flex flex-row justify-start items-center"
            v-if="user.userLoged.address && user.userLoged.address.country"
          >
            <v-checkbox v-model="checkBilling" color="primary" />
            <span class="ml-2" :style="{ color: colors.colorOnBackground }">{{
              $t("forms.address.useResidenceAddress")
            }}</span>
          </div>
          <direction-billing-data
            v-if="
              !checkBilling ||
              !(user.userLoged.address && user.userLoged.address.country)
            "
            :validate="validate"
            :nationalities="user.nationalities"
            @billingAddress="setBillingAddress"
            @validation="setValidationData"
          />
        </v-layout>
      </template>
      <template v-slot:actions>
        <div class="mt-3">
          <F2gButton
            customClass="secondary-dark"
            class="mr-2"
            outlined
            :text="$t('common.cancel')"
            @click="$emit('close')"
          />
          <F2gButton
            customClass="primary"
            :text="$t('common.save')"
            @click="sendData"
          />
        </div>
      </template>
    </F2gDialog>
  </div>
</template>

<script>
import { requiredIf, sameAs } from "vuelidate/lib/validators";
import DirectionBillingData from "@/components/direction-billing-data.vue";
import { mapGetters, mapState } from "vuex";
export default {
  name: "RequiredFieldsEndBooking",
  components: { DirectionBillingData },
  props: {
    showDialog: { type: Boolean, default: false, required: false },
  },
  data() {
    return {
      fields: {
        nif: null,
        billingAddressValidated: true,
      },
      checkBilling: true,
      validate: false,
      userInfo: null,
    };
  },
  validations() {
    return {
      fields: {
        nif: {
          required: requiredIf(() => {
            return this.filters.dniRequired && !this.user.userLoged.nif;
          }),
        },
        billingAddressValidated: { sameAs: sameAs(() => true) },
      },
    };
  },
  watch: {
    checkBilling() {
      if (this.checkBilling) {
        this.fields.billingAddressValidated = true;
      } else {
        this.fields.billingAddressValidated = false;
      }
    },
    showDialog() {
      if (this.showDialog) {
        window.parent.postMessage("goToTop", "*");
      }
    },
  },
  created() {
    this.$store.dispatch("user/getNationalities");
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      colors: "configuration/getColors",
      language: "configuration/getUserLanguage",
      loginParameters: "user/loginParameters",
      filters: "configuration/getFilters",
    }),
    nifErrors() {
      const errors = [];
      if (this.$v.fields.nif.$dirty) {
        !this.$v.fields.nif.required && errors.push(this.$t("errors.required"));
      }
      return errors;
    },
  },
  methods: {
    sendData() {
      this.userInfo = this.userInfo
        ? this.userInfo
        : Object.assign({}, this.user.userLoged);
      this.$v.$touch();
      this.validate = true;
      setTimeout(() => {
        this.validate = false;
        this.endSendData();
      }, 1000);
    },
    endSendData() {
      if (!this.$v.$invalid) {
        if (this.filters.dniRequired && !this.userInfo.nif) {
          this.userInfo.nif = this.fields.nif;
        }
        if (this.filters.billingAddressRequired) {
          if (
            this.checkBilling &&
            this.user.userLoged.address &&
            this.user.userLoged.address.country
          ) {
            this.userInfo.billAddress = Object.assign(
              {},
              this.userInfo.address
            );
          }
        }
        this.sendUserFields();
      }
    },
    setBillingAddress(data) {
      this.userInfo = this.userInfo
        ? this.userInfo
        : Object.assign({}, this.user.userLoged);
      this.userInfo.billAddress = data;
      this.userInfo.bussinesName = data.bussinesName;
      this.userInfo.documentIdentity = data.documentIdentity;
    },
    setValidationData(data) {
      this.fields.billingAddressValidated = data.valid;
      if (this.fields.billingAddressValidated && !this.$v.$invalid) {
        if (this.filters.dniRequired) {
          this.userInfo.nif = this.fields.nif;
        }
        this.sendUserFields();
      }
    },
    sendUserFields() {
      if (
        this.userInfo?.billAddress?.country &&
        typeof this.userInfo.billAddress.country === "object"
      ) {
        this.userInfo.billAddress.country = this.language
          ? this.userInfo.billAddress.country.name[this.language]
          : this.userInfo.billAddress.country.name["en"];
      }
      let data = {
        loginParameters: this.loginParameters,
        user: this.userInfo,
      };
      this.$store
        .dispatch("user/sendUserFields", data)
        .then(() => {
          this.$store.commit("user/SET_USER_LOGED", this.userInfo);
          this.$emit("close");
        })
        .catch((err) => {
          this.$showMessage.showError(err);
        });
    },
  },
};
</script>
