<template>
  <v-menu
    :close-on-content-click="false"
    v-model="menu"
    transition="scale-transition"
    offset-y
    :nudge-right="40"
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <span class="w-full h-2/3">
        <v-text-field
          v-on="on"
          ref="datePickerTextFieldFW"
          :style="
            colors
              ? `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
              : ''
          "
          :class="frameColours ? 'custom-placeholer-color' : ''"
          :color="colors ? colors.colorPrimary : inputColor"
          v-model="dateform"
          :outlined="outlined"
          readonly
          :label="label"
          :disabled="disabled"
          :error-messages="errors"
          :backgroundColor="backgroundColor"
          :dark="dark"
          @input="input"
        >
          <template slot="append" v-if="!disabled && dateform">
            <v-icon :color="inputColor" @click="clearEvent"
              >close</v-icon
            > </template
          ><template slot="prepend-inner" v-if="outlined">
            <v-icon :color="inputColor">event</v-icon>
          </template>
          <template v-if="required" #label>
            {{ label }}<span class="red--text"><strong> * </strong></span>
          </template>
        </v-text-field>
      </span>
    </template>
    <v-date-picker
      v-model="dateform"
      no-title
      scrollable
      :disabled="disabled"
      :min="minDate"
      :max="maxDate"
      :type="checkType()"
      locale="i18n.locale"
      :first-day-of-week="
        $PageUtils.getLanguageSplittedMinus($i18n.locale) === 'en' ? 0 : 1
      "
      @input="menu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
// import RedAsterisk from "@/flit2go-web-core/components/fields/f2g-red-asterisk";
export default {
  components: {
    // RedAsterisk,
  },
  data: () => ({
    dateform: "",
    menu: null,
  }),
  props: {
    label: String,
    name: String,
    clear: Boolean,
    errors: Array,
    input: null,
    minDate: String,
    maxDate: String,
    type: String,
    disabled: Boolean,
    outlined: { required: false, type: Boolean, default: false },
    inputColor: { type: String, required: false },
    backgroundColor: { type: String, required: false },
    dark: { type: Boolean, required: false, default: false },
    required: { type: Boolean, required: false, default: false },
    frameColours: { type: Boolean, required: false, default: false },
    colors: { type: Object, required: false, default: () => {} },
  },
  watch: {
    dateform: function() {
      this.$emit("form", this.dateform, this.name);
    },
    clear: function() {
      if (this.clear) {
        this.$nextTick(() => (this.dateform = ""));
      }
    },
    input: {
      inmediate: true,
      handler() {
        if (this.input) {
          this.dateform = this.input;
        }
      },
    },
    inputColor: {
      immediate: true,
      handler() {
        if (this.inputColor && this.inputColor.length > 0) {
          this.setInputColor();
        }
      },
    },
  },
  mounted() {
    if (this.inputColor && this.inputColor.length > 0) {
      this.setInputColor();
    }
  },
  methods: {
    checkType() {
      if (this.type) {
        return this.type;
      } else {
        return "date";
      }
    },
    clearEvent() {
      this.dateform = "";
      this.$emit("clear");
    },
    setInputColor() {
      const textField = this.$refs.datePickerTextFieldFW;
      if (textField) {
        const input = textField.$refs["input"];
        if (input) {
          input.style.color = this.inputColor;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-placeholer-color input {
  color: var(--custom_color-text) !important;
}
.custom-placeholer-color .v-label {
  color: var(--custom_color-label) !important;
  opacity: 1;
}
</style>
