import service from "@/flit2go-web-core/services/";

export default {
  namespaced: true,
  state: {
    status: "",
    paymentsList: [],
  },
  mutations: {
    ["STATUS_SUCCESS"]: (state) => {
      state.status = "success";
    },
    ["STATUS_LOADING"]: (state) => {
      state.status = "loading";
    },
    ["STATUS_ERROR"]: (state) => {
      state.status = "error";
    },
    ["SET_PAYMENTS_LIST"]: (state, payments) => {
      state.paymentsList = payments;
    },
  },
  actions: {
    getPayments({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service.payment
          .getPayments(params)
          .then((resp) => {
            commit(
              "SET_PAYMENTS_LIST",
              resp.status === 200 ? resp.data.content : []
            );
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getPaymentsBilling({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service.payment
          .getPaymentsBilling(params)
          .then((resp) => {
            commit(
              "SET_PAYMENTS_LIST",
              resp.status === 200 ? resp.data.content : []
            );
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getPaymentsByUserId({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service.payment
          .getPaymentsByUserId(params)
          .then((resp) => {
            commit(
              "SET_PAYMENTS_LIST",
              resp.status === 200 ? resp.data.content : []
            );
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getPaymentMethodsByUserId({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service.payment
          .getPaymentMethodsByUserId(params)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getTotalBillingByUserId({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service.payment
          .getTotalBillingByUserId(params)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    downloadPdf({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service.payment
          .downloadPdf(id)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getTotalPendingBillingByUserId({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service.payment
          .getTotalPendingBillingByUserId(params)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
  },
};
