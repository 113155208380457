import Vue from "vue";
import Router from "vue-router";

import EndBooking from "@/modules/v1/Pages/EndBooking";
import EndBookingV2 from "@/modules/v2/Pages/EndBooking";
import MainFilter from "@/modules/v1/Pages/MainFilter";
import ReservationStepper from "@/modules/v1/Pages/ReservationStepper";
import MainFilterV2 from "@/modules/v2/Pages/MainFilter";
import ReservationStepperV2 from "@/modules/v2/Pages/ReservationStepper";
import ReservationCatalogue from "@/modules/catalogue/Pages/ReservationCatalogue";
import Catalogue from "@/modules/catalogue/Pages/Catalogue";

Vue.use(Router);

const routes = [
  {
    path: "/v1",
    redirect: "/booking/vertical",
    name: "Main",
    component: MainFilter,
  },
  {
    path: "/v1/booking/:type/:mode",
    name: "ReserveFilter",
    component: MainFilter,
  },
  {
    path: "/v1/reservation",
    name: "Reservation",
    component: ReservationStepper,
  },
  {
    path: "/v1/endBooking",
    name: "EndBooking",
    component: EndBooking,
  },
  {
    path: "/v2",
    redirect: "/booking/vertical",
    name: "MainV2",
    component: MainFilterV2,
  },
  {
    path: "/v2/booking/:type/:mode/:size",
    name: "ReserveFilterV2",
    component: MainFilterV2,
  },
  {
    path: "/v2/reservation",
    name: "ReservationV2",
    component: ReservationStepperV2,
  },
  {
    path: "/v2/endBooking",
    name: "EndBookingV2",
    component: EndBookingV2,
  },
  {
    path: "/v2/catalogue",
    name: "Catalogue",
    component: Catalogue,
  },
  {
    path: "/v2/catalogue/reservation",
    name: "ReservationCatalogue",
    component: ReservationCatalogue,
  },
];

const router = new Router({
  routes,
});

export default router;
