<template>
  <div class="location-card-selector max-h-full">
    <div class="max-h-full w-full">
      <div class="flex justify-end mb-2">
        <F2gIcon
          value="close"
          :class="'cursor-pointer'"
          :height="26"
          :width="26"
          :stroke="colors.colorOnHeader"
          :strokeWidth="6"
          @click="$emit('close')"
        />
      </div>
      <v-text-field
        id="pickUpLocation"
        single-line
        filled
        :dense="true"
        :disabled="false"
        :autocomplete="'off'"
        :append-icon="''"
        flat
        hide-details
        clearable
        return-object
        v-model="branchName"
        class="mt-6 mb-4 rounded"
        type="text"
        :color="colors.colorPrimary"
        :background-color="colors.colorSurfaceMedium"
        @input="searchBranchesFiltered"
      >
        <template #label>
          <span :style="`color:${colors.colorOnSurfaceMedium} !important`">{{
            $t("placeOfDelivery")
          }}</span>
        </template>
        <template slot="prepend-inner">
          <F2gIcon
            :style="`cursor: pointer, color: ${colors.colorPrimary}`"
            :fill="colors.colorPrimary"
            class="cursor-pointer"
            value="location"
            solid
            file="icons_solid.svg"
            :strokeWidth="3"
          />
        </template>
      </v-text-field>
      <div
        class="location-card-selector__branches overflow-y-scroll max-h-full"
      >
        <div v-for="(branch, index) in branchesFiltered" :key="index">
          <div
            class="flex justify-between cursor-pointer rounded locationRow my-3"
            :style="`--custom_color:${colors.colorPrimary + '55'}`"
          >
            <div
              class="w-full ml-2 p-2"
              @click="selectLocation(branch)"
              :style="`color:${colors.colorOnHeader} !important`"
            >
              {{ branch.name }}
            </div>
            <div class="flex align-center mr-4" @click="goToinfoBranch(branch)">
              <F2gIcon
                :style="`cursor: pointer, color: ${colors.colorPrimary}`"
                :fill="$CONSTANTS.COLOUR.BLUE_1_F2G"
                :height="20"
                :width="20"
                class="cursor-pointer"
                value="info"
                solid
                file="icons_solid.svg"
                :strokeWidth="2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LocationCardSelector",
  components: {},
  props: {
    branches: { required: true, type: Array, default: null },
    branchToShow: { required: false, type: Object, default: () => {} },
  },
  data() {
    return {
      branchName: "",
      query: "",
    };
  },
  watch: {
    branchToShow() {
      if (this.branchToShow && this.branchToShow.id) {
        this.query = "";
        this.branchName = this.branchToShow.name;
      } else {
        this.branchName = "";
        this.query = "";
      }
    },
  },
  mounted() {
    if (this.branchToShow && this.branchToShow.id) {
      this.branchName = this.branchToShow.name;
    }
  },

  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
    }),
    branchesFiltered() {
      if (this.query) {
        let listBranches = [];
        this.branches.forEach((item) => {
          if (
            item.name &&
            item.name.toLowerCase().includes(this.query.toLowerCase())
          ) {
            listBranches.push(item);
          }
        });
        return listBranches;
      } else {
        return this.branches;
      }
    },
  },
  methods: {
    selectLocation(branch) {
      this.$emit("branchSelected", branch);
      this.query = "";
    },
    goToinfoBranch(branch) {
      this.$emit("goToinfoBranch", branch);
    },
    searchBranchesFiltered(queryText) {
      this.query = queryText != null ? queryText : "";
    },
  },
};
</script>
<style lang="scss" scoped>
.locationRow {
  &:hover {
    background: var(--custom_color);
  }
}

.location-card-selector {
  &__branches {
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $green-2-f2g;
      border-radius: 30px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $green-3-f2g;
    }
  }
}
</style>
