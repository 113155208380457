export default {
  enum() {
    return {
      CONSTRUCTION: "under_construction.svg",
      DATA: "no_data.svg",
      ERROR: "sad.svg",
      GRAPH: "no_graph_data.svg",
      INCIDENCES: "no_incidences.svg",
      LOCALITIES: "no_location.svg",
      OWNERS: "no_owners.svg",
      RENTALS: "no_rentals.svg",
      TASKS: "no_tasks.svg",
      USERS: "no_user.svg",
      VEHICLES: "no_vehicles.svg",
      VINCULATED_VEHICLE: "no_vinculated_vehicle.svg",
      NO_DOCUMENTATION: "no_documentation.svg",
      QUESTION: "question_box.svg",
    };
  },
};
