var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price-resume-data",class:{ 'w-full': _vm.detailCatalogue },attrs:{"id":"top-part"}},[(_vm.loadingAvailabilityDates)?_c('div',[_c('v-skeleton-loader',{staticClass:"mx-auto my-2",attrs:{"height":"108px","type":"image"}})],1):_c('div',{staticClass:"flex flex-row flex-wrap",class:{ 'my-2': !_vm.detailCatalogue }},[_vm._l((_vm.getPacks),function(pack,index){return _c('CategoryCardPackCatalogue',{key:index,staticClass:"w-full min-max-h-full flex lg:flex-none md:align-left md:flex-row relative shadow",class:{
        'rounded-lg mb-3': !_vm.detailCatalogue && !_vm.isSmallScreen,
        'rounded-b-lg shadow': _vm.detailCatalogue && !_vm.isSmallScreen,
        'mb-3': !_vm.isSmallScreen,
        'rounded-t-lg': _vm.isSmallScreen && Number(index) >= 1,
        'rounded-b-lg': _vm.isSmallScreen,
      },attrs:{"packRate":pack,"index":index,"category":_vm.currentCategory},on:{"showDescription":(packIndex) => (_vm.showPackDescription = packIndex),"changeDates":() => (_vm.showModalEdit = true),"selected":_vm.selectPackRate}})}),_c('transition',{attrs:{"name":"fade"}},[(_vm.showPackDescription > -1)?_c('Card',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(() => (_vm.showPackDescription = -1)),expression:"() => (showPackDescription = -1)"}],staticClass:"helpCard"},[_c('div',{staticClass:"pa-3"},[_c('span',{staticClass:"mt-1 message",domProps:{"innerHTML":_vm._s(
              _vm.currentCategory.packRate[_vm.showPackDescription].description[
                _vm.language
              ]
                ? _vm.currentCategory.packRate[_vm.showPackDescription].description[
                    _vm.language
                  ]
                : _vm.currentCategory.packRate[_vm.showPackDescription].description.en
            )}})])]):_vm._e()],1),(!_vm.hasPacks && _vm.currentCategory.deferredPayment)?_c('div',{staticClass:"min-max-h-full mb-3 flex lg:flex-none md:align-left md:flex-row relative shadow",class:{
        'rounded-bl-lg': _vm.detailCatalogue,
        'rounded-l-lg':
          _vm.currentCategory.deferredPayment &&
          !_vm.currentCategory.errorDatesReserve &&
          !_vm.detailCatalogue,
        'rounded-lg':
          _vm.currentCategory.deferredPayment &&
          _vm.currentCategory.errorDatesReserve &&
          !_vm.detailCatalogue,
        'w-1/2': !_vm.currentCategory.errorDatesReserve,
        'w-full': _vm.currentCategory.errorDatesReserve,
      },style:({ backgroundColor: _vm.colors.colorSurface })},[_c('div',{staticClass:"flex flex-col p-3 max-h-full"},[(!_vm.currentCategory.errorDatesReserve)?_c('div',{staticClass:"max-h-full flex flex-col justify-between"},[_c('div',[_c('div',{staticClass:"text-sm xl:text-base font-bold",style:({ color: _vm.colors.colorOnSurface })},[_vm._v(" "+_vm._s(_vm.$t("prepaid"))+" ")]),_c('div',{staticClass:"text-xs mt-2",style:({ color: _vm.colors.colorOnSurface })},[_vm._v(" "+_vm._s(_vm.$t("payNowPrepaid"))+" ")])]),_c('div',{class:{
              'xl:mt-2 mt-3': _vm.currentCategory.deferredPayment,
            }},[_c('div',{staticClass:"xl:text-3xl text-2xl font-bold",style:({ color: _vm.colors.colorPrimary })},[_vm._v(" "+_vm._s(_vm.BillingUtil.formatBillingValue( _vm.returnPrice(), _vm.currentCategory.symbol ))+" ")]),_c('div',{staticClass:"text-xl font-bold",style:({ color: _vm.colors.colorOnSurfaceMedium })},[_vm._v(" "+_vm._s(_vm.BillingUtil.formatBillingValue( _vm.getPricePerDay(), _vm.currentCategory.symbol ))),_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.currentCategory.timeType == "day" ? _vm.$t("common.perDay") : _vm.$t("common.perHour")))])]),_c('v-btn',{staticClass:"w-full border-2 rounded-config30 mt-3",style:({
                backgroundColor: _vm.colors.colorPrimary,
                color: _vm.colors.colorOnPrimary,
              }),attrs:{"loading":_vm.loading},on:{"click":() => {
                  _vm.$emit(
                    'selected',
                    _vm.currentCategory.deferredPayment ? true : false
                  );
                }}},[_vm._v(" "+_vm._s(_vm.$t("select"))+" ")])],1)]):_c('div',{staticClass:"my-2 text-normal text-red-700 max-w-lg w-full"},[_c('div',{staticClass:"text-sm font-bold flex flex-row justify-center align-center errorText"},[_vm._v(" "+_vm._s(_vm.currentCategory.txtErrorDatesReserve)+" ")]),_c('div',{staticClass:"flex flex-row justify-end align-end h-12"},[_c('v-btn',{staticClass:"w-full border-2 rounded-config30 mt-2",style:({
                backgroundColor: _vm.colors.colorPrimary,
                color: _vm.colors.colorOnPrimary,
              }),on:{"click":function($event){_vm.showModalEdit = true}}},[_vm._v(" "+_vm._s(_vm.$t("modifyDates"))+" ")])],1)])])]):_vm._e(),(
        !_vm.hasPacks &&
        _vm.currentCategory.deferredPayment &&
        !_vm.currentCategory.errorDatesReserve
      )?_c('div',{staticClass:"w-1/2 min-max-h-full mb-3 flex lg:flex-none md:align-left md:flex-row relative shadow",class:{
        'rounded-br-lg': _vm.detailCatalogue,
        'rounded-r-lg': !_vm.detailCatalogue,
      },style:({ backgroundColor: _vm.colors.colorSurface })},[_c('div',{staticClass:"flex flex-col p-3 max-h-full"},[(!_vm.currentCategory.errorDatesReserve)?_c('div',{staticClass:"max-h-full flex flex-col justify-between"},[_c('div',[_c('div',{staticClass:"text-sm xl:text-base font-bold",style:({ color: _vm.colors.colorOnSurface })},[_vm._v(" "+_vm._s(_vm.getTotalPriceDeferredTitle)+" ")]),_c('div',{staticClass:"text-xs mt-2",style:({ color: _vm.colors.colorOnSurface }),domProps:{"innerHTML":_vm._s(_vm.getTotalPriceDeferredText)}})]),_c('div',{staticClass:"xl:mt-2 mt-3"},[_c('div',{staticClass:"xl:text-3xl text-2xl font-bold",style:({ color: _vm.colors.colorPrimary })},[_vm._v(" "+_vm._s(_vm.BillingUtil.formatBillingValue( _vm.currentCategory.totalPrice ? _vm.currentCategory.totalPrice : 0, _vm.currentCategory.symbol ))+" ")]),_c('div',{staticClass:"text-xl font-bold",style:({ color: _vm.colors.colorOnSurfaceMedium })},[_vm._v(" "+_vm._s(_vm.BillingUtil.formatBillingValue( _vm.currentCategory.pricePerTime, _vm.currentCategory.symbol ))),_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.currentCategory.timeType == "day" ? _vm.$t("common.perDay") : _vm.$t("common.perHour")))])]),_c('v-btn',{staticClass:"w-full mt-3 border-2 rounded-config30",style:({
                backgroundColor: _vm.colors.colorPrimary,
                color: _vm.colors.colorOnPrimary,
              }),attrs:{"loading":_vm.loading},on:{"click":() => {
                  _vm.$emit('selected', false);
                }}},[_vm._v(" "+_vm._s(_vm.$t("select"))+" ")])],1)]):_vm._e()])]):_vm._e(),(!_vm.hasPacks && !_vm.currentCategory.deferredPayment)?_c('div',{staticClass:"w-full max-h-full flex lg:flex-none md:align-left md:flex-row relative shadow",class:{
        'rounded-lg mb-3': !_vm.detailCatalogue,
        'rounded-b-lg shadow': _vm.detailCatalogue,
      },style:({ backgroundColor: _vm.colors.colorPrimary })},[_c('div',{staticClass:"flex flex-col p-3 max-h-full"},[(!_vm.currentCategory.errorDatesReserve)?_c('div',{staticClass:"flex flex-row max-h-full"},[_c('div',{staticClass:"flex flex-col ml-3"},[_c('div',{staticClass:"xl:text-2xl text-xl font-bold",style:({ color: _vm.colors.colorOnPrimary })},[_vm._v(" "+_vm._s(_vm.BillingUtil.formatBillingValue( _vm.returnPrice(), _vm.currentCategory.symbol ))+" ")]),_c('div',{staticClass:"text-sm font-bold",style:({ color: _vm.colors.colorOnPrimary })},[_vm._v(" "+_vm._s(_vm.BillingUtil.formatBillingValue( _vm.getPricePerDay(), _vm.currentCategory.symbol ))),_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.currentCategory.timeType == "day" ? _vm.$t("common.perDay") : _vm.$t("common.perHour")))])])]),_c('v-btn',{staticClass:"w-36 border-2 rounded-config30 mt-2",style:({
              backgroundColor: _vm.colors.colorOnPrimary,
              color: _vm.colors.colorPrimary,
            }),attrs:{"loading":_vm.loading},on:{"click":() => {
                _vm.$emit(
                  'selected',
                  _vm.currentCategory.deferredPayment ? true : false
                );
              }}},[_vm._v(" "+_vm._s(_vm.$t("select"))+" ")])],1):(!_vm.hasPacks)?_c('div',{staticClass:"my-2 text-normal text-red-700 max-w-lg w-full"},[_c('div',{staticClass:"text-sm font-bold flex flex-row justify-center align-center errorText"},[_vm._v(" "+_vm._s(_vm.currentCategory.txtErrorDatesReserve)+" ")]),_c('div',{staticClass:"flex flex-row justify-end align-end h-12"},[_c('v-btn',{staticClass:"w-full border-2 rounded-config30 mt-2",style:({
                backgroundColor: _vm.colors.colorOnPrimary,
                color: _vm.colors.colorPrimary,
              }),on:{"click":function($event){_vm.showModalEdit = true}}},[_vm._v(" "+_vm._s(_vm.$t("modifyDates"))+" ")])],1)]):_vm._e()])]):_vm._e()],2),_c('LateralPanel',{ref:"lateralPanel",attrs:{"open":_vm.showModalEdit}},[_c('template',{slot:"content"},[(_vm.showModalEdit)?_c('MainFilterComponentNew',{staticStyle:{"min-height":"600px","display":"flex"},style:(`min-width: ${
          this.$vuetify.breakpoint.name === 'sm' ||
          this.$vuetify.breakpoint.name === 'xs'
            ? ''
            : '600px'
        }`),attrs:{"showCancelButton":"","type":'vertical',"edit":_vm.showModalEdit ? true : false,"bookingFiltersOld":_vm.bookingFilters},on:{"close":function($event){_vm.showModalEdit = false}}}):_vm._e()],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }