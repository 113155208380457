import conn from "./connection"

export default {
    getLanguages() {
        return conn.$axios({
            url: `/secured/v1/master/languages`,
            method: 'GET'
        })
    },
    updateLanguage(person) {
        return conn.$axios({
            url: `secured/v1/person/language`,
            method: 'PUT',
            data: person
        })
    },
    updateNumberResults(numberResults) {
        return conn.$axios({
            url: `secured/v1/person/numberResults/${numberResults}`,
            method: 'PUT'
        })
    },
    getShowDateTooltip() {
        return conn.$axios({
            url: `secured/v1/service/settings`,
            method: 'GET'
        })
    },
    getUserSettings() {
        return conn.$axios({
            url: `secured/v1/service/settings`,
            method: "GET",
        })

    },
    updateShowDateTooltips(showTooltipDate) {
        return conn.$axios({
            url: `secured/v1/service/settings/unique`,
            method: 'PUT',
            params: {
                settingName: 'showTooltipDate',
                settingValue: showTooltipDate
            }
        })
    },
    updateSettings(settings) {
        return conn.$axios({
            url: `secured/v1/service/settings/unique`,
            method: 'PUT',
            params: settings
        })
    },    
}