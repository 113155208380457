<template>
  <div class="EndBooking max-h-full flex-col-center">
    <F2gCard
      padding
      class="loginCard w-full max-h-full py-8"
      :style="{ backgroundColor: colors.colorBackground }"
    >
      <span class="flex flex-row align-center md:ml-24 md:mr-8 mt-6">
        <F2gIcon
          class="svg mt-2"
          :value="'check'"
          :stroke="colors.colorPrimary"
          :height="28"
          :width="36"
          :strokeWidth="7"
        />
        <span
          class="mt-2 ml-2 text-xl font-black"
          :style="{ color: colors.colorPrimary }"
          >{{ $t("common.processExitConfirm") }}</span
        >
      </span>
      <div class="flex flex-col md:flex-row align-middle">
        <!-- Svg booking created-->
        <div class="p-12 w-full md:w-4/12">
          <v-img
            class="h-64"
            :src="require('@/flit2go-web-core/assets/final-confirm.svg')"
            :contain="true"
          ></v-img>
        </div>
        <!-- Booking card-->
        <div class="w-full md:w-7/12 md:mt-6">
          <div
            class="flex flex-col border-2 shadow border-gray-500"
            style="border-radius: 10px"
          >
            <!-- Booking card TOP-->
            <div
              class="flex-none rounded-t-lg p-2"
              :style="{
                backgroundColor: colors.colorOnBackground,
              }"
            >
              {{ $t("common.booking") }} #{{
                $route.params.bookingInfo.bookingId
              }}
            </div>
            <!-- Booking mid MIDDLE-->
            <div
              class="flex-grow flex flex-col sm:flex-row p-5 border-t-2 border-gray-300 min-h-48"
              :style="{ backgroundColor: colors.colorOnBackground }"
            >
              <div class="flex flex-row items-center">
                <image-aspect-ratio
                  class="flex-none rounded-lg w-24"
                  :style="{ backgroundColor: colors.colorHeader }"
                  :img="categorySelected.img"
                  minHeight="6rem"
                  maxWidth="6rem"
                />
                <div
                  class="w-px mx-4 h-24"
                  :style="{ backgroundColor: colors.colorHeader }"
                ></div>
                <div class="flex flex-col">
                  <div class="font-bold">
                    {{ categorySelected.name }}
                  </div>
                  <div
                    v-html="
                      categorySelected.description[userLanguage]
                        ? categorySelected.description[userLanguage]
                        : categorySelected.description[en]
                    "
                  ></div>
                  <div class="text-sm row ml-1">
                    <div
                      class="mt-2"
                      v-for="(
                        attribute, index
                      ) in categorySelected.attributeList"
                      :key="index"
                    >
                      <span class="text-xs flex flex-row">
                        <div>
                          <svg
                            style="height: 24px; width: 24px"
                            :fill="colors.colorPrimary"
                          >
                            <path
                              v-if="attribute.icon.path"
                              :d="attribute.icon.path"
                            />
                          </svg>
                        </div>
                        <span
                          class="align-center flex mr-4 ml-2"
                          :style="{ color: colors.colorOnSurfaceMedium }"
                          >{{
                            attribute.supportedLangs[$i18n.locale.split("_")[0]]
                              ? attribute.supportedLangs[
                                  $i18n.locale.split("_")[0]
                                ]
                              : attribute.supportedLangs.en
                          }}</span
                        >
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="text-xs px-2 mt-4 sm:mt-0"
                :style="{ color: colors.colorOnSurface }"
              >
                <p class="mb-1">{{ $t("common.addToCalendar") }}</p>
                <div class="flex sm:flex-col">
                  <div class="flex items-center pr-2 sm:py-1">
                    <v-img
                      class="flex-none w-6 h-6"
                      :src="
                        require('@/flit2go-web-core/assets/brand-icons/google.png')
                      "
                      :contain="true"
                    ></v-img>
                    <a
                      target="_blank"
                      :href="getGoogleLink()"
                      class="ml-2 underline text-blue-800 cursor-pointer"
                    >
                      Google
                    </a>
                  </div>
                  <div class="flex items-center pr-2 sm:py-1">
                    <v-img
                      class="flex-none w-6 h-6"
                      :src="
                        require('@/flit2go-web-core/assets/brand-icons/ical.png')
                      "
                      :contain="true"
                    ></v-img>
                    <a
                      target="_blank"
                      :href="getICalLink()"
                      class="ml-2 underline text-blue-800 cursor-pointer"
                    >
                      iCal
                    </a>
                  </div>
                  <div class="flex items-center pr-2 sm:py-1">
                    <v-img
                      class="flex-none w-6 h-6"
                      :src="
                        require('@/flit2go-web-core/assets/brand-icons/yahoo.png')
                      "
                      :contain="true"
                    ></v-img>
                    <a
                      target="_blank"
                      :href="getYahooLink()"
                      class="ml-2 underline text-blue-800 cursor-pointer"
                    >
                      Yahoo
                    </a>
                  </div>
                  <div class="flex items-center sm:py-1">
                    <v-img
                      class="flex-none w-6 h-6"
                      :src="
                        require('@/flit2go-web-core/assets/brand-icons/outlook.png')
                      "
                      :contain="true"
                    ></v-img>
                    <a
                      target="_blank"
                      :href="getOutlookLink()"
                      class="ml-2 underline text-blue-800 cursor-pointer"
                    >
                      Outlook
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- Booking card BOTTOM-->
            <div
              class="flex-none p-2 rounded-b-lg"
              :style="{
                backgroundColor: colors.colorHeader,
                color: colors.onHeader,
              }"
            >
              <div class="flex flex-column">
                <div class="flex flex-row">
                  <F2gIcon
                    class="svg mt-2 mx-2"
                    file="icons_solid.svg"
                    :fill="colors.colorPrimary"
                    solid
                    :value="'location'"
                    :height="20"
                    :width="20"
                    :strokeWidth="4"
                  />
                  <span class="mt-2 text-sm md:text-base">{{
                    bookingFilters.pickUpNameBranch
                  }}</span>
                  <F2gIcon
                    class="svg mt-2 mx-2"
                    value="lined-arrow-right"
                    :stroke="colors.colorPrimary"
                    :strokeWidth="6"
                    :height="20"
                    :width="20"
                  />
                  <span class="mt-2 text-sm md:text-base">{{
                    bookingFilters.returnBackNameBranch
                  }}</span>
                </div>
                <div class="flex flex-row">
                  <F2gIcon
                    class="svg mt-2 ml-2 mr-1"
                    :stroke="colors.colorPrimary"
                    :value="'agenda'"
                    :height="17"
                    :width="17"
                    :strokeWidth="4"
                  />
                  <span class="mt-2 text-sm ml-2 md:text-base">{{
                    DateUtil.formatCustomDate(bookingFilters.pickUpDate)
                  }}</span>
                  <F2gIcon
                    class="svg mt-2 mx-2"
                    value="lined-arrow-right"
                    :stroke="colors.colorPrimary"
                    :strokeWidth="6"
                    :height="20"
                    :width="20"
                  />
                  <span class="mt-2 text-sm md:text-base">{{
                    DateUtil.formatCustomDate(bookingFilters.returnBackDate)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="md:ml-24 md:mr-8 mt-4"
        :style="{ color: colors.colorOnSurface }"
        v-html="
          $t('common.endBookingProcessOk', {
            number: this.$route.params.bookingInfo.bookingId,
          })
        "
      ></div>
      <ul
        id="example-1"
        class="md:ml-24 md:mr-24 mt-2 list-disc"
        :style="{ color: colors.colorOnSurface }"
      >
        <li>
          {{ $t("common.vehicle") }}:
          <span class="font-bold">{{ categorySelected.name }}</span>
        </li>
        <li>
          {{ $t("common.pickUp") }}:
          <span class="font-bold">{{
            DateUtil.formatCustomDate(bookingFilters.pickUpDate)
          }}</span>
        </li>
        <li>
          {{ $t("common.return") }}:
          <span class="font-bold">{{
            DateUtil.formatCustomDate(bookingFilters.returnBackDate)
          }}</span>
        </li>
        <li>
          {{ $t("mobile.booking.totalCost") }}
          <span class="font-bold">{{
            BillingUtil.formatBillingValue(
              actualPrice.priceTotal ? actualPrice.priceTotal.value : 0,
              actualPrice.priceTotal ? actualPrice.priceTotal.currency : "€"
            )
          }}</span>
        </li>
      </ul>
      <div
        class="md:ml-24 md:mr-24 mt-4"
        :style="{ color: colors.colorOnSurface }"
        v-html="$t('common.emailSentToBooking', { email: userLoged.username })"
      ></div>
      <div
        class="md:ml-24 md:mr-24 mt-4"
        :style="{ color: colors.colorOnSurface }"
      >
        {{ $t("common.endBookingInfo") }}
      </div>
      <div
        class="md:ml-24 md:mr-24 mt-4"
        :style="{ color: colors.colorOnSurface }"
      >
        {{ $t("common.endBookingTermsConditions") }}
        <a class="underline" @click="showTerms = !showTerms">
          {{ $t("common.followingLink").toLowerCase() }}
        </a>
      </div>
      <div
        class="md:ml-24 md:mr-24 mt-4 border-2 p-4"
        :style="{ color: colors.colorOnSurface }"
        v-if="showTerms"
        v-html="
          $route.params.bookingInfo.termsAndConditionsDTO.description[
            $i18n.locale.split('_')[0]
          ]
        "
      ></div>
      <div
        class="md:ml-24 md:mr-24 mt-4"
        :style="{ color: colors.colorOnSurface }"
      >
        {{ $t("common.followingSupportNumber") }}
        <a href="#" target="_blank" rel="noopener noreferrer" class="underline">
          {{ $route.params.bookingInfo.phoneNumber }}</a
        >
      </div>
      <h2
        class="md:ml-24 md:mr-24 mt-4 font-bold"
        :style="{ color: colors.colorOnSurface }"
      >
        {{ $t("common.additionalInfo") }}
      </h2>
      <div
        class="md:ml-24 md:mr-24 mt-4"
        :style="{ color: colors.colorOnSurface }"
        v-html="
          labels.textEmail[userLanguage]
            ? labels.textEmail[userLanguage]
            : labels.textEmail.en
        "
      ></div>
    </F2gCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DateUtil from "@/flit2go-web-core/util/dateUtil";
import ImageAspectRatio from "@/flit2go-web-core/components/ui/image-aspect-ratio";
export default {
  name: "EndBooking",
  components: {
    ImageAspectRatio,
  },
  data() {
    return {
      DateUtil,
      showTerms: false,
    };
  },
  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
      labels: "configuration/getLabels",
      categorySelected: "category/categorySelected",
      bookingFilters: "configuration/getBookingFilters",
      actualPrice: "configuration/getActualPrice",
      userLoged: "user/userLoged",
      userLanguage: "configuration/getUserLanguage",
    }),
  },
  methods: {
    getGoogleLink() {
      let link =
        "https://calendar.google.com/calendar/render?action=TEMPLATE&dates=";
      let pickUpDateIso = new Date(this.bookingFilters.pickUpDate);
      pickUpDateIso = pickUpDateIso
        .toISOString()
        .replace(/:/g, "")
        .replace(/-/g, "");
      let returnBackDateIso = new Date(this.bookingFilters.returnBackDate);
      returnBackDateIso = returnBackDateIso
        .toISOString()
        .replace(/:/g, "")
        .replace(/-/g, "");
      link = link + pickUpDateIso + "%2F" + returnBackDateIso + "&details=";
      let details =
        this.$t("common.vehicle") +
        ": " +
        this.categorySelected.name +
        ".  " +
        this.$t("common.pickUp") +
        ": " +
        this.bookingFilters.pickUpNameBranch +
        ".  " +
        this.$t("common.return") +
        ": " +
        this.bookingFilters.returnBackNameBranch;
      details = encodeURI(details);
      let location = this.bookingFilters.pickUpDirectionBranch;
      location = encodeURI(location);
      let title =
        this.$t("common.booking") +
        " " +
        this.$route.params.bookingInfo.bookingId;
      title = encodeURI(title);
      link = link + details + "&location=" + location + "&text=" + title;
      return link;
    },
    getOutlookLink() {
      let link = "https://outlook.live.com/calendar/0/deeplink/compose?body=";
      let details =
        this.$t("common.vehicle") +
        ": " +
        this.categorySelected.name +
        ".  " +
        this.$t("common.pickUp") +
        ": " +
        this.bookingFilters.pickUpNameBranch +
        ".  " +
        this.$t("common.return") +
        ": " +
        this.bookingFilters.returnBackNameBranch;
      details = encodeURI(details);
      link = link + details + "&enddt=";
      let pickUpDateIso = new Date(this.bookingFilters.pickUpDate);
      pickUpDateIso = pickUpDateIso.toISOString().replace(/:/g, "%3A");
      let returnBackDateIso = new Date(this.bookingFilters.returnBackDate);
      returnBackDateIso = returnBackDateIso.toISOString().replace(/:/g, "%3A");
      let location = this.bookingFilters.pickUpDirectionBranch;
      location = encodeURI(location);
      let title =
        this.$t("common.booking") +
        " " +
        this.$route.params.bookingInfo.bookingId;
      title = encodeURI(title);
      link =
        link +
        returnBackDateIso +
        "&location=" +
        location +
        "&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=" +
        pickUpDateIso +
        "&subject=" +
        title;
      return link;
    },
    getYahooLink() {
      let link = "https://calendar.yahoo.com/?desc=";
      let details =
        this.$t("common.vehicle") +
        ": " +
        this.categorySelected.name +
        ".  " +
        this.$t("common.pickUp") +
        ": " +
        this.bookingFilters.pickUpNameBranch +
        ".  " +
        this.$t("common.return") +
        ": " +
        this.bookingFilters.returnBackNameBranch;
      details = encodeURI(details);
      link = link + details + "&et=";
      let pickUpDateIso = new Date(this.bookingFilters.pickUpDate);
      pickUpDateIso = pickUpDateIso
        .toISOString()
        .replace(/:/g, "")
        .replace(/-/g, "");
      let returnBackDateIso = new Date(this.bookingFilters.returnBackDate);
      returnBackDateIso = returnBackDateIso
        .toISOString()
        .replace(/:/g, "")
        .replace(/-/g, "");
      let location = this.bookingFilters.pickUpDirectionBranch;
      location = encodeURI(location);
      let title =
        this.$t("common.booking") +
        " " +
        this.$route.params.bookingInfo.bookingId;
      title = encodeURI(title);
      link =
        link +
        returnBackDateIso +
        "&in_loc=" +
        location +
        "&st=" +
        pickUpDateIso +
        "&title=" +
        title +
        "&v=60";
      return link;
    },
    getICalLink() {
      let link =
        "data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0ADTSTART:";
      let pickUpDateIso = new Date(this.bookingFilters.pickUpDate);
      pickUpDateIso = pickUpDateIso
        .toISOString()
        .replace(/:/g, "")
        .replace(/-/g, "")
        .split(".000")
        .join("");
      let returnBackDateIso = new Date(this.bookingFilters.returnBackDate);
      returnBackDateIso = returnBackDateIso
        .toISOString()
        .replace(/:/g, "")
        .replace(/-/g, "")
        .split(".000")
        .join("");
      let location = this.bookingFilters.pickUpDirectionBranch.replace(
        /,/g,
        ""
      );
      location = encodeURI(location);
      let title =
        this.$t("common.booking") +
        " " +
        this.$route.params.bookingInfo.bookingId;
      title = encodeURI(title);
      link =
        link +
        pickUpDateIso +
        "%0ADTEND:" +
        returnBackDateIso +
        "%0ASUMMARY:" +
        title +
        "%0ALOCATION:" +
        location +
        "%0AEND:VEVENT%0AEND:VCALENDAR%0A";
      return link;
    },
  },
};
</script>

<style lang="scss" scoped></style>
