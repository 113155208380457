<template>
  <div
    class="age-filter flex"
    :class="
      type === 'horizontal' ? 'flex-row flex-grow-0 align-center' : 'flex-col'
    "
  >
    <div
      class=""
      :class="{
        'max-h-full flex-grow-0 pr-2 ': type === 'horizontal',
      }"
    >
      <h1 :style="{ color: colors.colorOnHeader }">
        {{ $t("driversAge") }}
      </h1>
    </div>
    <div
      class="flex-grow-0 flex flex-row"
      :class="{
        'align-center': type === 'horizontal',
        'w-64':
          this.$vuetify.breakpoint.name === 'sm' ||
          this.$vuetify.breakpoint.name === 'xs' ||
          this.$vuetify.breakpoint.name === 'md',
        'w-80':
          this.$vuetify.breakpoint.name === 'lg' ||
          this.$vuetify.breakpoint.name === 'xl',
      }"
    >
      <div
        class="w-12 h-8 m-2 shadow-md rounded-md flex justify-center align-center transform hover:-translate-y-1 hover:scale-105 cursor-pointer transition-all duration-500"
        :style="`background-color: ${
          ageSelected === '20' ? colors.colorPrimary : colors.colorSurfaceMedium
        }; color: ${
          ageSelected === '20'
            ? colors.colorOnPrimary
            : colors.colorOnSurfaceMedium
        };`"
        @click="
          ageSelected === '20' ? (ageSelected = null) : (ageSelected = '20')
        "
      >
        19-20
      </div>
      <div
        class="w-12 h-8 rounded-md flex justify-center m-2 shadow-md align-center transform hover:-translate-y-1 hover:scale-105 cursor-pointer transition-all duration-500"
        :style="`background-color: ${
          ageSelected === '24' ? colors.colorPrimary : colors.colorSurfaceMedium
        }; color: ${
          ageSelected === '24'
            ? colors.colorOnPrimary
            : colors.colorOnSurfaceMedium
        };`"
        @click="
          ageSelected === '24' ? (ageSelected = null) : (ageSelected = '24')
        "
      >
        21-24
      </div>
      <div
        class="w-12 h-8 rounded-md flex justify-center m-2 shadow-md align-center transform cursor-pointer transition-all duration-500 hover:-translate-y-1 hover:scale-105"
        :style="`background-color: ${
          ageSelected == '100' ? colors.colorPrimary : colors.colorSurfaceMedium
        }; color: ${
          ageSelected === '100'
            ? colors.colorOnPrimary
            : colors.colorOnSurfaceMedium
        };`"
        @click="
          ageSelected === '100' ? (ageSelected = null) : (ageSelected = '100')
        "
      >
        +25
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AgeFilter",
  components: {},
  props: {
    type: { required: false, type: String, default: "vertical" },
    minimumAge: { required: false, type: String, default: "" },
  },
  data() {
    return {
      ageSelected: null,
    };
  },
  watch: {
    ageSelected: function () {
      this.$emit("ageSelected", this.ageSelected);
    },
  },
  mounted() {
    if (this.minimumAge) {
      this.ageSelected = this.minimumAge;
    }
  },
  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
    }),
  },
  methods: {},
};
</script>
