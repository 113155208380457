<template>
  <div class="extras-list flex flex-col">
    <!-- Characteristics-->
    <h2
      class="leading-4 font-medium text-lg mt-12 lg:mt-10"
      :style="{ color: colors.colorOnSurface }"
    >
      {{ $t("common.vehicleCharacteristics") }}
    </h2>
    <div class="my-2 text-sm">
      <div
        class="inline-block mr-10"
        v-for="(attribute, index) in categorySelected.attributeList"
        :key="index"
      >
        <div class="pa-1 m-1 inline-block align-middle">
          <svg style="height: 24px; width: 24px" :fill="colors.colorPrimary">
            <path v-if="attribute.icon.path" :d="attribute.icon.path" />
          </svg>
        </div>
        <div
          class="inline-block align-middle"
          :style="{ color: colors.colorOnSurfaceMedium }"
        >
          {{
            attribute.supportedLangs[$i18n.locale.split("_")[0]]
              ? attribute.supportedLangs[$i18n.locale.split("_")[0]]
              : attribute.supportedLangs.en
          }}
        </div>
      </div>
    </div>

    <!-- Kms and petrol -->
    <div class="mt-6">
      <div class="align-middle">
        <h2
          class="leading-4 font-medium text-lg inline-block align-middle"
          :style="{ color: colors.colorOnSurface }"
        >
          {{ $t("common.kilometersAndPetrol") }}
        </h2>
      </div>
    </div>
    <div>
      <div class="inline-block align-middle">
        <div class="inline-block align-middle">
          <F2gIcon
            class="mt-2 mx-2"
            :value="'counter'"
            :stroke="colors.colorPrimary"
            :height="16"
            :width="25"
            :strokeWidth="4"
          />
        </div>
        <div
          class="mt-2 inline-block align-middle"
          :style="{ color: colors.colorOnSurfaceMedium }"
        >
          {{ categorySelected.kmBase }}
          {{ $t("common.includedKilometers") }}
        </div>
      </div>
      <div class="inline-block align-middle">
        <div class="inline-block align-middle">
          <F2gIcon
            class="ml-2 mr-2"
            :value="'gas-station'"
            :stroke="colors.colorPrimary"
            :height="16"
            :width="25"
            :strokeWidth="4"
          />
        </div>
        <div
          class="align-center inline-block align-middle relative"
          :style="{ color: colors.colorOnSurfaceMedium }"
          v-if="categorySelected.consumptionPenalization"
        >
          {{ categorySelected.consumptionPenalization }} €/l
          <div
            class="-left-10 ml-2 text-xs inline-block align-middle"
            :style="{ color: colors.colorOnSurfaceMedium }"
          >
            *{{ $t("common.extraKmCost") }}:
            {{ categorySelected.kmPriceExtra }}€/km
          </div>
        </div>

        <div
          class="inline-block align-middle"
          :style="{ color: colors.colorOnSurfaceMedium }"
          v-else
        >
          {{ "Lleno/lleno" }}
        </div>
      </div>
    </div>

    <!-- Franchises -->
    <h2
      class="leading-4 font-medium text-lg pt-1 mt-10"
      :style="{ color: colors.colorOnSurface }"
    >
      {{ $t("common.franchise") }}
    </h2>
    <div class="flex flex-col md:flex-row mt-2 rounded">
      <div>
        <v-radio-group
          v-model="radioGroupFranchise"
          v-for="(franchise, index) in franchises"
          :key="index"
          hide-details
          :error-messages="radioGroupFranchiseErrors"
          @change="setFranchise()"
        >
          <v-radio
            :key="franchise.type"
            :value="franchise"
            :color="colors.colorPrimary"
          >
            <template v-slot:label>
              <div :style="{ color: colors.colorOnSurfaceMedium }">
                {{ selectLabelFranchise(franchise) }}
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>

      <div
        class="w-xl md:w-1/2 bg-gray-200 md:ml-4 mt-4 md:mt-0 rounded"
        v-if="radioGroupFranchise"
      >
        <div
          class="p-2 shadow rounded-t flex justify-between"
          :style="{ backgroundColor: colors.colorHeader }"
        >
          <div class="flex flex-row align-center">
            <F2gIcon
              class="svg mr-2"
              :value="'info'"
              :fill="colors.colorOnHeader"
              file="icons_solid.svg"
              solid
              :height="20"
              :width="20"
              :strokeWidth="4"
            />
            <h3
              class="title font-normal roun"
              :style="{ color: colors.onHeader }"
            >
              {{ selectLabelFranchise(radioGroupFranchise) }}
            </h3>
          </div>
        </div>
        <div
          class="flex-col p-2 rounded-b"
          :style="{
            backgroundColor: colors.colorOnBackground,
            color: colors.colorOnBackgroundText,
          }"
        >
          <span>{{ selectLabelFranchiseAlert(radioGroupFranchise) }}</span>
          <p class="text-gray-500 text-xs pt-2">
            {{ $t("common.dayRate") }}:
            {{
              BillingUtil.formatBillingValue(
                radioGroupFranchise.pricePerTime,
                radioGroupFranchise.currency
              )
            }}
          </p>
        </div>
        <div
          class="flex flex-col--without-important md:flex-row justify-between"
        >
          <div class="justify-start ml-2"></div>
        </div>
      </div>
    </div>

    <!-- Extras List -->
    <h2
      v-if="categorySelected.extraList && categorySelected.extraList.length"
      class="leading-4 font-medium text-lg pt-1 mb-0 mt-10"
      :style="{ color: colors.colorOnSurface }"
    >
      {{ $t("common.extraEquipment") }}
    </h2>
    <div
      class="flex flex-col flex-wrap my-4"
      v-if="categorySelected.extraList && categorySelected.extraList.length"
    >
      <category-additionals-extras
        :itemsList="categorySelected.extraList"
        :type="'extras'"
        @addedExtra="extraAdded"
      />
    </div>
    <div
      class="flex flex-col flex-wrap my-4"
      v-if="
        categorySelected.additionalServicesList &&
        categorySelected.additionalServicesList.length
      "
    >
      <p class="leading-4 font-medium text-lg pt-1 mb-0">
        {{ $t("common.additionalServices") }}
      </p>
      <category-additionals-extras
        :itemsList="categorySelected.additionalServicesList"
        :type="'additional'"
        @addedExtra="extraAdded"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CategoryAdditionalsExtras from "@/components/category-additionals-extras.vue";
import { EventBus } from "@/flit2go-web-core/util/event-bus.js";
import { required } from "vuelidate/lib/validators";

export default {
  name: "ExtrasList",
  components: {
    CategoryAdditionalsExtras,
  },
  data() {
    return {
      radioGroupFranchise: null,
      franchises: null,
    };
  },
  validations: {
    radioGroupFranchise: { required },
  },
  computed: {
    ...mapState(["category", "configuration"]),
    ...mapGetters({
      colors: "configuration/getColors",
      categorySelected: "category/categorySelected",
      bookingFilters: "configuration/getBookingFilters",
    }),
    borderColor() {
      return `border-color: ${this.colors.colorPrimary}`;
    },

    radioGroupFranchiseErrors() {
      const errors = [];
      if (!this.$v.radioGroupFranchise.$dirty) {
        !this.$v.radioGroupFranchise.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
  },
  methods: {
    getFranchises() {
      this.$store
        .dispatch(
          "category/getFranchisesByCategoryId",
          this.categorySelected.id
        )
        .then((resp) => {
          this.franchises = resp.data;
        });
    },
    selectLabelFranchise(franchise) {
      if (franchise.type == 2) {
        return this.$t("common.withoutReductionFranchise");
      }
      if (franchise.type == 1) {
        return this.$t("common.withReductionFranchise", {
          number: franchise.franchisePrice,
        });
      }
      if (franchise.type == 0) {
        return this.$t("common.withoutFranchise");
      }
    },
    selectLabelFranchiseAlert(franchise) {
      if (franchise.type == 2) {
        return this.$t("common.damageFranchiseAlert", {
          number: franchise.franchisePrice,
        });
      }
      if (franchise.type == 1) {
        return this.$t("common.damageFranchiseAlert", {
          number: franchise.franchisePrice,
        });
      }
      if (franchise.type == 0) {
        return this.$t("common.damageWithoutFranchiseAlert");
      }
    },
    extraAdded(arraySelecteds) {
      if (arraySelecteds.type == "additional") {
        arraySelecteds.extras.forEach((extraOfList, index) => {
          if (
            extraOfList.type &&
            extraOfList.type.code === "ADD" &&
            !extraOfList.name
          ) {
            arraySelecteds.extras.splice(index, 1);
          }
        });
        this.$store.commit(
          "configuration/SET_ADDITIONALS_SELECTED",
          arraySelecteds.extras
        );
      } else {
        this.$store.commit(
          "configuration/SET_EXTRAS_SELECTED",
          arraySelecteds.extras
        );
      }
      EventBus.$emit("getPrice");
    },
    setFranchise() {
      this.$store.commit(
        "category/SET_FRANCHISE_SELECTED",
        this.radioGroupFranchise
      );
      EventBus.$emit("getPrice");
    },
    validate() {
      this.$v.$touch();
      this.$emit("validation", {
        valid: !this.$v.$invalid,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-container {
  height: 35px;
  width: 35px;
  border-radius: 100%;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 250ms ease-in;
}
</style>
