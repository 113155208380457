<template>
  <div class="confirm-data">
    <div class="flex flex-col my-4">
      <h2
        class="leading-4 font-medium text-lg mt-4"
        :style="{ color: colors.colorOnBackground }"
      >
        {{ $t("common.dateAndPlace") }}
      </h2>
      <!-- CategoryAttributes -->
      <div class="flex flex-col md:flex-row justify-start">
        <div
          class="flex flex-col mt-4 p-2 sm:mr-4 rounded-lg border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
          }"
        >
          <div class="mb-2">
            <F2gIcon
              class="inline-block align-middle mr-1"
              :stroke="colors.colorPrimary"
              :value="'marker'"
              :height="22"
              :width="22"
              :strokeWidth="4"
            />
            <div
              class="inline-block align-middle"
              :style="{ color: colors.colorOnSurface }"
            >
              {{ bookingFilters.pickUpNameBranch }}
            </div>
          </div>
          <div>
            <F2gIcon
              class="inline-block align-middle mr-1"
              :stroke="colors.colorPrimary"
              :value="'calendar'"
              :height="22"
              :width="22"
              :strokeWidth="4"
            />
            <div
              class="inline-block align-middle"
              :style="{ color: colors.colorOnSurface }"
            >
              {{ DateUtil.formatCustomDate(Number(bookingFilters.pickUpDate)) }}
            </div>
          </div>
        </div>
        <div
          class="flex flex-col mt-4 p-2 sm:mr-2 rounded-lg border"
          :style="{
            backgroundColor: colors.colorSurface,
            borderColor: colors.colorSurfaceMedium,
          }"
        >
          <div>
            <F2gIcon
              class="inline-block align-middle mr-1"
              :stroke="colors.colorPrimary"
              :value="'marker'"
              :height="22"
              :width="22"
              :strokeWidth="4"
            />
            <div
              class="inline-block align-middle"
              :style="{ color: colors.colorOnSurface }"
            >
              {{ bookingFilters.returnBackNameBranch }}
            </div>
          </div>
          <div class="mt-2">
            <F2gIcon
              class="inline-block align-middle mr-1"
              :stroke="colors.colorPrimary"
              :value="'calendar'"
              :height="22"
              :width="22"
              :strokeWidth="4"
            />
            <div
              class="inline-block align-middle"
              :style="{ color: colors.colorOnSurface }"
            >
              {{
                DateUtil.formatCustomDate(Number(bookingFilters.returnBackDate))
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="my-4">
      <driver-selected-data
        ref="driverSelectedData"
        @validation="setDriverSelectedDataValidation"
      />
    </div>
    <!-- VehicleSelected -->
    <div class="flex flex-col flex-wrap my-4">
      <h2
        class="leading-4 font-medium text-lg pt-1"
        :style="{ color: colors.colorOnBackground }"
      >
        {{ $t("common.selectedVehicle") }}
      </h2>
      <div
        class="p-4 rounded-lg mt-8 shadow border"
        :style="{
          backgroundColor: colors.colorSurface,
          borderColor: colors.colorSurfaceMedium,
        }"
      >
        <vehicle-selected-card />
      </div>
    </div>
    <div class="flex flex-col flex-wrap mt-4">
      <extras-selected-list />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VehicleSelectedCard from "@/components/vehicle-selected-card";
import ExtrasSelectedList from "@/components/extras-selected-list";
import DriverSelectedData from "@/components/driver-selected-data";
import DateUtil from "@/flit2go-web-core/util/dateUtil";

export default {
  name: "ConfirmData",
  components: {
    VehicleSelectedCard,
    ExtrasSelectedList,
    DriverSelectedData,
  },
  data() {
    return {
      DateUtil,
      bookingInfo: null,
    };
  },
  mounted() {
    if (!this.actualPrice) {
      this.getPrice();
    }
  },
  computed: {
    ...mapGetters({
      bookingFilters: "configuration/getBookingFilters",
      colors: "configuration/getColors",
      actualPrice: "configuration/getActualPrice",
      additionalsSelected: "configuration/getAdditionalsSelected",
      extrasSelected: "configuration/getExtrasSelected",
      franchiseSelected: "category/franchiseSelected",
      categorySelected: "category/categorySelected",
    }),
    borderColor() {
      return `border-color: ${this.colors.primary}`;
    },
  },
  methods: {
    getPrice() {
      if (this.categorySelected && this.bookingFilters) {
        let data = {};
        data.categoryId = this.categorySelected.id;
        data.data = {
          pickUpDate: this.bookingFilters.pickUpDate,
          returnBackDate: this.bookingFilters.returnBackDate,
          pickUpBranch: this.bookingFilters.pickUpBranch,
          returnBackBranch: this.bookingFilters.returnBackBranch,
          franchise: this.franchiseSelected
            ? this.franchiseSelected.franchiseType
            : -1,
          extra: [],
          additionServices: [],
          earlyPay: this.categorySelected.earlyPay,
        };
        if (this.extrasSelected[0]) {
          Object.keys(this.extrasSelected).forEach((extra) => {
            data.data.extra.push(this.extrasSelected[extra].id);
          });
        }

        if (this.additionalsSelected[0]) {
          Object.entries(this.additionalsSelected).forEach(([key, value]) => {
            data.data.additionServices.push(value);
          });
        }

        if (data.categoryId) {
          this.$store
            .dispatch("configuration/getPrice", data)
            .then((resp) => {
              this.bookingInfo = resp.data;
              let totalPrice = 0;
              if (this.bookingInfo.priceTotal) {
                totalPrice =
                  this.bookingInfo.priceTotal.value +
                  Number(
                    (
                      (this.bookingInfo.priceTotal.value *
                        this.bookingInfo.priceTotal.tax) /
                      100
                    ).toFixed(2)
                  );
              }
              this.$emit("priceTotal", totalPrice);
            })
            .catch((err) => {
              this.$showMessage.error(this.$t("errors.generic"));
            });
        }
      } else {
        this.bookingInfo = null;
      }
    },
    setDriverSelectedDataValidation(value) {
      this.$emit("validateConfirm", value);
    },
    validateData() {
      if (this.$refs && this.$refs.driverSelectedData) {
        this.$refs.driverSelectedData.validateDataCreated();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
