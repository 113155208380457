import Vue from "vue";
import Vuex from "vuex";

import authModule from "./authModule";
import dashboardModule from "./dashboardModule";
import settingsModule from "./settingsModule";
import profileModule from "./profileModule";
import notificationsModule from "./notificationsModule";
import imagesModule from "./imagesModule";
import paymentModule from "./paymentModule";
import promotionModule from "./promotionModule";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    dashboard: dashboardModule,
    auth: authModule,
    settings: settingsModule,
    profile: profileModule,
    notifications: notificationsModule,
    images: imagesModule,
    payment: paymentModule,
    promotion: promotionModule,
  },
});

export default store;
