<template>
  <div class="categoryAdditionalsExtrasSelected">
    <div class="px-3 button-rounded flex-wrap">
      <div class="flex flex-row flex-wrap mt-4">
        <v-flex class="md:w-1/2 px-2 md:px-4">
          <v-text-field
            ref="textFieldName"
            v-model="additionalDriver.name"
            :outlined="true"
            :error-messages="nameErrors"
            :backgroundColor="colors.colorSurfaceMedium"
            :color="colors.colorOnSurfaceMedium"
            ><template #label>
              <span
                :style="`color:${colors.colorOnSurfaceMedium} !important`"
                >{{ $t("common.name") }}</span
              >
            </template>
          </v-text-field>
        </v-flex>
        <v-flex class="md:w-1/2 px-2 md:px-4">
          <v-text-field
            ref="textFieldSurnames"
            v-model="additionalDriver.surnames"
            :outlined="true"
            :error-messages="surnamesErrors"
            :backgroundColor="colors.colorSurfaceMedium"
            :color="colors.colorOnSurfaceMedium"
            ><template #label>
              <span
                :style="`color:${colors.colorOnSurfaceMedium} !important`"
                >{{ $t("common.surnames") }}</span
              >
            </template>
          </v-text-field>
        </v-flex>
        <v-flex class="md:w-1/2 px-2 md:px-4">
          <v-text-field
            ref="textFieldNif"
            v-model="additionalDriver.nif"
            :outlined="true"
            :error-messages="nifErrors"
            :backgroundColor="colors.colorSurfaceMedium"
            :color="colors.colorOnSurfaceMedium"
            ><template #label>
              <span
                :style="`color:${colors.colorOnSurfaceMedium} !important`"
                >{{ $t("forms.nif") }}</span
              >
            </template>
          </v-text-field>
        </v-flex>
        <v-flex class="md:w-1/2 px-2">
          <DatePickerFW
            :label="$t('common.validityDate')"
            :clear="'' == additionalDriver.nifValidDate"
            :minDate="DateUtil.currentDateBasicFormat()"
            :outlined="true"
            @form="setReturnValidityDate"
            :errors="validityDateErrors"
            :backgroundColor="colors.colorSurfaceMedium"
            :inputColor="colors.colorOnSurfaceMedium"
          />
        </v-flex>
        <div class="flex justify-end w-full">
          <v-btn
            class="w-1/4 mb-2 border-2 rounded-config30"
            :style="{
              backgroundColor: colors.colorPrimary,
              color: colors.colorOnPrimary,
            }"
            @click="emitUser"
          >
            {{ $t("mobile.damages.validate") }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DatePickerFW from "@/modules/v1/Components/web-core/date-pickerFW.vue";
import DateUtil from "@/flit2go-web-core/util/dateUtil";
import { required } from "vuelidate/lib/validators";

export default {
  name: "categoryAdditionalsExtrasSelected",
  components: {
    DatePickerFW,
  },
  props: {
    extra: { required: false, type: Object },
  },
  data: () => ({
    DateUtil,
    additionalDriver: {
      name: null,
      surnames: null,
      nif: null,
      nifValidDate: null,
    },
  }),
  validations: {
    additionalDriver: {
      name: { required },
      surnames: { required },
      nif: { required },
      nifValidDate: { required },
    },
  },
  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
    }),
    nameErrors() {
      const errors = [];
      if (this.$v.additionalDriver.name.$dirty) {
        !this.$v.additionalDriver.name.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    surnamesErrors() {
      const errors = [];
      if (this.$v.additionalDriver.surnames.$dirty) {
        !this.$v.additionalDriver.surnames.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    nifErrors() {
      const errors = [];
      if (this.$v.additionalDriver.nif.$dirty) {
        !this.$v.additionalDriver.nif.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    validityDateErrors() {
      const errors = [];
      if (this.$v.additionalDriver.nifValidDate.$dirty) {
        !this.$v.additionalDriver.nifValidDate.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
  },
  mounted() {
    this.setInputColor("textFieldName");
    this.setInputColor("textFieldSurnames");
    this.setInputColor("textFieldNif");
  },
  methods: {
    setReturnValidityDate(time) {
      this.additionalDriver.nifValidDate = time;
    },
    emitUser() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.additionalDriver.id = this.extra.id;
        this.additionalDriver.price = this.extra.price;
        this.additionalDriver.type = Object.assign({}, this.extra.type);
        this.additionalDriver.priceModality = Object.assign(
          {},
          this.extra.priceModality
        );
        this.additionalDriver.priceNoTax = this.extra.priceNoTax;
        this.$emit("addUser", this.additionalDriver);
        this.clear();
      }
    },
    clear() {
      this.$v.$reset();
      this.additionalDriver = {
        id: "",
        type: {},
        name: "",
        surnames: "",
        nif: "",
        nifValidDate: "",
        priceModality: {},
        price: "",
      };
    },
    setInputColor(refElement) {
      const textField = this.$refs[refElement];
      if (textField) {
        const input = textField.$refs["input"];
        if (input) {
          input.style.color = this.colors.colorOnSurfaceMedium;
        }
      }
    },
  },
};
</script>
