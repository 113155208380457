import service from '@/flit2go-web-core/services/'

export default {
    namespaced: true,
    state: {
        status: '',
        notificationsQueue: [],
        existsMoreBellNotifications: false,
        profileNotifications: [],
        existsMoreProfileNotifications: true,
        tasks: {
            user: '',
            comment: '',
            damage: '',
            issue: '',
            penalization: '',
            reserveReduction: ''
        }
    },
    mutations: {
        ['STATUS_SUCCESS']: (state) => {
            state.status = 'success'
        },
        ['STATUS_LOADING']: (state) => {
            state.status = 'loading'
        },
        ['STATUS_ERROR']: (state) => {
            state.status = 'error'
        },
        ['SET_TASKS']: (state, tasks) => {
            state.tasks.user = tasks.user ? tasks.user : 0;
            state.tasks.comment = tasks.comment ? tasks.comment : 0;
            state.tasks.damage = tasks.damage ? tasks.damage : 0;
            state.tasks.issue = tasks.incidence ? tasks.incidence : 0;
            state.tasks.penalization = tasks.penalization ? tasks.penalization : 0;
            state.tasks.reserveReduction = tasks.reserveReduction ? tasks.reserveReduction : 0;
        },
        ['SET_TASKS_USER']: (state, tasks) => {
            state.tasks.user = tasks.user ? tasks.user : 0;
        },
        ['ADD_NOTIFICATION']: (state, notification) => {
            state.notificationsQueue.unshift(notification)
            if (state.notificationsQueue.length >= 5)
                state.notificationsQueue.pop()
        },
        ['INIT_NOTIFICATION']: (state, notifications) => {
            state.notificationsQueue = notifications
        },
        ['REMOVE_NOTIFICATION']: (state, notification) => {
            const index = state.notificationsQueue.indexOf(notification)
            state.notificationsQueue.splice(index, 1);
        },
        ['SET_MORE_BELL_NOTIS']: (state, value) => {
            state.existsMoreBellNotifications = value;
        },
        ['SET_MORE_PROFILE_NOTIFICATION']: (state, value) => {
            state.existsMoreProfileNotifications = value
        },
        ['SET_PROFILE_NOTIS']: (state, value) => {
            if (state.profileNotifications.length == 0) {
                state.profileNotifications = value;
            } else {
                value.forEach((newNoti) => {
                    !state.profileNotifications.find((oldNoti) => {
                        return oldNoti.id == newNoti.id
                    }) && state.profileNotifications.push(newNoti)
                })
            }
        },
        ['EMPTY_PROFILE_NOTIS']: (state) => {
            state.profileNotifications = []
            state.existsMoreProfileNotifications = true
        },
        ['UPDATE_VIEWED_NOTI']: (state, { notiId, value }) => {
            state.profileNotifications.forEach((noti) => {
                if (noti.id == notiId)
                    noti.view = value
            })
        }, 
        ['UPDATE_VIEWED_NOTIS']: (state, { selectedNotifications, value }) => {
            selectedNotifications.forEach(itemId => {
                const notification = state.profileNotifications.find((noti) => noti.id === itemId);
                notification.view = value;
            })
        }
    },
    actions: {
        loadPersistentBellNotifications({ commit }) {
            commit('INIT_NOTIFICATION', [])
            return new Promise((resolve, reject) => {
                commit('STATUS_LOADING')
                service.notification.loadPersistentNotifications({
                    page: 0,
                    size: 4,
                    q: "view==false",
                    sort: 'created,DESC'
                })
                    .then(resp => {
                        if (resp.status === 200) {
                            const notificationsFormated = resp.data.content.map((notification) => {
                                let parsedNoti = JSON.parse(notification.notificationText)
                                parsedNoti.id = notification.id
                                parsedNoti.view = notification.view
                                return parsedNoti;
                            })
                            commit('INIT_NOTIFICATION', notificationsFormated)
                            commit('SET_MORE_BELL_NOTIS', !resp.data.last)
                        } else {
                            commit('SET_MORE_BELL_NOTIS', false)
                        }
                        commit('STATUS_SUCCESS')
                        resolve(resp)
                    })
                    .catch((err) => {
                        commit('STATUS_ERROR')
                        reject(err)
                    })
            })
        },
        loadProfileNotifications({ commit }, { page, size, q, sort }) {
            return new Promise((resolve, reject) => {
                commit('STATUS_LOADING')
                service.notification.loadPersistentNotifications({ page, size, q, sort })
                    .then(resp => {
                        if (resp.status === 200) {
                            const notificationsFormated = resp.data.content.map((notification) => {
                                let parsedNoti = JSON.parse(notification.notificationText)
                                parsedNoti.id = notification.id
                                parsedNoti.view = notification.view
                                return parsedNoti;
                            })
                            commit('SET_PROFILE_NOTIS', notificationsFormated)
                            commit('SET_MORE_PROFILE_NOTIFICATION', !resp.data.last)
                        } else {
                            commit('SET_MORE_PROFILE_NOTIFICATION', false)
                        }
                        commit('STATUS_SUCCESS')
                        resolve(resp)
                    })
                    .catch((err) => {
                        commit('STATUS_ERROR')
                        reject(err)
                    })
            })
        },
        markNotiAsViewed({ commit, dispatch }, { notiId, viewed }) {
            return new Promise((resolve, reject) => {
                commit('STATUS_LOADING')
                service.notification.markNotiAsViewed(notiId, viewed)
                    .then(resp => {
                        commit('STATUS_SUCCESS')
                        commit("UPDATE_VIEWED_NOTI", { notiId, value: viewed })
                        dispatch("loadPersistentBellNotifications")
                        resolve(resp)
                    })
                    .catch((err) => {
                        commit('STATUS_ERROR')
                        reject(err)
                    })
            })
        },
        deleteNotification({ commit }, notiId) {
            return new Promise((resolve, reject) => {
                commit('STATUS_LOADING')
                service.notification.deleteNotification(notiId)
                    .then(resp => {
                        commit('STATUS_SUCCESS')
                        resolve(resp)
                    })
                    .catch((err) => {
                        commit('STATUS_ERROR')
                        reject(err)
                    })
            })
        },
        deleteSelectedNotifications({ commit }, selectedNotifications) {
            return new Promise((resolve, reject) => {
                commit('STATUS_LOADING')
                service.notification.deleteSelectedNotifications(selectedNotifications)
                    .then(resp => {
                        commit('STATUS_SUCCESS')
                        resolve(resp)
                    })
                    .catch((err) => {
                        commit('STATUS_ERROR')
                        reject(err)
                    })
            })
        },
        markSelectedNotificationsAsSeen({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                commit('STATUS_LOADING')
                service.notification.markSelectedNotificationsAsSeen(data.selectedNotifications, data.viewed)
                    .then(resp => {
                        commit('STATUS_SUCCESS')
                        commit("UPDATE_VIEWED_NOTIS", { selectedNotifications: data.selectedNotifications, value: data.viewed })
                        dispatch("loadPersistentBellNotifications")
                        resolve(resp)
                    })
                    .catch((err) => {
                        commit('STATUS_ERROR')
                        reject(err)
                    })
            })
        },
    }
}