<template>
  <div class="categoryAdditionalsExtras w-full">
    <!-- Main Driver -->
    <div
      v-for="(extra, index) in extraServicesTypes"
      :key="index"
      class="mb-4 px-0 py-2 button-rounded rounded-3xl transition-all duration-500 border-black transform shadow hover:shadow-md hover:-translate-y-1 cursor-pointer"
      :class="{
        'min-h-20 flex-col-center': selected,
      }"
      :style="{ backgroundColor: colors.colorSurfaceMedium }"
      @click.self="addExtraService(extra)"
    >
      <div
        class="flex flex-row justify-between align-center max-h-full"
        @click.self="addExtraService(extra)"
      >
        <div
          class="flex flex-row align-center"
          @click.self="addExtraService(extra)"
        >
          <div
            class="h-16 min-w-min max-w-xs"
            v-if="extra.img"
            @click="addExtraService(extra)"
          >
            <div class="w-24 max-h-full">
              <image-aspect-ratio
                class="flex-col-center mr-2"
                :img="extra.img"
                minHeight="4rem"
                maxHeight="4rem"
                min-width="4rem"
                maxWidth="4rem"
              />
            </div>
          </div>
          <F2gIcon
            v-else-if="$PageUtils.getObjectNameLower(extra.type).length > 0"
            class="mx-2 ml-4"
            :stroke="colors.colorOnSurfaceMedium"
            :strokeWidth="
              extra.type.code ===
                $CONSTANTS.CATEGORY.SERVICE.PICK_UP_DELIVERY ||
              extra.type.code === $CONSTANTS.CATEGORY.SERVICE.RETURN_DELIVERY
                ? 3
                : 2
            "
            :value="getAdditionalIcon(extra.type && extra.type.code)"
            @click="addExtraService(extra)"
          />
          <div
            class="flex flex-col flex-wrap sm:flex-row"
            @click.self="addExtraService(extra)"
          >
            <div
              class="flex-col-center max-w-4/12"
              style="
                word-break: break-word;
                overflow-wrap: break-word;
                word-wrap: break-word;
              "
              :style="{ color: colors.colorOnSurfaceMedium }"
              @click="addExtraService(extra)"
            >
              {{
                extra.type && extra.type.name
                  ? extra.type.code ===
                      $CONSTANTS.CATEGORY.SERVICE.ADDITIONAL_DRIVER && selected
                    ? extra.name + " " + extra.surnames
                    : $PageUtils.getObjectNameLower(extra.type)
                  : extra.name
              }}
              {{
                selected &&
                extra.type &&
                extra.type.code == $CONSTANTS.CATEGORY.SERVICE.LUGGAGE
                  ? "( x" + extra.numberPackages + " )"
                  : ""
              }}
              <span
                v-if="
                  extra.mandatory &&
                  !selected &&
                  ((extra.type.code ===
                    $CONSTANTS.CATEGORY.SERVICE.PICK_UP_DELIVERY &&
                    (pickUpAddressErrors.length > 0 ||
                      pickUpAddress.length === 0)) ||
                    (extra.type.code ===
                      $CONSTANTS.CATEGORY.SERVICE.RETURN_DELIVERY &&
                      (deliveryAddressErrors.length > 0 ||
                        deliveryAddress.length === 0)))
                "
                style="color: #b71c1c !important"
                >{{
                  $vuetify.breakpoint.name === "xs" ||
                  $vuetify.breakpoint.name === "sm"
                    ? "*"
                    : $t("mandatory")
                }}</span
              >
            </div>
            <div
              class="flex flex-row flex-none justify-start items-center ml-2"
              v-if="
                !selected &&
                showAddStorage &&
                extra.type &&
                extra.type.code === $CONSTANTS.CATEGORY.SERVICE.LUGGAGE
              "
            >
              <F2gIcon
                v-if="storage > 1"
                class="mr-1 cursor-pointer"
                :value="'rounded-minus'"
                :stroke="colors.colorPrimary"
                :height="20"
                :width="20"
                :strokeWidth="7"
                @click="deleteStorage(extra)"
              />
              <span class="mr-1 font-bold">{{ storage }}UD</span>
              <F2gIcon
                v-if="storage < extra.maxNumber"
                class="mr-1 cursor-pointer"
                :value="'rounded-add'"
                :stroke="colors.colorPrimary"
                :height="20"
                :width="20"
                :strokeWidth="7"
                @click="addStorage(extra)"
              />
            </div>
          </div>
        </div>
        <div
          class="flex flex-row items-center justify-end"
          :style="{ color: colors.colorOnSurfaceMedium }"
          @click="addExtraService(extra)"
        >
          <div
            v-if="getExtraPrice(extra) !== ''"
            class="text-right my-5"
            :class="{ 'mr-4': selected }"
            style="min-width: 50px"
          >
            {{ getExtraPrice(extra) }} /
            {{
              extra.priceModality &&
              extra.priceModality.code === $CONSTANTS.CATEGORY.PRICE.DAY
                ? $t("common.day")
                : extra.priceModality &&
                  extra.priceModality.code === $CONSTANTS.CATEGORY.PRICE.HOUR
                ? $t("common.hour").toLowerCase()
                : $t("common.unit").toLowerCase()
            }}
          </div>
          <v-checkbox
            readonly
            class="ml-2 pr-2"
            v-if="!selected && type === 'extras'"
            :value="extra"
            v-model="extrasChecked"
            :color="colors.colorOnSurfaceMedium"
            :backgroundColor="colors.colorSurfaceMedium"
          />
          <v-checkbox
            class="ml-2 pr-2"
            readonly
            v-if="!selected && type === 'additional'"
            :disabled="extra.mandatory"
            :value="extra"
            v-model="extrasSelected"
            :color="colors.colorOnSurfaceMedium"
            :backgroundColor="colors.colorSurfaceMedium"
            @change="emitExtras"
          >
          </v-checkbox>
        </div>
      </div>
      <div
        class="mx-4"
        v-show="
          extra.type &&
          (extra.type.code === $CONSTANTS.CATEGORY.SERVICE.PICK_UP_DELIVERY ||
            extra.type.code === $CONSTANTS.CATEGORY.SERVICE.RETURN_DELIVERY)
        "
      >
        <div
          v-if="
            (!selected &&
              extra.type &&
              extra.type.code ===
                $CONSTANTS.CATEGORY.SERVICE.PICK_UP_DELIVERY &&
              showPickUpAddress) ||
            (showDeliveryAddress &&
              extra.type &&
              extra.type.code === $CONSTANTS.CATEGORY.SERVICE.RETURN_DELIVERY)
          "
          class="mb-2 flex align-center"
        >
          <F2gIcon
            v-if="$PageUtils.getObjectNameLower(extra.informativeText)"
            class="svg mr-2"
            :value="'info'"
            :fill="colors.colorOnSurfaceMedium"
            file="icons_solid.svg"
            solid
            :height="20"
            :width="20"
            :strokeWidth="4"
          />
          <span :style="`color:${colors.colorOnSurfaceMedium}`">
            {{ $PageUtils.getObjectNameLower(extra.informativeText) }}
          </span>
        </div>
        <div
          class="flex flex-row justify-start items-center ml-2"
          v-if="
            !selected &&
            extra.type &&
            extra.type.code === $CONSTANTS.CATEGORY.SERVICE.PICK_UP_DELIVERY
          "
        >
          <v-layout column style="position: relative" v-if="showPickUpAddress">
            <v-text-field
              v-if="showPickUpAddress"
              ref="pickUpAddressTextField"
              outlined
              v-model="pickUpAddress"
              v-on:input="debounceInput('pickUp')"
              :error-messages="pickUpAddressErrors"
              :backgroundColor="colors.colorSurfaceMedium"
              :color="colors.colorOnSurfaceMedium"
              counter="250"
              maxlength="250"
              @input="$v.pickUpAddress.$touch()"
              @blur="$v.pickUpAddress.$touch()"
              ><template #label>
                <span
                  :style="`color:${colors.colorOnSurfaceMedium} !important`"
                  >{{ $t("pickUpAddress") }}</span
                >
              </template></v-text-field
            >
            <div
              class="results"
              v-if="
                filters.googlePlacesApiKey && directionResultsPickUp.length > 0
              "
              :style="`color:${
                colors.colorSurfaceMedium
              } !important;margin-top: ${
                pickUpAddressErrors.length > 0 ? '0px' : '-30px'
              };transition: margin-top 0.2s ease-in`"
            >
              <v-layout column v-on-clickaway="closeDirectionResultsPickUp">
                <span
                  v-for="(direction, index) in directionResultsPickUp"
                  :key="index"
                  class="results__item mt-2 ml-2 mr-2"
                  :style="`color:${colors.colorOnSurfaceMedium} !important`"
                  @click="ubicationSelected(direction, 'pickUpAddress')"
                >
                  <F2gIcon
                    class="results__item-icon mr-4"
                    value="ubication"
                    :stroke="colors.colorPrimary"
                    :strokeWidth="5"
                  />
                  {{
                    direction.hasOwnProperty("description")
                      ? direction.description
                      : direction.formatted_address
                  }}
                </span>
              </v-layout>
            </div>
          </v-layout>
        </div>
        <div
          class="flex flex-row justify-start items-center ml-2"
          v-if="
            !selected &&
            extra.type &&
            extra.type.code === $CONSTANTS.CATEGORY.SERVICE.RETURN_DELIVERY
          "
        >
          <v-layout
            column
            style="position: relative"
            v-if="showDeliveryAddress"
          >
            <v-text-field
              ref="deliveryAddressTextField"
              outlined
              v-model="deliveryAddress"
              v-on:input="debounceInput('delivery')"
              :error-messages="deliveryAddressErrors"
              :backgroundColor="colors.colorSurfaceMedium"
              :color="colors.colorOnSurfaceMedium"
              counter="250"
              maxlength="250"
              @input="$v.deliveryAddress.$touch()"
              @blur="$v.deliveryAddress.$touch()"
              ><template #label>
                <span
                  :style="`color:${colors.colorOnSurfaceMedium} !important`"
                  >{{ $t("deliveryAddress") }}</span
                >
              </template></v-text-field
            >
            <div
              class="results"
              v-if="
                filters.googlePlacesApiKey &&
                directionResultsDelivery.length > 0
              "
              :style="`color:${
                colors.colorSurfaceMedium
              } !important;margin-top: ${
                deliveryAddressErrors.length > 0 ? '0px' : '-30px'
              };transition: margin-top 0.2s ease-in`"
            >
              <v-layout column v-on-clickaway="closeDirectionResultsDelivery">
                <span
                  v-for="(direction, index) in directionResultsDelivery"
                  :key="index"
                  :style="`color:${colors.colorOnSurfaceMedium} !important`"
                  class="results__item mt-2 ml-2 mr-2"
                  @click="ubicationSelected(direction, 'deliveryAddress')"
                >
                  <F2gIcon
                    class="results__item-icon mr-4"
                    value="ubication"
                    :stroke="colors.colorPrimary"
                    :strokeWidth="5"
                  />
                  {{
                    direction.hasOwnProperty("description")
                      ? direction.description
                      : direction.formatted_address
                  }}
                </span>
              </v-layout>
            </div>
          </v-layout>
        </div>
      </div>
      <div
        class="my-4"
        v-show="
          !selected &&
          showCreateUser &&
          extra.type &&
          extra.type.code === $CONSTANTS.CATEGORY.SERVICE.ADDITIONAL_DRIVER
        "
      >
        <span v-if="usersValidated.length">
          <span v-for="(user, index) in usersValidated" :key="index">
            <extra-driver-validate
              class="my-2"
              :additionalDriver="user"
              :id="index"
              @deleteUser="deleteUser"
          /></span>
        </span>
        <v-btn
          v-if="!showAddExtra && usersValidated.length < extra.maxNumber"
          class="ml-3 border-2"
          color="primary"
          outlined
          @click="showAddExtra = true"
        >
          {{ $t("common.newAdditionalDriver") }}
        </v-btn>
        <category-additionals-extras-selected
          v-if="usersValidated.length < extra.maxNumber && showAddExtra"
          :extra="extra"
          @addUser="addUserValidated"
        />
      </div>
      <div
        class="ml-12"
        v-if="
          selected &&
          extra.type &&
          (extra.type.code === $CONSTANTS.CATEGORY.SERVICE.PICK_UP_DELIVERY ||
            extra.type.code === $CONSTANTS.CATEGORY.SERVICE.RETURN_DELIVERY)
        "
      >
        ({{ extra.pickUpAddress || extra.deliveryAddress }})
      </div>
    </div>
    <div id="fake-div"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { requiredIf } from "vuelidate/lib/validators";
import ImageAspectRatio from "@/flit2go-web-core/components/ui/image-aspect-ratio";
import CategoryAdditionalsExtrasSelected from "@/components/category-additionals-extras-selected";
import ExtraDriverValidate from "@/components/extra-driver-validate";
import BillingUtil from "@/flit2go-web-core/util/billingUtil.js";
import _ from "lodash";

import { directive as onClickaway } from "vue-clickaway";

export default {
  name: "categoryAdditionalsExtras",
  components: {
    ImageAspectRatio,
    CategoryAdditionalsExtrasSelected,
    ExtraDriverValidate,
  },
  props: {
    itemsList: { required: false, type: Array, default: () => [] },
    selected: { required: false, type: Boolean, default: false },
    type: { required: false, type: String, default: null },
  },
  directives: {
    onClickaway: onClickaway,
  },
  data() {
    return {
      BillingUtil,
      extraServicesTypes: [],
      extrasSelected: [],
      usersValidated: [],
      extrasChecked: [],
      showAddStorage: false,
      showCreateUser: false,
      showPickUpAddress: false,
      showDeliveryAddress: false,
      storage: 1,
      showAddExtra: true,
      pickUpAddress: "",
      deliveryAddress: "",
      directionResultsDelivery: [],
      directionResultsPickUp: [],
      geocoder: null,
      autocompleteService: null,
      placesService: null,
      pickUpAddressError: false,
      deliveryAddressError: false,
    };
  },
  validations() {
    return {
      pickUpAddress: { required: requiredIf(() => this.showPickUpAddress) },
      deliveryAddress: { required: requiredIf(() => this.showDeliveryAddress) },
    };
  },
  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
      actualPrice: "configuration/getActualPrice",
      categorySelected: "category/categorySelected",
      bookingFilters: "configuration/getBookingFilters",
      configurationFilters: "configuration/getFilters",
      filters: "configuration/getFilters",
    }),
    pickUpAddressErrors() {
      const errors = [];
      if (this.$v.pickUpAddress.$dirty) {
        this.filters.addressCenter &&
          this.pickUpAddressError &&
          errors.push(
            this.filters.addressCenterError[
              this.$PageUtils.getLanguageSplittedMinus()
            ]
          );
        !this.$v.pickUpAddress.required &&
          errors.push(this.$t(this.$CONSTANTS.ERROR.REQUIRED));
      }
      return errors;
    },
    deliveryAddressErrors() {
      const errors = [];
      if (this.$v.deliveryAddress.$dirty) {
        this.filters.addressCenter &&
          this.deliveryAddressError &&
          errors.push(
            this.filters.addressCenterError[
              this.$PageUtils.getLanguageSplittedMinus()
            ]
          );
        !this.$v.deliveryAddress.required &&
          errors.push(this.$t(this.$CONSTANTS.ERROR.REQUIRED));
      }

      return errors;
    },
  },
  watch: {
    itemsList() {
      this.initData();
    },
    extrasChecked() {
      this.emitExtras();
    },
    extrasSelected() {
      this.eventSelectType();
    },
    showAddStorage() {
      this.additionalStorageService();
    },
    storage() {
      this.additionalStorageService();
    },
    showPickUpAddress() {
      if (this.showPickUpAddress) {
        setTimeout(() => this.setInputColor("pickUpAddressTextField"), 500);
      }
    },
    showDeliveryAddress() {
      if (this.showDeliveryAddress) {
        setTimeout(() => this.setInputColor("deliveryAddressTextField"), 500);
      }
    },
  },
  created() {
    this.initData();
  },
  async mounted() {
    if (this.type === "additional" && this.filters.googlePlacesApiKey) {
      // eslint-disable-next-line
      if (typeof google === "undefined") {
        await this.importGoogleLibrary();
      }
      if (null === this.geocoder) {
        // eslint-disable-next-line
        this.geocoder = new google.maps.Geocoder();
      }
      if (null === this.autocompleteService) {
        this.autocompleteService =
          // eslint-disable-next-line
          await new google.maps.places.AutocompleteService();
      }
      if (null === this.placesService) {
        // eslint-disable-next-line
        this.placesService = new google.maps.places.PlacesService(
          document.getElementById("fake-div")
        );
      }
    }
    setTimeout(() => {
      if (this.placesService) {
        let userUbication = window.localStorage.getItem(
          "user-ubication-pickUp"
        );
        if (userUbication) {
          this.ubicationSelected(JSON.parse(userUbication), "deliveryAddress");
        }
        let userReturnBackUbication = window.localStorage.getItem(
          "user-ubication-returnBack"
        );
        if (userReturnBackUbication) {
          this.ubicationSelected(
            JSON.parse(userReturnBackUbication),
            "pickUpAddress"
          );
        }
      }
    }, 200);
  },
  methods: {
    importGoogleLibrary() {
      return new Promise((resolve) => {
        let placesScript = document.createElement("script");
        placesScript.type = "text/javascript";
        placesScript.async = false;
        placesScript.setAttribute(
          "src",
          `https://maps.googleapis.com/maps/api/js?key=${this.filters.googlePlacesApiKey}&libraries=geometry,places`
        );
        placesScript.onload = function () {
          resolve();
        };
        document.getElementsByTagName("head")[0].appendChild(placesScript);
      });
    },
    closeDirectionResultsDelivery() {
      this.directionResultsDelivery = [];
      this.deliveryAddress = "";
      this.deliveryAddressError = false;
      this.debounceInput("delivery");
      this.setDeliveryAddress();
    },
    closeDirectionResultsPickUp() {
      this.directionResultsPickUp = [];
      this.pickUpAddress = "";
      this.pickUpAddressError = false;
      this.debounceInput("pickUp");
      this.setPickUpAddress();
    },
    async ubicationSelected(ubication, address) {
      this[`${address}Error`] = false;
      const directions =
        address === "pickUpAddress"
          ? "directionResultsPickUp"
          : "directionResultsDelivery";
      const checkCoordinates =
        this.filters.addressCenterLatitude &&
        this.filters.addressCenterLongitude;

      if (!checkCoordinates && ubication.hasOwnProperty("formatted_address")) {
        this[address] = ubication.formatted_address;
        this[directions] = [];
      } else {
        const newUbication = await this.getDirectionCoordinates(
          ubication.place_id,
          ubication.description
        );
        if (newUbication) {
          if (checkCoordinates) {
            const distance =
              // eslint-disable-next-line
              google.maps.geometry.spherical.computeDistanceBetween(
                // eslint-disable-next-line
                new google.maps.LatLng(
                  this.filters.addressCenterLatitude,
                  this.filters.addressCenterLongitude
                ),
                // eslint-disable-next-line
                new google.maps.LatLng(
                  newUbication.geometry.location.lat(),
                  newUbication.geometry.location.lng()
                )
              ) / 1000;
            if (Number(distance) > Number(this.filters.addressCenterRadius)) {
              this[`${address}Error`] = true;
            } else {
              this[address] = ubication.description;
              this[directions] = [];
            }
          } else {
            this[address] = ubication.description;
            this[directions] = [];
          }
        } else {
          this.$showMessage.error(this.$t("errors.search"));
        }
      }
      if (address === "pickUpAddress") {
        this.setPickUpAddress();
      } else if (address === "deliveryAddress") {
        this.setDeliveryAddress();
      }
    },
    // eslint-disable-next-line
    debounceInput: _.debounce(async function (type) {
      const directions =
        type === "pickUp"
          ? "directionResultsPickUp"
          : "directionResultsDelivery";
      if (this.filters.googlePlacesApiKey) {
        const results = await this.getDirections(`${type}Address`);
        if (results) {
          this[directions] = results;
        } else {
          this.$showMessage.error(this.$t("errors.search"));
        }
      } else {
        if (type === "pickUp") {
          this.setPickUpAddress();
        } else if (type === "delivery") {
          this.setDeliveryAddress();
        }
      }
    }, 500),
    async getDirections(type) {
      let defaultBounds = null;
      if (
        this.configurationFilters.addressCenterLatitude &&
        this.configurationFilters.addressCenterLongitude &&
        this.configurationFilters.addressCenterRadius
      ) {
        let radius = this.configurationFilters.addressCenterRadius;
        const center = {
          lat: this.configurationFilters.addressCenterLatitude,
          lng: this.configurationFilters.addressCenterLongitude,
        };
        // Convertir el radio de kilómetros a grados
        let latitudinalRadius = radius / 111.0;
        let longitudinalRadius =
          radius / (111.0 * Math.cos((center.lat * Math.PI) / 180.0));
        defaultBounds = {
          north: center.lat + latitudinalRadius,
          south: center.lat - latitudinalRadius,
          east: center.lng + longitudinalRadius,
          west: center.lng - longitudinalRadius,
        };
      }
      return new Promise((resolve) => {
        const request = {
          input: this[type],
          types: ["geocode"],
          locationRestriction: defaultBounds,
        };
        if (this.autocompleteService) {
          this.autocompleteService.getPlacePredictions(
            request,
            (results, status) => {
              // eslint-disable-next-line
              if (status === google.maps.places.PlacesServiceStatus.OK) {
                resolve(results);
              } else {
                resolve(null);
              }
            }
          );
        }
      });
    },
    initData() {
      this.extraServicesTypes = [];
      this.extrasChecked = [];
      this.extrasSelected = [];
      let userUbication = window.localStorage.getItem("user-ubication-pickUp");
      let userReturnBackUbication = window.localStorage.getItem(
        "user-ubication-returnBack"
      );
      if (this.itemsList && this.itemsList.length > 0) {
        this.itemsList.forEach((extra) => {
          if (
            extra.type &&
            extra.type.code === this.$CONSTANTS.CATEGORY.SERVICE.LUGGAGE
          ) {
            if (extra.numberPackages === null) {
              extra.numberPackages = 1;
            }
          }
          if (
            extra.type &&
            extra.type.code ===
              this.$CONSTANTS.CATEGORY.SERVICE.RETURN_DELIVERY &&
            userUbication
          ) {
            this.extrasSelected.push(extra);
          }
          if (
            extra.type &&
            extra.type.code ===
              this.$CONSTANTS.CATEGORY.SERVICE.PICK_UP_DELIVERY &&
            userReturnBackUbication
          ) {
            this.extrasSelected.push(extra);
          }
          this.extraServicesTypes.push(extra);
          if (extra.mandatory) {
            this.extrasSelected.push(extra);
            if (
              extra.type.code ===
              this.$CONSTANTS.CATEGORY.SERVICE.PICK_UP_DELIVERY
            ) {
              this.showPickUpAddress = true;
            } else if (
              extra.type.code ===
              this.$CONSTANTS.CATEGORY.SERVICE.RETURN_DELIVERY
            ) {
              this.showDeliveryAddress = true;
            }
            this.$emit("servicesValidation", !this.$v.$invalid);
          }
        });
      }
    },
    addExtraService(extra) {
      if (this.type === "extras") {
        if (!this.extrasChecked.includes(extra)) {
          this.extrasChecked.push(extra);
        } else {
          this.extrasChecked.splice(this.extrasChecked.indexOf(extra), 1);
        }
      }
      if (this.type === "additional") {
        if (!this.extrasSelected.includes(extra)) {
          this.extrasSelected.push(extra);
        } else {
          if (!extra.mandatory) {
            this.extrasSelected.splice(this.extrasSelected.indexOf(extra), 1);
            this.emitExtras();
          }
        }
      }
    },
    getAdditionalIcon(code) {
      switch (code) {
        case this.$CONSTANTS.CATEGORY.SERVICE.LUGGAGE:
          return "briefcase";
        case this.$CONSTANTS.CATEGORY.SERVICE.ADDITIONAL_DRIVER:
          return "card-account-details";
        case this.$CONSTANTS.CATEGORY.SERVICE.PICK_UP_DELIVERY:
          return "pick-up-delivery";
        case this.$CONSTANTS.CATEGORY.SERVICE.RETURN_DELIVERY:
          return "return-delivery";
      }
    },
    eventSelectType() {
      this.showAddStorage = false;
      this.showCreateUser = false;
      this.showPickUpAddress = false;
      this.showDeliveryAddress = false;
      if (this.extrasSelected.length > 0) {
        if (this.type != "extras") {
          this.extrasSelected.forEach((extraOfList) => {
            if (!extraOfList.id) {
              let i = this.extrasSelected.indexOf(extraOfList);
              this.extrasSelected.splice(i, 1);
            }
            if (extraOfList.type) {
              switch (extraOfList.type.code) {
                case this.$CONSTANTS.CATEGORY.SERVICE.LUGGAGE:
                  this.showAddStorage = true;
                  break;
                case this.$CONSTANTS.CATEGORY.SERVICE.ADDITIONAL_DRIVER:
                  this.showCreateUser = true;
                  break;
                case this.$CONSTANTS.CATEGORY.SERVICE.PICK_UP_DELIVERY:
                  this.showPickUpAddress = true;
                  break;
                case this.$CONSTANTS.CATEGORY.SERVICE.RETURN_DELIVERY:
                  this.showDeliveryAddress = true;
                  break;
              }
            }
          });
        }
      }
    },
    additionalStorageService() {
      this.extrasSelected.forEach((extraOfList) => {
        if (
          extraOfList.type &&
          extraOfList.type.code === this.$CONSTANTS.CATEGORY.SERVICE.LUGGAGE
        ) {
          let i = this.extrasSelected.indexOf(extraOfList);
          this.extrasSelected[i].numberPackages = this.storage;
        }
      });
      this.emitExtras();
    },
    setPickUpAddress() {
      if (this.pickUpAddress) {
        let pickUpExtra = this.extrasSelected.find(
          (extra) =>
            extra.type.code ===
            this.$CONSTANTS.CATEGORY.SERVICE.PICK_UP_DELIVERY
        );
        pickUpExtra.pickUpAddress = this.pickUpAddress;
        this.$v.$touch();
        this.emitExtras();
      }
      this.$emit("servicesValidation", !this.$v.$invalid);
    },
    setDeliveryAddress() {
      if (this.deliveryAddress) {
        let deliveryExtra = this.extrasSelected.find(
          (extra) =>
            extra.type.code === this.$CONSTANTS.CATEGORY.SERVICE.RETURN_DELIVERY
        );
        deliveryExtra.deliveryAddress = this.deliveryAddress;
        this.$v.$touch();
        this.emitExtras();
      }
      this.$emit("servicesValidation", !this.$v.$invalid);
    },
    emitExtras() {
      let emptyExtra = {};
      if (this.type != "extras") {
        this.extrasSelected.forEach((extraOfList) => {
          if (
            extraOfList.type &&
            extraOfList.type.code ===
              this.$CONSTANTS.CATEGORY.SERVICE.ADDITIONAL_DRIVER &&
            extraOfList.name === null
          ) {
            let i = this.extrasSelected.indexOf(extraOfList);
            emptyExtra = Object.assign({}, extraOfList);
            this.extrasSelected.splice(i, 1);
          }
        });
      }
      let sendExtras = {
        type: this.type,
        extras:
          this.type === "extras" ? this.extrasChecked : this.extrasSelected,
      };
      this.$emit("addedExtra", sendExtras);
      this.extrasSelected.push(emptyExtra);
    },
    addUserValidated(user) {
      this.usersValidated.push(user);
      this.extrasSelected.push(user);
      this.showAddExtra = false;
      this.emitExtras();
    },
    deleteUser(user) {
      let i = this.extrasSelected.indexOf(user);
      let j = this.usersValidated.indexOf(user);
      this.extrasSelected.splice(i, 1);
      this.usersValidated.splice(j, 1);
      if (this.usersValidated.length === 0) {
        if (this.type != "extras") {
          this.extrasSelected.forEach((extraOfList) => {
            if (
              extraOfList.type &&
              extraOfList.type.code ===
                this.$CONSTANTS.CATEGORY.SERVICE.ADDITIONAL_DRIVER &&
              extraOfList.name === null
            ) {
              let i = this.extrasSelected.indexOf(extraOfList);
              this.extrasSelected.splice(i, 1);
            }
          });
        }
        this.showAddExtra = true;
      }
      this.emitExtras();
    },
    addStorage(extra) {
      if (this.storage < extra.maxNumber) {
        this.storage++;
      }
      this.eventSelectType(extra);
    },
    deleteStorage(extra) {
      if (this.storage > 1) {
        this.storage--;
      }
      this.eventSelectType(extra);
    },
    setInputColor(refElement) {
      const textField = this.$refs[refElement];
      if (textField) {
        const input = textField[0].$refs["input"];
        if (input) {
          input.style.color = this.colors.colorOnSurfaceMedium;
        }
      }
    },
    getExtraPrice(extra) {
      if (extra.price <= 0) {
        return "";
      }
      return this.selected &&
        extra.type &&
        extra.type.code === this.$CONSTANTS.CATEGORY.SERVICE.LUGGAGE
        ? BillingUtil.formatBillingValue(
            extra.price * extra.numberPackages,
            extra.currency.symbol
          )
        : BillingUtil.formatBillingValue(extra.price, extra.currency.symbol);
    },
    async getDirectionCoordinates(placeId) {
      return new Promise((resolve) => {
        const request = {
          placeId: placeId,
          fields: ["name", "rating", "formatted_phone_number", "geometry"],
        };
        this.placesService.getDetails(request, (place, status) => {
          // eslint-disable-next-line
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(place);
          } else {
            resolve(null);
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/flit2go-web-core/shared-styles/base-vars.scss";
// Transition
.grow-enter-active,
.grow-leave-active {
  transition: height 0.5s;
}
.grow-enter {
  height: 0px;
}
.grow-enter-to {
  height: fit-content;
}
.grow-leave-to /* .fade-leave-active below version 2.1.8 */ {
  height: 0px;
}

.results {
  position: sticky;
  z-index: 1000;
  width: 100%;
  background-color: white;
  overflow-y: auto;
  box-shadow: 0 23px 20px -20px rgba(115, 115, 115, 115),
    0 0 5px rgba(115, 115, 115, 115);
  border: 0 !important;
  border-radius: 0 0 0.3rem 0.3rem;
}

.results__item {
  font-size: 1.2em;
  min-height: 40px;
  border-bottom: 0.5px solid $gray-1-f2g;
  display: flex;
  align-items: center;
}

.results__item:hover {
  background-color: $gray-carousel;
  cursor: pointer;
}
</style>
