<template>
  <div v-if="categoriesList" class="reservation flex flex-row lg:p-4">
    <div class="w-full lg:w-9/12 lg:pr-4">
      <F2gFWStepper
        ref="stepper"
        :margin="false"
        :stepList="stepList"
        :currentStep="currentStep"
        @currentStep="eventCurrentStep"
      >
        <!-- Main info -->
        <template v-slot:content1>
          <div class="lg:max-max-h-full">
            <div class="flex flex-col md:flex-row mt-10 md:mt-0">
              <v-flex class="w-full md:w-1/2 px-4">
                <v-autocomplete
                  type="text"
                  :label="$t('common.vehicleType')"
                  v-model="filter.vehicleTypes"
                  outlined
                  :prepend-inner-icon="'room'"
                  :items="vehicles"
                  :item-text="`name`"
                  :item-value="`code`"
                  multiple
                  @change="searchCategories"
                />
              </v-flex>
              <v-flex class="w-full md:w-1/2 px-4">
                <v-autocomplete
                  type="text"
                  :label="$t('common.minimumAge')"
                  v-model="filter.minimumAge"
                  outlined
                  :prepend-inner-icon="'room'"
                  :items="ages"
                  :item-text="`name`"
                  :item-value="`code`"
                  @change="searchCategories"
                />
              </v-flex>
            </div>
            <!-- categoriesList.content -->
            <span v-if="categoriesList">
              <category-card
                v-for="(category, index) in categoriesList"
                :key="index"
                :categoryRow="category"
                @updateStep="eventCurrentStep(2)"
              />
            </span>
            <noData v-else :title="$t('common.noData')" />
          </div>
        </template>
        <template v-slot:content2>
          <extras-list ref="extrasList" @validation="extrasValidation" />
          <v-btn
            class="my-2 ml-4 w-xl border-2 rounded-config30"
            color="primary"
            @click="eventCurrentStep(3)"
          >
            {{ returnLabelButton() }}
          </v-btn>
        </template>
        <template v-slot:content3>
          <div class="min-h-2/3">
            <data-card
              class="mt-12"
              ref="dataCard"
              @type="setTypeUser"
              @validation="loginValidation"
            />
            <v-btn
              class="my-2 ml-4 w-xl border-2 rounded-config30"
              color="primary"
              :loading="loading"
              @click="eventCurrentStep(4)"
            >
              {{ returnLabelButton() }}
            </v-btn>
          </div>
        </template>
        <template v-slot:content4>
          <confirm-data />
          <v-btn
            class="my-2 ml-4 w-xl border-2 rounded-config30"
            color="primary"
            @click="eventCurrentStep(5)"
          >
            {{ returnLabelButton() }}
          </v-btn>
        </template>
        <template v-slot:content5>
          <div class="min-h-2/3 mt-12"><pay-card /></div
        ></template>
      </F2gFWStepper>
    </div>
    <div
      class="absolute top-16 left-0 w-full lg:relative lg:top-0 lg:w-3/12 lg:px-0"
    >
      <Resume
        :currentStep="currentStep"
        :loading="loading"
        @currentStep="eventCurrentStep"
      />
    </div>
  </div>
</template>

<script>
import CategoryCard from "@/modules/v1/Components/category-card";
import ExtrasList from "@/modules/v1/Components/extras-list";
import DataCard from "@/components/data-card";
import PayCard from "@/components/pay-card";
import ConfirmData from "@/components/confirm-data";
import Resume from "@/modules/v1/Components/resume";
import F2gFWStepper from "@/modules/v1/Components/web-core/f2gFW-stepper";
import { mapGetters, mapState } from "vuex";
import noData from "@/flit2go-web-core/components/ui/no-data.vue";

export default {
  name: "ReservationStepperV2",
  components: {
    CategoryCard,
    Resume,
    F2gFWStepper,
    ExtrasList,
    DataCard,
    PayCard,
    ConfirmData,
    noData,
  },
  data() {
    return {
      currentStep: 1,
      loading: false,
      filter: {
        vehicleTypes: [],
        minimumAge: null,
      },
      vehicles: [
        { code: "CAR", name: this.$t("vehicleTypes.CAR") },
        { code: "BIC", name: this.$t("vehicleTypes.BIC") },
        { code: "MOT", name: this.$t("vehicleTypes.MOT") },
        { code: "MOP", name: this.$t("vehicleTypes.MOP") },
        { code: "VAN", name: this.$t("vehicleTypes.VAN") },
        { code: "ES", name: this.$t("vehicleTypes.ES") },
        { code: "BOA", name: this.$t("vehicleTypes.BOA") },
      ],
      ages: [
        { name: "18", color: "#F9A8D4" },
        { name: "20", color: "#C4B5FD" },
        { name: "21", color: "#A5B4FC" },
        { name: "23", color: "#93C5FD" },
        { name: "25", color: "#6EE7B7" },
      ],
      stepList: [
        {
          id: 1,
          label: this.$t("common.vehicle"),
          ref: "vehicle",
          valid: false,
          visited: false,
        },
        {
          id: 2,
          label: this.$t("common.extras"),
          ref: "extras",
          valid: false,
          visited: false,
        },
        {
          id: 3,
          label: this.$t("common.yourData"),
          ref: "data",
          valid: false,
          visited: false,
        },
        {
          id: 4,
          label: this.$t("common.confirm"),
          ref: "confirm",
          valid: false,
          visited: false,
        },
        {
          id: 5,
          label: this.$t("common.payment"),
          ref: "payment",
          valid: false,
          visited: false,
        },
      ],
      typeUser: null,
    };
  },
  computed: {
    ...mapGetters({
      categoriesList: "category/categoriesList",
      bookingFilters: "configuration/getBookingFilters",
      colors: "configuration/getColors",
    }),
    ...mapState(["configuration"]),
  },
  beforeCreate() {
    if (
      this.$route.query.hasOwnProperty("pickUpBranch") &&
      this.$route.query.hasOwnProperty("pickUpDate") &&
      this.$route.query.hasOwnProperty("returnBackBranch") &&
      this.$route.query.hasOwnProperty("returnBackDate")
    ) {
      const url =
        window.location !== window.parent.location
          ? document.location.ancestorOrigins
            ? document.location.ancestorOrigins[0]
            : document.referrer
          : document.location.origin;
      let mainFilter = {};
      mainFilter.pickUpBranch = parseInt(this.$route.query.pickUpBranch);
      mainFilter.pickUpDate = parseInt(this.$route.query.pickUpDate);
      mainFilter.returnBackBranch = parseInt(
        this.$route.query.returnBackBranch
      );
      mainFilter.returnBackDate = parseInt(this.$route.query.returnBackDate);
      mainFilter.pickUpNameBranch = this.$route.query.pickUpNameBranch;
      mainFilter.returnBackNameBranch = this.$route.query.returnBackNameBranch;
      this.$store.commit("configuration/SET_BOOKING_FILTERS", mainFilter);
      localStorage.setItem(`apiToken-${url}`, this.$route.query.apiKey);
      let params = {
        type: this.$CONSTANTS.INTEGRATION.FRAME_WEB,
      };
      this.$store.dispatch("configuration/getConfiguration", params);
      this.$store.dispatch("category/getAvailableCategories", mainFilter);
    }
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
    Object.entries(this.$route.query).forEach(([key, value]) => {
      if (key in this.filter) {
        this.filter[key] = value;
      }
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    searchCategories() {
      let mainFilter = Object.assign({}, this.bookingFilters);
      if (this.filter.vehicleTypes.length > 0) {
        mainFilter.vehicleTypes = this.filter.vehicleTypes.toString();
      }
      if (this.filter.minimumAge) {
        mainFilter.minimumAge = this.filter.minimumAge;
      }
      this.$store.dispatch("category/getAvailableCategories", mainFilter);
    },
    returnLabelButton() {
      switch (this.currentStep) {
        case 1: {
          return this.$t("button.goToExtras");
        }
        case 2: {
          return this.$t("button.goToData");
        }
        case 3: {
          return this.$t("button.goToConfirm");
        }
        case 4: {
          return this.$t("button.goToPay");
        }
        default: {
          return "";
        }
      }
    },
    eventCurrentStep(currentStep) {
      if (currentStep == 1) {
        this.currentStep = currentStep;
      }
      if (currentStep == 2) {
        this.$refs.extrasList.getFranchises();
        this.currentStep = currentStep;
      }
      if (currentStep == 3) {
        this.$refs.extrasList.validate();
      }
      if (currentStep == 4) {
        this.loading = true;
        this.$refs.dataCard.validate();
      }
      if (currentStep == 5) {
        this.currentStep = currentStep;
      }
      if (this.currentStep > currentStep) {
        this.currentStep = currentStep;
      }
    },
    setTypeUser(userType) {
      this.typeUser = userType;
    },
    extrasValidation(extras) {
      if (extras.valid) {
        this.currentStep = this.currentStep + 1;
      }
    },
    loginValidation(login) {
      this.loading = false;
      if (login.valid) {
        this.currentStep = this.currentStep + 1;
      }
    },
  },
};
</script>
