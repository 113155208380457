<template>
  <div
    class="price-resume-data"
    :class="{ 'w-full': detailCatalogue }"
    id="top-part"
  >
    <div v-if="loadingAvailabilityDates">
      <v-skeleton-loader
        height="108px"
        class="mx-auto my-2"
        type="image"
      ></v-skeleton-loader>
    </div>
    <div
      v-else
      class="flex flex-row flex-wrap"
      :class="{ 'my-2': !detailCatalogue }"
    >
      <!-- packs -->
      <CategoryCardPackCatalogue
        v-for="(pack, index) in getPacks"
        class="w-full min-max-h-full flex lg:flex-none md:align-left md:flex-row relative shadow"
        :class="{
          'rounded-lg mb-3': !detailCatalogue && !isSmallScreen,
          'rounded-b-lg shadow': detailCatalogue && !isSmallScreen,
          'mb-3': !isSmallScreen,
          'rounded-t-lg': isSmallScreen && Number(index) >= 1,
          'rounded-b-lg': isSmallScreen,
        }"
        :packRate="pack"
        :key="index"
        :index="index"
        :category="currentCategory"
        @showDescription="(packIndex) => (showPackDescription = packIndex)"
        @changeDates="() => (showModalEdit = true)"
        @selected="selectPackRate"
      />

      <transition name="fade">
        <Card
          class="helpCard"
          v-if="showPackDescription > -1"
          v-on-clickaway="() => (showPackDescription = -1)"
          ><div class="pa-3">
            <span
              class="mt-1 message"
              v-html="
                currentCategory.packRate[showPackDescription].description[
                  language
                ]
                  ? currentCategory.packRate[showPackDescription].description[
                      language
                    ]
                  : currentCategory.packRate[showPackDescription].description.en
              "
            ></span>
          </div>
        </Card>
      </transition>
      <!-- end packs -->
      <div
        v-if="!hasPacks && currentCategory.deferredPayment"
        class="min-max-h-full mb-3 flex lg:flex-none md:align-left md:flex-row relative shadow"
        :style="{ backgroundColor: colors.colorSurface }"
        :class="{
          'rounded-bl-lg': detailCatalogue,
          'rounded-l-lg':
            currentCategory.deferredPayment &&
            !currentCategory.errorDatesReserve &&
            !detailCatalogue,
          'rounded-lg':
            currentCategory.deferredPayment &&
            currentCategory.errorDatesReserve &&
            !detailCatalogue,
          'w-1/2': !currentCategory.errorDatesReserve,
          'w-full': currentCategory.errorDatesReserve,
        }"
      >
        <div class="flex flex-col p-3 max-h-full">
          <div
            class="max-h-full flex flex-col justify-between"
            v-if="!currentCategory.errorDatesReserve"
          >
            <div>
              <div
                class="text-sm xl:text-base font-bold"
                :style="{ color: colors.colorOnSurface }"
              >
                {{ $t("prepaid") }}
              </div>
              <div
                class="text-xs mt-2"
                :style="{ color: colors.colorOnSurface }"
              >
                {{ $t("payNowPrepaid") }}
              </div>
            </div>
            <div
              :class="{
                'xl:mt-2 mt-3': currentCategory.deferredPayment,
              }"
            >
              <div
                class="xl:text-3xl text-2xl font-bold"
                :style="{ color: colors.colorPrimary }"
              >
                {{
                  BillingUtil.formatBillingValue(
                    returnPrice(),
                    currentCategory.symbol
                  )
                }}
              </div>
              <div
                class="text-xl font-bold"
                :style="{ color: colors.colorOnSurfaceMedium }"
              >
                {{
                  BillingUtil.formatBillingValue(
                    getPricePerDay(),
                    currentCategory.symbol
                  )
                }}<span class="text-xs">
                  {{
                    currentCategory.timeType == "day"
                      ? $t("common.perDay")
                      : $t("common.perHour")
                  }}</span
                >
              </div>
              <v-btn
                class="w-full border-2 rounded-config30 mt-3"
                :style="{
                  backgroundColor: colors.colorPrimary,
                  color: colors.colorOnPrimary,
                }"
                :loading="loading"
                @click="
                  () => {
                    $emit(
                      'selected',
                      currentCategory.deferredPayment ? true : false
                    );
                  }
                "
              >
                {{ $t("select") }}
              </v-btn>
            </div>
          </div>
          <div v-else class="my-2 text-normal text-red-700 max-w-lg w-full">
            <div
              class="text-sm font-bold flex flex-row justify-center align-center errorText"
            >
              {{ currentCategory.txtErrorDatesReserve }}
            </div>
            <div class="flex flex-row justify-end align-end h-12">
              <v-btn
                class="w-full border-2 rounded-config30 mt-2"
                :style="{
                  backgroundColor: colors.colorPrimary,
                  color: colors.colorOnPrimary,
                }"
                @click="showModalEdit = true"
              >
                {{ $t("modifyDates") }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="
          !hasPacks &&
          currentCategory.deferredPayment &&
          !currentCategory.errorDatesReserve
        "
        class="w-1/2 min-max-h-full mb-3 flex lg:flex-none md:align-left md:flex-row relative shadow"
        :class="{
          'rounded-br-lg': detailCatalogue,
          'rounded-r-lg': !detailCatalogue,
        }"
        :style="{ backgroundColor: colors.colorSurface }"
      >
        <div class="flex flex-col p-3 max-h-full">
          <div
            class="max-h-full flex flex-col justify-between"
            v-if="!currentCategory.errorDatesReserve"
          >
            <div>
              <div
                class="text-sm xl:text-base font-bold"
                :style="{ color: colors.colorOnSurface }"
              >
                {{ getTotalPriceDeferredTitle }}
              </div>
              <div
                class="text-xs mt-2"
                :style="{ color: colors.colorOnSurface }"
                v-html="getTotalPriceDeferredText"
              ></div>
            </div>
            <div class="xl:mt-2 mt-3">
              <div
                class="xl:text-3xl text-2xl font-bold"
                :style="{ color: colors.colorPrimary }"
              >
                {{
                  BillingUtil.formatBillingValue(
                    currentCategory.totalPrice ? currentCategory.totalPrice : 0,
                    currentCategory.symbol
                  )
                }}
              </div>
              <div
                class="text-xl font-bold"
                :style="{ color: colors.colorOnSurfaceMedium }"
              >
                {{
                  BillingUtil.formatBillingValue(
                    currentCategory.pricePerTime,
                    currentCategory.symbol
                  )
                }}<span class="text-xs">
                  {{
                    currentCategory.timeType == "day"
                      ? $t("common.perDay")
                      : $t("common.perHour")
                  }}</span
                >
              </div>
              <v-btn
                class="w-full mt-3 border-2 rounded-config30"
                :style="{
                  backgroundColor: colors.colorPrimary,
                  color: colors.colorOnPrimary,
                }"
                :loading="loading"
                @click="
                  () => {
                    $emit('selected', false);
                  }
                "
              >
                {{ $t("select") }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!hasPacks && !currentCategory.deferredPayment"
        class="w-full max-h-full flex lg:flex-none md:align-left md:flex-row relative shadow"
        :style="{ backgroundColor: colors.colorPrimary }"
        :class="{
          'rounded-lg mb-3': !detailCatalogue,
          'rounded-b-lg shadow': detailCatalogue,
        }"
      >
        <div class="flex flex-col p-3 max-h-full">
          <div
            class="flex flex-row max-h-full"
            v-if="!currentCategory.errorDatesReserve"
          >
            <div class="flex flex-col ml-3">
              <div
                class="xl:text-2xl text-xl font-bold"
                :style="{ color: colors.colorOnPrimary }"
              >
                {{
                  BillingUtil.formatBillingValue(
                    returnPrice(),
                    currentCategory.symbol
                  )
                }}
              </div>
              <div
                class="text-sm font-bold"
                :style="{ color: colors.colorOnPrimary }"
              >
                {{
                  BillingUtil.formatBillingValue(
                    getPricePerDay(),
                    currentCategory.symbol
                  )
                }}<span class="text-xs">
                  {{
                    currentCategory.timeType == "day"
                      ? $t("common.perDay")
                      : $t("common.perHour")
                  }}</span
                >
              </div>
            </div>
            <v-btn
              class="w-36 border-2 rounded-config30 mt-2"
              :style="{
                backgroundColor: colors.colorOnPrimary,
                color: colors.colorPrimary,
              }"
              :loading="loading"
              @click="
                () => {
                  $emit(
                    'selected',
                    currentCategory.deferredPayment ? true : false
                  );
                }
              "
            >
              {{ $t("select") }}
            </v-btn>
          </div>
          <div
            v-else-if="!hasPacks"
            class="my-2 text-normal text-red-700 max-w-lg w-full"
          >
            <div
              class="text-sm font-bold flex flex-row justify-center align-center errorText"
            >
              {{ currentCategory.txtErrorDatesReserve }}
            </div>
            <div class="flex flex-row justify-end align-end h-12">
              <v-btn
                class="w-full border-2 rounded-config30 mt-2"
                :style="{
                  backgroundColor: colors.colorOnPrimary,
                  color: colors.colorPrimary,
                }"
                @click="showModalEdit = true"
              >
                {{ $t("modifyDates") }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <TailDialog
      :backgroundColor="colors.colorBackground"
      :closeButtonBackgroundColor="colors.colorPrimary"
      :closeButtonLogoColor="colors.colorOnPrimary"
      colorCloseBorder="gray"
      :show="showModalEdit"
      @close="showModalEdit = false"
    > -->
    <LateralPanel :open="showModalEdit" ref="lateralPanel">
      <template slot="content">
        <MainFilterComponentNew
          v-if="showModalEdit"
          style="min-height: 600px; display: flex"
          :style="`min-width: ${
            this.$vuetify.breakpoint.name === 'sm' ||
            this.$vuetify.breakpoint.name === 'xs'
              ? ''
              : '600px'
          }`"
          showCancelButton
          :type="'vertical'"
          :edit="showModalEdit ? true : false"
          :bookingFiltersOld="bookingFilters"
          @close="showModalEdit = false"
        />
      </template>
    </LateralPanel>
    <!-- </TailDialog> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BillingUtil from "@/flit2go-web-core/util/billingUtil.js";
import MainFilterComponentNew from "@/modules/v2/Components/main-filter-component-new.vue";
// import TailDialog from "@/components/tail-dialog";
import { EventBus } from "@/flit2go-web-core/util/event-bus.js";
import LateralPanel from "@/flit2go-web-core/components/frameweb/lateral-panel";
import { directive as onClickaway } from "vue-clickaway";

export default {
  name: "PriceResumeData",
  directives: {
    onClickaway,
  },
  components: {
    MainFilterComponentNew,
    // TailDialog,
    LateralPanel,
    Card: () => import("@/flit2go-web-core/components/ui/card"),
    CategoryCardPackCatalogue: () =>
      import("@/modules/v2/Components/category-card-pack-catalogue"),
  },
  props: {
    currentStep: { required: false, type: Number, default: 1 },
    currentCategory: { required: true, type: Object },
    detailCatalogue: { required: false, type: Boolean, default: false },
    loading: { required: false, type: Boolean, default: false },
  },
  data() {
    return {
      BillingUtil,
      showModalEdit: false,
      loadingAvailabilityDates: false,
      showPackDescription: -1,
    };
  },

  watch: {
    showModalEdit() {
      window.parent.postMessage("goToTop", "*");
    },
  },
  mounted() {
    EventBus.$on(
      "loadingAvailabilityDates",
      (value) => (this.loadingAvailabilityDates = value)
    );
  },
  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
      bookingFilters: "configuration/getBookingFilters",
      language: "configuration/getUserLanguage",
    }),
    isSmallScreen() {
      return (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      );
    },
    getTotalPriceDeferredTitle() {
      let title = "";
      if (this.currentCategory?.deferredPayment) {
        const total = this.currentCategory?.totalPriceDeferred
          ? BillingUtil.formatNumber(this.currentCategory.totalPriceDeferred)
          : 0;
        if (this.currentCategory.deferredStartRental) {
          title =
            Number(this.currentCategory.deferredPaymentPercentageFirst) > 0
              ? this.$t("payNowAndRestOnVehicleDelivery", {
                  amount: BillingUtil.formatBillingValue(
                    total,
                    this.currentCategory.symbol
                  ),
                })
              : this.$t("payOnDeliveryVehicle");
        } else {
          title = this.$t("PayNowAmount", {
            amount: BillingUtil.formatBillingValue(
              total,
              this.currentCategory.symbol
            ),
          });
        }
      }
      return title;
    },
    getTotalPriceDeferredText() {
      let text = "";
      if (this.currentCategory?.deferredPayment) {
        const total = this.currentCategory?.totalPriceDeferred
          ? BillingUtil.formatNumber(this.currentCategory.totalPriceDeferred)
          : 0;
        if (this.currentCategory.deferredStartRental) {
          text =
            Number(this.currentCategory.deferredPaymentPercentageFirst) > 0
              ? this.$t("payNowAndCollectRestOnVehicleDelivery", {
                  amount: BillingUtil.formatBillingValue(
                    total,
                    this.currentCategory.symbol
                  ),
                })
              : this.$t("payNothingNowPayOnVehicleDelivery");
        } else {
          text = this.$t("payNowAndCollectBeforeRental", {
            amount: BillingUtil.formatBillingValue(
              total,
              this.currentCategory.symbol
            ),
          });
        }
      }
      return text.replace("<b>", '<b style="font-size:calc(100% + 2px)">');
    },
    hasPacks() {
      return this.currentCategory.packRate?.length > 0;
    },
    getPacks() {
      return this.hasPacks ? this.currentCategory.packRate : [];
    },
  },
  methods: {
    getPricePerDay() {
      if (this.currentCategory.deferredPayment) {
        return this.currentCategory.pricePerTimeDeferred;
      } else {
        return this.currentCategory.pricePerTime
          ? this.currentCategory.pricePerTime.toFixed(2)
          : 0;
      }
    },
    returnPrice() {
      if (
        this.currentCategory.deferredPayment &&
        this.currentCategory.deferredDiscount
      ) {
        return this.currentCategory.totalPriceDeferredDiscount;
      } else {
        return this.currentCategory.totalPrice
          ? this.currentCategory.totalPrice
          : "0";
      }
    },
    selectPackRate(earlyPay, packRate) {
      this.$emit("selected", earlyPay, packRate);
    },
  },
};
</script>

<style lang="scss" scoped>
.price-resume-data {
  .helpCard {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3), 0 0 10px rgba(0, 0, 0, 0.096) !important;
    position: fixed;
    z-index: 1000 !important;
    text-align: justify;
    text-justify: inter-word;
    min-width: 400px;
    max-width: 800px;
    top: 35%;
    left: 45%;
  }
  @media (max-width: 800px) {
    .helpCard {
      left: 2%;
      max-width: 500px;
      position: fixed;
      top: 35%;
    }
  }
  @media (max-width: 600px) {
    .helpCard {
      left: 2%;
      max-width: 450px;
      position: fixed;
      top: 40%;
    }
  }
  @media (max-width: 500px) {
    .helpCard {
      left: 2%;
      max-width: 350px;
      position: fixed;
      top: 40%;
    }
  }
  @media (max-width: 400px) {
    .helpCard {
      left: 2%;
      max-width: 250px;
      position: fixed;
      top: 40%;
    }
  }
  @media (max-width: 300px) {
    .helpCard {
      left: 2%;
      max-width: 200px;
      position: fixed;
      top: 40%;
    }
  }
}
</style>