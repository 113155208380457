<template>
  <div class="action-dialog-generic">
    <F2gDialog
      :title="dialogTitle"
      :show="show"
      :showClose="showClose"
      :statusCode="titleStatusCode ? statusCode : ''"
      :prependIcon="showIconTitle ? 'warning' : iconProp"
      :width="600"
      @close="$emit('close')"
    >
      <template v-slot:content>
        <v-layout v-html="dialogBody" column class="mb-4"></v-layout>
      </template>
      <template v-slot:actions>
        <v-btn
          v-if="showButtonCancel"
          outlined
          class="rounded"
          @click="$emit('close')"
          >{{ $t(`${buttonCancel}`) }}</v-btn
        >
        <v-btn
          :color="statusCode === 'warning' ? statusCode : 'red'"
          class="rounded ml-2"
          style="color: white"
          :loading="loading"
          @click="$emit('performAction')"
          >{{ $t(`${buttonAction}`) }}</v-btn
        >
      </template>
    </F2gDialog>
  </div>
</template>

<script>
export default {
  name: "action-dialog",
  data: () => ({
    dialogTitle: null,
    dialogBody: null,
  }),
  props: {
    title: { required: false, type: String },
    show: { type: Boolean, default: false, required: false },
    buttonCancel: {
      type: String,
      default: "common.cancel",
      required: false,
    },
    buttonAction: {
      type: String,
      default: "common.accept",
      required: false,
    },
    showClose: { required: false, type: Boolean, default: false },
    body: {
      required: false,
      type: String,
    },
    statusCode: { type: String, required: false },
    loading: { type: Boolean, required: false, default: false },
    titleStatusCode: { type: Boolean, required: false, default: true },
    showButtonCancel: {
      type: Boolean,
      default: true,
      required: false,
    },
    showIconTitle: {
      type: Boolean,
      default: true,
      required: false,
    },
    iconTitle: {
      type: String,
      required: false,
    },
  },
  mounted() {
    this.setTitle();
    this.setBody();
  },
  computed: {
    iconProp() {
      return this.iconTitle ? this.iconTitle : "";
    },
  },
  methods: {
    setTitle() {
      this.dialogTitle = this.title ? this.title : this.$t("common.delete");
    },
    setBody() {
      this.dialogBody = this.body
        ? this.body
        : this.$t("common.genericDeleteConfirm");
    },
  },
};
</script>

<style></style>
