<template>
  <v-menu
    class="z-205"
    ref="menu"
    :close-on-content-click="false"
    v-model="menu"
    transition="scale-transition"
    :nudge-right="40"
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <span v-on="on" class="w-full h-2/3">
        <v-text-field
          v-model="time"
          :clearable="true"
          :label="label"
          :outlined="outlined"
          :prepend-inner-icon="outlined ? 'query_builder' : ''"
          :prepend-icon="showIcon ? (outlined ? '' : 'query_builder') : ''"
          :disabled="disabled"
          :readonly="disabledInput"
          :error-messages="errors"
          @input="inputActivator"
          :hide-details="hideDetails"
          @click:clear="time = ''"
        ></v-text-field>
      </span>
    </template>
    <v-time-picker
      ref="picker"
      v-model="time"
      :color="ownerPrimaryColor"
      :min="minDate"
      :max="maxDate"
      locale="i18n.locale"
      :disabled="disabled"
      :allowed-minutes="allowedMinutes"
      format="24hr"
      @input="eventInputTime"
      @click:minute="eventMinute"
    ></v-time-picker>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    clear: Boolean,
    label: String,
    errors: Array,
    inputActivator: null,
    minDate: String,
    maxDate: String,
    value: String,
    disabled: Boolean,
    type: String,
    allowedMinutes: Function,
    showIcon: { default: true },
    hideDetails: { default: false },
    disabledInput: { default: false },
    outlined: { required: false, type: Boolean, default: false },
  },
  data: () => ({
    time: "",
    menu: null,
    ownerPrimaryColor: null,
    ownerOnPrimaryColor: null,
  }),
  computed: {
    ...mapGetters({
      ownerColors: "auth/getOwnerColors",
    }),
  },
  watch: {
    time: function() {
      this.$emit("input", this.time);
    },
    value: function() {
      this.time = this.value;
    },
    menu: function() {
      if (!this.menu) {
        this.$refs.picker.selecting = 1;
      }
    },
    clear() {
      if (this.clear) {
        this.time = "";
      }
    },
  },
  mounted() {
    this.time = this.value;
    this.ownerPrimaryColor = this.ownerColors.ownerPrimaryColor;
  },
  methods: {
    eventInputTime() {
      this.$emit("input", this.time);
    },
    eventMinute() {
      this.menu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.z-205 {
  width: 100%;
  z-index: 205 !important;
}
.v-menu__content {
  min-width: 0px !important;
}
</style>
