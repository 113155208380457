var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"create-credit-card",attrs:{"id":"top-part"}},[_c('v-dialog',{class:'rounded-t-xl',style:(`backgroundColor:${_vm.colors.colorSurface} !important`),attrs:{"transition":"dialog-bottom-transition","value":_vm.show,"maxWidth":800,"persistent":""}},[[_c('v-card',{staticClass:"max-h-full bg-white backdrop-blur-xl overflow-y-auto rounded-lg",style:(`backgroundColor:${_vm.colors.colorSurface} !important`)},[_c('v-card-title',{staticClass:"text-h5 mb-2",style:({
            color: _vm.colors.colorOnSurface,
          })},[_vm._v(" "+_vm._s(_vm.$t("newCreditCard"))+" ")]),_c('v-card-text',[_c('div',{staticClass:"absolute flex flex-row justify-end align-end mt-2 mr-2 top-0 right-0 z-50"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"flex flex-row align-center",style:({
              color: _vm.colors.colorOnSurface,
            })},[_c('F2gIcon',{staticClass:"mr-2",attrs:{"value":'security-check',"height":30,"width":30,"stroke":_vm.colors.colorOnSurface,"strokeWidth":3}}),_c('div',{staticClass:"text-base"},[_vm._v(" "+_vm._s(_vm.$t("encryptedPaymentData"))+" ")])],1),_c('div',{staticClass:"lg:flex-row flex-col w-full mt-6"},[_c('div',{staticClass:"w-full lg:w-full mr-2 mb-4 border p-3 rounded-lg",style:({ borderColor: _vm.colors.colorPrimary }),attrs:{"id":"card-element"}})]),_c('div',{staticClass:"flex align-center justify-center"},[_c('v-btn',{staticClass:"w-xl mb-2 border-2 mt-6 rounded-config30",style:({
                color: _vm.colors.colorOnPrimary,
                backgroundColor: _vm.colors.colorPrimary,
              }),attrs:{"loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("addCreditCard"))+" ")])],1),(_vm.error)?_c('div',{staticClass:"text-red-700 mb-2"},[_vm._v(_vm._s(_vm.error))]):_vm._e()])],1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }