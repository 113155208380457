import conn from "./connection"

export default {
    getDataIndicator(params) {
        return conn.$axios({
            url: `/secured/v1/dashboard/dataIndicator`,
            method: 'GET',
            params: params
        })
    },
    draftWidget(type) {
        return conn.$axios({
            url: `/secured/v1/dashboard/type/` + type,
            method: 'POST'
        })
    },
    getUserDashboard(entity,dashboardType) {
        return conn.$axios({
            url: `/secured/v1/dashboard/${entity}/${dashboardType}`, // `/secured/v1/personWidget/person/${id}`
            method: 'GET'
        })
    },
    updateDashboard(dashboard) {
        return conn.$axios({
            url: `/secured/v1/dashboard`,
            method: 'PUT',
            data: dashboard
        })
    },
    deleteWidgets(widgets) {
        return conn.$axios({
            url: `/secured/v1/widget/${widgets}`,
            method: 'DELETE'
        })
    },
    getDashboardBaseInfo(dashboardType) {
        return conn.$axios({
            url: `/secured/v1/dashboard/base/${dashboardType}`,
            method: 'GET'
        })
    },
    getLabels() {
        return conn.$axios({
            url: `/secured/v1/master/widgetLabels`,
            method: 'GET'
        })
    },
    getTypeWidgets() {
        return conn.$axios({
            url: `/secured/v1/master/widgetType`,
            method: 'GET'
        })
    },
    getDataTypeWidgets() {
        return conn.$axios({
            url: `/secured/v1/master/widgetDataType`,
            method: 'GET'
        })
    },
    getPeriodicity() {
        return conn.$axios({
            url: `/secured/v1/master/widgetPeriodicity`,
            method: 'GET'
        })
    },
}