<template>
  <div class="create-credit-card" id="top-part">
    <v-dialog
      :class="'rounded-t-xl'"
      transition="dialog-bottom-transition"
      :value="show"
      :maxWidth="800"
      :style="`backgroundColor:${colors.colorSurface} !important`"
      persistent
    >
      <template>
        <v-card
          class="max-h-full bg-white backdrop-blur-xl overflow-y-auto rounded-lg"
          :style="`backgroundColor:${colors.colorSurface} !important`"
        >
          <v-card-title
            class="text-h5 mb-2"
            :style="{
              color: colors.colorOnSurface,
            }"
          >
            {{ $t("newCreditCard") }}
          </v-card-title>
          <v-card-text>
            <div
              class="absolute flex flex-row justify-end align-end mt-2 mr-2 top-0 right-0 z-50"
            >
              <v-btn icon @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div
              class="flex flex-row align-center"
              :style="{
                color: colors.colorOnSurface,
              }"
            >
              <F2gIcon
                class="mr-2"
                :value="'security-check'"
                :height="30"
                :width="30"
                :stroke="colors.colorOnSurface"
                :strokeWidth="3"
              />
              <div class="text-base">
                {{ $t("encryptedPaymentData") }}
              </div>
            </div>
            <div class="lg:flex-row flex-col w-full mt-6">
              <div
                class="w-full lg:w-full mr-2 mb-4 border p-3 rounded-lg"
                :style="{ borderColor: colors.colorPrimary }"
                id="card-element"
              ></div>
            </div>
            <div class="flex align-center justify-center">
              <v-btn
                class="w-xl mb-2 border-2 mt-6 rounded-config30"
                :style="{
                  color: colors.colorOnPrimary,
                  backgroundColor: colors.colorPrimary,
                }"
                :loading="loading"
                @click="submit"
              >
                {{ $t("addCreditCard") }}
              </v-btn>
            </div>
            <div v-if="error" class="text-red-700 mb-2">{{ error }}</div>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script src="https://js.stripe.com/v3/"></script>

<script>
import { mapGetters, mapState } from "vuex";
import { StripeElementCard } from "@vue-stripe/vue-stripe";

export default {
  name: "CreateCreditCard",
  props: {
    show: { required: true, type: Boolean, default: true },
    ownerIdProp: { type: [String, Number], required: false },
  },
  components: {
    StripeElementCard,
  },
  data() {
    return {
      loading: false,
      clientSecret: null,
      stripe: null,
      paymentElement: null,
      error: null,
    };
  },
  computed: {
    ...mapState(["configuration"]),
    ...mapGetters({
      colors: "configuration/getColors",
      loginParameters: "user/loginParameters",
      userLoged: "user/userLoged",
      owner: "configuration/getOwner",
    }),
    ownerId() {
      return this.ownerIdProp ? this.ownerIdProp : this.owner;
    },
  },
  watch: {
    show() {
      if (this.show) {
        window.parent.postMessage("goToTop", "*");
      }
    },
  },
  mounted() {
    let params = {
      loginParameters: this.loginParameters,
      ownerId: { ownerId: this.ownerId },
    };
    this.$store
      .dispatch("configuration/getApiKeyStripe", params)
      .then((resp) => {
        let publicKey = process.env.VUE_APP_PUBLISHABLE_KEY;
        if (resp.data && resp.data.publicKey) {
          publicKey = resp.data.publicKey;
        }
        this.stripe = Stripe(publicKey, {
          locale: localStorage.getItem(`user-language`),
        });
        if (this.loginParameters) {
          this.customerStripe();
        }
      })
      .catch(() => {
        this.error = this.$t("errors.generic");
      });
  },
  methods: {
    customerStripe() {
      let params = Object.assign({}, this.loginParameters);
      params.ownerId = this.ownerId;
      this.$store
        .dispatch("configuration/customerStripe", params)
        .then(() => {
          this.getIntent();
        })
        .catch(() => {
          this.error = this.$t("errors.generic");
        });
    },
    getIntent() {
      if (!this.clientSecret) {
        let params = this.loginParameters;
        params.ownerId = this.ownerId;
        this.$store
          .dispatch("configuration/getIntentStripe", params)
          .then((resp) => {
            this.clientSecret = resp.data;
            let elements = this.stripe.elements({
              clientSecret: this.clientSecret,
            });
            elements.create("card", { hidePostalCode: true });
            this.paymentElement = elements.getElement("card");
            this.paymentElement.mount("#card-element");
          })
          .catch(() => {
            this.error = this.$t("errors.generic");
          })
          .finally(() => window.parent.postMessage("goToTop", "*"));
      }
    },
    submit() {
      this.loading = true;
      this.error = null;
      this.stripe
        .confirmCardSetup(this.clientSecret, {
          payment_method: { card: this.paymentElement },
        })
        .then((result) => {
          if (result.error) {
            this.error = result.error.message;
            this.loading = false;
          } else {
            this.newPayment(result);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.error = err.response.message;
        });
    },
    newPayment(result) {
      let data = {
        access_token: this.loginParameters.access_token,
        userId: this.userLoged.id,
        data: {
          cardId: result.setupIntent.payment_method,
          ownerId: this.ownerId,
        },
      };
      this.$store
        .dispatch("configuration/newPayment", data)
        .then(() => {
          this.loading = false;
          this.$emit("created");
          this.$emit("close");
        })
        .catch(() => {
          this.loading = false;
          this.error = this.$t("errors.generic");
        });
    },
  },
};
</script>

<style lang="scss">
.v-dialog--active {
  border-radius: 24px !important;
}
.v-dialog--fullscreen {
  height: 90% !important;
  bottom: 0 !important;
  top: auto !important;
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
</style>
