import service from "@/flit2go-web-core/services/";

export default {
  namespaced: true,
  state: {
    status: "",
    promotionsList: [],
  },
  mutations: {
    ["STATUS_SUCCESS"]: (state) => {
      state.status = "success";
    },
    ["STATUS_LOADING"]: (state) => {
      state.status = "loading";
    },
    ["STATUS_ERROR"]: (state) => {
      state.status = "error";
    },
    ["SET_PROMOTION_LIST"]: (state, promotion) => {
      state.promotionsList = promotion;
    },
  },
  actions: {
    getPromotions({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service.promotion
          .getPromotions(params)
          .then((resp) => {
            commit("SET_PROMOTION_LIST", resp.data.content);
            commit("STATUS_SUCCESS");
            resolve(resp.data);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getPromotionsByUserId({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service.promotion
          .getPromotionsByUserId(params)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    assignPromotion({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service.promotion
          .assignPromotion(params)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
  },
};
