import Vue from 'vue';

Vue.component("F2gButton", () => import("@/flit2go-web-core/components/buttons/f2g-button"));
Vue.component("F2gCard", ()=> import("@/flit2go-web-core/components/ui/card"));
Vue.component("F2gChip", () => import("@/flit2go-web-core/components/chips/f2g-chip"));
Vue.component("F2gColorPicker", ()=> import("@/flit2go-web-core/components/dialog-pickers/f2g-color-picker"));
Vue.component("F2gDialog", () => import("@/flit2go-web-core/components/dialog-pickers/f2g-dialog"));
Vue.component("F2gDialogLoader", () => import("@/flit2go-web-core/components/base/f2g-dialogLoader"));
Vue.component("F2gIcon", ()=> import("@/flit2go-web-core/components/ui/f2g-icon"));
Vue.component("F2gNote", ()=> import("@/flit2go-web-core/components/ui/f2g-note"));
Vue.component("F2gNoteForm", ()=> import("@/flit2go-web-core/components/ui/f2g-noteForm"));
Vue.component("F2gRoundedTitle", () => import("@/flit2go-web-core/components/ui/rounded-title"));
Vue.component("F2gSubtitle", () => import("@/flit2go-web-core/components/ui/f2g-subtitle"));
Vue.component("F2gTextField", () => import("@/flit2go-web-core/components/fields/f2g-text-field"));
Vue.component("F2gSelect", () => import("@/flit2go-web-core/components/fields/f2g-select"));
Vue.component("F2gAutocomplete", () => import("@/flit2go-web-core/components/fields/f2g-autocomplete"));
Vue.component("ScrollUpButton", ()=> import('@/flit2go-web-core/components/ui/scroll-up-btn'));
Vue.component("ActionDialogGeneric", ()=> import('@/flit2go-web-core/components/dialog-pickers/action-dialog-generic'));
Vue.component("F2gDatePicker", ()=> import('@/flit2go-web-core/components/ui/f2g-date-picker'));
Vue.component("F2gUxInfo", ()=> import('@/flit2go-web-core/components/ui/ux-info-icon'));
Vue.component("F2gTooltip", ()=> import('@/flit2go-web-core/components/ui/f2g-tooltip'));
Vue.component("DateFormatter", ()=> import('@/flit2go-web-core/components/ui/date-formatter'));