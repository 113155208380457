<template>
  <div class="vehicle-filter" v-if="vehicleTypes.length > 1">
    <v-menu content-class="mt-6" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-flex class="w-full cursor-pointer" v-bind="attrs" v-on="on">
          <h1 class="" :style="{ color: colors.colorOnHeader }">
            {{
              $t("common.vehicleType") +
                (typesSelected.length ? " (" + typesSelected.length + ")" : "")
            }}
          </h1>
          <F2gIcon class="svg mr-2" value="arrow-down" :strokeWidth="3" />
        </v-flex>
      </template>

      <v-list>
        <v-list-item :key="'all'" @click="changeSelectedAll">
          <v-list-item-action class="mr-2">
            <v-checkbox :value="selectedAll"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("common.allVehicles")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="(item, index) in vehicleTypes"
          :key="index"
          @click="
            !selectedAll ? addVehicle(item.replace('-', '').toLowerCase()) : ''
          "
          :disabled="selectedAll"
        >
          <v-list-item-action class="mr-2">
            <v-checkbox
              :input-value="
                !selectedAll &&
                  typesSelected &&
                  typesSelected.includes(item.toLowerCase())
              "
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t(`vehicleTypes.${item.replace("-", "")}`)
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "VehicleFilter",
  components: {},
  props: {
    type: { required: false, type: String, default: "vertical" },
    vehiclesFilter: { required: false, type: String, default: "" },
    vehicleTypesSelected: { required: false, type: String, default: "" },
  },
  data() {
    return {
      vehicleTypes: [],
      typesSelected: [],
      selectedAll: false,
    };
  },
  watch: {
    typesSelected: function() {
      this.$emit("vehiclesSelected", this.typesSelected);
    },
    vehiclesFilter: function() {
      this.vehicleTypes = this.vehiclesFilter.split(",");
    },
  },
  mounted() {
    this.vehicleTypes = this.vehiclesFilter
      ? this.vehiclesFilter.split(",")
      : [];
    if (this.vehicleTypesSelected) {
      this.typesSelected = this.vehicleTypesSelected
        ? this.vehicleTypesSelected.toLowerCase().split(",")
        : [];
    }
  },

  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
    }),
  },
  methods: {
    addVehicle(element) {
      if (this.typesSelected.includes(element)) {
        var i = this.typesSelected.indexOf(element);
        this.typesSelected.splice(i, 1);
      } else {
        this.typesSelected.push(element);
      }
    },
    changeSelectedAll() {
      this.selectedAll = !this.selectedAll;
      this.typesSelected = [];
      if (this.selectedAll) {
        this.vehicleTypes.forEach((vehicle) => {
          this.typesSelected.push(vehicle.replace("-", "").toLowerCase());
        });
      }
    },
  },
};
</script>
