import axios from "axios";
import { EventBus } from "@/flit2go-web-core/util/event-bus.js";
import Vue from "vue";

// Start axios config
const $axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});
// Response errors detect
$axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const vueInstance = Vue.prototype.$instance;
    if (
      (!error.response &&
        vueInstance._router.apps[0]._route.path != "/s/error") ||
      error.response.status === 502 ||
      error.response.status === 404
    ) {
      vueInstance.$store.commit(
        "auth/SET_LAST_ROUTE_VISITED",
        vueInstance._router.apps[0]._route.path
      );
      vueInstance._router.push("/s/error");
    } else if (
      error.response.status === 401 &&
      error.response.data.error == "invalid_token"
    ) {
      EventBus.$emit("invalid_token", true);
    }
    return Promise.reject(error);
  }
);

export default {
  $axios: $axios,
  setToken(token) {
    $axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  },
};
