<template>
  <div class="register-data">
    <div class="flex-wrap mt-4">
      <span class="flex flex-col lg:flex-row">
        <v-text-field
          class="lg:w-1/4 w-full lg:mr-2 custom-placeholer-color"
          v-model="register.name"
          :outlined="true"
          :style="
            `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
          "
          :color="colors.colorPrimary"
          :backgroundColor="colors.colorSurface"
          :dark="colors.colorBackground === '#000000' ? true : false"
          :error-messages="nameErrors"
          @input="sendData"
        >
          <template #label> {{ $t("common.name") }}<RedAsterisk /> </template>
        </v-text-field>
        <v-text-field
          class="lg:w-1/4 w-full lg:mr-2 custom-placeholer-color"
          v-model="register.surnames"
          :outlined="true"
          :style="
            `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
          "
          :color="colors.colorPrimary"
          :backgroundColor="colors.colorSurface"
          :dark="colors.colorBackground === '#000000' ? true : false"
          :error-messages="surnamesErrors"
          @input="sendData"
        >
          <template #label>
            {{ $t("common.surnames") }}<RedAsterisk />
          </template>
        </v-text-field>
        <v-text-field
          class="lg:w-1/4 w-full lg:mr-2 custom-placeholer-color"
          v-model="register.username"
          :outlined="true"
          :style="
            `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
          "
          :color="colors.colorPrimary"
          :backgroundColor="colors.colorSurface"
          :dark="colors.colorBackground === '#000000' ? true : false"
          :error-messages="emailErrors"
          :disabled="userInfo ? true : false"
          @input="
            () => {
              sendData();
              this.register.username = this.register.username.toLowerCase();
            }
          "
          @change="$v.register.username.$touch()"
        >
          <template #label> {{ $t("forms.email") }}<RedAsterisk /> </template>
        </v-text-field>
        <v-text-field
          v-if="!userInfo"
          class="lg:w-1/4 w-full custom-placeholer-color"
          v-model="register.repeatUserName"
          :outlined="true"
          :style="
            `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
          "
          :color="colors.colorPrimary"
          :backgroundColor="colors.colorSurface"
          :dark="colors.colorBackground === '#000000' ? true : false"
          :error-messages="repeatEmailErrors"
          @input="
            () => {
              sendData();
              this.register.repeatUserName = this.register.repeatUserName.toLowerCase();
            }
          "
          @change="$v.register.repeatUserName.$touch()"
        >
          <template #label>
            {{ $t("forms.repeatEmail") }}<RedAsterisk />
          </template>
        </v-text-field>
      </span>
      <span class="flex flex-col lg:flex-row">
        <v-flex class="lg:w-1/2 w-full">
          <PhoneNumber
            :outlined="true"
            :model="register.phoneNumber"
            :validate="validate"
            noCheckDisabled
            showAsterisk
            :style="
              `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
            "
            :backgroundColor="colors.colorSurface"
            frameColours
            :colors="colors"
            :dark="colors.colorBackground === '#000000' ? true : false"
            @phoneNumber="setPhoneNumber"
          />
        </v-flex>
      </span>
    </div>
    <!-- <p
      v-if="canShowAll && !userInfo"
      class="cursor-pointer flex flex-row"
      :style="`font-weight: bold;color: ${colors.colorPrimary}`"
      @click="toogleOptionalData"
    >
      {{ $t("clickForInformAllData") }}
      <F2gIcon
        :stroke="`${colors.colorPrimary}`"
        class="cursor-pointer font-bold"
        :value="arrowValue"
        :strokeWidth="3"
      />
    </p> -->
    <div class="register-data__optional" v-if="canShowAll">
      <transition name="fade">
        <div class="flex-wrap">
          <span class="flex flex-col lg:flex-row">
            <v-flex
              v-if="canShowData('bornDateMandatoryType')"
              class="lg:w-1/2 w-full"
            >
              <DatePickerFW
                class="lg:w-1/2 w-full custom-placeholer-color"
                :label="$t('forms.bornDate')"
                :clear="null === register.bornDate"
                :maxDate="DateUtil.maximumBornDateAdult()"
                :outlined="true"
                :input="register.bornDate"
                :errors="bornDateErrors"
                frameColours
                :colors="colors"
                :required="isRequired('bornDateMandatoryType')"
                :style="
                  `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
                "
                :backgroundColor="colors.colorSurface"
                :dark="colors.colorBackground === '#000000' ? true : false"
                @form="setBornDate"
              />
            </v-flex>
            <v-autocomplete
              v-if="canShowData('countryMandatoryType')"
              :label="$t('forms.nationality')"
              class="lg:w-1/2 w-full lg:ml-2 custom-placeholer-color"
              :class="[{ 'ml-0': !canShowData('bornDateMandatoryType') }]"
              v-model="register.nationality"
              :items="$PageUtils.orderArray(nationalities)"
              :item-text="$PageUtils.getNameLower()"
              return-object
              :outlined="true"
              :error-messages="nationalityErrors"
              :style="
                `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
              "
              :color="colors.colorPrimary"
              :backgroundColor="colors.colorSurface"
              :dark="colors.colorBackground === '#000000' ? true : false"
              :filter="customFilterCountries"
              @input="sendData"
            >
              <template #label>
                {{ $t("forms.nationality")
                }}<RedAsterisk v-if="isRequired('countryMandatoryType')" />
              </template>
            </v-autocomplete>
          </span>
          <!-- direction -->
          <p
            v-if="canShowDirectionData"
            class="leading-4 font-medium text-lg mb-3"
            :style="{ color: colors.colorOnBackground, paddingTop: '5px' }"
          >
            {{ $t("forms.address.residenceAddress") }}
          </p>
          <direction-data
            v-if="canShowDirectionData"
            :required="false"
            :validate="validate"
            :nationalities="nationalities"
            :userInfo="userInfo"
            @directionData="$emit('directionData', $event)"
            @validation="$emit('validationDirection', $event)"
          />
          <!-- nif -->
          <nif-driving-license-data
            v-if="canShowDrivingLicenseData"
            :required="false"
            :validate="validate"
            :nationalities="nationalities"
            :userInfo="userInfo"
            @nifDrivingLicenseData="$emit('nifDrivingLicenseData', $event)"
            @validation="$emit('validationNifDrivingLicenseData', $event)"
          />
          <!-- billing direction -->
          <!-- <p
            class="leading-4 font-medium text-lg mb-6"
            :style="{ color: colors.colorOnBackground, paddingTop: '5px' }"
          >
            {{ $t("forms.address.billingAddress") }}
          </p>
          <div class="flex flex-row justify-start items-center">
            <v-checkbox
              v-model="checkBilling"
              :dark="colors.colorBackground === '#000000' ? true : false"
              color="primary"
              @change="$emit('checkBilling', checkBilling)"
            />
            <span class="ml-2" :style="{ color: colors.colorOnBackground }">{{
              $t("forms.address.useResidenceAddress")
            }}</span>
          </div>
          <direction-billing-data
            v-if="!checkBilling"
            :validate="validate"
            :nationalities="nationalities"
            :isRequired="false"
            @billingAddress="$emit('billingAddress', $event)"
            @validation="$emit('billingDirectionValidation', $event)"
          /> -->
        </div>
      </transition>
    </div>

    <div class="flex flex-row justify-start items-center" v-if="!userInfo">
      <v-checkbox
        v-model="consentData"
        class="checkboxPadding"
        :color="colors.colorPrimary"
        :dark="colors.colorBackground === '#000000' ? true : false"
        :error-messages="consentErrors"
        @change="$emit('consentData', consentData)"
      >
      </v-checkbox>

      <span
        :style="{ color: colors.colorOnBackground }"
        class="lg:text-base text-sm"
        v-html="
          $t('common.consentCompanyData', {
            ownerName: ownerIsPremium ? ownerName : 'Flit2go',
            link:
              envBase +
              '/users/v1/frameweb/privacy?apiKey=' +
              $route.query.apiKey +
              '&lang=' +
              language,
          })
        "
      ></span>
    </div>
  </div>
</template>

<script>
import DatePickerFW from "@/modules/v1/Components/web-core/date-pickerFW.vue";
import DateUtil from "@/flit2go-web-core/util/dateUtil";
// import PhoneNumberFW from "@/modules/v1/Components/web-core/phone-numberFW.vue";
import PhoneNumber from "@/flit2go-web-core/components/ui/phone-number";
import { required, requiredIf, email, sameAs } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

import DirectionData from "@/components/direction-data.vue";
import NifDrivingLicenseData from "@/components/nif-driving-license-data.vue";
// import DirectionBillingData from "@/components/direction-billing-data.vue";

const envBase = process.env.VUE_APP_API_URL;

export default {
  name: "RegisterData",
  components: {
    DatePickerFW,
    PhoneNumber,
    DirectionData,
    // DirectionBillingData,
    NifDrivingLicenseData,
    RedAsterisk: () =>
      import("@/flit2go-web-core/components/fields/f2g-red-asterisk"),
  },
  props: {
    nationalities: Array,
    validate: {
      type: Boolean,
      default: false,
    },
    ownerName: {
      type: String,
      required: true,
    },
    ownerIsPremium: {
      type: Boolean,
      required: true,
    },
    openExtraData: {
      type: Boolean,
      required: false,
      default: false,
    },
    userInfo: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      envBase,
      DateUtil,
      showLogin: false,
      showRegister: false,
      showOptional: false,
      checkBilling: true,
      consentData: false,
      register: {
        name: null,
        surnames: null,
        username: null,
        repeatUserName: null,
        phoneNumber: null,
        bornDate: null,
        nationality: null,
      },
    };
  },
  validations() {
    return {
      register: {
        name: { required },
        surnames: { required },
        username: { required, email },
        repeatUserName: {
          required,
          sameAs: sameAs(() => this.register.username),
        },
        phoneNumber: { required },
        bornDate: {
          required: requiredIf(function() {
            return this.isRequired("bornDateMandatoryType");
          }),
        },
        nationality: {
          required: requiredIf(function() {
            return this.isRequired("countryMandatoryType");
          }),
        },
      },
      consentData: {
        sameAs: sameAs(() => (this.userInfo ? false : true)),
      },
    };
  },
  watch: {
    validate() {
      this.$v.$touch();
      this.$emit("validation", {
        valid: !this.$v.$invalid,
      });
    },
    openExtraData() {
      if (this.openExtraData) {
        this.showOptional = true;
      }
    },
  },
  mounted() {
    if (this.userInfo) {
      this.fillUserInfo();
    }
    if (!this.canShowDirectionData) {
      this.$emit("validationDirection", { valid: true });
    }
    if (!this.canShowDrivingLicenseData) {
      this.$emit("validationNifDrivingLicenseData", { valid: true });
    }
  },
  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
      language: "configuration/getUserLanguage",
      filterInformationUsers: "configuration/getFilterInformationUsers",
    }),
    canShowAll() {
      return (
        this.filterInformationUsers.countryMandatoryType !==
          this.$CONSTANTS.USER.MANDATORY.NOT_APPEAR ||
        this.filterInformationUsers.bornDateMandatoryType !==
          this.$CONSTANTS.USER.MANDATORY.NOT_APPEAR ||
        this.canShowDirectionData ||
        this.canShowDrivingLicenseData
      );
    },
    canShowDirectionData() {
      let show =
        this.canShowData("directionMandatoryType") ||
        this.canShowData("directionAddInfoMandatoryType") ||
        this.canShowData("addressCountryMandatoryType") ||
        this.canShowData("cityMandatoryType") ||
        this.canShowData("localityMandatoryType") ||
        this.canShowData("postalCodeMandatoryType");
      return show;
    },
    canShowDrivingLicenseData() {
      let show =
        this.canShowData("nifMandatoryType") ||
        this.canShowData("nifValidityDateMandatoryType") ||
        this.canShowData("nifPhotosMandatoryType") ||
        this.canShowData("drivingLicenseMandatoryType") ||
        this.canShowData("drivingValidityDateMandatoryType") ||
        this.canShowData("drivingPhotosMandatoryType") ||
        this.canShowData("expeditionLicenseBMandatoryType") ||
        this.canShowData("expeditionLicenseAMandatoryType") ||
        this.canShowData("licenseRangeMandatoryType");
      return show;
    },
    arrowValue() {
      if (this.showOptional) {
        return "arrow-up";
      } else {
        return "arrow-down";
      }
    },
    nameErrors() {
      const errors = [];
      if (this.$v.register.name.$dirty) {
        !this.$v.register.name.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    surnamesErrors() {
      const errors = [];
      if (this.$v.register.surnames.$dirty) {
        !this.$v.register.surnames.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (this.$v.register.username.$dirty) {
        !this.$v.register.username.required &&
          errors.push(this.$t("errors.required"));
        !this.$v.register.username.email &&
          errors.push(this.$t("errors.username"));
      }
      return errors;
    },
    repeatEmailErrors() {
      const errors = [];
      if (this.$v.register.repeatUserName.$dirty) {
        !this.$v.register.repeatUserName.required &&
          errors.push(this.$t("errors.required"));
        !this.$v.register.repeatUserName.sameAs &&
          errors.push(this.$t("errors.differentEmails"));
      }
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (this.$v.register.phoneNumber.$dirty) {
        !this.$v.register.phoneNumber.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    bornDateErrors() {
      const errors = [];
      if (this.$v.register.bornDate.$dirty) {
        !this.$v.register.bornDate.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    nationalityErrors() {
      const errors = [];
      if (this.$v.register.nationality.$dirty) {
        !this.$v.register.nationality.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    consentErrors() {
      const errors = [];
      if (this.$v.consentData.$dirty) {
        !this.$v.consentData.sameAs && errors.push(this.$t("errors.required"));
      }
      return errors;
    },
  },
  methods: {
    sendData() {
      this.$emit("personalData", this.register);
    },
    isRequired(element) {
      return (
        this.filterInformationUsers[element] ===
        this.$CONSTANTS.USER.MANDATORY.MANDATORY
      );
    },
    isOptional(element) {
      return (
        this.filterInformationUsers[element] ===
        this.$CONSTANTS.USER.MANDATORY.OPTIONAL
      );
    },
    canShowData(element) {
      return (
        this.filterInformationUsers[element] !==
        this.$CONSTANTS.USER.MANDATORY.NOT_APPEAR
      );
    },
    setBornDate(bornDate) {
      this.register.bornDate = bornDate;
      this.sendData();
    },
    setPhoneNumber(phone) {
      this.register.phoneNumber = phone;
      this.sendData();
    },
    toogleOptionalData() {
      this.showOptional = !this.showOptional;
      if (!this.showOptional) {
        this.register.bornDate = null;
        this.register.nationality = null;
        this.sendData();
      }
      this.$emit("emptyDirections");
      window.parent.postMessage("manualResize", "*");
    },
    fillUserInfo() {
      this.register.name = this.userInfo.name;
      this.register.surnames = this.userInfo.surnames;
      this.register.username = this.userInfo.username;
      this.register.repeatUserName = this.userInfo.username;
      this.register.phoneNumber = this.userInfo.phoneNumber;
      this.register.bornDate = this.userInfo.bornDate
        ? DateUtil.formatBasicDate(this.userInfo.bornDate)
        : null;
      if (this.userInfo.country) {
        const country = this.nationalities.filter(
          (country) => country.code === this.userInfo.country.code
        )[0];
        this.register.nationality = country;
      } else {
        this.register.nationality = null;
      }
    },
    customFilterCountries(item, queryText) {
      return (
        this.$PageUtils
          .removeAccents(item.name[this.$PageUtils.getLanguageSplittedMinus()])
          ?.toLowerCase()
          .indexOf(this.$PageUtils.removeAccents(queryText).toLowerCase()) > -1
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.checkboxPadding > .v-input__control > .v-input__slot {
  padding-left: 0 !important;
}
.custom-placeholer-color input {
  color: var(--custom_color-text) !important;
}
.custom-placeholer-color .v-label {
  color: var(--custom_color-label) !important;
  opacity: 1;
}
</style>
