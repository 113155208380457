import conn from "./connection"

export default {
    getOwnerImage(id){
        return conn.$axios({
            url: `secured/v1/owner/${id}/imgDB`,
            method: "GET",
            headers: {
                "Content-type": "image/png"
            }
        })
    },
    getImageByUrl(imgUrl){
        return conn.$axios({
            url: imgUrl,
            method: "GET",
            // headers: {
            //     "Content-type": "image/png"
            // }
        })
    }
}