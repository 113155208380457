<template>
  <div class="nif-driving-license-data flex-wrap flex-col">
    <p
      v-if="canShowNif"
      class="leading-4 font-medium text-lg mb-3"
      :style="{ color: colors.colorOnBackground, paddingTop: '5px' }"
    >
      {{ $t("forms.nif") }}
    </p>
    <span class="flex flex-col lg:flex-row">
      <v-flex v-if="canShowData('nifMandatoryType')" class="lg:w-1/2 w-full">
        <v-text-field
          class="lg:mr-2 custom-placeholer-color"
          v-model="userDocuments.nif"
          :outlined="true"
          :style="
            `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
          "
          :color="colors.colorPrimary"
          :backgroundColor="colors.colorSurface"
          :dark="colors.colorBackground === '#000000' ? true : false"
          :error-messages="nifErrors"
          @input="sendData"
        >
          <template #label>
            {{ $t("numberDocumentIdentity")
            }}<RedAsterisk v-if="isRequired('nifMandatoryType')" />
          </template>
        </v-text-field>
      </v-flex>
      <v-flex
        v-if="canShowData('nifValidityDateMandatoryType')"
        class="lg:w-1/2 w-full"
      >
        <DatePickerFW
          class=""
          key="nifValidityDate"
          :label="$t('common.validityDate')"
          :clear="null === userDocuments.nifValidityDate"
          :input="userDocuments.nifValidityDate"
          :minDate="DateUtil.currentDateBasicFormat()"
          :outlined="true"
          :errors="validityDateErrors"
          :required="isRequired('nifValidityDateMandatoryType')"
          frameColours
          :colors="colors"
          :backgroundColor="colors.colorSurface"
          :dark="colors.colorBackground === '#000000' ? true : false"
          @form="setNifValidityDate"
        />
      </v-flex>
    </span>
    <span
      v-if="canShowData('nifPhotosMandatoryType')"
      class="flex flex-col lg:flex-row"
    >
      <v-flex class="lg:w-1/2 w-full">
        <input
          type="file"
          style="display: none"
          id="fileUploadNifPhotosMandatoryTypeFront"
          hidden
          accept="image/*"
          @change="onFilePickedNifFront"
        />
        <div class="flex flex-col">
          <v-img
            v-if="urlNifFront"
            :src="urlNifFront"
            :aspect-ratio="16 / 9"
            max-width="400px"
            class="image rounded-lg mr-2"
          />
          <div class="flex flex-row mt-2">
            <v-btn
              class="lg:w-48 w-1/2 mb-4 border-2 rounded-config30"
              outlined
              :loading="loading"
              :color="colors.colorPrimary"
              @click="chooseFiles('nifPhotosMandatoryTypeFront')"
            >
              <v-icon left small class="mb-1"> mdi-camera-plus </v-icon>
              {{ $t("frontPart")
              }}<RedAsterisk v-if="isRequired('nifPhotosMandatoryType')" />
            </v-btn>
            <span
              v-if="userDocuments.nifImgFrontName"
              class="w-1/2 mt-2 ml-2 mr-2 truncate"
              >{{ userDocuments.nifImgFrontName }}
            </span>
          </div>
        </div>
      </v-flex>
      <v-flex class="lg:w-1/2 w-full">
        <input
          type="file"
          style="display: none"
          id="fileUploadNifPhotosMandatoryTypeReverse"
          hidden
          accept="image/*"
          @change="onFilePickedNifReverse"
        />
        <div class="flex flex-col">
          <v-img
            v-if="urlNifReverse"
            :src="urlNifReverse"
            :aspect-ratio="16 / 9"
            max-width="400px"
            class="image rounded-lg mr-2"
          />
          <div class="flex flex-row mt-2">
            <v-btn
              class="lg:w-48 w-1/2 mb-4 border-2 rounded-config30"
              outlined
              :loading="loading"
              :color="colors.colorPrimary"
              @click="chooseFiles('nifPhotosMandatoryTypeReverse')"
            >
              <v-icon left small class="mb-1"> mdi-camera-plus </v-icon>
              {{ $t("backPart")
              }}<RedAsterisk v-if="isRequired('nifPhotosMandatoryType')" />
            </v-btn>
            <span
              v-if="userDocuments.nifImgReverseName"
              class="w-1/2 mt-2 ml-2 mr-2 truncate"
              >{{ userDocuments.nifImgReverseName }}
            </span>
          </div>
        </div>
      </v-flex>
    </span>
    <span
      v-if="canShowData('nifPhotosMandatoryType') && imageNifErrors.length > 0"
      class="flex flex-col lg:flex-row errorTextPhotos"
    >
      {{ imageNifErrors[0] }}
    </span>
    <p
      v-if="canShowDrivingLicense"
      class="leading-4 font-medium text-lg mb-6"
      :style="{ color: colors.colorOnBackground, paddingTop: '5px' }"
    >
      {{ $t("mobile.register.licenseDriving") }}
    </p>
    <span key="drivingLicense" class="flex flex-col lg:flex-row">
      <v-flex
        v-if="canShowData('drivingLicenseMandatoryType')"
        class="lg:w-1/2 w-full"
      >
        <v-autocomplete
          class="lg:mr-2 custom-placeholer-color"
          v-model="userDocuments.drivingLicense"
          :outlined="true"
          :items="drivingLicenseOrigins"
          :item-text="$PageUtils.getNameLower()"
          return-object
          :error-messages="drivingLicenseErrors"
          :style="
            `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
          "
          :color="colors.colorPrimary"
          :backgroundColor="colors.colorSurface"
          :dark="colors.colorBackground === '#000000' ? true : false"
          @input="sendData"
        >
          <template #label>
            {{ $t("mobile.register.licenseDriving")
            }}<RedAsterisk v-if="isRequired('drivingLicenseMandatoryType')" />
          </template>
        </v-autocomplete>
      </v-flex>
      <v-flex
        v-if="
          userDocuments.drivingLicense &&
            userDocuments.drivingLicense.code ===
              $CONSTANTS.DRIVING_LICENSE.TYPE.OBTAINED_IN_COUNTRY
        "
        class="lg:w-1/2 w-full"
      >
        <v-autocomplete
          class="lg:mr-2 custom-placeholer-color"
          v-model="userDocuments.drivingLicenseCountry"
          :outlined="true"
          :items="$PageUtils.orderArray(nationalities)"
          :item-text="$PageUtils.getNameLower()"
          return-object
          :error-messages="drivingLicenseCountryErrors"
          :style="
            `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
          "
          :color="colors.colorPrimary"
          :backgroundColor="colors.colorSurface"
          :dark="colors.colorBackground === '#000000' ? true : false"
          @input="sendData"
        >
          <template #label>
            {{ $t("forms.obtainedCountry") }}<RedAsterisk />
          </template>
        </v-autocomplete>
      </v-flex>
      <v-flex
        v-if="canShowData('drivingValidityDateMandatoryType')"
        class="lg:w-1/2 w-full"
      >
        <DatePickerFW
          class=""
          key="drivingLicenseValidityDate"
          :label="$t('common.validityDate')"
          :clear="null === userDocuments.drivingLicenseValidityDate"
          :input="userDocuments.drivingLicenseValidityDate"
          :minDate="DateUtil.currentDateBasicFormat()"
          :outlined="true"
          :errors="drivingLicenseValidityDateErrors"
          :required="isRequired('drivingValidityDateMandatoryType')"
          frameColours
          :colors="colors"
          :backgroundColor="colors.colorSurface"
          :dark="colors.colorBackground === '#000000' ? true : false"
          @form="setDrivingLicenseValidityDate"
        />
      </v-flex>
    </span>
    <span class="flex flex-col lg:flex-row">
      <v-flex
        v-if="canShowData('drivingPhotosMandatoryType')"
        class="lg:w-1/2 w-full"
      >
        <input
          type="file"
          style="display: none"
          id="fileUploadDrivingPhotosMandatoryTypeFront"
          hidden
          accept="image/*"
          @change="onFilePickedDLFront"
        />
        <div class="flex flex-col">
          <v-img
            v-if="urlDLFront"
            :src="urlDLFront"
            :aspect-ratio="16 / 9"
            max-width="400px"
            class="image rounded-lg mr-2"
          />
          <div class="flex flex-row mt-2">
            <v-btn
              class="lg:w-48 w-1/2 mb-4 border-2 rounded-config30"
              outlined
              :loading="loading"
              :color="colors.colorPrimary"
              @click="chooseFiles('drivingPhotosMandatoryTypeFront')"
            >
              <v-icon left small class="mb-1"> mdi-camera-plus </v-icon>
              {{ $t("frontPart")
              }}<RedAsterisk v-if="isRequired('drivingPhotosMandatoryType')" />
            </v-btn>
            <span
              v-if="userDocuments.drivingLicenseImgFrontName"
              class="w-1/2 mt-2 ml-2 mr-2 truncate"
            >
              {{ userDocuments.drivingLicenseImgFrontName }}
            </span>
          </div>
        </div>
      </v-flex>
      <v-flex
        v-if="canShowData('drivingPhotosMandatoryType')"
        class="lg:w-1/2 w-full"
      >
        <input
          type="file"
          style="display: none"
          id="fileUploadDrivingPhotosMandatoryTypeReverse"
          hidden
          accept="image/*"
          @change="onFilePickedDLReverse"
        />
        <div class="flex flex-col">
          <v-img
            v-if="urlDLreverse"
            :src="urlDLreverse"
            :aspect-ratio="16 / 9"
            max-width="400px"
            class="image rounded-lg mr-2"
          />
          <div class="flex flex-row mt-2">
            <v-btn
              class="lg:w-48 w-1/2 mb-4 border-2 rounded-config30"
              outlined
              :loading="loading"
              :color="colors.colorPrimary"
              @click="chooseFiles('drivingPhotosMandatoryTypeReverse')"
            >
              <v-icon left small class="mb-1"> mdi-camera-plus </v-icon>
              {{ $t("backPart")
              }}<RedAsterisk v-if="isRequired('drivingPhotosMandatoryType')" />
            </v-btn>
            <span
              v-if="userDocuments.drivingLicenseImgReverseName"
              class="w-1/2 mt-2 ml-2 mr-2 truncate"
              >{{ userDocuments.drivingLicenseImgReverseName }}
            </span>
          </div>
        </div>
      </v-flex>
    </span>
    <span
      v-if="
        canShowData('drivingPhotosMandatoryType') &&
          imageDrivingLicenseErrors.length > 0
      "
      class="flex flex-col lg:flex-row errorTextPhotos"
    >
      {{ imageDrivingLicenseErrors[0] }}
    </span>
    <div
      v-if="canShowData('expeditionLicenseBMandatoryType')"
      class="flex flex-row justify-start items-center"
    >
      <v-checkbox
        v-model="userDocuments.drivingLicenseCar"
        class="checkboxPadding"
        :color="colors.colorPrimary"
        :dark="colors.colorBackground === '#000000' ? true : false"
        @change="
          () => {
            userDocuments.drivingLicenseCar
              ? (showLicenseCar = true)
              : (showLicenseCar = false);
          }
        "
      >
        <template v-slot:label>
          <div :style="{ color: colors.colorOnBackground }">
            {{ $t("mobile.register.licenseTypeB") }}
          </div>
        </template>
      </v-checkbox>
    </div>
    <div
      v-if="showLicenseCar"
      class="flex flex-row justify-start items-center mt-3"
    >
      <v-flex class="w-full">
        <DatePickerFW
          class=""
          :label="$t('carLicenseIssueDate')"
          :clear="null === userDocuments.drivingLicenseCarIssueDate"
          :maxDate="DateUtil.currentDateBasicFormat()"
          :outlined="true"
          :input="userDocuments.drivingLicenseCarIssueDate"
          :errors="drivingLicenseCarIssueDateErrors"
          :required="isRequired('expeditionLicenseBMandatoryType')"
          frameColours
          :colors="colors"
          :backgroundColor="colors.colorSurface"
          :dark="colors.colorBackground === '#000000' ? true : false"
          @form="setDrivingLicenseCarIssueDate"
        />
      </v-flex>
    </div>
    <div
      v-if="canShowData('expeditionLicenseAMandatoryType')"
      class="flex flex-row justify-start items-center"
    >
      <v-checkbox
        class="checkboxPadding"
        v-model="userDocuments.drivingLicenseMotorcycle"
        :color="colors.colorPrimary"
        :dark="colors.colorBackground === '#000000' ? true : false"
        @change="
          () => {
            userDocuments.drivingLicenseMotorcycle
              ? (showLicenseMotorcycle = true)
              : (showLicenseMotorcycle = false);
          }
        "
      >
        <template v-slot:label>
          <div :style="{ color: colors.colorOnBackground }">
            {{ $t("mobile.register.licenseTypeA") }}
          </div>
        </template>
      </v-checkbox>
    </div>
    <div
      v-if="showLicenseMotorcycle"
      class="flex lg:flex-row flex-col justify-start items-center"
    >
      <v-flex class="lg:w-1/2 w-full mt-3 lg:mr-2">
        <DatePickerFW
          class=""
          :label="$t('motorcycleLicenseIssueDate')"
          :clear="null === userDocuments.drivingLicenseMotorcycleIssueDate"
          :maxDate="DateUtil.currentDateBasicFormat()"
          :outlined="true"
          :input="userDocuments.drivingLicenseMotorcycleIssueDate"
          :errors="drivingLicenseMotorcycleIssueDateErrors"
          :required="isRequired('expeditionLicenseAMandatoryType')"
          frameColours
          :colors="colors"
          :backgroundColor="colors.colorSurface"
          :dark="colors.colorBackground === '#000000' ? true : false"
          @form="setDrivingLicenseMotorcycleIssueDate"
        />
      </v-flex>
      <v-flex
        v-if="canShowData('licenseRangeMandatoryType')"
        class="lg:w-1/2 w-full lg:mt-3"
      >
        <v-autocomplete
          v-model="userDocuments.licenseHighestRange"
          class="custom-placeholer-color"
          :outlined="true"
          :items="carLicenses"
          :error-messages="licenseHighestRangeErrors"
          :style="
            `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
          "
          :color="colors.colorPrimary"
          :backgroundColor="colors.colorSurface"
          :dark="colors.colorBackground === '#000000' ? true : false"
          @input="sendData"
        >
          <template #label>
            {{ $t("mobile.register.majorRangeLicenseA")
            }}<RedAsterisk v-if="isRequired('licenseRangeMandatoryType')" />
          </template>
        </v-autocomplete>
      </v-flex>
    </div>
  </div>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import DateUtil from "@/flit2go-web-core/util/dateUtil";
import DatePickerFW from "@/modules/v1/Components/web-core/date-pickerFW.vue";
export default {
  name: "NifDrivingLicenseData",
  components: {
    DatePickerFW,
    RedAsterisk: () =>
      import("@/flit2go-web-core/components/fields/f2g-red-asterisk"),
  },
  props: {
    nationalities: Array,
    validate: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    userInfo: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      DateUtil,
      userDocuments: {
        nif: null,
        nifValidityDate: null,
        nifImgFront: null,
        nifImgReverse: null,
        nifImgFrontName: null,
        nifImgReverseName: null,
        drivingLicense: null,
        drivingLicenseCountry: null,
        drivingLicenseValidityDate: null,
        drivingLicenseImgFront: null,
        drivingLicenseImgReverse: null,
        drivingLicenseImgFrontName: null,
        drivingLicenseImgReverseName: null,
        drivingLicenseCar: false,
        drivingLicenseCarIssueDate: null,
        drivingLicenseMotorcycle: false,
        drivingLicenseMotorcycleIssueDate: null,
        licenseHighestRange: null,
      },
      loading: false,
      showLicenseMotorcycle: false,
      showLicenseCar: false,
      drivingLicenseOrigins: [],
      carLicenses: ["A", "A2", "A1", "AM"],
      urlNifFront: null,
      urlNifReverse: null,
      urlDLFront: null,
      urlDLreverse: null,
    };
  },
  validations: {
    userDocuments: {
      nif: {
        required: requiredIf(function() {
          return this.isRequired("nifMandatoryType");
        }),
      },
      nifValidityDate: {
        required: requiredIf(function() {
          return this.isRequired("nifValidityDateMandatoryType");
        }),
      },
      nifImgFront: {
        required: requiredIf(function() {
          return (
            this.isRequired("nifPhotosMandatoryType") &&
            !this.userDocuments.nifImgFrontName
          );
        }),
      },
      nifImgReverse: {
        required: requiredIf(function() {
          return (
            this.isRequired("nifPhotosMandatoryType") &&
            !this.userDocuments.nifImgReverseName
          );
        }),
      },
      drivingLicense: {
        required: requiredIf(function() {
          return this.isRequired("drivingLicenseMandatoryType");
        }),
      },
      drivingLicenseImgFront: {
        required: requiredIf(function() {
          return (
            this.isRequired("drivingPhotosMandatoryType") &&
            !this.userDocuments.drivingLicenseImgFrontName
          );
        }),
      },
      drivingLicenseImgReverse: {
        required: requiredIf(function() {
          return (
            this.isRequired("drivingPhotosMandatoryType") &&
            !this.userDocuments.drivingLicenseImgReverseName
          );
        }),
      },
      drivingLicenseCountry: {
        required: requiredIf(function() {
          return (
            this.userDocuments.drivingLicense &&
            this.userDocuments.drivingLicense.code ===
              this.$CONSTANTS.DRIVING_LICENSE.TYPE.OBTAINED_IN_COUNTRY
          );
        }),
      },
      drivingLicenseValidityDate: {
        required: requiredIf(function() {
          return this.isRequired("drivingValidityDateMandatoryType");
        }),
      },
      drivingLicenseCarIssueDate: {
        required: requiredIf(function() {
          return this.isRequired("expeditionLicenseBMandatoryType");
        }),
      },
      drivingLicenseMotorcycleIssueDate: {
        required: requiredIf(function() {
          return this.isRequired("expeditionLicenseAMandatoryType");
        }),
      },
      licenseHighestRange: {
        required: requiredIf(function() {
          return this.isRequired("licenseRangeMandatoryType");
        }),
      },
    },
  },
  watch: {
    validate() {
      this.$v.$touch();
      if (this.isRequired("expeditionLicenseBMandatoryType")) {
        this.activeLicenseCar();
      }
      if (this.isRequired("expeditionLicenseAMandatoryType")) {
        this.activeLicenseMoto();
      }
      this.$emit("validation", {
        valid: !this.$v.$invalid,
      });
    },
  },
  mounted() {
    if (this.userInfo) {
      this.fillUserInfo();
    }
  },
  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
      language: "configuration/getUserLanguage",
      filterInformationUsers: "configuration/getFilterInformationUsers",
    }),
    canShowNif() {
      let show =
        this.canShowData("nifMandatoryType") ||
        this.canShowData("nifValidityDateMandatoryType") ||
        this.canShowData("nifPhotosMandatoryType");
      return show;
    },
    canShowDrivingLicense() {
      let show =
        this.canShowData("drivingLicenseMandatoryType") ||
        this.canShowData("drivingValidityDateMandatoryType") ||
        this.canShowData("drivingPhotosMandatoryType") ||
        this.canShowData("expeditionLicenseBMandatoryType") ||
        this.canShowData("expeditionLicenseAMandatoryType") ||
        this.canShowData("licenseRangeMandatoryType");
      return show;
    },
    nifErrors() {
      const errors = [];
      if (this.$v.userDocuments.nif.$dirty) {
        !this.$v.userDocuments.nif.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    validityDateErrors() {
      const errors = [];
      if (this.$v.userDocuments.nifValidityDate.$dirty) {
        !this.$v.userDocuments.nifValidityDate.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    imageNifErrors() {
      const errors = [];
      if (
        this.$v.userDocuments.nifImgFront.$dirty ||
        this.$v.userDocuments.nifImgReverse.$dirty
      ) {
        (!this.$v.userDocuments.nifImgFront.required ||
          !this.$v.userDocuments.nifImgReverse.required) &&
          errors.push(this.$t("documentPhotosRequired"));
      }
      return errors;
    },
    drivingLicenseErrors() {
      const errors = [];
      if (this.$v.userDocuments.drivingLicense.$dirty) {
        !this.$v.userDocuments.drivingLicense.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    imageDrivingLicenseErrors() {
      const errors = [];
      if (
        this.$v.userDocuments.drivingLicenseImgFront.$dirty ||
        this.$v.userDocuments.drivingLicenseImgReverse.$dirty
      ) {
        (!this.$v.userDocuments.drivingLicenseImgFront.required ||
          !this.$v.userDocuments.drivingLicenseImgReverse.required) &&
          errors.push(this.$t("documentPhotosRequired"));
      }
      return errors;
    },
    drivingLicenseCountryErrors() {
      const errors = [];
      if (this.$v.userDocuments.drivingLicenseCountry.$dirty) {
        !this.$v.userDocuments.drivingLicenseCountry.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    drivingLicenseValidityDateErrors() {
      const errors = [];
      if (this.$v.userDocuments.drivingLicenseValidityDate.$dirty) {
        !this.$v.userDocuments.drivingLicenseValidityDate.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    drivingLicenseCarIssueDateErrors() {
      const errors = [];
      if (this.$v.userDocuments.drivingLicenseCarIssueDate.$dirty) {
        !this.$v.userDocuments.drivingLicenseCarIssueDate.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    drivingLicenseMotorcycleIssueDateErrors() {
      const errors = [];
      if (this.$v.userDocuments.drivingLicenseMotorcycleIssueDate.$dirty) {
        !this.$v.userDocuments.drivingLicenseMotorcycleIssueDate.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    licenseHighestRangeErrors() {
      const errors = [];
      if (this.$v.userDocuments.licenseHighestRange.$dirty) {
        !this.$v.userDocuments.licenseHighestRange.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
  },
  created() {
    this.sendData();
    this.getDrivingLicenses();
  },
  methods: {
    sendData() {
      this.$emit("nifDrivingLicenseData", this.userDocuments);
    },
    activeLicenseCar() {
      this.userDocuments.drivingLicenseCar = true;
      this.showLicenseCar = true;
    },
    activeLicenseMoto() {
      this.userDocuments.drivingLicenseMotorcycle = true;
      this.showLicenseMotorcycle = true;
    },
    setDrivingLicenseValidityDate(drivingLicenseValidityDate) {
      this.userDocuments.drivingLicenseValidityDate = drivingLicenseValidityDate;
      this.sendData();
    },
    setNifValidityDate(nifValidityDate) {
      this.userDocuments.nifValidityDate = nifValidityDate;
      this.sendData();
    },
    setDrivingLicenseCarIssueDate(drivingLicenseCarIssueDate) {
      this.userDocuments.drivingLicenseCarIssueDate = drivingLicenseCarIssueDate;
      this.sendData();
    },
    setDrivingLicenseMotorcycleIssueDate(drivingLicenseMotorcycleIssueDate) {
      this.userDocuments.drivingLicenseMotorcycleIssueDate = drivingLicenseMotorcycleIssueDate;
      this.sendData();
    },
    getDrivingLicenses() {
      this.$store.dispatch("user/getDrivingLicensesOrigins").then((resp) => {
        this.drivingLicenseOrigins = resp;
      });
    },
    isRequired(element) {
      return (
        this.filterInformationUsers[element] ===
        this.$CONSTANTS.USER.MANDATORY.MANDATORY
      );
    },
    isOptional(element) {
      return (
        this.filterInformationUsers[element] ===
        this.$CONSTANTS.USER.MANDATORY.OPTIONAL
      );
    },
    canShowData(element) {
      return (
        this.filterInformationUsers[element] !==
        this.$CONSTANTS.USER.MANDATORY.NOT_APPEAR
      );
    },
    chooseFiles(openInputFile) {
      switch (openInputFile) {
        case "nifPhotosMandatoryTypeFront":
          document
            .getElementById("fileUploadNifPhotosMandatoryTypeFront")
            .click();
          break;
        case "nifPhotosMandatoryTypeReverse":
          document
            .getElementById("fileUploadNifPhotosMandatoryTypeReverse")
            .click();
          break;
        case "drivingPhotosMandatoryTypeFront":
          document
            .getElementById("fileUploadDrivingPhotosMandatoryTypeFront")
            .click();
          break;
        case "drivingPhotosMandatoryTypeReverse":
          document
            .getElementById("fileUploadDrivingPhotosMandatoryTypeReverse")
            .click();
          break;
      }
    },
    onFilePickedNifFront(event) {
      const files = event.target.files;
      let filename = files[0].name;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.urlNifFront = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.userDocuments.nifImgFront = files[0];
      this.userDocuments.nifImgFrontName = filename;
      this.sendData();
    },
    onFilePickedNifReverse(event) {
      const files = event.target.files;
      let filename = files[0].name;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.urlNifReverse = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.userDocuments.nifImgReverse = files[0];
      this.userDocuments.nifImgReverseName = filename;
      this.sendData();
    },
    onFilePickedDLFront(event) {
      const files = event.target.files;
      let filename = files[0].name;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.urlDLFront = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.userDocuments.drivingLicenseImgFront = files[0];
      this.userDocuments.drivingLicenseImgFrontName = filename;
      this.sendData();
    },
    onFilePickedDLReverse(event) {
      const files = event.target.files;
      let filename = files[0].name;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.urlDLreverse = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.userDocuments.drivingLicenseImgReverse = files[0];
      this.userDocuments.drivingLicenseImgReverseName = filename;
      this.sendData();
    },
    fillUserInfo() {
      this.userDocuments.drivingLicenseMotorcycle = this.userInfo
        .expeditionLicenseA
        ? true
        : false;
      this.userDocuments.drivingLicenseCar = this.userInfo.expeditionLicenseB
        ? true
        : false;
      this.showLicenseCar = this.userDocuments.drivingLicenseCar;
      this.showLicenseMotorcycle = this.userDocuments.drivingLicenseMotorcycle;
      setTimeout(() => {
        this.userDocuments.nif = this.userInfo.nif;
        this.userDocuments.nifValidityDate = this.userInfo.nifValidityDate
          ? DateUtil.formatBasicDate(this.userInfo.nifValidityDate)
          : null;
        this.userDocuments.nifImgFrontName = this.getNameImage("imgDniFront");
        this.userDocuments.nifImgReverseName = this.getNameImage(
          "imgDniReverse"
        );
        this.userDocuments.drivingLicenseImgFrontName = this.getNameImage(
          "imgDrivingLicenseFront"
        );
        this.userDocuments.drivingLicenseImgReverseName = this.getNameImage(
          "imgDrivingLicenseReverse"
        );

        this.userDocuments.drivingLicense = this.userInfo.typeLicenseDriving;
        this.userDocuments.drivingLicenseCountry = this.userInfo
          .countryLicenseDriving
          ? this.userInfo.countryLicenseDriving
          : null;
        this.userDocuments.drivingLicenseValidityDate = this.userInfo
          .drivingPermissionValidityDate
          ? DateUtil.formatBasicDate(
              this.userInfo.drivingPermissionValidityDate
            )
          : null;
        this.userDocuments.drivingLicenseCarIssueDate = this.userInfo
          .expeditionLicenseB
          ? DateUtil.formatBasicDate(this.userInfo.expeditionLicenseB)
          : null;
        this.userDocuments.drivingLicenseMotorcycleIssueDate = this.userInfo
          .expeditionLicenseA
          ? DateUtil.formatBasicDate(this.userInfo.expeditionLicenseA)
          : null;
        this.userDocuments.licenseHighestRange = this.userInfo.licenseTypeA;
        if (this.userInfo.imageLinks) {
          this.urlNifFront = this.userInfo.imageLinks["imgDniFront"];
          this.urlNifReverse = this.userInfo.imageLinks["imgDniReverse"];
          this.urlDLFront = this.userInfo.imageLinks["imgDrivingLicenseFront"];
          this.urlDLreverse = this.userInfo.imageLinks[
            "imgDrivingLicenseReverse"
          ];
        }
        this.this.$v.$touch();
        this.$emit("validation", {
          valid: !this.$v.$invalid,
        });
      }, 300);
    },
    getNameImage(image) {
      let imageName = null;
      let arr = this.userInfo.validateImg[image]
        ? this.userInfo.validateImg[image].split("/")
        : null;
      imageName = arr ? arr[arr.length - 1] : null;
      if (imageName !== null) {
        return imageName;
      } else {
        imageName = this.userInfo.preValidateImg[image]
          ? this.userInfo.preValidateImg[image]
          : null;
        return imageName;
      }
    },
  },
};
</script>
<style lang="scss">
.checkboxPadding > .v-input__control > .v-input__slot {
  padding-left: 0 !important;
}
.errorTextPhotos {
  color: #b71c1c !important;
  font-size: 0.75rem !important;
}
.custom-placeholer-color input {
  color: var(--custom_color-text) !important;
}
.custom-placeholer-color .v-label {
  color: var(--custom_color-label) !important;
  opacity: 1;
}
</style>
