<template>
  <div
    class="shadow cursor-pointer lg:cursor-default"
    :style="{ backgroundColor: colors.colorBackground }"
  >
    <div
      class="p-2 lg:rounded-t flex justify-between"
      :class="{ rounded: !showResume, 'rounded-t': showResume }"
      :style="{ backgroundColor: colors.colorHeader }"
      @click="showHideResume"
    >
      <div>
        <h1 class="title" :style="{ color: colors.onHeader }">
          {{ $t("issueActions.RES") }}
        </h1>
      </div>
      <div v-if="!isLarge">
        <F2gIcon
          v-if="!showResume"
          class="svg mt-2"
          :value="'arrow-down'"
          :height="16"
          :width="25"
          :strokeWidth="8"
        />
        <F2gIcon
          v-if="showResume"
          class="svg mt-2"
          :value="'arrow-up'"
          :height="16"
          :width="25"
          :strokeWidth="8"
        />
      </div>
    </div>
    <div>
      <div v-if="showResume">
        <ResumeData v-if="showResume" @priceTotal="setTotalPrice" />
      </div>
      <v-btn
        v-if="showResume && currentStep == 1"
        class="ml-4 mb-4 mx-auto border-2 rounded-config30"
        :class="{ 'mb-6': totalPrice }"
        outlined
        :color="colors.colorPrimary"
        @click="showModalEdit = true"
        >{{ $t("common.modify") }}
      </v-btn>
      <div
        v-if="showResume"
        class="
          p-2
          shadow
          rounded-b
          lg:rounded-md
          flex flex-row
          justify-between
          items-center
          lg:bottom-0
          w-full
          left-0
        "
        :style="{ backgroundColor: colors.colorHeader }"
      >
        <v-btn
          v-if="isLarge && currentStep != 4 && currentStep != 1"
          class="border-2 rounded-config30"
          :loading="loading"
          :style="{
            backgroundColor: colors.colorPrimary,
            color: colors.colorOnPrimary,
          }"
          @click="nextStep"
        >
          {{ returnLabelButton() }}
        </v-btn>
        <div
          class="mr-2 text-lg font-bold"
          :style="{ color: colors.colorOnHeaderText }"
        >
          {{ !isLarge ? $t("common.totalPrice") + ":" : "" }}
          {{ this.totalPrice ? this.totalPrice.toFixed(2) : "0" }}€
        </div>
      </div>
    </div>
    <TailDialog
      :backgroundColor="colors.colorBackground"
      :closeButtonBackgroundColor="colors.colorPrimary"
      :closeButtonLogoColor="colors.colorOnPrimary"
      colorCloseBorder="gray"
      :show="showModalEdit"
      @close="showModalEdit = false"
    >
      <template slot="content">
        <MainFilterComponent
          :type="'vertical'"
          @close="showModalEdit = false"
        />
      </template>
    </TailDialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TailDialog from "@/components/tail-dialog";
import MainFilterComponent from "@/modules/v1/Components/main-filter-component.vue";
import ResumeData from "@/modules/v1/Components/resume-data.vue";

export default {
  name: "Resume",
  components: {
    MainFilterComponent,
    ResumeData,
    TailDialog,
  },
  props: {
    currentStep: { required: false, type: Number, default: 1 },
    loading: { required: false, type: Boolean, default: false },
  },
  data() {
    return {
      showResume: window.innerWidth >= 1024 ? true : false,
      isLarge: window.innerWidth >= 1024 ? true : false,
      showModalEdit: false,
      totalPrice: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
      categorySelected: "category/categorySelected",
    }),
    borderColor() {
      return `border-color: ${this.colors.primary}`;
    },
  },
  methods: {
    myEventHandler(e) {
      if (window.innerWidth >= 1024) {
        this.showResume = true;
        this.isLarge = true;
      } else {
        this.showResume = false;
        this.isLarge = false;
      }
    },
    nextStep() {
      if (!this.isLarge) {
        this.showResume = false;
      }
      if (this.categorySelected.id) {
        this.$emit("currentStep", this.currentStep + 1);
      }
    },

    setTotalPrice(event) {
      this.totalPrice = event;
    },

    returnLabelButton() {
      switch (this.currentStep) {
        case 1: {
          return this.$t("button.goToExtras");
        }
        case 2: {
          return this.$t("button.goToData");
        }
        case 3: {
          return this.$t("button.goToConfirm");
        }
        case 4: {
          if (
            this.categorySelected.deferredPayment &&
            this.categorySelected.deferredStartRental &&
            !this.categorySelected.earlyPay &&
            Number(this.categorySelected.deferredPaymentPercentageFirst) === 0
          ) {
            return this.$t("common.continue");
          } else {
            return this.$t("button.goToPay");
          }
        }
        default: {
          return "";
        }
      }
    },
    showHideResume() {
      if (window.innerWidth <= 1000) {
        this.showResume = !this.showResume;
      }
    },
  },
};
</script>
