<template>
  <div
    class="category-details-catalogue overflow-y-scroll"
    :class="{ flex: !fullscreen }"
  >
    <div v-if="fullscreen" class="m-4">
      <div
        class="px-4 pt-6 pb-1 rounded-t-xl shadow"
        :style="{ backgroundColor: colors.colorSurface }"
      >
        <h1 class="mb-2">
          {{ $t("vehicleDetails") }}
        </h1>
      </div>
    </div>
    <div v-if="fullscreen" class="m-4">
      <ReserveFilterCatalogue
        ref="reserveFilterCatalogue"
        v-show="!showCalendarSelector"
        :calendarDates="calendarDates"
        :currentCategory="categoryRow"
        @filter="changeCalendar"
        @changeCategory="
          (value) => {
            $emit('changeCategory', value);
          }
        "
        @openDateCard="openDateCard"
      />
      <CategoryCardCalendar
        v-if="showCalendarSelector"
        :categoryRow="categoryRow"
        class="mb-12"
        :mode="mode"
        fullscreen
        :reserveFilter="reserveFilter"
        @changeDates="changeReserveFilter"
        @close="closeDateCard"
      />
      <!-- <calendar-date-selector
        v-show="showCalendarSelector"
        class="max-h-full w-full z-50"
        :mode="mode"
        :pickUpDate="reserveFilter ? reserveFilter.pickDate : null"
        :returnBackDate="reserveFilter ? reserveFilter.returnDate : null"
        @close="closeDateCard"
        @changeDates="changeDatesRange"
      /> -->
    </div>
    <div class="mx-4 flex flex-row align-center" v-if="fullscreen">
      <div
        class="p-1 rounded-full shadow mr-2 cursor-pointer"
        :style="{ backgroundColor: colors.colorSurface }"
      >
        <F2gIcon
          class="inline-block align-middle"
          :stroke="colors.colorPrimary"
          :value="'arrow-left'"
          :height="28"
          :width="28"
          :strokeWidth="6"
          @click="$emit('close')"
        />
      </div>
      <div
        class="text-sm cursor-pointer"
        :style="{ color: colors.colorPrimary }"
        @click="$emit('close')"
      >
        {{ $t("returnToVehicles") }}
      </div>
    </div>
    <div
      class="lg:w-9/12 mx-4 max-h-full backdrop-blur-xl shadow rounded-lg"
      :class="fullscreen ? 'my-2' : 'my-12'"
      :style="{ backgroundColor: colors.colorSurface }"
    >
      <div
        class="flex align-center justify-center w-full flex-col"
        :style="[
          fullscreen ? { backgroundColor: colors.colorSurfaceMedium } : '',
        ]"
      >
        <div
          class="absolute left-10 mt-8 flex flex-row align-center"
          v-if="!fullscreen"
        >
          <div
            class="p-1 rounded-full shadow mr-2 cursor-pointer"
            :style="{ backgroundColor: colors.colorSurface }"
          >
            <F2gIcon
              class="inline-block align-middle"
              :stroke="colors.colorPrimary"
              :value="'arrow-left'"
              :height="28"
              :width="28"
              :strokeWidth="4"
              @click="$emit('close')"
            />
          </div>
          <div
            class="text-sm cursor-pointer"
            :style="{ color: colors.colorPrimary }"
            @click="$emit('close')"
          >
            {{ $t("returnToVehicles") }}
          </div>
        </div>
        <div
          class="flex flex-row items-center align-center justify-center mx-2 w-4/6"
          :class="{ 'mt-8 relative': !fullscreen, 'h-12 my-2': fullscreen }"
        >
          <h1
            class="text-lg md:text-2xl text-xl font-bold ml-2 inline-block items-center align-center"
            :style="{ color: colors.colorOnSurfaceMedium }"
          >
            {{ categoryRow.name }}
          </h1>
        </div>
      </div>
      <div
        class="flex align-center justify-center w-full flex-col"
        :class="{ 'px-4': !fullscreen }"
      >
        <div
          v-show="showCarousel"
          class="bg-gray-200 max-h-full relative mx-2 lg:w-4/6 w-full"
          :class="{
            'rounded-lg mt-4': !fullscreen,
          }"
        >
          <Carousel
            :borderRadius="fullscreen ? '0px' : '8px'"
            :images="images"
            :showPagination="false"
            :showFooter="false"
            @mounted="mountedCarousel"
          >
          </Carousel>
        </div>
        <div
          v-if="!showCarousel"
          class="bg-gray-200 max-h-full relative mt-4 rounded-xl mx-2 w-4/6"
        >
          <v-img
            :src="categoryRow.img"
            :aspect-ratio="16 / 9"
            class="image rounded-lg"
          />
        </div>
        <div
          class="h-16 w-full rounded-b-xl relative bottom-0"
          v-if="categoryRow.attributeList.length && fullscreen"
        >
          <div
            class="max-h-full w-full px-2 flex flex-row align-center overflow-x-hidden category_services"
            :style="{ backgroundColor: colors.colorSurfaceMedium }"
          >
            <div
              v-for="(attribute, index) in categoryRow.attributeList"
              :key="index"
            >
              <div
                v-if="index < 3"
                class="inline-block my-2 text-sm align-middle mr-2 flex flex-row"
              >
                <div class="pa-1 m-1 flex flex-none align-center">
                  <svg
                    style="height: 24px; width: 24px"
                    :fill="colors.colorPrimary"
                  >
                    <path v-if="attribute.icon.path" :d="attribute.icon.path" />
                  </svg>
                </div>
                <div
                  class="flex align-center inline-block align-middle text-xs mt-1"
                  :style="{ color: colors.colorOnSurface }"
                >
                  {{
                    attribute.supportedLangs[
                      $PageUtils.getLanguageSplittedMinus($i18n.locale)
                    ]
                      ? attribute.supportedLangs[
                          $PageUtils.getLanguageSplittedMinus($i18n.locale)
                        ]
                      : attribute.supportedLangs.en
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <PriceResumeData
          v-if="fullscreen"
          :currentCategory="categoryRow"
          :detailCatalogue="true"
          :loading="loading"
          @selected="
            (value, pack) => {
              priceSelected(value, pack);
            }
          "
        />
        <div
          class="w-full flex flex-row align-center flex-wrap"
          v-if="!fullscreen"
        >
          <div
            class="inline-block mr-2 mt-4"
            v-for="(attribute, index) in categoryRow.attributeList"
            :key="index"
          >
            <div
              class="text-sm align-middle mr-2 inline-block rounded-md flex-none"
              :style="{
                backgroundColor: colors.colorSurfaceMedium,
                color: colors.colorPrimary,
              }"
            >
              <div class="pa-1 m-1 inline-block align-middle">
                <svg
                  style="height: 24px; width: 24px"
                  :fill="colors.colorPrimary"
                >
                  <path v-if="attribute.icon.path" :d="attribute.icon.path" />
                </svg>
              </div>
              <div
                class="align-center inline-block align-middle mr-3"
                :style="{ color: colors.colorOnSurfaceMedium }"
              >
                {{
                  attribute.supportedLangs[$i18n.locale.split("_")[0]]
                    ? attribute.supportedLangs[$i18n.locale.split("_")[0]]
                    : attribute.supportedLangs.en
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-4 px-4">
        <div
          class="rounded-2xl mb-4"
          :style="{
            backgroundColor: colors.colorSurface,
            color: colors.colorOnSurface,
          }"
        >
          <div
            v-if="categoryRow.description"
            class="text-sm overflow-clip overflow-hidden"
            :style="{
              color: colors.colorOnSurface,
            }"
            v-html="categoryRow.description[$i18n.locale.split('_')[0]]"
          ></div>
        </div>
      </div>
    </div>
    <!-- <div class="mx-4 mt-4" v-if="reserveFilter && fullscreen">
      <CategoryCardCalendar
        :categoryRow="categoryRow"
        :reserveFilter="reserveFilter"
        @changeDates="changeReserveFilter"
      />
    </div> -->
    <div
      class="relative top-6 w-3/12 px-0 max-h-full min-h-96"
      v-if="!fullscreen"
    >
      <div class="m-4 rounded-lg">
        <ReserveFilterCatalogue
          ref="reserveFilterCatalogue"
          :calendarDates="calendarDates"
          :currentCategory="categoryRow"
          :reserveFilter="reserveFilter"
          @filter="changeCalendar"
          @changeCategory="
            (value) => {
              $emit('changeCategory', value);
            }
          "
          @changeDates="changeReserveFilter"
        />
        <PriceResumeData
          :currentCategory="categoryRow"
          :detailCatalogue="false"
          :loading="loading"
          @selected="
            (value, pack) => {
              priceSelected(value, pack);
            }
          "
        />
        <!-- <CategoryCardCalendar
          v-if="reserveFilter"
          :categoryRow="categoryRow"
          class="mb-12"
          :reserveFilter="reserveFilter"
          @changeDates="changeReserveFilter"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import Carousel from "@/flit2go-web-core/components/base/carousel";
import PriceResumeData from "@/modules/v2/Components/price-resume-data.vue";
import ReserveFilterCatalogue from "@/modules/catalogue/Components/reserve-filter-catalogue.vue";
import CategoryCardCalendar from "@/modules/catalogue/Components/category-card-calendar.vue";
// import CalendarDateSelector from "@/modules/v2/Components/calendar-date-selector";
import DateUtil from "@/flit2go-web-core/util/dateUtil";

export default {
  name: "CategoryDetailsCatalogue",
  components: {
    Carousel,
    PriceResumeData,
    ReserveFilterCatalogue,
    CategoryCardCalendar,
    // CalendarDateSelector,
  },
  props: {
    categoryRow: { required: true, type: Object, default: () => [] },
    fullscreen: { required: false, type: Boolean, default: true },
    loading: { required: false, type: Boolean, default: false },
  },
  data() {
    return {
      DateUtil,
      showDetails: false,
      carouselPosition: 0,
      showModalEdit: false,
      reserveFilter: null,
      calendarDates: null,
      loadingDates: false,
      mode: "",
      showCalendarSelector: false,
      showCarousel: false,
    };
  },
  computed: {
    ...mapState(["category", "configuration"]),
    ...mapGetters({
      colors: "configuration/getColors",
      gtagCode: "configuration/getGtagCode",
      ownerTimezone: "configuration/getOwnerTimezone",
    }),
    images() {
      return [this.categoryRow.img].concat(this.categoryRow.image);
    },
  },
  methods: {
    changeCalendar(filter) {
      this.reserveFilter = filter;
    },
    mountedCarousel() {
      this.showCarousel = true;
    },
    changeReserveFilter(filter) {
      this.calendarDates = filter;
      if (this.fullscreen) {
        if (this.mode === "pickUp") {
          this.$refs.reserveFilterCatalogue.$refs.pickerInitialDate.openHours();
        } else {
          this.$refs.reserveFilterCatalogue.$refs.pickerFinishDate.openHours();
        }
      }
    },
    priceSelected(value, pack) {
      if (this.gtagCode) {
        this.$gtag.event("item_selection", {
          event_name: "item_selection",
        });
      }
      let categorySelected = Object.assign({}, this.categoryRow);
      categorySelected.earlyPay = value;
      this.$store.commit("category/SET_CATEGORY", categorySelected);
      this.$store.commit("category/SET_PACK_RATE", pack);
      this.$emit("selected", this.reserveFilter);
    },
    openDateCard(mode) {
      this.mode = mode;
      this.showCalendarSelector = true;
    },
    closeDateCard() {
      this.showCalendarSelector = false;
    },
    changeDatesRange(dates) {
      this.reserveFilter.pickDate = this.DateUtil.formatBasicDateNoTimeZone(
        dates.start,
        this.ownerTimezone
      );
      this.reserveFilter.returnDate = this.DateUtil.formatBasicDateNoTimeZone(
        dates.end,
        this.ownerTimezone
      );
      if (this.$refs.reserveFilterCatalogue) {
        this.$refs.reserveFilterCatalogue.$refs.pickerInitialDate.openHours();
      }
    },
  },
};
</script>

<style lang="scss">
.v-dialog--active {
  border-radius: 24px !important;
}
.v-dialog--fullscreen {
  height: 90% !important;
  bottom: 0 !important;
  top: auto !important;
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.errorText {
  color: #b71c1c !important;
  font-size: 1.25rem;
}
</style>
