<template>
  <div class="MainFilter max-h-full">
    <main-filter-component-new
      :class="{
        'pt-10':
          this.$vuetify.breakpoint.name !== 'sm' &&
          this.$vuetify.breakpoint.name !== 'xs',
      }"
      :type="getType"
      :size="`${$route.params.size}`"
    />
  </div>
</template>

<script>
import MainFilterComponentNew from "@/modules/v2/Components/main-filter-component-new";
// import MainFilterComponent from "@/modules/v2/Components/main-filter-component";
import { mapGetters } from "vuex";

export default {
  name: "MainFilterV2",
  components: {
    MainFilterComponentNew,
    // MainFilterComponent,
  },
  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
    }),
    getType() {
      if (
        this.$vuetify.breakpoint.name === "sm" ||
        this.$vuetify.breakpoint.name === "xs"
      ) {
        return "vertical";
      } else {
        return this.$route.params.type;
      }
    },
  },
};
</script>
