<template>
  <div class="direction-data flex-wrap flex-col">
    <span class="flex flex-col lg:flex-row">
      <v-text-field
        v-if="canShowData('directionMandatoryType')"
        class="lg:w-1/2 w-full lg:mr-2 custom-placeholer-color"
        :class="[{ 'mr-0': !canShowData('directionAddInfoMandatoryType') }]"
        v-model="direction.address"
        :outlined="true"
        :style="
          `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
        "
        :color="colors.colorPrimary"
        :backgroundColor="colors.colorSurface"
        :dark="colors.colorBackground === '#000000' ? true : false"
        :error-messages="addressErrors"
        @input="sendData"
      >
        <template #label>
          {{ $t("forms.address.address")
          }}<RedAsterisk v-if="isRequired('directionMandatoryType')" />
        </template>
      </v-text-field>
      <v-text-field
        v-if="canShowData('directionAddInfoMandatoryType')"
        class="lg:w-1/2 w-full custom-placeholer-color"
        v-model="direction.additionalAddressInfo"
        :outlined="true"
        :style="
          `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
        "
        :color="colors.colorPrimary"
        :backgroundColor="colors.colorSurface"
        :dark="colors.colorBackground === '#000000' ? true : false"
        :error-messages="additionalAddressInfoErrors"
        @input="sendData"
      >
        <template #label>
          {{ $t("forms.address.additionalAddressInfo")
          }}<RedAsterisk v-if="isRequired('directionAddInfoMandatoryType')" />
        </template>
      </v-text-field>
    </span>
    <span class="flex flex-col lg:flex-row">
      <v-autocomplete
        v-if="canShowData('addressCountryMandatoryType')"
        class="lg:w-1/4 w-full lg:mr-2 custom-placeholer-color"
        v-model="direction.country"
        :items="$PageUtils.orderArray(nationalities)"
        :item-text="$PageUtils.getNameLower()"
        return-object
        :outlined="true"
        :style="
          `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
        "
        :color="colors.colorPrimary"
        :backgroundColor="colors.colorSurface"
        :dark="colors.colorBackground === '#000000' ? true : false"
        :error-messages="countryErrors"
        :filter="customFilterCountries"
        @input="sendData"
      >
        <template #label>
          {{ $t("forms.address.country")
          }}<RedAsterisk v-if="isRequired('addressCountryMandatoryType')" />
        </template>
      </v-autocomplete>
      <v-text-field
        v-if="canShowData('cityMandatoryType')"
        class="lg:w-1/4 w-full lg:mr-2 custom-placeholer-color"
        v-model="direction.province"
        :outlined="true"
        :style="
          `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
        "
        :color="colors.colorPrimary"
        :backgroundColor="colors.colorSurface"
        :dark="colors.colorBackground === '#000000' ? true : false"
        :error-messages="provinceErrors"
        @input="sendData"
      >
        <template #label>
          {{ $t("forms.address.province")
          }}<RedAsterisk v-if="isRequired('cityMandatoryType')" />
        </template>
      </v-text-field>
      <v-text-field
        v-if="canShowData('localityMandatoryType')"
        class="lg:w-1/4 w-full lg:mr-2 custom-placeholer-color"
        v-model="direction.locality"
        :outlined="true"
        :style="
          `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
        "
        :color="colors.colorPrimary"
        :backgroundColor="colors.colorSurface"
        :dark="colors.colorBackground === '#000000' ? true : false"
        :error-messages="localityErrors"
        @input="sendData"
      >
        <template #label>
          {{ $t("forms.address.locality")
          }}<RedAsterisk v-if="isRequired('localityMandatoryType')" />
        </template>
      </v-text-field>
      <v-text-field
        v-if="canShowData('postalCodeMandatoryType')"
        class="lg:w-1/4 w-full custom-placeholer-color"
        v-model="direction.postalCode"
        :outlined="true"
        :style="
          `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
        "
        :color="colors.colorPrimary"
        :backgroundColor="colors.colorSurface"
        :dark="colors.colorBackground === '#000000' ? true : false"
        :error-messages="postalCodeErrors"
        @input="sendData"
      >
        <template #label>
          {{ $t("forms.address.postalCode")
          }}<RedAsterisk v-if="isRequired('postalCodeMandatoryType')" />
        </template>
      </v-text-field>
    </span>
  </div>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
export default {
  name: "DirectionData",
  components: {
    RedAsterisk: () =>
      import("@/flit2go-web-core/components/fields/f2g-red-asterisk"),
  },
  props: {
    nationalities: Array,
    validate: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    userInfo: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      direction: {
        address: null,
        additionalAddressInfo: null,
        country: null,
        province: null,
        locality: null,
        postalCode: null,
      },
    };
  },
  validations: {
    direction: {
      address: {
        required: requiredIf(function() {
          return this.isRequired("directionMandatoryType");
        }),
      },
      additionalAddressInfo: {
        required: requiredIf(function() {
          return this.isRequired("directionAddInfoMandatoryType");
        }),
      },
      country: {
        required: requiredIf(function() {
          return this.isRequired("addressCountryMandatoryType");
        }),
      },
      province: {
        required: requiredIf(function() {
          return this.isRequired("cityMandatoryType");
        }),
      },
      locality: {
        required: requiredIf(function() {
          return this.isRequired("localityMandatoryType");
        }),
      },
      postalCode: {
        required: requiredIf(function() {
          return this.isRequired("postalCodeMandatoryType");
        }),
      },
    },
  },
  watch: {
    validate() {
      this.$v.$touch();
      this.$emit("validation", {
        valid: !this.$v.$invalid,
      });
    },
  },
  mounted() {
    if (this.userInfo) {
      this.fillUserInfo();
    }
  },
  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
      language: "configuration/getUserLanguage",
      filterInformationUsers: "configuration/getFilterInformationUsers",
    }),
    addressErrors() {
      const errors = [];
      if (this.$v.direction.address.$dirty) {
        !this.$v.direction.address.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    additionalAddressInfoErrors() {
      const errors = [];
      if (this.$v.direction.additionalAddressInfo.$dirty) {
        !this.$v.direction.additionalAddressInfo.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    countryErrors() {
      const errors = [];
      if (this.$v.direction.country.$dirty) {
        !this.$v.direction.country.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    provinceErrors() {
      const errors = [];
      if (this.$v.direction.province.$dirty) {
        !this.$v.direction.province.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    localityErrors() {
      const errors = [];
      if (this.$v.direction.locality.$dirty) {
        !this.$v.direction.locality.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    postalCodeErrors() {
      const errors = [];
      if (this.$v.direction.postalCode.$dirty) {
        !this.$v.direction.postalCode.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
  },
  created() {
    this.sendData();
  },
  methods: {
    sendData() {
      this.$emit("directionData", this.direction);
    },
    isRequired(element) {
      return (
        this.filterInformationUsers[element] ===
        this.$CONSTANTS.USER.MANDATORY.MANDATORY
      );
    },
    isOptional(element) {
      return (
        this.filterInformationUsers[element] ===
        this.$CONSTANTS.USER.MANDATORY.OPTIONAL
      );
    },
    canShowData(element) {
      return (
        this.filterInformationUsers[element] !==
        this.$CONSTANTS.USER.MANDATORY.NOT_APPEAR
      );
    },
    fillUserInfo() {
      this.direction.address = this.userInfo.address.direction;
      this.direction.additionalAddressInfo = this.userInfo.address.directionAdditionalInfo;
      this.direction.province = this.userInfo.address.city;
      this.direction.locality = this.userInfo.address.locality;
      this.direction.postalCode = this.userInfo.address.postalCode;
      if (
        this.userInfo.address &&
        this.userInfo.address.country &&
        typeof this.userInfo.address.country === "object"
      ) {
        const country = this.nationalities.filter(
          (country) => country.code === this.userInfo.address.country.code
        )[0];
        this.direction.country = country;
      } else {
        if (this.userInfo.address && this.userInfo.address.country) {
          const country = this.nationalities.filter(
            (country) =>
              this.$PageUtils.getObjectNameLower(country) ===
              this.userInfo.address.country
          )[0];
          this.direction.country = country;
        } else {
          this.direction.country = null;
        }
      }
    },
    customFilterCountries(item, queryText) {
      return (
        this.$PageUtils
          .removeAccents(item.name[this.$PageUtils.getLanguageSplittedMinus()])
          ?.toLowerCase()
          .indexOf(this.$PageUtils.removeAccents(queryText).toLowerCase()) > -1
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-placeholer-color input {
  color: var(--custom_color-text) !important;
}
.custom-placeholer-color .v-label {
  color: var(--custom_color-label) !important;
  opacity: 1;
}
</style>
