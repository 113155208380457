const RESERVE_TYPE = Object.freeze({
  NO_RESERVE: "FRE",
  CLEAN: "SLI",
  MAINTENANCE: "SMA",
  SCHEDULED: "USC",
  CLEANLINESS: "SLI",
  SUBSCRIPTION: "SUB",
  PICK_UP_OPERATION: "PIO",
  RETURN_OPERATION: "REO",
  PICK_UP: "PIC",
  RETURN: "RET",
});

const RESERVE_STATUS = Object.freeze({
  CANCEL: "CAN",
  CREATED: "CRE",
  TRAVEL: "INT",
  FINISHED: "TFI",
  RENTAL_NOT_STARTED: "RNS",
  PENDING_PAYMENT: "PEN",
});

const SUBSCRIPTION_STATUS = Object.freeze({
  PENDING_ACCEPTATION: "PEA",
  PENDING_PICK_UP: "COP",
  IN_SUBSCRIPTION: "SUS",
  PENDING_RETURN: "PER",
  FINISHED: "SUT",
  CANCELLED: "SUC",
});

const OPERATION_STATUS = Object.freeze({
  CANCEL: "CAN",
  CREATED: "CRE",
  FINISHED: "OPE",
  OPERATION_NOT_STARTED: "ONS",
  OPERATION_IN_PROGRESS: "OIP",
});

const OPERATION_ACTIONS = Object.freeze({
  EDIT: "EDI",
  ASSIGN_EMPLOYEE: "ASS",
  DELETE: "DEL",
});

const RENTAL_STATUS = Object.freeze({
  CANCELLED: "CAN",
  CREATED: "CRE",
  IN_PROGRESS: "RIP",
  NOT_STARTED: "RNS",
  RENTAL_ENDED: "TFI",
});

const PAYMENT_STATUS = Object.freeze({
  UNPAID: "UNP",
  PENDING: "PND",
  PAID: "PAI",
  REFUND: "REF",
});

const TIMETYPES = Object.freeze({
  WEEK: "WEK",
  MONTH: "MON",
});

export default {
  RESERVE_TYPE: RESERVE_TYPE,
  RESERVE_STATUS: RESERVE_STATUS,
  SUBSCRIPTION_STATUS: SUBSCRIPTION_STATUS,
  RENTAL_STATUS: RENTAL_STATUS,
  PAYMENT: PAYMENT_STATUS,
  OPERATION_STATUS: OPERATION_STATUS,
  OPERATION_ACTIONS: OPERATION_ACTIONS,
  TIMETYPES: TIMETYPES,
};
