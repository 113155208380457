export default Object.freeze({
  OWNERS: {
    CODE: "OWN",
    DASHBOARD: "OWN_DSH",
    OWNERS: "OWN_OWN",
    BRANCHES: "OWN_BRA",
    PARKINGS: "OWN_PAR",
    PRICING: "OWN_PRI",
    ZONES: "OWN_ZON",
    CORPORATE_NETWORKS: "OWN_NET",
    PROMOTIONS: "OWN_PRO",
    SUPPORT_PHONES: "OWN_PHO",
    PAYMENTS: "OWN_PAY",
    BILLING: "OWN_BIL",
    REPORTS: "OWN_REP",
    AGENCIES: "OWN_AGE",
  },
  VEHICLES: {
    CODE: "VEH",
    DASHBOARD: "VEH_DSH",
    MAP: "VEH_MAP",
    VEHICLES: "VEH_VEH",
    DEVICES: "VEH_DEV",
    RENTALS: "VEH_REN",
    BOOKINGS: "VEH_BKN",
    BRANDS: "VEH_BRA",
    MODELS: "VEH_MOD",
    CATEGORIES: "VEH_CAT",
    SUBSCRIPTIONS: "VEH_SUB",
    BUDGETS: "VEH_BUD",
    SEASONS: "VEH_SEA",
    GROUP_SEATS: "VEH_GSE",
  },
  CORPORATE_NETWORK: {
    CODE: "NET",
  },
  CATEGORY: {
    CODE: "CAT",
  },
  PEOPLE: {
    CODE: "PEO",
    DASHBOARD: "PEO_DSH",
    EMPLOYEES: "PEO_EMP",
    OWNER_EMPLOYEES: "PEO_OEM",
    USERS: "PEO_USR",
    COMMUNICATIONS: "PEO_COM",
    VALIDATION_CODES: "PEO_VAC",
    DNR: "PEO_DNR",
    COMPANIES: "PEO_CMP",
  },
  DEALER: {
    CODE: "DEA",
    DASHBOARD: "DEA_DSH",
    DEALER: "DEA_DEA",
    VEHICLES: "DEA_VEH",
    DEVICES: "DEA_DEV",
    MODELS: "DEA_MOD",
    CUSTOMERS: "DEA_CUS",
    SUBSCRIPTIONS: "DEA_SUB",
    BILLING: "DEA_BIL",
  },
  CONTROL: {
    CODE: "CON",
    MAP: "CON_MAP",
    GEOFENCE: "CON_GEO",
  },
  SUPPORT: {
    CODE: "SUP",
  },
  SERVICE: {
    CODE: "SER",
  },
  ADMINISTRATION: {
    CODE: "ADM",
    DASHBOARD: "ADM_DSH",
    DAMAGES: "ADM_DMG",
    PENALIZATIONS: "ADM_PEN",
    RESERVE_REDUCTIONS: "ADM_RER",
    USERS: "ADM_USR",
    INCIDENCES: "ADM_INC",
    INTERNAL_APPS: "ADM_APP",
  },
  CONFIGURATION: {
    CODE: "CNF",
    ROLES_PERMISSIONS: "CNF_ROL",
    INTEGRATIONS: "CNF_INT",
    MASTER_TABLES: "CNF_MTA",
    FAQS: "CNF_FAQ",
    LEGAL: "CNF_LEG",
    DEVELOPERS: "CNF_DEV",
    EMAIL: "CNF_EMA",
    TAGS: "CNF_TAG",
    BUDGETS: "CNF_BUD",
    BILLING: "CNF_BIL",
    CHAT: "CNF_CHA",
    DOCUMENTATION: "CNF_DOC",
  },
  SEASON: {
    CODE: "SEA",
  },
});
