export default Object.freeze({
  PERCENTAGE: "%",
  EURO: "€",
  KILOMETRE: "km",
  KILOMETRES: "kms",
  LITER: "l",
  METER: "m",
  DISPLACEMENT: "cc",
  KILOWATT: "kW",
  WATT: "W",
  HORSE_POWER: "cv",
  KILOMETRE_HOUR: "km/h",
});
