import conn from "@/flit2go-web-core/services/connection";

export default {
  getPromotionsByUserId(params) {
    return conn.$axios({
      url: `secured/v1/user/${params.userId}/promotion/web/search`,
      method: "GET",
      params: params.params,
    });
  },
  getPromotions(params) {
    return conn.$axios({
      url: `/secured/v1/promotion/search`,
      method: "GET",
      params: params,
    });
  },
  assignPromotion(params) {
    return conn.$axios({
      url: `/secured/v1/user/${params.idUser}/promotion/${params.idPromotion}/active`,
      method: "POST",
    });
  },
};
