var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lateral-panel my-0 flex",class:{
    'lateral-panel--shown': _vm.show,
    'lateral-panel--hidded': !_vm.show,
    'rounded-l-lg':
      _vm.$vuetify.breakpoint.name !== 'xs' && _vm.$vuetify.breakpoint.name !== 'sm',
  },style:(`background-color: ${_vm.colors.colorSurface}`)},[_c('div',{staticClass:"lateral-panel__content mx-auto md:p-8",class:{
      'lateral-panel__content--shown': _vm.showContent,
      'lateral-panel__content--hidded': !_vm.showContent,
    }},[_vm._t("content")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }