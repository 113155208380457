<template>
  <div class="reserve-filter-vertical-new">
    <div class="flex-col flex w-full pb-3">
      <div class="flex-col flex w-full">
        <div
          v-if="!showDirectionPickUpField"
          class="mr-2 w-full mb-2"
          :class="[isPickUpDisabled ? '' : 'cursor-pointer']"
        >
          <div class="w-full max-h-full">
            <v-skeleton-loader
              v-if="loadingBranches"
              type="heading"
              min-width="750px"
            />
            <v-text-field
              v-else
              id="pickUpLocation"
              ref="pickUpLocation"
              single-line
              filled
              :dense="true"
              :disabled="isPickUpDisabled"
              :autocomplete="'off'"
              hide-no-data
              :append-icon="''"
              flat
              hide-details
              return-object
              class="rounded input-size"
              type="text"
              v-model="branchPickName"
              :items="branches"
              :color="colors.colorPrimary"
              :background-color="colors.colorSurfaceMedium"
              item-text="name"
              @change="changeSelected('pickUp')"
              @input="searchBranchesFiltered"
              @focus="pickLocationFocused"
              @click="openLocationCard('pick')"
            >
              <template #selection="{ item }">
                <span
                  :style="`color:${colors.colorOnSurfaceMedium} !important`"
                  >{{ item.name }}</span
                >
              </template>
              <template #label>
                <span
                  :style="`color:${colors.colorOnSurfaceMedium} !important`"
                  >{{ $t("placeOfDelivery") }}</span
                >
              </template>
              <template slot="prepend-inner">
                <F2gIcon
                  :style="`cursor: pointer, color: ${colors.colorPrimary}`"
                  :fill="colors.colorPrimary"
                  class="cursor-pointer"
                  value="location"
                  solid
                  file="icons_solid.svg"
                  :strokeWidth="3"
                />
              </template>
            </v-text-field>
          </div>
          <div class="speech-bubble" v-if="showPickUpCard"></div>
          <v-card
            v-if="showPickUpCard"
            elevation="3"
            class="absolute z-10 min-w-64 w-auto flex flex-row cursor-default"
            :style="{
              border: '0px',
              height: '300px',
            }"
            v-on-clickaway="closeDetailCard"
          >
            <branch-card-detail-horizontal
              class="w-full max-h-full"
              :branches="branchesFiltered"
              :type="'pickUp'"
              :size="size"
              :style="{
                border: '0px',
              }"
              :branchToShow="filter.pickLocation"
              @branchSelected="changePickUpLocation"
              @close="closeDetailCard"
            />
          </v-card>
        </div>

        <!-- CARMOVE HECHO A MEDIDA -->
        <div v-if="showDirectionPickUpField" class="mr-2 w-full mb-2">
          <v-layout column style="position: relative">
            <v-text-field
              ref="configurablePickUpAddressTextField"
              id="configurablePickUpAddressTextField"
              single-line
              filled
              :dense="true"
              :autocomplete="'off'"
              menu-props="{ display: none }"
              :append-icon="''"
              flat
              hide-details
              clearable
              return-object
              class="rounded input-size"
              type="text"
              v-model="configurablePickUpAddress"
              v-on:input="debounceInput('configurablePickUp')"
              :backgroundColor="colors.colorSurfaceMedium"
              :color="colors.colorOnSurfaceMedium"
              @click:clear="clearPickUpAddress"
              @change="
                (direction) => {
                  configurablePickUpAddress = direction;
                }
              "
              ><template #label>
                <span
                  :style="`color:${colors.colorOnSurfaceMedium} !important`"
                  >{{ $t("pickUpAddress") }}</span
                >
              </template></v-text-field
            >
            <div
              class="results"
              v-if="
                filters.googlePlacesApiKey && directionResultsPickUp.length > 0
              "
              :style="
                `color:${colors.colorSurfaceMedium} !important;transition: margin-top 0.2s ease-in`
              "
            >
              <v-layout column v-on-clickaway="closeDirectionResultsPickUp">
                <span
                  v-for="(direction, index) in directionResultsPickUp"
                  :key="index"
                  class="results__item ml-2 mr-2"
                  :style="`color:${colors.colorOnSurfaceMedium} !important`"
                  @click="ubicationSelected(direction, 'pickUp')"
                >
                  <F2gIcon
                    class="results__item-icon mr-4"
                    value="ubication"
                    :stroke="colors.colorPrimary"
                    :strokeWidth="5"
                  />
                  {{
                    direction.hasOwnProperty("description")
                      ? direction.description
                      : direction.formatted_address
                  }}
                </span>
              </v-layout>
            </div>
          </v-layout>
        </div>

        <div
          v-if="filter.otherBranch && !showDirectionReturnBackField"
          class="mr-2 w-full mb-2"
          :class="isReturnDisabled ? '' : 'cursor-pointer'"
        >
          <div class="w-full max-h-full">
            <v-skeleton-loader
              v-if="loadingBranches"
              type="heading"
              min-width="750px"
            />
            <v-text-field
              v-else
              id="returnLocation"
              ref="returnLocation"
              single-line
              filled
              :dense="true"
              :disabled="isReturnDisabled"
              :autocomplete="'off'"
              menu-props="{ display: none }"
              :append-icon="''"
              flat
              hide-details
              return-object
              class="rounded input-size"
              type="text"
              v-model="branchReturnName"
              :items="branches"
              :label="$t('common.returnLocation')"
              :color="colors.colorPrimary"
              :background-color="colors.colorSurfaceMedium"
              item-text="name"
              @change="changeSelected('return')"
              @input="searchBranchesFiltered"
              @click="openLocationCard('return')"
            >
              <template #selection="{ item }">
                <span
                  :style="`color:${colors.colorOnSurfaceMedium} !important`"
                  >{{ item.name }}</span
                >
              </template>
              <template #label>
                <span
                  :style="`color:${colors.colorOnSurfaceMedium} !important`"
                  >{{ $t("common.returnLocation") }}</span
                >
              </template>
              <template slot="prepend-inner">
                <F2gIcon
                  :fill="colors.colorPrimary"
                  :style="`cursor: pointer, color: ${colors.colorPrimary}`"
                  class="cursor-pointer"
                  value="location"
                  solid
                  file="icons_solid.svg"
                  :strokeWidth="3"
                />
              </template>
            </v-text-field>
          </div>
          <div class="speech-bubble" v-if="showReturnBackCard"></div>
          <v-card
            v-if="showReturnBackCard"
            elevation="3"
            class="absolute z-10 min-w-64 w-auto flex flex-row cursor-default"
            :style="{
              border: '0px',
              height: '300px',
            }"
            v-on-clickaway="closeDetailCard"
          >
            <branch-card-detail-horizontal
              class="w-full max-h-full"
              :branches="branchesFiltered"
              :type="'returnBack'"
              :size="size"
              :style="{
                border: '0px',
              }"
              :branchToShow="filter.returnLocation"
              @branchSelected="changeReturnBackLocation"
              @close="closeDetailCard"
            />
          </v-card>
        </div>

        <!-- CARMOVE HECHO A MEDIDA -->
        <div v-if="showDirectionReturnBackField" class="mr-2 w-full mb-2">
          <v-layout column style="position: relative">
            <v-text-field
              ref="configurableReturnBackAddressTextField"
              id="configurableReturnBackAddressTextField"
              single-line
              filled
              :dense="true"
              :autocomplete="'off'"
              menu-props="{ display: none }"
              :append-icon="''"
              flat
              clearable
              hide-details
              return-object
              class="rounded input-size"
              type="text"
              v-model="configurableReturnBackAddress"
              v-on:input="debounceInput('configurableReturnBack')"
              :backgroundColor="colors.colorSurfaceMedium"
              :color="colors.colorOnSurfaceMedium"
              @click:clear="clearReturnBackAddress"
              @change="
                (direction) => {
                  configurableReturnBackAddress = direction;
                }
              "
              ><template #label>
                <span
                  :style="`color:${colors.colorOnSurfaceMedium} !important`"
                  >{{ $t("deliveryAddress") }}</span
                >
              </template></v-text-field
            >
            <div
              class="results"
              v-if="
                filters.googlePlacesApiKey &&
                  directionResultsReturnBack.length > 0
              "
              :style="
                `color:${colors.colorSurfaceMedium} !important;transition: margin-top 0.2s ease-in`
              "
            >
              <v-layout column v-on-clickaway="closeDirectionResultsReturnBack">
                <span
                  v-for="(direction, index) in directionResultsReturnBack"
                  :key="index"
                  class="results__item ml-2 mr-2"
                  :style="`color:${colors.colorOnSurfaceMedium} !important`"
                  @click="ubicationSelected(direction, 'returnBack')"
                >
                  <F2gIcon
                    class="results__item-icon mr-4"
                    value="ubication"
                    :stroke="colors.colorPrimary"
                    :strokeWidth="5"
                  />
                  {{
                    direction.hasOwnProperty("description")
                      ? direction.description
                      : direction.formatted_address
                  }}
                </span>
              </v-layout>
            </div>
          </v-layout>
        </div>
      </div>
      <div class="w-full flex flex-col">
        <div :class="'mr-2 w-full mb-2'">
          <DateHourPickerInline
            ref="pickerInitialDate"
            :labelTime="$t('common.pickUpTime')"
            :labelDate="$t('common.pickUpDate')"
            :dateSelected="filter.pickDate"
            :timeSelected="filter.pickHour"
            :minDate="pickUpMinDate"
            :minHour="pickUpMinTime"
            :availableHours="filters.hours"
            @time="setPickUpTime"
            @date="setPickUpDate"
            @openDateCard="openDateCard('pickUp')"
          />
          <div class="speech-bubble" v-if="showPickUpCalendar"></div>
          <v-card
            v-if="showPickUpCalendar"
            elevation="3"
            class="absolute z-10 p-2 flex flex-row cursor-default"
            :style="{
              border: '0px',
            }"
            v-on-clickaway="closeDetailCalendar"
          >
            <calendar-date-selector
              v-show="showPickUpCalendar"
              class="max-h-full"
              :type="'vertical'"
              :mode="'pickUp'"
              :pickUpDate="bookingFilters.pickDate"
              :returnBackDate="bookingFilters.returnDate"
              @changeDates="changeDatesRange"
            />
          </v-card>
        </div>
        <div :class="'mr-2 w-full'">
          <DateHourPickerInline
            ref="pickerFinishDate"
            :labelTime="$t('common.returnBackTime')"
            :labelDate="$t('common.returnBackDate')"
            :dateSelected="filter.returnDate"
            :timeSelected="filter.returnHour"
            :minDate="returnBackMinDate"
            :minHour="returnBackMinTime"
            :availableHours="filters.hours"
            @time="setReturnBackTime"
            @date="setReturnBackDate"
            @openDateCard="openDateCard('returnBack')"
          />
          <div class="speech-bubble" v-if="showReturnBackCalendar"></div>
          <v-card
            v-if="showReturnBackCalendar"
            elevation="3"
            class="absolute right-36 z-10 p-2 flex flex-row cursor-default"
            :style="{
              border: '0px',
            }"
            v-on-clickaway="closeDetailCalendar"
          >
            <calendar-date-selector
              v-show="showReturnBackCalendar"
              :mode="'returnBack'"
              class="max-h-full"
              :type="'vertical'"
              :pickUpDate="bookingFilters.pickDate"
              :returnBackDate="bookingFilters.returnDate"
              @changeDates="changeDatesRange"
            />
          </v-card>
        </div>
      </div>
    </div>

    <div class="max-h-full flex flex-col">
      <div class="flex" :class="'mb-2 mt-2 flex-row align-center'">
        <div
          style="display: flex"
          :class="'align-center mr-4'"
          v-if="filters.allowReturnInAnotherBranch"
        >
          <div :class="'text-sm'" :style="{ color: colors.colorOnHeader }">
            {{ $t("returnToDifferentPlace") }}
          </div>
          <v-switch
            class="mt-0 ml-2 max-h-full flex align-center"
            :color="colors.colorPrimary"
            v-model="filter.otherBranch"
            inset
            :dense="true"
            hide-details
            @change="checkPickLocation"
          >
          </v-switch>
        </div>
        <vehicle-filter
          v-if="filters.showVehicleFilter"
          :type="type"
          :vehiclesFilter="vehicles"
          :vehicleTypesSelected="
            bookingFilters && bookingFilters.vehicleTypes
              ? bookingFilters.vehicleTypes.toString()
              : null
          "
          @vehiclesSelected="eventVehiclesSelected"
        />
      </div>
      <div
        class="my-2 text-normal text-red-700 w-full flex flex-row bg-red-100 p-1 rounded"
        v-if="error || (errorSend && type === 'horizontal')"
      >
        <F2gIcon
          class="mr-2 flex align-center"
          :value="'info'"
          :fill="'#B91C1C'"
          file="icons_solid.svg"
          solid
          :height="18"
          :width="18"
          :strokeWidth="4"
        />
        <span class="w-full">
          {{
            errorSend == "branch"
              ? $t("errors.getBranches")
              : errorSend == "category"
              ? errorCategory
              : errorSend
              ? errorSend
              : error
          }}
        </span>
      </div>
    </div>
    <div id="fake-div"></div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import DateUtil from "@/flit2go-web-core/util/dateUtil";
import { directive as onClickaway } from "vue-clickaway";
import { EventBus } from "@/flit2go-web-core/util/event-bus.js";
import DateHourPickerInline from "@/modules/v2/Components/date-hour-picker-inline";
import VehicleFilter from "@/modules/v2/Components/vehicle-filter";
import BranchCardDetailHorizontal from "@/modules/v2/Components/branch-card-detail-horizontal";
import CalendarDateSelector from "@/modules/v2/Components/calendar-date-selector";

export default {
  name: "ReserveFilterVerticalNew",
  directives: {
    onClickaway,
  },
  components: {
    DateHourPickerInline,
    VehicleFilter,
    BranchCardDetailHorizontal,
    CalendarDateSelector,
  },
  props: {
    branches: { required: true, type: Array },
    branchSelectedPickUp: { required: false, type: Object, default: () => {} },
    branchSelectedReturn: { required: false, type: Object, default: () => {} },
    bookingFilters: { required: false, type: Object, default: null },
    type: { required: false, type: String, default: "vertical" },
    size: { required: false, type: String, default: "full" },
    errorSend: { required: false, type: String, default: null },
    loading: { required: false, type: Boolean, default: false },
    loadingBranches: { required: false, type: Boolean, default: false },
    isSuperowner: { required: false, type: Boolean, default: false },
  },
  data: () => ({
    DateUtil,
    filter: {
      pickDate: null,
      returnDate: null,
      pickHour: null,
      returnHour: null,
      pickLocation: {},
      returnLocation: {},
      otherBranch: true,
    },
    error: null,
    returnBackMinDate: DateUtil.currentDateBasicFormat(),
    returnBackMinTime: "",
    pickUpMinDate: DateUtil.currentDateBasicFormat(),
    pickUpMinTime: "",
    showPickUpCard: false,
    showReturnBackCard: false,
    showPickUpCalendar: false,
    showReturnBackCalendar: false,
    query: null,
    branchPickName: "",
    branchReturnName: "",
    showDirectionPickUpField: false,
    showDirectionReturnBackField: false,
    configurablePickUpAddress: "",
    configurableReturnBackAddress: "",
    directionResultsReturnBack: [],
    directionResultsPickUp: [],
    geocoder: null,
    autocompleteService: null,
    placesService: null,
  }),
  validations() {
    return {
      filter: {
        pickLocation: { required },
        returnLocation: { required },
      },
      configurablePickUpAddress: {
        required: requiredIf(() => this.showDirectionPickUpField),
      },
      configurableReturnBackAddress: {
        required: requiredIf(() => this.showDirectionReturnBackField),
      },
    };
  },
  watch: {
    "filter.pickDate"() {
      if (this.filter.pickDate === DateUtil.currentDateBasicFormat()) {
        this.pickUpMinTime = DateUtil.currentTimeHour();
      } else {
        this.pickUpMinTime = "";
      }
      if (this.filter.pickDate) {
        this.returnBackMinDate = this.filter.pickDate;
      }
    },
    "filter.returnDate"() {
      if (this.filter.returnDate === DateUtil.currentDateBasicFormat()) {
        this.returnBackMinTime = DateUtil.currentTimeHour();
      } else {
        this.returnBackMinTime = "";
      }
      if (this.filter.pickDate === this.filter.returnDate) {
        this.returnBackMinTime = this.filter.pickHour;
      }
    },
    "filter.pickLocation"() {
      this.branchPickName = this.filter.pickLocation.name;
    },
    "filter.returnLocation"() {
      this.branchReturnName = this.filter.returnLocation.name;
    },
    "filters.allowReturnInAnotherBranch"() {
      this.filter.otherBranch = this.filters.allowReturnInAnotherBranch;
      this.checkPickLocation();
    },
    branches() {
      if (this.branches.length === 1) {
        this.filter.pickLocation = this.branches[0];
        this.filter.returnLocation = this.branches[0];
      }
    },
    bookingFilters() {
      this.setDefault();
    },
  },
  async mounted() {
    this.setDefault();
    if (this.filters.googlePlacesApiKey) {
      // eslint-disable-next-line
      if (typeof google === "undefined") {
        await this.importGoogleLibrary();
      }
      if (null === this.geocoder) {
        // eslint-disable-next-line
        this.geocoder = new google.maps.Geocoder();
      }
      if (null === this.autocompleteService) {
        this.autocompleteService =
          // eslint-disable-next-line
          await new google.maps.places.AutocompleteService();
      }
      if (null === this.placesService) {
        // eslint-disable-next-line
        this.placesService = new google.maps.places.PlacesService(
          document.getElementById("fake-div")
        );
      }
    }
  },
  computed: {
    ...mapState(["configuration"]),
    ...mapGetters({
      colors: "configuration/getColors",
      contact: "configuration/getContact",
      filters: "configuration/getFilters",
      vehicles: "configuration/getVehiclesFilter",
      ownerTimezone: "configuration/getOwnerTimezone",
    }),
    branchesFiltered() {
      if (this.query) {
        let listBranches = [];
        this.branches.forEach((item) => {
          if (
            item.name &&
            item.name.toLowerCase().includes(this.query.toLowerCase())
          ) {
            listBranches.push(item);
          }
        });
        return listBranches;
      } else {
        return this.branches;
      }
    },
    isReturnDisabled() {
      return (
        (this.branches.length <= 1 && !this.isSuperowner) ||
        !this.filters.allowReturnInAnotherBranch ||
        !this.filter.otherBranch
      );
    },
    isPickUpDisabled() {
      return this.branches.length <= 1 && !this.isSuperowner;
    },

    pickUpErrors() {
      let errorToShow = false;
      if (!this.filter.pickDate || !this.filter.pickHour) {
        errorToShow = this.$t("errors.completeAllFields");
      }
      if (this.filter.pickDate && this.filter.returnDate) {
        let pickDateTimestamp = new Date(this.filter.pickDate);
        let returnDateTimestamp = new Date(this.filter.returnDate);
        if (pickDateTimestamp > returnDateTimestamp)
          errorToShow = this.$t("errors.dateLess");
      }
      if (
        this.filter.pickDate &&
        this.filter.returnDate &&
        this.filter.pickHour &&
        this.filter.returnHour
      ) {
        let pickDateTimeTimestamp = new Date(
          this.filter.pickDate + "T" + this.filter.pickHour
        );
        if (this.filter.pickDate === this.filter.returnDate) {
          let returnDateTimestamp = new Date(
            this.filter.returnDate + "T" + this.filter.returnHour
          );
          if (
            pickDateTimeTimestamp.getTime() >= returnDateTimestamp.getTime()
          ) {
            errorToShow = this.$t("errors.hourLess");
          }
        }
        if (pickDateTimeTimestamp < Date.now() - 3600000) {
          errorToShow = this.$t("errors.hourMoreActual");
        }
      }
      return errorToShow;
    },

    returnBackErrors() {
      let errorToShow = false;
      if (!this.filter.returnDate || !this.filter.returnHour) {
        errorToShow = this.$t("errors.completeAllFields");
      }
      if (this.filter.pickDate && this.filter.returnDate) {
        let pickDateTimestamp = new Date(this.filter.pickDate);
        let returnDateTimestamp = new Date(this.filter.returnDate);
        if (pickDateTimestamp > returnDateTimestamp)
          errorToShow = this.$t("errors.dateMore");
      }
      if (
        this.filter.pickDate &&
        this.filter.returnDate &&
        this.filter.pickHour &&
        this.filter.returnHour
      ) {
        if (this.filter.pickDate === this.filter.returnDate) {
          let pickDateTimeTimestamp = new Date(
            this.filter.pickDate + " " + this.filter.pickHour
          );
          let returnDateTimestamp = new Date(
            this.filter.returnDate + " " + this.filter.returnHour
          );
          if (
            pickDateTimeTimestamp.getTime() >= returnDateTimestamp.getTime()
          ) {
            errorToShow = this.$t("errors.hourMore");
          }
        }
      }
      return errorToShow;
    },
    errorCategory() {
      let text;
      if (this.contact.phoneNumber && this.contact.email) {
        text =
          this.$t("common.noVehiclesFoundDate") +
          ", " +
          this.$t("mobile.booking.nveText").toLowerCase() +
          this.contact.phoneNumber +
          "/" +
          this.contact.email;
      } else if (this.contact.phoneNumber) {
        text =
          this.$t("common.noVehiclesFoundDate") +
          ", " +
          this.$t("mobile.booking.nveText").toLowerCase() +
          this.contact.phoneNumber;
      } else if (this.contact.email) {
        text =
          this.$t("common.noVehiclesFoundDate") +
          ", " +
          this.$t("mobile.booking.nveText").toLowerCase() +
          this.contact.email;
      } else {
        text = this.$t("noVehiclesFoundDateTryOtherBranch");
      }
      return text;
    },
  },
  methods: {
    // eslint-disable-next-line
    debounceInput: _.debounce(async function(type) {
      const directions =
        type === "configurablePickUp"
          ? "directionResultsPickUp"
          : "directionResultsReturnBack";
      if (this.filters.googlePlacesApiKey) {
        const results = await this.getDirections(`${type}Address`);
        if (results) {
          this[directions] = results.slice(0, 3);
        } else {
          this.$showMessage.error(this.$t("errors.search"));
        }
      }
    }, 500),
    async getDirections(type) {
      let defaultBounds = null;
      if (
        this.filters.addressCenterLatitude &&
        this.filters.addressCenterLongitude &&
        this.filters.addressCenterRadius
      ) {
        let radius = this.filters.addressCenterRadius;
        const center = {
          lat: this.filters.addressCenterLatitude,
          lng: this.filters.addressCenterLongitude,
        };
        // Convertir el radio de kilómetros a grados
        let latitudinalRadius = radius / 111.0;
        let longitudinalRadius =
          radius / (111.0 * Math.cos((center.lat * Math.PI) / 180.0));
        defaultBounds = {
          north: center.lat + latitudinalRadius,
          south: center.lat - latitudinalRadius,
          east: center.lng + longitudinalRadius,
          west: center.lng - longitudinalRadius,
        };
      }
      return new Promise((resolve) => {
        const request = {
          input: this[type],
          types: ["geocode"],
          locationRestriction: defaultBounds,
        };
        if (this.autocompleteService) {
          this.autocompleteService.getPlacePredictions(
            request,
            (results, status) => {
              // eslint-disable-next-line
              if (status === google.maps.places.PlacesServiceStatus.OK) {
                resolve(results);
              } else {
                resolve(null);
              }
            }
          );
        }
      });
    },
    importGoogleLibrary() {
      return new Promise((resolve) => {
        let placesScript = document.createElement("script");
        placesScript.type = "text/javascript";
        placesScript.async = false;
        placesScript.setAttribute(
          "src",
          `https://maps.googleapis.com/maps/api/js?key=${this.filters.googlePlacesApiKey}&libraries=geometry,places`
        );
        placesScript.onload = function() {
          resolve();
        };
        document.getElementsByTagName("head")[0].appendChild(placesScript);
      });
    },
    closeDirectionResultsPickUp() {
      this.directionResultsPickUp = [];
      this.configurablePickUpAddress = "";
      this.debounceInput("configurablePickUp");
    },
    closeDirectionResultsReturnBack() {
      this.directionResultsReturnBack = [];
      this.configurableReturnBackAddress = "";
      this.debounceInput("configurableReturnBack");
    },
    closeDetailCard() {
      this.showPickUpCard = false;
      this.showReturnBackCard = false;
      this.query = "";
    },
    closeDetailCalendar() {
      this.showPickUpCalendar = false;
      this.showReturnBackCalendar = false;
    },
    searchBranchesFiltered(queryText) {
      this.query = queryText != null ? queryText : "";
    },
    setDefault() {
      if (this.branches.length === 1) {
        this.filter.pickLocation = this.branches[0];
        this.filter.returnLocation = this.branches[0];
      }
      if (this.bookingFilters.pickUpBranch) {
        this.filter.pickLocation = this.bookingFilters.pickUpBranch;
      }
      if (this.bookingFilters.returnBackBranch) {
        this.filter.returnLocation = this.bookingFilters.returnBackBranch;
      }
      this.filter.pickDate = this.bookingFilters.pickDate;
      this.filter.returnDate = this.bookingFilters.returnDate;
      this.filter.pickHour = this.bookingFilters.pickHour;
      this.filter.returnHour = this.bookingFilters.returnHour;
      this.filter.otherBranch = this.configuration.filters.allowReturnInAnotherBranch;
    },
    sendFilters() {
      this.$emit("filters", this.filter);
    },
    changeSelected(type) {
      if (type === "pickUp") {
        if (
          !this.filter.otherBranch ||
          !this.filters.allowReturnInAnotherBranch
        ) {
          this.filter.returnLocation = Object.assign(
            {},
            this.filter.pickLocation
          );
          this.$emit("changeSelected", this.filter.returnLocation, "return");
        }

        this.$emit("changeSelected", this.filter.pickLocation, type);
      } else {
        this.$emit("changeSelected", this.filter.returnLocation, type);
      }
    },
    eventCheckAvailability() {
      this.$emit("send");
    },
    validate() {
      this.error = null;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.error = this.$t("errors.completeAllFields");
      } else {
        let pickUpError = this.pickUpErrors;
        let returnBackError = this.returnBackErrors;
        if (pickUpError) {
          this.error = pickUpError;
        }
        if (returnBackError) {
          this.error = returnBackError;
        }
        EventBus.$emit("changeDates");
      }
      this.$emit("validation", {
        valid: this.error ? false : true,
        filter: this.filter,
      });
    },
    setPickUpDate(date) {
      if (date) {
        this.filter.pickDate = date;
        this.$emit("filters", this.filter);
      }
    },
    setPickUpTime(time) {
      this.filter.pickHour = time;
      this.$emit("filters", this.filter);
      // this.$refs.pickerFinishDate.openDate();
    },
    setReturnBackDate(date) {
      if (date) {
        this.filter.returnDate = date;
        this.$emit("filters", this.filter);
      }
    },
    setReturnBackTime(time) {
      this.filter.returnHour = time;
      this.$emit("filters", this.filter);
    },
    pickLocationFocused() {
      this.$nextTick(() => {
        this.checkPickLocation();
      });
    },
    checkPickLocation() {
      if (
        this.filter.pickLocation &&
        (!this.filter.otherBranch || !this.filters.allowReturnInAnotherBranch)
      ) {
        this.filter.returnLocation = this.filter.pickLocation;
        this.branchReturnName = this.filter.returnLocation.name;
      }
      this.sendFilters();
    },
    changePickUpLocation(branch) {
      this.filter.pickLocation = branch;
      this.branchPickName = this.filter.pickLocation.name;
      this.showPickUpCard = false;
      this.showDirectionPickUpField = false;
      this.changeSelected("pickUp");
      //CARMOVE HECHO A MEDIDA
      if (
        this.filter.pickLocation.owner.id === 62 &&
        this.filter.pickLocation.id === 91
      ) {
        this.showDirectionPickUpField = true;
        setTimeout(() => {
          document.getElementById("configurablePickUpAddressTextField").focus();
        }, 200);
      } else {
        this.showDirectionPickUpField = false;
        if (this.filter.otherBranch) {
          // document.getElementById("returnLocation").click();
        } else {
          this.$refs.pickerInitialDate.openDateCard();
        }
      }

      this.checkPickLocation();
    },
    changeReturnBackLocation(branch) {
      this.filter.returnLocation = branch;
      this.branchReturnName = this.filter.returnLocation.name;
      this.showReturnBackCard = false;
      this.showDirectionReturnBackField = false;
      this.changeSelected("return");
      //CARMOVE HECHO A MEDIDA
      if (
        this.filter.returnLocation.owner.id === 62 &&
        this.filter.returnLocation.id === 91
      ) {
        this.showDirectionReturnBackField = true;
        setTimeout(() => {
          document
            .getElementById("configurableReturnBackAddressTextField")
            .focus();
        }, 200);
      } else {
        this.$refs.pickerInitialDate.openDateCard();
      }
    },
    openDateCard(mode) {
      this.$emit("openDateCard", mode);
    },
    openDateCalendar() {
      this.showPickUpCalendar = true;
    },
    openDateReturnCalendar() {
      this.showReturnBackCalendar = true;
    },
    eventVehiclesSelected(vehiclesSelected) {
      this.$emit("vehiclesSelected", vehiclesSelected);
    },
    eventAgeSelected(ageSelected) {
      this.$emit("ageSelected", ageSelected);
    },
    openLocationCard(type) {
      if (type === "pick" && !this.isPickUpDisabled) {
        setTimeout(() => {
          this.showPickUpCard = true;
        }, 150);
      }
      if (type === "return" && !this.isReturnDisabled) {
        setTimeout(() => {
          this.showReturnBackCard = true;
        }, 150);
      }
    },
    changeDatesRange(dates) {
      this.$emit("changeDates", dates);
      this.filter.pickDate = this.DateUtil.formatBasicDateNoTimeZone(
        dates.start,
        this.ownerTimezone
      );
      this.filter.returnDate = this.DateUtil.formatBasicDateNoTimeZone(
        dates.end,
        this.ownerTimezone
      );
      this.closeDetailCalendar();
      if (dates.mode === "pickUp") {
        this.$refs.pickerInitialDate.openHours();
      } else {
        this.$refs.pickerFinishDate.openHours();
      }
    },
    ubicationSelected(ubication, type) {
      if (type === "pickUp") {
        this.configurablePickUpAddress = ubication.description;
        localStorage.setItem(
          "user-ubication-pickUp",
          JSON.stringify(ubication)
        );
        this.directionResultsPickUp = [];
      } else {
        this.configurableReturnBackAddress = ubication.description;
        localStorage.setItem(
          "user-ubication-returnBack",
          JSON.stringify(ubication)
        );
        this.directionResultsReturnBack = [];
      }
    },
    clearPickUpAddress() {
      this.showDirectionPickUpField = false;
      this.configurablePickUpAddress = "";
      this.filter.pickLocation = {};
      localStorage.removeItem("user-ubication-pickUp");
    },
    clearReturnBackAddress() {
      this.showDirectionReturnBackField = false;
      this.configurableReturnBackAddress = "";
      this.filter.returnLocation = {};
      localStorage.removeItem("user-ubication-returnBack");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-input__slot {
  padding-left: 8px !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-width: 0px !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:after {
  border-width: 0px !important;
}

.input-size * {
  font-size: 0.9rem !important;
}
@import "@/flit2go-web-core/shared-styles/base-vars.scss";
// Transition
.grow-enter-active,
.grow-leave-active {
  transition: height 0.5s;
}
.grow-enter {
  height: 0px;
}
.grow-enter-to {
  height: fit-content;
}
.grow-leave-to /* .fade-leave-active below version 2.1.8 */ {
  height: 0px;
}

.results {
  position: absolute;
  margin-top: 40px;
  z-index: 1000;
  width: 100%;
  background-color: white;
  overflow-y: auto;
  box-shadow: 0 23px 20px -20px rgba(115, 115, 115, 115),
    0 0 5px rgba(115, 115, 115, 115);
  border: 0 !important;
  border-radius: 0 0 0.3rem 0.3rem;
}

.results__item {
  font-size: 1.2em;
  min-height: 40px;
  border-bottom: 0.5px solid $gray-1-f2g;
  display: flex;
  align-items: center;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.results__item:hover {
  background-color: $gray-carousel;
  cursor: pointer;
}
.speech-bubble {
  position: relative;
  background: #ffffff;
  border-radius: 0.4em;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  top: -10px;
  left: 40%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-bottom-color: #ffffff;
  border-top: 0;
  margin-left: -20px;
  margin-top: 0px;
}
</style>
