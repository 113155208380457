<template>
  <div class="EndBookingV2 max-h-full flex-col-center">
    <F2gCard
      padding
      class="loginCard w-full max-h-full py-8"
      :style="{ backgroundColor: colors.colorBackground }"
    >
      <span class="flex flex-row align-center md:ml-24 md:mr-8 mt-4">
        <span
          class="mt-2 ml-2 text-xl font-black"
          :style="{ color: colors.colorPrimary }"
          >{{ $t("thanksForBooking") }}</span
        >
      </span>
      <span
        class="flex flex-row align-center md:ml-24 md:mr-8 mt-4"
        v-if="getTotalPrice() > 0"
      >
        <F2gIcon
          class="svg mt-2"
          :value="'check'"
          :stroke="$CONSTANTS.COLOUR.GREEN_1_F2G"
          :height="24"
          :width="32"
          :strokeWidth="7"
        />
        <span
          class="mt-2 ml-2 text-lg font-black"
          :style="{ color: colors.colorPrimary }"
          >{{
            $t("payAmountSuccess", {
              amount: BillingUtil.formatBillingValue(
                this.getTotalPrice(),
                actualPrice.priceTotal.currency
              ),
            })
          }}</span
        >
      </span>
      <div class="flex flex-col md:flex-row align-middle">
        <!-- Booking card-->
        <div class="w-full md:ml-24 md:mr-8 md:mt-6">
          <div class="flex flex-col shadow-md rounded-lg">
            <!-- Booking card TOP-->
            <div
              class="flex-none rounded-lg p-2"
              :style="{
                backgroundColor: colors.colorSurface,
              }"
            >
              <h3
                class="ml-2 mt-2 font-bold"
                :style="{ color: colors.colorOnSurface }"
              >
                {{ $t("common.booking") }} #{{
                  $route.params.bookingInfo.rentalId
                }}
              </h3>
            </div>
            <!-- Booking mid MIDDLE-->
            <div
              v-if="userInfo"
              class="flex flex-col flex-wrap border-t border-gray-300"
              :style="{
                backgroundColor: colors.colorSurface,
                color: colors.colorOnSurface,
              }"
            >
              <h2
                class="leading-4 font-medium mt-3 ml-4 mb-2"
                :style="{ color: colors.colorOnSurface }"
              >
                {{ $t("common.dataPrincipalDriver") }}
              </h2>
              <div class="w-full flex flex-row align-center flex-wrap">
                <div class="inline-block mr-2">
                  <div
                    class="text-sm align-middle ml-4 inline-block rounded-md flex-none mt-2 px-4 border"
                    :style="{
                      backgroundColor: colors.colorSurface,
                      color: colors.colorOnSurface,
                    }"
                  >
                    <div class="m-1 inline-block align-middle">
                      <F2gIcon
                        class="mr-2"
                        :value="'user'"
                        :stroke="colors.colorPrimary"
                        :height="16"
                        :width="25"
                        :strokeWidth="4"
                      />
                    </div>
                    <div
                      class="align-center inline-block align-middle mr-3 py-2"
                      :style="{ color: colors.colorOnSurface }"
                    >
                      {{ userInfo.name }} {{ userInfo.surnames }}
                    </div>
                  </div>
                  <div
                    class="text-sm align-middle ml-4 inline-block rounded-md flex-none mt-2 px-4 border"
                    :style="{
                      backgroundColor: colors.colorSurface,
                      color: colors.colorOnSurface,
                    }"
                  >
                    <div class="m-1 inline-block align-middle">
                      <F2gIcon
                        class="mr-2"
                        :value="'email'"
                        :stroke="colors.colorPrimary"
                        :height="16"
                        :width="25"
                        :strokeWidth="4"
                      />
                    </div>
                    <div
                      class="align-center inline-block align-middle mr-3 py-2"
                      :style="{ color: colors.colorOnSurface }"
                    >
                      {{ userInfo.username }}
                    </div>
                  </div>

                  <div
                    v-if="userInfo.phoneNumber"
                    class="text-sm align-middle ml-4 inline-block rounded-md flex-none mt-2 px-4 border"
                    :style="{
                      backgroundColor: colors.colorSurface,
                      color: colors.colorOnSurface,
                    }"
                  >
                    <div class="m-1 inline-block align-middle">
                      <F2gIcon
                        class="mr-2"
                        :value="'phone'"
                        :stroke="colors.colorPrimary"
                        :height="16"
                        :width="25"
                        :strokeWidth="4"
                      />
                    </div>
                    <div
                      class="align-center inline-block align-middle mr-3 py-2"
                      :style="{ color: colors.colorOnSurface }"
                    >
                      {{ userInfo.phoneNumber }}
                    </div>
                  </div>
                  <div
                    v-if="userInfo.bornDate"
                    class="text-sm align-middle ml-4 inline-block rounded-md flex-none mt-2 px-4 border"
                    :style="{
                      backgroundColor: colors.colorSurface,
                      color: colors.colorOnSurface,
                    }"
                  >
                    <div class="m-1 inline-block align-middle">
                      <F2gIcon
                        class="mr-3"
                        :value="'calendar'"
                        :stroke="colors.colorPrimary"
                        :height="20"
                        :width="22"
                        :strokeWidth="4"
                      />
                    </div>
                    <div
                      class="align-center inline-block align-middle mr-3 py-2"
                      :style="{ color: colors.colorOnSurface }"
                    >
                      {{ DateUtil.formatBasicDate(userInfo.bornDate) }}
                    </div>
                  </div>

                  <div
                    v-if="userInfo.country"
                    class="text-sm align-middle ml-4 inline-block rounded-md flex-none mt-2 px-4 border"
                    :style="{
                      backgroundColor: colors.colorSurface,
                      color: colors.colorOnSurface,
                    }"
                  >
                    <div class="m-1 inline-block align-middle">
                      <F2gIcon
                        class="mr-2 ml-1"
                        :value="'marker'"
                        :stroke="colors.colorPrimary"
                        :height="18"
                        :width="18"
                        :strokeWidth="4"
                      />
                    </div>
                    <div
                      class="align-center inline-block align-middle mr-3 py-2"
                      :style="{ color: colors.colorOnSurface }"
                    >
                      {{
                        userInfo.country && userInfo.country.name
                          ? userInfo.country.name[$i18n.locale.split("_")[0]]
                          : ""
                      }}
                    </div>
                  </div>
                  <div
                    v-if="userInfo.nif"
                    class="text-sm align-middle ml-4 inline-block rounded-md flex-none mt-2 px-4 border"
                    :style="{
                      backgroundColor: colors.colorSurface,
                      color: colors.colorOnSurface,
                    }"
                  >
                    <div class="m-1 inline-block align-middle">
                      <F2gIcon
                        class="mr-2"
                        :value="'rol'"
                        :stroke="colors.colorPrimary"
                        :height="16"
                        :width="25"
                        :strokeWidth="4"
                      />
                    </div>
                    <div
                      class="align-center inline-block align-middle mr-3 py-2"
                      :style="{ color: colors.colorOnSurface }"
                    >
                      {{ userInfo.nif }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex flex-col py-4"
              :style="{
                backgroundColor: colors.colorSurface,
                color: colors.colorOnSurface,
              }"
            >
              <!-- Date and place -->
              <div class="flex flex-col md:flex-row justify-start mx-4">
                <div class="flex flex-col">
                  <h2
                    class="leading-4 font-medium mt-4 w-1/2"
                    :style="{ color: colors.colorOnSurface }"
                  >
                    {{ $t("delivery") }}
                  </h2>
                  <div
                    class="flex flex-col mt-2 border md:mr-2 p-2 text-sm rounded-md"
                  >
                    <div class="mb-2 flex flex-row align-center">
                      <F2gIcon
                        class="inline-block align-middle mr-1"
                        :stroke="colors.colorPrimary"
                        :value="'marker'"
                        :height="22"
                        :width="22"
                        :strokeWidth="4"
                      />
                      <div class="flex flex-col inline-block align-middle">
                        <div
                          class="inline-block align-middle"
                          :style="{ color: colors.colorOnSurface }"
                        >
                          {{ bookingFilters.pickUpNameBranch }}
                        </div>
                        <div
                          class="text-xs inline-block align-middle w-full"
                          v-if="filters.showBranchAddress"
                        >
                          {{ bookingFilters.pickUpDirectionBranch }}
                        </div>
                      </div>
                    </div>
                    <div>
                      <F2gIcon
                        class="inline-block align-middle mr-1"
                        :stroke="colors.colorPrimary"
                        :value="'calendar'"
                        :height="22"
                        :width="22"
                        :strokeWidth="4"
                      />
                      <div
                        class="inline-block align-middle"
                        :style="{ color: colors.colorOnSurface }"
                      >
                        {{
                          DateUtil.formatCustomDate(
                            Number(bookingFilters.pickUpDate)
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col">
                  <h2
                    class="leading-4 font-medium mt-4 w-1/2 md:ml-2"
                    :style="{ color: colors.colorOnSurface }"
                  >
                    {{ $t("common.returnBack") }}
                  </h2>
                  <div
                    class="flex flex-col mt-2 border md:ml-2 p-2 text-sm rounded-md"
                  >
                    <div class="mb-2 flex flex-row align-center">
                      <F2gIcon
                        class="inline-block align-middle mr-1"
                        :stroke="colors.colorPrimary"
                        :value="'marker'"
                        :height="22"
                        :width="22"
                        :strokeWidth="4"
                      />
                      <div class="flex flex-col inline-block align-middle">
                        <div
                          class="inline-block align-middle"
                          :style="{ color: colors.colorOnSurface }"
                        >
                          {{ bookingFilters.returnBackNameBranch }}
                        </div>
                        <div
                          class="text-xs inline-block align-middle w-full"
                          v-if="filters.showBranchAddress"
                        >
                          {{ bookingFilters.returnBackDirectionBranch }}
                        </div>
                      </div>
                    </div>
                    <div class="mt-2">
                      <F2gIcon
                        class="inline-block align-middle mr-1"
                        :stroke="colors.colorPrimary"
                        :value="'calendar'"
                        :height="22"
                        :width="22"
                        :strokeWidth="4"
                      />
                      <div
                        class="inline-block align-middle"
                        :style="{ color: colors.colorOnSurface }"
                      >
                        {{
                          DateUtil.formatCustomDate(
                            Number(bookingFilters.returnBackDate)
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex-grow flex flex-col sm:flex-row p-5 min-h-48 rounded-b-lg"
              :style="{ backgroundColor: colors.colorSurface }"
            >
              <div class="flex md:flex-row flex-col">
                <v-flex class="inline-block md:max-w-36 md:min-w-30">
                  <v-img
                    :src="categorySelected.img"
                    :aspect-ratio="16 / 9"
                    class="rounded"
                  />
                </v-flex>

                <div class="md:ml-2 mt-2">
                  <!-- CategoryDesciption -->
                  <div
                    class="ml-2 font-bold"
                    :style="{ color: colors.colorPrimary }"
                  >
                    {{ categorySelected.name }}
                  </div>
                  <div
                    class="m-2 md:text-sm text-xs overflow-clip overflow-hidden overflow-y-auto max-h-10 min-h-10"
                    :style="{ color: colors.colorOnSurface }"
                    v-html="
                      categorySelected.description &&
                      $PageUtils.getObjectDescriptionLower(categorySelected)
                        ? $PageUtils.getObjectDescriptionLower(categorySelected)
                        : ''
                    "
                  ></div>
                  <div class="text-sm">
                    <div class="w-full flex flex-row align-center flex-wrap">
                      <div
                        class="inline-block mr-2 mt-4"
                        v-for="(
                          attribute, index
                        ) in categorySelected.attributeList"
                        :key="index"
                      >
                        <div
                          class="text-sm align-middle mr-2 inline-block rounded-md flex-none"
                          :style="{
                            backgroundColor: colors.colorSurfaceMedium,
                            color: colors.colorPrimary,
                          }"
                        >
                          <div class="pa-1 m-1 inline-block align-middle">
                            <svg
                              style="height: 24px; width: 24px"
                              :fill="colors.colorPrimary"
                            >
                              <path
                                v-if="attribute.icon.path"
                                :d="attribute.icon.path"
                              />
                            </svg>
                          </div>
                          <div
                            class="align-center inline-block align-middle mr-3"
                            :style="{ color: colors.colorOnSurfaceMedium }"
                          >
                            {{
                              attribute.supportedLangs[
                                $i18n.locale.split("_")[0]
                              ]
                                ? attribute.supportedLangs[
                                    $i18n.locale.split("_")[0]
                                  ]
                                : attribute.supportedLangs.en
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="text-sm px-2 mt-4 sm:mt-0 mb-2 border-t border-gray-300 flex flex-row align-center"
                    :style="{ color: colors.colorOnSurface }"
                  >
                    <div
                      class="flex md:flex-row flex-col align-start lg:align-center mt-3 mr-2"
                    >
                      <div class="max-h-full mb-2 md:mb-0">
                        {{ $t("common.addToCalendar") }}
                      </div>
                      <div class="flex flex-row md:ml-2">
                        <div class="flex items-center pr-2 sm:py-1">
                          <a
                            target="_blank"
                            :href="getGoogleLink()"
                            class="cursor-pointer"
                          >
                            <v-img
                              class="flex-none w-6 h-6"
                              :src="
                                require('@/flit2go-web-core/assets/brand-icons/google.png')
                              "
                              :contain="true"
                            ></v-img>
                          </a>
                        </div>
                        <div class="flex items-center pr-2 sm:py-1">
                          <a
                            target="_blank"
                            :href="getICalLink()"
                            class="cursor-pointer"
                          >
                            <v-img
                              class="flex-none w-6 h-6"
                              :src="
                                require('@/flit2go-web-core/assets/brand-icons/ical.png')
                              "
                              :contain="true"
                            ></v-img>
                          </a>
                        </div>
                        <div class="flex items-center pr-2 sm:py-1">
                          <a
                            target="_blank"
                            :href="getYahooLink()"
                            class="cursor-pointer"
                          >
                            <v-img
                              class="flex-none w-6 h-6"
                              :src="
                                require('@/flit2go-web-core/assets/brand-icons/yahoo.png')
                              "
                              :contain="true"
                            ></v-img>
                          </a>
                        </div>
                        <div class="flex items-center sm:py-1 w-full">
                          <a
                            target="_blank"
                            :href="getOutlookLink()"
                            class="cursor-pointer"
                          >
                            <v-img
                              class="flex-none w-6 h-6"
                              :src="
                                require('@/flit2go-web-core/assets/brand-icons/outlook.png')
                              "
                              :contain="true"
                            ></v-img>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div
        class="md:ml-24 md:mr-8 mt-4"
        :style="{ color: colors.colorOnBackground }"
        v-html="
          $t('common.endBookingProcessOk', {
            number: this.$route.params.bookingInfo.bookingId,
          })
        "
      ></div>
      <ul
        id="example-1"
        class="md:ml-24 md:mr-24 mt-2 list-disc"
        :style="{ color: colors.colorOnBackground }"
      >
        <li>
          {{ $t("common.vehicle") }}:
          <span class="font-bold">{{ categorySelected.name }}</span>
        </li>
        <li>
          {{ $t("common.pickUp") }}:
          <span class="font-bold">{{
            DateUtil.formatCustomDate(bookingFilters.pickUpDate)
          }}</span>
        </li>
        <li>
          {{ $t("common.return") }}:
          <span class="font-bold">{{
            DateUtil.formatCustomDate(bookingFilters.returnBackDate)
          }}</span>
        </li>
        <li>
          {{ $t("mobile.booking.totalCost") }}
          <span class="font-bold"
            >{{ BillingUtil.formatNumber(getTotalPrice())
            }}{{
              actualPrice.priceTotal ? actualPrice.priceTotal.currency : "€"
            }}</span
          >
        </li>
      </ul> -->
      <div
        class="md:ml-24 md:mr-24 mt-8"
        :style="{ color: colors.colorOnBackground }"
        v-html="$t('common.emailSentToBooking', { email: userLoged.username })"
      ></div>
      <div
        class="md:ml-24 md:mr-24 mt-4"
        :style="{ color: colors.colorOnBackground }"
      >
        {{ $t("common.endBookingInfo") }}
      </div>
      <div
        class="md:ml-24 md:mr-24 mt-4"
        :style="{ color: colors.colorOnBackground }"
      >
        {{ $t("common.endBookingTermsConditions") }}
        <a
          class="underline font-bold"
          :style="{ color: colors.colorPrimary }"
          :href="
            envBase +
            '/users/v1/frameweb/terms?idCategory=' +
            categorySelected.id +
            '&apiKey=' +
            apiKey +
            '&lang=' +
            userLanguage
          "
          target="_blank"
        >
          {{ $t("common.followingLink").toLowerCase() }}
        </a>
      </div>
      <div
        class="md:ml-24 md:mr-24 mt-4"
        :style="{ color: colors.colorOnBackground }"
      >
        {{ $t("common.followingSupportNumber") }}
        <a
          :style="{ color: colors.colorPrimary }"
          :href="`tel:${$route.params.bookingInfo.phoneNumber}`"
          target="_blank"
          rel="noopener noreferrer"
          class="underline font-bold"
        >
          {{ $route.params.bookingInfo.phoneNumber }}</a
        >
      </div>
      <h2
        v-if="packSelected && packSelected.id"
        class="md:ml-24 md:mr-24 mt-4 font-bold"
        :style="{ color: colors.colorOnBackground }"
      >
        {{ `${$t("pack")} ${packSelected.name}` }}
      </h2>
      <div
        v-if="packSelected && packSelected.id"
        class="md:ml-24 md:mr-24 mt-4"
        :style="{ color: colors.colorOnBackground }"
        v-html="
          packSelected.description[userLanguage]
            ? packSelected.description[userLanguage]
            : packSelected.description.en
        "
      ></div>
      <h2
        class="md:ml-24 md:mr-24 mt-4 font-bold"
        :style="{ color: colors.colorOnBackground }"
      >
        {{ $t("common.additionalInfo") }}
      </h2>
      <div
        class="md:ml-24 md:mr-24 mt-4"
        :style="{ color: colors.colorOnBackground }"
        v-html="
          labels.textEmail[userLanguage]
            ? labels.textEmail[userLanguage]
            : labels.textEmail.en
        "
      ></div>
    </F2gCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DateUtil from "@/flit2go-web-core/util/dateUtil";
import BillingUtil from "@/flit2go-web-core/util/billingUtil.js";

const envBase = process.env.VUE_APP_API_URL;
const url =
  window.location !== window.parent.location
    ? document.location.ancestorOrigins
      ? document.location.ancestorOrigins[0]
      : document.referrer
    : document.location.origin;
const apiKey = localStorage.getItem(`apiToken-${url}`);

export default {
  name: "EndBookingV2",
  components: {},
  data() {
    return {
      DateUtil,
      BillingUtil,
      envBase: envBase,
      apiKey: apiKey,
      userInfo: null,
    };
  },
  created() {
    if (this.loginParameters) {
      this.getUserData(this.loginParameters);
    }
  },
  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
      labels: "configuration/getLabels",
      categorySelected: "category/categorySelected",
      bookingFilters: "configuration/getBookingFilters",
      actualPrice: "configuration/getActualPrice",
      userLoged: "user/userLoged",
      userLanguage: "configuration/getUserLanguage",
      loginParameters: "user/loginParameters",
      filters: "configuration/getFilters",
      packSelected: "category/packSelected",
    }),
  },
  methods: {
    getTotalPrice() {
      let price = 0;
      if (this.actualPrice.priceTotal) {
        price =
          this.actualPrice.priceTotal.value +
          Number(
            (
              (this.actualPrice.priceTotal.value *
                this.actualPrice.priceTotal.tax) /
              100
            ).toFixed(2)
          );
      }
      if (
        this.actualPrice.priceDeferred &&
        !this.categorySelected.earlyPay &&
        this.categorySelected.deferredPayment
      ) {
        let priceTotalDeferred = 0;
        priceTotalDeferred =
          this.actualPrice.priceDeferred.value +
          Number(
            (
              (this.actualPrice.priceDeferred.value *
                this.actualPrice.priceDeferred.tax) /
              100
            ).toFixed(2)
          );
        price = priceTotalDeferred;
      }
      return price;
    },
    getGoogleLink() {
      let link =
        "https://calendar.google.com/calendar/render?action=TEMPLATE&dates=";
      let pickUpDateIso = new Date(Number(this.bookingFilters.pickUpDate));
      pickUpDateIso = pickUpDateIso
        .toISOString()
        .replace(/:/g, "")
        .replace(/-/g, "");
      let returnBackDateIso = new Date(
        Number(this.bookingFilters.returnBackDate)
      );
      returnBackDateIso = returnBackDateIso
        .toISOString()
        .replace(/:/g, "")
        .replace(/-/g, "");
      link = link + pickUpDateIso + "%2F" + returnBackDateIso + "&details=";
      let details =
        this.$t("common.vehicle") +
        ": " +
        this.categorySelected.name +
        ".  " +
        this.$t("common.pickUp") +
        ": " +
        this.bookingFilters.pickUpNameBranch +
        ".  " +
        this.$t("common.return") +
        ": " +
        this.bookingFilters.returnBackNameBranch;
      details = encodeURI(details);
      let location = this.bookingFilters.pickUpDirectionBranch;
      location = encodeURI(location);
      let title =
        this.$t("common.booking") +
        " " +
        this.$route.params.bookingInfo.bookingId;
      title = encodeURI(title);
      link = link + details + "&location=" + location + "&text=" + title;
      return link;
    },
    getOutlookLink() {
      let link = "https://outlook.live.com/calendar/0/deeplink/compose?body=";
      let details =
        this.$t("common.vehicle") +
        ": " +
        this.categorySelected.name +
        ".  " +
        this.$t("common.pickUp") +
        ": " +
        this.bookingFilters.pickUpNameBranch +
        ".  " +
        this.$t("common.return") +
        ": " +
        this.bookingFilters.returnBackNameBranch;
      details = encodeURI(details);
      link = link + details + "&enddt=";
      let pickUpDateIso = new Date(Number(this.bookingFilters.pickUpDate));
      pickUpDateIso = pickUpDateIso.toISOString().replace(/:/g, "%3A");
      let returnBackDateIso = new Date(
        Number(this.bookingFilters.returnBackDate)
      );
      returnBackDateIso = returnBackDateIso.toISOString().replace(/:/g, "%3A");
      let location = this.bookingFilters.pickUpDirectionBranch;
      location = encodeURI(location);
      let title =
        this.$t("common.booking") +
        " " +
        this.$route.params.bookingInfo.bookingId;
      title = encodeURI(title);
      link =
        link +
        returnBackDateIso +
        "&location=" +
        location +
        "&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=" +
        pickUpDateIso +
        "&subject=" +
        title;
      return link;
    },
    getYahooLink() {
      let link = "https://calendar.yahoo.com/?desc=";
      let details =
        this.$t("common.vehicle") +
        ": " +
        this.categorySelected.name +
        ".  " +
        this.$t("common.pickUp") +
        ": " +
        this.bookingFilters.pickUpNameBranch +
        ".  " +
        this.$t("common.return") +
        ": " +
        this.bookingFilters.returnBackNameBranch;
      details = encodeURI(details);
      link = link + details + "&et=";
      let pickUpDateIso = new Date(Number(this.bookingFilters.pickUpDate));
      pickUpDateIso = pickUpDateIso
        .toISOString()
        .replace(/:/g, "")
        .replace(/-/g, "");
      let returnBackDateIso = new Date(
        Number(this.bookingFilters.returnBackDate)
      );
      returnBackDateIso = returnBackDateIso
        .toISOString()
        .replace(/:/g, "")
        .replace(/-/g, "");
      let location = this.bookingFilters.pickUpDirectionBranch;
      location = encodeURI(location);
      let title =
        this.$t("common.booking") +
        " " +
        this.$route.params.bookingInfo.bookingId;
      title = encodeURI(title);
      link =
        link +
        returnBackDateIso +
        "&in_loc=" +
        location +
        "&st=" +
        pickUpDateIso +
        "&title=" +
        title +
        "&v=60";
      return link;
    },
    getICalLink() {
      let link =
        "data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0ADTSTART:";
      let pickUpDateIso = new Date(Number(this.bookingFilters.pickUpDate));
      pickUpDateIso = pickUpDateIso
        .toISOString()
        .replace(/:/g, "")
        .replace(/-/g, "")
        .split(".000")
        .join("");
      let returnBackDateIso = new Date(
        Number(this.bookingFilters.returnBackDate)
      );
      returnBackDateIso = returnBackDateIso
        .toISOString()
        .replace(/:/g, "")
        .replace(/-/g, "")
        .split(".000")
        .join("");
      let location = this.bookingFilters.pickUpDirectionBranch.replace(
        /,/g,
        ""
      );
      location = encodeURI(location);
      let title =
        this.$t("common.booking") +
        " " +
        this.$route.params.bookingInfo.bookingId;
      title = encodeURI(title);
      link =
        link +
        pickUpDateIso +
        "%0ADTEND:" +
        returnBackDateIso +
        "%0ASUMMARY:" +
        title +
        "%0ALOCATION:" +
        location +
        "%0AEND:VEVENT%0AEND:VCALENDAR%0A";
      return link;
    },
    getUserData(loginParameters) {
      let params = {
        loginParameters: loginParameters,
      };
      this.$store
        .dispatch("user/getUserData", params)
        .then((resp) => {
          this.userInfo = Object.assign({}, resp.data);
        })
        .catch(() => {
          this.$showMessage.error(this.$t("errors.generic"));
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
