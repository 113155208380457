import Vue from "vue";
import App from "./App.vue";
import store from "@/flit2go-web-core/store/";
import router from "@/base/router";
import i18n from "@/flit2go-web-core/lang/";
import Constants from "@/flit2go-web-core/interfaces/index.js";
import vuetify from "./plugins/vuetify";
import Vuelidate from "vuelidate";
import _ from "lodash";
import VueCardFormat from "vue-credit-card-validation";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@/flit2go-web-core/plugins/global-components";
import "./assets/tailwind.css";
import PageUtils from "@/flit2go-web-core/util/paginationUtil.js";
import VueTheMask from "vue-the-mask";
import VCalendar from "v-calendar";

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(VueTheMask);
Vue.use(VueCardFormat);
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
});

if (process.env.VUE_APP_MODE === "production") {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY,
    environment: process.env.VUE_APP_MODE,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "my-site-url.com", /^\//],
      }),
    ], // Set tracesSampleRate to 1.0 to capture 100%// of transactions for performance monitoring.// We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
} else {
  console.log("Sentry not started environment: " + process.env.VUE_APP_MODE);
}

// Prototypes
Vue.prototype.$CONSTANTS = Constants;
Vue.prototype.$PageUtils = PageUtils;
Vue.prototype.$_ = _;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
