<template>
  <div class="pay-card">
    <div class="min-max-h-full h-3/4 my-4" id="top-part">
      <div class="flex-wrap" :style="{ color: colors.colorOnBackground }">
        <div class="flex flex-row align-center mb-4 mt-6">
          <F2gIcon
            class="mr-2"
            :value="'info'"
            :fill="colors.colorPrimary"
            file="icons_solid.svg"
            solid
            :height="20"
            :width="20"
            :strokeWidth="4"
          />
          <p class="text-md mb-0">
            {{
              labels.textGratitude[language]
                ? labels.textGratitude[language]
                : labels.textGratitude.en
            }}
          </p>
        </div>
        <!-- billing direction -->
        <direction-billing-data
          @validation="setBillingValidation"
          @setTravelFields="setTravelFields"
        />
        <Agency
          v-if="configuration.agencyCode"
          :currency="filters.currency"
          @input="setAgencyCode"
        />
        <div>
          <div class="flex-col">
            <div class="mt-6">
              <div class="align-middle">
                <h2
                  class="leading-4 font-medium text-lg inline-block align-middle"
                  :style="{ color: colors.colorOnBackground }"
                >
                  {{ $t("selectCreditCard") }}
                </h2>
              </div>
            </div>
            <p class="text-md mb-6 mt-2">
              {{ $t("endBookingProcess") }}
            </p>
          </div>
          <div v-if="!creditCards" class="mb-2 ml-2">
            <div class="flex flex-row align-center mb-4 mt-12">
              <F2gIcon
                class="mr-2"
                :value="'warning'"
                :fill="'orange'"
                file="icons_solid.svg"
                solid
                :height="20"
                :width="20"
                :strokeWidth="4"
              />
              <div
                class="font-medium mt-1"
                :style="{ color: colors.colorOnBackground }"
              >
                {{ $t("noPaymentMethodAssociated") }}
              </div>
            </div>
          </div>
          <div v-else class="mb-8">
            <div
              v-for="(card, index) in creditCards"
              :key="index"
              class="creditCard mb-4 p-3 cursor-pointer rounded shadow hover:shadow-lg hover:-translate-y-1 flex flex-row align-center justify-between"
              :style="{ backgroundColor: colors.colorSurface }"
            >
              <div
                class="flex flex-row align-center justify-between"
                @click="changeCreditCard(card)"
              >
                <div class="flex flex-row">
                  <div class="flex justify-center align-center">
                    <F2gIcon
                      class="border-2 border-black rounded-lg px-2"
                      :value="card.brand.toLowerCase()"
                      file="credit-cards.svg"
                      :height="24"
                      :width="24"
                      solid
                    />
                  </div>
                  <div
                    class="w-full ml-2"
                    :style="{ color: colors.colorOnSurface }"
                  >
                    {{
                      card.brand.charAt(0).toUpperCase() + card.brand.slice(1)
                    }}
                    {{ card.last4 }}
                  </div>
                </div>
                <div class="flex flex-row justify-end">
                  <div
                    v-if="
                      creditCardSelected &&
                      card.cardId === creditCardSelected.cardId
                    "
                  >
                    <div
                      class="check-icon-container mr-4"
                      :style="{ backgroundColor: colors.colorPrimary }"
                    >
                      <F2gIcon
                        value="checkmark"
                        stroke="white"
                        :strokeWidth="3"
                        :height="24"
                        :width="24"
                        borderRadius
                      />
                    </div>
                  </div>
                </div>
              </div>
              <F2gIcon
                v-if="
                  creditCardSelected &&
                  card.cardId !== creditCardSelected.cardId
                "
                class="delete ml-2 mr-4 hidden"
                :value="'delete'"
                :height="24"
                :width="24"
                :fill="$CONSTANTS.COLOUR.RED_ERROR"
                file="icons_solid.svg"
                solid
                @click="
                  showDeleteDialog = true;
                  cardToDelete = card;
                "
              />
            </div>
          </div>
          <v-btn
            class="lg:w-48 w-full mb-2 border-2 mb-8 rounded-config30 mr-2"
            outlined
            :color="colors.colorPrimary"
            :loading="loading"
            @click="
              configuration.paymentPlatform.code ===
              $CONSTANTS.INTEGRATION.REDSYS
                ? (showModalRedsys = true)
                : (showModal = true)
            "
          >
            {{ $t("addCreditCard") }}
          </v-btn>
        </div>
        <div
          v-if="
            configuration.paymentPlatform.code === $CONSTANTS.INTEGRATION.REDSYS
          "
        >
          <redsys-form-redirection :sendData="sendPaymentMethod" />
        </div>
        <!-- promotions -->
        <div
          v-if="showPromotion"
          class="flex flex-col md:flex-row"
          :class="{ 'md:w-1/3': appliedPromotions.length === 0 }"
        >
          <!-- input -->
          <div class="flex flex-col px-2">
            <div class="font-bold">
              {{ $t("haveDiscountCode") }}
            </div>
            <div class="flex flex-col sm:flex-row mt-3">
              <v-text-field
                class="md:w-1/6 mr-2"
                v-model="promotionalCode"
                dense
                :label="$t('mobile.promotions.promotionalCode')"
                :outlined="true"
                :backgroundColor="colors.colorSurfaceMedium + '33'"
                :dark="colors.colorBackground === '#000000' ? true : false"
                :error-messages="promotionError"
              >
              </v-text-field>
              <v-btn
                class="border-2 rounded-config30 w-full sm:w-36 mb-2 sm:mb-0"
                outlined
                :color="colors.colorPrimary"
                :loading="loadingActivate"
                @click="sendPromotionalCode"
              >
                {{ $t("common.activate") }}
              </v-btn>
            </div>
          </div>
          <!-- applied -->
          <div class="flex flex-col px-2" v-if="appliedPromotions.length > 0">
            <div class="font-bold">
              {{ $t("appliedCodes") }}
            </div>
            <div class="flex flex-row flex-wrap">
              <div
                v-for="promotion in appliedPromotions"
                :key="promotion.idPromotion"
                class="ma-2 pa-4 rounded-lg"
                :style="`background-color: ${colors.colorSurfaceMedium};color: ${colors.colorOnSurfaceMedium}`"
                style="
                  height: 40px;
                  min-width: 100px;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                "
              >
                {{ promotion.code }}
              </div>
            </div>
          </div>
        </div>
        <!-- end promotions -->
        <div
          v-if="creditCards || showButtons"
          class="flex-col flex"
          :style="{ color: colors.colorOnBackground }"
        >
          <div class="flex-row">
            <v-checkbox
              v-model="secureChecks.age"
              :color="colors.colorPrimary"
              :dark="colors.colorBackground === '#000000' ? true : false"
              style="margin-top: 0; padding-top: 0"
              :error-messages="ageErrors"
            >
              <template v-slot:label>
                <div :style="{ color: colors.colorOnBackground }">
                  {{ $t("mobile.register.drivingLicenseCheck") }}
                </div>
              </template>
            </v-checkbox>
          </div>
          <div class="flex flex-row justify-start">
            <v-checkbox
              style="margin-top: 0; padding-top: 0"
              v-model="secureChecks.termsAndConditions"
              :dark="colors.colorBackground === '#000000' ? true : false"
              :color="colors.colorPrimary"
              :error-messages="termsAndConditionsErrors"
            >
              <template v-slot:label>
                <div :style="{ color: colors.colorOnBackground }">
                  {{ $t("mobile.common.iAccept") }}
                  <a
                    style="text-decoration: underline"
                    :href="
                      envBase +
                      '/users/v1/frameweb/terms?idCategory=' +
                      categorySelected.id +
                      '&apiKey=' +
                      $route.query.apiKey +
                      '&lang=' +
                      language
                    "
                    target="_blank"
                    @click.stop
                  >
                    {{ $t("mobile.common.termsAndCondition") }}
                  </a>
                  {{ $t("mobile.common.andThe") }}
                  <a
                    style="text-decoration: underline"
                    :href="
                      envBase +
                      '/users/v1/frameweb/privacy?apiKey=' +
                      $route.query.apiKey +
                      '&lang=' +
                      language
                    "
                    target="_blank"
                    @click.stop
                  >
                    {{ $t("mobile.common.privacyPolicy") }}
                  </a>
                </div>
              </template>
            </v-checkbox>
          </div>
          <div class="flex-row" v-if="userLoged.newUser">
            <v-checkbox
              v-model="secureChecks.data"
              :color="colors.colorPrimary"
              :dark="colors.colorBackground === '#000000' ? true : false"
              style="margin-top: 0; padding-top: 0"
            >
              <template v-slot:label>
                <div :style="{ color: colors.colorOnBackground }">
                  {{
                    $t("mobile.register.dataUsageCheck", {
                      0: configuration.ownerName,
                    })
                  }}
                </div>
              </template>
            </v-checkbox>
          </div>
          <div class="flex-row" v-if="userLoged.newUser">
            <v-checkbox
              v-model="secureChecks.offers"
              :color="colors.colorPrimary"
              :dark="colors.colorBackground === '#000000' ? true : false"
              style="margin-top: 0; padding-top: 0"
            >
              <template v-slot:label>
                <div :style="{ color: colors.colorOnBackground }">
                  {{
                    $t("mobile.register.receiveOffersCheck", {
                      0: configuration.ownerName,
                    })
                  }}
                </div>
              </template>
            </v-checkbox>
          </div>
        </div>

        <v-skeleton-loader
          v-if="loadingPackPrice"
          class="mx-auto my-2"
          type="image"
        ></v-skeleton-loader>
        <div
          v-else-if="
            (creditCards || showButtons) &&
            packPrice &&
            packPrice.deferredPayment
          "
          class="w-full flex px-2 h-full"
          style="min-height: 100px"
        >
          <div
            class="w-1/2 cursor-pointer rounded shadow hover:shadow-lg hover:-translate-y-1 flex-col pa-3 min-max-h-full"
            :style="{ backgroundColor: colors.colorSurface }"
          >
            <div v-html="$t('payNowPrepaidBold')" class="text-md mb-4"></div>
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
              class="mb-4"
            >
              <div class="uppercase text-xs">{{ $t("common.total") }}</div>
              <div
                v-html="getDeferredPrepaidPrice"
                class="text-2xl font-bold"
              ></div>
            </div>
            <v-btn
              class="w-full mb-2 border-2 mt-2 rounded-config30 mr-2"
              :style="{
                color: colors.colorOnPrimary,
                backgroundColor: colors.colorPrimary,
              }"
              :loading="loading"
              @click="setEarlyPayAndCreateBooking(true)"
            >
              {{ $t("makeFullPayment") }}
            </v-btn>
            <error-message
              v-if="$v.billingDataValidation.$invalid"
              :text="$t('billingAddressError')"
              :color="$CONSTANTS.COLOUR.RED_ERROR"
            />
          </div>

          <div
            class="w-1/2 cursor-pointer rounded shadow hover:shadow-lg hover:-translate-y-1 flex-col pa-3 min-max-h-full"
            :style="{ backgroundColor: colors.colorSurface }"
          >
            <div v-html="getTotalPriceDeferredText" class="text-md mb-4"></div>
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
              class="mb-4"
            >
              <div class="uppercase text-xs">{{ $t("common.total") }}</div>
              <div
                v-html="
                  BillingUtil.formatBillingValue(
                    packPrice.price,
                    categorySelected.symbol
                  )
                "
                class="text-2xl font-bold"
              ></div>
            </div>
            <v-btn
              class="w-full mb-2 border-2 mt-2 rounded-config30 mr-2"
              :style="{
                color: colors.colorOnPrimary,
                backgroundColor: colors.colorPrimary,
              }"
              :loading="loading"
              @click="setEarlyPayAndCreateBooking(false)"
            >
              {{ $t("makePartialPayment") }}
            </v-btn>
            <error-message
              v-if="$v.billingDataValidation.$invalid"
              :text="$t('billingAddressError')"
              :color="$CONSTANTS.COLOUR.RED_ERROR"
            />
          </div>
        </div>
        <div v-else-if="creditCards || showButtons">
          <v-btn
            class="lg:w-48 w-full mb-2 border-2 mt-2 rounded-config30 mr-2"
            :style="{
              color: colors.colorOnPrimary,
              backgroundColor: colors.colorPrimary,
            }"
            :loading="loading"
            @click="createBooking"
          >
            {{
              showCreditCards
                ? $t("mobile.payments.realizePayment")
                : $t("mobile.booking.bookingNextButton")
            }}
          </v-btn>
          <error-message
            v-if="$v.billingDataValidation.$invalid"
            :text="$t('billingAddressError')"
            :color="$CONSTANTS.COLOUR.RED_ERROR"
          />
        </div>
        <div v-if="error" class="text-red-700 mb-2">{{ error }}</div>
      </div>
    </div>

    <create-credit-card
      v-if="showModal"
      :show="showModal"
      :ownerIdProp="categorySelected.ownerId"
      @close="
        () => {
          showModal = false;
          goToTop();
        }
      "
      @created="getPayment"
    />
    <create-credit-card-modal-redsys
      v-if="showModalRedsys"
      :show="showModalRedsys"
      @close="closeModalRedsys"
    />
    <ActionDialogGeneric
      persistent
      :show="showDeleteDialog"
      :showClose="true"
      :width="600"
      @close="
        showDeleteDialog = false;
        cardToDelete = null;
      "
      @performAction="deleteCreditCard(cardToDelete)"
    />
    <!-- <RequiredFieldsEndBooking
      :showDialog="showRequiredFieldsDialog"
      @close="
        () => {
          showRequiredFieldsDialog = false;
          askRequiredFields();
          goToTop();
        }
      "
    /> -->
  </div>
</template>

<script src="https://js.stripe.com/v3/"></script>

<script>
import { mapState, mapGetters } from "vuex";
import { sameAs } from "vuelidate/lib/validators";
import CreateCreditCard from "@/modules/v2/Components/create-credit-card";
import CreateCreditCardModalRedsys from "@/modules/v2/Components/create-credit-card-modal-redsys.vue";
import ActionDialogGeneric from "@/flit2go-web-core/components/dialog-pickers/action-dialog-generic";
import RequiredFieldsEndBooking from "@/flit2go-web-core/components/frameweb/requiredFieldsEndBooking";
import { EventBus } from "@/flit2go-web-core/util/event-bus.js";
import RedsysFormRedirection from "../flit2go-web-core/src/flit2go-payment-method/redsys-form-redirection.vue";
import DirectionBillingData from "@/components/direction-billing-data.vue";
import DateUtil from "@/flit2go-web-core/util/dateUtil";

import BillingUtil from "@/flit2go-web-core/util/billingUtil.js";

const envBase = process.env.VUE_APP_API_URL;

export default {
  name: "PayCard",
  components: {
    DirectionBillingData,
    CreateCreditCard,
    CreateCreditCardModalRedsys,
    ActionDialogGeneric,
    RequiredFieldsEndBooking,
    RedsysFormRedirection,
    Agency: () => import("@/components/agency"),
    ErrorMessage: () =>
      import("@/flit2go-web-core/components/ui/error-message"),
  },
  data() {
    return {
      BillingUtil,
      DateUtil,
      loading: false,
      loadingActivate: false,
      promotionalCode: null,
      showPromotion: false,
      clientSecret: null,
      stripe: null,
      paymentElement: null,
      token: null,
      result: null,
      creditCard: {
        number: null,
        expiry: null,
        cvc: null,
      },
      secureChecks: {
        age: false,
        termsAndConditions: false,
        data: false,
        offers: false,
      },
      error: null,
      envBase: envBase,
      creditCards: null,
      creditCardSelected: null,
      showModal: false,
      showModalRedsys: false,
      showDeleteDialog: false,
      cardToDelete: null,
      // showRequiredFieldsDialog: false,
      requiredFields: false,
      promotions: null,
      promotionError: null,
      agency: null,
      sendPaymentMethod: false,
      billingDataValidation: false,
      travelFields: {
        flightNumber: null,
        destinationAddress: null,
      },
      packEarlyPay: true,
      packPrice: null,
      loadingPackPrice: false,
    };
  },
  validations: {
    secureChecks: {
      age: { sameAs: sameAs(() => true) },
      termsAndConditions: { sameAs: sameAs(() => true) },
    },
    billingDataValidation: { sameAs: sameAs(() => true) },
  },
  created() {
    if (this.packSelected) {
      this.packPrice = { ...this.packSelected };
      if (this.packSelected.deferredPayment) {
        this.getCategoryPackPrice();
      }
    }
  },
  mounted() {
    if (this.loginParameters) {
      this.getPayment();
      this.getPromotions();
      let params = {
        loginParameters: this.loginParameters,
        ownerId: { ownerId: this.categorySelected.ownerId },
      };
      this.$store
        .dispatch("configuration/getApiKeyStripe", params)
        .then((resp) => {
          let publicKey = process.env.VUE_APP_PUBLISHABLE_KEY;
          if (resp.data && resp.data.publicKey) {
            publicKey = resp.data.publicKey;
          }
          this.stripe = Stripe(publicKey, {
            locale: localStorage.getItem(`user-language`),
          });
        })
        .catch(() => {
          this.error = this.$t("errors.generic");
        });
    }
    if (this.userLoged) {
      this.askRequiredFields();
    }
  },
  watch: {
    promotionalCode() {
      if (!this.promotionalCode) {
        this.promotionError = null;
      }
    },
    showModal() {
      if (!this.showModal) {
        this.goToTop();
      }
    },
    showModalRedsys() {
      if (!this.showModalRedsys) {
        var access = document.getElementById("top-part");
        access.scrollIntoView({ behavior: "smooth" }, true);
      }
    },
  },
  computed: {
    ...mapState(["configuration"]),
    ...mapGetters({
      bookingFilters: "configuration/getBookingFilters",
      actualPrice: "configuration/getActualPrice",
      categorySelected: "category/categorySelected",
      extrasSelected: "configuration/getExtrasSelected",
      additionalsSelected: "configuration/getAdditionalsSelected",
      loginParameters: "user/loginParameters",
      franchiseSelected: "category/franchiseSelected",
      userLoged: "user/userLoged",
      colors: "configuration/getColors",
      labels: "configuration/getLabels",
      language: "configuration/getUserLanguage",
      filters: "configuration/getFilters",
      packSelected: "category/packSelected",
    }),

    ageErrors() {
      const errors = [];
      if (this.$v.secureChecks.age.$dirty) {
        !this.$v.secureChecks.age.sameAs &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    termsAndConditionsErrors() {
      const errors = [];
      if (this.$v.secureChecks.termsAndConditions.$dirty) {
        !this.$v.secureChecks.termsAndConditions.sameAs &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    showCreditCards() {
      if (
        !this.categorySelected.earlyPay &&
        this.categorySelected.deferredPayment
      ) {
        if (
          this.actualPrice?.priceTotal?.value &&
          this.categorySelected.totalPriceDeferred
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.actualPrice?.priceTotal?.value) {
          return true;
        } else {
          return false;
        }
      }
    },
    showButtons() {
      if (
        !this.categorySelected.earlyPay &&
        this.categorySelected.deferredPayment
      ) {
        if (
          !this.categorySelected.totalPriceDeferred ||
          !this.actualPrice ||
          !this.actualPrice.priceTotal ||
          !this.actualPrice.priceTotal.value
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          !this.actualPrice ||
          !this.actualPrice.priceTotal ||
          !this.actualPrice.priceTotal.value
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    getDeferredPrepaidPrice() {
      let price = 0;
      if (this.packPrice?.deferredPayment) {
        if (this.packPrice.deferredDiscount) {
          price = this.packPrice.totalPriceDeferredDiscount;
        } else if (this.packPrice.price) {
          return this.packPrice.price;
        }
      }
      return BillingUtil.formatBillingValue(
        price,
        this.categorySelected.symbol
      );
    },
    getTotalPriceDeferredText() {
      let text = "";
      if (this.packPrice?.deferredPayment) {
        const total = this.packPrice?.totalPriceDeferred
          ? BillingUtil.formatNumber(this.packPrice.totalPriceDeferred)
          : 0;
        if (this.packPrice.deferredStartRental) {
          text =
            Number(this.packPrice.deferredPaymentPercentageFirst) > 0
              ? this.$t("payNowAndCollectRestOnVehicleDelivery", {
                  amount: BillingUtil.formatBillingValue(
                    total,
                    this.categorySelected.symbol
                  ),
                })
              : this.$t("payNothingNowPayOnVehicleDelivery");
        } else {
          text = this.$t("payNowAndCollectBeforeRental", {
            amount: BillingUtil.formatBillingValue(
              total,
              this.categorySelected.symbol
            ),
          });
        }
      }
      return text.replace("<b>", '<b style="font-size:calc(100% + 2px)">');
    },
    appliedPromotions() {
      let promotionsSelected = [];
      if (
        this.actualPrice?.promotion?.length > 0 &&
        this.promotions?.length > 0
      ) {
        promotionsSelected = this.promotions.filter(
          (promotion) =>
            this.actualPrice.promotion.findIndex(
              (promotionSelected) => promotionSelected === promotion.idPromotion
            ) > -1
        );
      }
      return promotionsSelected;
    },
  },
  methods: {
    getPayment() {
      let params = {
        loginParameters: this.loginParameters,
        id: this.userLoged.id,
        ownerId: this.categorySelected.ownerId,
      };
      this.$store
        .dispatch("user/getPayment", params)
        .then((resp) => {
          this.creditCards = resp.data;
          if (this.creditCards) {
            this.creditCards.forEach((card) => {
              if (card.isDefault) {
                this.creditCardSelected = card;
              }
            });
          }
        })
        .catch(() => {
          this.$showMessage.error(this.$t("errors.generic"));
        });
    },
    goToTop() {
      window.parent.postMessage("goToTop", "*");
    },
    getPromotions() {
      let params = {
        loginParameters: this.loginParameters,
        categoryId: this.categorySelected.id,
      };
      this.$store
        .dispatch("user/getPromotionList", params)
        .then((resp) => {
          this.promotions = resp.data;
          this.showPromotion = true;
          EventBus.$emit("getPrice");
        })
        .catch(() => {
          this.$showMessage.error(this.$t("errors.generic"));
        });
    },
    sendPromotionalCode() {
      let params = {
        loginParameters: this.loginParameters,
        name: this.promotionalCode,
      };
      this.$store
        .dispatch("user/sendPromotionalCode", params)
        .then(() => {
          this.getPromotions();
          this.promotionalCode = "";
        })
        .catch((err) => {
          this.promotionError = err.response.data
            ? err.response.data
            : this.$t("promotionNotFound");
        });
    },
    setEarlyPayAndCreateBooking(earlyPay) {
      this.packEarlyPay = earlyPay;
      this.createBooking();
    },
    createBookingRedsys() {
      this.sendPaymentMethod = true;
    },
    createBooking() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        this.error = null;
        let booking = {};
        booking.loginParameters = this.loginParameters;
        booking.categoryId = this.categorySelected.id;
        booking.userId = this.userLoged.id;
        booking.data = {
          pickUpDate: this.bookingFilters.pickUpDate,
          returnBackDate: this.bookingFilters.returnBackDate,
          pickUpBranch: this.bookingFilters.pickUpBranch,
          returnBackBranch: this.bookingFilters.returnBackBranch,
          franchise: this.franchiseSelected
            ? this.franchiseSelected.franchiseType
            : -1,
          extra: [],
          additionServices: [],
          earlyPay:
            this.packPrice?.id && this.packPrice.deferredPayment
              ? this.packEarlyPay
              : this.categorySelected.earlyPay,
          agencyCode: this.agency ? this.agency.code : null,
          flightNumber: this.travelFields.flightNumber,
          destinationAddress: this.travelFields.destinationAddress,
          packId: this.packSelected?.id ? this.packSelected.id : null,
        };

        if (this.extrasSelected[0]) {
          Object.keys(this.extrasSelected).forEach((extra) => {
            booking.data.extra.push(this.extrasSelected[extra].id);
          });
        }

        if (this.additionalsSelected[0]) {
          Object.keys(this.additionalsSelected).forEach((value) => {
            booking.data.additionServices.push(this.additionalsSelected[value]);
          });
        }

        this.$store
          .dispatch("configuration/createBooking", booking)
          .then((resp) => {
            if (resp.status === 200) {
              let bookingInfo = resp.data;
              this.loading = false;
              this.goToTop();
              EventBus.$emit("goToEndBooking");
              this.$router.push({
                name: "EndBookingV2",
                params: { bookingInfo: bookingInfo },
              });
            } else {
              this.error = this.$t("endReserveFrameError");
            }
          })
          .catch((err) => {
            if (
              err.response.data.paymentIntentStatus ===
              "authentication_required"
            ) {
              this.stripe
                .confirmCardPayment(err.response.data.clientSecret, {
                  payment_method: err.response.data.paymentMethodId,
                })
                .then((result) => {
                  let data = {};
                  data.reserveId = err.response.data.id;
                  data.loginParameters = this.loginParameters;
                  data.params = {};
                  data.params.paymentIntentId = result.paymentIntent.id;
                  if (result.paymentIntent.status === "succeeded") {
                    data.params.accept = true;
                  } else {
                    data.params.accept = false;
                  }
                  this.$store
                    .dispatch("configuration/createReserve", data)
                    .then((response) => {
                      if (response.status === 200) {
                        this.loading = false;
                        this.$router.push({
                          name: "EndBookingV2",
                          params: { bookingInfo: response.data },
                        });
                      } else {
                        this.error = this.$t("endReserveFrameError");
                      }
                    })
                    .catch((error) => {
                      this.loading = false;
                      this.error = error.response.message;
                    });
                });
            } else {
              this.loading = false;
              this.error = err.response.data
                ? err.response.data
                : this.$t("errors.generic");
            }
          });
      } else {
        if (!this.billingDataValidation) {
          this.$showMessage.error(this.$t("checkRequiredFields"));
        }
        this.loading = false;
        // if (this.requiredFields) {
        //   this.showRequiredFieldsDialog = true;
        // }
      }
    },
    askRequiredFields() {
      if (
        (this.filters.dniRequired && !this.userLoged.nif) ||
        (this.filters.billingAddressRequired &&
          !(this.userLoged.billAddress && this.userLoged.billAddress.country))
      ) {
        this.requiredFields = true;
      } else {
        this.requiredFields = false;
      }
    },
    changeCreditCard(card) {
      let params = {
        loginParameters: this.loginParameters,
        id: this.userLoged.id,
        data: {
          cardId: String(card.stripeCardId),
        },
      };
      this.$store
        .dispatch("user/changeDefaultPayment", params)
        .then(() => {
          this.creditCardSelected = card;
          this.$emit("close");
        })
        .catch((err) => {
          this.error = err.response.message;
        });
    },
    deleteCreditCard(card) {
      let data = {
        loginParameters: this.loginParameters,
        id: this.userLoged.id,
        cardStripeId: String(card.stripeCardId),
      };
      this.$store
        .dispatch("user/deletePayment", data)
        .then(() => {
          var indice = this.creditCards.indexOf(card);
          this.creditCards.splice(indice, 1);
          this.showDeleteDialog = false;
        })
        .catch((err) => {
          this.error = err.response.message
            ? err.response.message
            : err.response.data;
          this.showDeleteDialog = false;
        });
    },
    setAgencyCode(agency) {
      this.agency = agency;
      EventBus.$emit("agencyCode", this.agency ? this.agency.code : null);
    },
    closeModalRedsys() {
      this.showModalRedsys = false;
      this.getPayment();
    },
    setBillingValidation(event) {
      this.billingDataValidation = event.valid;
    },
    setTravelFields(direction) {
      this.travelFields.flightNumber = direction.flightNumber;
      this.travelFields.destinationAddress = direction.destinationAddress;
    },
    getCategoryPackPrice() {
      this.loadingPackPrice = true;
      const extras = [];
      const services = [];

      if (this.extrasSelected[0]) {
        Object.keys(this.extrasSelected).forEach((extra) => {
          extras.push(this.extrasSelected[extra].id);
        });
      }

      if (this.additionalsSelected[0]) {
        Object.keys(this.additionalsSelected).forEach((value) => {
          services.push(this.additionalsSelected[value]);
        });
      }

      this.$store
        .dispatch("category/getCategoryPackRatePrice", {
          categoryId: this.categorySelected.id,
          packId: this.packSelected.id,
          data: {
            pickUpDate: this.bookingFilters.pickUpDate,
            returnBackDate: this.bookingFilters.returnBackDate,
            pickUpBranch: this.bookingFilters.pickUpBranch,
            returnBackBranch: this.bookingFilters.returnBackBranch,
            franchise: this.franchiseSelected
              ? this.franchiseSelected.franchiseType
              : -1,
            extra: extras,
            additionServices: services,
            earlyPay: true,
            userId: this.userLoged.id,
            agencyCode: this.agency ? this.agency.code : null,
          },
        })
        .then(
          (resp) =>
            (this.packPrice = {
              ...resp.data.packRate.find(
                (pack) => pack.id === this.packSelected.id
              ),
            })
        )
        .catch(this.$showMessage.showError)
        .finally(() => (this.loadingPackPrice = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.creditCard {
  &:hover {
    .delete {
      display: block !important;
    }
  }
}
.check-icon-container {
  height: 26px;
  width: 26px;
  border-radius: 80%;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
