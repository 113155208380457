<template>
  <div class="Catalogue max-h-full">
    <main-filter-catalogue />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MainFilterCatalogue from "@/modules/catalogue/Components/main-filter-catalogue";

export default {
  name: "Catalogue",
  components: { MainFilterCatalogue },
  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
    }),
  },
};
</script>
