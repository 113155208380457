var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"f2gfw-custom-stepper w-full"},[_c('div',{staticClass:"w-full lg:h-16 mt-8 rounded-t-lg rounded-b-lg relative shadow",class:_vm.userLoged.id ? 'h-32' : 'h-20',style:({ backgroundColor: _vm.colors.colorHeader })},[_c('div',{staticClass:"w-full max-h-full relative flex rounded-t-lg rounded-b-lg justify-between align-start lg:align-end pb-1 px-3",class:_vm.$vuetify.breakpoint.name === 'xs' || _vm.$vuetify.breakpoint.name === 'sm'
          ? 'pt-10'
          : 'pt-8',style:({ backgroundColor: _vm.colors.colorHeader })},[_c('div',{staticClass:"text-xl pb-2",style:({ color: _vm.colors.colorOnHeader })},[_vm._v(" "+_vm._s(_vm.stepList[_vm.currentStep - 1].label)+" ")]),_c('div',{staticClass:"inline-flex"},[(
            _vm.$vuetify.breakpoint.name === 'xs' ||
              _vm.$vuetify.breakpoint.name === 'sm'
          )?_c('div',{staticClass:"rounded-full h-7 w-7 mr-2 justify-center align-center flex shadow",style:({ backgroundColor: _vm.colors.colorSurfaceMedium }),on:{"click":function($event){_vm.showResume = !_vm.showResume}}},[_c('F2gIcon',{staticClass:"cursor-pointer p-1",attrs:{"height":20,"width":20,"stroke":_vm.colors.colorOnSurfaceMedium,"value":_vm.showResume ? 'arrow-up' : 'arrow-down',"strokeWidth":5}})],1):_vm._e(),(_vm.currentStep === 1 && !_vm.isCatalogue)?_c('div',{staticClass:"rounded-full h-7 w-7 mr-3 justify-center align-center flex shadow",style:({ backgroundColor: _vm.colors.colorSurfaceMedium }),on:{"click":function($event){_vm.showSearch = !_vm.showSearch}}},[_c('F2gIcon',{staticClass:"cursor-pointer p-3",attrs:{"height":16,"width":16,"stroke":_vm.colors.colorOnSurfaceMedium,"value":'search',"strokeWidth":5}})],1):_vm._e(),(
            _vm.userLoged.id &&
              _vm.$vuetify.breakpoint.name !== 'xs' &&
              _vm.$vuetify.breakpoint.name !== 'sm'
          )?_c('div',{staticClass:"text-xl flex"},[_c('span',{staticClass:"mr-4",style:({ color: _vm.colors.colorOnHeader })},[_vm._v(_vm._s(_vm.$t("helloUser", { user: `${_vm.userLoged.name} ${_vm.userLoged.surnames}`, })))]),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"rounded-full justify-center align-center flex shadow pt-1 cursor-pointer",staticStyle:{"height":"28px","width":"28px"},style:({ backgroundColor: _vm.colors.colorSurfaceMedium }),on:{"click":_vm.showLogout}},[_c('F2gIcon',{attrs:{"height":16,"width":16,"stroke":_vm.colors.colorOnHeader,"value":"logout","tooltip":_vm.$t('common.logout'),"strokeWidth":2}})],1)]):_vm._e()])]),(
        _vm.userLoged.id &&
          (_vm.$vuetify.breakpoint.name === 'xs' ||
            _vm.$vuetify.breakpoint.name === 'sm')
      )?_c('div',{staticClass:"p-2 text-xl flex items-center pb-1 px-3 rounded-t-lg rounded-b-lg"},[_c('span',{staticClass:"mr-4",style:({ color: _vm.colors.colorOnHeader })},[_vm._v(_vm._s(_vm.$t("helloUser", { user: `${_vm.userLoged.name} ${_vm.userLoged.surnames}`, })))]),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"rounded-full justify-center align-center shadow p-2 cursor-pointer",staticStyle:{"height":"28px","width":"28px"},style:({ backgroundColor: _vm.colors.colorSurfaceMedium }),on:{"click":_vm.showLogout}},[_c('F2gIcon',{attrs:{"height":16,"width":16,"stroke":_vm.colors.colorOnHeader,"value":"logout","tooltip":_vm.$t('common.logout'),"strokeWidth":2}})],1)]):_vm._e()]),_c('div',{staticClass:"absolute top-3 left-0 flex flex-row justify-around w-full w-full lg:w-9/12"},_vm._l((_vm.stepList),function(step,index){return _c('div',{key:index},[_c('div',{staticClass:"w-12 h-12 rounded-full flex justify-center align-center border-2 border-white text-white font-bold cursor-pointer shadow",style:(`background-color: ${
            step.id === _vm.currentStep
              ? _vm.colors.colorPrimary
              : _vm.colors.colorSurface
          }`),on:{"click":function($event){return _vm.changeCurrentStep(step.id)}}},[_c('div',{style:(`color: ${
              step.id === _vm.currentStep
                ? _vm.colors.colorOnPrimary
                : _vm.colors.colorOnSurface
            }`)},[_vm._v(" "+_vm._s(step.id)+" ")])])])}),0),_c('transition',{attrs:{"name":"fade"}},[(
        _vm.showResume &&
          (_vm.$vuetify.breakpoint.name === 'xs' ||
            _vm.$vuetify.breakpoint.name === 'sm')
      )?_c('ResumeData',{attrs:{"currentStep":_vm.currentStep,"isCatalogue":_vm.isCatalogue,"showNextStep":_vm.showNextStep,"loading":_vm.loading,"servicesValidation":_vm.servicesValidation},on:{"showExtras":function($event){return _vm.$emit('showExtras')},"currentStep":(value) => {
          _vm.$emit('currentStep', value);
        }}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.showSearch && _vm.currentStep === 1)?_c('v-text-field',{staticClass:"mx-4 mt-2",attrs:{"label":_vm.$t('common.search'),"outlined":"","solo":"","clearable":"","hide-details":"","prepend-inner-icon":"search"},on:{"input":_vm.debounceInput,"click:clear":function($event){_vm.search = ''}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),_vm._t(`content${_vm.currentStep}`)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }