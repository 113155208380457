<template>
  <div class="reserve-filter-catalogue">
    <div
      class="px-4 pt-3 rounded-lg shadow"
      id="top-part-filter"
      :style="{ backgroundColor: colors.colorSurface }"
    >
      <h1 class="font-bold mb-2">
        {{ $t("delivery").toUpperCase() }}
      </h1>
      <div class="flex flex-col">
        <!-- <div class="w-full" v-if="branchesFiltered">
          <v-autocomplete
            id="pickUpLocation"
            style="z-index: 100"
            single-line
            filled
            :dense="true"
            :disabled="isPickUpDisabled"
            :append-icon="isPickUpDisabled ? '' : '$dropdown'"
            flat
            hide-details
            clearable
            return-object
            class="mb-2 rounded-l"
            type="text"
            v-model="filter.pickLocation"
            :items="branchesFiltered"
            :label="$t('placeOfDelivery')"
            :color="colors.colorPrimary"
            :background-color="
              isPickUpDisabled ? colors.colorSurface : colors.colorSurfaceMedium
            "
            item-text="name"
            @change="changeSelected('pickUp')"
            @focus="showBubbleMenuPickUp"
            @blur="quiteBubbleMenu"
          >
            <template slot="prepend-inner">
              <div class="speech-bubble" v-if="showBubblePickUp"></div>
              <F2gIcon
                :fill="colors.colorPrimary"
                :style="`cursor: pointer; color: ${colors.colorPrimary}`"
                class="cursor-pointer"
                value="location"
                solid
                file="icons_solid.svg"
                :strokeWidth="3"
              />
            </template>
          </v-autocomplete>
        </div> -->
        <div
          v-if="branchesFiltered"
          class="mr-2 w-full mb-2"
          :class="[isPickUpDisabled ? '' : 'cursor-pointer']"
        >
          <div class="w-full max-h-full">
            <v-text-field
              id="pickUpLocation"
              ref="pickUpLocation"
              single-line
              filled
              :dense="true"
              :disabled="isPickUpDisabled"
              :autocomplete="'off'"
              hide-no-data
              :append-icon="''"
              flat
              hide-details
              return-object
              class="rounded input-size"
              type="text"
              v-model="branchPickName"
              :items="branchesFiltered"
              :color="colors.colorPrimary"
              :background-color="colors.colorSurfaceMedium"
              item-text="name"
              @change="changeSelected('pickUp')"
              @input="searchBranchesFiltered"
              @click="openLocationCard('pick')"
            >
              <template #selection="{ item }">
                <span
                  :style="`color:${colors.colorOnSurfaceMedium} !important`"
                  >{{ item.name }}</span
                >
              </template>
              <template #label>
                <span
                  :style="`color:${colors.colorOnSurfaceMedium} !important`"
                  >{{ $t("placeOfDelivery") }}</span
                >
              </template>
              <template slot="prepend-inner">
                <F2gIcon
                  :style="`cursor: pointer, color: ${colors.colorPrimary}`"
                  :fill="colors.colorPrimary"
                  class="cursor-pointer"
                  value="location"
                  solid
                  file="icons_solid.svg"
                  :strokeWidth="3"
                />
              </template>
            </v-text-field>
          </div>
          <div class="speech-bubble" v-if="showPickUpCard"></div>
          <v-card
            v-if="showPickUpCard"
            elevation="3"
            class="absolute z-10 min-w-64 w-auto flex flex-row cursor-default"
            :style="{
              border: '0px',
              height: '300px',
            }"
            v-on-clickaway="closeDetailCard"
          >
            <branch-card-detail-horizontal
              class="w-full max-h-full"
              :branches="branchesFiltered"
              :type="'pickUp'"
              :size="size"
              :style="{
                border: '0px',
              }"
              :branchToShow="filter.pickLocation"
              @branchSelected="changePickUpLocation"
              @close="closeDetailCard"
            />
          </v-card>
        </div>
        <div class="w-full mb-4">
          <DateHourPickerInline
            ref="pickerInitialDate"
            :labelTime="$t('common.pickUpTime')"
            :labelDate="$t('common.pickUpDate')"
            :dateSelected="filter.pickDate"
            :timeSelected="filter.pickHour"
            :minDate="pickUpMinDate"
            :minHour="pickUpMinTime"
            :availableHours="filters.hours"
            :zIndex="200"
            @time="setPickUpTime"
            @date="setPickUpDate"
            @openDateCard="openDateCalendar('pickUp')"
          />
          <v-card
            v-if="showPickUpCalendar"
            elevation="3"
            class="absolute p-2 flex flex-row cursor-default"
            :class="{
              'right-36':
                $vuetify.breakpoint.name === 'xs' &&
                $vuetify.breakpoint.name === 'sm',
            }"
            :style="{
              border: '0px',
              zIndex: '200',
            }"
            v-on-clickaway="closeDetailCalendar"
          >
            <CategoryCardCalendar
              v-show="showPickUpCalendar"
              :categoryRow="currentCategory"
              :reserveFilter="reserveFilter"
              :mode="'pickUp'"
              @changeDates="changeDatesRange"
              @close="() => (showPickUpCalendar = false)"
            />
            <!-- <calendar-date-selector
              v-show="showPickUpCalendar"
              style="z-index: 200"
              :type="'vertical'"
              :mode="'pickUp'"
              :pickUpDate="filter.pickDate"
              :returnBackDate="filter.returnDate"
              @changeDates="changeDatesRange"
              @close="() => (showPickUpCalendar = false)"
            /> -->
          </v-card>
        </div>
      </div>
    </div>
    <div
      class="px-4 pt-3 rounded-lg mt-2 shadow"
      :style="{ backgroundColor: colors.colorSurface }"
    >
      <h1 class="font-bold mb-2">
        {{ $t("common.returnBack").toUpperCase() }}
      </h1>
      <div class="flex flex-col">
        <!-- <div class="w-full" v-if="branchesFiltered">
          <v-autocomplete
            id="returnLocation"
            ref="returnLocation"
            single-line
            filled
            style="z-index: 0"
            :dense="true"
            :disabled="isReturnDisabled"
            :append-icon="isReturnDisabled ? '' : '$dropdown'"
            flat
            hide-details
            clearable
            return-object
            class="mb-2 rounded rounded-l"
            type="text"
            v-model="filter.returnLocation"
            :items="branchesFiltered"
            :label="$t('common.returnLocation')"
            :color="colors.colorPrimary"
            :background-color="
              isReturnDisabled ? colors.colorSurface : colors.colorSurfaceMedium
            "
            item-text="name"
            @change="changeSelected('return')"
            @focus="showBubbleMenuReturnBack"
            @blur="quiteBubbleMenu"
          >
            <template slot="prepend-inner">
              <div class="speech-bubble" v-if="showBubbleReturnBack"></div>
              <F2gIcon
                :fill="colors.colorPrimary"
                class="cursor-pointer"
                :style="`cursor: pointer, color: ${colors.colorPrimary}`"
                value="location"
                solid
                file="icons_solid.svg"
                :strokeWidth="3"
              />
            </template>
          </v-autocomplete>
        </div> -->
        <div
          v-if="
            filter.otherBranch &&
            branchesFiltered &&
            this.filters.allowReturnInAnotherBranch
          "
          class="mr-2 w-full mb-2"
          :class="isReturnDisabled ? '' : 'cursor-pointer'"
        >
          <div class="w-full max-h-full">
            <v-text-field
              id="returnLocation"
              ref="returnLocation"
              single-line
              filled
              :dense="true"
              :disabled="isReturnDisabled"
              :autocomplete="'off'"
              menu-props="{ display: none }"
              :append-icon="''"
              flat
              hide-details
              return-object
              class="rounded input-size"
              type="text"
              v-model="branchReturnName"
              :items="branches"
              :label="$t('common.returnLocation')"
              :color="colors.colorPrimary"
              :background-color="colors.colorSurfaceMedium"
              item-text="name"
              @change="changeSelected('return')"
              @input="searchBranchesFiltered"
              @click="openLocationCard('return')"
            >
              <template #selection="{ item }">
                <span
                  :style="`color:${colors.colorOnSurfaceMedium} !important`"
                  >{{ item.name }}</span
                >
              </template>
              <template #label>
                <span
                  :style="`color:${colors.colorOnSurfaceMedium} !important`"
                  >{{ $t("common.returnLocation") }}</span
                >
              </template>
              <template slot="prepend-inner">
                <F2gIcon
                  :fill="colors.colorPrimary"
                  :style="`cursor: pointer, color: ${colors.colorPrimary}`"
                  class="cursor-pointer"
                  value="location"
                  solid
                  file="icons_solid.svg"
                  :strokeWidth="3"
                />
              </template>
            </v-text-field>
          </div>
          <div class="speech-bubble" v-if="showReturnBackCard"></div>
          <v-card
            v-if="showReturnBackCard"
            elevation="3"
            class="absolute z-10 min-w-64 w-auto flex flex-row cursor-default"
            :style="{
              border: '0px',
              height: '300px',
            }"
            v-on-clickaway="closeDetailCard"
          >
            <branch-card-detail-horizontal
              class="w-full max-h-full"
              :branches="branchesFiltered"
              :type="'returnBack'"
              :size="size"
              :style="{
                border: '0px',
              }"
              :branchToShow="filter.returnLocation"
              @branchSelected="changeReturnBackLocation"
              @close="closeDetailCard"
            />
          </v-card>
        </div>
        <div class="w-full mb-4">
          <DateHourPickerInline
            ref="pickerFinishDate"
            :labelTime="$t('common.pickUpTime')"
            :labelDate="$t('common.pickUpDate')"
            :dateSelected="filter.returnDate"
            :timeSelected="filter.returnHour"
            :minDate="returnBackMinDate"
            :minHour="returnBackMinTime"
            :availableHours="filters.hours"
            :isLateralPanel="false"
            :zIndex="200"
            @time="setReturnBackTime"
            @date="setReturnBackDate"
            @openDateCard="openDateReturnCalendar('returnBack')"
          />
          <v-card
            v-if="showReturnBackCalendar"
            elevation="3"
            class="absolute p-2 flex flex-row cursor-default"
            :style="{
              border: '0px',
              zIndex: '200',
            }"
            :class="{
              'right-36':
                $vuetify.breakpoint.name === 'xs' &&
                $vuetify.breakpoint.name === 'sm',
            }"
          >
            <CategoryCardCalendar
              v-show="showReturnBackCalendar"
              :categoryRow="currentCategory"
              :reserveFilter="reserveFilter"
              :mode="'returnBack'"
              @changeDates="changeDatesRange"
              @close="() => (showReturnBackCalendar = false)"
            />
            <!-- <calendar-date-selector
              v-show="showReturnBackCalendar"
              class="max-h-full"
              :type="'vertical'"
              :mode="'returnBack'"
              :pickUpDate="filter.pickDate"
              oneColumn
              :returnBackDate="filter.returnDate"
              @changeDates="changeDatesRange"
              @close="() => (showReturnBackCalendar = false)"
            /> -->
          </v-card>
        </div>
      </div>
      <div v-if="error" class="font-bold text-red-700 p-2">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import DateUtil from "@/flit2go-web-core/util/dateUtil";
import moment from "moment";
import { EventBus } from "@/flit2go-web-core/util/event-bus.js";
import DateHourPickerInline from "@/modules/v2/Components/date-hour-picker-inline";
// import CalendarDateSelector from "@/modules/v2/Components/calendar-date-selector";
import { directive as onClickaway } from "vue-clickaway";
import CategoryCardCalendar from "@/modules/catalogue/Components/category-card-calendar.vue";
import BranchCardDetailHorizontal from "@/modules/v2/Components/branch-card-detail-horizontal";

export default {
  name: "ReserveFilterCatalogue",
  directives: {
    onClickaway,
  },
  components: {
    DateHourPickerInline,
    // CalendarDateSelector,
    CategoryCardCalendar,
    BranchCardDetailHorizontal,
  },
  props: {
    currentCategory: { required: true, type: Object, default: () => [] },
    calendarDates: { required: false, type: Object, default: () => [] },
    reserveFilter: { required: false, type: Object, default: () => {} },
  },
  data: () => ({
    DateUtil,
    filter: {
      pickDate: null,
      returnDate: null,
      pickHour: null,
      returnHour: null,
      pickLocation: null,
      returnLocation: null,
      otherBranch: true,
    },
    error: null,
    returnBackMinDate: DateUtil.currentDateBasicFormat(),
    returnBackMinTime: "",
    pickUpMinDate: DateUtil.currentDateBasicFormat(),
    pickUpMinTime: "",
    branches: null,
    branchesFiltered: null,
    firstEntryPickUpDate: true,
    firstEntryReturnDate: true,
    showPickUpCalendar: false,
    showReturnBackCalendar: false,
    showBubblePickUp: false,
    showBubbleReturnBack: false,
    showPickUpCard: false,
    showReturnBackCard: false,
    query: "",
    branchPickName: "",
    branchReturnName: "",
    size: "full",
  }),
  validations: {
    filter: {
      pickLocation: { required },
      returnLocation: { required },
    },
  },
  watch: {
    "filter.pickDate"() {
      if (this.filter.pickDate === DateUtil.currentDateBasicFormat()) {
        this.pickUpMinTime = DateUtil.currentTimeHour();
      } else {
        this.pickUpMinTime = "";
      }
      if (this.filter.pickDate) {
        this.returnBackMinDate = this.filter.pickDate;
        if (!this.filters.allowReturnInAnotherBranch) {
          this.filter.returnLocation = Object.assign(
            {},
            this.filter.pickLocation
          );
        }
      }
    },
    "filter.returnDate"() {
      if (this.filter.returnDate === DateUtil.currentDateBasicFormat()) {
        this.returnBackMinTime = DateUtil.currentTimeHour();
      } else {
        this.returnBackMinTime = "";
      }
      if (this.filter.pickDate === this.filter.returnDate) {
        this.returnBackMinTime = this.filter.pickHour;
      }
    },
    filter: {
      deep: true,
      handler() {
        this.error = null;
        this.$emit("filter", this.filter);
        if (
          this.filter.pickDate &&
          this.filter.returnDate &&
          this.filter.pickHour &&
          this.filter.returnHour
        ) {
          this.canShowPriceDeferredCategory();
        }
      },
    },
    calendarDates: {
      deep: true,
      handler() {
        this.filter.pickDate = this.DateUtil.formatBasicDateNoTimeZone(
          this.calendarDates.start,
          this.ownerTimezone
        );
        this.filter.returnDate = this.DateUtil.formatBasicDateNoTimeZone(
          this.calendarDates.end,
          this.ownerTimezone
        );
      },
    },
    branches() {
      if (this.branches.length === 1) {
        this.filter.pickLocation = this.branches[0];
        this.filter.returnLocation = this.branches[0];
      }
    },
  },
  created() {
    this.searchBranches();
  },
  mounted() {
    EventBus.$on("errorFilters", (value) => {
      this.error = value;
    });
    this.setDefaultDates();
  },
  computed: {
    ...mapState(["configuration, category"]),
    ...mapGetters({
      colors: "configuration/getColors",
      contact: "configuration/getContact",
      filters: "configuration/getFilters",
      isSuperowner: "configuration/getSuperowner",
      orderedHours: "configuration/getOrderedHours",
      ownerTimezone: "configuration/getOwnerTimezone",
    }),
    isPickUpDisabled() {
      return (
        this.branchesFiltered &&
        this.branchesFiltered.length <= 0 &&
        !this.isSuperowner
      );
    },
    isReturnDisabled() {
      return (
        this.branchesFiltered.length <= 0 ||
        !this.filters.allowReturnInAnotherBranch ||
        !this.filter.otherBranch
      );
    },
  },
  methods: {
    showBubbleMenuPickUp() {
      this.showBubblePickUp = true;
      this.showBubbleReturnBack = false;
    },
    showBubbleMenuReturnBack() {
      this.showBubblePickUp = false;
      this.showBubbleReturnBack = true;
    },
    quiteBubbleMenu() {
      this.showBubblePickUp = false;
      this.showBubbleReturnBack = false;
    },
    changeDatesRange(dates) {
      this.$emit("changeDates", dates);
      this.filter.pickDate = this.DateUtil.formatBasicDateNoTimeZone(
        dates.start,
        this.ownerTimezone
      );
      this.filter.returnDate = this.DateUtil.formatBasicDateNoTimeZone(
        dates.end,
        this.ownerTimezone
      );
      this.closeDetailCalendar();
      if (dates.mode === "pickUp") {
        this.$refs.pickerInitialDate.openHours();
      } else {
        this.$refs.pickerFinishDate.openHours();
      }
      this.firstEntryPickUpDate = false;
    },
    openDateCalendar(mode) {
      if (
        this.$vuetify.breakpoint.name === "sm" ||
        this.$vuetify.breakpoint.name === "xs"
      ) {
        this.$emit("openDateCard", mode);
      } else {
        this.showPickUpCalendar = true;
      }
    },
    openDateReturnCalendar(mode) {
      if (
        this.$vuetify.breakpoint.name === "sm" ||
        this.$vuetify.breakpoint.name === "xs"
      ) {
        this.$emit("openDateCard", mode);
      } else {
        this.showReturnBackCalendar = true;
      }
    },
    closeDetailCalendar() {
      this.showPickUpCalendar = false;
      this.showReturnBackCalendar = false;
    },
    searchBranches() {
      let params = {
        categoryId: this.currentCategory.id,
        type: this.$CONSTANTS.INTEGRATION.FRAME_WEB,
      };
      this.$store
        .dispatch("branchVehicle/getAvailableBranches", params)
        .then((resp) => {
          if (resp.status !== 200) {
            this.branchesFiltered = [];
            this.branches = [];
          } else {
            this.branches = resp.data;
            if (this.isSuperowner) {
              this.updateBranchesFiltered();
            } else {
              this.branchesFiltered = resp.data;
            }
          }
        });
    },
    canShowPriceDeferredCategory() {
      let pickUpDate = this.filter.pickDate + " " + this.filter.pickHour;
      let returnBackDate =
        this.filter.returnDate + " " + this.filter.returnHour;

      let data = {
        categoryId: this.currentCategory.id,
        body: {
          pickUpDate: DateUtil.formatDateNoTimeZoneTimestamp(
            pickUpDate,
            this.ownerTimezone
          ),
          returnBackDate: DateUtil.formatDateNoTimeZoneTimestamp(
            returnBackDate,
            this.ownerTimezone
          ),
          pickUpBranch: this.filter.pickLocation
            ? this.filter.pickLocation.id
            : null,
          returnBackBranch: this.filter.returnLocation
            ? this.filter.returnLocation.id
            : null,
        },
      };
      EventBus.$emit("loadingAvailabilityDates", true);
      this.$store
        .dispatch("category/canShowPriceDeferredCategory", data)
        .then((resp) => {
          if (resp.status === 200) {
            this.$store.commit("category/SET_CATEGORY", resp.data);
            this.$emit("changeCategory", resp.data);
          }
          if (resp.status === 204) {
            let categoryData = Object.assign({}, this.currentCategory);
            categoryData.errorDatesReserve = false;
            this.$emit("changeCategory", categoryData);
          }
        })
        .catch((err) => {
          let categoryError = Object.assign({}, this.currentCategory);
          categoryError.errorDatesReserve = true;
          if (err && err.response && err.response.data) {
            categoryError.txtErrorDatesReserve = err.response.data;
          } else {
            categoryError.txtErrorDatesReserve = this.$t("errors.generic");
          }
          window.parent.postMessage("goToTop", "*");
          this.$emit("changeCategory", categoryError);
        })
        .finally(() => EventBus.$emit("loadingAvailabilityDates", false));
    },
    changeSelected(type) {
      this.showBubblePickUp = false;
      this.showBubbleReturnBack = false;
      if (type === "pickUp") {
        if (this.isReturnDisabled) {
          this.filter.returnLocation = Object.assign(
            {},
            this.filter.pickLocation
          );
        }
        this.$emit("changeSelected", this.filter.pickLocation);
        /*   if (this.filters.allowReturnInAnotherBranch) {
          document.getElementById("returnLocation").click();
        } else {
          this.$refs.pickerInitialDate.openDateCard();
        }*/
      } else {
        this.$emit("changeSelected", this.filter.returnLocation);
        //  this.$refs.pickerInitialDate.openDateCard();
      }
    },
    updateBranchesFiltered() {
      if (!this.filter.pickLocation && !this.filter.returnLocation) {
        this.branchesFiltered = this.branches;
      } else {
        if (this.filter.pickLocation) {
          this.branchesFiltered = [];
          this.branches.forEach((branch) => {
            if (branch.owner.id === this.filter.pickLocation.owner.id) {
              this.branchesFiltered.push(branch);
            }
          });
        }
        if (this.filter.returnLocation) {
          this.branchesFiltered = [];
          this.branches.forEach((branch) => {
            if (branch.owner.id === this.filter.returnLocation.owner.id) {
              this.branchesFiltered.push(branch);
            }
          });
        }
      }
      this.setDefaultDates();
    },
    searchBranchesFiltered(queryText) {
      this.query = queryText != null ? queryText : "";
    },
    closeDetailCard() {
      this.showPickUpCard = false;
      this.showReturnBackCard = false;
      this.query = "";
    },
    setPickUpDate(date) {
      if (date) {
        this.filter.pickDate = date;
        this.firstEntryPickUpDate = false;
      }
    },
    setPickUpTime(time) {
      this.filter.pickHour = time;
      if (this.$refs.pickerFinishDate) {
        this.$refs.pickerFinishDate.openHours();
      }
      // this.$refs.pickerFinishDate.openDate();
    },
    setReturnBackDate(date) {
      if (date) {
        this.filter.returnDate = date;
        this.firstEntryReturnDate = false;
      }
    },
    setReturnBackTime(time) {
      this.filter.returnHour = time;
    },
    setDefaultDates() {
      const defaultDate = DateUtil.addDate(
        null,
        this.currentCategory.pickUpAdvanceMargin,
        "hours"
      )
        .toString()
        .split(" ");

      let addDay = false;

      const time = defaultDate[1].split(":");
      let minutes = Number(time[1]);
      let hours = Number(time[0]);
      if (minutes < 30) {
        minutes = "30";
      } else {
        if (hours === 23) {
          addDay = true;
          hours = "00";
        } else {
          hours = hours + 1;
        }
        minutes = "00";
      }

      if (hours.toString().length === 1) {
        hours = `0${hours}`;
      }

      let formattedTime = `${hours}:${minutes}`;

      if (
        this.filters.hours &&
        !this.filters.hours.find((hour) => hour === formattedTime)
      ) {
        let nextHour = this.orderedHours.find(
          (orderedHour) =>
            (Number(orderedHour.split(":")[0]) === Number(hours) &&
              Number(orderedHour.split(":")[1]) >= Number(minutes)) ||
            Number(orderedHour.split(":")[0]) > Number(hours)
        );

        if (!nextHour) {
          nextHour = this.orderedHours[0];
          addDay = true;
        }
        formattedTime = nextHour;
      }

      if (!this.$refs.pickerInitialDate.hour) {
        this.$refs.pickerInitialDate.hour = formattedTime;
        this.filter.pickHour = formattedTime;
      }
      if (!this.$refs.pickerFinishDate.hour) {
        this.$refs.pickerFinishDate.hour = formattedTime;
        this.filter.returnHour = formattedTime;
      }

      this.filter.pickDate = defaultDate[0];
      let minDays = 1;
      if (Math.ceil(this.currentCategory.minTime / 24) > 1) {
        minDays = Math.ceil(this.currentCategory.minTime / 24);
      }
      this.filter.returnDate = moment(
        new Date(`${defaultDate[0]}T${formattedTime}`)
      )
        .add(minDays, "days")
        .format("YYYY-MM-DD");

      if (addDay) {
        this.filter.pickDate = DateUtil.addDate(
          this.filter.pickDate,
          1,
          "days"
        ).split(" ")[0];
        this.filter.returnDate = DateUtil.addDate(
          this.filter.returnDate,
          1,
          "days"
        ).split(" ")[0];
      }
    },
    openLocationCard(type) {
      if (type === "pick" && !this.isPickUpDisabled) {
        setTimeout(() => {
          this.showPickUpCard = true;
        }, 150);
      }
      if (type === "return" && !this.isReturnDisabled) {
        setTimeout(() => {
          this.showReturnBackCard = true;
        }, 150);
      }
    },
    changePickUpLocation(branch) {
      this.filter.pickLocation = branch;
      this.branchPickName = this.filter.pickLocation.name;
      this.showPickUpCard = false;
      this.showDirectionPickUpField = false;
      //CARMOVE HECHO A MEDIDA
      if (
        this.filter.pickLocation.owner.id === 62 &&
        this.filter.pickLocation.id === 91
      ) {
        this.showDirectionPickUpField = true;
        setTimeout(() => {
          document.getElementById("configurablePickUpAddressTextField").focus();
        }, 200);
      } else {
        this.showDirectionPickUpField = false;
        if (this.filter.otherBranch) {
          // document.getElementById("returnLocation").click();
        } else {
          this.$refs.pickerInitialDate.openDateCard();
        }
      }

      this.checkPickLocation();
    },
    changeReturnBackLocation(branch) {
      this.filter.returnLocation = branch;
      this.branchReturnName = this.filter.returnLocation.name;
      this.showReturnBackCard = false;
      this.showDirectionReturnBackField = false;
      //CARMOVE HECHO A MEDIDA
      if (
        this.filter.returnLocation.owner.id === 62 &&
        this.filter.returnLocation.id === 91
      ) {
        this.showDirectionReturnBackField = true;
        setTimeout(() => {
          document
            .getElementById("configurableReturnBackAddressTextField")
            .focus();
        }, 200);
      } else {
        this.$refs.pickerFinishDate.openDateCard();
      }
    },
    checkPickLocation() {
      if (this.filter.pickLocation && !this.filter.otherBranch) {
        this.filter.returnLocation = this.filter.pickLocation;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.speech-bubble {
  position: relative;
  background: #ffffff;
  border-radius: 0.4em;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  top: -12px;
  left: 100px;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-bottom-color: #ffffff;
  border-top: 0;
  margin-left: -20px;
  margin-top: 0px;
}
</style>
