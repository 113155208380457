<template>
  <div class="f2g-snackbar">
    <v-layout column>
      <v-snackbar
        v-for="(snackbar, index) in snackbarsArray.filter((item) => item.show)"
        :key="snackbar.id"
        :value="snackbar.show"
        :timeout="dialogTimeout"
        :color="snackbar.color"
        outlined
        class="mb-3 cursor-pointer"
        top
        right
        transition="slide-y-transition"
        :style="`top: ${index === 0 ? 60 : (index + 1) * 60 + 12 * index}px;`"
        @click.native="closeSnackbarDialog(snackbar)"
      >
        <v-layout column>
          <v-layout>
            <F2gIcon
              class="mr-2"
              solid
              file="icons_solid.svg"
              :fill="setIconColor(snackbar.color)"
              :value="setIconValue(snackbar.color)"
            />
            <span :style="`color: black`">{{ snackbar.text }}</span>
          </v-layout>
          <div class="pt-4">
            <v-progress-linear
              v-model="snackbar.progress"
              :buffer-value="100"
              :color="snackbar.color"
              rounded
              height="6px"
            />
          </div>
        </v-layout>
      </v-snackbar>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "F2gSnackbar",
  components: {
    F2gIcon: () => import("@/flit2go-web-core/components/ui/f2g-icon"),
  },
  props: {
    snackbarText: {
      type: String,
      required: false,
    },
    snackbarCode: {
      type: String,
      required: false,
      default: "#1c8523",
    },
  },
  data: () => ({
    snackbarColor: null,
    dialogTimeout: 100000,
    progressBar: 100,
    interval: null,
    id: 0,
    snackBarQeue: null,
    snackbarsArray: [],
    intervalsArray: [],
  }),
  methods: {
    closeSnackbarDialog(snackbar) {
      snackbar.show = false;
      clearInterval(snackbar.interval);
    },
    show(data) {
      var snackbarColor = this.setSnackbarColor(data.code);
      const id = this.snackbarsArray.length + 1;
      this.snackbarsArray.push({
        id: id,
        show: data.show,
        color: snackbarColor,
        text: data.text,
        progress: 100,
      });
      this.showSnackbar(id);
    },
    showSnackbar(id) {
      let snackbarDialog = this.snackbarsArray.find(
        (element) => element.id === id
      );
      setTimeout(() => (snackbarDialog.show = false), 4000);
      snackbarDialog.interval = setInterval(() => {
        if (snackbarDialog.progress <= 0) {
          snackbarDialog.show = false;
          clearInterval(snackbarDialog.interval);
        } else {
          snackbarDialog.progress = snackbarDialog.progress - 4.4;
        }
      }, 130);
    },

    setIconValue(iconCode) {
      let iconValue = "";

      switch (iconCode) {
        case "success":
          iconValue = "check-circle";
          break;
        case "warning":
          iconValue = "warning";
          break;
        case "error":
          iconValue = "priority-warning";
          break;
        case "info":
          iconValue = "info";
          break;
        default:
          iconValue = "check-circle";
          break;
      }
      return iconValue;
    },

    setIconColor(iconCode) {
      let iconColor = "";

      switch (iconCode) {
        case "success":
          iconColor = "#1c8523";
          break;
        case "warning":
          iconColor = "#F59217";
          break;
        case "error":
          iconColor = "#b71c1c";
          break;
        case "info":
          iconColor = "#2196f3";
          break;
        default:
          iconColor = "#1c8523";
          break;
      }
      return iconColor;
    },

    setSnackbarColor(snackbarCode) {
      let snackbarColor = null;
      switch (snackbarCode) {
        case "success":
          snackbarColor = "#1c8523";
          break;
        case "warning":
          snackbarColor = "warning";
          break;
        case "error":
          snackbarColor = "error";
          break;
        case "info":
          snackbarColor = "info";
          break;
        default:
          snackbarColor = "info";
          break;
      }
      return snackbarColor;
    },
  },
};
</script>

<style lang="scss">
.f2g-snackbar {
  .info-icon {
    top: 5px;
    left: 5px;
    position: absolute;
    cursor: pointer;
  }
  .snackbar {
    position: absolute;
    top: 60px;
    right: 10px;
  }
  .v-snack__wrapper {
    border: 2px solid !important;
    box-shadow: 0 23px 20px -20px rgba(23, 23, 24, 0.329),
      0 0 15px rgba(20, 22, 24, 0.247) !important;
  }
}
</style>
