import conn from "@/flit2go-web-core/services/connection";
const url =
  window.location !== window.parent.location
    ? document.location.ancestorOrigins
      ? document.location.ancestorOrigins[0]
      : document.referrer
    : document.location.origin;

export default {
  getConfigurationToken() {
    return {
      Authorization: localStorage.getItem(`apiToken-${url}`),
      "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
      "Accept-language": localStorage.getItem(`user-language`),
    };
  },
  getAvailableBranches(params) {
    return conn.$axios({
      url: `/v1/frameweb/branch/search`,
      method: "GET",
      params: params,
      headers: this.getConfigurationToken(),
    });
  },
  getBranchById(id) {
    return conn.$axios({
      url: `/v1/frame/branch/${id}`,
      method: "GET",
      headers: this.getConfigurationToken(),
    });
  },
  getBranchSpecialDays(idBranch) {
    return conn.$axios({
      url: `/v1/frame/branch/${idBranch}/specialday`,
      method: "GET",
      headers: this.getConfigurationToken(),
    });
  },
};
