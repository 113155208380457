<template>
  <div class="category-card">
    <div class="flex flex-row flex-wrap md:my-4">
      <div
        class="w-full lg:w-9/12 p-4 mb-3 flex flex-col align-center md:align-left md:flex-row relative rounded transition-all duration-500 border-black transform hover:shadow hover:-translate-y-1 cursor-pointer"
        :style="{ backgroundColor: colors.colorOnBackground }"
        @click="selectCategory"
      >
        <!-- CategoryImage -->
        <div class="h-48 min-w-min max-w-xs mx-2">
          <div class="bg-gray-200 w-48 lg:w-64 max-h-full rounded">
            <image-aspect-ratio
              class="flex-col-center mr-2"
              :border="false"
              :img="categoryRow.img"
              minHeight="12rem"
              maxHeight="12rem"
              minWidth="12rem"
              maxWidth="18rem"
            />
          </div>
        </div>
        <!-- Bordecico -->
        <div
          class="pt-3 rounded-none border-b-2 border-f2gGray w-xl md:w-2 md:max-h-full md:mx-3 md:border-b-0 md:border-r-2"
        />
        <!-- CategoryData -->
        <div class="mt-3">
          <!-- CategoryMinimumAge -->
          <div class="absolute -top-4 -right-1 lg:-right-5">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on" class="flex-row items-center">
                  <div
                    class="w-9 h-9 mr-2 flex items-center justify-center rounded-full"
                    :style="{ backgroundColor: colors.colorPrimary }"
                  >
                    <div :style="{ color: colors.colorOnPrimary }">
                      +{{ categoryRow.minimumAge }}
                    </div>
                  </div>
                </div>
              </template>
              <span>{{ $t("common.minimumAgeCar") }}</span>
            </v-tooltip>
          </div>
          <!-- CategoryName -->
          <h1
            class="text-2xl font-bold mb-2 ml-2"
            :style="{ color: colors.colorOnSurface }"
          >
            {{ categoryRow.name }}
          </h1>
          <!-- CategoryDesciption -->
          <div
            class="mb-6 ml-2"
            :style="{ color: colors.colorOnSurfaceMedium }"
            v-html="categoryRow.description[this.$i18n.locale.split('_')[0]]"
          ></div>
          <!-- CategoryAttributes -->
          <div>
            <div
              class="inline-block"
              v-for="(attribute, index) in categoryRow.attributeList"
              :key="index"
            >
              <div class="text-sm align-middle mr-2">
                <div class="pa-1 m-1 inline-block align-middle">
                  <svg
                    style="height: 24px; width: 24px"
                    :fill="colors.colorPrimary"
                  >
                    <path v-if="attribute.icon.path" :d="attribute.icon.path" />
                  </svg>
                </div>
                <div
                  class="align-center inline-block align-middle"
                  :style="{ color: colors.colorOnSurfaceMedium }"
                >
                  {{
                    attribute.supportedLangs[$i18n.locale.split("_")[0]]
                      ? attribute.supportedLangs[$i18n.locale.split("_")[0]]
                      : attribute.supportedLangs.en
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Price -->
      <div class="w-full lg:w-3/12 text-center p-2 lg:pl-4 lg:pt-16">
        <div
          class="text-3xl font-bold"
          :style="{ color: colors.colorOnSurface }"
        >
          {{ categoryRow.totalPrice ? categoryRow.totalPrice : "0" }}€
        </div>
        <div class="text-sm" :style="{ color: colors.colorOnSurfaceMedium }">
          {{
            categoryRow.pricePerTime
              ? categoryRow.pricePerTime.toFixed(2)
              : "0"
          }}€
          {{
            categoryRow.timeType == "day"
              ? $t("common.perDay")
              : $t("common.perHour")
          }}
        </div>
        <v-btn
          class="flex w-40 mt-4 mx-auto border-2 rounded-config30"
          outlined
          :color="colors.colorPrimary"
          @click="selectCategory"
        >
          Seleccionar
        </v-btn>
      </div>
    </div>
    <Separator class="mt-4 mb-10 md:hidden" />
  </div>
</template>

<script>
import Separator from "@/modules/v1/Components/separator";
import { mapGetters, mapState } from "vuex";
import ImageAspectRatio from "@/flit2go-web-core/components/ui/image-aspect-ratio";
import { EventBus } from "@/flit2go-web-core/util/event-bus.js";

export default {
  name: "CategoryCard",
  components: { Separator, ImageAspectRatio },
  props: {
    categoryRow: { required: true, type: Object, default: () => [] },
  },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  computed: {
    ...mapState(["category", "configuration"]),
    ...mapGetters({
      colors: "configuration/getColors",
    }),
  },
  methods: {
    selectCategory() {
      this.$store.commit("category/SET_CATEGORY", this.categoryRow);
      this.$store.commit("configuration/SET_EXTRAS_SELECTED", null);
      EventBus.$emit("getPrice");
      this.$emit("updateStep");
    },
  },
};
</script>

<style lang="scss" scoped></style>
