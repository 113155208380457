import conn from "./connection"

export default {
    loadPersistentNotifications(params) {
        return conn.$axios({
            url: `secured/v1/notification/search`,
            method: 'GET',
            params: params
        })
    },
    markNotiAsViewed(notiId, viewed) {
        return conn.$axios({
            url: `secured/v1/notifications/${notiId}/view/${viewed}`,
            method: 'PUT'
        })
    },
    markSelectedNotificationsAsSeen(selectedNotifications, viewed) {
        return conn.$axios({
            url: `secured/v1/notifications/view/list/${viewed}`,
            method: 'PUT',
            data: selectedNotifications
            
        })
    },
    deleteNotification(notificationId) {
        return conn.$axios({
            url: `secured/v1/notifications/${notificationId}`,
            method: 'DELETE'
        })
    },
    deleteSelectedNotifications(selectedNotifications) {
        return conn.$axios({
            url: `secured/v1/notifications/list`,
            method: 'DELETE',
            data: selectedNotifications
            
        })
    }
}