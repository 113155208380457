<template>
  <div class="resume-data">
    <div class="flex flex-col p-2">
      <h2 class="font-bold" :style="{ color: colors.colorOnSurface }">
        {{ $t("common.pickUp") }}
      </h2>
      <div class="">
        <div>
          <F2gIcon
            class="inline-block align-middle mt-2 mx-2"
            file="icons_solid.svg"
            :fill="colors.colorPrimary"
            solid
            :value="'location'"
            :height="20"
            :width="20"
            :strokeWidth="4"
          />
          <div
            class="inline-block align-middle mt-2 text-sm"
            :style="{ color: colors.colorOnSurfaceMedium }"
          >
            {{ bookingFilters.pickUpNameBranch }}
          </div>
        </div>
        <div>
          <F2gIcon
            class="inline-block align-middle mt-2 ml-2 mr-1"
            :fill="colors.colorPrimary"
            :stroke="colors.colorPrimary"
            :value="'agenda'"
            :height="16"
            :width="24"
            :strokeWidth="4"
          />
          <div
            class="inline-block align-middle mt-2 text-sm"
            :style="{ color: colors.colorOnSurfaceMedium }"
          >
            {{ DateUtil.formatCustomDate(bookingFilters.pickUpDate) }}
          </div>
        </div>
      </div>
      <h2 class="font-bold mt-4" :style="{ color: colors.colorOnSurface }">
        {{ $t("common.returnBack") }}
      </h2>
      <div
        class="max-h-full"
        :class="{
          'min-h-1/7': categorySelected && categorySelected.id,
          'mb-4': !categorySelected.id,
        }"
      >
        <div>
          <div>
            <F2gIcon
              class="inline-block align-middle mt-2 mx-2"
              file="icons_solid.svg"
              :fill="colors.colorPrimary"
              solid
              :value="'location'"
              :height="20"
              :width="20"
              :strokeWidth="4"
            />
            <div
              class="inline-block align-middle mt-2 text-sm"
              :style="{ color: colors.colorOnSurfaceMedium }"
            >
              {{ bookingFilters.returnBackNameBranch }}
            </div>
          </div>
          <div>
            <F2gIcon
              class="inline-block align-middle mt-2 ml-2 mr-1"
              :stroke="colors.colorPrimary"
              :value="'agenda'"
              :height="16"
              :width="24"
              :strokeWidth="4"
            />
            <div
              class="inline-block align-middle mt-2 text-sm"
              :style="{ color: colors.colorOnSurfaceMedium }"
            >
              {{ DateUtil.formatCustomDate(bookingFilters.returnBackDate) }}
            </div>
          </div>
          <div
            class="flex flex-row mt-4"
            v-if="categorySelected && categorySelected.id"
            :class="{ 'mb-6': !bookingInfo }"
          >
            <div class="h-16 mr-2">
              <div class="bg-gray-100 w-24 h-16">
                <image-aspect-ratio
                  class="flex-col-center mr-2"
                  :img="categorySelected.img"
                  minHeight="4rem"
                  maxHeight="4rem"
                  min-width="6rem"
                  maxWidth="6rem"
                />
              </div>
            </div>
            <div class="flex-col justify-center">
              <h1
                class="text-base text--bold"
                :style="{ color: colors.colorOnSurface }"
              >
                {{ categorySelected.name }}
              </h1>
              <div
                class="text-base font-bold"
                :style="{ color: colors.colorOnSurfaceMedium }"
              >
                {{
                  categorySelected.pricePerTime
                    ? categorySelected.pricePerTime.toFixed(2)
                    : 0
                }}€<span class="text-xs">
                  {{
                    categorySelected.timeType == "day"
                      ? $t("common.perDay")
                      : $t("common.perHour")
                  }}</span
                >
              </div>
            </div>
          </div>
          <span v-if="bookingInfo" class="max-h-full mb-4">
            <div
              class="flex flex-row mt-4 ml-3 justify-between"
              v-if="bookingInfo.priceBase.value"
            >
              <span
                class="mt-2 text-sm"
                :style="{ color: colors.colorOnSurfaceMedium }"
                >{{
                  $t("common.booking") +
                  " ( " +
                  bookingDays() +
                  " " +
                  (bookingDays() > 1 ? $t("common.days") : $t("common.day")) +
                  " )"
                }}</span
              >
              <span
                class="mt-2 text-sm"
                :style="{ color: colors.colorOnSurfaceMedium }"
                >{{
                  BillingUtil.formatBillingValue(
                    bookingInfo.priceBase.value,
                    bookingInfo.priceBase.currency
                  )
                }}</span
              >
            </div>
            <div
              class="flex flex-row ml-3 justify-between"
              v-if="franchiseSelected"
            >
              <span
                class="mt-2 text-sm"
                :style="{ color: colors.colorPrimary }"
                >{{ $t("common.franchise") }}</span
              >
            </div>
            <div
              class="flex flex-row ml-3 justify-between"
              v-if="franchiseSelected"
            >
              <span
                class="mt-2 text-sm"
                :style="{ color: colors.colorOnSurfaceMedium }"
                >{{ selectLabelFranchise(franchiseSelected) }}</span
              >
              <span
                class="mt-2 text-sm"
                :style="{ color: colors.colorOnSurfaceMedium }"
                >{{
                  BillingUtil.formatBillingValue(
                    bookingInfo.priceFranchise.value,
                    bookingInfo.priceFranchise.currency
                  )
                }}</span
              >
            </div>
            <div
              class="flex flex-row ml-3 justify-between"
              v-if="bookingInfo.priceExtras.value"
            >
              <span
                class="mt-2 text-sm"
                :style="{ color: colors.colorPrimary }"
                >{{ $t("common.extraEquipment") }}</span
              >
            </div>
            <span
              v-for="(extra, index) in extrasSelected"
              :key="'extra' + index"
              :style="{ color: colors.colorOnSurfaceMedium }"
            >
              <div
                class="flex flex-row ml-3 justify-between"
                v-if="bookingInfo.priceExtras.value"
              >
                <span class="mt-2 text-smk">{{ extra.name }}</span>
                <span class="mt-2 text-sm">{{
                  BillingUtil.formatBillingValue(
                    getExtraPrice(extra),
                    bookingInfo.priceExtras.currency
                  )
                }}</span>
              </div>
            </span>
            <div
              class="flex flex-row ml-3 justify-between"
              v-if="bookingInfo.priceServices.value"
            >
              <span
                class="mt-2 text-sm"
                :style="{ color: colors.colorPrimary }"
                >{{ $t("common.additionalServices") }}</span
              >
            </div>
            <span
              v-for="(additional, index) in additionalsSelected"
              :key="'add' + index"
              :style="{ color: colors.colorOnSurfaceMedium }"
            >
              <div
                class="flex flex-row ml-3 justify-between"
                v-if="bookingInfo.priceServices.value"
              >
                <span class="mt-2 text-sm">{{
                  additional.type.name[$i18n.locale.split("_")[0]]
                }}</span>
                <span class="mt-2 text-sm">
                  {{
                    BillingUtil.formatBillingValue(
                      additional.numberPackages
                        ? additional.numberPackages * getExtraPrice(additional)
                        : getExtraPrice(additional),
                      bookingInfo.priceServices.currency
                    )
                  }}</span
                >
              </div>
            </span>
            <div
              class="flex flex-row mt-10 ml-3 justify-between"
              :style="{ color: colors.colorOnSurfaceMedium }"
              v-if="bookingInfo.priceTotal.value"
            >
              <span class="mt-2 text-sm"
                >{{ $t("common.iva") }} ({{
                  bookingInfo.priceTotal.tax
                }}%)</span
              >
              <span class="mt-2 text-sm"
                >{{
                  (bookingInfo.priceTotal.value * bookingInfo.priceTotal.tax) /
                  100
                }}€</span
              >
            </div>
            <div
              class="flex flex-row ml-3 justify-between"
              :style="{ color: colors.colorOnSurfaceMedium }"
              v-if="bookingInfo.priceTotal.value"
            >
              <span class="mt-2 text-sm">{{ $t("common.discount") }}</span>
              <span class="mt-2 text-sm">{{ "-" }}</span>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageAspectRatio from "@/flit2go-web-core/components/ui/image-aspect-ratio";
import { mapGetters } from "vuex";
import DateUtil from "@/flit2go-web-core/util/dateUtil";
import { EventBus } from "@/flit2go-web-core/util/event-bus.js";

export default {
  name: "ResumeData",
  components: { ImageAspectRatio },
  props: {},
  data() {
    return {
      DateUtil,
      bookingInfo: null,
      hours: null,
    };
  },
  mounted() {
    if (
      this.franchiseSelected &&
      this.categorySelected &&
      this.bookingFilters
    ) {
      this.getPrice();
    }
    EventBus.$on("getPrice", () => {
      this.getPrice();
    });
  },
  computed: {
    ...mapGetters({
      bookingFilters: "configuration/getBookingFilters",
      additionalsSelected: "configuration/getAdditionalsSelected",
      extrasSelected: "configuration/getExtrasSelected",
      franchiseSelected: "category/franchiseSelected",
      categorySelected: "category/categorySelected",
      colors: "configuration/getColors",
    }),
  },
  methods: {
    getPrice() {
      if (
        this.franchiseSelected &&
        this.categorySelected &&
        this.bookingFilters
      ) {
        let data = {};
        data.categoryId = this.categorySelected.id;
        data.data = {
          pickUpDate: this.bookingFilters.pickUpDate,
          returnBackDate: this.bookingFilters.returnBackDate,
          pickUpBranch: this.bookingFilters.pickUpBranch,
          returnBackBranch: this.bookingFilters.returnBackBranch,
          franchise: this.franchiseSelected.type,
          extra: [],
          additionServices: [],
        };
        if (this.extrasSelected[0]) {
          Object.keys(this.extrasSelected).forEach((extra) => {
            data.data.extra.push(this.extrasSelected[extra].id);
          });
        }

        if (this.additionalsSelected[0]) {
          Object.entries(this.additionalsSelected).forEach(([key, value]) => {
            data.data.additionServices.push(value);
          });
        }

        this.$store
          .dispatch("configuration/getPrice", data)
          .then((resp) => {
            this.bookingInfo = resp.data;
            let totalPrice =
              this.bookingInfo.priceTotal.value +
              (this.bookingInfo.priceTotal.value *
                this.bookingInfo.priceTotal.tax) /
                100;
            this.$emit("priceTotal", totalPrice);
          })
          .catch(() => {
            this.$showMessage.error(this.$t("errors.generic"));
          });
      }
    },
    bookingDays() {
      let timeHours =
        (this.bookingFilters.returnBackDate - this.bookingFilters.pickUpDate) /
        3600000;
      if (timeHours < 8) {
        this.hours = timeHours;
      }
      let timeDays = parseInt(timeHours / 24);
      let timeHoursRemainder = timeHours % 24;
      if (timeHoursRemainder > 2) {
        timeDays = timeDays + 1;
      }
      return timeDays;
    },
    selectLabelFranchise(franchise) {
      if (franchise.type == 2) {
        return this.$t("common.withoutReductionFranchise");
      }
      if (franchise.type == 1) {
        return this.$t("common.withReductionFranchise", {
          number: franchise.franchisePrice,
        });
      }
      if (franchise.type == 0) {
        return this.$t("common.withoutFranchise");
      }
    },
    getExtraPrice(extra) {
      if (extra.priceModality.code == "PRD") {
        return extra.price * this.bookingDays();
      } else if (extra.priceModality.code == "PRH") {
        if (this.hours) {
          return extra.price * this.hours;
        } else {
          return extra.price * 24 * this.bookingDays();
        }
      } else {
        return extra.price;
      }
    },
  },
};
</script>
