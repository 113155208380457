import service from '@/flit2go-web-core/services/'

export default {
    namespaced: true,
    state: {
        status: ''
    },
    mutations: {
        ['STATUS_SUCCESS']: (state) => {
            state.status = 'success'
        },
        ['STATUS_LOADING']: (state) => {
            state.status = 'loading'
        },
        ['STATUS_ERROR']: (state) => {
            state.status = 'error'
        }
    },
    actions: {
        changePassword({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('STATUS_LOADING')
                service.profile.changePassword(data)
                    .then(resp => {
                        commit('STATUS_SUCCESS')
                        resolve(resp)
                    })
                    .catch((err) => {
                        commit('STATUS_ERROR')
                        reject(err)
                    })
            })
        },
    }     
}