<template>
  <div class="f2gfw-custom-stepper w-full">
    <div
      class="w-full lg:h-16 mt-8 rounded-t-lg rounded-b-lg relative shadow"
      :class="userLoged.id ? 'h-32' : 'h-20'"
      :style="{ backgroundColor: colors.colorHeader }"
    >
      <div
        class="w-full max-h-full relative flex rounded-t-lg rounded-b-lg justify-between align-start lg:align-end pb-1 px-3"
        :class="
          $vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'
            ? 'pt-10'
            : 'pt-8'
        "
        :style="{ backgroundColor: colors.colorHeader }"
      >
        <div class="text-xl pb-2" :style="{ color: colors.colorOnHeader }">
          {{ stepList[currentStep - 1].label }}
        </div>
        <div class="inline-flex">
          <div
            v-if="
              $vuetify.breakpoint.name === 'xs' ||
                $vuetify.breakpoint.name === 'sm'
            "
            class="rounded-full h-7 w-7 mr-2 justify-center align-center flex shadow"
            :style="{ backgroundColor: colors.colorSurfaceMedium }"
            @click="showResume = !showResume"
          >
            <F2gIcon
              :height="20"
              :width="20"
              :stroke="colors.colorOnSurfaceMedium"
              class="cursor-pointer p-1"
              :value="showResume ? 'arrow-up' : 'arrow-down'"
              :strokeWidth="5"
            />
          </div>
          <div
            v-if="currentStep === 1 && !isCatalogue"
            class="rounded-full h-7 w-7 mr-3 justify-center align-center flex shadow"
            :style="{ backgroundColor: colors.colorSurfaceMedium }"
            @click="showSearch = !showSearch"
          >
            <F2gIcon
              :height="16"
              :width="16"
              :stroke="colors.colorOnSurfaceMedium"
              class="cursor-pointer p-3"
              :value="'search'"
              :strokeWidth="5"
            />
          </div>
          <div
            v-if="
              userLoged.id &&
                $vuetify.breakpoint.name !== 'xs' &&
                $vuetify.breakpoint.name !== 'sm'
            "
            class="text-xl flex"
          >
            <span class="mr-4" :style="{ color: colors.colorOnHeader }">{{
              $t("helloUser", {
                user: `${userLoged.name} ${userLoged.surnames}`,
              })
            }}</span>
            <div
              class="rounded-full justify-center align-center flex shadow pt-1 cursor-pointer"
              style="height: 28px; width: 28px"
              v-ripple
              :style="{ backgroundColor: colors.colorSurfaceMedium }"
              @click="showLogout"
            >
              <F2gIcon
                :height="16"
                :width="16"
                :stroke="colors.colorOnHeader"
                value="logout"
                :tooltip="$t('common.logout')"
                :strokeWidth="2"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="
          userLoged.id &&
            ($vuetify.breakpoint.name === 'xs' ||
              $vuetify.breakpoint.name === 'sm')
        "
        class="p-2 text-xl flex items-center pb-1 px-3 rounded-t-lg rounded-b-lg"
      >
        <span class="mr-4" :style="{ color: colors.colorOnHeader }">{{
          $t("helloUser", {
            user: `${userLoged.name} ${userLoged.surnames}`,
          })
        }}</span>
        <div
          class="rounded-full justify-center align-center shadow p-2 cursor-pointer"
          style="height: 28px; width: 28px"
          :style="{ backgroundColor: colors.colorSurfaceMedium }"
          v-ripple
          @click="showLogout"
        >
          <F2gIcon
            :height="16"
            :width="16"
            :stroke="colors.colorOnHeader"
            value="logout"
            :tooltip="$t('common.logout')"
            :strokeWidth="2"
          />
        </div>
      </div>
    </div>
    <div
      class="absolute top-3 left-0 flex flex-row justify-around w-full w-full lg:w-9/12"
    >
      <div v-for="(step, index) in stepList" :key="index">
        <div
          @click="changeCurrentStep(step.id)"
          class="w-12 h-12 rounded-full flex justify-center align-center border-2 border-white text-white font-bold cursor-pointer shadow"
          :style="
            `background-color: ${
              step.id === currentStep
                ? colors.colorPrimary
                : colors.colorSurface
            }`
          "
        >
          <div
            :style="
              `color: ${
                step.id === currentStep
                  ? colors.colorOnPrimary
                  : colors.colorOnSurface
              }`
            "
          >
            {{ step.id }}
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <ResumeData
        v-if="
          showResume &&
            ($vuetify.breakpoint.name === 'xs' ||
              $vuetify.breakpoint.name === 'sm')
        "
        :currentStep="currentStep"
        :isCatalogue="isCatalogue"
        :showNextStep="showNextStep"
        :loading="loading"
        :servicesValidation="servicesValidation"
        @showExtras="$emit('showExtras')"
        @currentStep="
          (value) => {
            $emit('currentStep', value);
          }
        "
      />
    </transition>
    <transition name="fade">
      <v-text-field
        v-if="showSearch && currentStep === 1"
        v-on:input="debounceInput"
        class="mx-4 mt-2"
        :label="$t('common.search')"
        outlined
        solo
        clearable
        hide-details
        v-model="search"
        prepend-inner-icon="search"
        @click:clear="search = ''"
      ></v-text-field
    ></transition>
    <slot :name="`content${currentStep}`"> </slot>
    <!-- <div
      class="flex bottom-0 justify-between align-end w-full mt-2"
      :class="{
        'absolute ':
          $vuetify.breakpoint.name !== 'xs' &&
          $vuetify.breakpoint.name !== 'sm',
      }"
    >
      <div class="version mt-4 ml-6">v.{{ version }}</div>
      <img
        class="flit2go-logo z-5 mr-8"
        width="100"
        height="40"
        src="@/flit2go-web-core/assets/powered-new-logo.svg"
        alt="f2gLogo"
      />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ResumeData from "@/modules/v2/Components/resume-data.vue";
import _ from "lodash";
import { EventBus } from "@/flit2go-web-core/util/event-bus.js";

export default {
  name: "F2gFWCustomStepper",
  props: {
    stepList: { required: true, type: Array, default: new Array() },
    currentStep: { required: false, type: Number, default: 1 },
    loading: { required: false, type: Boolean, default: false },
    servicesValidation: { required: false, type: Boolean, default: true },
    showNextStep: { required: false, type: Boolean, default: false },
    isCatalogue: { required: false, type: Boolean, default: false },
  },
  components: {
    ResumeData,
  },
  data: () => ({
    showResume: true,
    showSearch: false,
    search: "",
    version: process.env.PACKAGE_VERSION || "0",
  }),
  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
      categorySelected: "category/categorySelected",
      userLoged: "user/userLoged",
    }),
  },
  watch: {},
  methods: {
    showLogout() {
      EventBus.$emit("openLogout");
    },
    // eslint-disable-next-line
    debounceInput: _.debounce(function() {
      this.sendSearchFilter();
    }, 500),
    sendSearchFilter() {
      this.$emit("search", this.search);
    },
    changeCurrentStep(newStep) {
      if (this.currentStep > newStep) {
        if (this.isCatalogue) {
          if (newStep === 1) {
            this.$store.commit("configuration/SET_EXTRAS_SELECTED", null);
            this.$store.commit("category/SET_FRANCHISE_SELECTED", null);
          }
          this.$emit("currentStep", newStep);
        } else {
          if (newStep === 1) {
            this.$store.commit("category/SET_CATEGORY", this.categoryRow);
            this.$store.commit("configuration/SET_EXTRAS_SELECTED", null);
            this.$store.commit("configuration/SET_ACTUAL_PRICE", null);
            this.$store.commit("category/SET_FRANCHISE_SELECTED", null);
          }
          if (newStep === 2) {
            this.$store.commit("configuration/SET_EXTRAS_SELECTED", null);
            this.$store.commit("configuration/SET_ACTUAL_PRICE", null);
            this.$store.commit("category/SET_FRANCHISE_SELECTED", null);
          }
          this.$emit("currentStep", newStep);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.version {
  color: $gray-1-f2g;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
