const ISSUE_STATUS = Object.freeze({
    "ACTIVE": "ACT",
    "DECLINED": "DEN",
    "PENDING_VALIDATION": "PEN",
    "REPAIRED": "REP",
    "IN_PROGRESS": "INP",
    "RESOLVED": "RES",
    "DUPLICATED": "DUP"
});

const ISSUE_TYPE = Object.freeze({
    "OTHER": "OTH",
    "TRAVEL": "TRA",
    "SUGESTION": "SUG",
    "INQUIRY": "QUE",
    "INCIDENCE": "INC",
});

export default { STATUS: ISSUE_STATUS, TYPE: ISSUE_TYPE }