const DAMAGE_TYPE = Object.freeze({
  INTERNAL: "INT",
  EXTERNAL: "EXT",
});
const DAMAGE_STATUS = Object.freeze({
  NOT_REPAIRED: "ACT",
  DENIED: "DEN",
  PENDING_VALIDATION: "PEN",
  PENDING_PHOTO: "PNP",
  PENDING_REPAIR: "PRE",
  REPAIRED: "REP",
});

export default {
  TYPE: DAMAGE_TYPE,
  STATUS: DAMAGE_STATUS,
};
