import service from "@/services/configurationService";
import i18n from "@/flit2go-web-core/lang/";

export default {
  namespaced: true,
  state: {
    owner: null,
    ownerName: "",
    ownerTimezone: "",
    superowner: false,
    logo: "",
    paymentPlatform: null,
    contact: {
      phoneNumber: null,
      email: null,
    },
    vehicles: null,
    status: "",
    language: "en",
    colors: {
      colorBackground: "",
      colorHeader: "",
      colorOnBackground: "",
      colorOnHeader: "",
      colorOnPrimary: "",
      colorOnSurface: "",
      colorOnSurfaceMedium: "",
      colorPrimary: "",
      colorSurface: "",
      colorSurfaceMedium: "",
    },
    labels: {
      textEmail: "",
      textGratitude: "",
      textSubtitle: "",
      textTitle: "",
    },
    bookingFilters: {
      pickUpDate: null,
      returnBackDate: null,
      pickUpBranch: null,
      returnBackBranch: null,
      pickUpDirectionBranch: null,
      returnBackDirectionBranch: null,
      pickUpLogo: null,
      returnLogo: null,
    },
    additionalsSelected: {},
    extrasSelected: {},
    actualPrice: null,
    trackNumberHubSpot: null,
    clientifyActived: null,
    filters: {
      showAgeFilter: false,
      showVehicleFilter: false,
      allowReturnInAnotherBranch: null,
      showTitle: true,
      showLogo: true,
      billingAddressRequired: false,
      dniRequired: false,
      currency: null,
      currencyCode: null,
      // searchByBranch: false,
      // searchByCity: false,
      hours: null,
      //city: null,
      showBranchAddress: false,
      gtagCode: null,
      googlePlacesApiKey: null,
      addressCenter: null,
      addressCenterLatitude: null,
      addressCenterLongitude: null,
      addressCenterRadius: null,
      addressCenterError: null,
      showPriceFilter: false,
      showOrdination: false,
      showCategoryFilter: false,
      showLocationFilter: false,
    },
    agencyCode: false,
    redsysConfiguration: null,
    userFilters: {},
    gtagCode: null,
  },

  getters: {
    getLogo: (state) => {
      return state.logo;
    },
    getContact: (state) => {
      return state.contact;
    },
    getVehiclesFilter: (state) => {
      return state.vehicles;
    },
    getSuperowner: (state) => {
      return state.superowner;
    },
    getOwner: (state) => {
      return state.owner;
    },
    getOwnerTimezone: (state) => {
      return state.ownerTimezone;
    },
    getColors: (state) => {
      return state.colors;
    },
    getUserLanguage: (state) => {
      return state.language;
    },
    getLabels: (state) => {
      return state.labels;
    },
    getBookingFilters: (state) => {
      return state.bookingFilters;
    },
    getAdditionalsSelected: (state) => {
      return state.additionalsSelected;
    },
    getExtrasSelected: (state) => {
      return state.extrasSelected;
    },
    getActualPrice: (state) => {
      return state.actualPrice;
    },
    getTrackNumberHubSpot: (state) => {
      return state.trackNumberHubSpot;
    },
    getGtagCode: (state) => {
      return state.gtagCode;
    },
    getFilters: (state) => {
      return state.filters;
    },
    getFilterInformationUsers: (state) => {
      return state.userFilters;
    },
    getRedsysConfiguration: (state) => {
      return state.redsysConfiguration;
    },
    getOrderedHours: (state) => {
      return state.filters.hours
        ? [
            "00:00",
            "00:30",
            "01:00",
            "01:30",
            "02:00",
            "02:30",
            "03:00",
            "03:30",
            "04:00",
            "04:30",
            "05:00",
            "05:30",
            "06:00",
            "06:30",
            "07:00",
            "07:30",
            "08:00",
            "08:30",
            "09:00",
            "09:30",
            "10:00",
            "10:30",
            "11:00",
            "11:30",
            "12:00",
            "12:30",
            "13:00",
            "13:30",
            "14:00",
            "14:30",
            "15:00",
            "15:30",
            "16:00",
            "16:30",
            "17:00",
            "17:30",
            "18:00",
            "18:30",
            "19:00",
            "19:30",
            "20:00",
            "20:30",
            "21:00",
            "21:30",
            "22:00",
            "22:30",
            "23:00",
            "23:30",
          ].filter(
            (hour) =>
              state.filters.hours.findIndex(
                (hourFilter) => hour === hourFilter
              ) > -1
          )
        : [];
    },
  },

  mutations: {
    SET_LOGO: (state, logo) => {
      state.logo = logo;
    },
    SET_CONTACT: (state, contact) => {
      state.contact.phoneNumber = contact.phoneNumber;
      state.contact.email = contact.email;
    },
    SET_VEHICLES: (state, vehicles) => {
      state.vehicles = vehicles;
    },
    SET_COLORS: (state, configuration) => {
      state.colors.colorBackground = configuration.colorBackground;
      state.colors.colorHeader = configuration.colorHeader;
      state.colors.colorOnBackground = configuration.colorOnBackground;
      state.colors.colorOnHeader = configuration.colorOnHeader;
      state.colors.colorOnPrimary = configuration.colorOnPrimary;
      state.colors.colorOnSurface = configuration.colorOnSurface;
      state.colors.colorOnSurfaceMedium = configuration.colorOnSurfaceMedium;
      state.colors.colorPrimary = configuration.colorPrimary;
      state.colors.colorSurface = configuration.colorSurface;
      state.colors.colorSurfaceMedium = configuration.colorSurfaceMedium;
      localStorage.setItem("colors", JSON.stringify(state.colors));
    },

    SET_LABELS: (state, configuration) => {
      state.labels.textEmail = configuration.textEmail;
      state.labels.textGratitude = configuration.textGratitude;
      state.labels.textSubtitle = configuration.textSubtitle;
      state.labels.textTitle = configuration.textTitle;
      localStorage.setItem("labels", JSON.stringify(state.labels));
    },
    SET_PAYMENTPLATFORM: (state, configuration) => {
      state.paymentPlatform = configuration.paymentPlatform;
    },

    SET_SUPEROWNER: (state, configuration) => {
      state.superowner = configuration.showAllCompanies;
    },
    SET_OWNER: (state, configuration) => {
      state.owner = configuration.owner;
      state.ownerName = configuration.ownerName;
      state.ownerTimezone = configuration.ownerTimezone;
    },

    // Status
    STATUS_LOADING: (state) => {
      state.status = "loading";
    },
    STATUS_SUCCESS: (state) => {
      state.status = "success";
    },
    STATUS_ERROR: (state) => {
      state.status = "error";
    },
    SET_BOOKING_FILTERS: (state, filters) => {
      state.bookingFilters = Object.assign({}, filters);
    },
    SET_ADDITIONALS_SELECTED: (state, additionals) => {
      state.additionalsSelected = Object.assign({}, additionals);
    },
    SET_EXTRAS_SELECTED: (state, extras) => {
      state.extrasSelected = Object.assign({}, extras);
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language;
    },
    CHANGE_LANG: (state, lang) => {
      if (lang.includes("-")) lang = lang.replace("-", "_");
      if (
        lang != "es_ES" &&
        lang != "it_IT" &&
        lang != "fr_FR" &&
        lang != "pt_PT"
      ) {
        lang = "en_GB";
      }
      state.language = lang;
      i18n.locale = lang;
      localStorage.setItem("user-language", lang);
      document.querySelector("html").setAttribute("lang", lang);
    },
    SET_ACTUAL_PRICE: (state, price) => {
      state.actualPrice = Object.assign({}, price);
    },
    SET_CONFIGURATION_HUBSPOT: (state, key) => {
      state.trackNumberHubSpot = key.trackNumberHubSpot;
      state.clientifyActived = key.clientifyActived;
    },
    SET_CONFIGURATION_GTAG: (state, key) => {
      state.gtagCode = key.gtagCode;
    },
    SET_SHOW_FILTERS: (state, filter) => {
      state.filters.showAgeFilter = filter.showAgeFilter;
      state.filters.showVehicleFilter = filter.showVehicleFilter;
      state.filters.allowReturnInAnotherBranch =
        filter.allowReturnInAnotherBranch;
      state.filters.showTitle = filter.showTitle;
      state.filters.showLogo = filter.showLogo;
      state.filters.billingAddressRequired = filter.billingAddressRequired;
      state.filters.dniRequired = filter.dniRequired;
      state.filters.currency = filter.currency;
      state.filters.currencyCode = filter.currencyCode;
      //state.filters.searchByBranch = filter.searchByBranch;
      //state.filters.searchByCity = filter.searchByCity;
      state.filters.hours =
        filter.hours && filter.hours.length > 0
          ? filter.hours.split(",")
          : null;
      // state.filters.city = filter.city && filter.city.length > 0 ? filter.city.split(",") : null;
      state.filters.showBranchAddress = filter.showBranchAddress;
      state.filters.gtagCode = filter.gtagCode;
      state.filters.googlePlacesApiKey = filter.googlePlacesApiKey;
      state.filters.addressCenter = filter.addressCenter;
      state.filters.addressCenterLatitude = filter.addressCenterLatitude;
      state.filters.addressCenterLongitude = filter.addressCenterLongitude;
      state.filters.addressCenterRadius = filter.addressCenterRadius;
      state.filters.addressCenterError = filter.addressCenterError;
      state.filters.showPriceFilter = filter.showPriceFilter;
      state.filters.showOrdination = filter.showOrdination;
      state.filters.showCategoryFilter = filter.showCategoryFilter;
      state.filters.showLocationFilter = filter.showLocationFilter;
    },
    SET_AGENCY_CODE: (state, data) => {
      state.agencyCode = data.agencyCode;
    },
    SET_REDSYS_CONFIGURATION: (state, data) => {
      state.redsysConfiguration = data.configurationRedsysDto;
    },
    SET_FILTER_INFORMATION_USERS: (state, data) => {
      state.userFilters = {
        bornDateMandatoryType: data.bornDateMandatoryType
          ? data.bornDateMandatoryType.code
          : "NAP",
        countryMandatoryType: data.countryMandatoryType
          ? data.countryMandatoryType.code
          : "NAP",
        directionMandatoryType: data.directionMandatoryType
          ? data.directionMandatoryType.code
          : "NAP",
        directionAddInfoMandatoryType: data.directionAddInfoMandatoryType
          ? data.directionAddInfoMandatoryType.code
          : "NAP",
        addressCountryMandatoryType: data.addressCountryMandatoryType
          ? data.addressCountryMandatoryType.code
          : "NAP",
        cityMandatoryType: data.cityMandatoryType
          ? data.cityMandatoryType.code
          : "NAP",
        localityMandatoryType: data.localityMandatoryType
          ? data.localityMandatoryType.code
          : "NAP",
        postalCodeMandatoryType: data.postalCodeMandatoryType
          ? data.postalCodeMandatoryType.code
          : "NAP",
        flightMandatoryType: data.flightMandatoryType
          ? data.flightMandatoryType.code
          : "NAP",
        destinationMandatoryType: data.destinationMandatoryType
          ? data.destinationMandatoryType.code
          : "NAP",
        nifMandatoryType: data.nifMandatoryType
          ? data.nifMandatoryType.code
          : "NAP",
        nifValidityDateMandatoryType: data.nifValidityDateMandatoryType
          ? data.nifValidityDateMandatoryType.code
          : "NAP",
        nifPhotosMandatoryType: data.nifPhotosMandatoryType
          ? data.nifPhotosMandatoryType.code
          : "NAP",
        drivingLicenseMandatoryType: data.drivingLicenseMandatoryType
          ? data.drivingLicenseMandatoryType.code
          : "NAP",
        drivingValidityDateMandatoryType: data.drivingValidityDateMandatoryType
          ? data.drivingValidityDateMandatoryType.code
          : "NAP",
        drivingPhotosMandatoryType: data.drivingPhotosMandatoryType
          ? data.drivingPhotosMandatoryType.code
          : "NAP",
        expeditionLicenseBMandatoryType: data.expeditionLicenseBMandatoryType
          ? data.expeditionLicenseBMandatoryType.code
          : "NAP",
        expeditionLicenseAMandatoryType: data.expeditionLicenseAMandatoryType
          ? data.expeditionLicenseAMandatoryType.code
          : "NAP",
        licenseRangeMandatoryType: data.licenseRangeMandatoryType
          ? data.licenseRangeMandatoryType.code
          : "NAP",
      };
    },
  },

  actions: {
    customerStripe({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .customerStripe(data)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getApiKeyStripe({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getApiKeyStripe(params)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },

    getPrivacy({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getPrivacy(data)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },

    getTerms({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getTerms(data)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },

    getIntentStripe({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getIntentStripe(data)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },

    newPayment({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .newPayment(data)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getPrice({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getPrice(data)
          .then((resp) => {
            commit("SET_ACTUAL_PRICE", resp.data);
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    createBooking({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .createBooking(data)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            commit("STATUS_ERROR");
          });
      });
    },
    createReserve({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .createReserve(data)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            commit("STATUS_ERROR");
          });
      });
    },
    getConfiguration({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getConfiguration(params)
          .then((resp) => {
            commit("SET_PAYMENTPLATFORM", resp.data);
            commit("SET_LABELS", resp.data);
            commit("SET_SUPEROWNER", resp.data);
            commit("SET_OWNER", resp.data);
            commit("SET_COLORS", resp.data);
            commit("SET_LOGO", resp.data.logo);
            commit("SET_CONTACT", resp.data);
            commit("SET_VEHICLES", resp.data.vehicles);
            commit("SET_SHOW_FILTERS", resp.data);
            commit("SET_CONFIGURATION_HUBSPOT", resp.data);
            commit("SET_CONFIGURATION_GTAG", resp.data);
            commit("SET_AGENCY_CODE", resp.data);
            commit("SET_FILTER_INFORMATION_USERS", resp.data);
            commit("SET_REDSYS_CONFIGURATION", resp.data);
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            commit("STATUS_ERROR");
          });
      });
    },
    sendHubspotEndpoint({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .sendHubspotEndpoint(data)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
  },
};
