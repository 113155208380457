var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"extras-selected-list w-full"},[_c('div',{staticClass:"flex flex-col flex-wrap my-4"},[_c('h2',{staticClass:"leading-4 font-medium text-lg mb-2",style:({ color: _vm.colors.colorOnBackground })},[_vm._v(" "+_vm._s(_vm.$t("common.vehicleCharacteristics"))+" ")]),_c('div',{staticClass:"text-sm"},[_c('div',{staticClass:"w-full flex flex-row align-center flex-wrap"},_vm._l((_vm.categorySelected.attributeList),function(attribute,index){return _c('div',{key:index,staticClass:"inline-block mr-2 mt-4"},[_c('div',{staticClass:"text-sm align-middle mr-2 inline-block rounded-md flex-none",style:({
              backgroundColor: _vm.colors.colorSurfaceMedium,
              color: _vm.colors.colorPrimary,
            })},[_c('div',{staticClass:"pa-1 m-1 inline-block align-middle"},[_c('svg',{staticStyle:{"height":"24px","width":"24px"},attrs:{"fill":_vm.colors.colorPrimary}},[(attribute.icon.path)?_c('path',{attrs:{"d":attribute.icon.path}}):_vm._e()])]),_c('div',{staticClass:"align-center inline-block align-middle mr-3",style:({ color: _vm.colors.colorOnSurfaceMedium })},[_vm._v(" "+_vm._s(attribute.supportedLangs[ _vm.$PageUtils.getLanguageSplittedMinus(_vm.$i18n.locale) ] ? attribute.supportedLangs[ _vm.$PageUtils.getLanguageSplittedMinus(_vm.$i18n.locale) ] : attribute.supportedLangs.en)+" ")])])])}),0)])]),_c('div',{staticClass:"mt-6"},[_c('div',{staticClass:"align-middle"},[_c('h2',{staticClass:"leading-4 font-medium text-lg inline-block align-middle",style:({ color: _vm.colors.colorOnBackground })},[_vm._v(" "+_vm._s(_vm.$t("common.kilometersAndPetrol"))+" ")])])]),_c('div',{staticClass:"w-full flex flex-row align-center flex-wrap"},[_c('div',{staticClass:"inline-block mr-2"},[_c('div',{staticClass:"text-sm align-middle mr-2 inline-block rounded-md flex-none mt-4 border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
          color: _vm.colors.colorOnSurface,
        })},[_c('div',{staticClass:"pa-1 m-1 inline-block align-middle"},[_c('F2gIcon',{staticClass:"mx-1",attrs:{"value":'counter',"stroke":_vm.colors.colorPrimary,"height":16,"width":25,"strokeWidth":4}})],1),_c('div',{staticClass:"align-center inline-block align-middle mr-3 py-2",style:({ color: _vm.colors.colorOnSurface })},[_vm._v(" "+_vm._s(_vm.categorySelected.kmBase === -1 ? _vm.$t("unlimitedKm") : _vm.categorySelected.kmBase === 0 ? _vm.$t("mileageNotIncluded") : `${_vm.categorySelected.kmBase} ${_vm.$t( "common.includedKilometers" )}`)+" "),(_vm.categorySelected.kmPriceExtra)?_c('div',{staticClass:"text-xs align-center",style:({ color: _vm.colors.colorOnSurface })},[_vm._v(" *"+_vm._s(_vm.$t("common.extraKmCost"))+": "+_vm._s(_vm.BillingUtil.formatBillingValue( _vm.categorySelected.kmPriceExtra, _vm.categorySelected.symbol ))+"/km ")]):_vm._e()])]),(
          _vm.categorySelected.vehicleType.code !==
            _vm.$CONSTANTS.VEHICLE.TYPE.SCOOTER
        )?_c('div',{staticClass:"text-sm align-middle mr-2 inline-block rounded-md flex-none mt-4 border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
          color: _vm.colors.colorOnSurface,
        })},[_c('div',{staticClass:"pa-1 m-1 inline-block align-middle"},[_c('F2gIcon',{staticClass:"mx-1",attrs:{"value":'gas-station',"stroke":_vm.colors.colorPrimary,"height":16,"width":25,"strokeWidth":4}})],1),_c('div',{staticClass:"align-center inline-block align-middle mr-3 py-2",style:({ color: _vm.colors.colorOnSurface })},[_vm._v(" "+_vm._s(_vm.$t("common.fullFull"))+" "),(_vm.categorySelected.consumptionPenalization)?_c('div',{staticClass:"-left-10 text-xs align-center",style:({ color: _vm.colors.colorOnSurface })},[_vm._v(" *"+_vm._s(_vm.$t("common.extraFuelDifference"))+": "+_vm._s(_vm.BillingUtil.formatBillingValue( _vm.categorySelected.consumptionPenalization, _vm.categorySelected.symbol ))+"/l ")]):_vm._e()])]):_vm._e()])]),(
      _vm.franchiseSelected &&
        (_vm.franchiseSelected.franchiseType ||
          _vm.franchiseSelected.franchiseType === 0)
    )?_c('div',{staticClass:"flex flex-col flex-wrap mb-2 mt-6"},[_c('h2',{staticClass:"leading-4 font-medium text-lg pt-1 mb-4",style:({ color: _vm.colors.colorOnBackground })},[_vm._v(" "+_vm._s(_vm.$t("common.franchise"))+" ")]),_c('div',{staticClass:"w-full flex flex-row align-center flex-wrap"},[_c('div',{staticClass:"text-sm align-middle mr-2 rounded-md border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
          color: _vm.colors.colorOnSurface,
        })},[_c('div',{staticClass:"pa-1 m-1 inline-block align-middle"},[_c('F2gIcon',{staticClass:"inline-block align-middle",attrs:{"value":'check',"stroke":_vm.colors.colorPrimary,"height":16,"width":25,"strokeWidth":4}})],1),_c('div',{staticClass:"align-center inline-block align-middle mr-3 py-2",style:({ color: _vm.colors.colorOnSurface })},[_vm._v(" "+_vm._s(_vm.franchiseSelected.message)+" ")])])])]):_vm._e(),(
      Object.keys(_vm.extrasSelected).length != 0 ||
        Object.keys(_vm.additionalsSelected).length != 0
    )?_c('div',{staticClass:"flex flex-col flex-wrap my-4"},[_c('p',{staticClass:"leading-4 font-medium text-lg pt-1 mb-0",style:({ color: _vm.colors.colorOnSurface })},[_vm._v(" "+_vm._s(_vm.$t("extraEquipmentAndOptionalServices"))+" ")]),_c('div',{staticClass:"mt-4"},[(Object.keys(_vm.extrasSelected).length != 0)?_c('category-additionals-extras',{attrs:{"itemsList":Object.values(_vm.extrasSelected),"selected":true}}):_vm._e(),(Object.keys(_vm.additionalsSelected).length != 0)?_c('category-additionals-extras',{attrs:{"itemsList":Object.values(_vm.additionalsSelected),"selected":true}}):_vm._e()],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }