import moment from "moment-timezone";
import "moment/locale/es";
import "moment/locale/en-gb";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/pt";
import "moment/locale/ca";
import authStore from "@/flit2go-web-core/store/authModule";

import i18n from "@/flit2go-web-core/lang/";

const daysShortEn = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];

const daysShortEs = ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"];

const getMonthEn = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];

const getMonthEs = [
  "ene",
  "feb",
  "mar",
  "abr",
  "may",
  "jun",
  "jul",
  "ago",
  "sep",
  "oct",
  "nov",
  "dic",
];

function timeElapsedForNotifications(start, end, lang) {
  let str = "";
  let unit = "";

  if (start != null && end != null) {
    // get total seconds between the times
    str = Math.abs(end - start) / 1000;

    //years
    if (str >= 31536000) {
      str = Math.floor(str / 31536000);
      unit = lang == "es" ? "año" : "yea";
      //months
    } else if (str >= 2419200 && str < 31536000) {
      str = Math.floor(str / 2419200);
      unit = lang == "es" ? "mes" : "mon";
      //weeks
    } else if (str >= 604800 && str < 2419200) {
      str = Math.floor(str / 604800);
      unit = lang == "es" ? "sem" : "wee";
      //days
    } else if (str >= 86400 && str < 604800) {
      str = Math.floor(str / 86400);
      unit = "d";
      //hours
    } else if (str >= 3600 && str < 86400) {
      str = Math.floor(str / 3600);
      unit = "h";
      //minutes
    } else if (str > 59 && str < 3600) {
      str = Math.floor(str / 60);
      unit = "m";
    } else {
      str = Math.floor(str);
      unit = "s";
    }
  }

  return `${str} ${unit}`;
}

function formatEnDate(timestamp) {
  const date = new Date(timestamp);
  const day = date.getDate();
  const monthIndex = date.getMonth() + 1;
  const year = date.getFullYear();
  return year + "/" + monthIndex + "/" + day;
}

function formatEsDate(timestamp) {
  const date = new Date(timestamp);
  const day = date.getDate();
  const monthIndex = date.getMonth() + 1;
  const year = date.getFullYear();
  return day + "/" + monthIndex + "/" + year;
}

const genericInternationalization = {
  EN: formatEnDate,
  ES: formatEsDate,
  PT: formatEsDate,
  FR: formatEsDate,
  IT: formatEsDate,
  GB: formatEnDate,
  CA: formatEsDate,
  DE: formatEsDate,
};

function notificationEnDate(timestamp) {
  const date = new Date(timestamp);
  const dayName = daysShortEn[date.getDay()];
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  return `${dayName}, ${date.getDate()} ${
    getMonthEn[date.getMonth()]
  } ${hours}:${minutes} ${timeElapsedForNotifications(
    timestamp,
    new Date().getTime(),
    "en"
  )}`;
}

function notificationEsDate(timestamp) {
  const date = new Date(timestamp);
  const dayName = daysShortEs[date.getDay()];
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  return `${dayName}, ${date.getDate()} ${
    getMonthEs[date.getMonth()]
  } ${hours}:${minutes} ${timeElapsedForNotifications(
    timestamp,
    new Date().getTime(),
    "es"
  )}`;
}

function notificationPtDate(timestamp) {
  const date = new Date(timestamp);
  const dayName = daysShortEs[date.getDay()];
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  return `${dayName}, ${date.getDate()} ${
    getMonthEs[date.getMonth()]
  } ${hours}:${minutes} ${timeElapsedForNotifications(
    timestamp,
    new Date().getTime(),
    "pt"
  )}`;
}

function notificationFrDate(timestamp) {
  const date = new Date(timestamp);
  const dayName = daysShortEs[date.getDay()];
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  return `${dayName}, ${date.getDate()} ${
    getMonthEs[date.getMonth()]
  } ${hours}:${minutes} ${timeElapsedForNotifications(
    timestamp,
    new Date().getTime(),
    "fr"
  )}`;
}

function notificationItDate(timestamp) {
  const date = new Date(timestamp);
  const dayName = daysShortEs[date.getDay()];
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  return `${dayName}, ${date.getDate()} ${
    getMonthEs[date.getMonth()]
  } ${hours}:${minutes} ${timeElapsedForNotifications(
    timestamp,
    new Date().getTime(),
    "it"
  )}`;
}

const notificationInternationalization = {
  EN: notificationEnDate,
  ES: notificationEsDate,
  PT: notificationPtDate,
  FR: notificationFrDate,
  IT: notificationItDate,
  GB: notificationEnDate,
  CA: notificationEsDate,
  DE: notificationEsDate,
};

export default {
  changeTimeZone(
    date,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid"
  ) {
    return moment(date).tz(timeZone);
  },
  formatInternationalDate(lang, timestamp) {
    const language = lang.toUpperCase();

    return genericInternationalization[language](timestamp);
  },
  formatNotificationsDate(lang, timestamp) {
    return notificationInternationalization[lang](timestamp);
  },
  formatBasicDate(
    date,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid"
  ) {
    return moment(date).tz(timeZone).format("YYYY-MM-DD");
  },
  formatBasicDateNoTimeZone(
    date,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid"
  ) {
    return moment(date).tz(timeZone, true).format("YYYY-MM-DD");
  },
  formatDateNoTimeZone(
    date,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid"
  ) {
    return moment(date).tz(timeZone, true).format("YYYY-MM-DD HH:mm:ss");
  },
  formatDateNoTimeZoneTimestamp(
    date,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid"
  ) {
    return moment(date).tz(timeZone, true).format("x");
  },
  formatBasicHour(
    date,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid"
  ) {
    return moment(date).tz(timeZone).format("HH:mm");
  },
  formatCustomDate(
    date,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid",
    formatterFormat = "DD-MM-YYYY HH:mm:ss"
  ) {
    return moment(date).tz(timeZone).format(formatterFormat);
  },
  formatCustomDateUntil(
    date,

    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid",
    formatterFormat = "DD-MM-YYYY HH:mm:ss"
  ) {
    return moment(date).add("days", 1).tz(timeZone).format(formatterFormat);
  },
  formatCustomFrameDate(
    date,
    language = "en",
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid",
    formatterFormat = "ddd D MMM HH:mm"
  ) {
    return moment(date).tz(timeZone).locale(language).format(formatterFormat);
  },
  formatYMDDate(
    date,

    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid",
    formatterFormat = "YYYY-MM-DD HH:mm:ss"
  ) {
    return moment(date).tz(timeZone).format(formatterFormat);
  },
  currentDateBasicFormat() {
    return moment(new Date()).format("YYYY-MM-DD");
  },
  maximumBornDateAdult() {
    let maximumBornYear = new Date();
    maximumBornYear.setFullYear(parseInt(maximumBornYear.getFullYear()) - 18);
    return moment(maximumBornYear).format("YYYY-MM-DD");
  },
  getHourWithMinutes(timestamp) {
    const date = new Date(timestamp);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    return `${hours}:${minutes}`;
  },
  getHourWithMinutesTimestamp(timestamp, timeZone = "Europe/Madrid") {
    return moment(timestamp).tz(timeZone).format("HH:mm");
  },
  getShortMonth(timestamp, language = "en") {
    return moment(timestamp).locale(language).format("MMM");
  },
  getDay(timestamp) {
    return moment(timestamp).format("DD");
  },
  formatDate(timestamp) {
    return formatEnDate(timestamp);
  },
  // Time elapsed between two dates
  timeElapsed(start, end) {
    let str = "";

    if (start != null && end != null) {
      // get total seconds between the times
      let delta = Math.abs(end - start) / 1000;
      // calculate (and subtract) whole days
      let days = Math.floor(delta / 86400);
      delta -= days * 86400;
      // calculate (and subtract) whole hours
      let hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;
      // calculate (and subtract) whole minutes
      let minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;
      // what's left is seconds
      let seconds = delta % 60;

      str = days > 0 ? days + "d " : "";
      str += hours > 0 ? hours + "h " : "";
      str += minutes > 0 ? minutes + "m " : "";
      str += seconds > 0 ? seconds + "s " : "";
    }

    return str;
  },
  // Time elapsed between two dates
  timeElapsedSubscriptions(start, end) {
    let str = "";

    if (start != null && end != null) {
      // get total seconds between the times
      let delta = Math.abs(end - start) / 1000;
      // calculate (and subtract) whole months
      let months = Math.floor(delta / 2629800);
      delta -= months * 2629800;
      // calculate (and subtract) whole days
      let days = Math.floor(delta / 86400);
      delta -= days * 86400;
      // calculate (and subtract) whole hours
      let hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;
      // calculate (and subtract) whole minutes
      let minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;
      // what's left is seconds
      let seconds = delta % 60;

      str = months > 0 ? months + "m " : "";
      str += days > 0 ? days + "d " : "";
      str += hours > 0 ? hours + "h " : "";
      str += minutes > 0 ? minutes + "m " : "";
      str += seconds > 0 ? seconds + "s " : "";
    }

    return str;
  },
  timeElapsedText(start, end) {
    let fullStr = "";
    let differenceTimestamp = end - start;
    let time = "";
    let timeUnit = "";
    if (start != null && end != null) {
      if (differenceTimestamp >= 2678400000) {
        time = Math.floor(differenceTimestamp / 2678400000) % 12;
        timeUnit =
          differenceTimestamp >= 5356800000
            ? i18n.t("common.months")
            : i18n.t("common.month");
      } else if (differenceTimestamp >= 86400000) {
        time = Math.floor(differenceTimestamp / 86400000) % 31;
        timeUnit =
          differenceTimestamp >= 172800000
            ? i18n.t("common.days")
            : i18n.t("common.day");
      } else if (differenceTimestamp >= 3600000) {
        time = Math.floor(differenceTimestamp / 3600000) % 24;
        timeUnit = i18n.t(
          differenceTimestamp >= 7200000 ? "common.hours" : "common.hour"
        );
      } else if (differenceTimestamp >= 6000) {
        time = Math.floor(differenceTimestamp / 6000) % 60;
        timeUnit = i18n.t("common.minutes");
      } else {
        time = 1;
        timeUnit = i18n.t("common.minute");
      }

      fullStr = i18n
        .t("common.timeElapsedComparison", {
          time: time,
          timeUnit: timeUnit,
        })
        .toLowerCase();
    }

    return fullStr;
  },
  getLocaleMonth(month) {
    const months = [
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december",
    ];
    return i18n.t(months[month - 1]);
  },
  getDateInText(locale, date) {
    const dateFormat = this.formatInternationalDate(locale, date).split("/");
    return `${dateFormat[0]} ${i18n.t(
      this.getLocaleMonth(dateFormat[1]).toLowerCase()
    )} ${dateFormat[2]}`;
  },
  currentTime() {
    return moment(new Date()).format("HH:mm");
  },
  currentTimeHour() {
    return moment(new Date().getTime()).format("HH:00");
  },
  nextTimeHour() {
    return moment(new Date().getTime() + 1 * 60 * 60 * 1000).format("HH:00");
  },
  getCurrentTimestamp() {
    return new Date().getTime();
  },
  beautifullDate(
    date,
    lang = "en-gb",
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid",
    format = "DD MMMM YYYY"
  ) {
    return moment(date).tz(timeZone).locale(lang).format(format);
  },
  beautifullFormatedDate(
    date,
    daysToAdd = 0,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid"
  ) {
    return moment(date)
      .tz(timeZone)
      .add(daysToAdd, "days")
      .format("DD-MM-YYYY");
  },
  beautifullDateHour(
    date,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid"
  ) {
    return moment(date)
      .tz(timeZone)
      .locale(i18n.locale.split("_")[0])
      .format("DD MMMM YYYY HH:mm");
  },

  currentDate() {
    return moment(new Date()).format("YYYY-MM-DD HH:mm");
  },

  subtractDate(date, number, timeType, format = "YYYY-MM-DD HH:mm") {
    const dateToSubtract = date ? date : new Date();
    return moment(dateToSubtract).subtract(number, timeType).format(format);
  },

  addDate(date, number, timeType, format = "YYYY-MM-DD HH:mm") {
    const dateToSubtract = date ? date : new Date();
    return moment(dateToSubtract).add(number, timeType).format(format);
  },
  currentDateForDatePicker() {
    return moment(new Date()).format("YYYY-MM");
  },

  timestampToMoment(
    timestamp,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid"
  ) {
    return moment(timestamp).tz(timeZone);
  },

  formatDateDayAndShortMonth(
    timestamp,
    timeZone = authStore.state.userProfile.ownerPerson
      ? authStore.state.userProfile.ownerPerson.timeZone
      : "Europe/Madrid"
  ) {
    return moment(timestamp).tz(timeZone).format("DD MMM");
  },
};
