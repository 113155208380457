<template>
  <div class="">
    <div
      :class="type === 'horizontal' ? 'h-14' : 'h-10'"
      class="w-full rounded-full flex flex-row flex-wrap items-start text-center align-center"
    >
      <div
        class="w-3/5 max-h-full align-center cursor-pointer"
        @click="openDateCard"
      >
        <v-text-field
          ref="dateTextField"
          filled
          dense
          hide-details
          single-line
          :class="
            'rounded-l border-r-2 border-gray-300 rounded-r-none cursorPointer  input-size'
          "
          :value="dateformFormatted"
          :label="labelDate"
          readonly
          :backgroundColor="colors.colorSurfaceMedium"
        >
          <template :slot="'prepend-inner'">
            <F2gIcon
              :stroke="colors.colorPrimary"
              :height="22"
              :width="22"
              :strokeWidth="6"
              value="calendar"
              class="cursor-pointer"
            />
          </template>
        </v-text-field>
      </div>
      <div class="w-2/5 max-h-full flex align-center">
        <HourPicker
          ref="hourPicker"
          :rounded="false"
          :backgroundColor="colors.colorSurfaceMedium"
          :inputColor="colors.colorOnSurfaceMedium"
          singleLine
          filled
          hideDetails
          dense
          :clearable="false"
          :customColor="colors.colorPrimary"
          :customClass="
            'rounded-r cursor-pointer rounded-tl-none cursorPointer  input-size'
          "
          :readonly="true"
          :strokeWidth="5"
          :label="labelTime"
          :minHour="minHour"
          :availableHours="availableHours"
          :showIcon="true"
          :zIndex="zIndex"
          iconPaddingRight="pr-1"
          @input="setTime"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DateUtil from "@/flit2go-web-core/util/dateUtil";
import HourPicker from "@/flit2go-web-core/components/ui/hour-picker";
import moment from "moment-timezone";

export default {
  name: "date-hour-picker-inline",
  components: {
    HourPicker,
  },
  data: () => ({
    DateUtil,
    date: "",
    time: "",
    menu: null,
    hours: [
      "00:00",
      "00:30",
      "01:00",
      "01:30",
      "02:00",
      "02:30",
      "03:00",
      "03:30",
      "04:00",
      "04:30",
      "05:00",
      "05:30",
      "06:00",
      "06:30",
      "07:00",
      "07:30",
      "08:00",
      "08:30",
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
      "18:00",
      "18:30",
      "19:00",
      "19:30",
      "20:00",
      "20:30",
      "21:00",
      "21:30",
      "22:00",
      "22:30",
      "23:00",
      "23:30",
    ],
  }),
  props: {
    clear: Boolean,
    minDate: String,
    maxDate: String,
    minHour: String,
    maxHour: String,
    type: { required: false, type: String, default: "vertical" },
    dateSelected: { required: false, type: String, default: null },
    timeSelected: { required: false, type: String, default: null },
    filterHours: { required: false, type: Array, default: null },
    labelTime: { required: false, type: String, default: null },
    labelDate: { required: false, type: String, default: null },
    availableHours: { Type: Array, required: false },
    zIndex: { Type: Number, required: false },
  },
  watch: {
    dateSelected() {
      if (this.dateSelected) {
        this.date = this.dateSelected;
      }
    },
    timeSelected() {
      if (this.timeSelected) {
        this.time = this.timeSelected;
        this.$refs.hourPicker.hour = this.time;
      }
    },
    /*time: function () {
      this.$emit("time", this.time);
    },*/
    clear: function() {
      if (this.clear) {
        this.$nextTick(() => {
          this.date = "";
          this.time = "";
        });
      }
    },
  },
  mounted() {
    if (this.dateSelected) {
      this.date = this.dateSelected;
    }
    if (this.timeSelected) {
      this.time = this.timeSelected;
      this.$refs.hourPicker.hour = this.time;
    }
  },
  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
    }),
    dateformFormatted() {
      let dateFormatted = this.date
        ? moment(this.date)
            .locale(this.$PageUtils.getLanguageSplittedMinus())
            .format("ddd D MMM")
        : "";
      dateFormatted =
        dateFormatted.charAt(0).toUpperCase() + dateFormatted.slice(1);
      dateFormatted.replaceAll(".", "");
      return dateFormatted.replaceAll(".", "");
    },
  },
  methods: {
    setTime(time) {
      this.time = time;
      this.$emit("time", this.time);
    },
    setDate(date) {
      this.date = date;
      this.$emit("date", this.date);
    },
    openDateCard() {
      this.$emit("openDateCard");
    },
    closeDateCard() {
      console.log("entro");
    },
    openHours() {
      this.$refs.hourPicker.open();
    },
    openDate() {
      this.$refs.dateTextField.$refs["input"].click();
    },
  },
};
</script>

<style lang="scss">
.v-text-field > .v-input__control > .v-input__slot:before {
  border-width: 0px !important;
}
.v-text-field > .v-input__control > .v-input__slot:after {
  border-width: 0px !important;
}
.cursorPointer input {
  cursor: pointer !important;
}
.input-size * {
  font-size: 0.9rem !important;
}
</style>
