var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"confirm-data"},[_c('div',{staticClass:"flex flex-col my-4"},[_c('h2',{staticClass:"leading-4 font-medium text-lg mt-4",style:({ color: _vm.colors.colorOnBackground })},[_vm._v(" "+_vm._s(_vm.$t("common.dateAndPlace"))+" ")]),_c('div',{staticClass:"flex flex-col md:flex-row justify-start"},[_c('div',{staticClass:"flex flex-col mt-4 p-2 sm:mr-4 rounded-lg border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
        })},[_c('div',{staticClass:"mb-2"},[_c('F2gIcon',{staticClass:"inline-block align-middle mr-1",attrs:{"stroke":_vm.colors.colorPrimary,"value":'marker',"height":22,"width":22,"strokeWidth":4}}),_c('div',{staticClass:"inline-block align-middle",style:({ color: _vm.colors.colorOnSurface })},[_vm._v(" "+_vm._s(_vm.bookingFilters.pickUpNameBranch)+" ")])],1),_c('div',[_c('F2gIcon',{staticClass:"inline-block align-middle mr-1",attrs:{"stroke":_vm.colors.colorPrimary,"value":'calendar',"height":22,"width":22,"strokeWidth":4}}),_c('div',{staticClass:"inline-block align-middle",style:({ color: _vm.colors.colorOnSurface })},[_vm._v(" "+_vm._s(_vm.DateUtil.formatCustomDate(Number(_vm.bookingFilters.pickUpDate)))+" ")])],1)]),_c('div',{staticClass:"flex flex-col mt-4 p-2 sm:mr-2 rounded-lg border",style:({
          backgroundColor: _vm.colors.colorSurface,
          borderColor: _vm.colors.colorSurfaceMedium,
        })},[_c('div',[_c('F2gIcon',{staticClass:"inline-block align-middle mr-1",attrs:{"stroke":_vm.colors.colorPrimary,"value":'marker',"height":22,"width":22,"strokeWidth":4}}),_c('div',{staticClass:"inline-block align-middle",style:({ color: _vm.colors.colorOnSurface })},[_vm._v(" "+_vm._s(_vm.bookingFilters.returnBackNameBranch)+" ")])],1),_c('div',{staticClass:"mt-2"},[_c('F2gIcon',{staticClass:"inline-block align-middle mr-1",attrs:{"stroke":_vm.colors.colorPrimary,"value":'calendar',"height":22,"width":22,"strokeWidth":4}}),_c('div',{staticClass:"inline-block align-middle",style:({ color: _vm.colors.colorOnSurface })},[_vm._v(" "+_vm._s(_vm.DateUtil.formatCustomDate(Number(_vm.bookingFilters.returnBackDate)))+" ")])],1)])])]),_c('div',{staticClass:"my-4"},[_c('driver-selected-data',{ref:"driverSelectedData",on:{"validation":_vm.setDriverSelectedDataValidation}})],1),_c('div',{staticClass:"flex flex-col flex-wrap my-4"},[_c('h2',{staticClass:"leading-4 font-medium text-lg pt-1",style:({ color: _vm.colors.colorOnBackground })},[_vm._v(" "+_vm._s(_vm.$t("common.selectedVehicle"))+" ")]),_c('div',{staticClass:"p-4 rounded-lg mt-8 shadow border",style:({
        backgroundColor: _vm.colors.colorSurface,
        borderColor: _vm.colors.colorSurfaceMedium,
      })},[_c('vehicle-selected-card')],1)]),_c('div',{staticClass:"flex flex-col flex-wrap mt-4"},[_c('extras-selected-list')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }