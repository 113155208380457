<template>
  <div id="lmark"></div>
</template>

<script>
import L from "leaflet";

export default {
  name: "LMarker",
  props: {
    lat: Number,
    lng: Number,
    icon: {
      type: String,
      default: "/locations/red-location.svg"
    },
    link: {
      type: String,
      default: ""
    },
    linkParam: {
      type: String,
      default: null
    },
    zoom: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      lmap: this.$parent.lmap,
      lmarker: null
    };
  },
  computed: {
    coordinatesChange() {
      return `${this.lat} ${this.lng}`;
    }
  },
  watch: {
    coordinatesChange() {
      this.lmarker.remove();
      this.initialize();
    }
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      const customIcon = L.icon({
        iconUrl: require("@/flit2go-web-core/assets/markers" + this.icon), // "/icons/icon.png"
        iconSize: [35, 45], // size of the icon
        shadowSize: [50, 64], // size of the shadow
        iconAnchor: [17, 38], // point of the icon which will correspond to marker's location
        shadowAnchor: [4, 62], // the same for the shadow
        popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
      });

      this.lmarker = L.marker(
        [this.lat, this.lng],
        { icon: customIcon },
        { pmIgnore: true }
      )
        .addTo(this.lmap)
        .on("click", this.changePage);
    },
    changePage() {
      if (this.link != "")
        this.$router.push({ path: this.link, query: this.linkParam });
    },
    removeMarker(){
      this.lmarker.remove();
    }
  }
};
</script>
