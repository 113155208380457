const STATUS = Object.freeze({
  REFUND: "REF",
  PENDING: "PND",
  PAID: "PAI",
  UNPAID: "UNP",
  CANCELLED: "CAN",
});

const TYPE = Object.freeze({
  PENDING: "pending",
  FINISHED: "finished",
  ALL: "all",
  INDEMNIZATION: "IND",
  RESERVE: "RES",
  FINISHED_TRAVEL: "FTR",
  PENALIZATION: "PEN",
  ADDITIONAL_LIQUIDATION: "IND",
  SUBSCRIPTION: "SBF",
  RESERVE_EXTENDED: "EXR",
  RESERVE_CANCELLED: "CRE",
  REFUND: "REF",
  ENTRY: "ENT",
  SHORT: "SHR",
});

const ACTION = Object.freeze({
  PAY: "pay",
  CONVERT_TO_RECEIPT: "convert",
  SEND_TO_MAIL: "sendToMail",
  SEND_TO_SII: "sendToSII",
  MAKE_RETURN: "makeRetun",
  CANCEL: "CANCEL",
  PARCIAL_PAYMENT: "PARCIAL_PAYMENT",
  MODIFY: "MODIFY",
  FORCE_COLLECTION: "FORCE_COLLECTION",
  CHANGE_TO_OFFLINE: "CHANGE_TO_OFFLINE",
  CHANGE_TO_ONLINE: "CHANGE_TO_ONLINE",
  PAY_ON_ACCOUNT: "payOnAccount",
  REGENERATE_DOCUMENT: "regenerateDocument",
  UNGROUP: "ungroup",
  CHANGE_ONLINE_OFFLINE: "changeOnlineOffline",
  CREATE_PAYMENT_LINK: "generatePaymentLink",
  SEND_TO_ERP: "sendToERP",
});

const METHOD = Object.freeze({
  BECS: "BECS",
  SEPA: "SEPA",
  CREDIT_CARD: "CREDIT_CARD",
});

const PLATFORM = Object.freeze({
  REDSYS: "RED",
  STRIPE: "STR",
});

export default Object.freeze({
  STATUS: STATUS,
  TYPE: TYPE,
  ACTION: ACTION,
  METHOD: METHOD,
  PLATFORM: PLATFORM,
});
