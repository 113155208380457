<template>
  <div class="data-card max-h-full">
    <div class="min-max-h-full h-3/4" id="top-part-login">
      <div
        class="flex-wrap my-4"
        v-if="!userLogged || !userLogged.id || showChangePassword"
      >
        <div
          class="flex flex-col lg:flex-row justify-between"
          v-if="!userLogged || !userLogged.id"
        >
          <p
            class="leading-2 font-medium text-lg mb-6 w-full"
            :style="{ color: colors.colorOnBackground }"
          >
            {{
              $t("alreadyRegisteredInfo", {
                owner: ownerIsPremium ? ownerName : "Flit2go",
              })
            }}
          </p>
          <div class="flex lg:justify-end lg:ml-2">
            <v-btn
              v-if="!showLogin"
              class="lg:w-58 w-full mb-4 mx-auto border-2 rounded-config30"
              :class="animateButton ? 'button-shrink' : ''"
              :outlined="showRegister"
              :style="{
                backgroundColor: showRegister ? '' : colors.colorPrimary,
                color: showRegister
                  ? colors.colorPrimary
                  : colors.colorOnPrimary,
              }"
              @click="showLoginEvent"
              >{{ $t("alreadyRegistered") }}
            </v-btn>
          </div>
        </div>
        <div
          v-if="
            changePassword &&
              showLogin &&
              !showValidateToken &&
              !showChangePassword
          "
          class="text-bold mb-4"
          v-html="$t('common.insertEmail')"
          :style="{ color: colors.colorOnBackground }"
        ></div>
        <div
          class="text-bold mb-4"
          v-html="$t('introduceTheReceivedCodeAtEmail')"
          v-if="showValidateToken"
          :style="{ color: colors.colorOnBackground }"
        ></div>
        <div
          v-if="showLogin && showChangePassword"
          class="pl-2 leading-2 font-medium text-lg mb-6 w-full"
          :style="{ color: colors.colorOnBackground }"
        >
          {{ $t("frameChangePassword") }}
        </div>
        <div class="w-full" v-if="requiredOldPassword">
          <v-text-field
            class="lg:w-2/5 xl:w-2/5 w-full lg:ml-2 xl:ml-2 custom-placeholer-color"
            v-model="access.password"
            :type="showPass ? 'text' : 'password'"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :label="$t('forms.actualPassword')"
            :outlined="true"
            :color="colors.colorPrimary"
            :style="
              `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
            "
            :backgroundColor="colors.colorSurface"
            :dark="colors.colorBackground === '#000000' ? true : false"
            :error-messages="passwordErrors"
            v-on:input="sendData"
            @click:append="showPass = !showPass"
            @keyup.enter="validateTextField"
          >
          </v-text-field>
        </div>
        <v-expand-transition>
          <div
            v-if="showLogin"
            class="flex flex-col lg:flex-row"
            :style="{ color: colors.colorOnBackground }"
          >
            <v-text-field
              v-if="showValidateToken"
              class="lg:w-1/2 w-full lg:mr-2 custom-placeholer-color"
              v-model="token"
              label="Token"
              :outlined="true"
              :color="colors.colorPrimary"
              :style="
                `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
              "
              :backgroundColor="colors.colorSurface"
              :dark="colors.colorBackground === '#000000' ? true : false"
              :error-messages="tokenErrors"
              @keyup.enter="validateToken"
            />
            <v-text-field
              v-else-if="showLogin && !showValidateToken && !showChangePassword"
              class="lg:w-1/2 w-full lg:mr-2 custom-placeholer-color"
              v-model="access.email"
              :color="colors.colorPrimary"
              :outlined="true"
              :error-messages="emailErrors"
              :style="
                `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
              "
              :backgroundColor="colors.colorSurface"
              :dark="colors.colorBackground === '#000000' ? true : false"
              v-on:input="sendData"
              @keyup.enter="validateTextField"
            >
              <template #label>
                {{ $t("driver.email") }}<RedAsterisk />
              </template>
            </v-text-field>
            <v-text-field
              v-if="!changePassword"
              class="lg:w-1/2 w-full lg:ml-2 custom-placeholer-color"
              v-model="access.password"
              :type="showPass ? 'text' : 'password'"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :outlined="true"
              :color="colors.colorPrimary"
              :style="
                `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
              "
              :backgroundColor="colors.colorSurface"
              :dark="colors.colorBackground === '#000000' ? true : false"
              :error-messages="passwordErrors"
              v-on:input="sendData"
              @click:append="showPass = !showPass"
              @keyup.enter="validateTextField"
            >
              <template #label>
                {{ $t("common.password") }}<RedAsterisk />
              </template>
            </v-text-field>
            <v-text-field
              v-if="showChangePassword"
              class="lg:w-1/2 w-full lg:ml-2 custom-placeholer-color"
              v-model="newPassword"
              :type="showNewPassword ? 'text' : 'password'"
              :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :label="$t('common.password')"
              :outlined="true"
              :color="colors.colorPrimary"
              :error-messages="newPasswordErrors"
              :style="
                `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
              "
              :backgroundColor="colors.colorSurface"
              :dark="colors.colorBackground === '#000000' ? true : false"
              @click:append="showNewPassword = !showNewPassword"
              @keyup.enter="changeLostPasswordEvent"
            >
            </v-text-field>
            <v-text-field
              v-if="showChangePassword"
              class="lg:w-1/2 w-full lg:ml-2 custom-placeholer-color"
              v-model="confirmPassword"
              :type="showConfirmPassword ? 'text' : 'password'"
              :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :label="$t('forms.passwordRepeat')"
              :outlined="true"
              :color="colors.colorPrimary"
              :style="
                `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
              "
              :backgroundColor="colors.colorSurface"
              :dark="colors.colorBackground === '#000000' ? true : false"
              :error-messages="confirmPasswordErrors"
              @click:append="showConfirmPassword = !showConfirmPassword"
              @keyup.enter="changeLostPasswordEvent"
            >
            </v-text-field>
            <div
              class="flex lg:justify-end justify-center lg:ml-8 lg:mt-2"
              v-if="!changePassword"
            >
              <v-btn
                class="w-full lg:w-48 mb-4 mx-auto border-2 rounded-config30"
                :style="{
                  backgroundColor: colors.colorPrimary,
                  color: colors.colorOnPrimary,
                }"
                @click="validateTextField"
                >{{ $t("common.confirm") }}
              </v-btn>
            </div>
            <div
              class="flex lg:justify-end lg:flex-row flex-col lg:ml-8 lg:mt-2"
              v-if="changePassword"
            >
              <v-btn
                v-if="
                  changePassword &&
                    showLogin &&
                    !showValidateToken &&
                    !showChangePassword
                "
                class="lg:w-48 w-full mb-4 border-2 rounded-config30"
                outlined
                :loading="loading"
                :color="colors.colorPrimary"
                @click="recoverPassword"
                >{{ $t("common.send") }}
              </v-btn>

              <v-btn
                v-if="
                  changePassword &&
                    showLogin &&
                    !showValidateToken &&
                    !showChangePassword
                "
                class="lg:w-48 w-full mb-4 lg:ml-2 border-2 rounded-config30"
                outlined
                :color="colors.colorOnBackground"
                @click="
                  changePassword = false;
                  emailSend = false;
                  errorShow = null;
                  error = null;
                "
                >{{ $t("common.cancel") }}
              </v-btn>

              <v-btn
                v-if="
                  changePassword &&
                    showLogin &&
                    emailSend &&
                    !showValidateToken &&
                    !showChangePassword
                "
                class="lg:w-48 w-full mb-4 lg:ml-2 border-2 rounded-config30"
                outlined
                :loading="loading"
                :color="colors.colorPrimary"
                @click="validateTokenEvent"
                >{{ $t("validateToken") }}
              </v-btn>
            </div>
            <div
              class="flex lg:justify-end lg:flex-row flex-col lg:ml-8 lg:mt-2"
              v-if="showValidateToken || showChangePassword"
            >
              <v-btn
                class="lg:w-48 w-full mb-4 border-2 rounded-config30"
                outlined
                :loading="loading"
                :color="colors.colorPrimary"
                @click="acceptButtonMethod"
                >{{ $t("common.accept") }}
              </v-btn>

              <v-btn
                v-if="
                  !requiredOldPassword &&
                    userLogged &&
                    !userLogged.passwordReset
                "
                class="lg:w-48 w-full mb-4 lg:ml-2 border-2 rounded-config30"
                outlined
                :color="colors.colorOnBackground"
                @click="cancelValidateTokenEvent"
                >{{ $t("common.cancel") }}
              </v-btn>
            </div>
          </div>
        </v-expand-transition>
        <div
          v-if="showLogin && !changePassword"
          class="underline w-max cursor-pointer flex"
          :style="{ color: colors.colorOnBackground }"
          @click="
            () => {
              changePassword = true;
              this.errorShow = null;
              this.error = null;
            }
          "
        >
          {{ $t("common.forgotPassword") }}
        </div>
        <div v-if="error === 'login' && showLogin" class="text-red-700 mb-2">
          {{ errorShow ? errorShow : $t("common.loginError") }}
        </div>
        <div
          v-if="emailSend && showLogin && !showChangePassword"
          class="text-primary mb-2 w-full row ml-1"
          :style="{ color: colors.colorOnBackground }"
        >
          <span>{{ $t("emailSendTo") }} </span>&nbsp;<strong>{{
            access.email
          }}</strong>
        </div>
      </div>
      <div class="flex-wrap my-4" v-if="!userLogged || !userLogged.id">
        <div class="flex flex-col lg:flex-row justify-between">
          <p
            class="leading-2 font-medium text-lg mb-6 w-full"
            :style="{ color: colors.colorOnBackground }"
          >
            {{ $t("notRegisteredInfo") }}
          </p>
          <div class="flex lg:justify-end justify-center lg:ml-2">
            <v-btn
              v-if="!showRegister"
              class="lg:w-58 w-full mb-4 border-2 rounded-config30"
              :class="animateButton ? 'button-shrink' : ''"
              :outlined="showLogin"
              :style="{
                backgroundColor: showLogin ? '' : colors.colorPrimary,
                color: showLogin ? colors.colorPrimary : colors.colorOnPrimary,
              }"
              @click="registerEvent"
              >{{ $t("notRegistered") }}
            </v-btn>
          </div>
        </div>
        <div id="top-part-register" style="margin-bottom:805px">
          <v-expand-transition>
            <div v-if="showRegister">
              <div v-if="error === 'create'" class="text-red-700 mb-2">
                {{ errorShow ? errorShow : $t("common.errorCreateUser") }}
              </div>
              <register-data
                ref="registerData"
                :ownerName="ownerName"
                :ownerIsPremium="ownerIsPremium"
                :validate="validate"
                :nationalities="user.nationalities"
                :openExtraData="openExtraData"
                @personalData="setPersonalData"
                @validation="validateData"
                @consentData="setConsentData"
                @billingAddress="setBillingDirection"
                @directionData="setDirectionData"
                @nifDrivingLicenseData="setNifDrivingLicenseData"
                @checkBilling="changeCheckBilling"
                @emptyDirections="emptyDirections"
                @validationDirection="validateDirection"
                @validationNifDrivingLicenseData="validateNifDrivingLicenseData"
                @billingDirectionValidation="validateBillingDirection"
              />
              <div class="flex justify-end mt-4 w-full">
                <v-btn
                  class="w-full lg:w-48 mb-4 border-2 rounded-config30"
                  :style="{
                    backgroundColor: colors.colorPrimary,
                    color: colors.colorOnPrimary,
                  }"
                  @click="validateTextField"
                  >{{ $t("common.confirm") }}
                </v-btn>
              </div>
            </div>
          </v-expand-transition>
        </div>
      </div>
      <div v-if="userLogged && userLogged.id && !showChangePassword">
        <div class="flex flex-row">
          <F2gIcon
            class="svg mr-2"
            :value="'check'"
            :stroke="colors.colorPrimary"
            :height="24"
            :width="32"
            :strokeWidth="7"
          />
          <div :style="{ color: colors.colorOnBackground }">
            {{ $t("title.welcome.f2g", { 0: userLogged.name, 1: ownerName }) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegisterData from "@/components/register-data.vue";
import { requiredIf, email, sameAs } from "vuelidate/lib/validators";
import { EventBus } from "@/flit2go-web-core/util/event-bus.js";
import { mapState, mapGetters } from "vuex";
import uploadImgS3 from "@/flit2go-web-core/util/uploadImgS3";

export default {
  name: "DataCard",
  components: {
    RegisterData,
    RedAsterisk: () =>
      import("@/flit2go-web-core/components/fields/f2g-red-asterisk"),
  },
  data() {
    return {
      emailSend: false,
      loading: false,
      validate: false,
      validatedRegister: false,
      validatedDirection: false,
      validatedNifDrivingLicenseData: false,
      validatedNif: false,
      validatedBilling: false,
      error: null,
      errorShow: null,
      typeUser: null,
      showPass: false,
      showLogin: false,
      showRegister: false,
      changePassword: false,
      access: {
        email: null,
        password: null,
      },
      loginParams: {
        clientId: null,
        secretId: null,
        basicId: null,
      },
      userToCreate: {
        address: null,
        billAddress: null,
        checkBilling: true,
        name: "",
        surnames: "",
        country: null,
        phoneNumber: "",
        bornDate: "",
        language: "",
        username: "",
      },
      usertoCreateExtraInfo: {
        drivingPermissionValidityDate: null,
        licenseTypeA: false,
        expeditionLicenseA: null,
        expeditionLicenseB: null,
        typeLicenseDriving: null,
        drivingLicenseCar: false,
        nif: null,
        typeLicense: null,
        countryLicense: null,
        newDlImgFront: null,
        newDlImgReverse: null,
        newNifImgFront: null,
        newNifImgReverse: null,
        nifValidityDate: null,
      },
      canSendDocumentation: false,
      imagesToUpload: {
        nifImgFront: null,
        nifImgReverse: null,
        drivingLicenseImgFront: null,
        drivingLicenseImgReverse: null,
      },
      ownerName: "",
      ownerIsPremium: false,
      consentData: false,
      token: "",
      showValidateToken: false,
      showChangePassword: false,
      newPassword: "",
      confirmPassword: "",
      showNewPassword: false,
      showConfirmPassword: false,
      userLogged: null,
      openExtraData: false,
      tokenUser: null,
      requiredOldPassword: false,
      animateButton: false,
    };
  },
  validations() {
    return {
      access: {
        email: {
          required: requiredIf(function() {
            return (
              this.showLogin &&
              !this.showValidateToken &&
              !this.showChangePassword
            );
          }),
          email,
        },
        password: {
          required: requiredIf(function() {
            return (
              (this.showLogin &&
                !this.showValidateToken &&
                !this.showChangePassword) ||
              this.requiredOldPassword
            );
          }),
        },
      },
      consentData: {
        sameAs: sameAs(() => true),
      },
      token: {
        required: requiredIf(() => this.showValidateToken),
      },
      newPassword: {
        required: requiredIf(() => this.showChangePassword),
      },
      confirmPassword: {
        sameAs: sameAs(() => this.newPassword),
      },
      validatedDirection: {
        sameAs: sameAs(() => {
          if (this.typeUser == "create") {
            return true;
          } else {
            return false;
          }
        }),
      },
      validatedNifDrivingLicenseData: {
        sameAs: sameAs(() => {
          if (this.typeUser == "create") {
            return true;
          } else {
            return false;
          }
        }),
      },
    };
  },
  watch: {
    validatedRegister() {
      if (this.validatedRegister) {
        this.saveNewUser();
      }
    },
    validatedDirection() {
      if (this.validatedDirection) {
        this.saveNewUser();
      }
    },
    validatedNifDrivingLicenseData() {
      if (this.validatedNifDrivingLicenseData) {
        this.saveNewUser();
      }
    },
    validatedBilling() {
      if (this.validatedBilling) {
        this.saveNewUser();
      }
    },
  },
  mounted() {
    EventBus.$on("goToLogin", () => {
      this.animateButton = true;
      var register = document.getElementById("top-part-login");
      if (register) {
        register.scrollIntoView({ behavior: "smooth", block: "center" }, false);
      }
      setTimeout(() => {
        this.animateButton = false;
      }, 3000);
    });
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      colors: "configuration/getColors",
      language: "configuration/getUserLanguage",
      loginParameters: "user/loginParameters",
      userLoged: "user/userLoged",
    }),
    emailErrors() {
      const errors = [];
      if (this.$v.access.email.$dirty) {
        !this.$v.access.email.required &&
          errors.push(this.$t("errors.required"));
        !this.$v.access.email.email && errors.push(this.$t("errors.username"));
      }
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (this.$v.access.password.$dirty) {
        !this.$v.access.password.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    tokenErrors() {
      const errors = [];
      if (this.$v.token.$dirty) {
        !this.$v.token.required && errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    newPasswordErrors() {
      const errors = [];
      if (this.$v.newPassword.$dirty) {
        !this.$v.newPassword.required &&
          errors.push(this.$t("errors.required"));
      }
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (this.$v.confirmPassword.$dirty) {
        !this.$v.confirmPassword.sameAs &&
          errors.push(this.$t("errors.passwordsNotMatch"));
      }
      return errors;
    },
  },
  created() {
    this.$store.dispatch("user/getNationalities");
    this.getOwner();
    this.checkTokenEvent();
  },
  methods: {
    emptyDirections() {
      this.validatedBilling = true;
      this.userToCreate.address = null;
      this.userToCreate.billAddress = null;
    },
    validateDirection(event) {
      this.validatedDirection = event.valid;
      if (!event.valid) {
        this.$emit("validation", {
          valid: event.valid,
        });
      }
    },
    validateNifDrivingLicenseData(event) {
      this.validatedNifDrivingLicenseData = event.valid;
      if (!event.valid) {
        this.$emit("validation", {
          valid: event.valid,
        });
      }
    },
    validateTokenEvent() {
      this.showValidateToken = true;
    },
    cancelValidateTokenEvent() {
      this.changePassword = false;
      this.emailSend = false;
      this.errorShow = null;
      this.error = null;
      this.showValidateToken = false;
      this.showLogin = false;
      this.showChangePassword = false;
    },
    async validateToken() {
      const idUser = await this.getPersonByUsername();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("user/validateToken", {
            token: this.token,
            id: idUser,
          })
          .then((resp) => {
            if (resp.data === true) {
              this.showValidateToken = false;
              this.showChangePassword = true;
              this.errorShow = null;
              this.error = null;
            }
          })
          .catch(this.$showMessage.showError);
      }
    },
    async changeLostPasswordEvent() {
      if (!this.$v.$invalid) {
        const data = {
          id: await this.getPersonByUsername(),
          token: this.token,
          password: this.newPassword,
        };
        this.$store
          .dispatch("user/changeLostPassword", data)
          .then((resp) => {
            if (resp.data === true) {
              this.showChangePassword = true;
              this.errorShow = null;
              this.error = null;
              this.cancelValidateTokenEvent();
              this.access.password = this.newPassword;
              this.login();
            }
          })
          .catch(this.$showMessage.showError);
      }
    },
    async changePasswordEvent() {
      if (!this.$v.$invalid) {
        const data = {
          id: await this.getPersonByUsername(),
          oldPassword: this.access.password,
          newPassword: this.newPassword,
        };
        this.$store
          .dispatch("user/changePassword", {
            data: data,
            token: this.loginParameters.access_token,
          })
          .then((resp) => {
            if (resp?.data === true) {
              this.showChangePassword = true;
              this.errorShow = null;
              this.error = null;
              this.cancelValidateTokenEvent();
              this.access.password = this.newPassword;
              this.login();
              this.requiredOldPassword = false;
            } else {
              this.$showMessage.error(this.$t("incorrectCurrentPassword"));
            }
          })
          .catch(this.$showMessage.showError);
      }
    },
    validateBillingDirection(event) {
      this.validatedBilling = event.valid;
      if (!event.valid) {
        this.$emit("validation", {
          valid: event.valid,
        });
      }
    },
    setConsentData(consent) {
      this.consentData = consent;
      this.sendData();
    },
    sendData() {
      this.$emit("showConfirmation", !this.$v.$invalid);
    },
    validateData(event) {
      this.validatedRegister = event.valid;
      var register = document.getElementById("top-part-register");
      if (register) {
        register.scrollIntoView({ behavior: "smooth" }, true);
      }
      if (!event.valid) {
        this.openExtraData = true;
        this.$emit("validation", {
          valid: event.valid,
        });
      }
      setTimeout(() => {
        this.validatedRegister = false;
      }, 300);
      this.sendData();
    },
    getClientId() {
      this.$store
        .dispatch("user/getClientId")
        .then((resp) => {
          this.loginParams.clientId = resp.data.clientId;
          this.loginParams.secretId = resp.data.secretId;
          this.loginParams.basicId =
            "Basic " +
            btoa(this.loginParams.clientId + ":" + this.loginParams.secretId);
        })
        .catch(() => {
          this.$showMessage.error(this.$t("errors.generic"));
        });
    },
    getOwner() {
      this.$store
        .dispatch("user/getOwner")
        .then((resp) => {
          this.ownerName = resp.data.ownerName;
          this.ownerIsPremium = resp.data.isPremium;
        })
        .catch(() => {
          this.$showMessage.error(this.$t("errors.generic"));
        });
    },
    login() {
      this.errorShow = null;
      let params = {};
      params.loginParams = Object.assign({}, this.loginParams);
      params.access = new URLSearchParams();
      params.access.append("username", this.access.email);
      params.access.append("password", this.access.password);
      this.$store.commit("user/SET_EMAIL_ACCESS", this.access);
      this.$store
        .dispatch("user/login", params)
        .then((resp) => {
          if (resp.status === 200) {
            window.localStorage.setItem(
              "frame-user-token",
              resp.data.access_token
            );
            EventBus.$emit("userLoged", resp.data);
            this.getUserLogged();
          } else {
            this.error = "login";
            this.$emit("validation", {
              valid: false,
            });
          }
        })
        .catch((err) => {
          this.errorShow = err.response.data;
          this.error = "login";
          this.$emit("validation", {
            valid: false,
          });
        });
    },
    getUserLogged(goNextStep = true) {
      this.$store
        .dispatch("user/getUserByUsername", {
          username: this.access.email,
          token: this.loginParameters.access_token,
        })
        .then((resp) => {
          this.userLogged = resp.data;
          this.$emit("validation", {
            valid: !this.$v.$invalid,
          });
          if (this.userLogged.passwordReset && !this.userLoged.newUser) {
            this.changePassword = true;
            this.showChangePassword = true;
            this.errorShow = null;
            this.error = null;
            this.$emit("hideButtonNextStep");
            if (!goNextStep) {
              this.consentData = true;
              this.showLogin = true;
              this.requiredOldPassword = !this.access.password;
            }
          } else if (goNextStep) {
            this.$emit("nextStep");
          } else {
            this.$emit("showButtonNextStep");
          }
        })
        .catch(this.$showMessage.showError);
    },
    acceptButtonMethod() {
      let method = "";
      if (this.showValidateToken) {
        this.validateToken();
      } else if (this.userLogged && this.userLogged.id) {
        this.changePasswordEvent();
      } else {
        this.changeLostPasswordEvent();
      }
    },
    setPersonalData(data) {
      this.userToCreate.name = data.name;
      this.userToCreate.surnames = data.surnames;
      this.userToCreate.country = Object.assign({}, data.nationality);
      this.userToCreate.phoneNumber = data.phoneNumber;
      this.userToCreate.bornDate = data.bornDate;
      this.userToCreate.username = data.username;
      this.userToCreate.language = this.$PageUtils.getLanguageSplittedMinus();
      this.sendData();
    },
    setDirectionData(data) {
      this.userToCreate.address = {};
      this.userToCreate.address.direction = data.address;
      this.userToCreate.address.directionAdditionalInfo =
        data.additionalAddressInfo;
      this.userToCreate.address.postalCode = data.postalCode;
      this.userToCreate.address.city = data.province;
      this.userToCreate.address.locality = data.locality;
      this.userToCreate.address.country =
        data.country && data.country.name
          ? this.$PageUtils.getObjectNameLower(data.country)
          : null;
      this.sendData();
    },
    setNifDrivingLicenseData(data) {
      this.usertoCreateExtraInfo.country = data.drivingLicenseCountry
        ? data.drivingLicenseCountry
        : this.userToCreate.country
        ? this.userToCreate.country
        : null;
      this.usertoCreateExtraInfo.typeLicense = data.drivingLicense;
      this.usertoCreateExtraInfo.drivingLicenseCar = data.drivingLicenseCar;
      this.usertoCreateExtraInfo.expeditionLicenseB = this.getDate(
        data.drivingLicenseCarIssueDate
      );
      this.usertoCreateExtraInfo.countryLicense = data.drivingLicenseCountry;
      this.usertoCreateExtraInfo.newDlImgFront = data.drivingLicenseImgFront
        ? true
        : false;
      this.usertoCreateExtraInfo.newDlImgReverse = data.drivingLicenseImgReverse
        ? true
        : false;
      this.usertoCreateExtraInfo.licenseTypeA = data.licenseHighestRange;
      this.usertoCreateExtraInfo.expeditionLicenseA = this.getDate(
        data.drivingLicenseMotorcycleIssueDate
      );
      this.usertoCreateExtraInfo.drivingPermissionValidityDate = this.getDate(
        data.drivingLicenseValidityDate
      );
      this.usertoCreateExtraInfo.typeLicenseDriving =
        data.drivingLicense && data.drivingLicense.code !== "NO"
          ? data.drivingLicense.code
          : null;
      this.usertoCreateExtraInfo.nif = data.nif;
      this.usertoCreateExtraInfo.newNifImgFront = data.nifImgFront
        ? true
        : false;
      this.usertoCreateExtraInfo.newNifImgReverse = data.nifImgReverse
        ? true
        : false;
      this.usertoCreateExtraInfo.nifValidityDate = this.getDate(
        data.nifValidityDate
      );

      this.imagesToUpload.nifImgFront = data.nifImgFront;
      this.imagesToUpload.nifImgReverse = data.nifImgReverse;
      this.imagesToUpload.drivingLicenseImgFront = data.drivingLicenseImgFront;
      this.imagesToUpload.drivingLicenseImgReverse =
        data.drivingLicenseImgReverse;
      this.canSendDocumentation = true;
    },
    setBillingDirection(data) {
      this.userToCreate.billAddress = {};
      this.userToCreate.billAddress.direction = data.direction;
      this.userToCreate.billAddress.directionAdditionalInfo =
        data.directionAdditionalInfo;
      this.userToCreate.billAddress.postalCode = data.postalCode;
      this.userToCreate.billAddress.city = data.city;
      this.userToCreate.billAddress.locality = data.locality;
      this.userToCreate.billAddress.country =
        data.country && data.country.name
          ? data.country.name[
              this.$PageUtils.getLanguageSplitted(this.$i18n.locale)[0]
            ]
          : null;
      this.userToCreate.billAddress.documentIdentity = data.documentIdentity;
      this.userToCreate.billAddress.businessName = data.businessName;
      this.userToCreate.billAddress.businessSurnames = data.businessSurnames;
      this.userToCreate.billAddress.company = data.company;
      this.sendData();
    },
    saveNewUser() {
      this.errorShow = null;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (
          this.validatedRegister &&
          (!this.userToCreate.checkBilling ? this.validatedBilling : 1)
        ) {
          if (
            typeof this.userToCreate.country === "object" &&
            Object.keys(this.userToCreate.country).length === 0
          ) {
            this.userToCreate.country = null;
          }
          this.$store
            .dispatch("user/createUser", this.userToCreate)
            .then((resp) => {
              this.userLogged = resp;
              this.tokenUser = resp.token;
              window.localStorage.setItem(
                "frame-user-token",
                resp.token.access_token
              );
              if (this.canSendDocumentation) {
                this.saveNifInfo();
              } else {
                this.$emit("validation", {
                  valid: !this.$v.$invalid,
                });
                EventBus.$emit("userLoged", this.tokenUser);
                this.$emit("nextStep");
              }
            })
            .catch((err) => {
              this.$emit("validation", {
                valid: false,
              });
              this.errorShow = err.response.data;
              this.error = "create";
            });
        }
      }
    },
    saveNifInfo() {
      let data = {
        userInfo: this.usertoCreateExtraInfo,
        token: this.loginParameters.access_token,
        params: { isFrameweb: true },
      };
      this.canSendDocumentation = false;
      this.$store
        .dispatch("user/createInfoNewUser", data)
        .then((resp) => {
          if (
            this.imagesToUpload.nifImgFront ||
            this.imagesToUpload.nifImgReverse ||
            this.imagesToUpload.dlImgFront ||
            this.imagesToUpload.dlImgReverse
          ) {
            this.uploadImages(resp);
          } else {
            this.confirmUpdateUploadImages();
          }
          this.$emit("validation", {
            valid: !this.$v.$invalid,
          });
        })
        .catch((err) => {
          this.$emit("validation", {
            valid: false,
          });
          this.errorShow = err.response.data;
          this.error = "create";
        });
    },
    validateTextField() {
      this.error = null;
      this.errorShow = null;
      this.$v.$touch();
      // if (this.showRegister) {
      //   this.$refs.registerData.$v.$touch();
      // }
      if (!this.$v.$invalid && this.typeUser !== null) {
        if (this.typeUser == "login") {
          this.login();
        }
        if (this.typeUser == "create") {
          this.validate = true;
          setTimeout(() => {
            this.validate = false;
          }, 200);
        }
      } else {
        if (this.typeUser == "login") {
          this.showLogin = true;
          this.showRegister = false;
          this.consentData = true;
          this.error = "login";
          this.typeUser = "login";
          this.errorShow = this.$t("MustBeIdentifiedToContinue");
          this.getClientId();
        }
        if (this.typeUser == "create") {
          this.validate = true;
          setTimeout(() => {
            this.validate = false;
          }, 200);
        }
        if (this.userLoged && this.userLoged.id) {
          this.$emit("validation", {
            valid: true,
          });
        } else {
          this.$emit("validation", {
            valid: false,
          });
        }
      }
    },
    async getPersonByUsername() {
      try {
        let response = await this.$store.dispatch(
          "user/getPersonIdByUsername",
          { username: this.access.email, client_ID: this.loginParams.clientId }
        );
        this.errorShow = null;
        this.error = null;
        return response.data;
      } catch (error) {
        this.errorShow = error.response.data;
        this.error = "login";
        return null;
      }
    },
    async recoverPassword() {
      if (this.access.email) {
        this.loading = true;
        let idUser = await this.getPersonByUsername();
        if (idUser) {
          this.$store
            .dispatch("user/sendEmailToUserApp", idUser)
            .then(() => {
              this.loading = false;
              this.emailSend = true;
            })
            .catch(() => {
              this.loading = false;
              this.errorShow = this.$t("errors.errorSendEmail");
            });
        } else {
          this.loading = false;
          this.errorShow = this.$t("errors.emailNotExist");
        }
      } else {
        this.errorShow = this.$t("errors.mustInsertEmail");
      }
    },
    changeCheckBilling(event) {
      this.userToCreate.checkBilling = event;
      if (!this.userToCreate.checkBilling) {
        this.validatedBilling = false;
      }
    },
    getDate(element) {
      return element ? new Date(element).getTime() : null;
    },
    uploadImages(data) {
      const promises = [];
      if (data.imgDniFront) {
        promises.push(
          uploadImgS3(data.imgDniFront, this.imagesToUpload.nifImgFront)
        );
      }
      if (data.imgDniReverse) {
        promises.push(
          uploadImgS3(data.imgDniReverse, this.imagesToUpload.nifImgReverse)
        );
      }
      if (data.imgDrivingLicense) {
        promises.push(
          uploadImgS3(
            data.imgDrivingLicense,
            this.imagesToUpload.drivingLicenseImgFront
          )
        );
      }
      if (data.imgDrivingLicenseReverse) {
        promises.push(
          uploadImgS3(
            data.imgDrivingLicenseReverse,
            this.imagesToUpload.drivingLicenseImgReverse
          )
        );
      }
      Promise.allSettled(promises)
        .catch(this.$showMessage.showError)
        .finally(this.confirmUpdateUploadImages);
    },
    confirmUpdateUploadImages() {
      this.$store
        .dispatch("user/confirmUpdateUploadImages", {
          userId: this.userLogged.id,
          token: this.loginParameters.access_token,
        })
        .then(() => {
          EventBus.$emit("userLoged", this.tokenUser);
          this.$emit("nextStep");
        })
        .catch(this.$showMessage.showError);
    },

    showLoginEvent() {
      this.showLogin = true;
      this.showRegister = false;
      this.consentData = true;
      this.error = null;
      this.typeUser = "login";
      this.$v.access.$reset();
      this.getClientId();
      window.parent.postMessage("manualResize", "*");
      // var login = document.getElementById("top-part-login");
      // if (login) {
      //   login.scrollIntoView({ behavior: "smooth" }, true);
      // }
      /*    setTimeout(() => {
        var register = document.getElementById("top-part-login");
        if (register) {
          register.scrollIntoView({ behavior: "smooth" }, true);
        }
      }, 250);*/
    },
    registerEvent() {
      this.showLogin = false;
      this.showRegister = true;
      this.consentData = false;
      this.error = null;
      this.typeUser = "create";
      // window.parent.postMessage("manualResize", "*");
      // var register = document.getElementById("top-part-register");
      // if (register) {
      //   register.scrollIntoView({ behavior: "smooth" }, true);
      // }
      setTimeout(() => {
        var register = document.getElementById("top-part-register");
        if (register) {
          register.scrollIntoView({ behavior: "smooth" }, true);
        }
      }, 350);
    },
    checkTokenEvent() {
      try {
        const token = window.localStorage.getItem("frame-user-token");
        if (token) {
          this.token = token;
          this.$store
            .dispatch("user/getClientId")
            .then(async (resp) => {
              this.loginParams.clientId = resp.data.clientId;
              this.loginParams.secretId = resp.data.secretId;
              this.loginParams.basicId =
                "Basic " +
                btoa(
                  this.loginParams.clientId + ":" + this.loginParams.secretId
                );
              this.$store
                .dispatch("user/checkToken", {
                  token: this.token,
                  headers: { Authorization: this.loginParams.basicId },
                })
                .then((resp) => {
                  if (resp.data) {
                    this.access.email = resp.data.user_name;
                    this.$store.commit("user/SET_LOGIN_PARAMETERS", {
                      access_token: this.token,
                    });
                    this.showValidateToken = false;
                    this.getUserLogged(false);
                  } else {
                    this.clearCheckTokenEvent();
                  }
                })
                .catch(() => {
                  this.clearCheckTokenEvent();
                  window.localStorage.removeItem("frame-user-token");
                });
            })
            .catch(this.clearCheckTokenEvent);
        }
      } catch (e) {
        this.clearCheckTokenEvent();
      }
    },
    clearCheckTokenEvent() {
      this.token = null;
      this.access.email = null;
      this.loginParams.clientId = null;
      this.loginParamssecretId = null;
      this.loginParamsbasicId = null;
      this.checkingToken = false;
    },
    clear() {
      this.validatedRegister = false;
      this.validatedDirection = false;
      this.validatedBilling = false;
      this.error = null;
      this.errorShow = null;
      this.typeUser = null;
      this.showPass = false;
      this.showLogin = false;
      this.showRegister = false;
      this.changePassword = false;
      this.access.email = null;
      this.access.password = null;
      this.loginParams.clientId = null;
      this.loginParams.secretId = null;
      this.loginParams.basicId = null;
      this.userToCreate.address = null;
      this.userToCreate.billAddress = null;
      this.userToCreate.checkBilling = true;
      this.userToCreate.name = "";
      this.userToCreate.surnames = "";
      this.userToCreate.country = null;
      this.userToCreate.phoneNumber = "";
      this.userToCreate.bornDate = "";
      this.userToCreate.language = "";
      this.userToCreate.nif = "";
      this.userToCreate.username = "";
      this.consentData = false;
      this.token = "";
      this.showValidateToken = false;
      this.showChangePassword = false;
      this.newPassword = "";
      this.confirmPassword = "";
      this.showNewPassword = false;
      this.showConfirmPassword = false;
      this.userLogged = null;
      this.requiredOldPassword = false;
      this.$v.$reset();
      this.$emit("validation", {
        valid: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-placeholer-color input {
  color: var(--custom_color-text) !important;
}
.custom-placeholer-color .v-label {
  color: var(--custom_color-label) !important;
  opacity: 1;
}
.button-shrink {
  animation: circleScale 1s infinite;
  animation-delay: 0.5s;
  z-index: 1;
}
@keyframes circleScale {
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}
</style>
