export default Object.freeze({
  BIGGER_THAN_MIN_DURATION: "errors.biggerThanMinDuration",
  DATE_LESS: "errors.dateLess",
  DATE_MORE: "errors.dateMore",
  DECIMALS_2: "errors.decimals2",
  GENERIC: "errors.generic",
  HOUR_LESS: "errors.hourLess",
  HOUR_MORE: "errors.hourMore",
  MOTIVE_MIN_LENGTH: "errors.motiveMinLength",
  MOTIVE_REQUIRED: "errors.motiveRequired",
  NUMERIC: "errors.numeric",
  MIN_VALUE_0: "errors.minValue0",
  MAX_VALUE_100: "errors.maxValue100",
  MAX_VALUE_9999: "errors.maxValue99999999",
  NOT_NEGATIVE: "errors.notNegative",
  REQUIRED: "errors.required",
  REQUIRED_EXACT_HOUR: "errors.requiredExactHour",
  SPECIFY_USER_CONSENT: "errors.specifyUserConsent",
  TWO_DECIMALS: "errors.decimals2",
  VALUE_MUST_BE: "valueMustBe",
  INVALID_DATA: "errors.invalidData",
  NOT_FOUND: "errors.notFound",
  DECIMAL: "errors.decimal",
  MAX_LENGTH: "errors.maxLength",
  MINVALUE: "errors.minValue",
  MIN_LENGTH: "minLength",
  LENGTH: "errors.length",
  EMAIL: "errors.email",
  INCORRECT_FIELD: "errors.incorrectField",
  ALPHA_NUM: "errors.alphaNum",
  INCORRECT_PATTERN: "errors.incorrectPattern",
  RESERVE_HOURS: "errors.reserveHours",
  MIN_VALUE_05: "errors.minValue05",
  MAXIMUM_SIZE: "maximumSize",
  MAX_VALUE_99999: "maxValue99999",
  MINIMUM_AGE_18: "errors.minimumAge18",
  PREMIUM_DEALER_OR_MARKETPLACE: "errors.premiumDealerOrMarketplace",
  URL: "errors.url",
  MIN_LENGTH_SIMPLE: "errors.minLength",
  VALID_IBAN: "errors.validIBAN",
  INCORRECT_DATA: "errors.incorrectData",
  DOWNLOAD_FILE: "errors.downloadFile",
  COMPLETE_ALL_STEPS: "completeAllSteps",
  ONLY_INTEGER: "onlyInteger",
  MAX_VALUE_LESS_THAN_MIN: "maxValueCannotBeLessThanMinValue",
  UPLOADING_IMAGES: "errors.uploadingImages",
  MAX_VALUE_ERROR: "maxValueError",
  COMPLETE_ALL_FIELDS: "errors.completeAllFields",
  DATE_MUST_BE_INFERIOR_CURRENT: "errors.dateInferiorToCurrent",
  DATE_AND_HOUR_MUST_BE_SUPERIOR_TO_INITIAL:
    "common.dateAndHourSuperiorToInitial",
  DATE_AND_HOUR_SUPERIOR_TO_ORIGINAL_OPERATION:
    "errors.dateHourSuperiorToOrginalOperation",
  DATE_AND_HOUR_RETURN_SUPERIOR_TO_PICKUP: "common.returnDateGreaterThanPickUp",
  NOT_EQUAL_PATTERNS: "notEqualPatterns",
  NO_RESULTS: "errors.noResults",
  UBICATON_NOT_ACTIVATED: "ubicationNotActivated",
  GREATER_THAN_ZERO: "mustBeGreaterThanZero",
  MAX_VALUE_VARIABLE: "maxValueErrorCount",
  MAX_VALUE_COUNT: "maxValueErrorCount",
  COMPLETE_ALL_LANGUAGES: "completeAllLanguages",
  REQUIRED_IN: "errors.requiredIn",
});
