export default Object.freeze({
    "SUPER_ADMIN": "SUPER_ADMIN",
    "EMPLOYEE": "EMPLOYEE",
    "MANAGER": "MANAGER",
    "OWNER_EMPLOYEE": "OWNER_EMPLOYEE",
    "SUPER_OWNER": "SUPER_OWNER",
    // "TECHNICIAN": "TECHNICIAN",
    // "SUPPORT": "SUPPORT",
    // "EXTERNAL_SUPPORT": "EXTERNAL_SUPPORT",
    "USER": "USER",
    "USER_PREMIUM": "USER_PREMIUM" 
});