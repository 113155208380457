const PRICING = Object.freeze({
  MONTH: "PRM",
  DAY: "PRD",
  HOUR: "PRH",
  UNITY: "PRU",
  WEEK: "PRW",
});

const SERVICES = Object.freeze({
  ADDITIONAL_DRIVER: "ADD",
  LUGGAGE: "LUS",
  PICK_UP_DELIVERY: "HOP",
  RETURN_DELIVERY: "HOD",
});

export default Object.freeze({
  PRICE: PRICING,
  SERVICE: SERVICES,
});
