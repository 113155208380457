import conn from "@/flit2go-web-core/services/connection";
const url =
  window.location !== window.parent.location
    ? document.location.ancestorOrigins
      ? document.location.ancestorOrigins[0]
      : document.referrer
    : document.location.origin;

export default {
  getConfigurationToken() {
    return {
      Authorization: localStorage.getItem(`apiToken-${url}`),
      "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
      "Accept-language": localStorage.getItem(`user-language`),
    };
  },
  getAvailableCategories(params) {
    return conn.$axios({
      url: `/v1/frameweb/booking/category/search`,
      method: "GET",
      params: params,
      headers: this.getConfigurationToken(),
    });
  },
  getAvailableCategoriesCatalogue(header) {
    const headers = this.getConfigurationToken();
    headers["nameAttributes"] = header.nameAttributes;
    return conn.$axios({
      url: `/v2/frameweb/category/search`,
      method: "GET",
      headers: headers,
    });
  },
  getFranchisesByCategoryId(params) {
    return conn.$axios({
      url: `/v1/frameweb/franchises/category/${params.categoryId}`,
      method: "GET",
      params: params.params,
      headers: this.getConfigurationToken(),
    });
  },
  getAvailabilityByCategoryId(params) {
    return conn.$axios({
      url: `/v1/frameweb/category/${params.categoryId}/availability`,
      method: "GET",
      params: params.params,
      headers: this.getConfigurationToken(),
    });
  },
  canShowPriceDeferredCategory(data) {
    return conn.$axios({
      url: `/v1/frameweb/category/${data.categoryId}/deferred`,
      method: "POST",
      data: data.body,
      headers: this.getConfigurationToken(),
    });
  },
  getCategoryPackRatePrice(data) {
    return conn.$axios({
      url: `/v1/frameweb/booking/category/${data.categoryId}/packRate/${data.packId}`,
      method: "POST",
      data: data.data,
      headers: this.getConfigurationToken(),
    });
  },
};
