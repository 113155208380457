import service from "@/services/userService";

export default {
  namespaced: true,
  state: {
    status: "",
    nationalities: [],
    loginParameters: {},
    emailAccess: {},
    userLoged: {
      newUser: null,
    },
    userPromotion: null,
    drivingLicenseOrigins: [],
  },
  getters: {
    status: (state) => {
      return state.status;
    },
    loginParameters: (state) => {
      return state.loginParameters;
    },
    emailAccess: (state) => {
      return state.emailAccess;
    },
    userLoged: (state) => {
      return state.userLoged;
    },
    userPromotion: (state) => {
      return state.userPromotion;
    },
  },
  mutations: {
    // Status
    STATUS_LOADING: (state) => {
      state.status = "loading";
    },
    STATUS_SUCCESS: (state) => {
      state.status = "success";
    },
    STATUS_ERROR: (state) => {
      state.status = "error";
    },
    ["SET_NATIONALITIES"]: (state, nationalities) => {
      state.nationalities = nationalities;
    },
    ["SET_LOGIN_PARAMETERS"]: (state, login) => {
      state.loginParameters = Object.assign({}, login);
    },
    ["SET_EMAIL_ACCESS"]: (state, access) => {
      state.emailAccess = Object.assign({}, access);
    },
    ["SET_USER_LOGED"]: (state, user) => {
      let newUser = state.userLoged.newUser;
      state.userLoged = Object.assign({}, user);
      state.userLoged.newUser = newUser;
    },
    ["EMPTY_USER_LOGED"]: (state) => {
      state.userLoged = {
        newUser: null,
      };
      state.loginParameters = {};
      state.emailAccess = {};
    },
    ["SET_NEW_USER"]: (state, newUser) => {
      state.userLoged.newUser = newUser;
    },
    ["SET_USER_PROMOTION"]: (state, promotion) => {
      state.userPromotion = promotion;
    },
    ["SET_DRIVING_LICENSE_ORIGINS"]: (state, drivingLicenseOrigins) => {
      state.drivingLicenseOrigins = drivingLicenseOrigins;
    },
  },
  actions: {
    createUser({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .createUser(user)
          .then((resp) => {
            commit("SET_LOGIN_PARAMETERS", resp.data.token);
            commit("SET_NEW_USER", true);
            commit("STATUS_SUCCESS");
            resolve(resp.data);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    updateUser({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .updateUser(user)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp.data);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    createInfoNewUser({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .createInfoNewUser(user)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp.data);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    updateInfoUser({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .updateInfoUser(user)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp.data);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    confirmUpdateUploadImages({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .confirmUpdateUploadImages(user)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp.data);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getNationalities({ commit }) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getNationalities()
          .then((resp) => {
            commit("SET_NATIONALITIES", resp.data.content);
            commit("STATUS_SUCCESS");
            resolve(resp.data);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getDrivingLicensesOrigins({ commit }) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getDrivingLicensesOrigins()
          .then((resp) => {
            commit("SET_DRIVING_LICENSE_ORIGINS", resp.data);
            commit("STATUS_SUCCESS");
            resolve(resp.data);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getClientId({ commit }) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getClientId()
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            commit("STATUS_ERROR");
          });
      });
    },
    validateToken({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .validateToken(data)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            commit("STATUS_ERROR");
          });
      });
    },
    changeLostPassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .changeLostPassword(data)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            commit("STATUS_ERROR");
          });
      });
    },
    changePassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .changePassword(data)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            commit("STATUS_ERROR");
          });
      });
    },
    login({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .login(params)
          .then((resp) => {
            commit("SET_LOGIN_PARAMETERS", resp.data);
            commit("SET_NEW_USER", false);
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            commit("STATUS_ERROR");
          });
      });
    },
    getUserData({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getUserData(params)
          .then((resp) => {
            commit("SET_USER_LOGED", resp.data);
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            commit("STATUS_ERROR");
          });
      });
    },
    getImageValidate({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getImageValidate(params)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            commit("STATUS_ERROR");
          });
      });
    },
    getPersonIdByUsername({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .personIdByUsername(params)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getUserByUsername({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getUserByUsername(data)
          .then((resp) => {
            commit("SET_USER_LOGED", resp.data);
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    sendEmailToUser({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .sendEmailToUser(id)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getOwner({ commit }) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getOwner()
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getPayment({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getPayment(params)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getPromotionList({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getPromotionList(params)
          .then((resp) => {
            commit("SET_USER_PROMOTION", resp.data);
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    sendPromotionalCode({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .sendPromotionalCode(params)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },

    sendUserFields({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .sendUserFields(data)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },

    changeDefaultPayment({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .changeDefaultPayment(params)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },

    deletePayment({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .deletePayment(data)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    sendEmailToUserApp({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .sendEmailToUserApp(id)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    getAgencyByCodeFrame({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .getAgencyByCodeFrame(params)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
    checkToken({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("STATUS_LOADING");
        service
          .checkToken(data)
          .then((resp) => {
            commit("STATUS_SUCCESS");
            resolve(resp);
          })
          .catch((err) => {
            commit("STATUS_ERROR");
            reject(err);
          });
      });
    },
  },
};
