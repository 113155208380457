<template>
  <div class="branch-card-detail">
    <div class="max-h-full w-full" v-if="branchInfo">
      <div class="flex justify-between mb-4">
        <F2gIcon
          value="arrow-left"
          :height="26"
          :width="26"
          :class="'cursor-pointer'"
          :stroke="colors.colorOnHeader"
          :strokeWidth="6"
          @click="$emit('returnBack')"
        />
        <F2gIcon
          value="close"
          :height="26"
          :width="26"
          :class="'cursor-pointer'"
          :stroke="colors.colorOnHeader"
          :strokeWidth="6"
          @click="$emit('close')"
        />
      </div>
      <!-- <div
        class="w-full mt-6 ml-2 mb-6"
        :style="`color:${colors.colorOnHeader} !important`"
        v-html="$PageUtils.getObjectDescriptionLower(branchInfo)"
      ></div> -->
      <div v-for="(day, index) in getScheduleList" :key="index" class="ml-2">
        <div
          class="w-full grid grid-cols-4 text-sm"
          :style="`color:${colors.colorOnHeader}`"
        >
          <div class="w-full">
            {{ $PageUtils.getObjectNameLower(day.day) }}
          </div>

          <span
            :class="day.closed ? 'text-red-700' : ''"
            :style="`color:${colors.colorOnHeader}`"
            >{{ getOpenTimeFormatted(day) }}

            {{ getOpenTimeAdditionalFormatted(day) }}
          </span>
        </div>
      </div>
      <div class="relative w-full h-64 shadow z-5 mt-6">
        <LMap
          ref="map"
          class="relative"
          :centerLocation="centerLocation"
          :isFrameweb="true"
        >
          <LMarker
            ref="marker"
            v-if="this.branchInfo"
            :lat="this.branchInfo.latitude"
            :lng="this.branchInfo.longitude"
            :zoom="false"
          />
        </LMap>
        <v-btn
          v-if="branchInfo && branchInfo.latitude"
          class="absolute bottom-2 right-2 border"
          :style="{
            backgroundColor: colors.colorPrimary,
            'border-color': colors.colorOnPrimary,
          }"
          outlined
          fab
          bottom
          right
          @click="centerMap"
        >
          <F2gIcon
            value="send"
            :stroke="colors.colorOnPrimary"
            :strokeWidth="3"
          />
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LMap from "@/flit2go-web-core/components/ui/lmap";
import LMarker from "@/flit2go-web-core/components/ui/lmarker";

export default {
  name: "BranchCardDetail",
  components: { LMap, LMarker },
  props: {
    branch: { required: true, type: Object, default: () => {} },
    type: { required: true, type: String, default: "" },
  },
  data() {
    return {
      branchInfo: null,
      centerLocation: null,
    };
  },
  watch: {
    branch() {
      if (this.branch && this.branch.id) {
        this.getBranchInfo();
      } else {
        this.branchInfo = null;
      }
    },
  },
  mounted() {
    this.centerMap();
  },

  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
    }),
    getScheduleList() {
      if (this.type === "pickUp") {
        return this.branchInfo.scheduleBranchOffice;
      } else {
        if (this.branchInfo.scheduleBranchOfficeBack.length) {
          return this.branchInfo.scheduleBranchOfficeBack;
        } else {
          return this.branchInfo.scheduleBranchOffice;
        }
      }
    },
  },
  methods: {
    getBranchInfo() {
      this.$store
        .dispatch("branchVehicle/getBranchById", this.branch.id)
        .then((resp) => {
          this.branchInfo = resp;
          this.centerLocation = [
            this.branchInfo.latitude,
            this.branchInfo.longitude,
          ];
          setTimeout(() => this.centerMap(), 500);
        });
    },
    centerMap() {
      if (this.branchInfo && this.$refs.map) {
        this.$refs.map.centerMap([
          this.branchInfo.latitude,
          this.branchInfo.longitude,
        ]);
      }
    },
    getHourOpen(day, additional = false) {
      let label = "";
      if (!additional) {
        if (day.openTime) {
          label = day.openTime.split(":")[0] + ":" + day.openTime.split(":")[1];
        } else {
          if (day.open24h) {
            label = "00:00";
          }
        }
      } else {
        const openTimeAdditionalSplitted = day.openTimeAdditional.split(":");
        label =
          label +
          openTimeAdditionalSplitted[0] +
          ":" +
          openTimeAdditionalSplitted[1];
      }
      return label;
    },
    getHourClose(day, additional = false) {
      let label = "";
      if (!additional) {
        if (day.closeTime) {
          label =
            day.closeTime.split(":")[0] + ":" + day.closeTime.split(":")[1];
        } else {
          if (day.open24h) {
            label = "24:00";
          }
        }
      } else {
        const closeTimeAdditionalSplitted = day.closeTimeAdditional.split(":");
        label =
          label +
          closeTimeAdditionalSplitted[0] +
          ":" +
          closeTimeAdditionalSplitted[1];
      }
      return label;
    },
    getOpenTimeFormatted(day) {
      const openTime = this.getHourOpen(day);
      const closeTime = this.getHourClose(day);
      let timeFormatted = "";
      if (openTime === "00:00" && closeTime === "24:00") {
        timeFormatted = "24h";
      } else {
        timeFormatted = `${openTime}-${closeTime}`;
      }
      if (day.closed) {
        timeFormatted = this.$t("common.closed");
      }
      return timeFormatted;
    },
    getOpenTimeAdditionalFormatted(day) {
      if (day.openTimeAdditional) {
        return `${this.getHourOpen(day, true)}-${this.getHourClose(day, true)}`;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.locationRow:hover {
  background-color: $f2g-light-gray-2;
}
</style>
