const RESERVE_LOG_TYPE = Object.freeze({
  BOTH: "BOT",
  CANCELED_F2G: "CF2",
  CANCELED_MANAGER: "CMA",
  RESERVE_CREATION: "COR",
  CANCELED_USER: "CUS",
  RESERVE_COMPLETION: "FRT",
  MODIFIED_F2G: "MF2",
  MODIFIED_MANAGER: "MMA",
  EXPANDED_USER: "MUS",
  RENTAL_START: "SRT",
  SHORTENED_USER: "SUS",
  MODIFICATION_IN_TRAVEL: "MIT",
  MODIFICATION_OF_SUBSCRIPTION: "MSB",
  CREATION_FRAME: "COF",
  CREATION_MANAGER: "COF",
  CREATION: "COR",
});

const RESERVE_LOG_STATUS = Object.freeze({
  CANCELED: "CAN",
  CREATED: "CRE",
  IN_TRAVEL: "INT",
  PENDING: "PEN",
  RENTAL_NOT_STARTED: "RNS",
  TRAVEL_FINISHED: "TFI",
});

export default {
  TYPE: RESERVE_LOG_TYPE,
  STATUS: RESERVE_LOG_STATUS,
};
