<template>
  <div class="no-data" :style="'background:' + ownerSurfaceColor">
    <v-layout
      column
      align-center
      class="my-2"
      :style="'color:' + ownerSurfaceColor"
    >
      <v-img
        :src="src"
        contain
        :class="{
          'image-big': !small && !medium && !xsmall,
          'image-medium': medium,
          'image-small': small,
          'image-x-small': xsmall,
        }"
      />
      <v-layout justify-center>
        <span
          class="no-data__text mt-6 mb-2"
          :style="'color:' + ownerOnSurfaceColor"
          v-html="title"
        ></span>
      </v-layout>
    </v-layout>
  </div>
</template>

<script>
import NoDataEnum from "@/flit2go-web-core/interfaces/no-data-enum";

export default {
  name: "NoData",
  props: {
    type: {
      type: String,
      default: NoDataEnum.enum().DATA,
    },
    title: String,
    medium: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xsmall: {
      type: Boolean,
      default: false,
    },
    invertedColorTitle: {
      type: Boolean,
      default: false,
    },
    ownerOnSurfaceColor: {
      type: String,
      default: "",
    },
    ownerSurfaceColor: {
      type: String,
      default: "",
    },
  },
  beforeMount() {
    this.src = require("@/flit2go-web-core/assets/no-information/" + this.type);
  },
  data: () => ({
    NoDataEnum,
    src: "",
  }),
  computed: {
    colorTitleStyle() {
      let classes = "";
      if (this.invertedColorTitle) {
        classes = "title-style-inverted";
      } else {
        classes = "title-style";
      }
      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
.no-data {
  height: 100%;
  width: 100%;

  .image-big {
    height: 350px;
    width: 350px;
  }

  .image-medium {
    height: 200px;
    width: 200px;
  }

  .image-small {
    height: 150px;
    width: 150px;
  }

  .image-x-small {
    height: 100px;
    width: 100px;
  }

  .title-style {
    color: $gray-1-f2g;
  }
  .title-style-inverted {
    color: $f2g-light-gray-2;
  }

  &__text {
    text-align: center;
  }
}
</style>
