<template>
  <div class="category-cardV2">
    <div class="flex flex-row align-center pt-4" v-if="isDetail" id="top-part">
      <div
        class="p-1 rounded-full shadow mr-2 cursor-pointer"
        :style="{ backgroundColor: colors.colorSurface }"
      >
        <F2gIcon
          class="inline-block align-middle"
          :stroke="colors.colorPrimary"
          :value="'arrow-left'"
          :height="28"
          :width="28"
          :strokeWidth="4"
          @click="$emit('close')"
        />
      </div>
      <div
        class="text-sm cursor-pointer"
        :style="{ color: colors.colorPrimary }"
        @click="$emit('close')"
      >
        {{ $t("returnToVehicles") }}
      </div>
    </div>
    <div class="flex flex-row flex-wrap my-4">
      <div
        class="w-full max-h-full lg:min-w-18 flex flex-1 md:align-left md:flex-row relative rounded-lg border-black shadow"
        :style="{ backgroundColor: colors.colorSurface }"
        :class="{
          'mb-3': showResume,
          'transition-all duration-500 transform hover:shadow-xl hover:-translate-y-1 cursor-pointer': !isDetail,
        }"
        @click="
          categoryRow.notAvailable
            ? ''
            : $emit('categorySelected', categoryRow, true)
        "
      >
        <div class="flex flex-col">
          <div
            class="w-full h-12 rounded-t-lg p-1 flex align-center"
            :style="{ backgroundColor: colors.colorSurfaceMedium }"
          >
            <h1
              class="text-lg md:text-title font-bold ml-2 overflow-hidden h-full flex align-center"
              :style="{ color: colors.colorOnSurface }"
            >
              {{ categoryRow.name }}
            </h1>
            <v-chip
              v-if="categoryRow.notAvailable"
              class="ma-2 absolute top-0 z-5 text-xs right-2"
              color="warning"
            >
              {{ $t("temporarilyNoStock") }}
            </v-chip>
            <div
              v-if="
                !categoryRow.deferredPayment &&
                  !showResume &&
                  !isDetail &&
                  !categoryRow.notAvailable
              "
              class="absolute right-0 top-0 h-12 rounded-tr-lg rounded-bl-lg pt-1 px-3 pb-3 text-center"
              :style="{
                backgroundColor: colors.colorPrimary,
                color: colors.colorOnPrimary,
              }"
            >
              <div>
                {{
                  BillingUtil.formatBillingValue(
                    returnPrice(),
                    categoryRow.symbol
                  )
                }}
              </div>
              <div
                class="text-xxs"
                :style="{
                  color: colors.colorOnPrimary,
                }"
              >
                {{
                  BillingUtil.formatBillingValue(
                    getPricePerDay(),
                    categoryRow.symbol
                  )
                }}<span class="text-xxs">
                  {{
                    categoryRow.timeType == "day"
                      ? `/${$t("common.day")}`
                      : `/${$t("common.hour")}`
                  }}</span
                >
              </div>
            </div>
          </div>
          <div class="flex flex-row">
            <div
              class="flex flex-col"
              :class="{
                'min-w-320 max-w-320': showResume,
                'min-w-18': !showResume,
              }"
            >
              <v-flex class="inline-block">
                <v-img
                  v-if="categoryRow.img && !isDetail"
                  :src="categoryRow.img"
                  :aspect-ratio="16 / 9"
                  class="image rounded-bl-lg"
                />
                <div
                  class="flex align-center justify-center w-full flex-col"
                  v-else
                >
                  <div
                    class="bg-gray-200 max-h-full relative mx-2 w-full"
                    :class="{ 'rounded-r-lg': showResume }"
                  >
                    <Carousel
                      :images="images"
                      :showPagination="false"
                      :showFooter="false"
                      :borderRadius="showResume ? '20px' : '0'"
                    >
                    </Carousel>
                  </div>
                </div>
              </v-flex>
            </div>
            <div class="flex flex-col" v-if="showResume">
              <div
                v-if="showResume"
                class="max-h-1/4 min-h-1/4 w-full overflow-clip overflow-hidden overflow-y-auto min-w-64"
              >
                <!-- CategoryDesciption -->
                <div
                  v-if="categoryRow.description"
                  class="m-2 md:text-sm text-xs overflow-clip overflow-hidden"
                  :style="{ color: colors.colorOnSurface }"
                  v-html="
                    categoryRow.description[this.$i18n.locale.split('_')[0]]
                  "
                ></div>
              </div>
              <div
                v-if="showResume"
                class="w-full ml-2 pt-1"
                :style="{ color: colors.colorPrimary }"
              >
                <span class="transform hover:scale-125">{{
                  $t("seeMore")
                }}</span>
              </div>
              <div
                class="h-12 w-full rounded-br-lg"
                v-if="categoryRow.attributeList.length"
              >
                <div
                  class="max-h-full w-full px-2 flex flex-row align-center overflow-x-hidden category_services"
                  :class="{ 'rounded-br-lg': showResume }"
                  :style="{ backgroundColor: colors.colorSurfaceMedium }"
                >
                  <div
                    v-for="(attribute, index) in categoryRow.attributeList"
                    :key="index"
                  >
                    <div
                      v-if="index < 3"
                      class="inline-block my-2 text-sm align-middle mr-2 flex flex-row"
                    >
                      <div class="pa-1 m-1 flex flex-none align-center">
                        <svg
                          style="height: 24px; width: 24px"
                          :fill="colors.colorPrimary"
                        >
                          <path
                            v-if="attribute.icon.path"
                            :d="attribute.icon.path"
                          />
                        </svg>
                      </div>
                      <div
                        class="flex align-center inline-block align-middle text-xs mt-1"
                        :style="{ color: colors.colorOnSurface }"
                      >
                        {{
                          attribute.supportedLangs[$i18n.locale.split("_")[0]]
                            ? attribute.supportedLangs[
                                $i18n.locale.split("_")[0]
                              ]
                            : attribute.supportedLangs.en
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CategoryCardPack
        v-for="(pack, index) in getPacks"
        :key="pack.id"
        :index="index"
        :category="categoryRow"
        :showResume="showResume"
        @showDescription="(packIndex) => (showPackDescription = packIndex)"
        @changeDates="() => (showModalEdit = true)"
        @packSelected="packSelected"
      />

      <transition name="fade">
        <Card
          class="helpCard"
          v-if="showPackDescription > -1"
          v-on-clickaway="() => (showPackDescription = -1)"
          ><div class="pa-3">
            <span
              class="mt-1 message"
              v-html="
                categoryRow.packRate[showPackDescription].description[language]
                  ? categoryRow.packRate[showPackDescription].description[
                      language
                    ]
                  : categoryRow.packRate[showPackDescription].description.en
              "
            ></span>
          </div>
        </Card>
      </transition>

      <div
        v-if="
          !hasPacks &&
            (!isDetail ||
              showResume ||
              (categoryRow.deferredPayment && isDetail)) &&
            !categoryRow.notAvailable
        "
        class="xl:w-56 lg:w-40 min-max-h-full flex lg:flex-none md:align-left md:flex-row relative shadow"
        :style="{ backgroundColor: colors.colorSurface }"
        :class="{
          'mb-3': !isDetail,
          'w-1/2': !isDetail || (isDetail && categoryRow.deferredPayment),
          'w-full': isDetail && !categoryRow.deferredPayment,
          'rounded-lg':
            ((!categoryRow.deferredPayment && showResume) ||
              (categoryRow.errorDatesReserve && showResume)) &&
            !isDetail,
          'rounded-l-lg':
            categoryRow.deferredPayment &&
            showResume &&
            !categoryRow.errorDatesReserve &&
            !isDetail,
          'rounded-b-lg':
            ((!categoryRow.deferredPayment && !showResume) ||
              (categoryRow.deferredPayment && categoryRow.errorDatesReserve)) &&
            !isDetail,
          'rounded-bl-lg':
            categoryRow.deferredPayment &&
            !showResume &&
            !categoryRow.errorDatesReserve &&
            !isDetail,
        }"
      >
        <div class="flex flex-col p-3 max-h-full">
          <div
            class="max-h-full flex flex-col justify-between"
            v-if="!categoryRow.errorDatesReserve"
          >
            <div v-if="showResume || categoryRow.deferredPayment">
              <div
                class="text-titleSmall xl:text-title font-bold"
                :style="{ color: colors.colorOnSurface }"
              >
                {{ $t("prepaid") }}
              </div>
              <div
                class="text-xs xl:text-sm mt-2"
                :style="{ color: colors.colorOnSurface }"
              >
                {{ $t("payNowPrepaid") }}
              </div>
            </div>
            <div
              :class="{
                'xl:mt-2 mt-3': showResume || categoryRow.deferredPayment,
              }"
            >
              <div
                v-if="showResume || categoryRow.deferredPayment"
                class="xl:text-3xl text-2xl font-bold"
                :style="{ color: colors.colorPrimary }"
              >
                {{
                  BillingUtil.formatBillingValue(
                    returnPrice(),
                    categoryRow.symbol
                  )
                }}
              </div>
              <div
                v-if="showResume || categoryRow.deferredPayment"
                class="text-xl font-bold"
                :style="{ color: colors.colorOnSurfaceMedium }"
              >
                {{
                  BillingUtil.formatBillingValue(
                    getPricePerDay(),
                    categoryRow.symbol
                  )
                }}<span class="text-xs">
                  {{
                    categoryRow.timeType == "day"
                      ? `/${$t("common.day")}`
                      : `/${$t("common.hour")}`
                  }}</span
                >
              </div>
              <v-btn
                class="w-full border-2 rounded-config30"
                :class="{
                  'mt-3': showResume || categoryRow.deferredPayment,
                  'text-xs': reducePaymentCard,
                }"
                :style="{
                  backgroundColor: colors.colorPrimary,
                  color: colors.colorOnPrimary,
                }"
                @click="
                  () => {
                    $emit('categorySelected', categoryRow);
                    $emit(
                      'selected',
                      categoryRow.deferredPayment ? true : false
                    );
                  }
                "
              >
                {{ $t("select") }}
              </v-btn>
            </div>
          </div>
          <div
            v-else
            class="text-normal text-red-700 max-w-lg w-full lg:min-h-1/4"
          >
            <div
              class="text-sm font-bold flex flex-row justify-center align-center errorText"
            >
              {{ categoryRow.txtErrorDatesReserve }}
            </div>
            <div class="flex flex-row justify-end align-end md:h-40 h-12">
              <v-btn
                class="w-full border-2 rounded-config30 text-xs px-1"
                :class="{ 'mt-2': showResume || categoryRow.deferredPayment }"
                :style="{
                  backgroundColor: colors.colorPrimary,
                  color: colors.colorOnPrimary,
                }"
                @click="showModalEdit = true"
              >
                {{ $t("modifyDates") }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          !hasPacks &&
            !categoryRow.deferredPayment &&
            isDetail &&
            !showResume &&
            !categoryRow.notAvailable
        "
        class="w-full max-h-full flex lg:flex-none md:align-left md:flex-row relative shadow rounded-b-lg"
        :style="{ backgroundColor: colors.colorPrimary }"
      >
        <div class="flex flex-col p-3 max-h-full">
          <div
            class="flex flex-row max-h-full"
            v-if="!categoryRow.errorDatesReserve"
          >
            <div class="flex flex-col ml-3">
              <div
                class="xl:text-2xl text-xl font-bold"
                :style="{ color: colors.colorOnPrimary }"
              >
                {{
                  BillingUtil.formatBillingValue(
                    returnPrice(),
                    categoryRow.symbol
                  )
                }}
              </div>
              <div
                class="text-sm font-bold"
                :style="{ color: colors.colorOnPrimary }"
              >
                {{
                  BillingUtil.formatBillingValue(
                    getPricePerDay(),
                    categoryRow.symbol
                  )
                }}<span class="text-xs">
                  {{
                    categoryRow.timeType == "day"
                      ? `/${$t("common.day")}`
                      : `/${$t("common.hour")}`
                  }}</span
                >
              </div>
            </div>
            <v-btn
              class="w-36 border-2 rounded-config30 mt-2"
              :style="{
                backgroundColor: colors.colorOnPrimary,
                color: colors.colorPrimary,
              }"
              @click="
                () => {
                  $emit('selected', categoryRow.deferredPayment ? true : false);
                }
              "
            >
              {{ $t("select") }}
            </v-btn>
          </div>
          <div v-else class="my-2 text-normal text-red-700 max-w-lg w-full">
            <div
              class="text-sm font-bold flex flex-row justify-center align-center errorText"
            >
              {{ categoryRow.txtErrorDatesReserve }}
            </div>
            <div class="flex flex-row justify-end align-end h-12">
              <v-btn
                class="w-full border-2 rounded-config30 mt-2"
                :style="{
                  backgroundColor: colors.colorOnPrimary,
                  color: colors.colorPrimary,
                }"
                @click="showModalEdit = true"
              >
                {{ $t("modifyDates") }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          !hasPacks &&
            categoryRow.deferredPayment &&
            !categoryRow.errorDatesReserve &&
            !categoryRow.notAvailable
        "
        class="w-1/2 xl:w-56 lg:w-40 min-max-h-full flex lg:flex-none md:align-left md:flex-row relative shadow"
        :class="{
          'rounded-r-lg': showResume && !isDetail,
          'rounded-br-lg': !showResume && !isDetail,
          'mb-3': !isDetail,
        }"
        :style="{ backgroundColor: colors.colorSurface }"
      >
        <div class="flex flex-col p-3 max-h-full">
          <div
            class="max-h-full flex flex-col justify-between"
            v-if="!categoryRow.errorDatesReserve"
          >
            <div>
              <div
                class="xl:text-title font-bold text-titleSmall"
                :style="{ color: colors.colorOnSurface }"
              >
                {{ getTotalPriceDeferredTitle }}
              </div>
              <div
                class="mt-2"
                :class="{
                  'xl:text-sm text-xs': !reducePaymentCard,
                  'text-xxs': reducePaymentCard,
                }"
                :style="{ color: colors.colorOnSurface }"
                v-html="getTotalPriceDeferredText"
              ></div>
            </div>
            <div
              class="xl:mt-2"
              :class="{
                'mt-3': !reducePaymentCard,
                'mt-1': reducePaymentCard,
              }"
            >
              <div
                class="xl:text-3xl font-bold"
                :class="{
                  'text-2xl': !reducePaymentCard,
                  'text-xl': reducePaymentCard,
                }"
                :style="{ color: colors.colorPrimary }"
              >
                {{
                  BillingUtil.formatBillingValue(
                    categoryRow.totalPrice ? categoryRow.totalPrice : 0,
                    categoryRow.symbol
                  )
                }}
              </div>
              <div
                class="font-bold"
                :class="{
                  'text-xl': !reducePaymentCard,
                  'text-lg': reducePaymentCard,
                }"
                :style="{ color: colors.colorOnSurfaceMedium }"
              >
                {{
                  BillingUtil.formatBillingValue(
                    categoryRow.pricePerTime,
                    categoryRow.symbol
                  )
                }}<span class="text-xs">
                  {{
                    categoryRow.timeType == "day"
                      ? `/${$t("common.day")}`
                      : `/${$t("common.hour")}`
                  }}</span
                >
              </div>
              <v-btn
                class="w-full border-2 rounded-config30"
                :class="{
                  'mt-3': !reducePaymentCard,
                  'mt-1 text-xs': reducePaymentCard,
                }"
                :style="{
                  backgroundColor: colors.colorPrimary,
                  color: colors.colorOnPrimary,
                }"
                @click="
                  () => {
                    $emit('categorySelected', categoryRow);
                    $emit('selected', false);
                  }
                "
              >
                {{ $t("select") }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="isDetail && !showResume && !categoryRow.notAvailable"
        class="w-1/2 xl:w-56 lg:w-40 max-h-full mb-3 flex lg:flex-none md:align-left md:flex-row relative shadow rounded-b-lg"
        :style="{ backgroundColor: colors.colorSurface }"
      >
        <div
          class="w-full overflow-clip overflow-hidden overflow-y-auto mx-2 ml-1"
        >
          <!-- CategoryDesciption -->
          <div
            v-if="categoryRow.description"
            class="m-2 md:text-sm text-xs overflow-clip overflow-hidden"
            :style="{ color: colors.colorOnSurface }"
            v-html="categoryRow.description[this.$i18n.locale.split('_')[0]]"
          ></div>
        </div>
      </div>
    </div>
    <!-- <TailDialog
      :backgroundColor="colors.colorBackground"
      :closeButtonBackgroundColor="colors.colorPrimary"
      :closeButtonLogoColor="colors.colorOnPrimary"
      colorCloseBorder="gray"
      :show="showModalEdit"
      @close="showModalEdit = false"
    >
      <template slot="content">
        <MainFilterComponentNew
          v-if="showModalEdit"
          style="min-height: 600px; display: flex"
          :style="`min-width: ${
            this.$vuetify.breakpoint.name === 'sm' ||
            this.$vuetify.breakpoint.name === 'xs'
              ? ''
              : '600px'
          }`"
          :type="'vertical'"
          :edit="showModalEdit ? true : false"
          :bookingFiltersOld="bookingFilters"
          @close="showModalEdit = false"
        />
      </template>
    </TailDialog> -->
    <LateralPanel :open="showModalEdit" ref="lateralPanel">
      <template slot="content">
        <MainFilterComponentNew
          style="min-height: 600px; display: flex"
          :style="
            `min-width: ${
              this.$vuetify.breakpoint.name === 'sm' ||
              this.$vuetify.breakpoint.name === 'xs'
                ? ''
                : '600px'
            }`
          "
          :type="'vertical'"
          :edit="true"
          showCancelButton
          :bookingFiltersOld="bookingFilters"
          @close="showModalEdit = false"
        />
      </template>
    </LateralPanel>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BillingUtil from "@/flit2go-web-core/util/billingUtil.js";
import MainFilterComponentNew from "@/modules/v2/Components/main-filter-component-new.vue";
// import TailDialog from "@/components/tail-dialog";
import LateralPanel from "@/flit2go-web-core/components/frameweb/lateral-panel";
import Carousel from "@/flit2go-web-core/components/base/carousel";
import { directive as onClickaway } from "vue-clickaway";

export default {
  name: "CategoryCardV2",
  directives: {
    onClickaway,
  },
  components: {
    MainFilterComponentNew,
    // TailDialog,
    Carousel,
    LateralPanel,
    Card: () => import("@/flit2go-web-core/components/ui/card"),
    CategoryCardPack: () =>
      import("@/modules/v2/Components/category-card-pack"),
  },
  props: {
    categoryRow: { required: true, type: Object, default: () => [] },
    isDetail: { required: true, type: Boolean, default: false },
  },
  data() {
    return {
      BillingUtil,
      loading: true,
      showResume: window.innerWidth >= 640,
      showModalEdit: false,
      reducePaymentCard: window.innerWidth <= 320,
      showPackDescription: -1,
    };
  },
  watch: {
    showModalEdit() {
      if (this.showModalEdit) {
        window.parent.postMessage("goToTop", "*");
      }
    },
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  computed: {
    ...mapState(["category", "configuration"]),
    ...mapGetters({
      colors: "configuration/getColors",
      bookingFilters: "configuration/getBookingFilters",
      language: "configuration/getUserLanguage",
    }),
    images() {
      return [this.categoryRow.img].concat(this.categoryRow.image);
    },
    getTotalPriceDeferredTitle() {
      let title = "";
      if (this.categoryRow?.deferredPayment) {
        const total = this.categoryRow?.totalPriceDeferred
          ? this.categoryRow.totalPriceDeferred
          : 0;
        if (this.categoryRow.deferredStartRental) {
          title =
            Number(this.categoryRow.deferredPaymentPercentageFirst) > 0
              ? this.$t("payNowAndRestOnVehicleDelivery", {
                  amount: BillingUtil.formatBillingValue(
                    total,
                    this.categoryRow.symbol
                  ),
                })
              : this.$t("payOnDeliveryVehicle");
        } else {
          title = this.$t("PayNowAmount", {
            amount: BillingUtil.formatBillingValue(
              total,
              this.categoryRow.symbol
            ),
          });
        }
      }
      return title;
    },
    getTotalPriceDeferredText() {
      let text = "";
      if (this.categoryRow?.deferredPayment) {
        const total = this.categoryRow?.totalPriceDeferred
          ? this.categoryRow.totalPriceDeferred
          : 0;
        if (this.categoryRow.deferredStartRental) {
          text =
            Number(this.categoryRow.deferredPaymentPercentageFirst) > 0
              ? this.$t("payNowAndCollectRestOnVehicleDelivery", {
                  amount: BillingUtil.formatBillingValue(
                    total,
                    this.categoryRow.symbol
                  ),
                })
              : this.$t("payNothingNowPayOnVehicleDelivery");
        } else {
          text = this.$t("payNowAndCollectBeforeRental", {
            amount: BillingUtil.formatBillingValue(
              total,
              this.categoryRow.symbol
            ),
          });
        }
      }
      return text.replace("<b>", '<b style="font-size:calc(100% + 2px)">');
    },
    hasPacks() {
      return this.categoryRow.packRate?.length > 0;
    },
    getPacks() {
      return this.hasPacks && (!this.isDetail || this.showResume)
        ? this.categoryRow.packRate
        : [];
    },
  },
  methods: {
    myEventHandler(e) {
      if (window.innerWidth >= 640) {
        this.showResume = true;
      } else {
        this.showResume = false;
      }
      if (window.innerWidth <= 320) {
        this.reducePaymentCard = true;
      } else {
        this.reducePaymentCard = false;
      }
    },
    getPricePerDay() {
      if (this.categoryRow.deferredPayment) {
        return this.categoryRow.pricePerTimeDeferred;
      } else {
        return this.categoryRow.pricePerTime
          ? this.categoryRow.pricePerTime.toFixed(2)
          : 0;
      }
    },
    returnPrice() {
      if (
        this.categoryRow.deferredPayment &&
        this.categoryRow.deferredDiscount
      ) {
        return this.categoryRow.totalPriceDeferredDiscount;
      } else {
        return this.categoryRow.totalPrice ? this.categoryRow.totalPrice : "0";
      }
    },
    packSelected(pack) {
      this.$emit("categorySelected", this.categoryRow);
      this.$emit("selected", false, pack);
    },
  },
};
</script>

<style lang="scss" scoped>
.category-cardV2 {
  .helpCard {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3), 0 0 10px rgba(0, 0, 0, 0.096) !important;
    position: absolute;
    z-index: 1000 !important;
    text-align: justify;
    text-justify: inter-word;
    min-width: 400px;
    max-width: 800px;
    left: 40%;
  }
  @media (max-width: 800px) {
    .helpCard {
      left: 2%;
      max-width: 500px;
      position: fixed;
      top: 40%;
    }
  }
  @media (max-width: 500px) {
    .helpCard {
      left: 2%;
      max-width: 300px;
      position: fixed;
      top: 40%;
    }
  }
  @media (max-width: 400px) {
    .helpCard {
      left: 2%;
      max-width: 300px;
      position: fixed;
      top: 40%;
    }
  }
  @media (max-width: 300px) {
    .helpCard {
      left: 2%;
      max-width: 200px;
      position: fixed;
      top: 40%;
    }
  }
}
</style>
