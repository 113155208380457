<template>
  <div class="redsys-form-redirection">
    <form
      name="form"
      ref="form"
      action="https://sis-t.redsys.es:25443/sis/realizarPago"
      method="POST"
    >
      <input
        type="hidden"
        name="Ds_SignatureVersion"
        :value="signatureVersion"
      />
      <input
        type="hidden"
        name="Ds_MerchantParameters"
        :value="merchantParameters"
      />
      <input type="hidden" name="Ds_Signature" :value="signature" />
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import conn from "@/flit2go-web-core/services/connection";

// const Redsys = require("node-redsys-api").Redsys;

// function createPayment(
//   total,
//   merchantOrder,
//   merchantCode,
//   merchantTerminal,
//   secret
// ) {
//   const redsys = new Redsys();
//   const mParams = {
//     DS_MERCHANT_AMOUNT: total,
//     DS_MERCHANT_CURRENCY: "978",
//     DS_MERCHANT_MERCHANTCODE: merchantCode,
//     DS_MERCHANT_MERCHANTURL: "http://localhost:8080/urlNotificacion.php",
//     DS_MERCHANT_ORDER: merchantOrder,
//     DS_MERCHANT_TERMINAL: merchantTerminal,
//     DS_MERCHANT_TRANSACTIONTYPE: "0",
//     DS_MERCHANT_URLKO: "http://localhost:8080/urlKO.php",
//     DS_MERCHANT_URLOK: "http://localhost:8080/urlOK.php",
//   };

//   return {
//     signature: redsys.createMerchantSignature(secret, mParams),
//     merchantParameters: redsys.createMerchantParameters(mParams),
//     raw: mParams,
//   };
// }

export default {
  name: "RedsysFormRedirection",
  components: {},
  props: {
    sendData: {
      type: Boolean,
      required: false,
      default: false,
    },
    totalPrice: {
      type: Number,
      required: false,
      default: null,
    },
    token: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      merchantOrder: null,
      merchantTerminal: null,
      merchantCode: null,
      errorCode: null,
      ds_EMV3DS: null,
      signatureVersion: null,
      secret: null,
      signature: null,
      merchantParameters: null,
      priceTotal: 0,
      priceTotalDeferred: null,
    };
  },
  validations: {},
  created() {
    this.pedido();
    if (this.redsysConfiguration) {
      this.merchantTerminal = this.redsysConfiguration.merchantTerminal;
      this.merchantCode = this.redsysConfiguration.merchantCode;
      this.signatureVersion = this.redsysConfiguration.signatureVersion;
      this.secret = this.redsysConfiguration.secret;
    }
  },
  mounted() {},
  watch: {
    sendData() {
      if (this.sendData) {
        this.submitForm();
      }
    },
  },
  computed: {
    ...mapState(["configuration"]),
    ...mapGetters({
      bookingFilters: "configuration/getBookingFilters",
      actualPrice: "configuration/getActualPrice",
      categorySelected: "category/categorySelected",
      extrasSelected: "configuration/getExtrasSelected",
      additionalsSelected: "configuration/getAdditionalsSelected",
      loginParameters: "user/loginParameters",
      franchiseSelected: "category/franchiseSelected",
      userLoged: "user/userLoged",
      colors: "configuration/getColors",
      labels: "configuration/getLabels",
      language: "configuration/getUserLanguage",
      filters: "configuration/getFilters",
      redsysConfiguration: "configuration/getRedsysConfiguration",
    }),
    getErrorText() {
      switch (this.errorCode) {
        case "msg1":
          return this.$t("msg1");
        case "msg2":
          return this.$t("msg2");
        case "msg3":
          return this.$t("msg3");
        case "msg4":
          return this.$t("msg4");
        case "msg5":
          return this.$t("msg5");
        case "msg6":
          return this.$t("msg6");
        case "msg7":
          return this.$t("msg7");
        case "msg8":
          return this.$t("msg8");
        case "msg9":
          return this.$t("msg9");
        case "msg10":
          return this.$t("msg10");
        case "msg11":
          return this.$t("msg11");
        case "msg12":
          return this.$t("msg12");
        case "msg13":
          return this.$t("msg13");
        case "msg14":
          return this.$t("msg14");
        case "msg15":
          return this.$t("msg15");
        case "msg16":
          return this.$t("msg16");
        case "msg17":
          return this.$t("msg17");
        case "msg18":
          return this.$t("msg18");
        default:
          return null;
      }
    },
    getTotalPrice() {
      let totalPrice = 0;
      let priceTotalDeferred = null;
      if (this.actualPrice.priceTotal) {
        totalPrice =
          this.actualPrice.priceTotal.value +
          Number(
            (
              (this.actualPrice.priceTotal.value *
                this.actualPrice.priceTotal.tax) /
              100
            ).toFixed(2)
          );
      }
      if (
        this.actualPrice.priceDeferred &&
        !this.categorySelected.earlyPay &&
        this.categorySelected.deferredPayment
      ) {
        priceTotalDeferred = 0;
        priceTotalDeferred =
          this.actualPrice.priceDeferred.value +
          Number(
            (
              (this.actualPrice.priceDeferred.value *
                this.actualPrice.priceDeferred.tax) /
              100
            ).toFixed(2)
          );
        priceTotalDeferred = Number((priceTotalDeferred * 100).toFixed(2));
      }
      totalPrice = Number((totalPrice * 100).toFixed(2));

      return priceTotalDeferred !== null
        ? priceTotalDeferred.toString()
        : totalPrice.toString();
    },
  },
  methods: {
    pedido() {
      this.merchantOrder = Math.floor(Math.random() * 1000000000 + 1);
      return this.merchantOrder.toString();
    },
    submitForm() {
      const url =
        window.location !== window.parent.location
          ? document.location.ancestorOrigins
            ? document.location.ancestorOrigins[0]
            : document.referrer
          : document.location.origin;
      const ownerId = localStorage.getItem("ownerId");
      conn
        .$axios({
          url: `v1/redsys/iniciaPeticion`,
          method: "POST",
          headers: {
            "x-requested-with": localStorage.getItem(`windowUrl-${url}`),
            "Accept-language": localStorage.getItem(`user-language`),
          },
          data: {
            cofIni: "S",
            cofType: "I",
            identifier: "REQUIRED",
            merchantCode: this.merchantCode,
            terminal: this.merchantTerminal,
            order: this.merchantOrder,
            currency: "978",
            transactionType: "0",
            ownerId: ownerId,
            amount: this.totalPrice
              ? this.totalPrice.toString()
              : this.getTotalPrice,
            urlok:
              process.env.VUE_APP_PAYMENTS_URL +
              `/#/mainView?token=${this.token}&paymentOk=true&ownerId=${ownerId}`,
            urlko:
              process.env.VUE_APP_PAYMENTS_URL +
              `/#/mainView?token=${this.token}&error=true&ownerId=${ownerId}`,
          },
        })
        .then((resp) => {
          if (resp.status === 200) {
            this.merchantParameters = resp.data.Ds_MerchantParameters;
            this.signature = resp.data.Ds_Signature;
            this.signatureVersion = resp.data.Ds_SignatureVersion;

            setTimeout(() => {
              this.$refs.form.submit();
            }, 3000);
          }
        })
        .catch((err) => {
          this.$emit("error");
          this.showError(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
