import conn from "@/flit2go-web-core/services/connection";

export default {
  confirmPaymentById(paymentId, description, params) {
    return conn.$axios({
      url: `secured/v1/stripe/pay/${paymentId}/reservation/offline`,
      headers: {
        "content-type": "text/plain",
      },
      method: "PUT",
      data: description,
      params: params,
    });
  },
  convertReceiptToInvoice(paymentId, description) {
    return conn.$axios({
      url: `secured/v1/stripe/pay/paymentModify/${paymentId}`,
      headers: {
        "content-type": "text/plain",
      },
      method: "PUT",
      data: description,
    });
  },
  convertReceiptToInvoiceBilling(paymentId, description, params) {
    return conn.$axios({
      url: `secured/v1.1/billing/stripe/pay/paymentModify/${paymentId}`,
      headers: {
        "content-type": "text/plain",
      },
      method: "PUT",
      data: description,
      params: params,
    });
  },
  convertReceiptToInvoiceBillingRental(paymentId, description, params) {
    return conn.$axios({
      url: `secured/v1.1/billing/stripe/pay/paymentModify/${paymentId}/rental`,
      headers: {
        "content-type": "text/plain",
      },
      method: "PUT",
      data: description,
      params: params,
    });
  },
  sendByMail(params) {
    return conn.$axios({
      url: `secured/v1/stripe/pay/sendMail/${params.paymentId}`,
      method: "GET",
      params: { isInvoice: params.isInvoice },
    });
  },
  sendToSII(paymentId) {
    return conn.$axios({
      url: `secured/v1/payment/${paymentId}/sii`,
      method: "PUT",
    });
  },
  sendByMailBilling(params) {
    return conn.$axios({
      url: `secured/v1/billing/stripe/pay/sendMail/${params.paymentId}`,
      method: "GET",
      params: { isInvoice: params.isInvoice },
    });
  },
  cancelPayment(idPayment) {
    return conn.$axios({
      url: `secured/v1/stripe/pay/cancel/${idPayment}`,
      method: "PUT",
    });
  },
  changeToOnline(idPayment) {
    return conn.$axios({
      url: `secured/v1/stripe/pay/change/online/${idPayment}`,
      method: "PUT",
    });
  },
  changeToOffline(idPayment) {
    return conn.$axios({
      url: `secured/v1/stripe/pay/change/offline/force/${idPayment}`,
      method: "PUT",
    });
  },
  refundPayment(params) {
    return conn.$axios({
      url: `secured/v1/payment/${params.id}/refund`,
      method: "POST",
      params: params,
    });
  },
  modifyPayment(params) {
    return conn.$axios({
      url: `secured/v1/payment/${params.id}/modification`,
      method: "POST",
      params: params.params,
      data: params.data,
    });
  },
  modifyPaymentRental(params) {
    return conn.$axios({
      url: `secured/v1/payment/${params.id}/modification/rental`,
      method: "POST",
      params: params.params,
      data: params.data,
    });
  },
  refundPaymentBilling(params) {
    return conn.$axios({
      url: `secured/v1/billing/payment/${params.id}/refund`,
      method: "POST",
      params: params,
    });
  },
  refundPaymentRental(params) {
    return conn.$axios({
      url: `secured/v1/rental/payment/${params.id}/refund`,
      method: "POST",
      params: params,
    });
  },
  getPaymentsBilling(params) {
    return conn.$axios({
      url: `secured/v1.1/billing/payment/search`,
      method: "GET",
      params: params,
    });
  },
  getPaymentsByUserId(params) {
    return conn.$axios({
      url: `secured/v1/user/${params.userId}/payment/web/search`,
      method: "GET",
      params: params.params,
    });
  },
  getPaymentMethodsByUserId(params) {
    return conn.$axios({
      url: `secured/v1/user/${params.userId}/payment/method/web/search`,
      method: "GET",
      params: params.params,
    });
  },
  getTotalBillingByUserId(params) {
    return conn.$axios({
      url: `secured/v1/user/${params.userId}/payment/total`,
      method: "GET",
    });
  },
  downloadPdf(id) {
    return conn.$axios({
      url: `secured/v1/payment/${id}/receipt`,
      method: "GET",
    });
  },
  getPaymentInformation(params) {
    return conn.$axios({
      url: `v1/token/payment`,
      method: "GET",
      params: params,
    });
  },
  sendCreditCardRedsys(items) {
    return conn.$axios({
      url: `secured/v1/add/card/redsys`,
      method: "POST",
      params: items.params,
      data: items.data,
      headers: {
        Authorization: "Bearer " + items.bearer,
      },
    });
  },
  getStripeApiKey(ownerId) {
    return conn.$axios({
      url: `secured/v1/stripe/apiKey/web/user`,
      method: "GET",
      params: { ownerId: ownerId },
    });
  },
  getCustomerStripe(userId, ownerId) {
    return conn.$axios({
      url: `secured/v1/stripe/customer/user/${userId}/web`,
      method: "PUT",
      params: {
        ownerId: ownerId,
      },
    });
  },
  getIntentStripe(userId, ownerId) {
    return conn.$axios({
      url: `secured/v1/stripe/intent/user/${userId}`,
      method: "GET",
      params: { ownerId: ownerId },
    });
  },
  getIntentStripeSepa(userId, ownerId) {
    return conn.$axios({
      url: `secured/v1/stripe/intent/user/${userId}/sepa`,
      method: "GET",
      params: { ownerId: ownerId },
    });
  },
  getIntentStripeBecs(userId, ownerId) {
    return conn.$axios({
      url: `secured/v1/stripe/intent/user/${userId}/becs`,
      method: "GET",
      params: { ownerId: ownerId },
    });
  },
  newPaymentMethod(data) {
    return conn.$axios({
      url: `secured/v1.1/user/${data.userId}/payment/new/web`,
      method: "POST",
      params: data.params,
    });
  },
  setAsDefault(cardId, userId) {
    return conn.$axios({
      url: `secured/v1.1/user/${userId}/payment/default/web`,
      method: "PUT",
      params: { cardId: cardId },
    });
  },
  getPaymentMethods() {
    return conn.$axios({
      url: `secured/v1/payment/method`,
      method: "GET",
    });
  },
  getPaymentPlatforms(params) {
    return conn.$axios({
      url: `secured/v1/payment/processing/platform`,
      method: "GET",
      params: params,
    });
  },
  getPaymentLink(paymentId, codePlatform, params) {
    return conn.$axios({
      url: `secured/v1/payment/${paymentId}/platform/${codePlatform}`,
      method: "POST",
      params: params,
    });
  },
  makeOnAccountPayment(data) {
    return conn.$axios({
      url: `secured/v1.1/payment/${data.paymentId}/onAccount`,
      method: "POST",
      params: data.params,
    });
  },
  regenerateDocument(data) {
    return conn.$axios({
      url: `secured/v1.1/payment/${data.paymentId}/new/billing/web`,
      method: "POST",
      params: data.params,
    });
  },
  markAsPaid(data) {
    return conn.$axios({
      url: `secured/v1/stripe/pay/reservation/offline`,
      method: "PUT",
      params: data.params,
      data: data.data,
    });
  },
  convertPaymentsInInvoice(data) {
    return conn.$axios({
      url: `secured/v1/group/payment`,
      method: "POST",
      data: data,
    });
  },
  ungroupPayment(data) {
    return conn.$axios({
      url: `secured/v1/ungroup/payment`,
      method: "POST",
      data: data,
    });
  },
  createPaymentToUser(data) {
    return conn.$axios({
      url: `secured/v1/payment/user/${data.userId}`,
      method: "POST",
      data: data.data,
    });
  },
  forceCollection(data) {
    return conn.$axios({
      url: `secured/v1/stripe/force/payment/${data.id}`,
      method: "POST",
    });
  },
  changePaymentOnlineOffline(paymentId) {
    return conn.$axios({
      url: `secured/v1/payment/onlineOffline/${paymentId}`,
      method: "PUT",
    });
  },
  getTotalPendingBillingByUserId(params) {
    return conn.$axios({
      url: `secured/v1/user/${params.userId}/payment/pending`,
      method: "GET",
    });
  },
  updateInStripe(data) {
    return conn.$axios({
      url: `secured/v1/user/${data.userId}/owner/${data.ownerId}/stripe/refresh`,
      method: "PUT",
    });
  },
  userHasDefaultPaymentMethod(userId) {
    return conn.$axios({
      url: `secured/v1/user/${userId}/payment/method/default`,
      method: "GET",
    });
  },
  getStripeCustomerId(userId, ownerId) {
    return conn.$axios({
      url: `secured/v1/user/${userId}/owner/${ownerId}/stripe/id`,
      method: "GET",
    });
  },
  sendToERP(paymentId, params) {
    return conn.$axios({
      url: `secured/v1/payment/${paymentId}/invoice/deliver/erp`,
      method: "PUT",
      data: params,
    });
  },
};
