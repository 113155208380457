<template>
  <div class="phone-number" :class="{ 'w-full': outlined }">
    <v-layout align-center>
      <v-flex xs4 class="prefixPosition" :class="{ 'mr-2': outlined }">
        <v-autocomplete
          :disabled="disabled"
          :menu-props="{
            auto: false,
            maxWidth: 600,
            closeOnContentClick: true,
          }"
          item-text="prefix"
          item-value="code"
          :style="
            colors
              ? `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
              : ''
          "
          :class="frameColours ? 'custom-placeholer-color' : ''"
          :color="colors ? colors.colorPrimary : ''"
          :append-icon="$vuetify.breakpoint.name === 'xs' ? '' : '$dropdown'"
          hide-selected
          :items="countries"
          v-model="country"
          name="country"
          :outlined="outlined"
          ref="selectCountry"
          :filter="findCountries"
          :backgroundColor="backgroundColor"
          :dark="dark"
          :label="$t('common.countryCode')"
          @change="
            () => {
              $v.phoneNumber.$touch();
              formatPhoneNumber();
              focusTexfield();
            }
          "
          return-object
        >
          <template slot="prepend-inner">
            <div class="flag">
              <v-layout>
                <svg style="width: 20px; height: 20px" v-if="country">
                  <use
                    class="onion-icon"
                    v-bind="{
                      'xlink:href': spriteUrl + '#' + country.code,
                    }"
                  />
                </svg>
              </v-layout>
            </div>
          </template>
          <template slot="item" slot-scope="{ item }">
            <v-layout style="width: 200px; min-height: 80px">
              <v-layout>
                <svg style="width: 20px; height: 20px; margin-right: 5px">
                  <use
                    class="onion-icon"
                    v-bind="{ 'xlink:href': spriteUrl + '#' + item.code }"
                  />
                </svg>
              </v-layout>
              <v-layout
                style="width: 190px; min-height: 20px; margin-right: 5px mt-1"
              >
                {{ item.name_EN }}
                {{ item.name_own ? `(${item.name_own})` : "" }}
                {{ item.prefix }}
              </v-layout>
            </v-layout>
          </template>
        </v-autocomplete>
      </v-flex>
      <v-flex xs9>
        <v-text-field
          id="textFieldPhone"
          :disabled="disabled"
          :label="labelText"
          v-model="phoneNumber"
          :outlined="outlined"
          :style="
            colors
              ? `--custom_color-text:${colors.colorOnSurface}; --custom_color-label:${colors.colorOnSurfaceMedium}`
              : ''
          "
          :color="colors ? colors.colorPrimary : ''"
          :class="frameColours ? 'custom-placeholer-color' : ''"
          :error-messages="phoneNumberErrors"
          @input="
            () => {
              $v.phoneNumber.$touch();
              formatPhoneNumber();
            }
          "
          :backgroundColor="backgroundColor"
          :dark="dark"
          @blur="validateBlur ? $v.phoneNumber.$touch() : ''"
          @keyup.enter="$emit('keyupEnter')"
        >
          <template v-if="showAsterisk" #label>
            {{ $t("forms.phoneNumber")
            }}<span class="red--text"><strong> * </strong></span>
          </template>
        </v-text-field>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { requiredIf, maxLength } from "vuelidate/lib/validators";
import countriesFunction from "@/flit2go-web-core/util/prefixes.js";

// const correctLength = (value, country) =>
//   country.country &&
//   country.country.size.findIndex(
//     (element) => element === value.replace(/ /g, "").length
//   ) > -1
//     ? true
//     : false;
const localPrefixes = (value, country) =>
  country.country && country.country.localPrefixes.length != 0
    ? country.country.localPrefixes.findIndex(
        (element) =>
          value.replace(/ /g, "").length >= element.toString().length &&
          value.replace(/ /g, "").substring(0, element.toString().length) ==
            element
      ) > -1
      ? true
      : false
    : true;
const numeric = (value) => !isNaN(value.replace(/ /g, ""));

export default {
  name: "f2g-phone-number",
  data() {
    return {
      items: [],
      countries: countriesFunction.prefixes(),
      country: {
        prefix: "",
        size: [],
        localPrefixes: [],
        code: "",
        name_EN: "",
        name_own: "",
      },
      spriteUrl: require("@/flit2go-web-core/assets/countries-icons-phone-number.svg"),
      phoneNumber: "",
    };
  },
  props: {
    clear: {
      type: Boolean,
      default: false,
    },
    model: { type: String, required: false },
    countryModel: { type: String, required: false },
    disabled: Boolean,
    noCheckDisabled: { type: Boolean, default: false, required: false },
    fromForm: {
      type: Boolean,
      default: false,
    },
    showAsterisk: { type: Boolean, default: false, required: false },
    validate: Boolean,
    outlined: { type: Boolean, default: false, required: false },
    isRequired: { type: Boolean, default: true, required: false },
    validateBlur: { type: Boolean, default: true, required: false },
    dark: { type: Boolean, default: false, required: false },
    frameColours: { type: Boolean, default: false, required: false },
    colors: { type: Object, default: () => {}, required: false },
    backgroundColor: { type: String, default: null, required: false },
    label: {
      type: String,
      required: false,
    },
    phone: {
      type: String,
      required: false,
    },
  },
  computed: {
    labelText() {
      return this.label || this.$t("forms.phoneNumber");
    },
    phoneNumberErrors() {
      const errors = [];
      if (this.$v.phoneNumber.$dirty) {
        !this.$v.phoneNumber.required &&
          errors.push(this.$t("errors.required"));
        !this.$v.phoneNumber.localPrefixes &&
          this.phoneNumber &&
          errors.push(
            `${this.$t("errors.correctLocalPrefixes")} ${
              this.country.localPrefixes[0]
            }`
          );
        !this.$v.phoneNumber.numeric && errors.push(this.$t("errors.numeric"));
        !this.$v.phoneNumber.maxLength &&
          errors.push(this.$t(this.$CONSTANTS.ERROR.MAX_LENGTH, { count: 20 }));
      }
      return errors;
    },
  },
  watch: {
    clear() {
      if (this.clear) {
        this.phoneNumber = "";
        this.$v.$reset();
      }
    },
    model: {
      deep: true,
      immediate: true,
      handler() {
        if ((this.disabled || this.noCheckDisabled) && this.model) {
          this.initModel();
        }
      },
    },
    disabled() {
      if (this.disabled) {
        this.initModel();
      }
    },
    validate() {
      if (this.validate) {
        this.$v.$touch();
      }
    },
  },
  validations: {
    phoneNumber: {
      required: requiredIf(function() {
        return this.isRequired;
      }),
      // correctLength,
      maxLength: maxLength(20),
      numeric,
      localPrefixes,
    },
  },
  created() {
    this.setInitialCountry();
  },
  mounted() {
    if (this.model) {
      this.initModel();
    } else {
      this.setInitialCountry();
    }
  },
  methods: {
    setInitialCountry() {
      if (this.phone) {
        const splitModel = this.phone.split(" ");
        this.country = this.countries.find(
          (c) => c.prefix === splitModel[0] && c.default
        );
        this.phoneNumber = splitModel.slice(1).join("");
        this.formatPhoneNumber();
      } else {
        this.setInitialCountryByLanguage();
      }
    },
    setInitialCountryByLanguage() {
      let language = this.$PageUtils.getLanguageSplitted(this.$i18n.locale)[1];
      if (language === "EN") {
        language = "GB";
      } else if (language === "CA") {
        language = "ES";
      }

      this.country = this.countries.find((c) => c.code === language);
    },
    formatPhoneNumber() {
      let numberString = this.phoneNumber.toString().replace(/ /g, "");
      let formattedNumber = "";
      for (let i = 0; i < numberString.length; i++) {
        formattedNumber += numberString.charAt(i);
        if (i === 2 || (i > 2 && i % 2 === 0)) {
          formattedNumber += " ";
        }
      }
      this.phoneNumber = formattedNumber.trim();
      if (this.countryModel || this.fromForm) {
        this.$emit(
          "phoneAndCountry",
          !this.$v.$invalid
            ? {
                phone: `${this.country.prefix} ${this.phoneNumber}`,
                country: this.country.code,
              }
            : null
        );
      } else {
        this.$emit(
          "phoneNumber",
          !this.$v.$invalid
            ? `${this.country.prefix} ${this.phoneNumber}`
            : null
        );
      }
    },
    findCountries(item, query) {
      if (query && query !== "" && item) {
        const fields = ["name_EN", "name_own", "prefix"];
        const searchText = query.toLowerCase();

        return fields.some(
          (field) =>
            item[field] && item[field].toLowerCase().includes(searchText)
        );
      }
    },
    initModel() {
      if (
        this.model &&
        this.model.length > 0 &&
        (this.disabled || this.noCheckDisabled)
      ) {
        let splitModel = this.model.split(" ");
        if (this.countryModel) {
          this.country = this.countries.find(
            (c) => c.code === this.countryModel
          );
        } else {
          this.country = this.countries.find(
            (c) => c.prefix === splitModel[0] && c.default
          );
        }
        this.phoneNumber = "";
        for (let i = 1; i < splitModel.length; i++) {
          this.phoneNumber += splitModel[i];
        }
        this.formatPhoneNumber();
      }
    },

    focusTexfield() {
      document.getElementById("textFieldPhone").focus();
    },
  },
};
</script>

<style scoped lang="scss">
.custom-placeholer-color input {
  color: var(--custom_color-text) !important;
}
.custom-placeholer-color .v-label {
  color: var(--custom_color-label) !important;
  opacity: 1;
}
.phone-number {
  svg {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }

  use.onion-icon {
    stroke-width: 3;
    stroke: #202020;
  }

  .flag {
    display: flex;
    width: 100%;
  }

  .prefixPosition {
    margin-top: 0px;
  }

  @media (max-width: 570px) {
    .text {
      display: none;
    }
  }

  @media (max-width: 820px) {
    .form {
      display: none;
    }
  }

  @media (min-width: 960px) and (max-width: 1540px) {
    .form {
      display: none;
    }
  }

  .items {
    margin-top: 50px !important;
  }
}
</style>
