const DEVICE_STATUS = Object.freeze({
  PLATFORM: "PLA",
});
const UNLOCK_TYPE = Object.freeze({
  "3G": "3G",
  BLUETOOTH: "BLE",
  BLUETOOTH_WITHOUT_CON: "BLN",
  LOCATION_ONLY: "LON",
});
const STARTING_UNLOCK_TYPE = Object.freeze({
  "3G": "3G",
  BLUETOOTH: "BLE",
  KEY: "KEY",
  WITHOUT_NETWORK: "NNE",
  LOCATION_ONLY: "LON",
});

const TYPE = Object.freeze({
  SMARTCAR: "SMC",
  GEOTAB: "GEOTAB",
});

export default Object.freeze({
  STATUS: DEVICE_STATUS,
  STARTING_UNLOCK_TYPE: STARTING_UNLOCK_TYPE,
  UNLOCK_TYPE: UNLOCK_TYPE,
  TYPE: TYPE
});
