<template>
  <div class="resumeV2" :style="{ backgroundColor: colors.colorBackground }">
    <div v-if="showResume" class="h-full overflow-y-auto mb-4">
      <ResumeData
        :showNextStep="showNextStep"
        :currentStep="currentStep"
        :loading="loading"
        :servicesValidation="servicesValidation"
        :extrasValidation="extrasValidation"
        :isCatalogue="isCatalogue"
        @showExtras="$emit('showExtras')"
        @currentStep="
          (value) => {
            $emit('currentStep', value);
          }
        "
      />
      <div class="mx-2">
        <PriceResumeData
          v-if="categoryDetail && currentStep === 1"
          :currentCategory="currentCategory"
          :currentStep="currentStep"
          @selected="
            (value) => {
              $emit('selected', value);
            }
          "
        />
      </div>

      <!-- <TotalCard
        :currentStep="currentStep"
        :loading="loading"
        :servicesValidation="servicesValidation"
        :totalPrice="totalPrice"
        :totalPriceDeferred="totalPriceDeferred"
        @currentStep="
          (value) => {
            $emit('currentStep', value);
          }
        "
      /> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ResumeData from "@/modules/v2/Components/resume-data.vue";
import PriceResumeData from "@/modules/v2/Components/price-resume-data.vue";
// import TotalCard from "@/modules/v2/Components/total-card.vue";
import BillingUtil from "@/flit2go-web-core/util/billingUtil.js";

export default {
  name: "ResumeV2",
  components: {
    ResumeData,
    // TotalCard,
    PriceResumeData,
  },
  props: {
    currentStep: { required: false, type: Number, default: 1 },
    loading: { required: false, type: Boolean, default: false },
    servicesValidation: { required: false, type: Boolean, default: true },
    extrasValidation: { required: false, type: Boolean, default: true },
    categoryDetail: { required: false, type: Boolean, default: false },
    showNextStep: { required: false, type: Boolean, default: false },
    isCatalogue: { required: false, type: Boolean, default: false },
    currentCategory: { required: false, type: Object, default: null },
  },
  data() {
    return {
      BillingUtil,
      showResume: window.innerWidth >= 1024 ? true : false,
    };
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
      categorySelected: "category/categorySelected",
    }),
    borderColor() {
      return `border-color: ${this.colors.primary}`;
    },
  },
  methods: {
    myEventHandler(e) {
      if (
        this.$vuetify.breakpoint.name !== "xs" &&
        this.$vuetify.breakpoint.name !== "sm"
      ) {
        this.showResume = true;
      } else {
        this.showResume = false;
      }
    },
    nextStep() {
      if (this.categorySelected.id) {
        this.$emit("currentStep", this.currentStep + 1);
      }
    },

    returnLabelButton() {
      switch (this.currentStep) {
        case 1: {
          return this.$t("button.goToExtras");
        }
        case 2: {
          return this.$t("common.continue");
        }
        case 3: {
          if (
            this.categorySelected.deferredPayment &&
            this.categorySelected.deferredStartRental &&
            !this.categorySelected.earlyPay &&
            Number(this.categorySelected.deferredPaymentPercentageFirst) === 0
          ) {
            return this.$t("common.continue");
          } else {
            return this.$t("button.goToPay");
          }
        }
        case 4: {
          if (
            this.categorySelected.deferredPayment &&
            this.categorySelected.deferredStartRental &&
            !this.categorySelected.earlyPay &&
            Number(this.categorySelected.deferredPaymentPercentageFirst) === 0
          ) {
            return this.$t("common.continue");
          } else {
            return this.$t("button.goToPay");
          }
        }
        default: {
          return "";
        }
      }
    },
  },
};
</script>
