<template>
  <div
    class="reservation flex flex-row lg:px-4 lg:pt-4"
    id="top-part"
    :class="showLogoAndVersion ? 'relative' : 'fixed w-full max-h-full'"
  >
    <div class="w-full lg:w-9/12 mb-16">
      <F2gFWCustomStepper
        ref="stepper"
        :stepList="stepList"
        :currentStep="currentStep"
        :loading="loading"
        :servicesValidation="servicesValidation"
        :showNextStep="showNextStep"
        selectedcurrentStep="eventCurrentStep"
        @currentStep="eventCurrentStep"
        @showExtras="showExtras = true"
        @search="setQuery"
      >
        <template v-slot:content1>
          <div class="lg:max-max-h-full px-4" v-if="!showCategorySelected">
            <div class="flex md:flex-row flex-col mt-4">
              <div class="flex flex-row w-full">
                <div
                  class="w-3/5 md:w-1/3 md:mb-6 mb-2 mr-2"
                  v-if="
                    vehiclesForFilter.length > 0 && filters.showVehicleFilter
                  "
                >
                  <v-autocomplete
                    class="rounded-md"
                    type="text"
                    v-model="filter.vehicleTypes"
                    :background-color="colors.colorSurfaceMedium"
                    dense
                    filled
                    single-line
                    hide-details
                    :items="vehiclesForFilter"
                    item-text="name"
                    item-value="code"
                    multiple
                    @change="searchCategories"
                  >
                    <!-- <template
                        slot="prepend-inner"
                        :style="
                          `cursor: pointer, color: ${colors.colorPrimary}`
                        "
                      >
                        <F2gIcon
                          :stroke="colors.colorPrimary"
                          class="cursor-pointer"
                          file="material-svg-icons.svg"
                          value="car"
                          :strokeWidth="3"
                        />
                      </template> -->
                    <template #selection="{ item }">
                      <span
                        :style="
                          `color:${colors.colorOnSurfaceMedium} !important`
                        "
                        >{{ item.name }}&nbsp;</span
                      > </template
                    ><template #label>
                      <span
                        :style="
                          `color:${colors.colorOnSurfaceMedium} !important`
                        "
                        >{{ $t("common.vehicleType") }}</span
                      >
                    </template>
                  </v-autocomplete>
                </div>
                <!--      <div class="w-2/5 md:w-1/5" v-if="filters.showPriceFilter">
                  <v-autocomplete
                    type="text"
                    class="rounded-md"
                    v-model="filter.price"
                    :background-color="colors.colorSurfaceMedium"
                    color="red"
                    filled
                    single-line
                    dense
                    hide-details
                    :items="prices"
                    item-text="name"
                    item-value="value"
                  >-->
                <!-- <template
                        slot="prepend-inner"
                        :style="
                          `cursor: pointer, color: ${colors.colorPrimary}`
                        "
                      >
                        <F2gIcon
                          :stroke="colors.colorPrimary"
                          class="cursor-pointer"
                          value="pricing"
                          :strokeWidth="3"
                        />
                      </template> -->
                <!--   <template #selection="{ item }">
                      <span
                        :style="`color:${colors.colorOnSurfaceMedium} !important`"
                        >{{ item.name }}</span
                      >
                    </template>
                    <template #label>
                      <span
                        :style="`color:${colors.colorOnSurfaceMedium} !important`"
                        >{{ $t("common.price") }}</span
                      >
                    </template>
                  </v-autocomplete>
                </div>-->
              </div>
              <div
                v-if="filters.showOrdination"
                class="flex rounded-md flex align-center justify-end cursor-pointer w-full h-10 md:w-44"
                :style="{
                  backgroundColor: colors.colorSurface,
                }"
              >
                <div class="flex flex-row w-full align-center">
                  <v-autocomplete
                    type="text"
                    class="inline-block mb-1"
                    v-model="filter.order"
                    dense
                    single-line
                    reverse
                    hide-details
                    :items="orderBy"
                    item-text="name"
                    item-value="value"
                    append-icon=""
                    ><template #label>
                      <span
                        :style="
                          `color:${colors.colorOnSurfaceMedium} !important`
                        "
                        >{{ `${$t("common.orderBy")} :` }}</span
                      >
                    </template>
                  </v-autocomplete>
                  <F2gIcon
                    :stroke="colors.colorOnSurfaceMedium"
                    class="cursor-pointer ml-2"
                    :value="arrowValue"
                    :strokeWidth="3"
                    @click="filter.order ? (arrow = !arrow) : ''"
                  />
                </div>
              </div>
            </div>
            <div v-if="categoriesFiltered.length">
              <div
                class="text-sm mt-4"
                :style="{ color: colors.colorOnBackground }"
              >
                {{ categoriesFiltered.length }}
                {{ $t("categoriesFound") }}...
              </div>
              <category-cardV2
                v-for="(category, index) in categoriesFiltered"
                :key="index"
                :isDetail="false"
                :categoryRow="category"
                @categorySelected="changeCategorySelected"
                @selected="selectCategory"
              />
            </div>
            <noData v-else :title="$t('common.noData')" />
          </div>

          <div
            class="lg:max-max-h-full px-4 w-full rounded-lg mt-2 mb-1"
            :style="{
              backgroundColor: colors.colorSurface,
              color: colors.colorOnSurface,
            }"
            v-if="showCategorySelected"
          >
            <category-details
              v-if="
                $vuetify.breakpoint.name !== 'xs' &&
                  $vuetify.breakpoint.name !== 'sm'
              "
              :categoryRow="categorySelected"
              :fullscreen="
                $vuetify.breakpoint.name === 'xs' ||
                  $vuetify.breakpoint.name === 'sm'
              "
              @close="
                () => {
                  categorySelected = null;
                  showCategorySelected = false;
                }
              "
              @selected="selectCategory"
            />
            <category-cardV2
              v-else
              :isDetail="true"
              :categoryRow="categorySelected"
              @categorySelected="changeCategorySelected"
              @selected="selectCategory"
              @close="
                () => {
                  categorySelected = null;
                  $store.commit('category/SET_PACK', null);
                  showCategorySelected = false;
                }
              "
            />
          </div>
        </template>
        <template v-slot:content2>
          <div class="lg:max-max-h-full px-4">
            <extras-list
              ref="extrasList"
              :showExtras="showExtras"
              @validation="validateExtras"
              @servicesValidation="
                (validation) => (servicesValidation = validation)
              "
            />
            <div class="mt-12 mx-2">
              <data-card
                ref="dataCard"
                @type="setTypeUser"
                @validation="loginValidation"
                @nextStep="eventCurrentStep(3)"
                @showButtonNextStep="showUserNextStep"
                @hideButtonNextStep="
                  () => {
                    showNextStep = false;
                  }
                "
              />
            </div>
            <div
              v-if="showNextStep"
              class="w-full flex px-2 justify-center mt-2"
            >
              <v-btn
                class="my-2 w-full border-2 rounded-config30"
                :style="{
                  backgroundColor: colors.colorPrimary,
                  color: colors.colorOnPrimary,
                }"
                :disabled="!servicesValidation"
                @click="eventCurrentStep(3)"
              >
                {{ returnLabelButton() }}
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:content3>
          <div class="lg:max-max-h-full min-h-2/3 px-4">
            <confirm-data
              ref="confirmData"
              @validateConfirm="validateConfirm"
            />
            <div class="w-full flex px-2 justify-center">
              <v-btn
                class="my-2 w-full border-2 rounded-config30"
                :style="{
                  backgroundColor: colors.colorPrimary,
                  color: colors.colorOnPrimary,
                }"
                :loading="loading"
                @click="eventCurrentStep(4)"
              >
                {{ returnLabelButton() }}
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:content4>
          <div class="lg:max-h-full min-h-3/4 px-4">
            <div class="min-h-2/3">
              <pay-card />
            </div>
          </div>
        </template>
      </F2gFWCustomStepper>
    </div>
    <div
      class="top-6 w-3/12 px-0 right-2 max-h-full"
      v-if="
        !(
          $vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'
        )
      "
    >
      <Resume
        :currentStep="currentStep"
        :loading="loading"
        :categoryDetail="showCategorySelected"
        :currentCategory="categorySelected"
        :servicesValidation="servicesValidation"
        :extrasValidation="extrasValidation"
        :showNextStep="showNextStep"
        @showExtras="showExtras = true"
        @currentStep="
          (value) => {
            hideDialogExtras = true;
            eventCurrentStep(value);
          }
        "
        @selected="selectCategory"
      />
    </div>
    <div
      v-if="showLogoAndVersion"
      :style="{ backgroundColor: colors.colorBackground }"
      class="flex bottom-0 align-end absolute"
      :class="
        $vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'
          ? 'w-full'
          : 'width73'
      "
    >
      <div class="version mt-4 ml-6">v.{{ version }}</div>
      <div class="version mt-4 ml-1">
        developed by
        <a
          href="https://flit2go.com/"
          target="_blank"
          :style="{ color: colors.colorPrimary }"
          >Flit2go technology</a
        >
      </div>
      <!-- <img
        class="flit2go-logo z-5 mr-8"
        width="100"
        height="40"
        src="@/flit2go-web-core/assets/powered-new-logo.svg"
        alt="f2gLogo"
      /> -->
    </div>
    <v-dialog
      :class="'rounded-t-lg'"
      transition="dialog-bottom-transition"
      :value="showModalExtras"
      :maxWidth="800"
      :style="`backgroundColor:${colors.colorSurface} !important`"
    >
      <template>
        <v-card
          :style="`backgroundColor:${colors.colorSurface} !important`"
          class="max-h-full bg-white backdrop-blur-xl overflow-y-auto rounded-lg"
        >
          <v-card-title
            class="text-h5 mb-2"
            id="dialog-extras"
            :style="{
              color: colors.colorOnSurface,
            }"
          >
            <F2gIcon
              class="mr-2"
              :value="'warning'"
              :height="30"
              :width="30"
              :stroke="colors.colorOnSurface"
              :strokeWidth="3"
            />
            {{ $t("reviewExtras") }}
          </v-card-title>
          <v-card-text>
            <div
              class="absolute flex flex-row justify-end align-end mt-2 mr-2 top-0 right-0 z-50"
            >
              <v-btn icon @click="showModalExtras = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div
              class="flex flex-row align-center"
              :style="{
                color: colors.colorOnSurface,
              }"
            >
              <v-layout column class="mb-4">
                {{ $t("reviewExtrasQuestion") }}
              </v-layout>
            </div>

            <div
              class="flex align-end justify-end"
              :class="{
                'flex-col': $vuetify.breakpoint.name === 'xs',
              }"
            >
              <v-btn
                outlined
                class="rounded"
                :class="{
                  'mb-2': $vuetify.breakpoint.name === 'xs',
                }"
                :color="colors.colorPrimary"
                @click="
                  hideDialogExtras = true;
                  eventCurrentStep(3);
                "
                >{{ $t("dontNeedAnything") }}</v-btn
              >
              <v-btn
                :color="colors.colorPrimary"
                class="rounded"
                :class="{
                  'ml-2': $vuetify.breakpoint.name !== 'xs',
                }"
                :style="'color: ' + colors.colorOnPrimary"
                @click="
                  showModalExtras = false;
                  showExtras = true;
                "
                >{{ $t("showExtras") }}</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import CategoryCardV2 from "@/modules/v2/Components/category-card";
import ExtrasList from "@/modules/v2/Components/extras-list";
import DataCard from "@/components/data-card";
import PayCard from "@/components/pay-card";
import ConfirmData from "@/components/confirm-data";
import Resume from "@/modules/v2/Components/resume";
import F2gFWCustomStepper from "@/modules/v2/Components/f2gFW-custom-stepper";
import { mapGetters, mapState } from "vuex";
import noData from "@/flit2go-web-core/components/ui/no-data.vue";
import CategoryDetails from "@/modules/v2/Components/category-details";
import { EventBus } from "@/flit2go-web-core/util/event-bus.js";
import DateUtil from "@/flit2go-web-core/util/dateUtil";
import BillingUtil from "@/flit2go-web-core/util/billingUtil.js";

export default {
  name: "ReservationStepper",
  components: {
    CategoryCardV2,
    Resume,
    F2gFWCustomStepper,
    ExtrasList,
    DataCard,
    PayCard,
    ConfirmData,
    noData,
    CategoryDetails,
  },
  data() {
    return {
      DateUtil,
      currentStep: 1,
      version: process.env.PACKAGE_VERSION || "0",
      loading: false,
      search: "",
      showExtras: false,
      filter: {
        vehicleTypes: [],
        price: null,
        order: null,
      },
      vehicles: [],
      prices: [],
      orderBy: [],
      arrow: 1,
      typeUser: null,
      showConfirmation: false,
      showCategorySelected: false,
      categorySelected: null,
      servicesValidation: true,
      showNextStep: false,
      extrasValidation: false,
      franchiseValidation: false,
      hideDialogExtras: false,
      showModalExtras: false,
      showLogoAndVersion: true,
      validateConfirmData: false,
    };
  },
  beforeCreate() {
    if (
      this.$route.query.hasOwnProperty("pickUpBranch") &&
      this.$route.query.hasOwnProperty("pickUpDate") &&
      this.$route.query.hasOwnProperty("returnBackBranch") &&
      this.$route.query.hasOwnProperty("returnBackDate")
    ) {
      const url =
        window.location !== window.parent.location
          ? document.location.ancestorOrigins
            ? document.location.ancestorOrigins[0]
            : document.referrer
          : document.location.origin;
      var mainFilter = {};
      mainFilter.pickUpBranch = parseInt(this.$route.query.pickUpBranch);
      mainFilter.pickUpDate = parseInt(this.$route.query.pickUpDate);
      mainFilter.returnBackBranch = parseInt(
        this.$route.query.returnBackBranch
      );
      mainFilter.returnBackDate = parseInt(this.$route.query.returnBackDate);
      mainFilter.pickUpNameBranch = this.$route.query.pickUpNameBranch;
      mainFilter.returnBackNameBranch = this.$route.query.returnBackNameBranch;
      mainFilter.pickUpDirectionBranch = this.$route.query.pickUpDirectionBranch;
      mainFilter.returnBackDirectionBranch = this.$route.query.returnBackDirectionBranch;
      mainFilter.pickUpLatitude = this.$route.query.pickUpLatitude;
      mainFilter.pickUpLongitude = this.$route.query.pickUpLongitude;
      mainFilter.returnBackLatitude = this.$route.query.returnBackLatitude;
      mainFilter.returnBackLongitude = this.$route.query.returnBackLongitude;
      if (
        this.$route.query.vehicleTypes &&
        this.$route.query.vehicleTypes.length
      ) {
        mainFilter.vehicleTypes = this.$route.query.vehicleTypes.toString();
      } else {
        mainFilter.vehicleTypes = this.vehiclesFilters;
      }
      this.categorySelected = null;
      this.showCategorySelected = false;
      this.$store.commit("configuration/SET_BOOKING_FILTERS", mainFilter);
      localStorage.setItem(`apiToken-${url}`, this.$route.query.apiKey);
      let params = {
        type: this.$CONSTANTS.INTEGRATION.FRAME_WEB,
      };
      this.$store.dispatch("configuration/getConfiguration", params);
      this.$store.dispatch("category/getAvailableCategories", mainFilter);
    }
  },
  watch: {
    showModalExtras() {
      this.goToTop();
    },
    currentStep() {
      window.parent.postMessage("manualResize", "*");
    },
  },
  computed: {
    ...mapState(["configuration"]),
    ...mapGetters({
      categoriesList: "category/categoriesList",
      bookingFilters: "configuration/getBookingFilters",
      colors: "configuration/getColors",
      configurationFilters: "configuration/getFilters",
      vehiclesFilters: "configuration/getVehiclesFilter",
      additionalsSelected: "configuration/getAdditionalsSelected",
      extrasSelected: "configuration/getExtrasSelected",
      franchiseSelected: "category/franchiseSelected",
      userLoged: "user/userLoged",
      actualPrice: "configuration/getActualPrice",
      loginParameters: "user/loginParameters",
      filters: "configuration/getFilters",
      gtagCode: "configuration/getGtagCode",
    }),
    stepList() {
      let stepList = [
        {
          id: 1,
          label: this.$t("common.selectVehicles"),
          ref: "vehicle",
          valid: false,
          visited: false,
        },
        {
          id: 2,
          label: this.$t("common.extras"),
          ref: "extras",
          valid: false,
          visited: false,
        },
        {
          id: 3,
          label: this.$t("issueActions.RES"),
          ref: "confirm",
          valid: false,
          visited: false,
        },
        {
          id: 4,
          label: this.$t("common.payment"),
          ref: "payment",
          valid: false,
          visited: false,
        },
      ];
      return stepList;
    },
    categoriesFiltered() {
      let categories = this.categoriesList ? this.categoriesList : [];
      if (this.filter.order && this.filter.order === "price") {
        categories = this.orderByPrice;
      }
      if (this.filter.order && this.filter.order === "name") {
        categories = this.orderByName;
      }
      /*if (this.filter.price) {
        categories = categories.filter(
          (category) =>
            category.totalPrice <= this.filter.price.max &&
            category.totalPrice >= this.filter.price.min
        );
      }*/
      if (this.search && this.search.length > 0) {
        categories = categories.filter(
          (category) => category.name.toLowerCase().indexOf(this.search) > -1
        );
      }

      return categories;
    },
    orderByPrice() {
      return this.categoriesList
        .filter((category) => category.totalPrice >= 0)
        .sort((a, b) =>
          this.arrow ? a.totalPrice - b.totalPrice : b.totalPrice - a.totalPrice
        );
    },
    orderByName() {
      let categoriesSorted = this.categoriesList
        .filter((category) => category.name != null)
        .sort((a, b) => {
          const name1 = a.name.toUpperCase();
          const name2 = b.name.toUpperCase();
          if (name1 > name2) {
            return 1;
          } else if (name1 < name2) {
            return -1;
          }
        });
      if (!this.arrow) {
        categoriesSorted.reverse();
      }
      return categoriesSorted;
    },
    arrowValue() {
      if (this.arrow) {
        return "arrow-up";
      } else {
        return "arrow-down";
      }
    },
    vehiclesForFilter() {
      const vehiclesList = [];
      this.vehicles.forEach((vehicle) => {
        if (
          this.vehiclesFilters &&
          this.vehiclesFilters.includes(vehicle.code)
        ) {
          vehiclesList.push(vehicle);
        }
      });

      return vehiclesList;
    },
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
    Object.entries(this.$route.query).forEach(([key, value]) => {
      if (key in this.filter) {
        this.filter[key] = value;
      }
    });

    EventBus.$on("changeDates", () => {
      this.categorySelected = null;
      this.$store.commit("category/SET_PACK_RATE", null);
      this.showCategorySelected = false;
    });
    EventBus.$on("hideDialogExtras", () => {
      this.hideDialogExtras = true;
    });
    EventBus.$on("tailOpen", () => {
      this.showLogoAndVersion = false;
    });
    EventBus.$on("tailClose", () => {
      this.showLogoAndVersion = true;
    });
    EventBus.$on("goToEndBooking", () => {
      if (this.gtagCode) {
        this.$gtag.event("confirmation", {
          event_name: "confirmation",
        });
      }
      this.sendHubspotEndpoint(
        this.$CONSTANTS.SETTINGS.STATUS.FRAME_WEB_CONFIRMATION
      );
    });
    EventBus.$on("doLogout", () => {
      if (this.$refs.dataCard) {
        this.$refs.dataCard.clear();
      }
      if (this.currentStep >= 2) {
        this.eventCurrentStep(2);
        this.showNextStep = false;
      }
    });
    setTimeout(() => {
      this.fillFilters();
    }, 1000);

    if (
      this.$route.query.hasOwnProperty("pickUpBranch") &&
      this.$route.query.hasOwnProperty("pickUpDate") &&
      this.$route.query.hasOwnProperty("returnBackBranch") &&
      this.$route.query.hasOwnProperty("returnBackDate")
    ) {
      this.setLanguage();
    }
  },
  mounted() {
    EventBus.$on("goStep1", () => {
      this.categorySelected = null;
      this.showCategorySelected = false;
      this.$store.commit("category/SET_PACK_RATE", null);
      this.goToTop();
      this.eventCurrentStep(1);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    showUserNextStep() {
      setTimeout(() => (this.showNextStep = true), 500);
    },
    sendHubspotEndpoint(status) {
      if (
        this.configuration.trackNumberHubSpot ||
        this.configuration.clientifyActived
      ) {
        let params = {
          idStage: status,
          data: {
            category: this.categorySelected.name,
            initialDate: this.DateUtil.formatCustomDate(
              Number(this.bookingFilters.pickUpDate)
            ),
            finishDate: this.DateUtil.formatCustomDate(
              Number(this.bookingFilters.returnBackDate)
            ),
            initial:
              this.bookingFilters.pickUpNameBranch +
              " " +
              this.DateUtil.formatCustomDate(
                Number(this.bookingFilters.pickUpDate)
              ),
            finish:
              this.bookingFilters.returnBackNameBranch +
              " " +
              this.DateUtil.formatCustomDate(
                Number(this.bookingFilters.returnBackDate)
              ),
            franchise: this.franchiseSelected
              ? this.franchiseSelected.message
              : null,
            extras: this.extrasSelected ? this.getExtras() : null,
            servicesAdditional: this.additionalsSelected
              ? this.getAdditionalServices()
              : null,
            amount: this.actualPrice ? this.getActualPrice() : null,
            username: this.userLoged ? this.userLoged.username : null,
            currency: this.filters.currencyCode
              ? this.filters.currencyCode
              : null,
          },
          params: {
            ApiKey: this.$route.query.apiKey,
          },
          access_token: this.loginParameters.access_token,
        };
        this.$store.dispatch("configuration/sendHubspotEndpoint", params);
      }
    },
    goToTop() {
      window.parent.postMessage("goToTop", "*");
    },
    getActualPrice() {
      let price =
        this.actualPrice.priceTotal.value +
        Number(
          (
            (this.actualPrice.priceTotal.value *
              this.actualPrice.priceTotal.tax) /
            100
          ).toFixed(2)
        );

      return price;
    },
    getExtras() {
      let extrasNames = [];
      Object.entries(this.extrasSelected).forEach(([key, extra]) => {
        if (extra && extra.name) {
          extrasNames.push(extra.name);
        }
      });
      return extrasNames.toString();
    },
    getAdditionalServices() {
      let additionalNames = [];
      Object.entries(this.additionalsSelected).forEach(([key, additional]) => {
        if (additional.type && additional.type.name) {
          additionalNames.push(
            this.$PageUtils.getObjectNameLower(additional.type)
          );
        }
      });
      return additionalNames.toString();
    },
    setQuery(query) {
      this.search = query.toLowerCase();
    },
    changeCategorySelected(category, showDetail = false) {
      if (showDetail && this.gtagCode) {
        this.$gtag.event("category_detail", {
          event_name: "category_detail",
        });
      }
      this.categorySelected = { ...category };
      this.showCategorySelected = true;
      this.goToTop();
    },
    searchCategories() {
      let mainFilter = Object.assign({}, this.bookingFilters);
      if (this.search.length > 0) {
        mainFilter.q = this.search;
      }
      if (this.filter.vehicleTypes.length > 0) {
        mainFilter.vehicleTypes = this.filter.vehicleTypes.toString();
      } else {
        mainFilter.vehicleTypes = this.vehiclesFilters;
      }
      this.categorySelected = null;
      this.packSelected = null;
      this.showCategorySelected = false;
      this.$store.dispatch("category/getAvailableCategories", mainFilter);
    },
    returnLabelButton() {
      switch (this.currentStep) {
        case 1: {
          return this.$t("button.goToExtras");
        }
        case 2: {
          return this.$t("common.continue");
        }
        case 3: {
          return this.$t("button.goToPay");
        }
        default: {
          return "";
        }
      }
    },
    eventCurrentStep(currentStep) {
      if (currentStep == 1) {
        this.currentStep = currentStep;
      }
      if (currentStep == 2) {
        if (this.gtagCode) {
          this.$gtag.event("item_selection", {
            event_name: "item_selection",
          });
        }
        this.currentStep = currentStep;
      }
      if (currentStep == 3) {
        if (this.$refs && this.$refs.extrasList) {
          this.$refs.extrasList.validate();
        }
        if (this.servicesValidation) {
          if (
            this.hideDialogExtras ||
            (!this.categorySelected.additionalServicesList.length &&
              !this.categorySelected.extraList.length)
          ) {
            this.goToTop();
            this.showModalExtras = false;
            this.currentStep = currentStep;
            this.loading = false;
            if (this.gtagCode) {
              this.$gtag.event("summary", {
                event_name: "summary",
              });
            }
            this.sendHubspotEndpoint(
              this.$CONSTANTS.SETTINGS.STATUS.FRAME_WEB_LOGIN
            );
          } else {
            this.showModalExtras = true;
          }
        }
      }
      if (currentStep == 4) {
        if (this.$refs && this.$refs.confirmData) {
          this.$refs.confirmData.validateData();
        }
        if (this.validateConfirmData) {
          this.goToTop();
          this.currentStep = currentStep;
          if (this.gtagCode) {
            this.$gtag.event("payment_process", {
              event_name: "payment_process",
            });
          }
          this.sendHubspotEndpoint(
            this.$CONSTANTS.SETTINGS.STATUS.FRAME_WEB_PENDING_PAYMENT
          );
        }
      }
      if (currentStep == 5) {
        this.currentStep = currentStep;
      }
      if (this.currentStep > currentStep) {
        this.currentStep = currentStep;
      }
    },
    setTypeUser(userType) {
      this.typeUser = userType;
    },
    validateExtras(extras) {
      this.extrasValidation = extras.valid;
      if (extras.valid) {
        if (this.$refs && this.$refs.dataCard) {
          this.$refs.dataCard.validateTextField();
        }
      }
    },
    loginValidation(login) {
      this.loading = false;
      this.showNextStep = false;
      if (login.valid) {
        this.showNextStep = true;
        //this.goToTop();
      }
    },
    selectCategory(value, pack = null) {
      this.categorySelected.earlyPay = value;
      this.$store.commit("category/SET_CATEGORY", this.categorySelected);
      this.$store.commit("category/SET_PACK_RATE", pack);
      this.$store.commit("configuration/SET_EXTRAS_SELECTED", null);
      EventBus.$emit("getPrice");
      this.goToTop();
      this.eventCurrentStep(2);
    },
    fillFilters() {
      this.vehicles = [
        { code: "CAR", name: this.$t("vehicleTypes.CAR") },
        { code: "BIC", name: this.$t("vehicleTypes.BIC") },
        { code: "MOT", name: this.$t("vehicleTypes.MOT") },
        { code: "MOP", name: this.$t("vehicleTypes.MOP") },
        { code: "VAN", name: this.$t("vehicleTypes.VAN") },
        { code: "ES", name: this.$t("vehicleTypes.ES") },
        { code: "BOA", name: this.$t("vehicleTypes.BOA") },
      ];
      this.prices = [
        { name: this.$t("common.allPrices"), value: { min: 0, max: 10000000 } },
        {
          name:
            BillingUtil.formatBillingValue(
              0,
              this.configurationFilters.currency
            ) +
            BillingUtil.formatBillingValue(
              -25,
              this.configurationFilters.currency
            ),
          value: { min: 0, max: 25 },
        },
        {
          name:
            BillingUtil.formatBillingValue(
              25,
              this.configurationFilters.currency
            ) +
            BillingUtil.formatBillingValue(
              -50,
              this.configurationFilters.currency
            ),
          value: { min: 25, max: 50 },
        },
        {
          name:
            BillingUtil.formatBillingValue(
              50,
              this.configurationFilters.currency
            ) +
            BillingUtil.formatBillingValue(
              -100,
              this.configurationFilters.currency
            ),
          value: { min: 50, max: 100 },
        },
        {
          name:
            BillingUtil.formatBillingValue(
              100,
              this.configurationFilters.currency
            ) +
            BillingUtil.formatBillingValue(
              -250,
              this.configurationFilters.currency
            ),
          value: { min: 100, max: 250 },
        },
        {
          name:
            BillingUtil.formatBillingValue(
              250,
              this.configurationFilters.currency
            ) +
            BillingUtil.formatBillingValue(
              -500,
              this.configurationFilters.currency
            ),
          value: { min: 250, max: 500 },
        },
        {
          name:
            "+" +
            BillingUtil.formatBillingValue(
              500,
              this.configurationFilters.currency
            ),
          value: { min: 500, max: 10000000 },
        },
      ];
      this.orderBy = [
        { name: this.$t("common.price"), value: "price" },
        { name: this.$t("common.name"), value: "name" },
      ];
    },
    validateConfirm(value) {
      this.validateConfirmData = value.valid;
    },
    setLanguage() {
      // Set language to user language or browser language
      let URLactual = window.location;
      let language;
      if (URLactual.href.includes("lang")) {
        let person = URLactual.href
          .split("?lang=")
          .pop()
          .split("#")[0];
        language = "en";
        if (
          person === "es" ||
          person === "de" ||
          person === "pt" ||
          person === "it" ||
          person === "fr" ||
          person === "ca"
        ) {
          language = person;
        }
      } else {
        language = localStorage.getItem("user-language");
      }

      if (language.includes("_")) {
        language = language.split("_")[0];
      }
      localStorage.setItem("user-language", language);
      this.$store.dispatch("settings/CHANGE_LANG", { language: language });
    },
  },
};
</script>
<style scoped lang="scss">
.fade-enter-active {
  transition: opacity 1.2s;
}
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 1.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(1300px);
  opacity: 0;
}
.version {
  color: $gray-1-f2g;
}
.width73 {
  width: 73%;
  padding-right: 5px;
}
</style>
