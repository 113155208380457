<template>
  <div class="carousel-component">
    <v-skeleton-loader
      height="500px"
      class="mx-auto"
      type="image"
      v-show="loading || !mounted"
    ></v-skeleton-loader>
    <div
      class="carousel"
      :style="`width:${width} !important; border-radius: ${borderRadius};`"
      v-show="mounted && !loading"
      id="carousel"
    >
      <!-- images -->
      <div
        class="carousel__item"
        v-for="(image, index) in images"
        :key="index"
        :id="`block${index + 1}`"
      >
        <img
          :src="image"
          :style="
            `width:${width} !important;object-fit: cover; !important;aspect-ratio:${aspectRatio} !important; border-radius: ${borderRadius}`
          "
          :alt="image"
        />
      </div>
      <!-- buttons -->
      <div
        class="carousel__buttons px-4"
        :style="`width:${width} !important`"
        v-if="images.length > 0"
      >
        <div
          class="carousel__buttons__item"
          v-ripple
          @click="previousPositionEvent(null)"
        >
          <F2gIcon
            value="arrow-left"
            :strokeWidth="6"
            :height="40"
            :width="40"
            stroke="white"
          />
        </div>
        <div
          class="carousel__buttons__item"
          v-ripple
          @click="nextPositionEvent(null)"
        >
          <F2gIcon
            value="arrow-right"
            :strokeWidth="6"
            :height="40"
            :width="40"
            stroke="white"
          />
        </div>
      </div>
      <!-- footer -->
      <div
        v-if="showFooter"
        class="carousel__footer"
        :class="{
          'carousel__footer--big': !showPagination,
          'carousel__footer--small': showPagination,
        }"
        :style="
          `border-bottom-left-radius: ${borderRadius};border-bottom-right-radius: ${borderRadius}`
        "
      >
        <!-- footer pagination -->
        <div class="carousel__footer__position" v-if="showPagination">
          <div
            v-for="index in images.length"
            :key="index"
            class="carousel__footer__position__container mx-2"
            :class="{
              'carousel__footer__position__container--selected':
                position === index,
              'carousel__footer__position__container--not-selected':
                position !== index,
            }"
          >
            <div
              class="carousel__footer__position__container__item"
              :class="{
                'carousel__footer__position__container__item--selected':
                  position === index,
              }"
              v-ripple
              @click="goTo(index)"
            ></div>
          </div>
        </div>
        <!-- footer slot -->
        <slot v-else name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  props: {
    images: { type: Array, required: true },
    width: { type: String, required: false, default: "100%" },
    aspectRatio: { type: Number, required: false, default: 16 / 9 },
    showPagination: { type: Boolean, required: false, default: true },
    loading: { type: Boolean, required: false, default: false },
    borderRadius: { type: String, required: false, default: "20px" },
    showFooter: { type: Boolean, required: false, default: true },
  },
  data: () => ({
    position: 1,
    mounted: false,
    canChangePosition: true,
  }),
  watch: {
    images: {
      deep: true,
      handler() {
        this.initData();
      },
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    mountedEvent() {
      this.mounted = true;
      this.$emit("mounted");
    },
    initData() {
      if (
        this.images.length > 0 &&
        !this.mounted &&
        document.getElementById("carousel")
      ) {
        setTimeout(() => {
          for (let i = 1; i <= this.images.length; i++) {
            if (i !== this.position) {
              const block = document.getElementById(`block${i}`);
              if (block) {
                block.classList.add("carousel__item__slide-left-leave-active");
                setTimeout(
                  () => block.classList.add("carousel__item__not-show"),
                  480
                );
              }
            }
          }
        }, 1000);
        setTimeout(this.mountedEvent, 3000);
      }
    },
    goTo(index) {
      if (index < this.position) {
        this.nextPositionEvent(index);
      } else if (index > this.position) {
        this.previousPositionEvent(index);
      }
    },
    previousPositionEvent(newPosition = null) {
      if (this.canChangePosition && this.images.length > 1) {
        this.canChangePosition = false;
        const currentPosition = this.position;
        const currentBlock = document.getElementById(`block${this.position}`);
        currentBlock.classList = "";
        currentBlock.classList.add("carousel__item__slide-left-leave-active");
        this.hideBlock(currentPosition);

        setTimeout(() => this.addPreviousPosition(newPosition), 400);
      }
    },
    addPreviousPosition(newPosition = null) {
      if (newPosition) {
        this.position = newPosition;
      } else {
        this.position =
          this.position > 1 ? this.position - 1 : this.images.length;
      }

      const newBlock = document.getElementById(`block${this.position}`);
      newBlock.classList = "";
      newBlock.classList.add("carousel__item__slide-left-enter-active");
      this.canChangePosition = true;
    },
    nextPositionEvent(newPosition = null) {
      if (this.canChangePosition && this.images.length > 1) {
        this.canChangePosition = false;
        const currentPosition = this.position;
        const currentBlock = document.getElementById(`block${this.position}`);
        currentBlock.classList = "";
        currentBlock.classList.add("carousel__item__slide-right-leave-active");
        this.hideBlock(currentPosition);

        setTimeout(() => this.addNextPosition(newPosition), 400);
      }
    },
    addNextPosition(newPosition = null) {
      if (newPosition) {
        this.position = newPosition;
      } else {
        this.position =
          this.position < this.images.length ? this.position + 1 : 1;
      }
      const newBlock = document.getElementById(`block${this.position}`);
      newBlock.classList = "";
      newBlock.classList.add("carousel__item__slide-right-enter-active");
      this.canChangePosition = true;
    },
    hideBlock(currentPosition) {
      setTimeout(() => {
        document
          .getElementById(`block${currentPosition}`)
          .classList.add("carousel__item__not-show");
      }, 400);
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-component {
  ::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
    .v-skeleton-loader__image {
      height: 500px;
    }
  }
  .carousel {
    position: relative;
    z-index: 0;
    overflow: hidden;

    &__item {
      height: 100% !important;
      width: 100% !important;
      position: relative;
      z-index: 1;

      &__slide-left-enter-active {
        animation: slide-left 1s;
      }

      &__slide-left-leave-active {
        animation: slide-left-out 0.5s;
      }

      @keyframes slide-left {
        0% {
          opacity: 0;
          visibility: hidden;
          transform: translate(-200%, 0);
        }

        100% {
          transform: translate(0%, 0);
        }
      }

      @keyframes slide-left-out {
        0% {
          transform: translate(0, 0);
        }
        100% {
          opacity: 0;
          visibility: hidden;
          transform: translate(200%, 0);
        }
      }

      &__slide-right-enter-active {
        animation: slide-right 1s;
      }

      &__slide-right-leave-active {
        animation: slide-right-out 0.5s;
      }

      @keyframes slide-right {
        0% {
          opacity: 0;
          visibility: hidden;
          transform: translate(200%, 0);
        }

        100% {
          transform: translate(0, 0);
        }
      }

      @keyframes slide-right-out {
        0% {
          transform: translate(0, 0);
        }
        100% {
          opacity: 0;
          visibility: hidden;
          transform: translate(-200%, 0);
        }
      }

      &__not-show {
        opacity: 0;
        display: none;
      }
    }

    &__buttons {
      position: absolute;
      z-index: 20;
      top: 0;
      left: 0;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__item {
        background-color: rgba(128, 128, 128, 0.5);
        filter: opacity(60%);
        height: 50px !important;
        width: 50px !important;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: filter 0.1s ease-in;
        cursor: pointer;
        &:hover {
          filter: opacity(100%);
          transition: filter 0.1s ease-in;
        }
      }
    }

    &__footer {
      width: 100%;
      background-color: rgba(128, 128, 128, 0.5);
      position: absolute;
      z-index: 20;
      bottom: 7px;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &--big {
        height: 70px;
      }

      &--small {
        height: 50px;
      }

      &__position {
        display: flex;
        justify-self: center;

        &__container {
          height: 30px;
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          z-index: 5;
          position: relative;

          &--selected {
            background-color: rgba(255, 255, 255, 0.2);
            transition: all 0.1s ease-in;
          }

          &--not-selected {
            background-color: none;
            transition: all 0.1s ease-in;
          }

          &__item {
            height: 15px;
            width: 15px;
            background-color: white;
            border-radius: 50%;
            filter: brightness(0.7);
            transition: filter 0.1s ease-in;
            cursor: pointer;
            z-index: 8;
            position: absolute;

            &--selected {
              filter: brightness(0.9);
              transition: filter 0.1s ease-in;
            }

            &:hover {
              filter: brightness(1);
              transition: filter 0.1s ease-in;
            }
          }
        }
      }
    }
  }
}
</style>
