<template>
  <div v-if="colors" class="main-filter-component p-5 text-center">
    <h1 class="font-bold text-xl" :style="{ color: colors.colorTitleText }">
      {{ labels.textTitle }}
    </h1>
    <h2 :style="{ color: colors.colorTitleText }">{{ labels.textSubtitle }}</h2>
    <Separator class="my-5" />
    <reserve-filter-card
      v-if="type === 'vertical'"
      ref="reserveFilterCard"
      :loading="loadingData"
      :branches="branches"
      :error="error"
      @validation="eventReserveValidation"
      @checkAvailability="eventCheckAvailability"
    />
    <reserve-filter-long
      v-if="type === 'horizontal'"
      ref="reserveFilterLong"
      :loading="loadingData"
      :error="error"
      :branches="branches"
      @validation="eventReserveValidation"
      @checkAvailability="eventCheckAvailability"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Separator from "@/modules/v1/Components/separator";
import ReserveFilterCard from "@/modules/v1/Components/reserve-filter-card";
import ReserveFilterLong from "@/modules/v1/Components/reserve-filter-long";

export default {
  name: "main-filter-component",
  components: {
    Separator,
    ReserveFilterCard,
    ReserveFilterLong,
  },
  props: {
    type: { required: false, type: String, default: "vertical" },
  },
  data: () => ({
    mainFilter: {
      pickUpDate: null,
      returnBackDate: null,
      pickUpBranch: null,
      returnBackBranch: null,
    },
    branches: [],
    loadingData: false,
    reserveValidation: false,
    error: null,
  }),
  computed: {
    ...mapState(["category, branchVehicle, configuration"]),
    ...mapGetters({
      colors: "configuration/getColors",
      labels: "configuration/getLabels",
    }),
  },
  created() {
    var params = [];
    this.$store
      .dispatch("branchVehicle/getAvailableBranches", params)
      .then((resp) => {
        if (resp.status !== 200) {
          this.error = "branch";
        } else {
          this.branches = resp.data;
        }
      });
  },
  methods: {
    eventCheckAvailability() {
      this.error = null;
      this.loadingData = true;
      setTimeout(() => this.validateData(), 1000);
    },

    validateData() {
      if (this.type === "vertical") {
        this.$refs.reserveFilterCard.validate();
      }
      if (this.type === "horizontal") {
        this.$refs.reserveFilterLong.validate();
      }
    },

    eventReserveValidation(event) {
      this.reserveValidation = event.valid;
      if (this.reserveValidation) {
        this.mainFilter.pickUpBranch = event.filter.pickLocation.id;
        this.mainFilter.returnBackBranch = event.filter.returnLocation.id;
        this.mainFilter.pickUpNameBranch = event.filter.pickLocation.name;
        this.mainFilter.returnBackNameBranch = event.filter.returnLocation.name;
        this.mainFilter.pickUpDirectionBranch =
          event.filter.pickLocation.positionStr +
          ", " +
          event.filter.pickLocation.city +
          ", " +
          event.filter.pickLocation.postalCode;
        this.mainFilter.returnBackDirectionBranch =
          event.filter.returnLocation.positionStr +
          ", " +
          event.filter.returnLocation.city +
          ", " +
          event.filter.returnLocation.postalCode;
        this.mainFilter.pickUpDate = new Date(
          event.filter.pickDate + " " + event.filter.pickHour
        ).getTime();
        this.mainFilter.returnBackDate = new Date(
          event.filter.returnDate + " " + event.filter.returnHour
        ).getTime();
        this.goToReservation();
      } else {
        this.loadingData = false;
      }
    },

    goToReservation() {
      this.$store.commit("configuration/SET_BOOKING_FILTERS", this.mainFilter);
      this.$store
        .dispatch("category/getAvailableCategories", this.mainFilter)
        .then((resp) => {
          this.loadingData = false;
          if (resp.status !== 200) {
            this.error = "category";
          } else {
            const bookingInfo = JSON.stringify(this.mainFilter);
            switch (this.$route.params.mode) {
              case "current":
                this.$router.push({
                  name: "Reservation",
                  query: this.$route.query,
                });
                break;
              case "new":
                window.parent.postMessage(bookingInfo, "*"); // Emit booking data for other frames
                break;
            }
          }
        })
        .catch((err) => {
          this.loadingData = false;
          this.error = err.response.data;
        });
    },
  },
};
</script>
